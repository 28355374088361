import { ServiceRequestApi } from '../ServiceRequestApi';
import Dict from '../../../modules/utils/Dict';
import Notice from '../../../modules/utils/Notice';
import i18next from 'i18next';
import moment from 'moment';

export const CheckChildInQueue = async (child_iin, queue_type) => {
  let check = false;
  const response = await ServiceRequestApi.checkQueueByIin(child_iin, queue_type);
  if (response.data.status === "SUCCESS") {
    for (let i = 0; i < response.data.result.length; i++) {
      let item = response.data.result[i];
      switch (item.appStatus) {
        case "ON_CHECK":
          check = true;
          let app_status = await Dict.item('gu_app_status', item.appStatus);
          Notice.error(i18next.t('serviceRequest_check_in_queue_err1', {
            fio: item.childSurname +" "+ item.childName+" "+item.childMiddleName,
            child_iin: item.childIin,
            req_form_id: item.requestFormId,
            app_status: app_status[`${i18next.language}_name`],
          }));
          break;
        case "IN_QUEUED":
        case "WAITING_PRIORITY":
        case "PRIORITY_ACCESS":
          check = true;
          let q_type = await Dict.item('gu_queued_type', item.queuedType);
          let p_queued = await Dict.item('gu_priority_queued', item.priorityQueued);
          Notice.error(i18next.t('serviceRequest_check_in_queue_err2', {
            fio: item.childSurname +" "+ item.childName+" "+item.childMiddleName,
            child_iin: item.childIin,
            req_form_id: item.requestFormId,
            queued_type: q_type[`${i18next.language}_name`],
            priority_queued: p_queued[`${i18next.language}_name`],
          }));
          break;
        case "DIRECTION_RECEIVED":
        case "DIRECTION_PROLONGED":
          check = true;
          let dir_id = '__';
          let dir_date = '__.__.____';
          if (item.directionId) {
            const dir_res = await ServiceRequestApi.getDirection(JSON.stringify({id: item.directionId} ));
            const direction = dir_res.data.result.content;
            if(direction.length > 0) {
              dir_id = direction[0].id;
              if (direction[0].directionDate) {
                dir_date = moment(direction[0].directionDate).format("DD.MM.YYYY");
              }
            }
          }
          Notice.error(i18next.t('serviceRequest_check_in_queue_err3', {
            fio: item.childSurname +" "+ item.childName+" "+item.childMiddleName,
            child_iin: item.childIin,
            req_form_id: item.requestFormId,
            dir_id: dir_id,
            dir_date: dir_date,
          }));
          break;
        case "STOP_LIST_DC":
          check = true;
          Notice.error(i18next.t('serviceRequest_check_in_queue_err4', {
            fio: item.childSurname +" "+ item.childName+" "+item.childMiddleName,
            child_iin: item.childIin,
            req_form_id: item.requestFormId,
            deadline_stop_list: item.deadlineStopList ? moment(item.deadlineStopList).format("DD.MM.YYYY") : '__.__.____',
          }));
          break;
        default:
      }
      if(check){
        break;
      }
    }
  } else {
    Notice.error(i18next.t('serviceRequest_queue_type_check_req_err'));
  }
  return check;
}
