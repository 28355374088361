import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardsContainer } from './KindergartenApplicationsStyle';
import './KindergartenApplicationsTranslate';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearState,
  loadApplications,
  kindergartenApplicationsModule,
  checkCanGetNapravlenie,
  confirmInterest
} from './KindergartenApplicationsDucks';
import { requestsColumns } from './TableColumns/TableColumns';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';
import useModal from '../../modules/_hooks/useModal';
import DialogRevoke from './Dialogs/DialogRevoke';
import { revokeByApplicant } from './KindergartenApplicationsDucks';
import { useHistory } from 'react-router-dom';
import DialogInterested from './Dialogs/DialogInterested';
import DialogSelectDDO from './Dialogs/DialogSelectDDO';

function KindergartenApplications() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector(state => state[kindergartenApplicationsModule].data);
  const pageNumber = useSelector(
    state => state[kindergartenApplicationsModule].pageNumber
  );
  const totalElements = useSelector(
    state => state[kindergartenApplicationsModule].totalElements
  );
  const loading = useSelector(state => state[kindergartenApplicationsModule].loading);
  const check_loading = useSelector(
    state => state[kindergartenApplicationsModule].check_loading
  );

  const loadData = filter => dispatch(loadApplications(filter));
  const { push } = useHistory();

  const { toPage, filter } = useTableFilter(loadData, '', {
    pageNumber: 1,
    pageSize: 10,
    isActive: null,
    isArchived: null
  });

  const modalRevoke = useModal('revoke');
  const modalInterested = useModal('interested');
  const modalSelectDDO = useModal('selectDDO');

  useEffect(() => {
    dispatch(clearState());
  }, []);

  const handleDone = async () => {
    dispatch(revokeByApplicant(modalRevoke.data.id, modalRevoke, filter));
  };

  const onOInterested = async () => {
    dispatch(confirmInterest(modalInterested.data.id, modalInterested, filter));
  };

  const checkBulletins = data => {
    dispatch(checkCanGetNapravlenie(data, push));
  };

  return (
    <div className="mb3 mt3" style={{ width: '100%' }}>
      <Typography
        children={t('kindergarten_applications_subtitle')}
        color="secondary"
        variant="h6"
        style={{ paddingLeft: '10px' }}
      />
      <StyledCardsContainer>
        <StaticTablePaginated
          TheadComponent={_ => null}
          pageNumber={pageNumber}
          loading={loading}
          data={data}
          totalElements={totalElements}
          columns={requestsColumns({
            t,
            filter,
            check_loading,
            pageNumber,
            lang: i18n.language,
            checkBulletins,
            modalRevoke,
            modalInterested,
            modalSelectDDO
          })}
          filterable={false}
          pageSize={10}
          pagePagination={page => {
            if (page !== pageNumber) {
              toPage(page);
            }
          }}
        />
      </StyledCardsContainer>
      <DialogRevoke
        open={modalRevoke.isOpen}
        handleClose={modalRevoke.close}
        handleDone={handleDone}
      />
      <DialogInterested
        open={modalInterested.isOpen}
        handleClose={modalInterested.close}
        handleDone={onOInterested}
        data={modalInterested.data}
        loading={loading}
      />
      <DialogSelectDDO 
        open={modalSelectDDO.isOpen}
        handleClose={modalSelectDDO.close}
        data={modalSelectDDO.data}
      />
    </div>
  );
}

export default KindergartenApplications;
