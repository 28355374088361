export function getContentWidth(location){
  switch (location) {
    case '/kindergarten-applications':
    case '/kindergarten-appointments':
    case '/kindergarten-notifications':
    case '/account':
    case '/requests':
    case '/completed-requests':
    case '/account/settings':
      return '1400px';
    default:
      return '1400px';
  }
}
