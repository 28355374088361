import lodash from 'lodash';
import { getFlcRules } from '../Utils/getFlcRules';
import { RENDERER } from '../Utils/Constants';

// формирование разделов
export function getGroups(
  metaData,
  groups = [],
  hideKeyInLabel,
  getCodeWithMetaId
) {
  const metaDataId = metaData.id;
  let result = [];
  for (const mGroup of groups) {
    //TODO временное игнорирование раздела
    if (mGroup.id === 'request_input') continue;
    let group = {
      id: mGroup.id,
      code: `group_${metaDataId}_${mGroup.id}`,
      ru_name: mGroup.ru_name,
      kk_name: mGroup.kk_name,
      filledCount: mGroup.filledCount,
      count: mGroup.count,
      metaDataId: metaDataId,
      hideKeyInLabel: hideKeyInLabel,
      renderer: 'group',
      questionCodes: lodash
        .get(mGroup, 'sections[0].questionCodes', [])
        .map(
          code =>
            metaData.questions.hasOwnProperty(code)
              ? `${metaDataId}.${code}`
              : getCodeWithMetaId(code, metaDataId)
        ),
      subGroups: []
    };

    /*
    if (window.test) {
      group.kk_name += ` [${group.id}]`;
      group.ru_name += ` [${group.id}]`;
    }
*/

    // записываем рулы если есть
    for (const rule of getFlcRules(RENDERER.group)) {
      if (mGroup[rule]) {
        let ruleBody = mGroup[rule];
        ruleBody = ruleBody.replace(/dynamicTableRowCell/g, 'complexTableCell');
        ruleBody = ruleBody.replace(/dynamicTableColumn/g, 'complexTableColumn');
        ruleBody = ruleBody.replace(/staticTableColumn/g, 'tableMatrix');
        group[rule] = ruleBody;
      }
    }

    // формирование подразделов
    if (mGroup.hasOwnProperty('subGroups') && mGroup.subGroups.length > 0) {
      group.subGroups = getGroups(
        metaData,
        mGroup.subGroups,
        hideKeyInLabel,
        getCodeWithMetaId
      );
    }
    // пропускаем если пусто
    if (group.questionCodes.length === 0 && group.subGroups.length === 0) {
      continue;
    }
    result.push(group);
  }
  return result;
}
