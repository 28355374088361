import FormChange from './FormData/FormChange';
import Form from './FormData/PassportForm';
import _PassportProvider from './PassportProvider';
import _PassportForm from './PassportForm';

export { default as PassportProvider } from './PassportProvider';
export { default as useStaticInfo } from './useStaticInfo';
export { default as PassportForm } from './PassportForm';
export { default as PassportNavigation } from './Components/PassportNavigation';
export { default as PassportExpander } from './Components/PassportExpander';
export { default as setFormValueByEntity } from './FormData/setFormValueByEntity';
export const changeFormValue = (questionCode, value) => {
  FormChange.change(questionCode, value);
};

export const changeTableValue = (questionCode, cellsValue = {}, source = {}) => {
  FormChange.changeTable(questionCode, cellsValue, source);
};
export const changeComplexValue = (questionCode, value = {}, cellsSource = {}) => {
  FormChange.changeComplex(questionCode, Object.keys(value), value, cellsSource);
};
export const getFormValues = (onlyFlc, checkError) => Form.getFormValues(onlyFlc, checkError);


export const Passport = {
  Form: Form,
  Provider: _PassportProvider,
  PassportForm: _PassportForm,
  formChange: Form.change
};

export default Passport;
