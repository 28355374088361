import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const Wrapper = styled.div`
  width: 290px;
  border-right: 1px solid #e0e0e0;
  margin-right: 30px;
  position: relative;

  .queue_filter_inputs{
    padding: 20px 15px 0px 0px;
    font-size: 14px;
    text-transform: uppercase;
    width: 290px;
  }
  .centerContainer{
    text-align: center;
    margin: auto;
    top: 10%;
  }
  .mgt5{
    margin-top: 5px;
  }
  .mgt15{
    margin-top: 15px;
  }
  
  @media screen and (max-width: 850px) {
    width: 100%;
    padding-top: 20px;
    border-right: 0;
    margin-right: 0;
    
    .MuiPaper-root {
      display: none;
    }
  }
`;

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputBase-input': {
      color: 'rgb(114, 100, 214) !important',
      fontSize: '14px',
      textTransform: 'uppercase',
    },
    '& .MuiAutocomplete-endAdornment': {
      width: '68px',
      right: '0 !important',
      top: '0',
      height: '100%',
      backgroundColor: '#e0dcff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopRightRadius: '10px !important',
      borderBottomRightRadius: '10px !important',
      '& button': {
        width: '0',
        margin: '0 !important',
        '&[aria-label="Open"]': {
          display: 'none',
        },
        '&[aria-label="Clear"]': {
          visibility: 'visible'
        },
      },
    },
  },
  clearIndicator: {
    "& span": {
      margin: '0 !important',
      "& svg": {
        fontSize: 25,
        "& path": {
          d: "path('M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z')"
        }
      }
    }
  },
  comboOptions: {
    textTransform: 'none'
  }
}));

export const useStyles1 = makeStyles(theme => ({
  root: {
    '& .MuiInputBase-input': {
      color: 'rgb(114, 100, 214) !important',
      fontSize: '14px',
      textTransform: 'uppercase',
    },
    '& .MuiAutocomplete-endAdornment': {
      width: '68px',
      right: '0 !important',
      top: '0',
      height: '100%',
      backgroundColor: '#e0dcff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopRightRadius: '10px !important',
      borderBottomRightRadius: '10px !important',
      '& button': {
        width: '0',
        margin: '0 !important',
      },
      '& .MuiAutocomplete-clearIndicator': {
        display: 'none'
      },
    },
  },
  popupIndicator: {
    "& span": {
      margin: '0 !important',
      "& svg": {
        fontSize: 25,
        "& path": {
          d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')"
        }
      }
    },
  },
  comboOptions: {
    textTransform: 'none'
  },
}));