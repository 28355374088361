import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';

import Button from '../../modules/Button/Button';

export const StyledButton = styled(Button)`
  overflow: hidden;
  span {
    display: block;
  }
`;

export const StyledTableContainer = styled.div`
  @media screen and (max-width: 600px) {
    overflow-x: auto;
    & > div {
      min-width: 800px;
    }
  }
`;

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);