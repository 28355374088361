import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardsContainer, StyledTableContainer } from './QueueApplicationsStyle';
import './QueueApplicationsTranslate';
import paths from '../../_helpers/paths';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { queueApplicationsModule, clearState, loadApplications } from './QueueApplicationsDucks';
import { requestsColumns } from './TableColumns';
import Counters from './QueueApplicationsComponents/Counters/Counters';
import Filters from './QueueApplicationsComponents/Filters/Filters.jsx';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import Search from './QueueApplicationsComponents/Search/Search';

function QueueApplications() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const breadCrumbItems = [];
  breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
  breadCrumbItems.push({ name: t('kindergarten_subtitle'), href: paths.kindergartenServices });
  breadCrumbItems.push({ name: t('queue_applications_subtitle') });

  const applications = useSelector(state => state[queueApplicationsModule].data);
  const pageNumber = useSelector(state => state[queueApplicationsModule].pageNumber);
  const totalElements = useSelector(state => state[queueApplicationsModule].totalElements);
  const loading = useSelector(state => state[queueApplicationsModule].loading);
  const [search, setSearch] = useState('');

  const loadData = (filter) => dispatch(loadApplications(t, filter));
  const { filter, toPage, changePageSize, searchByFilter } = useTableFilter(
    loadData, "", { pageNumber: 1, pageSize: 20 });

  useEffect(() => {
    dispatch(clearState());
  }, [])

  return (
    <div className="mb3 mt3" style={{width: '100%'}}>
      <BreadCrumbs items={breadCrumbItems} />
      <StyledCardsContainer>
        <Filters loading={loading} filter={filter} setSearch={setSearch} searchByFilter={searchByFilter}/>
        <div>
          <Counters/>
          <Search search={search} setSearch={setSearch} filter={filter}/>
          {applications &&
            <StyledTableContainer>
              <div className="mt2">
                <StaticTablePaginated
                  TheadComponent={_ => null}
                  pageNumber={pageNumber}
                  loading={loading}
                  data={applications}
                  totalElements={totalElements}
                  columns={requestsColumns({t})}
                  filterable={false}
                  pageSize={filter.pageSize}
                  changePageSize={changePageSize}
                  pageSizes={[10, 20, 50, 100]}
                  pagePagination={page => {
                    if (page !== pageNumber) {
                      toPage(page);
                    }
                  }}
                />
              </div>
            </StyledTableContainer>
          }
        </div>
      </StyledCardsContainer>
    </div>
  );
}

export default QueueApplications;
