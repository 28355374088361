import axios from 'axios';

export const KindergartenReestrApi = {
  getKindergartens: (filter = null) => axios.get(
    `/api/public/data/service_provider_with_pre_school_graduation_with_filters`, {params: filter}
  ),
  getImg: (imgUrl, accessToken) => axios.get(imgUrl, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
};
