import { tableDomainData } from '../../Utils/tableDomainData';
import { FLC_RULES } from '../../Utils/Constants';
import { replaceRuleMethods } from '../../Utils/replaceRuleMethods';
import { flcHasValue } from '../../Utils/flcHasValue';
import Dict from '../../Utils/Dict';

export async function setTableData(question, valueObj) {
  if (!question.initialized) {
    question.domain = {
      rows: await tableDomainData(
        question.config.horizontalDomainDatas,
        question.config.excludedDomainItems,
        true
      ),
      columns: await tableDomainData(
        question.config.verticalDomainDatas,
        question.config.excludedDomainItems
      )
    };

    // загружаем справочники
    question.domainData = {};
    if (question.config.horizontalDomainDatas) {
      for (const dictCode of question.config.horizontalDomainDatas) {
        let dict = await Dict.items(dictCode);
        question.domainData[dictCode] = dict.map(item => item.code);
      }
    }
    if (question.config.verticalDomainDatas) {
      for (const dictCode of question.config.verticalDomainDatas) {
        let dict = await Dict.items(dictCode);
        question.domainData[dictCode] = dict.map(item => item.code);
      }
    }

    // записываем рулы для ячеек
    let matrixVisibilityRule = [];
    let emptyCellsValues = {};
    let cellsRules = {};

    if (question.cellRules && question.cellRules.length > 0) {
      for (const mCellRule of question.cellRules) {
        if (mCellRule['cellsVisibilityRule']) {
          const hasRowDomain = question.config['horizontalDomainDatas'].some(code => mCellRule.cellsFilter[code]);
          const hasColDomain = question.config['verticalDomainDatas'].some(code => mCellRule.cellsFilter[code]);

          if (!hasColDomain && hasRowDomain) {
            matrixVisibilityRule.push({
              cellsFilter: mCellRule.cellsFilter,
              rule: mCellRule['cellsVisibilityRule']
            })
          }
          if (hasColDomain && !hasRowDomain) {
            matrixVisibilityRule.push({
              cellsFilter: mCellRule.cellsFilter,
              rule: mCellRule['cellsVisibilityRule']
            })
          }
        }
      }
    }

    for (const columnSet of question.domain.columns.cells) {
      for (const rowSet of question.domain.rows.cells) {
        let cellCode = JSON.stringify({ row: rowSet, column: columnSet });
        let cellRule = {};
        emptyCellsValues[cellCode] = {
          value: '',
          coordinates: { row: rowSet, column: columnSet }
        };

        if (question.cellRules && question.cellRules.length > 0) {
          for (const mCellRule of question.cellRules) {
            let coordinates = { ...columnSet, ...rowSet };
            let foundedRule = true;
            for (const code of Object.keys(mCellRule.cellsFilter)) {
              if (coordinates[code] !== mCellRule.cellsFilter[code]) {
                foundedRule = false;
              }
            }

            if (foundedRule) {
              for (const rule of Object.values(FLC_RULES)) {
                const ruleKey = 'cells' + rule.charAt(0).toUpperCase() + rule.slice(1);
                let ruleBody = replaceRuleMethods(mCellRule[ruleKey]);
                if (ruleBody) cellRule[rule] = ruleBody;
              }
            }
          }

          if (Object.keys(cellRule).length > 0) {
            cellsRules[cellCode] = cellRule;
          }
        }
      }
    }
    delete question.cellRules;
    question.matrixVisibilityRule = matrixVisibilityRule;
    question.cellsRules = cellsRules;
    question.source = {};
    question.cellsSavedValue = emptyCellsValues;
    question.cellsToDelete = [];
  }

  question.cellHasValue = false;

  // записываем значение таблицы
  if (valueObj.values && valueObj.values[0]) {
    for (const cell of valueObj.values[0].value) {
      let coordinates = { row: {}, column: {} };
      cell.coordinates.rowSet.forEach((code, index) => {
        coordinates.row[question.config.horizontalDomainDatas[index]] = code;
      });
      cell.coordinates.columnSet.forEach((code, index) => {
        coordinates.column[question.config.verticalDomainDatas[index]] = code;
      });
      const newCell = { ...cell, coordinates };
      const key = JSON.stringify(newCell.coordinates);
      if (question.cellsSavedValue.hasOwnProperty(key)) {
        question.cellsSavedValue[key] = newCell;
      } else if (
        !question.keepIfHidden &&
        newCell.hasOwnProperty('value') &&
        newCell.value !== null
      ) {
        question.cellsToDelete.push(newCell);
      }
      if (!question.cellHasValue && flcHasValue(newCell.value)) {
        question.cellHasValue = true;
      }
    }
    question.source = valueObj.values[0].source || {};
  }
}
