import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledContent, StyledBreadCrumbs } from './KindergartenServicesStyle';
import './KindergartenServicesTranslate';
import paths from '../../_helpers/paths';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { getContentWidth } from '../../utils/getContentWidth';
import { Content } from '../../components/ThemeProvider/ThemeStyle';
import KindergartenInsert from './section/KindergartenInsert';
import KindergartenOurService from './section/KindergartenOurService'
import KindergartenQuestions from './section/KindergartenQuestions';
import KindergartenUsefulArticles from './section/KindergartenUsefulArticles';

function KindergartenServices() {
  const { t } = useTranslation();
  const breadCrumbItems = [];
  breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
  breadCrumbItems.push({ name: t('kindergarten_subtitle') });
  const contentWidth = getContentWidth('/kindergarten-services');
  return (
    <div style={{width: '100%'}}>
      <StyledContent>
        <StyledBreadCrumbs>
          <Content contentWidth={contentWidth}>
            <BreadCrumbs items={breadCrumbItems} />
          </Content>
        </StyledBreadCrumbs>
        <KindergartenInsert contentWidth={contentWidth} />
      </StyledContent>
      <KindergartenOurService contentWidth={contentWidth} />
      <KindergartenQuestions contentWidth={contentWidth} />
      <KindergartenUsefulArticles contentWidth={contentWidth} />
    </div>
  );
}

export default KindergartenServices;
