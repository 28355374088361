import flc from './flc_ru';

export const RU_TRANSLATION = {
  lang: 'ru',
  messages: {
    ...flc,

    'header.changeLangBtn': 'РУС',
    'header.logout': 'Выйти',
    'header.changePass': 'Смена пароля',
    'header.userManagement': 'Управление пользователями',
    'header.notificationManagement': 'Управление уведомлениями',
    'header.editProfile': 'Редактировать профиль',
    'header.errorOccurred': 'Произошла ошибка',
    'header.errorFirstPart':
      'Ошибка при подключении к программе - NCALayer. Убедитесь что программа запущена, или пройдите по следующей ',
    'header.errorSecondPart': 'ссылке',
    'header.errorThirdPart': ', чтобы установить программу NCALayer',
    'header.help': 'Помощь',
    'header.support': 'Служба поддержки',
    'header.FAQ': 'Часто задаваемые вопросы',
    'header.manual': 'Руководство пользователя',
    'header.instructions': 'Инструкции по заполнению',
    'header.infoSecurity': 'Информационная безопасность',
    'header.nca.connect.success': 'Успешное подключение к NCALayer',

    'manual.admin': 'Руководство пользователя для администратора',
    'manual.PO': 'Руководство пользователя для "Дошкольных организаций"',
    'manual.orphan':
      'Руководство пользователя для "Организаций образования для детей-сирот и детей, оставшихся без попечения родителей"',
    'manual.OSE':
      'Руководство пользователя для "Организаций среднего образования(начального, основного среднего и общего среднего)"',
    'manual.special':
      'Руководство пользователя для "Специальных организаций образования"',
    'manual.OTVE':
      'Руководство пользователя для "Организаций технического и профессионального образования"',
    'manual.mio_employees': 'Руководство пользователя для сотрудников МИО',
    'manual.mio_directors': 'Руководство пользователя для руководителя МИО',
    'manual.ministry_employees': 'Руководство пользователя для сотрудников МОН РК',
    'manual.dkson_employees': 'Руководство пользователя для сотрудников ДКСО',
    'manual.dkson_directors': 'Руководство пользователя для руководителя ДКСО',
    'manual.kkson_employees': 'Руководство пользователя для сотрудника ККСОН',
    'manual.vseobuch':
      'Руководство пользователя Всеобуч для "Организаций среднего образования"',
    'manual.OAE':
      'Руководство пользователя для "Организаций дополнительного образования"',
    'manual.specialized':
      'Руководство пользователя для "Специализированных организаций образования"',
    'manual.trajectory': 'Руководство пользователя "Траектория движения учащихся"',
    'manual.microterritory': 'Руководство пользователя "Микроучастки"',
    'manual.createSchool': 'Руководство пользователя "Создание организации образования"',
    'manual.MIO_MON': 'Руководство пользователя для сотрудников МИО-РОО, ГорОО',

    'instruction.OOType.01': 'Инструкция по заполнению НОБД для "Дошкольных организаций"',
    'instruction.OOType.02':
      'Инструкция по заполнению НОБД для "Организаций среднего образования"',
    'instruction.OOType.03':
      'Инструкция по заполнению НОБД для "Организаций технического и профессионального образования"',
    'instruction.OOType.06':
      'Инструкция по заполнению НОБД для "Организаций высшего и (или) послевузовского образования"',
    'instruction.OOType.07':
      'Инструкция по заполнению НОБД для "Специализированных организаций образования"',
    'instruction.OOType.08':
      'Инструкция по заполнению НОБД для "Специальных организаций образования"',
    'instruction.OOType.09':
      'Инструкция по заполнению НОБД для "Организаций образования для детей-сирот и детей, оставшихся без попечения родителей"',
    'instruction.OOType.10':
      'Инструкция по заполнению НОБД для "Организаций дополнительного образования для детей"',

    'instruction.mio_employees': 'Инструкция по заполнению НОБД для сотрудников МИО',
    'instruction.mio_directors': 'Инструкция по заполнению НОБД для руководителя МИО',
    'instruction.ministry_employees':
      'Инструкция по заполнению НОБД для сотрудников МОН РК',
    'instruction.dkson_employees': 'Инструкция по заполнению НОБД для сотрудников ДКСО',
    'instruction.dkson_directors': 'Инструкция по заполнению НОБД для руководителя ДКСО',
    'instruction.kkson_employees': 'Инструкция по заполнению НОБД для сотрудников ККСОН',

    'footer.text': '© Министерство образования и науки РК, 2019',

    'validation.error.number_of_pupils':
      '«Дети-сироты и дети оставшиеся без попечения родителей по национальности» и «дети-сироты и дети оставшиеся без попечения родителей» должны быть равны',
    'validation.error.incorrect': 'Некорректное значение',
    'validation.error.undefined': 'Обязательно для заполнения',
    'validation.error.required': 'Обязательно для заполнения',
    'validation.error.test_validation_code': 'Тестовая проверка данных',
    'validation.error.email_validation': 'Неправильный формат адреса электронной почты',
    'validation.error.another_test_validation_code':
      'Неправильный формат адреса электронной почты',
    'validation.error.interactive_tools_validation_code': 'Не может быть равно нулю',
    'validation.error.zero_validation_code':
      'Ставка должна равняться нулю для сотрудника в декретном отпуске',
    'validation.error.personalCompareDates':
      'Дата расторжения/прекращения трудового договора не может быть меньше даты принятия на работу',
    'validation.error.personalCheckemploymentDate':
      'Дата принятия на работу должна быть обязательно заполнена',
    'validation.error.group_room_area':
      'Площадь групповых помещений должна быть меньше общей плошади здания',
    'validation.error.up_limit': 'Не должно быть больше 2500',
    'validation.error.area_not_null':
      'При наличии спортивного зала площадь не должна равняться нулю',
    'validation.error.less_five_numbers': 'Число должно быть не более 5 цифр',
    'validation.error.less_count_book':
      'Должно быть равно или меньше количеству комплектов книг',
    'validation.error.less_count_mug': 'Должно быть меньше или равно количеству кружков',
    'validation.error.office_quantaty_nomore_quantaty_withequipment':
      'Количество кабинетов, оснащенных современным обучающим оборудованием (за последние 10 лет) не может быть больше общего количества кабинетов',
    'validation.error.not_more_people': 'Не может быть больше 20 человек',
    'validation.error.total_design_capacity':
      'Суммарная проектная мощность не может быть больше 2500',
    'validation.error.groups_must_coincide':
      'Наименование группы должно совпадать с одним из наименований, введенных в показателе "Группы"',
    'validation.error.not_null': 'Не может быть равно нулю',
    'validation.error.no_more_total': 'Не может превышать значение «Всего»',
    'validation.error.no_more_used_instudy':
      'Не может превышать значение «Используются в учебном/воспитательном процессе»',
    'validation.error.no_more_for__teachers':
      'Не может превышать значение «Для преподавателей/учителей/воспитателей»',
    'validation.error.can_not_exceed':
      'Не может превышать значение той же строки таблицы «Посещаемость всех, человек»',
    'validation.error.no_more_total_equipment':
      'не может превышать значение «количество единиц установленного оборудования»',
    'validation.error.no_more_total_facilities':
      'Не может превышать значение «сумма выделенных средств»',
    'validation.error.no_more_3number':
      'Средний размер заработной платы не должен превышать 999 тысяч тенге',
    'validation.error.sum_lines_equal':
      'Сумма значений по строке «Всего учащихся» должна быть равна значению «Количество учащихся школьного возраста (6-18 лет) ,чел.» в разделе «Основные сведения об образовательном процессе»',
    'validation.error.no_more_total_countries':
      'Не может превышать значение «Выехали за пределы страны»',
    'validation.error.groups_must_coincide_property':
      'Наименование группы должно совпадать с одним из наименований, введенных в показателе «Данные о группах всех форм собственности»',
    'validation.error.can_not_exceed_diseases':
      'Не может превышать значение в той же строке таблицы «Число случаев заболевания у детей»',
    'validation.error.wrongCoordinates':
      'Некорректные координаты, отметьте место на карте!',
    'validation.error.no_more_2_number': 'Количество  не должно превышать числа 99',
    'validation.error.no_more_3_number': 'Количество не должно превышать числа 999',
    'validation.error.no_more_exploitation':
      'Не может быть больше «Год ввода здания в эксплуатацию»',
    'validation.error.no_more_contract':
      'Не должно быть больше «Скорость интернета по договору»',
    'validation.error.no_more_total_area': 'Не должно быть больше «Общая площадь здания»',
    'validation.error.no_more_work_area':
      'Не должно быть больше «Рабочая площадь здания»',
    'validation.error.no_more_current_computers':
      'Сумма не должна превышать количество компьютеров на текущий год',
    'validation.error.no_more_total_cabinets':
      'Не должно быть больше количества кабинетов',
    'validation.error.more_null': 'Должно быть больше нуля',
    'validation.error.no_more_ling_cabinets':
      'Не должно превышать количество лингофонных кабинетов',
    'validation.error.null_total_ling_cabinets':
      'Должно равняться нулю как в количестве лингофонных кабинетов',
    'validation.error.null_total_inf_cabinets':
      'Должно равняться нулю как в количестве  кабинетов по информатике',
    'validation.error.up_limit_do': 'Не должно быть больше 1000',
    'validation.error.null_cabinets_fizics':
      'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по физике',
    'validation.error.no_more_cabinets_fizics':
      'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" по физике',
    'validation.error.null_cabinets_chemistry':
      'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по химии',
    'validation.error.no_more_cabinets_chemistry':
      'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" по химии',
    'validation.error.null_cabinets_bilogy':
      'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по биологии',
    'validation.error.no_more_cabinets_biology':
      'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" по биологии',
    'validation.error.null_cabinets_geography':
      'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по географии',
    'validation.error.no_more_cabinets_geography':
      'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" по географии',
    'validation.error.null_cabinets_ling':
      'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по лингафонно-мультимедийным кабинетам',
    'validation.error.no_more_cabinets_ling':
      'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" полингафонно-мультимедийным кабинетам',
    'validation.error.equal_zero': 'Должно быть равно нулю',
    'validation.error.less_element':
      'Сумма Аудиторного и Внеаудиторного фонда не должны превышать Общую площадь здания',
    'validation.error.no_more_5number': 'должно быть меньше 100 000',
    'validation.error.more_yard_clubs': 'Должно быть больше «Количество дворовых клубов»',
    'validation.error.one_hot_or_buffet':
      'Не может быть охвачен и горячим и буфетным питанием одновременно',
    'validation.error.no_hotmeal':
      'Чтобы отметить "Охвачен(-а) бесплатным горячим питанием" нужно отметить  "Охвачен(-а) горячим питанием"',
    'validation.error.no_buffet':
      'Чтобы отметить "Охвачен(-а) бесплатным буфетным питанием" нужно отметить  "Охвачен(-а) буфетным питанием"',
    'validation.error.equal_rebuildings':
      'Должно быть равным количеству зданий с техническим состоянием «проведен или требует капитальный ремонт»',
    'validation.error.equal_current_repairs':
      'Должно быть равным количеству зданий с техническим состоянием «проведен или требует текущий ремонт»',
    'validation.error.cost_more_null': 'Стоимость должна быть больше нуля',
    'validation.error.need_boording':
      'Учащийся не может одновременно «проживать в интернате при школе» и «нуждаться в размещении в интернате при школе»',
    'validation.error.equal_sum_lingcabinets':
      'Сумма ячеек должна быть равна показателю «Наличие кабинетов, единиц» в нем элементу «лингафонные кабинеты»',
    'validation.error.not_more_total_work_experience':
      'Стаж педагогической работы не может быть больше «Общий стаж работы»',
    'validation.error.no_more_16_year': 'Стаж педагогической работы допустим с 16-ти лет',
    'validation.error.school_children_age':
      'Должно быть меньше или равно элементу «Количество учащихся школьного возраста (6-18 лет)»',
    'validation.error.sum_lines_equal_two':
      'Сумма значений по этой строке должна быть равна значению «Количество детей дошкольного возраста (1-6 лет)» в разделе «Основные сведения об образовательном процессе»',
    'validation.error.integer_5number':
      'Должно быть целое положительное число не более 5 цифр',
    'validation.error.school_children_age_two':
      'Должен быть меньше или равен элементу «Количество детей дошкольного возраста (1-6 лет)»',
    'validation.error.must_main': 'Должно быть хотя бы одно «Основное здание»',
    'validation.error.following_format':
      'Номер телефона должен соответствовать 11 цифр без символов',
    'validation.error.scholarships_available':
      'при наличии стипендии в показателе «Информация о начислении и размер стипендии, тенге» не менее 1 ячейки должно быть больше 0',
    'validation.error.should_not_exceed':
      'не должен превышать «Общий стаж в данной организации»',
    'validation.error.property_group_data':
      'количество строк таблицы «Группы» и «Данные о группах всех форм собственности» должны быть равны',
    'validation.error.girls_boys_total_not_exceed':
      'сумма строк «Из них девочек» и «Из них мальчиков» не должно превышать «Общее количество»',
    'validation.error.the_total_must_match':
      'показатель «Общее количество детей» должно совпадать с «Общее количество детей по нарушениям (заболеваниям)» и «Из них по возрасту»',
    'validation.error.need_for_staff':
      '«из них женщин» не должно превышать «потребность в кадрах»',
    'validation.error.language_match_groups':
      'выбранные языки в показателе «Язык обучения» должны совпадать с показателем «Группы»',
    'validation.error.should_once_main': 'Основное здание может быть только одно',
    'validation.error.cant_anymore_quantity': 'Не может быть больше «Количество, экз»',

    'errors.error.runtime_student_is_on_academic_leave':
      'Студент находится в академическом отпуске',
    'errors.error.runtime_leave_date_more_than_days':
      'Срок отзыва выбытия 30 дней истекло',
    'errors.error.can_not_transition_approve':
      'Данный ученик числится в действующем контингенте следующей организации',
    'errors.error.runtime_education_statistic_fully_filled_not_be_false':
      'Необходимо заполнить и сохранить паспорт для контингента',
    'errors.error.runtime_education_info_already_registered2':
      'Данный обучающийся числиться в контингенте',
    'errors.error.student_with_iin_already_exists': 'Ученик с таким ИИН уже существует',
    'errors.error.teacher_with_iin_already_exists':
      'Персонал с таким ИИН уже существует в системе с другим ID',
    'errors.AUTH_HEADER_IS_NULL':"Пользователь не авторизован",

    'error_window_number_tab':'Не разрешается открывать больше 5 вкладок',

    'loginForm.title': 'Авторизация',
    'loginForm.login': 'Войти',
    'loginForm.iin': 'Имя пользователя',
    'loginForm.password': 'Пароль',
    'loginForm.changePassword': 'Изменить пароль',
    'loginForm.register': 'Зарегистрироваться',
    'loginForm.recoverPassword': 'Забыли пароль?',
    'loginForm.loginTip': 'Никому не сообщайте данные для входа',
    'loginForm.formValidation.required': 'Это поле обязательное для заполнения',
    'loginForm.formValidation.wrongIin': 'Неправильный формат ИИН',
    'loginForm.error.authorization': 'Не удалось авторизоваться',
    'loginForm.error.validECP': 'ИИН владельца ЭЦП не совпадает с ИИН пользователя',
    'loginForm.error.block':
      'Учетная запись пользователя заблокирована из-за неверно введенного логин/пароля.',
    'loginForm.formValidation.wrongPassword':
      '"Обязательно нужно использовать буквы (a-z и A-Z), не менее 8 цифр и символы (например, "#", "!" или "&")"',
    'loginForm.warning.passwordPolicy':
      'До истечения пароля осталось {{xDay}} дней. \nПароль можно изменить по ссылке : ',
    'loginForm.warning.passwordPolicy0Day':
      'Сегодня истекает срок действия вашего пароля. \nПароль можно изменить по ссылке : ',
    'loginForm.warning.infoSafety': 'Уведомление об ответственности\n' +
      'Я, пользователь информационной системы «Национальная образовательная база данных», продолжая работу в системе, подтверждаю свое согласие об ответственности за все осуществленные действия, в соответствии с Законом РК "О персональных данных и их защите"',

    'changePasswordForm.title': 'Изменить пароль',
    'changePasswordForm.changePassword': 'Изменить пароль',
    'changePasswordForm.currentPassword': 'Текущий пароль',
    'changePasswordForm.newPassword': 'Новый пароль',
    'changePasswordForm.confirmationPassword': 'Повторите пароль',
    'changePasswordForm.formValidation.required': 'Это поле обязательное для заполнения',
    'changePasswordForm.formValidation.wrongPasswordLength':
      'Пароль должен состоять не менее из 8 символов',
    'changePasswordForm.formValidation.wrongPasswordDigits':
      'Пароль должен включать цифры',
    'changePasswordForm.formValidation.wrongPasswordUppercase':
      'Пароль должен включать латинские буквы верхнего регистра (A-Z)',
    'changePasswordForm.formValidation.wrongPasswordLowercase':
      'Пароль должен включать латинские буквы нижнего регистра (a-z)',
    'changePasswordForm.formValidation.wrongPasswordNotCyrillic':
      'Пароль не должен содержать кириллицу',
    'changePasswordForm.formValidation.wrongPasswordSymbols':
      'Пароль должен включать не буквенные и не цифровые символы (например, "#", "!" или "&")',

    'onetimePasswordForm.title': 'Восстановить пароль',
    'onetimePasswordForm.recoverPassword': 'Восстановить',
    'onetimePasswordForm.usernameOrEmail': 'Имя пользователя или электронная почта',
    'onetimePasswordForm.formValidation.required': 'Это поле обязательное для заполнения',
    'onetimePasswordForm.tip':
      'На вами указанный электронный адрес будет напрaвлено письмо с ссылкой, куда нужно будет перейти, чтобы задать новый пароль',

    'signMethodForm.methods.kaztoken.title': 'Казтокен',
    'signMethodForm.methods.kaztoken.text':
      'Используйте для входа специальное USB-устройство электронной цифровой подписью',
    'signMethodForm.methods.desktop.title': 'Ваш компьютер',
    'signMethodForm.methods.desktop.text':
      'Выберите файл электронной цифровой подписи на Вашем компьютере',
    'signMethodForm.methods.personal.title': 'Удостоверение личности',
    'signMethodForm.methods.personal.text':
      'Осуществите вход с помощью электронной цифровой подписи на удостоверении личности',
    'signMethodForm.choose': 'Выбрать',
    'signMethodForm.sign': 'Подписать и отправить',
    'signMethodForm.signShort': 'Подписать',
    'signMethodForm.methodNotSupported': 'Невозможно использовать',
    'signMethodForm.methodNotSupported2': 'Для электронной цифровой подписи',
    'signMethodForm.methodCancelled': 'Электронная цифровая подпись с помощью',
    'signMethodForm.methodCancelled2': 'Отменена',
    'signMethodForm.methodSuccess': 'С помощью электронно цифровой подписью',
    'signMethodForm.methodSuccess2': 'Завершена успешно',

    'registerForm.iin': 'ИИН',
    'registerForm.username': 'Имя пользователя',
    'registerForm.password': 'Пароль',
    'registerForm.category': 'Категория организации',
    'registerForm.role': 'Роль',
    'registerForm.emailAddress': 'Электронная почта',
    'registerForm.agreement': 'Согласие на хранение и обработку персональных данных.',
    'registerForm.submit': 'Зарегистрировать',
    'registerForm.signAndSubmit': 'Подписать и отправить',
    'registerForm.educationManagement': 'Управление образования',
    'registerForm.region': 'Область',
    'registerForm.regionEducation': 'Отдел образования',
    'registerForm.organizationType': 'Тип организации',
    'registerForm.locality': 'Населенный пункт',
    'registerForm.organization': 'Организация',
    'registerForm.territorialLawsKKSON': 'Территориальные органы КKСОН',
    'registerForm.formValidation.required': 'Это поле обязательное для заполнения',
    'registerForm.formValidation.wrongEmail': 'Некорректный email',
    'registerForm.formValidation.wrongIin': 'Неправильный формат ИИН',
    'registerForm.formValidation.wrongPasswordLength':
      'Пароль должен состоять не менее из 8 символов',
    'registerForm.formValidation.wrongPasswordDigits': 'Пароль должен включать цифры',
    'registerForm.formValidation.wrongPasswordUppercase':
      'Пароль должен включать латинские буквы верхнего регистра (A-Z)',
    'registerForm.formValidation.wrongPasswordLowercase':
      'Пароль должен включать латинские буквы нижнего регистра (a-z)',
    'registerForm.formValidation.wrongPasswordNotCyrillic':
      'Пароль не должен содержать кириллицу',
    'registerForm.formValidation.wrongPasswordSymbols':
      "Пароль должен включать не буквенные и не цифровые символы (например, '#', '!' или '&')",
    'registerForm.formValidation.wrongUsernameNotCyrillic':
      'Имя пользователя должно содержать только латинские буквы и цифры',

    'passport.history.signDateColumn': 'Дата подписи',
    'passport.signSubTitle': 'Подпишите данные заполненного паспорта организации.',
    'passport.regInfo.loadData': 'Загрузить данные с:',
    'passport.regInfo.license': 'Данные по лицензиям',

    'passportOrg.accordion.orgData': 'Данные организации',
    'passportOrg.accordion.bin': 'БИН',
    'passportOrg.accordion.name': 'Название организации образования',
    'passportOrg.accordion.kk_fullName': 'Полное наименование на казахском',
    'passportOrg.accordion.kk_shortName': 'Краткое наименование на казахском',
    'passportOrg.accordion.kk_latin_fullName':
      'Полное наименование на казахском (латиница)',
    'passportOrg.accordion.kk_latin_shortName':
      'Краткое наименование на казахском (латиница)',
    'passportOrg.accordion.ru_fullName': 'Полное наименование на русском',
    'passportOrg.accordion.ru_shortName': 'Краткое наименование на русском',
    'passportOrg.accordion.en_fullName': 'Полное наименование на английском',
    'passportOrg.accordion.en_shortName': 'Краткое наименование на английском',
    'passportOrg.accordion.shortInfo': 'Тип организации образования (обязательное)',
    'passportOrg.totalPercentage': 'Общий процент заполненности',
    'passportOrg.signed': 'Подписано!',
    'passportOrg.signedError': 'Не удалось подписать паспорт',
    'passportOrg.personal': 'Персонал',
    'passportOrg.contingent': 'Контингент',

    'queueKindergarten.all': 'Общая очередь',
    'queueKindergarten.special': 'Очередь в специальные дошкольные организации',
    'queueKindergarten.sanatornye': 'Очередь в санаторные дошкольные организации',
    'queueKindergarten.archive': 'Архив',
    'queueKindergarten.table.id': '№ заявления',
    'queueKindergarten.table.iin': 'ИИН контингента',
    'queueKindergarten.table.lastName': 'Фамилия контингента',
    'queueKindergarten.table.firstName': 'Имя контингента',
    'queueKindergarten.table.middleName': 'Отчество контингента',
    'queueKindergarten.table.birthDate': 'Дата рождения',
    'queueKindergarten.table.queueType': 'Тип очереди',
    'passportOrg.queueKindergarten': 'Очередь в дошкольные организации',
    'ducks.queueKindergarten.cantloadQueue': 'Не удалось загрузить список очереди',
    'queueKindergartenView.cantloadQueue': 'Не удалось загрузить данные данной очереди',
    'queueKindergarten.cantloadQueueTypes': 'Не удалось загрузить типы очередей',
    'queueKindergarten.removed': 'Данное заявление удалено с очереди',
    'queueKindergartenView.createdAt': 'Запись создана',
    'queueKindergartenView.updatedAt': 'Данные о заявлении изменены',
    'queueKindergartenView.studentUpdatedAt': 'Персональные данные изменены',
    'queueKindergartenView.noData': 'Нет данных',

    'miopassport.signSubTitle': 'Подпишите данные заполненного паспорта МИО.',
    'miopassport.signed': 'Подписка выполнена!',

    'vseobuch.tab.covered': 'Охват детей обучением',
    'vseobuch.tab.notVisiting': 'Дети, не посещающие организацию образования',
    'vseobuch.button.history': 'История',
    'vseobuch.signSubTitle': 'Подпишите заполненные данные.',
    'vseobuch.covered.table.id': '№',
    'vseobuch.covered.table.iin': 'ИИН',
    'vseobuch.covered.table.secondName': 'Фамилия',
    'vseobuch.covered.table.firstName': 'Имя',
    'vseobuch.covered.table.thirdName': 'Отчество',
    'vseobuch.covered.table.birthDate': 'Дата рождения',
    'vseobuch.covered.table.address': 'Домашний адрес, город',
    'vseobuch.covered.table.organization': 'Наименование организации образования',
    'vseobuch.covered.table.group': 'Класс/Группа/Курс',
    'vseobuch.covered.table.reason': 'Причина не охвата',
    'vseobuch.covered.table.measures': 'Принятые меры',
    'vseobuch.covered.table.note': 'Примечание',
    'vseobuch.covered.table.upload': 'Прикрепить',
    'vseobuch.covered.table.options.childCovered': 'Дети, охваченные обучением',
    'vseobuch.covered.table.options.childNotCovered': 'Дети, не охваченные обучением',
    'vseobuch.covered.select.age': 'Выбрать возрастную категорию',
    'vseobuch.covered.select.coverage': 'Охват детей',
    'vseobuch.covered.button.makeReport': 'Найти',
    'vseobuch.covered.button.export': 'Скачать',
    'vseobuch.covered.button.save': 'Сохранить',
    'vseobuch.covered.button.print': 'Распечатать',
    'vseobuch.notVisited.table.iin': 'ИИН',
    'vseobuch.notVisited.table.firstName': 'Имя',
    'vseobuch.notVisited.table.lastName': 'Фамилия',
    'vseobuch.notVisited.table.middleName': 'Отчество',
    'vseobuch.notVisited.table.birthDate': 'Дата рождения',
    'vseobuch.notVisited.table.grade': 'Класс/Группа/Курс',
    'vseobuch.notVisited.table.fromDate': 'С какого дня не посещает',
    'vseobuch.notVisited.table.toDate': 'Возвращен в организацию среднего образования',
    'vseobuch.notVisited.table.familyState': 'Социальное положение семьи',
    'vseobuch.notVisited.table.reason': 'Причины не посещения',
    'vseobuch.notVisited.table.measures': 'Принятые меры',
    'vseobuch.notVisited.table.note': 'Примечание',
    'vseobuch.notVisited.table.actions': 'Действия',
    'vseobuch.notVisited.table.delete': 'Удалить',
    'vseobuch.notVisited.table.upload': 'Прикрепить',
    'vseobuch.notVisited.addStudent': 'Добавить ученика',
    'vseobuch.notVisited.save': 'Сохранить',
    'vseobuch.notVisited.download': 'Скачать',
    'vseobuch.notVisited.print': 'Распечатать',
    'vseobuch.notVisited.search': 'Поиск',
    'vseobuch.notVisited.saveInfo': 'Для сохранения данных, необходимо подписать ЭЦП',
    'vseobuch.notVisited.sign': 'Подписать ЭЦП',
    'vseobuch.notVisited.add': 'Добавить',
    'vseobuch.notVisited.cancel': 'Отмена',
    'vseobuch.notVisited.adding': 'Добавить контингент',
    'vseobuch.notVisited.searchByFullName': 'Поиск по ФИО и дате рождения',
    'vseobuch.notVisited.searchByIin': 'Поиск по ИИН',
    'vseobuch.notVisited.studentRemoved': 'Контингент успешно удален!',
    'vseobuch.notVisited.studentAdded': 'Данные сохранены!',
    'vseobuch.notVisited.alreadyAdded':
      'Введенный ИИН уже числится в данной организации!',
    'vseobuch.noFile': 'Нет вложенных файлов',
    'vseobuch.alt': 'Вложенный файл',
    'vseobuch.covered.csv.iin': 'ИИН',
    'vseobuch.covered.csv.lastName': 'Фамилия',
    'vseobuch.covered.csv.firstName': 'Имя',
    'vseobuch.covered.csv.middleName': 'Отчество',
    'vseobuch.covered.csv.birthDate': 'Дата рождения',
    'vseobuch.covered.csv.address': 'Домашний адрес, город',
    'vseobuch.covered.csv.nameOO': 'Наименование организации образования',
    'vseobuch.covered.csv.grade': 'Класс/Группа/Курс',
    'vseobuch.covered.csv.reason': 'Причина не охвата',
    'vseobuch.covered.csv.measures': 'Принятые меры',
    'vseobuch.covered.csv.note': 'Примечание',
    'vseobuch.notVisited.csv.iin': 'ИИН',
    'vseobuch.notVisited.csv.lastName': 'Фамилия',
    'vseobuch.notVisited.csv.firstName': 'Имя',
    'vseobuch.notVisited.csv.middleName': 'Отчество',
    'vseobuch.notVisited.csv.birthDate': 'Дата рождения',
    'vseobuch.notVisited.csv.grade': 'Класс/Группа/Курс',
    'vseobuch.notVisited.csv.fromDate': 'С какого дня не посещает',
    'vseobuch.notVisited.csv.toDate': 'Возвращен в организацию среднего обравзования',
    'vseobuch.notVisited.csv.reason': 'Причина не посещения',
    'vseobuch.notVisited.csv.measures': 'Принятые меры',
    'vseobuch.notVisited.csv.note': 'Примечание',
    'vseobuch.signing': 'Выполняется подпись',
    'vseobuch.cantSign': 'Не удалось подписать данные',
    'vseobuch.cantLoadForSign': 'Не удалось получить данные для подписи',
    'vseobuch.signDone': 'Подпись успешно выполнена',
    'vseobuch.signFailed': 'Подпись не выполнена',
    'vseobuch.history': 'История всеобуч',
    'vseobuch.withoutChanges': 'Нет изменений',

    'cameralControl.Checklist.options.yes': 'Да',
    'cameralControl.Checklist.options.no': 'Нет',
    'cameralControl.Checklist.input.numberAkt': '№ акта о назначении проверки',
    'cameralControl.Checklist.input.dateAkt': 'Дата акта о назначении проверки',
    'cameralControl.checklistReport.title': 'Проверочный лист',
    'cameralControl.checklistReport.appointedBy':
      'Государственный орган, назначивший проверку - Департамент по контролю в сфере образования и науки Министерства образования и науки Республики Казахстан',
    'cameralControl.checklistReport.akt': 'Акт о назначении проверки',
    'cameralControl.checklistReport.numAndDate': '(№, дата)',
    'cameralControl.checklistReport.name': 'Наименование проверяемого субъекта (объекта)',
    'cameralControl.checklistReport.bin':
      'Бизнес-идентификационный код (БИН) проверяемого субъекта (объекта)',
    'cameralControl.checklistReport.address': 'Адрес места нахождения',
    'cameralControl.checklistReport.executive':
      'Должностное лицо (фамилия, имя, отчество (при его наличии))',
    'cameralControl.DKSONEmployee.select.statusSort': 'Сортировка',
    'cameralControl.DKSONEmployee.select.statusAll': 'Все',
    'cameralControl.DKSONEmployee.select.statusHistory': 'Прошли проверку',
    'cameralControl.DKSONEmployee.select.statusDate': 'Дата акта о назначении проверки',
    'cameralControl.DKSONEmployee.select.statusDateFrom': 'С',
    'cameralControl.DKSONEmployee.select.statusDateTo': 'До',
    'cameralControl.DKSONEmployee.table.name': 'Наименование организации образования',
    'cameralControl.DKSONEmployee.table.location': 'Пункт',
    'cameralControl.DKSONEmployee.history.title':
      'История камерального государственного контроля',
    'cameralControl.DKSONEmployee.history.table.no': '№',
    'cameralControl.DKSONEmployee.history.table.numberAkt':
      '№ акта о назначении проверки',
    'cameralControl.DKSONEmployee.history.table.dateAkt':
      'Дата акта о назначении проверки',
    'cameralControl.DKSONEmployee.history.table.status': 'Статус',
    'cameralControl.DKSONEmployee.history.table.status.new': 'Новый',
    'cameralControl.DKSONEmployee.history.table.status.waitsign': 'На подписании',
    'cameralControl.DKSONEmployee.history.table.status.history': 'Подписано',
    'cameralControl.DKSONEmployee.history.button.create': 'Создать проверочный лист',
    'cameralControl.DKSONEmployee.createChecklist.save': 'Сохранить',
    'cameralControl.DKSONEmployee.createChecklist.sign': 'Отправить на подписание',
    'cameralControl.DKSONDirector.tab.unsignedChecklists': 'Требующие подписание',
    'cameralControl.DKSONDirector.tab.signedChecklists': 'Подписанные',
    'cameralControl.DKSONDirector.unsignedChecklists.note':
      'Есть документы, ожидающие подписания!',
    'cameralControl.DKSONDirector.unsignedChecklists.table.no': '№',
    'cameralControl.DKSONDirector.unsignedChecklists.table.orgName':
      'Наименование организации образования',
    'cameralControl.DKSONDirector.unsignedChecklists.table.numberAkt':
      '№ акта о назначении проверки',
    'cameralControl.DKSONDirector.unsignedChecklists.table.dateAkt':
      'Дата акта о назначении проверки',
    'cameralControl.DKSONDirector.unsignedChecklists.table.checkListProject':
      'Проект проверочного листа',
    'cameralControl.DKSONDirector.unsignedChecklists.table.checkListProject.value':
      'Проект',
    'cameralControl.DKSONDirector.signedChecklists.table.no': '№',
    'cameralControl.DKSONDirector.signedChecklists.table.orgName':
      'Наименование организации образования',
    'cameralControl.DKSONDirector.signedChecklists.table.numberAkt':
      '№ акта о назначении проверки',
    'cameralControl.DKSONDirector.signedChecklists.table.dateAkt':
      'Дата акта о назначении проверки',
    'cameralControl.DKSONDirector.signedChecklists.table.checkList': 'Проверочный лист',
    'cameralControl.DKSONDirector.signedChecklists.table.checkList.value': 'Скачать',
    'cameralControl.DKSONDirector.signedChecklists.select.statusDate':
      'Дата акта о назначении проверки',
    'cameralControl.DKSONDirector.signedChecklists.select.statusDateFrom': 'С',
    'cameralControl.DKSONDirector.signedChecklists.select.statusDateTo': 'До',
    'cameralControl.DKSONDirector.signChecklist.saveAndSign': 'Сохранить и подписать',
    'cameralControl.KKSONEmployee.title': 'Территориальная принадлежность',
    'cameralControl.KKSONEmployee.passed.title':
      'Организации образования, прошедшие проверку',
    'cameralControl.KKSONEmployee.passed.table.name':
      'Наименование организации образования',
    'cameralControl.KKSONEmployee.passed.table.location': 'Населённый пункт',
    'cameralControl.SchoolEmployee.history.title':
      'История камерального государственного контроля',
    'cameralControl.SchoolEmployee.history.table.no': '№',
    'cameralControl.SchoolEmployee.history.table.numberAkt':
      '№ акта о назначении проверки',
    'cameralControl.SchoolEmployee.history.table.dateAkt':
      'Дата акта о назначении проверки',
    'cameralControl.SchoolEmployee.history.downloadChecklist.view':
      'Просмотреть проверочный лист',
    'cameralControl.SchoolEmployee.history.downloadChecklist.download':
      'Скачать проверочный лист',
    'cameralControl.sign.success': 'Подписано!',
    'cameralControl.sign.error': 'Не удалось подписать!',
    'cameralControl.verify.error': 'Ошибка проверки подписи!',

    'admin.addUser': '+ Добавить пользователя',
    'admin.table.id': 'ID',
    'admin.table.schoolId': 'ID организации',
    'admin.table.username': 'Имя пользователя',
    'admin.table.email': 'Электронная почта',
    'admin.table.firstName': 'Имя',
    'admin.table.lastName': 'Фамилия',
    'admin.table.middleName': 'Отчество',
    'admin.table.organization': 'Организация',
    'admin.table.active': 'Активация/деактивация',
    'admin.table.active.selectAll': 'Все',
    'admin.table.active.selectActivated': 'Активированные',
    'admin.table.active.selectDeactivated': 'Неактивированные',
    'admin.table.action': 'Действие',
    'admin.table.action.delete': 'Удалить',
    'admin.role.SCHOOL_EMPLOYEES': 'Сотрудник или руководитель организации образования',
    'admin.role.MINISTRY_EMPLOYEES': 'Пользователи МОН РК',
    'admin.role.MIO_EMPLOYEES': 'Пользователь управления или отдела образования',
    'admin.role.KKSON_EMPLOYEES':
      'Пользователь комитета по контролю в сфере образования и науки',
    'admin.role.DKSON_EMPLOYEES':
      'Пользователь департамента по контролю в сфере образования',
    'admin.role.MIO_EMPLOYEES.cat_1': 'Сотрудник или руководитель управления образования',
    'admin.role.MIO_EMPLOYEES.cat_7': 'Сотрудник или руководитель отдела образования',
    'admin.header.role': 'Роль',
    'admin.header.typeOO': 'Тип организации',
    'admin.header.settlement': 'Населенный пункт',
    'admin.header.region': 'Область',
    'admin.confirmModal.title': 'Удаление',
    'admin.confirmModal.content': 'Вы хотите удалить',
    'admin.confirmModal.yes': 'Удалить',
    'admin.confirmModal.no': 'Отмена',

    'profile.username': 'Имя пользователя',
    'profile.iin': 'ИИН',
    'profile.email': 'Адрес электронной почты',
    'profile.firstName': 'Имя',
    'profile.lastName': 'Фамилия',
    'profile.middleName': 'Отчество',
    'profile.active': 'Активен',
    'profile.regDate': 'Дата регистрации',
    'profile.btn': 'Подписать и сохранить',
    'profile.lastSign': 'Время последнего входа',
    'profile.lastPassChange': 'Время последней смены пароля',
    'profile.errorTimeAutch': 'Дата последней не успешной попытки авторизации',
    'profile.changePassword': 'Сменить пароль',
    'profile.iinLength': 'ИИН должен состоять из 12 чисел',
    'profile.requiredFields': '"Организация" и "Роль" должны быть заполнены!',
    'profile.successfullyUpdated': 'Данные сохранены',
    'profile.changePassword.newPassword': 'Новый пароль',
    'profile.changePassword.ok': 'Ок',
    'profile.changePassword.cancel': 'Отменить',
    'profile.changePassword.invalid':
      'Пароль должен быть длиной не менее 8 символов и включать цифры, латинские буквы (a-z и A-Z), не буквенные и не цифровые символы (например, "#", "!" или "&")',

    'GBDFL.validation.first': 'Необходимо запросить данные с ГБД ФЛ',

    'personal.validation.yearsOld': 'Нельзя добавить сотрудника моложе 16-ти лет',

    'contingent.validation.yearsOld': 'Возраст контингента от 5 месяцев по 15 лет',

    'statistics.select.title': 'Статистика',
    'statistics.select.statistics.filledPassports': 'Заполнение паспортов',
    'statistics.select.statistics.signedPassports': 'Подпись паспортов',
    'statistics.tab.eduOrg': 'Организации образования',
    'statistics.tab.eduMng': 'Управления/Отделы образования',
    'statistics.tab.eduControlMng': 'Управления образования',
    'statistics.tab.eduDepartmentMng': 'Отделы образования',
    'statistics.button.export': 'Экспорт в Excel',
    'statistics.FilledPassports.EduOrg.rootName': 'Республика Казахстан',
    'statistics.FilledPassports.EduOrg.table.total': 'Всего',
    'statistics.FilledPassports.EduOrg.table.filled': 'Заполнено',
    'statistics.FilledPassports.EduOrg.table.filledTotalCount': '(заполнено/всего)',
    'statistics.FilledPassports.EduOrg.table.name': 'Наименование',
    'statistics.FilledPassports.EduOrg.table.mio': 'МИО',
    'statistics.FilledPassports.EduOrg.table.typeOO': 'Тип ОО',
    'statistics.FilledPassports.EduOrg.table.totalCount': 'Всего ОО',
    'statistics.FilledPassports.EduOrg.table.filledCount': 'Заполнено ОО',
    'statistics.FilledPassports.EduOrg.table.personnel': 'Персонал',
    'statistics.FilledPassports.EduOrg.table.contingent': 'Контингент',
    'statistics.FilledPassports.EduOrg.table.section.3': 'Регистрационные сведения',
    'statistics.FilledPassports.EduOrg.table.section.10': 'Сведения о здании',
    'statistics.FilledPassports.EduOrg.table.section.12': 'Библиотека/Учебники',
    'statistics.FilledPassports.EduOrg.table.section.13': 'Кабинеты/Классы/Аудитории',
    'statistics.FilledPassports.EduOrg.table.section.18': 'Компьютеризация',
    'statistics.FilledPassports.EduOrg.table.section.20': 'Столовая',
    'statistics.FilledPassports.EduOrg.table.section.36':
      'Дополнительные сведения по МТБ',
    'statistics.FilledPassports.EduOrg.table.section.21':
      'Основные сведения об образовательном процессе',
    'statistics.FilledPassports.EduOrg.table.section.22':
      'Основные сведения об объекте образования',
    'statistics.FilledPassports.EduOrg.table.section.26': 'Общежитие/интернат',
    'statistics.FilledPassports.EduOrg.table.section.44':
      'Дополнительные сведения о кадрах',
    'statistics.FilledPassports.EduOrg.table.section.45':
      'Дополнительные сведения о контингенте',
    'statistics.FilledPassports.EduOrg.table.section.81': 'Выпускники',
    'statistics.FilledPassports.EduOrg.table.section.185': 'Аккредитация',
    'statistics.FilledPassports.EduOrg.table.section.186':
      'Инновационная деятельность ВУЗа',
    'statistics.FilledPassports.EduOrg.table.section.197': 'Финансирование',
    'statistics.FilledPassports.EduOrg.table.section.198': 'Попечительские советы',
    'statistics.FilledPassports.EduOrg.table.section.199': 'Социальное партнерство',
    'statistics.FilledPassports.EduOrg.table.section.125':
      'Ответственный за паспортизацию',
    'statistics.FilledPassports.EduOrg.table.section.cameral_control':
      'Камеральный контроль',
    'statistics.FilledPassports.EduOrg.table.section.strict_reporting_documents':
      'Документы строгой отчетности',
    'statistics.FilledPassports.ControlMngOrg.table.total': 'Всего',
    'statistics.FilledPassports.ControlMngOrg.table.filled': 'Заполнено',
    'statistics.FilledPassports.ControlMngOrg.table.filledTotalCount':
      '(заполнено/всего)',
    'statistics.FilledPassports.ControlMngOrg.table.name': 'Наименование',
    'statistics.FilledPassports.ControlMngOrg.table.totalCount': 'Всего УО',
    'statistics.FilledPassports.ControlMngOrg.table.filledCount': 'Заполнено УО',
    'statistics.FilledPassports.ControlMngOrg.table.personnel': 'Персонал',
    'statistics.FilledPassports.ControlMngOrg.table.section.registration_info':
      'Регистрационные сведения',
    'statistics.FilledPassports.ControlMngOrg.table.section.166':
      'Сведения о мерах, предпринимаемых для решения проблемы аварийности организаций образования',
    'statistics.FilledPassports.ControlMngOrg.table.section.145':
      'Данные об организациях образования',
    'statistics.FilledPassports.ControlMngOrg.table.section.167':
      'Сведения о населенных пунктах, где отсутствуют организации образования',
    'statistics.FilledPassports.ControlMngOrg.table.section.168':
      'Данные об организациях дополнительного образования',
    'statistics.FilledPassports.ControlMngOrg.table.section.169':
      'Дополнительные сведения',
    'statistics.FilledPassports.ControlMngOrg.table.section.127':
      'Ответственный за паспортизацию',
    'statistics.FilledPassports.ControlMngOrg.table.section.165': 'Охрана прав детей',
    'statistics.FilledPassports.ControlMngOrg.table.section.194':
      'Данные о дошкольных организациях',
    'statistics.FilledPassports.ControlMngOrg.table.section.201': 'Финансирование',
    'statistics.FilledPassports.ControlMngOrg.table.section.225': 'Занятость',
    'statistics.FilledPassports.ControlMngOrg.table.section.208':
      'Финансирование (Летний отдых)',
    'statistics.FilledPassports.DepartmentMngOrg.table.total': 'Всего',
    'statistics.FilledPassports.DepartmentMngOrg.table.filled': 'Заполнено',
    'statistics.FilledPassports.DepartmentMngOrg.table.filledTotalCount':
      '(заполнено/всего)',
    'statistics.FilledPassports.DepartmentMngOrg.table.name': 'Наименование',
    'statistics.FilledPassports.DepartmentMngOrg.table.totalCount': 'Всего ГОО/РОО',
    'statistics.FilledPassports.DepartmentMngOrg.table.filledCount': 'Заполнено ГОО/РОО',
    'statistics.FilledPassports.DepartmentMngOrg.table.personnel': 'Персонал',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.registration_info':
      'Регистрационные сведения',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.61':
      'Сведения о мерах, предпринимаемых для решения проблемы аварийности организаций образования',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.121':
      'Данные об организациях образования',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.122':
      'Сведения о населенных пунктах, где отсутствуют организации образования',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.123':
      'Данные об организациях дополнительного образования',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.124':
      'Дополнительные сведения',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.126':
      'Ответственный за паспортизацию',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.195': 'Охрана прав детей',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.196':
      'Данные о дошкольных организациях',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.200': 'Финансирование',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.207': 'Занятость',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.209':
      'Финансирование (Летний отдых)',
    'statistics.SignedPassports.EduOrg.rootName': 'Республика Казахстан',
    'statistics.SignedPassports.EduOrg.table.name': 'Наименование',
    'statistics.SignedPassports.EduOrg.table.mio': 'МИО',
    'statistics.SignedPassports.EduOrg.table.typeOO': 'Тип ОО',
    'statistics.SignedPassports.EduOrg.table.signDate': 'Дата подписи',
    'statistics.SignedPassports.EduOrg.table.signWho': 'Кем подписано',
    'statistics.SignedPassports.EduOrg.table.filled': 'Заполненость (в %)',
    'statistics.SignedPassports.MngOrg.table.name': 'Наименование',
    'statistics.SignedPassports.MngOrg.table.signDate': 'Дата подписи',
    'statistics.SignedPassports.MngOrg.table.signWho': 'Кем подписано',
    'statistics.SignedPassports.MngOrg.table.filled': 'Заполненость (в %)',

    'ui.dynamic.document.filePath': 'Путь к файлу',
    'ui.dynamic.document.chooseFile': 'Выбрать файл',
    'ui.dynamic.document.noFileChosen': 'Файл не выбран',
    'ui.dynamic.document.file': 'Файл',
    'ui.dynamic.document.cantViewFile': 'Невозможно просмотреть файл, попробуйте скачать',
    'ui.dynamic.document.attachment': 'Вложенный файл',
    'ui.dynamic.dTable.buildingsInfo': 'Сведения о зданиях',
    'ui.dynamic.dTable.building': 'Здание',
    'ui.dynamic.dTable.deleteBuilding': 'Удалить здание',
    'ui.dynamic.dTable.addBuilding': 'Добавить здание',
    'ui.dynamic.dTable.action': 'Действия',
    'ui.dynamic.dTable.delete': 'Удалить',
    'ui.dynamic.dTable.addString': 'Добавить строку',
    'ui.dynamic.dTable.emptyList.part1': 'Начните заполнение',
    'ui.dynamic.dTable.emptyList.part2': 'нажав на кнопку "Добавить строку +"',
    'ui.dynamic.dTable.emptyList.part3': 'Если у вас нет',
    'ui.dynamic.dTable.emptyList.part4':
      'то пропустите заполнение этого блока информации',
    'ui.expComponent.collapse': 'Свернуть все',
    'ui.expComponent.expand': 'Развернуть все',
    'ui.row.source': 'Источник',
    'ui.select.placeholder': 'Выберите значение',
    'ui.select.loading': 'Загрузка...',
    'ui.select.noOptions': 'Нет данных',
    'ui.staticTable.collapse': 'Свернуть таблицу',
    'ui.staticTable.expand': 'Развернуть таблицу',
    'ui.staticTablePagination.load': 'Загрузить еще',
    'ui.staticTablePagination.goToPage': 'Перейти на страницу',
    'ui.loadingData.reload': 'Обновить страницу',

    'errors.UNKNOWN_ERROR': 'Неизвестная ошибка',
    'errors.CONNECTION': 'Нет подключения к интернету',
    'errors.FAIL': 'Неудачный запрос',
    'errors.SERVER_ERROR': 'Ошибка сервера, попробуйте повторить операцию позже',
    'errors.BAD_GATEWAY':
      'В данный момент ведутся технические работы.\n Попробуйте войти позже',
    'errors.NETWORK_TIMEOUT': 'Ошибка соединения, сервер не отвечает',
    'errors.NETWORK_ERROR': 'Ошибка, соединение прервано',
    'errors.NETWORK_PARAMS': 'Попробуте проверить параметры подключения к интернету.',
    'errors.INCORRECT_USERNAME_PASSWORD':
      'Неправильное имя пользователя или пароль, попробуйте снова',
    'errors.MULTISESSION_NOT_ALLOWED':
      'Пользователь под данной учетной записью уже авторизован',
    'errors.ALREADY_EXISTS':
      'Имя пользователя уже существует, создайте новый или попробуйте войти в систему по существующей учетной записи',
    'errors.SIGNATURE_VERIFICATION_FAIL':
      'ИИН вашей цифровой подписи не совпадает с ИИН пользователя',
    'errors.USER_NOT_ACTIVE': 'Пользователь не активирован',
    'errors.AUTH_SERVICE_IS_UNAVAILABLE': 'Сервис авторизации не доступен',
    'errors.INVALID_TOKEN': 'Недействительный токен',
    'errors.REQUIRED_ATTRIBUTES_ARE_NOT_FILLED':
      'Не заполнены обязательные аттрибуты документа',
    'errors.PASSWORD_ATTEMPTS_EXCEEDED':
      'Учетная запись пользователя заблокирована из-за неверно введенного логин/пароля.',
    'errors.PASSWORD_IS_EXPIRED': 'Истек срок действия пароля',
    'errors.NEW_PASSWORD_IS_EQUALS_TO_PREVIOUS':
      'Запрещено использование трех последних паролей',

    'errors.NCALayer_CONNECTION': 'Нет подключения с программой NCALayer',
    'errors.activation_failed': 'Не удалось выполнить операцию',
    'errors.cantLoadFlk': 'Не удалось загрузить скрипт ФЛК rule.js',
    'errors.cantLoadProfile': 'Не удалось загрузить данные профиля',
    'errors.typeOOFor': 'Вид организации образования для',
    'errors.notFound': 'не найден',

    'errors.common_0001':
      'Сервис в данный момент недоступен. Пожалуйста, попробуйте позже',
    'errors.common_0002': 'Ресурс не найден',
    'errors.common_0003':
      'Внешний сервис временно недоступен. Свяжитесь со службой поддержки',
    'errors.common_0004': 'Проверка запроса не удалась',
    'errors.common_0005': 'Внутренняя общая ошибка. Свяжитесь со службой поддержки',
    'errors.common_0006':
      'Сервис в данный момент недоступен. Пожалуйста, попробуйте позже',
    'errors.common_0007': 'Внутренняя общая ошибка. Свяжитесь со службой поддержки',
    'errors.common_0008': 'Внутренняя общая ошибка. Свяжитесь со службой поддержки',
    'errors.common_0009':
      'Авторизация не удалась. Действие запрещено для текущего пользователя',
    'errors.common_0010':
      "External service returned data which couldn't be interpreted correctly. Please contact OMC User Support",

    'errors.entitlement_0001':
      'Проверка токена не удалась. Пожалуйста проверьте имя пользователя и пароль, и попробуйте еще раз',
    'errors.entitlement_0002': 'Ссылка для смены или сброса пароля не активна',
    'errors.entitlement_0003': 'Пользователь с таким именем уже существует',
    'errors.entitlement_0004':
      'В базе данных есть связи с сущностью, которую пытаетесь удалить',
    'errors.entitlement_0005': 'Проверка подлинности подписи пользователя не удалась',
    'errors.entitlement_0006': 'Адрес электронной почты занят',
    'errors.entitlement_password_is_not_correct': 'Ваш пароль не совпадает с текущим',
    'errors.entitlement_passwords_are_not_equal': 'Пароли не совпадают',
    'errors.entitlement_user_username_is_null': 'Не заполнено имя пользователя',
    'errors.entitlement_user_iin_is_null': 'Не заполнен ИИН',
    'errors.entitlement_user_iin_is_busy': 'Пользователь с таким ИИН уже существует',
    'errors.entitlement_user_profile_data_registration_role_does_not_exist':
      'Роль пользователя не указана',
    'errors.entitlement_user_profile_data_school_id_does_not_exist':
      'Не указана организация образования',
    'errors.entitlement_teacher_does_not_work_at_school':
      'Вы не числитесь в персонале данной организации',
    'errors.entitlement_user_not_found_in_gbdfl': 'Не найден человек с таким ИИН в ГБДФЛ',
    'errors.entitlement_signature_verification': 'ЭЦП не валидна',
    'errors.entitlement_user_email_address_is_null': 'Не заполнен email',
    'errors.entitlement_user_email_address_is_busy': 'Этот email уже занят',
    'errors.entitlement_user_by_one_time_password_not_found':
      'Недействительный одноразовый пароль',
    'errors.entitlement_user_not_found': 'Пользователь не найден',
    'errors.error.runtime_job_info_already_registered':
      'Сотрудник уже зарегистрирован в данной организации',
    'errors.error.common_0004': 'Вы отправили не допустимые данные',
    'errors.error.entitlement_password_is_not_correct':
      'Ваш пароль не совпадает с текущим',
    'errors.error.entitlement_exists_current_at_users':
      'В данной организации образования имеется пользователь. Можно создать только одного пользователя.',
    'errors.error.runtime_business_object_type_is_unavailable':
      'Недопустимый тип бизнес объекта',
    'errors.error.runtime_company_data_not_found': 'Данные с ГБД ЮЛ не найдены',
    'errors.error.runtime_school_not_found': 'ОО не найдена',
    'errors.error.runtime_teacher_not_found': 'Сотрудник не найден',
    'errors.error.runtime_student_not_found': 'Контингент не найден',
    'errors.error.runtime_job_info_not_found': 'job не найден',
    'errors.error.runtime_education_info_not_found': 'education не найден',
    'errors.error.runtime_authenticated_user_not_found': 'Пользователь не авторизован',
    'errors.error.runtime_schools_are_equal':
      'ошибка. Школа out и школа in не могут быть равны',
    'errors.error.runtime_schools_are_not_equal': 'Ошибка. Школы должны быть равны',
    'errors.error.runtime_school_subtype_not_found':
      'Вид организации образования не найден',
    'errors.error.runtime_target_school_is_not_found':
      'Организация образования, в которую хочет поступить учащийся, не найдена',
    'errors.error.runtime_source_school_is_not_found':
      'ОО, из которой хочет выбыть студент, не найдена',
    'errors.error.runtime_school_must_not_be_null': 'Поле школа не может быть пустым',
    'errors.error.runtime_student_cannot_study_somewhere_else':
      'Ошибка, обучающийся не может учиться параллельно в другой организации образования',
    'errors.error.runtime_education_info_already_registered':
      'Контингент уже зарегистрирован',
    'errors.error.runtime_student_movement_is_unavailable':
      'Перемещение обучающегося в другую организацию образования запрещено',
    'errors.error.runtime_student_graduated_from_school': 'Обучающийся уже выпущен',
    'errors.error.runtime_education_status_is_incorrect':
      'неподходящий статус у студента',
    'errors.error.runtime_iin_must_not_be_null': 'ИИН не может быть пустым',
    'errors.error.runtime_bin_must_not_be_null': 'БИН не может быть пустым',
    'errors.error.runtime_ids_must_not_be_null': 'Список id не может быть пустым',
    'errors.error.runtime_signature_data_was_changed': 'данные в подписи были подменены',
    'errors.error.runtime_user_must_sing_own_signature':
      'ИИН пользователя не соответствует ЭЦП',
    'errors.error.runtime_signature_is_invalid': 'Ошибка при подписании',
    'errors.error.runtime_questionnaire_metadata_not_found': 'Метаданные не найдены',
    'errors.error.runtime_student_has_gone_previous_year':
      'Последнее перемещение обучающегося было не в этом году',
    'errors.error.runtime_student_cannot_enter_inside':
      'Обучающийся не может быть принят',
    'errors.error.runtime_cameral_control_does_not_exists_for_this_school':
      'School type code does not match for cameral control',
    'errors.error.runtime_cameral_control_can_not_create_repeat_for_this_school':
      'Cameral control can not create repeat for this school',
    'errors.error.runtime_page_size_and_page_number_must_be_more_than_zero':
      'Page size and page number must be more than zero',
    'errors.error.runtime_file_must_be_less_than_or_equal_to_one_megabyte':
      'Размер вложенного файла не должен превышать 1 Мб',
    'errors.error.runtime_school_cannot_be_closed':
      'Невозмжно закрыть организацию. Необходимо оформить выбытие/увольнение сотрудников и обучающихся в разделах персонал и контингент',
    'errors.scripts.syntax':
      'Синтаксическая ошибка в скрипте ФЛК "rules.js", обратитесь к администратору',
    'errors.scripts.not_exist_rules':
      'Ошибка в скрипте ФЛК "rules.js", отсутствуют следующие методы:',
    'errors.save.dynamic.form':
      'Возможно данные не сохранились. Нажмите на кнопку «Сохранить» ещё раз"',
    'errors.run.flk': 'Не работает ФЛК для показателя',
    'errors.run.withCode': 'с кодом',
    'errors.error.entitlement_signature_iin_not_equal_current':
      'ИИН пользователя не совпадает с ИИН в электронно-цифровой подписи',
    'errors.error.runtime_student_is_held_back':
      'Обучающийся не может быть перемещен, так как оставлен на повторный курс',
    'errors.sign_only_rsa_method':
      'Для регистрации пользователя необходимо использовать электронно-цифровую подпись (ЭЦП) НУЦ РК (физическое лицо)',
    'errors.error.runtime_education_coverage_not_found':
      'Не привязан микроучасток к данной организации образования',
    'errors.error.runtime_last_name_filter_must_be_more_than_or_equals_to_three_symbols':
      'В поле "Фамилия" должно быть больше двух символов',
    'errors.error.runtime_fist_name_filter_must_be_more_than_or_equals_to_three_symbols':
      'В поле "Имя" должно быть больше двух символов',
    'errors.error.runtime_middle_name_filter_must_be_more_than_or_equals_to_three_symbols':
      'В поле "Отчество" должно быть больше двух символов',
    'errors.error.runtime_not_found': 'Указанный адрес не существует',
    'errors.error.ddm_item_not_found':
      'Не найден элемент с кодом "{code}" в справочнике "{dataset}" ',
    'errors.error.runtime_school_cannot_be_deleted_because_has_contingents_and_personals':
      'Невозможно удалить организацию, так как есть записи по персоналу и контингенту.',
    'errors.error.runtime_school_cannot_be_deleted_because_has_contingents':
      'Невозможно удалить организацию, так как есть записи по контингенту.',
    'errors.error.runtime_school_cannot_be_deleted_because_has_personals':
      'Невозможно удалить организацию, так как есть записи по персоналу.',
    'errors.error.runtime_school_cannot_be_deleted_because_has_contraints':
      'Невозможно удалить организацию, так как есть записи по данной организации.',

    'warning.staff_personal': 'Возможен прием только по совместительству',

    'searchAddress.addressPartType': 'Тип составной части части населенного пункта',
    'searchAddress.addressPart': 'Наименование',
    'searchAddress.buildingNumber': 'Номер',
    'searchAddress.search': 'Поиск',
    'searchAddress.delete': 'Удалить',
    'searchAddress.add': 'Добавить',
    'searchAddress.added': 'Добавлено',
    'searchAddress.found': 'Найденные адреса',
    'searchAddress.rka': 'РКА',
    'searchAddress.action': 'Действие',
    'searchAddress.addresses': 'Добавленные адреса',

    'responsibleForPassportization.iin': 'ИИН',
    'responsibleForPassportization.request': 'Запросить данные сотрудника',

    'secondSidebar.contingent': 'Контингент',
    'secondSidebar.personal': 'Персонал',

    'support.title':
      'В случае технических неполадок и возникновении иных вопросов при работе с НОБД, запрос в техподдержку НОБД направлять строго в следующем формате:',
    'support.firstDemand':
      'Указать полное наименование организации образования, область/город/район/село, id организации, ответственное лицо (ФИО, должность), номер телефона, Имя пользователя (логин);',
    'support.secondDemand':
      'Обязательно указать: дата и время ошибки, описание причины, написать в каком разделе НОБД произошла ошибка,  приложить дополнительные сведения о характере проблемы (например указать ИИН и ФИО ребенка/сотрудника , student_id, educationinfo_id , teacher_id, jobinfo_id, по которым возник вопрос и т.п.).',
    'support.thirdDemand':
      'Какие действия совершаются - приложите скриншоты выполненных действий (зайти в раздел «название», нажать кнопку «название», отображается сообщение/ошибка/не реагирует).',
    'support.fourthDemand': 'Что необходимо выполнить в НОБД.',
    'support.send': 'Вышеуказанную информацию направить по адресу электронной почты',
    'support.contacts': 'Контактные телефоны',

    'infoSecurity.title':
      'В связи с требованиями обеспечения информационной безопасности:',
    'infoSecurity.point_1': 'срок действия пароля 60 дней;',
    'infoSecurity.point_2':
      'при смене пароля запрещено использование трех последних паролей;',
    'infoSecurity.point_3':
      'после 3 неудачных попыток авторизации учетная запись пользователя будет заблокирована;',
    'infoSecurity.point_4':
      'запрещено нескольким людям входить под одной учетной записью;',
    'infoSecurity.point_5': 'вход в НОБД производится с использованием ЭЦП.',

    'yandexMap.search': 'Поиск мест и адресов',
    'yandexMap.ready': 'Готово',
    'yandexMap.mapLoading': 'Идёт загрузка карты',
    'yandexMap.error': 'Не удалось загрузить карту',

    'trajectory.studentTrajectory': 'Траектория движения учащегося',
    'trajectory.studentDocie': 'Досье учащегося',
    'trajectory.trajectory': 'Траектория движения',
    'trajectory.searchForm.searchByInn': 'Поиск по ИИН',
    'trajectory.searchForm.searchByFio': 'Поиск по ФИО и дате рождения',
    'trajectory.searchForm.foundSubjects': 'Найденные субъекты образования',
    'trajectory.searchForm.notifInnSearchWasPerformed':
      'Результаты поиска по данному ИИН уже выданы!',
    'trajectory.searchForm.notifFioSearchWasPerformed':
      'Результаты поиска по введенным полям уже выданы!',
    'trajectory.searchForm.innForm.search': 'Поиск',
    'trajectory.searchForm.innForm.iin': 'ИИН',
    'trajectory.searchForm.fioForm.lastName': 'Фамилия',
    'trajectory.searchForm.fioForm.firstName': 'Имя',
    'trajectory.searchForm.fioForm.middleName': 'Отчество',
    'trajectory.searchForm.fioForm.birthDate': 'Дата рождения',
    'trajectory.searchForm.fioForm.search': 'Поиск',
    'trajectory.searchForm.popup.header.lastName': 'Фамилия',
    'trajectory.searchForm.popup.header.firstName': 'Имя',
    'trajectory.searchForm.popup.header.middleName': 'Отчество',
    'trajectory.searchForm.popup.header.birthDate': 'Дата рождения',
    'trajectory.searchForm.popup.header.regAddressCountry': 'Адрес регистрации',
    'trajectory.searchForm.popup.header.studentId': 'ID',
    'trajectory.searchForm.popup.select': 'Выбрать',
    'trajectory.searchForm.popup.cancel': 'Отмена',
    'trajectory.dossier.dossierButtonTitle': 'Скачать досье',
    'trajectory.dossier.iin': 'ИИН',
    'trajectory.dossier.fio': 'ФИО',
    'trajectory.dossier.birthDate': 'Дата рождения',
    'trajectory.dossier.status': 'Статус гражданства',
    'trajectory.dossier.regAddress': 'Адрес регистрации',
    'trajectory.dossier.currentState': 'Нынешнее состояние',
    'trajectory.dossier.employed': 'Трудоустроен',
    'trajectory.dossier.inOO': 'Числится в организации образования',
    'trajectory.dossier.notInOO': 'Не числится ни в одной из организаций образования',
    'trajectory.dossier.notFilled': '(наименование организации не заполнено)',
    'trajectory.dossier.groupName': 'Название группы',
    'trajectory.dossier.group': 'Группа',
    'trajectory.dossier.parallel': 'Параллель',
    'trajectory.dossier.litera': 'Литера',
    'trajectory.dossier.groupCode': 'Код группы',
    'trajectory.dossier.studentCourse': 'Студент (курс обучения)',
    'trajectory.dossier.profession': 'Специальность и классификатор (основной)',
    'trajectory.table.data': 'Данные по обучению / месту работы',
    'trajectory.table.progress': 'Успеваемость',
    'trajectory.table.docs': 'Документы об образовании',
    'trajectory.table.competitions': 'Участие в соревнованиях',
    'trajectory.table.sections': 'Посещает кружки и секции',
    'trajectory.table.health': 'Состояние здоровья',
    'trajectory.table.signs': 'Особые признаки',
    'trajectory.table.languages': 'Изучаемые языки',
    'trajectory.table.mainLanguage': 'Язык обучения',

    'contingent.createContingentModal.yes': 'Да',
    'contingent.createContingentModal.no': 'Нет',
    'contingent.createContingentModal.header': 'Контингент уже зарегистрирован',
    'contingent.createContingentModal.desc': 'Хотите перейти?',
    'contingent.arrivalModal.cancel': 'Отмена',
    'contingent.arrivalModal.confirm': 'Оформить прием',
    'contingent.arrivalTable.action': 'Действие',
    'contingent.arrivalTable.status': 'Статус',
    'contingent.arrivalTable.confirm': 'Оформить прием',
    'contingent.arrivalTable.deny': 'Отклонить прием',
    'contingent.arrivalTable.denied': 'Отклонен',
    'contingent.arrivalTable.restrictedAction':
      'Прием в 1 класс будет доступен с 25 августа текущего года',
    'contingent.denyModal.header': 'Отклонить приём',
    'contingent.denyModal.date': 'Дата отказа',
    'contingent.denyModal.reason': 'Причина отказа',
    'contingent.denyModal.deny': 'Отклонить прием',
    'contingent.denyModal.cancel': 'Отмена',
    'contingent.checkoutModal.header': 'Оформить выбытие',
    'contingent.checkoutModal.date': 'Дата выбытия',
    'contingent.checkoutModal.number': 'Номер приказа выбытия',
    'contingent.checkoutModal.reason': 'Причина выбытия',
    'contingent.checkoutModal.status': 'Статус',
    'contingent.checkoutModal.kato': 'КАТО',
    'contingent.checkoutModal.typeOO': 'Тип принимающей организаций',
    'contingent.checkoutModal.receiveOO': 'Принимающая организация',
    'contingent.checkoutModal.confirm': 'Оформить выбытие',
    'contingent.checkoutModal.cancel': 'Отмена',
    'contingent.checkoutModal.country': 'Страна выбытия',
    'contingent.checkoutModal.notice':
      'Внимание! Вы оформляете выбытие. Если вам нужно оформить выпуск, то нужно нажать на кнопку "Отмена" и далее нажать на кнопку "Оформить выпуск, перемещение или прием"',
    'contingent.checkoutModal.alreadyExist':
      'Контингент числится на прибытие в следующие школы по заявке родителя через веб-портал «электронного правительства»',
    'contingent.checkoutModal.schoolId': 'ID организации',
    'contingent.checkoutModal.schoolName': 'Наименование организации',
    'contingent.checkoutModal.phone': 'Телефон',
    'contingent.checkoutModal.location': 'Населенный пункт',
    'contingent.checkoutModal.contingentID': 'ID Контингента',
    'contingent.checkoutModal.select': 'Выбрать',
    'contingent.tableContingent.action': 'Действие',
    'contingent.tableContingent.confirm': 'Оформить выбытие',
    'contingent.tableContingent.educationForm': 'Изменить форму обучения',
    'contingent.disposalModal.header': 'Отозвать выбытие',
    'contingent.disposalModal.date': 'Дата отмены выбытия',
    'contingent.disposalModal.reason': 'Причина отмены выбытия',
    'contingent.disposalModal.confirm': 'Отозвать выбытие',
    'contingent.disposalModal.cancel': 'Отмена',
    'contingent.disposalTable.status': 'Статус',
    'contingent.disposalTable.canceled': 'Отклонен',
    'contingent.disposalTable.inProgress': 'В обработке',
    'contingent.disposalTable.action': 'Действие',
    'contingent.disposalTable.deny': 'Отозвать выбытие',
    'contingent.disposalTable.orgId': 'ID организации',
    'contingent.disposalTable.name': 'Наименование',
    'contingent.disposalTable.location': 'Населенный пункт',
    'contingent.disposalTable.phone': 'Рабочий телефон',
    'contingent.disposalTable.reason': 'Причина отклонения',
    'contingent.disposalTable.info':
      'Учащихся со статусом "Отклонен" необходимо отозвать и заново оформить выбытие',
    'contingent.disposalTable.importByES':
      'Контингент поступил по заявке родителя через веб-портал «электронного правительства» для приема в 1 класс',
    'contingent.integrations.title': 'Загрузить данные с',
    'contingent.integrations.personal': 'Персональные данные',
    'contingent.integrations.labor': 'Данные по трудоустройству',
    'contingent.integrations.socAssist': 'Данные о получении социальной помощи',
    'contingent.integrations.eisz': 'Данные о состоянии здоровья',
    'contingent.integrations.disability': 'Данные по инвалидности',
    'contingent.enterModal.header': 'Оформить прием',
    'contingent.enterModal.date': 'Дата приема',
    'contingent.enterModal.number': 'Номер приказа',
    'contingent.enterModal.confirm': 'Оформить прием',
    'contingent.enterModal.cancel': 'Отмена',
    'contingent.enterTable.confirm': 'Оформить прием',
    'contingent.notDisplacedModal.header': 'Оформить перемещение',
    'contingent.notDisplacedModal.course': 'Курс',
    'contingent.notDisplacedModal.grade': 'Параллель',
    'contingent.notDisplacedModal.gradeLetter': 'Литера',
    'contingent.notDisplacedModal.confirm': 'Оформить перемещение',
    'contingent.notDisplacedModal.cancel': 'Отмена',
    'contingent.notDisplacedModal.transition': 'Хотите оформить перемещение?',
    'contingent.notDisplacedModal.selectedCount': 'Количество выбранных : ',
    'contingent.notDisplacedTable.confirm': 'Переместить',
    'contingent.notDisplacedTable.info':
      'В данной вкладке "Перемещение" отображается список учащихся, у которых заполнены все показатели',
    'contingent.motionReleaseModal.header': 'Оформить выпуск',
    'contingent.motionReleaseModal.released': 'Выпущен',
    'contingent.motionReleaseModal.date': 'Дата выбытия',
    'contingent.motionReleaseModal.type': 'Вид диплома',
    'contingent.motionReleaseModal.serial': 'Серия диплома',
    'contingent.motionReleaseModal.diplomaNumber': '№ диплома',
    'contingent.motionReleaseModal.number': 'Номер приказа выбытия',
    'contingent.motionReleaseModal.employment': 'Трудоустройство',
    'contingent.motionReleaseModal.upload': 'Загрузить скан копию диплома',
    'contingent.motionReleaseModal.confirm': 'Оформить выпуск',
    'contingent.motionReleaseModal.cancel': 'Отмена',
    'contingent.motionReleaseModal.disposalCountry': 'Страна выбытия',
    'contingent.motionReleaseTable.form': 'Класс',
    'contingent.motionReleaseTable.confirm': 'Оформить выпуск',
    'contingent.motionReleaseTable.info':
      'В данной вкладке "Выпуск" отображается список студентов, которым заполнили показатель "Серия и № диплома" и все обязательные показатели',
    'contingent.motionReleaseTable.infoType02':
      'В данной вкладке "Выпуск" отображается список учащихся, которым заполнили показатели "Аттестат о среднем образовании (Серия аттестата)/Аттестат об основном образовании (Серия аттестата)", "Аттестат о среднем образовании (Номер аттестата)/Аттестат об основном образовании (Номер аттестата)", "Аттестат о среднем образовании (Дата выдачи)/Аттестат об основном образовании (Дата выдачи) и все обязательные показатели',
    'contingent.educationFormModal.header': 'Изменение формы обучения',
    'contingent.educationFormModal.course': 'Курс',
    'contingent.educationFormModal.grade': 'Параллель',
    'contingent.educationFormModal.gradeLetter': 'Литера',
    'contingent.educationFormModal.confirm': 'Изменить форму обучения',
    'contingent.educationFormModal.cancel': 'Отмена',
    'contingent.educationFormModal.close': 'Закрыть',
    'contingent.educationFormModal.text': 'Хотите изменить форму обучения?',
    'contingent.educationFormModal.error':
      'Количество выбранных записей не может быть больше',
    'contingent.educationFormModal.selectedCount': 'Количество выбранных: ',
    'contingent.educationFormTable.confirm': 'Изменить форму обучения',
    'contingent.educationFormTable.placeholder': 'Форма обучения',
    'contingent.educationFormTable.showSelectionNote':
      'Для множественного выбора необходимо обязательно выбрать «Параллель» и «Литера»',
    'contingent.columnsTable.contingentID': 'ID Контингента',
    'contingent.columnsTable.iin': 'ИИН',
    'contingent.columnsTable.statistic': 'Статистика заполненности',
    'contingent.columnsTable.lastName': 'Фамилия',
    'contingent.columnsTable.firstName': 'Имя',
    'contingent.columnsTable.middleName': 'Отчество',
    'contingent.columnsTable.group': 'Группа',
    'contingent.columnsTable.groupName': 'Название группы',
    'contingent.columnsTable.parallel': 'Параллель',
    'contingent.columnsTable.litera': 'Литера',
    'contingent.columnsTable.course': 'Курс/Год',
    'contingent.columnsTable.groupCode': 'Код группы',
    'contingent.contingent': 'Контингент',
    'contingent.graduates': 'Выпускники',
    'contingent.retired': 'Выбывшие',
    'contingent.forRetire': 'На выбытие',
    'contingent.forIncoming': 'На прибытие',
    'contingent.add': 'Добавить контингент',
    'contingent.issue': 'Оформить выпуск, перемещение или прием',
    'contingent.awaitingRelease': 'Ожидают выпуска',
    'contingent.personal': 'Персональные данные',
    'contingent.delete': 'Удалить',
    'contingent.save': 'Сохранить',
    'contingent.error.loadCount': 'Не удалось получить данные о количестве контингента',
    'contingent.id': 'ID контингента',
    'contingent.entries': 'Показано {mapped} записей из {total}',
    'contingent.entriesSelected': 'Отмечено {mapped} из {total}',
    'contingent.entered': 'Контингент успешно принят',
    'contingent.notEntered': 'Не удалось принять контингент',
    'contingent.transited': 'Контингент успешно выбыл',
    'contingent.notTransited': 'Не удалось выбыть контингент',
    'contingent.disposed': 'Контингент успешно отозван',
    'contingent.notDisposed': 'Не удалось отозвать контингент',
    'contingent.approved': 'Контингент успешно принят',
    'contingent.notApproved': 'Не удалось принять контингент',
    'contingent.withdrawed': 'Контингент успешно отклонён',
    'contingent.notWithdrawed': 'Не удалось отклонить контингент',
    'contingent.graduated': 'Контингент успешно выпущен',
    'contingent.notGraduated': 'Не удалось выпустить контингент',
    'contingent.internalTransited': 'Перемещение прошло успешно',
    'contingent.notInternalTransited': 'Не удалось переместить контингент',
    'contingent.successChangeEducationForm': 'Форма обучения успешно изменена',
    'contingent.errorChangeEducationForm': 'Не удалось изменить форму обучения',
    'contingent.attention': 'Внимание',
    'contingent.needSave': 'Необходимо сохранить паспорт',
    'contingent.createdAt': 'Запись создана',
    'contingent.updatedAt': 'Данные об образовании изменены',
    'contingent.studentUpdatedAt': 'Персональные данные изменены',
    'contingent.noData': 'Нет данных',
    'contingent.warning.otherSpecialty':'Возможен прием только по другой специальности или уровню образования',

    'createSchool.fields.yes': 'Да',
    'createSchool.fields.no': 'Нет',
    'createSchool.fields.bin': 'БИН',
    'createSchool.fields.iin': 'ИИН',
    'createSchool.form.hasBin': 'Наличие БИН/ИИН',
    'createSchool.form.bin': 'БИН организации образования',
    'createSchool.form.iin': 'ИИН',
    'createSchool.form.request': 'Запросить данные из ГБД ЮЛ',
    'createSchool.form.request.iin': 'Запросить данные из ГБД ФЛ',
    'createSchool.form.kkName': 'Наименование на казахском',
    'createSchool.form.kkShortName': 'Краткое наименование на казахском',
    'createSchool.form.kkFullName': 'Полное наименование на казахском',
    'createSchool.form.kkLatinName': 'Наименование на казахском (латиница)',
    'createSchool.form.kkLatinShortName': 'Краткое наименование на казахском (латиница)',
    'createSchool.form.kkLatinFullName': 'Полное наименование на казахском (латиница)',
    'createSchool.form.ruName': 'Наименование на русском',
    'createSchool.form.ruShortName': 'Краткое наименование на русском',
    'createSchool.form.ruFullName': 'Полное наименование на русском',
    'createSchool.form.enName': 'Наименование на английском',
    'createSchool.form.enShortName': 'Краткое наименование на английском',
    'createSchool.form.enFullName': 'Полное наименование на английском',
    'createSchool.form.uLocality': 'Населенный пункт юридический',
    'createSchool.form.locality': 'Населенный пункт',
    'createSchool.form.affiliationMIO': 'Принадлежность',
    'createSchool.form.affiliation': 'Ведомственная принадлежность',
    'createSchool.form.typeOO': 'Тип организации образования',
    'createSchool.form.subtypesOO': 'Виды организации образования',
    'createSchool.form.ownershipForm': 'Форма собственности',
    'createSchool.form.OOByTerritory': 'Организация по территориальной принадлежности',
    'createSchool.form.legalForm': 'Организационно-правовая форма',
    'createSchool.form.kkAddress': 'Адрес юридический на казахском',
    'createSchool.form.ruAddress': 'Адрес юридический на русском',
    'createSchool.form.mainFounders': 'Основные учредители',
    'createSchool.form.dateOpen': 'Дата открытия организации образования',
    'createSchool.form.dateClose': 'Дата закрытия организации образования',
    'createSchool.form.save': 'Сохранить',
    'createSchool.create': 'Создание организации образования',
    'createSchool.edit': 'Редактирование организаций образования',
    'createSchool.view': 'Просмотр организации образования',
    'createSchool.form.map': 'Координаты на карте (широта, долгота)',
    'createSchool.form.coords': 'Координаты не установлены',
    'createSchool.form.partType': 'Тип составной части населенного пункта',
    'createSchool.form.partName': 'Наименование составной части населенного пункта',
    'createSchool.form.houseNumber': '№ дома',
    'createSchool.form.subtypesFlk':
      'Пункт "по уровням образования" обязательное для заполнения',
    'createSchool.form.changeTypeCodeNote':
      'Изменение типа повлияет на показатели в паспорте ОО.',

    'filterForTerritory.search': 'Поиск по организациям',
    'filterForTerritory.loading': 'Загрузка...',
    'filterForTerritory.choose': 'Выберите тип оранизации',

    'forms.save': 'Сохранить',
    'forms.required': 'Это поле обязательное для заполнения',
    'forms.wrongFormatIin': 'Неправильный формат ИИН',
    'forms.delete': 'Удалить',
    'forms.yes': 'Да',
    'forms.no': 'Нет',
    'forms.1': 'Да',
    'forms.2': 'Нет',

    'modalDelete.title': 'Удаление',
    'modalDelete.question': 'Вы хотите удалить',
    'modalDelete.delete': 'Удалить',
    'modalDelete.cancel': 'Отмена',

    'personal.id': 'ID персонала',
    'personal.integrations.loadData': 'Загрузить данные с:',
    'personal.integrations.personal': 'Персональные данные',
    'personal.integrations.disability': 'Данные по инвалидности',
    'personal.integrations.labor': 'Данные по трудоустройству',
    'personal.columnsTable.personalID': 'ID Персонала',
    'personal.columnsTable.iin': 'ИИН',
    'personal.columnsTable.statistic': 'Статистика заполненности',
    'personal.columnsTable.lastName': 'Фамилия',
    'personal.columnsTable.firstName': 'Имя',
    'personal.columnsTable.middleName': 'Отчество',
    'personal.columnsTable.jobPost': 'Должность',
    'personal.columnsTable.birthDate': 'Дата рождения',
    'personal.layOffModal.title': 'Оформить увольнение',
    'personal.layOffModal.number': 'Номер приказа',
    'personal.layOffModal.date': 'Дата увольнения',
    'personal.layOffModal.reason': 'Причина увольнения',
    'personal.layOffModal.order': 'Приказ о расторжении трудового договора',
    'personal.layOffModal.confirm': 'Уволить',
    'personal.layOffModal.cancel': 'Отмена',
    'personal.table.action': 'Действие',
    'personal.table.confirm': 'Уволить',
    'personal.personal': 'Персонал',
    'personal.add': 'Добавить персонал',
    'personal.retired': 'Выбывшие',
    'personal.error.loadCount': 'Не удалось получить данные о количестве персонала',
    'personal.teacherID': 'ID Персонала',
    'personal.updatedAt':'Данные о работе изменены',

    'personData.personal.dosie': 'Досье сотрудника',
    'personData.dosie': 'Досье учащегося',
    'personData.gbdflData': 'Данные из ГБД ФЛ',
    'personData.iin': 'ИИН',
    'personData.addIin': 'Добавить ИИН',
    'personData.correctIIN': 'Корректировать ИИН',
    'personData.correctingIIN': 'Корректировка ИИН',
    'personData.addTitle': 'Добавление ИИН',
    'personData.addPlaceholder': 'Введите ИИН',
    'personData.addCancel': 'Отмена',
    'personData.requestGBDFL': 'Запросить данные с ГБД ФЛ',
    'personData.signAndSend': 'Подписать и отправить',
    'personData.signAndAdd': 'Подписать и добавить',
    'personData.fio': 'ФИО',
    'personData.lastName': 'Фамилия',
    'personData.firstName': 'Имя',
    'personData.middleName': 'Отчество',
    'personData.birthDate': 'Дата рождения',
    'personData.sex': 'Пол',
    'personData.correctIINSuccess': 'ИИН успешно изменен',
    'personData.addIINSuccess': 'ИИН успешно добавлен',
    'personData.addIINFail': 'Не удалось добавить ИИН',
    'personData.correctIINFail': 'Не удалось изменить ИИН',
    'personData.applicationId': 'Номер заявления',

    'reestr.deleteModal.title': 'Удаление',
    'reestr.deleteModal.question': 'Вы хотите удалить',
    'reestr.deleteModal.confirm': 'Удалить',
    'reestr.deleteModal.cancel': 'Отмена',
    'reestr.filterForm.name': 'Наименование',
    'reestr.filterForm.typeOO': 'Тип организаций образования',
    'reestr.filterForm.locality': 'Населенный пункт',
    'reestr.filterForm.search': 'Найти',
    'reestr.table.id': 'ID',
    'reestr.table.name': 'Наименование',
    'reestr.table.typeOO': 'Тип организаций образования',
    'reestr.table.area': 'Область',
    'reestr.table.region': 'Район',
    'reestr.table.locality': 'Населенный пункт',
    'reestr.table.action': 'Действия',
    'reestr.table.delete': 'Удалить',
    'reestr.table.agency': 'Принадлежность',
    'reestr.table.actingDate': 'Дата открытия',
    'reestr.table.closedDate': 'Дата закрытия',
    'reestr.table.deletedDate': 'Дата удаления',
    'reestr.reestr': 'Реестр организаций образования',
    'reestr.create': 'Создать организацию образования',
    'reestr.acting': 'Действующие',
    'reestr.closed': 'Закрытые',
    'reestr.deleted': 'Удалённые',

    'controlToken.expiredAt': 'Ваш сеанс истекает через',
    'controlToken.second': 'сек',
    'controlToken.stay': 'Оставаться в системе',

    'GBDFLInn.placeholder': 'ДД.ММ.ГГГГ',
    'GBDFLInn.hasIIN': 'Наличие ИИН',
    'GBDFLInn.yes': 'Да',
    'GBDFLInn.no': 'Нет',
    'GBDFLInn.request': 'Запросить данные с ГБД ФЛ',
    'GBDFLInn.beforeRequest': 'Запросите данные с ГБД ФЛ',
    'GBDFLInn.iin': 'ИИН',
    'GBDFLInn.lastName': 'Фамилия',
    'GBDFLInn.firstName': 'Имя',
    'GBDFLInn.middleName': 'Отчество',
    'GBDFLInn.birthDate': 'Дата рождения',
    'GBDFLInn.sex': 'Пол',
    'GBDFLInn.male': 'Мужской',
    'GBDFLInn.female': 'Женский',
    'GBDFLInn.citizenship': 'Гражданство',
    'GBDFLInn.nationality': 'Национальность',
    'GBDFLInn.rka': 'РКА',
    'GBDFLInn.add': 'Добавить',
    'GBDFLInn.addAndSign': 'Подписать и добавить',
    'GBDFLInn.errorSign': 'Не удалось подписать',
    'GBDFLInn.choose': 'Выбрать',
    'GBDFLInn.close': 'Закрыть',
    'GBDFLInn.cancel': 'Отменить',
    'GBDFLInn.notMatches': 'В списке нет подходящих людей',
    'GBDFLInn.regTimeAddress': 'Адрес временной регистрации',
    'GBDFLInn.regAddress': 'Адрес постоянной регистрации',
    'GBDFLInn.duplicates': 'Найдены следующие совпадения с ГБД "Физические лица" МЮ РК',
    'GBDFLInn.noticeContingent':
      'ВНИМАНИЕ! Добавление контингента без ИИН разрешается, только если контингент является иностранцем или лицом без гражданства.',
    'GBDFLInn.noticePersonal':
      'ВНИМАНИЕ! Добавление персонала без ИИН разрешается, только если персонал является иностранцем или лицом без гражданства.',

    'mioTerritory.management': 'Перейти в паспорт управления',
    'mioTerritory.agency': 'Местный исполнительный орган',

    'motion.release': 'Выпуск',
    'motion.moving': 'Перемещение',
    'motion.notMoved': 'Не перемещенные',
    'motion.moved': 'Перемещенные',
    'motion.reception': 'Прием',

    'territory.affiliation': 'Территориальная принадлежность',
    'territory.name': 'Наименование',

    'treeTable.noData': 'Нет данных',
    'treeTable.loading': 'Идет загрузка...',

    'utils.dict.cantLoad': 'Не удалось загрузить справочник',
    'utils.dict.withCode': 'С кодом',

    'invalidIins.contingentMessage':
      'В контингенте вашей организации обнаружены некорректные ИИН. Для исправления нажмите',
    'invalidIins.personnelMessage':
      'В персонале вашей организации обнаружены некорректные ИИН. Для исправления нажмите',
    'invalidIins.fixButton': 'Исправить',
    'invalidIins.contingentListTitle': 'Список некорректных ИИН контингента',
    'invalidIins.personnelListTitle': 'Список некорректных ИИН персонала',
    'invalidIins.table.id': '№',
    'invalidIins.table.iin': 'ИИН',
    'invalidIins.table.lastName': 'Фамилия',
    'invalidIins.table.firstName': 'Имя',
    'invalidIins.table.middleName': 'Отчество',
    'invalidIins.table.birthDate': 'Дата рождения',
    'invalidIins.invalidIinFixTitle': 'Форма для ввода корректного ИИН',
    'invalidIinFixForm.formValidation.required': 'Это поле обязательное для заполнения',
    'invalidIinFixForm.formValidation.wrongIin': 'Неправильный формат ИИН',
    'invalidIinFixForm.iin': 'ИИН',
    'invalidIinFixForm.lastName': 'Фамилия',
    'invalidIinFixForm.firstName': 'Имя',
    'invalidIinFixForm.middleName': 'Отчество',
    'invalidIinFixForm.birthDate': 'День рождения',
    'invalidIinFixForm.fetchGBDFLButton': 'Запросить данные из ГБД ФЛ',
    'invalidIinFixForm.saveButton': 'Сохранить',
    'invalidIinFixForm.cancelButton': 'Отменить',
    'invalidIinFixForm.iinError': 'Ошибка загрузки данных по указанному ИИН',
    'invalidIinFixForm.iinNotFound': 'Данные не найдены по указанному ИИН',

    'ducks.netConnection': 'Ошибка запроса. Пожалуйста, проверьте доступ к интернету',
    'ducks.serverError': 'Ошибка сервера',
    'ducks.registrationSuccess': 'Регистрация прошла успешно!',
    'ducks.passChanged': 'Смена пароля прошла успешно!',
    'ducks.linkSent': 'Письмо с ссылкой для сброса пароля успешно отправлено!',
    'ducks.loadDataError': 'Ошибка загрузки данных',
    'ducks.error': 'Ошибка',
    'ducks.dataSaved': 'Данные успешно сохранены',
    'ducks.dataNotSaved': 'Не удалось сохранить данные',
    'ducks.dataUpdated': 'Данные успешно обновлены',
    'ducks.contingentRemoved': 'Контингент успешно удален',
    'ducks.contingentCreated': 'Контингент успешно создан',
    'ducks.dataNotFound': 'Данные не найдены',
    'ducks.dataNotFoundElicence': 'По сервису Е-лицензирования данные не найдены',
    'ducks.cantLoadMIOCatalog': 'Не удалось загрузить справочник "Принадлежность МИО"',
    'ducks.cantLoadOOSubtypesCatalog':
      'Не удалось загрузить справочник "Виды организации образования"',
    'ducks.bin': 'БИН',
    'ducks.iin': 'ИИН',
    'ducks.notFoundInGBDUL': 'Не найден в ГБД ЮЛ',
    'ducks.notFoundInGBDFL': 'Не найден в ГБД ФЛ',
    'ducks.cantLoadFromGBDUL': 'Не удалось загрузить из ГБД ЮЛ',
    'ducks.cantLoadFromGBDFL': 'Не удалось загрузить из ГБД ФЛ',
    'ducks.cantLoadDataForEdit': 'Не удалось загрузить данные для редактирования',
    'ducks.notFound': 'Не найдено',
    'ducks.cantLoadPassportHistory': 'Ошибка загрузки истории паспорта',
    'ducks.microTerritory.cantLoadData': 'Ошибка загрузки данных',
    'ducks.microTerritory.cantAddAddress': 'Ошибка добавления данных',
    'ducks.microTerritory.cantDeleteAddress': 'Ошибка удаления данных',
    'ducks.notIncludesInThisOO':
      'Сотрудник с таким ИИН не числится в данной организации образования',
    'ducks.cantLoadJobPostsCatalog': 'Не удалось загрузить справочник должностей',
    'ducks.cantLoadPersonalList': 'Не удалось загрузить список персонала',
    'ducks.getInvalidIinsError': 'Не удалось загрузить список некорректных ИИН',
    'ducks.saveIinSuccess': 'ИИН успешно изменен',
    'ducks.saveIinError': 'Не удалось изменить ИИН',
    'ducks.employeeDismissed': 'Сотрудник успешно уволен',
    'ducks.personalCreated': 'Персонал успешно создан',
    'ducks.personalRemoved': 'Персонал успешно удален',
    'ducks.already.staff': 'Данный сотрудник уже числится штатным в другой организации',
    'ducks.reestr.cantLoadSchoolList': 'Не удалось загрузить список школ',
    'ducks.reestr.cantDeleteOO': 'Не удалось удалить организацию',
    'ducks.reestr.organization': 'Организация',
    'ducks.reestr.removed': 'Успешно удалена',
    'ducks.territory.cantLoadOOTypes': 'Ошибка! Не удалось загрузить типы организаций',
    'ducks.trajectory.cantFindByFullName': 'Не удалось найти по ФИО и дате рождения',
    'ducks.trajectory.cantFindByIIN': 'Не удалось найти по ИИН',
    'ducks.trajectory.notIncludes': 'В данной организации не найден обучающийся',
    'ducks.users.cantLoadUsersList': 'Ошибка загрузки списка пользователей',
    'ducks.users.userAdded': 'Добавление пользователя прошло успешно!',
    'ducks.cantLoadContingentList': 'Не удалось загрузить список контингента',
    'ducks.statisticsError': 'Не удалось получить количество заполненных показателей',
    'ducks.alreadyStudying': 'Данный обучающийся числится в контингенте',
    'ducks.personalAlreadyExist': 'Данный сотрудник числится в персонале',
    'ducks.personal.already.exists1':
      'Персонвл с таким ИИН существует в системе \nID: ',
    'ducks.contingent.already.exists1':
      'Контингент с таким ИИН существует в системе \nID: ',
    'ducks.contingent.already.exists2': 'Добавление ИИН невозможно',
    'ducks.alreadyOtherWaitingOut':
      'Данный обучающийся числится на выбытие в контингенте',
    'ducks.alreadyWaitingIn':
      'Данный контингент числится во вкладке «На прибытие», для оформления приема перейдите во вкладку',
    'ducks.alreadyWaitingOut':
      'Данный контингент уже зарегистрирован со статусом "На выбытие"',

    'utils.fullNameOrShortName': 'Наименование не заполнено',

    'exportExcel.title': 'Экспорт в Excel',
    'exportExcel.success': 'Экспорт в Excel успешно завершен',
    'exportExcel.error': 'Ошибка экспорта в Excel',
    'exportExcel.cancel': 'Экспорт в Excel отменен',
    'exportExcel.tooltip':
      'Передача персональных данных третьим лицам ЗАПРЕЩАЕТСЯ согласно Закона РК «О персональных данных и их защите»',

    'financing.dictName': 'Наименование справочника',
    'financing.actions': 'Действия',
    'financing.dictSaved': 'Справочник успешно сохранен!',

    'dictManag.ADD_item': 'Добавить элемент',
    'dictManag.REMOVE_item': 'Удалить элемент',
    'dictManag.CHANGE_item': 'Редактировать элемент',
    'dictManag.entry_year': 'Год поступления',
    'dictManag.college_type': 'Тип ВУЗа',
    'dictManag.education_level': 'Уровень образования',
    'dictManag.specialty': 'Специальность и классификатор (основной)',
    'dictManag.grant_cost_per_month':
      'Стоимость обучения 1 (одного) обучающегося за месяц',
    'dictManag.grant_cost_per_year':
      'Стоимость обучения 1 (одного) обучающегося за учебный год',
    'dictManag.education_program_group': 'Группы образовательных программ',
    'dictManag.credit_cost': 'Стоимость 1 (одного) кредита',
    'dictManag.ADD': 'Добавить',
    'dictManag.REMOVE': 'Удалить',
    'dictManag.CHANGE': 'Редактировать',
    'dictManag.save': 'Сохранить',
    'dictManag.cancel': 'Отмена',
    'dictManag.ruName': 'Наименование на русском',
    'dictManag.kkName': 'Наименование на казахском',
    'dictManag.description': 'Описание',
    'dictManag.editDict': 'Редактировать справочник',
    'dictManag.elementSaved': 'Элемент успешно сохранен!',
    'dictManag.duplicate': 'Дублирование элемента!',
    'dictManag.notZero': 'Значение не должно начинаться с нуля',
    'dictManag.notNegative': 'Значение не должно быть отрицательным',

    page404_oops: 'Упс, Страница не найдена!',
    page404_notFound:
      'Возможно вы ввели не правильный адрес, <br/> или страница была удалена.',
    tableFilter_min3symbol: 'минимум 3 символа',
    notice_transition: 'В перемещаемом списке есть контингент с незаполненной параллелью',

    contingent_retiredAction: 'Отмена выбытия',
    contingent_graduatesAction: 'Отмена выпуска',

    contingent_cancelLeaveSchool: 'Отзыв выбытия контингента',
    contingent_cancelLeaveSchoolConfirm: 'Отозвать выбытие',
    contingent_cancelLeaveSchoolCancel: 'Отмена',
    contingent_cancelLeaveSchoolNotice: 'Хотите отозвать выбытие ',
    contingent_canceledLeaveSchool: 'Выбытие контингента успешно отозван',
    contingent_canceledLeaveSchoolError: 'Не удалось отозвать выбытие контингента',
    contingent_canceledLeaveSchoolError14:
      'Невозможно отозвать выбытие, так как прошло более 14 дней со дня выбытия',

    contingent_cancelGraduated: 'Отзыв выпуска контингента',
    contingent_cancelGraduatedConfirm: 'Отозвать выпуск',
    contingent_cancelGraduatedCancel: 'Отмена',
    contingent_cancelGraduatedNotice: 'Хотите отозвать выпуск ',
    contingent_cancelGraduatedSuccess: 'Выпуск контингента успешно отозван',
    contingent_cancelGraduatedError: 'Не удалось отозвать выпуск контингента',
    contingent_cancelGraduatedError14:
      'Невозможно отозвать выпуск, так как прошло более 14 дней со дня выпуска',

    personal_cancelRetired: 'Отмена увольнения',
    personal_cancelRetiredConfirm: 'Отменить увольнение',
    personal_cancelRetiredCancel: 'Отмена',
    personal_cancelRetiredNotice: 'Хотите отменить увольнение ',
    personal_cancelRetiredSuccess: 'Увольнение персонала успешно отменен',
    personal_cancelRetiredError: 'Не удалось отменить увольнение персонала',
    personal_cancelRetiredError14:
      'Невозможно отменить увольнение, так как прошло более 14 дней со дня увольнения',
    contingentDucks_errorCrossQuestion1: 'Ошибка в мета данных, показатели ',
    contingentDucks_errorCrossQuestion2: ' встречаются в мета данных ',
    contingentDucks_orgName: 'Наименование организации образования',

    personalDucks_errorCrossQuestion1: 'Ошибка в мета данных, показатели ',
    personalDucks_errorCrossQuestion2: ' встречаются в мета данных ',
    accordion_next: 'Подробнее',
    flkRuleJs_error: 'В ФЛК были внесены изменения. Необходимо обновить страницу.',
    ContingentTables_phoneNumber: 'Рабочий телефон',
    contingentView_deleteError: 'Нельзя удалить ученика, который оформлен на выбытие',
    columnFilter_filled: 'Заполненные',
    columnFilter_notFilled: 'Незаполненные',

    'faq.question': 'Вопрос:',
    'faq.answer': 'Ответ:',
    'faq.title': 'ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ (FAQ)',

    'faq.1.01.q1': 'Где посмотреть данные по ОО название и тип ОО?',
    'faq.1.01.a1': 'В Паспорте ОО раскрыть (развернуть) раздел Данные организации',
    'faq.1.01.q2': 'В организации изменилось название как его отредактировать?',
    'faq.1.01.a2':
      'Название ОО (Название организации образования, Полное наименование на казахском, Краткое наименование на казахском, Полное наименование на русском, Краткое наименование на русском) заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.01.q3':
      'Почему в разделе «Регистрационные» сведения не доступны к редактированию показатели: «Виды организаций образования», «БИН организации образования», «Форма собственности», «Организация по территориальной принадлежности», «Организационно - правовая форма», «Тип составной части», «№ Дома», «Основные учредители», «Дата открытия организации образования» и «Дата закрытия организации образования»? Как в этих показателях исправить данные?',
    'faq.1.01.a3':
      'Данные показатели заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.01.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.01.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.01.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.01.a5':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о зданиях».',
    'faq.1.01.q6':
      'В разделе «Компьютеризация» ранее были заполнены показатели по компьютерам, сейчас показатели не отображаются?',
    'faq.1.01.a6':
      'Показатель «Количество компьютеров» скрывается если показатель «Наличие компьютеров» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие компьютеров» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.01.q7':
      'В разделе «Компьютеризация» ранее были заполнены показатели по скорости интернета, сейчас показатели не отображаются?',
    'faq.1.01.a7':
      'Показатели «Скорость интернета по договору», «Скорость интернета по факту» и «Доступ к сети Интернет (точек), единиц» скрываются если показатель «Наличие интернета» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие интернета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.01.q8':
      'Почему в разделе «Основные сведения об образовательном процессе» в показателе «Группы» в элементе «Язык обучения группы» выходит «нет данных»?',
    'faq.1.01.a8':
      'На показатель установлен форматно-логический контроль в элементе «Язык обучения группы» отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, то в элементе «Язык обучения группы» выходит сообщение «нет данных».',
    'faq.1.01.q9':
      'Почему в разделе «Ответственный за паспортизацию» выходит ошибка (окрашивается красным цветом) по показателям: Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.01.a9':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.01.q11':
      'Как уволить сотрудника показатели «Дата расторжения/прекращения трудового договора», «Причина расторжения/прекращения трудового договора», «Номер приказа о расторжении/прекращении трудового договора» не активны?',
    'faq.1.01.a11':
      'Уволить сотрудника можно с главной страницы Персонала, напротив каждого сотрудника находится кнопка «Уволить». Процесс увольнения подробно описан в руководстве пользователя.',
    'faq.1.01.q12':
      'Почему в персонале у сотрудника «Ставка основной должности» выходит 0?',
    'faq.1.01.a12':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка основной должности» может быть только 0.',
    'faq.1.01.q13':
      'Почему в персонале показатель «Общий стаж работы на текущий момент» не активный? Как его исправить?',
    'faq.1.01.a13':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Общий стаж работы на момент принятия на работу».',
    'faq.1.01.q14':
      'Почему в персонале показатель «Стаж педагогической работы на текущий момент» не активный? Как его исправить?',
    'faq.1.01.a14':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Стаж педагогической работы на момент принятия на работу».',
    'faq.1.01.q15':
      'Почему в персонале показатель «Язык обучения» пустой, нет элементов для выбора?',
    'faq.1.01.a15':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в персонале показатель «Язык обучения» пустой.',
    'faq.1.01.q16':
      'Почему в персонале у сотрудника в показателе «Дополнительная должность» элемент «Ставка» выходит 0?',
    'faq.1.01.a16':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка» может быть только 0.',
    'faq.1.01.q17':
      'У сотрудника изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.01.a17':
      'При добавлении персонала персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном персонале если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.01.q18': 'Как выгрузить персонал в Excel?',
    'faq.1.01.a18':
      'В разделе персонал пользователь может экспортировать списки персонала в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Персонал» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.01.q20':
      'При добавлении контингента выходит сообщение что учащийся уже числится в другой ОО, что делать?',
    'faq.1.01.a20':
      'При добавлении учащегося, который уже числится в другой ОО выходит сообщение с контактными данными организации, при отсутствии связи с организаций обратитесь в МИО или в службу поддержки данные службы поддержки размещены в разделе «Помощь».',
    'faq.1.01.q21': 'Почему в контингенте в показателе «Группа» выходит «нет данных»?',
    'faq.1.01.a21':
      'На показатель установлен форматно-логический контроль. Отображаются те группы, которые были выбраны для названия группы в показателе «Данные о группах всех форм собственности» элемент «Год воспитания и обучения» раздела «Основные сведения об образовательном процессе». Убедитесь, что «Название группы» соответствует с одним из наименований, введенных в показателе «Данные о группах всех форм собственности».',
    'faq.1.01.q22':
      'Почему в контингенте показатель «Язык обучения» выходит «нет данных»?',
    'faq.1.01.a22':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в контингенте показатель «Язык обучения» выходит «нет данных».',
    'faq.1.01.q23':
      'Почему в контингенте показатели Изучаемый иностранный язык 1 и Изучаемый иностранный язык 2 выходит «нет данных»?',
    'faq.1.01.a23':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Группы» раздела «Основные сведения об образовательном процессе» элементы «Как иностранный 1» и «Как иностранный 2» соответственно. Убедитесь, что «Название группы» соответствует с одним из наименований, введенных в показателе «Группы» раздела «Основные сведения об образовательном процессе».',
    'faq.1.01.q24':
      'Почему в контингенте в показателе «Посещает кружки и секции в данной организации образования» выходит только «нет (не посещает)»?',
    'faq.1.01.a24':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Количество кружков и спортивных секций по видам» раздела «Основные сведения об образовательном процессе».',
    'faq.1.01.q25':
      'Почему в контингенте показатели «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» не активные? Как их исправить?',
    'faq.1.01.a25':
      'Данные по показателям «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» поступают из информационной системы Министерства труда и социальной защиты РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные по инвалидности».',
    'faq.1.01.q26':
      'Почему в контингенте показатель «Сведение о здоровье» не активные? Как их исправить?',
    'faq.1.01.a26':
      'Данные по показателю «Сведение о здоровье» поступают из информационной системы Министерства здравоохранения РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные о состоянии здоровья».',
    'faq.1.01.q27':
      'У учащегося изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.01.a27':
      'При добавлении Контингента персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном контингенте если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.01.q28': 'Как выбыть учащегося показатель «Дата выбытия не активен?',
    'faq.1.01.a28':
      'Оформить выбытие учащихся можно с главной страницы Контингент, напротив каждого учащегося находится кнопка «Оформить выбытие». Процесс оформления выбытия подробно описан в руководстве пользователя.',
    'faq.1.01.q29':
      'Как исправить не корректный ИИН ранее созданного сотрудника/контингента?',
    'faq.1.01.a29':
      'для исправления некорректных ИИН контингента и персонала, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz.  Форма размещена в разделе «Помощь».',
    'faq.1.01.q30': 'Как выгрузить контингент в Excel?',
    'faq.1.01.a30':
      'В разделе контингент пользователь может экспортировать списки контингента в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Контингент» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.01.q31': 'Как поменять пароль?',
    'faq.1.01.a31':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.01.q32': 'Кто подписывает паспорт ОО?',
    'faq.1.01.a32':
      'Паспорт ОО подписывает руководитель ОО – роль руководитель ЭЦП на физическое лицо RSA_********.',
    'faq.1.01.q33':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.1.01.a33':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в МИО или в службу технической поддержки раздел «Помощь».',
    'faq.1.01.q34': 'Как зарегистрироваться в системе НОБД?',
    'faq.1.01.a34':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.1.01.q35':
      'При авторизации вышло сообщение «Пользователь не активирован». Что делать?',
    'faq.1.01.a35':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в МИО для активации пользователя.',
    'faq.1.01.q36': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.1.01.a36':
      'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.1.01.q37': 'Где посмотреть руководства пользователя НОБД?',
    'faq.1.01.a37': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.1.01.q38': 'Как изменить язык интерфейса?',
    'faq.1.01.a38':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.1.01.q39': 'Не нашли ответа на вопрос?',
    'faq.1.01.a39':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',

    'faq.1.02.q1': 'Где посмотреть данные по ОО название и тип ОО?',
    'faq.1.02.a1': 'В Паспорте ОО раскрыть (развернуть) раздел Данные организации',
    'faq.1.02.q2': 'В организации изменилось название как его отредактировать?',
    'faq.1.02.a2':
      'Название ОО (Название организации образования, Полное наименование на казахском, Краткое наименование на казахском, Полное наименование на русском, Краткое наименование на русском) заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.02.q3':
      'Почему в разделе «Регистрационные» сведения не доступны к редактированию показатели: «Виды организаций образования», «БИН организации образования», «Форма собственности», «Организация по территориальной принадлежности», «Организационно - правовая форма», «Тип составной части», «№ Дома», «Основные учредители», «Дата открытия организации образования» и «Дата закрытия организации образования»? Как в этих показателях исправить данные?',
    'faq.1.02.a3':
      'Данные показатели заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.02.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.02.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.02.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.02.a5':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о зданиях».',
    'faq.1.02.q6':
      'В разделе «Библиотека/учебники» как исправить в показателе «Количество книг, экз.» - всего (включая учебники, художественную литературу, брошюры, журналы)?',
    'faq.1.02.a6':
      'Данное поле показателя рассчитывается автоматически – сумма строк «из них учебников» + «из них книг (включая художественную литературу, брошюры и журналы)».',
    'faq.1.02.q7':
      'Почему в разделе «Библиотека/учебники» в показателе «Обеспеченность учащихся учебниками с учетом поступлений на начало учебного года» в элементе «Язык обучения» выходит «нет данных» или не все языки обучения?',
    'faq.1.02.a7':
      'На показателе установлен форматно-логический контроль, отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в элементе «Язык обучения» нет данных.',
    'faq.1.02.q8':
      'Почему в разделе «Библиотека/учебники» в показателе «Обеспеченность учащихся учебниками с учетом поступлений на начало учебного года» в элементе «Класс» выходят не все классы?',
    'faq.1.02.a8':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение» раздела «Основные сведения об образовательном процессе».',
    'faq.1.02.q9':
      'В разделе «Библиотека/учебники» ранее были заполнены показатели: «Библиотека, единиц», «Площадь библиотеки, кв.м.», «Количество читальных залов, единиц», «Количество посадочных мест в читальных залах», «Количество читателей, человек», «Число посещений, человек» сейчас показатели не отображаются?',
    'faq.1.02.a9':
      'Данные показатели скрываются если показатель «Наличие библиотеки» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО Библиотеки нажмите на показателе «Наличие библиотеки» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.02.q10':
      'В разделе «Компьютеризация» ранее были заполнены показатели по компьютерам, сейчас показатели не отображаются?',
    'faq.1.02.a10':
      'Показатель «Количество компьютеров» скрывается если показатель «Наличие компьютеров» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие компьютеров» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.02.q11':
      'В разделе «Компьютеризация» ранее были заполнены показатели по скорости интернета, сейчас показатели не отображаются?',
    'faq.1.02.a11':
      'Показатели «Скорость интернета по договору», «Скорость интернета по факту» и «Доступ к сети Интернет (точек), единиц» скрываются если показатель «Наличие интернета» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие интернета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.02.q12':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в столовой, единиц», сейчас показатель не отображается?',
    'faq.1.02.a12':
      'Показатель «Количество посадочных мест в столовой, единиц» скрываются если показатель «Наличие столовой» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО столовой нажмите на показателе «Наличие столовой» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.02.q13':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в буфете, единиц», сейчас показатель не отображается?',
    'faq.1.02.a13':
      'Показатель «Количество посадочных мест в буфете, единиц» скрываются если показатель «Наличие буфета» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО буфета нажмите на показателе «Наличие буфета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.02.q14':
      'Почему в разделе «Основные сведения об образовательном процессе» показатель «Школа развивается в сторону языка обучения» выходит «нет данных» или не все языки обучения?',
    'faq.1.02.a14':
      'На показателе установлен форматно-логический контроль, отображаются те языки, которые отмечены в показателе «Язык обучения», если в показателе не отмечен не один язык, в показателе «Школа развивается в сторону языка обучения» выходит «нет данных».',
    'faq.1.02.q15':
      'Почему в разделе «Основные сведения об образовательном процессе» в показателе «Количество классов с углубленным изучением предметов» в элементе «Класс» выходят не все классы?',
    'faq.1.02.a15':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение».',
    'faq.1.02.q16':
      'Почему в разделе «Основные сведения об образовательном процессе» в показателе «Полиязычное обучение предметов» в элементе «Класс» выходят не все классы?',
    'faq.1.02.a16':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение».',
    'faq.1.02.q17':
      'Почему в разделе «Основные сведения об объекте образования» в показателе «Количество классов, класс-комплектов» в элементе «Класс» выходят не все классы?',
    'faq.1.02.a17':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение» разделе «Основные сведения об образовательном процессе».',
    'faq.1.02.q18':
      'Почему показатель «Численность членов в попечительском совете» не активный?',
    'faq.1.02.a18':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о составе попечительского совета».',
    'faq.1.02.q19':
      'Почему в разделе «Ответственный за паспортизацию» выходит ошибка (окрашивается красным цветом) по показателям: Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.02.a19':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.02.q21':
      'Как уволить сотрудника показатели «Дата расторжения/прекращения трудового договора», «Причина расторжения/прекращения трудового договора», «Номер приказа о расторжении/прекращении трудового договора» не активны?',
    'faq.1.02.a21':
      'Уволить сотрудника можно с главной страницы Персонала, напротив каждого сотрудника находится кнопка «Уволить». Процесс увольнения подробно описан в руководстве пользователя.',
    'faq.1.02.q22':
      'Почему в персонале у сотрудника «Ставка основной должности» выходит 0?',
    'faq.1.02.a22':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка основной должности» может быть только 0.',
    'faq.1.02.q23':
      'Почему в персонале показатель «Общий стаж работы на текущий момент» не активный? Как его исправить?',
    'faq.1.02.a23':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Общий стаж работы на момент принятия на работу».',
    'faq.1.02.q24':
      'Почему в персонале показатель «Стаж педагогической работы на текущий момент» не активный? Как его исправить? ',
    'faq.1.02.a24':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Стаж педагогической работы на момент принятия на работу».',
    'faq.1.02.q25':
      'Почему в персонале показатель «Язык обучения» пустой, нет элементов для выбора?',
    'faq.1.02.a25':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в персонале показатель «Язык обучения» пустой.',
    'faq.1.02.q26':
      'Почему в персонале у сотрудника в показателе «Дополнительная должность» элемент «Ставка» выходит 0?',
    'faq.1.02.a26':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка» может быть только 0.',
    'faq.1.02.q27':
      'Почему у сотрудника в показателе «В каких классах больше часов» выходит «нет данных»?',
    'faq.1.02.a27':
      'На показатель установлен форматно-логический контроль выходят те классы, которые отмечены в показателе «В каких классах преподает».',
    'faq.1.02.q28':
      'У сотрудника изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.02.a28':
      'При добавлении персонала персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном персонале если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.02.q29': 'Как выгрузить персонал в Excel?',
    'faq.1.02.a29':
      'В разделе персонал пользователь может экспортировать списки персонала в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Персонал» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.02.q31':
      'Почему в контингенте показатель «Язык обучения» выходит «нет данных»?',
    'faq.1.02.a31':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в контингенте показатель «Язык обучения» выходит «нет данных».',
    'faq.1.02.q32':
      'Почему в контингенте показатель «Смена обучения» выходит «нет данных» или нет не обходимой смены?',
    'faq.1.02.a32':
      'На показатель установлен форматно-логический контроль отображаются те смены, которые отмечены в показателе «Количество смен» раздела «Основные сведения об объекте образования», если в показатель не заполнен, в контингенте показатель «Смена обучения» выходит «нет данных», если «Количество смен» выбрано 1 смена, в контингенте выйдет только 1 смена и т.д.',
    'faq.1.02.q33':
      'Почему в контингенте в показателе «Посещает кружки и секции в данной организации образования» выходит только «нет (не посещает)» или не выходит необходимый вид кружка или спортивной секции?',
    'faq.1.02.a33':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Количество кружков и спортивных секций по видам» раздела «Основные сведения об образовательном процессе».',
    'faq.1.02.q34':
      'Почему в контингенте показатели «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» не активные? Как их исправить?',
    'faq.1.02.a34':
      'Данные по показателям «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» поступают из информационной системы Министерства труда и социальной защиты РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные по инвалидности».',
    'faq.1.02.q35':
      'Почему в контингенте показатель «Сведение о здоровье» не активные? Как их исправить?',
    'faq.1.02.a35':
      'Данные по показателю «Сведение о здоровье» поступают из информационной системы Министерства здравоохранения РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные о состоянии здоровья».',
    'faq.1.02.q36':
      'У учащегося изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.02.a36':
      'При добавлении Контингента персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном контингенте если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.02.q37': 'Как выбыть учащегося показатель «Дата выбытия не активен?',
    'faq.1.02.a37':
      'Оформить выбытие учащихся можно с главной страницы Контингент, напротив каждого учащегося находится кнопка «Оформить выбытие». Процесс оформления выбытия подробно описан в руководстве пользователя.',
    'faq.1.02.q38': 'Почему Оформить выпуск, перемещение или прием нет выпускников?',
    'faq.1.02.a38':
      'В данном разделе на вкладке "Выпуск" отображается список учащихся, которым заполнили показатели "Аттестат о среднем образовании (Серия аттестата)/Аттестат об основном образовании (Серия аттестата)", "Аттестат о среднем образовании (Номер аттестата)/Аттестат об основном образовании (Номер аттестата)" и "Аттестат о среднем образовании (Дата выдачи)/Аттестат об основном образовании (Дата выдачи).',
    'faq.1.02.q39':
      'При добавлении контингента выходит сообщение что учащийся уже числится в другой ОО, что делать?',
    'faq.1.02.a39':
      'При добавлении учащегося, который уже числится в другой ОО выходит сообщение с контактными данными организации, при отсутствии связи с организаций обратитесь в МИО или в службу поддержки данные службы поддержки размещены в разделе «Помощь».',
    'faq.1.02.q40':
      'Как исправить не корректный ИИН ранее созданного сотрудника/контингента?',
    'faq.1.02.a40':
      'Для исправления некорректных ИИН контингента и персонала, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz.  Форма размещена в разделе «Помощь».',
    'faq.1.02.q41': 'Как выгрузить контингент в Excel?',
    'faq.1.02.a41':
      'В разделе контингент пользователь может экспортировать списки контингента в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Контингент» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.02.q42': 'Как поменять пароль?',
    'faq.1.02.a42':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.02.q43': 'Кто подписывает паспорт ОО?',
    'faq.1.02.a43':
      'Паспорт ОО подписывает руководитель ОО – роль руководитель ЭЦП на физическое лицо RSA_********.',
    'faq.1.02.q44':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.1.02.a44':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в МИО или в службу технической поддержки раздел «Помощь».',
    'faq.1.02.q45': 'Что такое «Траектория движения учащегося»?',
    'faq.1.02.a45':
      'Это модуль НОБД позволяющий сотруднику ОО просмотреть досье учащегося из числа действующих. Подробный процесс работы с модулем описан в руководстве пользователя по модулю траектория движения учащегося.',
    'faq.1.02.q46': 'Как зарегистрироваться в системе НОБД?',
    'faq.1.02.a46':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.1.02.q47':
      'При авторизации вышло сообщение «Пользователь не активирован».  Что делать?',
    'faq.1.02.a47':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в МИО для активации пользователя.',
    'faq.1.02.q48': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.1.02.a48':
      'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.1.02.q49': 'Где посмотреть руководства пользователя НОБД?',
    'faq.1.02.a49': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.1.02.q50': 'Как изменить язык интерфейса?',
    'faq.1.02.a50':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.1.02.q51': 'Не нашли ответа на вопрос?',
    'faq.1.02.a51':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',

    'faq.1.03.q1': 'Где посмотреть данные по ОО название и тип ОО?',
    'faq.1.03.a1': 'В Паспорте ОО раскрыть (развернуть) раздел Данные организации',
    'faq.1.03.q2': 'В организации изменилось название как его отредактировать?',
    'faq.1.03.a2':
      'Название ОО (Название организации образования, Полное наименование на казахском, Краткое наименование на казахском, Полное наименование на русском, Краткое наименование на русском) заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.03.q3':
      'Почему в разделе «Регистрационные» сведения не доступны к редактированию показатели: «Виды организаций образования», «БИН организации образования», «Форма собственности», «Организация по территориальной принадлежности», «Организационно - правовая форма», «Тип составной части», «№ Дома», «Основные учредители», «Дата открытия организации образования» и «Дата закрытия организации образования»? Как в этих показателях исправить данные?',
    'faq.1.03.a3':
      'Данные показатели заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.03.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.03.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.03.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.03.a5':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о зданиях».',
    'faq.1.03.q6':
      'В разделе «Библиотека/учебники» как исправить в показателе «Количество книг, экз.» - всего (включая учебники, художественную литературу, брошюры, журналы)?',
    'faq.1.03.a6':
      'Данное поле показателя рассчитывается автоматически – сумма строк «из них учебников» + «из них книг (включая художественную литературу, брошюры и журналы)».',
    'faq.1.03.q7':
      'В разделе «Библиотека/учебники» ранее были заполнены показатели: «Библиотека, единиц», «Площадь библиотеки, кв.м.», «Количество читальных залов, единиц», «Количество посадочных мест в читальных залах», «Количество читателей, человек», «Число посещений, человек» сейчас показатели не отображаются?',
    'faq.1.03.a7':
      'Данные показатели скрываются если показатель «Наличие библиотеки» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО Библиотеки нажмите на показателе «Наличие библиотеки» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.03.q8':
      'В разделе «Компьютеризация» ранее были заполнены показатели по компьютерам, сейчас показатели не отображаются?',
    'faq.1.03.a8':
      'Показатель «Количество компьютеров» скрывается если показатель «Наличие компьютеров» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие компьютеров» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.03.q9':
      'В разделе «Компьютеризация» ранее были заполнены показатели по скорости интернета, сейчас показатели не отображаются?',
    'faq.1.03.a9':
      'Показатели «Скорость интернета по договору», «Скорость интернета по факту» и «Доступ к сети Интернет (точек), единиц» скрываются если показатель «Наличие интернета» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие интернета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.03.q10':
      'В разделе «Столовая» ранее были заполнены показатели «Количество посадочных мест в столовой, единиц», «Используется помещение» и «Средняя стоимость питания на одного обучающегося в месяц, тенге» сейчас показатель не отображается?',
    'faq.1.03.a10':
      'Показатели «Количество посадочных мест в столовой, единиц» «Используется помещение» и «Средняя стоимость питания на одного обучающегося в месяц, тенге» скрываются если показатель «Наличие столовой» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО столовой нажмите на показателе «Наличие столовой» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.03.q11':
      'Почему показатель «Численность членов в попечительском совете» не активный?',
    'faq.1.03.a11':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о составе попечительского совета».',
    'faq.1.03.q12':
      'Почему в разделе «Ответственный за паспортизацию» выходит ошибка (окрашивается красным цветом) по показателям: Рабочий телефон (код+номер), Сотовый телефон (номер)? ',
    'faq.1.03.a12':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.03.q14':
      'Как уволить сотрудника показатели «Дата расторжения/прекращения трудового договора», «Причина расторжения/прекращения трудового договора», «Номер приказа о расторжении/прекращении трудового договора» не активны?',
    'faq.1.03.a14':
      'Уволить сотрудника можно с главной страницы Персонала, напротив каждого сотрудника находится кнопка «Уволить». Процесс увольнения подробно описан в руководстве пользователя.',
    'faq.1.03.q15':
      'Почему в персонале у сотрудника «Ставка основной должности» выходит 0?',
    'faq.1.03.a15':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка основной должности» может быть только 0.',
    'faq.1.03.q16':
      'Почему в персонале показатель «Общий стаж работы на текущий момент» не активный? Как его исправить?',
    'faq.1.03.a16':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Общий стаж работы на момент принятия на работу».',
    'faq.1.03.q17':
      'Почему в персонале показатель «Стаж педагогической работы на текущий момент» не активный? Как его исправить?',
    'faq.1.03.a17':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Стаж педагогической работы на момент принятия на работу».',
    'faq.1.03.q18':
      'Почему в персонале показатель «Язык обучения» пустой, нет элементов для выбора?',
    'faq.1.03.a18':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в персонале показатель «Язык обучения» пустой.',
    'faq.1.03.q19':
      'Почему в персонале у сотрудника в показателе «Дополнительная должность» элемент «Ставка» выходит 0?',
    'faq.1.03.a19':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка» может быть только 0.',
    'faq.1.03.q20':
      'У сотрудника изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.03.a20':
      'При добавлении персонала персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном персонале если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.03.q21': 'Как выгрузить персонал в Excel?',
    'faq.1.03.a21':
      'В разделе персонал пользователь может экспортировать списки персонала в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Персонал» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.03.q23':
      'Почему в контингенте показатель «Язык обучения» выходит «нет данных»?',
    'faq.1.03.a23':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в контингенте показатель «Язык обучения» выходит «нет данных».',
    'faq.1.03.q24': 'Почему в контингенте нет показателя «Специальность»?',
    'faq.1.03.a24':
      'На показатель установлен форматно-логический контроль, отображается, когда заполнен показатель «Подготовка по уровневым квалификациям» раздела Основные сведения об объекте образования. Если в показателе «Подготовка по уровневым квалификациям» отмечено «одна квалификация» отображается только «Специальность и классификатор (основной)». Если в показателе «Подготовка по уровневым квалификациям» отмечено «двухуровневая квалификация» отображается только «Специальность и классификатор (основной)» и Специальность и классификатор 2 (для тех, у кого двухуровневая квалификация). Если в показателе «Подготовка по уровневым квалификациям» отмечено «трехуровневая квалификация» отображается только «Специальность и классификатор (основной)», Специальность и классификатор 2 (для тех, у кого двухуровневая квалификация) и Специальность и классификатор 3 (для тех, у кого трехуровневая квалификация).',
    'faq.1.03.q25':
      'Почему в контингенте в показатели «Студент (курс обучения)» выходят не все курсы? ',
    'faq.1.03.a25':
      'На показатель установлен форматно-логический контроль отображаются те курсы до которого отмечены в показателе «Срок обучения», если «Срок обучения» не заполнен, то в показателе «Студент (курс обучения)» выйдет «нет данных».',
    'faq.1.03.q26':
      'Почему в контингенте показатели «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» не активные? Как их исправить?',
    'faq.1.03.a26':
      'Данные по показателям «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» поступают из информационной системы Министерства труда и социальной защиты РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные по инвалидности».',
    'faq.1.03.q27':
      'Почему в контингенте показатель «Сведение о здоровье» не активные? Как их исправить?',
    'faq.1.03.a27':
      'Данные по показателю «Сведение о здоровье» поступают из информационной системы Министерства здравоохранения РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные о состоянии здоровья».',
    'faq.1.03.q28':
      'У учащегося изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.03.a28':
      'При добавлении Контингента персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном контингенте если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.03.q29': 'Как выбыть учащегося показатель «Дата выбытия не активен?',
    'faq.1.03.a29':
      'Оформить выбытие учащихся можно с главной страницы Контингент, напротив каждого учащегося находится кнопка «Оформить выбытие». Процесс оформления выбытия подробно описан в руководстве пользователя.',
    'faq.1.03.q30': 'Почему Оформить выпуск, перемещение или прием нет выпускников?',
    'faq.1.03.a30':
      'В данном разделе на вкладке "Выпуск" отображается список студентов, которым заполнили показатель "Серия и № диплома". Подробно процесс выпуска описан в руководстве пользователя.',
    'faq.1.03.q31':
      'При добавлении контингента выходит сообщение что учащийся уже числится в другой ОО, что делать?',
    'faq.1.03.a31':
      'При добавлении учащегося, который уже числится в другой ОО выходит сообщение с контактными данными организации, при отсутствии связи с организаций обратитесь в МИО или в службу поддержки данные службы поддержки размещены в разделе «Помощь».',
    'faq.1.03.q32':
      'Как исправить не корректный ИИН ранее созданного сотрудника/контингента?',
    'faq.1.03.a32':
      'Для исправления некорректных ИИН контингента и персонала, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz.  Форма размещена в разделе «Помощь».',
    'faq.1.03.q33': 'Как выгрузить контингент в Excel?',
    'faq.1.03.a33':
      'В разделе контингент пользователь может экспортировать списки контингента в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Контингент» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.03.q34': 'Как поменять пароль?',
    'faq.1.03.a34':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.03.q35': 'Кто подписывает паспорт ОО?',
    'faq.1.03.a35':
      'Паспорт ОО подписывает руководитель ОО – Для юридических лиц подписание паспорта производиться с помощью ЭЦП на юридическое лицо (GOST), при этом в системе будет сверяться БИН организации образования и БИН в ЭЦП. <br/> Для индивидуальных предпринимателей подписание паспорта производиться посредством ЭЦП на физическое лицо (RSA), при этом система при подписании паспорта будет сверять ИИН в профиле пользователя с ИИН в ЭЦП. Если ИИН не будет заполнен в профиле пользователя подписание невозможно.',
    'faq.1.03.q36':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.1.03.a36':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в МИО или в службу технической поддержки раздел «Помощь».',
    'faq.1.03.q37': 'Что такое «Траектория движения учащегося»?',
    'faq.1.03.a37':
      'Это модуль НОБД позволяющий сотруднику ОО просмотреть досье учащегося из числа действующих. Подробный процесс работы с модулем описан в руководстве пользователя по модулю траектория движения учащегося.',
    'faq.1.03.q38': 'Как зарегистрироваться в системе НОБД?',
    'faq.1.03.a38':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.1.03.q39':
      'При авторизации вышло сообщение «Пользователь не активирован».  Что делать?',
    'faq.1.03.a39':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в МИО для активации пользователя.',
    'faq.1.03.q40': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.1.03.a40':
      'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.1.03.q41': 'Где посмотреть руководства пользователя НОБД?',
    'faq.1.03.a41': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.1.03.q42': 'Как изменить язык интерфейса?',
    'faq.1.03.a42':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.1.03.q43': 'Не нашли ответа на вопрос?',
    'faq.1.03.a43':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',

    'faq.1.06.q1': 'Где посмотреть данные по ОО название и тип ОО?',
    'faq.1.06.a1': 'В Паспорте ОО раскрыть (развернуть) раздел Данные организации',
    'faq.1.06.q2': 'В организации изменилось название как его отредактировать?',
    'faq.1.06.a2':
      'Название ОО (Название организации образования, Полное наименование на казахском, Краткое наименование на казахском, Полное наименование на русском, Краткое наименование на русском) заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.06.q3':
      'Почему в разделе «Регистрационные» сведения не доступны к редактированию показатели: «Виды организаций образования», «БИН организации образования», «Форма собственности», «Организация по территориальной принадлежности», «Организационно - правовая форма», «Тип составной части», «№ Дома», «Основные учредители», «Дата открытия организации образования» и «Дата закрытия организации образования»? Как в этих показателях исправить данные?',
    'faq.1.06.a3':
      'Данные показатели заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.06.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.06.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.06.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.06.a5':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о зданиях».',
    'faq.1.06.q6':
      'В разделе «Библиотека/учебники» как исправить в показателе «Количество книг, экз.» - всего (включая учебники, художественную литературу, брошюры, журналы)?',
    'faq.1.06.a6':
      'Данное поле показателя рассчитывается автоматически – сумма строк «из них учебников» + «из них книг (включая художественную литературу, брошюры и журналы)».',
    'faq.1.06.q7':
      'В разделе «Библиотека/учебники» ранее были заполнены показатели: «Библиотека, единиц», «Площадь библиотеки, кв.м.», «Количество читальных залов, единиц», «Количество посадочных мест в читальных залах», «Количество читателей, человек», «Число посещений, человек» сейчас показатели не отображаются?',
    'faq.1.06.a7':
      'Данные показатели скрываются если показатель «Наличие библиотеки» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО Библиотеки нажмите на показателе «Наличие библиотеки» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.06.q8':
      'В разделе «Компьютеризация» ранее были заполнены показатели по компьютерам, сейчас показатели не отображаются?',
    'faq.1.06.a8':
      'Показатель «Количество компьютеров» скрывается если показатель «Наличие компьютеров» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие компьютеров» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.06.q9':
      'В разделе «Компьютеризация» ранее были заполнены показатели по скорости интернета, сейчас показатели не отображаются?',
    'faq.1.06.a9':
      'Показатели «Скорость интернета по договору», «Скорость интернета по факту» и «Доступ к сети Интернет (точек), единиц» скрываются если показатель «Наличие интернета» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие интернета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.06.q10':
      'В разделе «Столовая» ранее были заполнены показатели «Количество посадочных мест в столовой, единиц», «Используется помещение» и «Средняя стоимость питания на одного обучающегося в месяц, тенге» сейчас показатель не отображается?',
    'faq.1.06.a10':
      'Показатели «Количество посадочных мест в столовой, единиц» «Используется помещение» и «Средняя стоимость питания на одного обучающегося в месяц, тенге» скрываются если показатель «Наличие столовой» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО столовой нажмите на показателе «Наличие столовой» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.06.q11':
      'Почему показатель «Численность членов в попечительском совете» не активный?',
    'faq.1.06.a11':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о составе попечительского совета».',
    'faq.1.06.q12':
      'Почему в разделе «Ответственный за паспортизацию» выходит ошибка (окрашивается красным цветом) по показателям: Рабочий телефон (код+номер), Сотовый телефон (номер)? ',
    'faq.1.06.a12':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.06.q14':
      'Как уволить сотрудника показатели «Дата расторжения/прекращения трудового договора», «Причина расторжения/прекращения трудового договора», «Номер приказа о расторжении/прекращении трудового договора» не активны?',
    'faq.1.06.a14':
      'Уволить сотрудника можно с главной страницы Персонала, напротив каждого сотрудника находится кнопка «Уволить». Процесс увольнения подробно описан в руководстве пользователя.',
    'faq.1.06.q15':
      'Почему в персонале у сотрудника «Ставка основной должности» выходит 0?',
    'faq.1.06.a15':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка основной должности» может быть только 0.',
    'faq.1.06.q16':
      'Почему в персонале показатель «Общий стаж работы на текущий момент» не активный? Как его исправить?',
    'faq.1.06.a16':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Общий стаж работы на момент принятия на работу».',
    'faq.1.06.q17':
      'Почему в персонале показатель «Стаж педагогической работы на текущий момент» не активный? Как его исправить?',
    'faq.1.06.a17':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Стаж педагогической работы на момент принятия на работу».',
    'faq.1.06.q18':
      'Почему в персонале показатель «Язык обучения» пустой, нет элементов для выбора?',
    'faq.1.06.a18':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в персонале показатель «Язык обучения» пустой.',
    'faq.1.06.q19':
      'Почему в персонале у сотрудника в показателе «Дополнительная должность» элемент «Ставка» выходит 0?',
    'faq.1.06.a19':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка» может быть только 0.',
    'faq.1.06.q20':
      'У сотрудника изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.06.a20':
      'При добавлении персонала персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном персонале если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.06.q21': 'Как выгрузить персонал в Excel?',
    'faq.1.06.a21':
      'В разделе персонал пользователь может экспортировать списки персонала в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Персонал» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.06.q23':
      'Почему в контингенте показатель «Язык обучения» выходит «нет данных»?',
    'faq.1.06.a23':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в контингенте показатель «Язык обучения» выходит «нет данных».',
    'faq.1.06.q24': 'Почему в контингенте нет показателя «Специальность»?',
    'faq.1.06.a24':
      'На показатель установлен форматно-логический контроль, отображается, когда заполнен показатель «Подготовка по уровневым квалификациям» раздела Основные сведения об объекте образования. Если в показателе «Подготовка по уровневым квалификациям» отмечено «одна квалификация» отображается только «Специальность и классификатор (основной)». Если в показателе «Подготовка по уровневым квалификациям» отмечено «двухуровневая квалификация» отображается только «Специальность и классификатор (основной)» и Специальность и классификатор 2 (для тех, у кого двухуровневая квалификация). Если в показателе «Подготовка по уровневым квалификациям» отмечено «трехуровневая квалификация» отображается только «Специальность и классификатор (основной)», Специальность и классификатор 2 (для тех, у кого двухуровневая квалификация) и Специальность и классификатор 3 (для тех, у кого трехуровневая квалификация).',
    'faq.1.06.q25':
      'Почему в контингенте в показатели «Студент (курс обучения)» выходят не все курсы? ',
    'faq.1.06.a25':
      'На показатель установлен форматно-логический контроль отображаются те курсы до которого отмечены в показателе «Срок обучения», если «Срок обучения» не заполнен, то в показателе «Студент (курс обучения)» выйдет «нет данных».',
    'faq.1.06.q26':
      'Почему в контингенте показатели «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» не активные? Как их исправить?',
    'faq.1.06.a26':
      'Данные по показателям «Инвалид», «Группа инвалидности», поступают из информационной системы Министерства труда и социальной защиты РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные по инвалидности».',
    'faq.1.06.q27':
      'У учащегося изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.06.a27':
      'При добавлении Контингента персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном контингенте если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.06.q28': 'Как выбыть учащегося показатель «Дата выбытия не активен?',
    'faq.1.06.a28':
      'Оформить выбытие учащихся можно с главной страницы Контингент, напротив каждого учащегося находится кнопка «Оформить выбытие». Процесс оформления выбытия подробно описан в руководстве пользователя.',
    'faq.1.06.q29': 'Почему Оформить выпуск, перемещение или прием нет выпускников?',
    'faq.1.06.a29':
      'В данном разделе на вкладке "Выпуск" отображается список студентов, которым заполнили показатель "Серия и № диплома". Подробно процесс выпуска описан в руководстве пользователя.',
    'faq.1.06.q30':
      'При добавлении контингента выходит сообщение что учащийся уже числится в другой ОО, что делать?',
    'faq.1.06.a30':
      'При добавлении учащегося, который уже числится в другой ОО выходит сообщение с контактными данными организации, при отсутствии связи с организаций обратитесь в МИО или в службу поддержки данные службы поддержки размещены в разделе «Помощь».',
    'faq.1.06.q31':
      'Как исправить не корректный ИИН ранее созданного сотрудника/контингента?',
    'faq.1.06.a31':
      'Для исправления некорректных ИИН контингента и персонала, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz.  Форма размещена в разделе «Помощь».',
    'faq.1.06.q32': 'Как выгрузить контингент в Excel?',
    'faq.1.06.a32':
      'В разделе контингент пользователь может экспортировать списки контингента в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Контингент» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.06.q33': 'Как поменять пароль?',
    'faq.1.06.a33':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.06.q34': 'Кто подписывает паспорт ОО?',
    'faq.1.06.a34':
      'Паспорт ОО подписывает руководитель ОО – Для юридических лиц подписание паспорта производиться с помощью ЭЦП на юридическое лицо (GOST), при этом в системе будет сверяться БИН организации образования и БИН в ЭЦП. <br/> Для индивидуальных предпринимателей подписание паспорта производиться посредством ЭЦП на физическое лицо (RSA), при этом система при подписании паспорта будет сверять ИИН в профиле пользователя с ИИН в ЭЦП. Если ИИН не будет заполнен в профиле пользователя подписание невозможно.',
    'faq.1.06.q35':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.1.06.a35':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в МИО или в службу технической поддержки раздел «Помощь».',
    'faq.1.06.q36': 'Что такое «Траектория движения учащегося»?',
    'faq.1.06.a36':
      'Это модуль НОБД позволяющий сотруднику ОО просмотреть досье учащегося из числа действующих. Подробный процесс работы с модулем описан в руководстве пользователя по модулю траектория движения учащегося.',
    'faq.1.06.q37': 'Как зарегистрироваться в системе НОБД?',
    'faq.1.06.a37':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.1.06.q38':
      'При авторизации вышло сообщение «Пользователь не активирован».  Что делать?',
    'faq.1.06.a38':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в МИО для активации пользователя.',
    'faq.1.06.q39': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.1.06.a39':
      'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.1.06.q40': 'Где посмотреть руководства пользователя НОБД?',
    'faq.1.06.a40': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.1.06.q41': 'Как изменить язык интерфейса?',
    'faq.1.06.a41':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.1.06.q42': 'Не нашли ответа на вопрос?',
    'faq.1.06.a42':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',

    'faq.1.07.q1': 'Где посмотреть данные по ОО название и тип ОО?',
    'faq.1.07.a1': 'В Паспорте ОО раскрыть (развернуть) раздел Данные организации',
    'faq.1.07.q2': 'В организации изменилось название как его отредактировать?',
    'faq.1.07.a2':
      'Название ОО (Название организации образования, Полное наименование на казахском, Краткое наименование на казахском, Полное наименование на русском, Краткое наименование на русском) заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.07.q3':
      'Почему в разделе «Регистрационные» сведения не доступны к редактированию показатели: «Виды организаций образования», «БИН организации образования», «Форма собственности», «Организация по территориальной принадлежности», «Организационно - правовая форма», «Тип составной части», «№ Дома», «Основные учредители», «Дата открытия организации образования» и «Дата закрытия организации образования»? Как в этих показателях исправить данные?',
    'faq.1.07.a3':
      'Данные показатели заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.07.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.07.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.07.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.07.a5':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о зданиях».',
    'faq.1.07.q6':
      'В разделе «Библиотека/учебники» как исправить в показателе «Количество книг, экз.» - всего (включая учебники, художественную литературу, брошюры, журналы)?',
    'faq.1.07.a6':
      'Данное поле показателя рассчитывается автоматически – сумма строк «из них учебников» + «из них книг (включая художественную литературу, брошюры и журналы)».',
    'faq.1.07.q7':
      'Почему в разделе «Библиотека/учебники» в показателе «Обеспеченность учащихся учебниками с учетом поступлений на начало учебного года» в элементе «Язык обучения» выходит «нет данных» или не все языки обучения?',
    'faq.1.07.a7':
      'На показателе установлен форматно-логический контроль, отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в элементе «Язык обучения» нет данных.',
    'faq.1.07.q8':
      'Почему в разделе «Библиотека/учебники» в показателе «Обеспеченность учащихся учебниками с учетом поступлений на начало учебного года» в элементе «Класс» выходят не все классы?',
    'faq.1.07.a8':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение» раздела «Основные сведения об образовательном процессе».',
    'faq.1.07.q9':
      'В разделе «Библиотека/учебники» ранее были заполнены показатели: «Библиотека, единиц», «Площадь библиотеки, кв.м.», «Количество читальных залов, единиц», «Количество посадочных мест в читальных залах», «Количество читателей, человек», «Число посещений, человек» сейчас показатели не отображаются?',
    'faq.1.07.a9':
      'Данные показатели скрываются если показатель «Наличие библиотеки» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО Библиотеки нажмите на показателе «Наличие библиотеки» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.07.q10':
      'В разделе «Компьютеризация» ранее были заполнены показатели по компьютерам, сейчас показатели не отображаются?',
    'faq.1.07.a10':
      'Показатель «Количество компьютеров» скрывается если показатель «Наличие компьютеров» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие компьютеров» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.07.q11':
      'В разделе «Компьютеризация» ранее были заполнены показатели по скорости интернета, сейчас показатели не отображаются?',
    'faq.1.07.a11':
      'Показатели «Скорость интернета по договору», «Скорость интернета по факту» и «Доступ к сети Интернет (точек), единиц» скрываются если показатель «Наличие интернета» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие интернета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.07.q12':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в столовой, единиц», сейчас показатель не отображается?',
    'faq.1.07.a12':
      'Показатель «Количество посадочных мест в столовой, единиц» скрываются если показатель «Наличие столовой» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО столовой нажмите на показателе «Наличие столовой» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.07.q13':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в буфете, единиц», сейчас показатель не отображается?',
    'faq.1.07.a13':
      'Показатель «Количество посадочных мест в буфете, единиц» скрываются если показатель «Наличие буфета» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО буфета нажмите на показателе «Наличие буфета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.07.q14':
      'Почему в разделе «Основные сведения об образовательном процессе» показатель «Школа развивается в сторону языка обучения» выходит «нет данных» или не все языки обучения?',
    'faq.1.07.a14':
      'На показателе установлен форматно-логический контроль, отображаются те языки, которые отмечены в показателе «Язык обучения», если в показателе не отмечен не один язык, в показателе «Школа развивается в сторону языка обучения» выходит «нет данных».',
    'faq.1.07.q15':
      'Почему в разделе «Основные сведения об образовательном процессе» в показателе «Количество классов с углубленным изучением предметов» в элементе «Класс» выходят не все классы?',
    'faq.1.07.a15':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение».',
    'faq.1.07.q16':
      'Почему в разделе «Основные сведения об образовательном процессе» в показателе «Полиязычное обучение предметов» в элементе «Класс» выходят не все классы?',
    'faq.1.07.a16':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение».',
    'faq.1.07.q17':
      'Почему в разделе «Основные сведения об объекте образования» в показателе «Количество классов, класс-комплектов» в элементе «Класс» выходят не все классы?',
    'faq.1.07.a17':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение» разделе «Основные сведения об образовательном процессе».',
    'faq.1.07.q18':
      'Почему показатель «Численность членов в попечительском совете» не активный?',
    'faq.1.07.a18':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о составе попечительского совета».',
    'faq.1.07.q19':
      'Почему в разделе «Ответственный за паспортизацию» выходит ошибка (окрашивается красным цветом) по показателям: Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.07.a19':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.07.q21':
      'Как уволить сотрудника показатели «Дата расторжения/прекращения трудового договора», «Причина расторжения/прекращения трудового договора», «Номер приказа о расторжении/прекращении трудового договора» не активны?',
    'faq.1.07.a21':
      'Уволить сотрудника можно с главной страницы Персонала, напротив каждого сотрудника находится кнопка «Уволить». Процесс увольнения подробно описан в руководстве пользователя.',
    'faq.1.07.q22':
      'Почему в персонале у сотрудника «Ставка основной должности» выходит 0?',
    'faq.1.07.a22':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка основной должности» может быть только 0.',
    'faq.1.07.q23':
      'Почему в персонале показатель «Общий стаж работы на текущий момент» не активный? Как его исправить? ',
    'faq.1.07.a23':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Общий стаж работы на момент принятия на работу».',
    'faq.1.07.q24':
      'Почему в персонале показатель «Стаж педагогической работы на текущий момент» не активный? Как его исправить?',
    'faq.1.07.a24':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Стаж педагогической работы на момент принятия на работу».',
    'faq.1.07.q25':
      'Почему в персонале показатель «Язык обучения» пустой, нет элементов для выбора?',
    'faq.1.07.a25':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в персонале показатель «Язык обучения» пустой.',
    'faq.1.07.q26':
      'Почему в персонале у сотрудника в показателе «Дополнительная должность» элемент «Ставка» выходит 0?',
    'faq.1.07.a26':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка» может быть только 0.',
    'faq.1.07.q27':
      'Почему у сотрудника в показателе «В каких классах больше часов» выходит «нет данных»?',
    'faq.1.07.a27':
      'На показатель установлен форматно-логический контроль выходят те классы, которые отмечены в показателе «В каких классах преподает».',
    'faq.1.07.q28':
      'У сотрудника изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.07.a28':
      'При добавлении персонала персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном персонале если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.07.q29': 'Как выгрузить персонал в Excel?',
    'faq.1.07.a29':
      'В разделе персонал пользователь может экспортировать списки персонала в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Персонал» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.07.q31':
      'Почему в контингенте показатель «Язык обучения» выходит «нет данных»?',
    'faq.1.07.a31':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в контингенте показатель «Язык обучения» выходит «нет данных».',
    'faq.1.07.q32':
      'Почему в контингенте показатель «Смена обучения» выходит «нет данных» или нет не обходимой смены?',
    'faq.1.07.a32':
      'На показатель установлен форматно-логический контроль отображаются те смены, которые отмечены в показателе «Количество смен» раздела «Основные сведения об объекте образования», если в показатель не заполнен, в контингенте показатель «Смена обучения» выходит «нет данных», если «Количество смен» выбрано 1 смена, в контингенте выйдет только 1 смена и т.д.',
    'faq.1.07.q33':
      'Почему в контингенте в показателе «Посещает кружки и секции в данной организации образования» выходит только «нет (не посещает)» или не выходит необходимый вид кружка или спортивной секции?',
    'faq.1.07.a33':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Количество кружков и спортивных секций по видам» раздела «Основные сведения об образовательном процессе».',
    'faq.1.07.q34':
      'Почему в контингенте показатели «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» не активные? Как их исправить?',
    'faq.1.07.a34':
      'Данные по показателям «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» поступают из информационной системы Министерства труда и социальной защиты РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные по инвалидности».',
    'faq.1.07.q35':
      'Почему в контингенте показатель «Сведение о здоровье» не активные? Как их исправить?',
    'faq.1.07.a35':
      'Данные по показателю «Сведение о здоровье» поступают из информационной системы Министерства здравоохранения РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные о состоянии здоровья».',
    'faq.1.07.q36':
      'У учащегося изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.07.a36':
      'При добавлении Контингента персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном контингенте если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.07.q37': 'Как выбыть учащегося показатель «Дата выбытия не активен?',
    'faq.1.07.a37':
      'Оформить выбытие учащихся можно с главной страницы Контингент, напротив каждого учащегося находится кнопка «Оформить выбытие». Процесс оформления выбытия подробно описан в руководстве пользователя.',
    'faq.1.07.q38': 'Почему Оформить выпуск, перемещение или прием нет выпускников?',
    'faq.1.07.a38':
      'В данном разделе на вкладке "Выпуск" отображается список учащихся, которым заполнили показатели "Аттестат о среднем образовании (Серия аттестата)/Аттестат об основном образовании (Серия аттестата)", "Аттестат о среднем образовании (Номер аттестата)/Аттестат об основном образовании (Номер аттестата)" и "Аттестат о среднем образовании (Дата выдачи)/Аттестат об основном образовании (Дата выдачи).',
    'faq.1.07.q39':
      'При добавлении контингента выходит сообщение что учащийся уже числится в другой ОО, что делать?',
    'faq.1.07.a39':
      'При добавлении учащегося, который уже числится в другой ОО выходит сообщение с контактными данными организации, при отсутствии связи с организаций обратитесь в МИО или в службу поддержки данные службы поддержки размещены в разделе «Помощь».',
    'faq.1.07.q40':
      'Как исправить не корректный ИИН ранее созданного сотрудника/контингента?',
    'faq.1.07.a40':
      'Для исправления некорректных ИИН контингента и персонала, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz.  Форма размещена в разделе «Помощь».',
    'faq.1.07.q41': 'Как выгрузить контингент в Excel?',
    'faq.1.07.a41':
      'В разделе контингент пользователь может экспортировать списки контингента в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Контингент» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.07.q42': 'Как поменять пароль?',
    'faq.1.07.a42':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.07.q43': 'Кто подписывает паспорт ОО?',
    'faq.1.07.a43':
      'Паспорт ОО подписывает руководитель ОО – Для юридических лиц подписание паспорта производиться с помощью ЭЦП на юридическое лицо (GOST), при этом в системе будет сверяться БИН организации образования и БИН в ЭЦП. <br/> Для индивидуальных предпринимателей подписание паспорта производиться посредством ЭЦП на физическое лицо (RSA), при этом система при подписании паспорта будет сверять ИИН в профиле пользователя с ИИН в ЭЦП. Если ИИН не будет заполнен в профиле пользователя подписание невозможно.',
    'faq.1.07.q44':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.1.07.a44':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в МИО или в службу технической поддержки раздел «Помощь».',
    'faq.1.07.q45': 'Что такое «Траектория движения учащегося»?',
    'faq.1.07.a45':
      'Это модуль НОБД позволяющий сотруднику ОО просмотреть досье учащегося из числа действующих. Подробный процесс работы с модулем описан в руководстве пользователя по модулю траектория движения учащегося.',
    'faq.1.07.q46': 'Как зарегистрироваться в системе НОБД?',
    'faq.1.07.a46':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.1.07.q47':
      'При авторизации вышло сообщение «Пользователь не активирован». Что делать?',
    'faq.1.07.a47':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в МИО для активации пользователя.',
    'faq.1.07.q48': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.1.07.a48':
      'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.1.07.q49': 'Где посмотреть руководства пользователя НОБД?',
    'faq.1.07.a49': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.1.07.q50': 'Как изменить язык интерфейса?',
    'faq.1.07.a50':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.1.07.q51': 'Не нашли ответа на вопрос?',
    'faq.1.07.a51':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',

    'faq.1.08.q1': 'Где посмотреть данные по ОО название и тип ОО?',
    'faq.1.08.a1': 'В Паспорте ОО раскрыть (развернуть) раздел Данные организации',
    'faq.1.08.q2': 'В организации изменилось название как его отредактировать?',
    'faq.1.08.a2':
      'Название ОО (Название организации образования, Полное наименование на казахском, Краткое наименование на казахском, Полное наименование на русском, Краткое наименование на русском) заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.08.q3':
      'Почему в разделе «Регистрационные» сведения не доступны к редактированию показатели: «Виды организаций образования», «БИН организации образования», «Форма собственности», «Организация по территориальной принадлежности», «Организационно - правовая форма», «Тип составной части», «№ Дома», «Основные учредители», «Дата открытия организации образования» и «Дата закрытия организации образования»? Как в этих показателях исправить данные?',
    'faq.1.08.a3':
      'Данные показатели заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.08.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.08.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.08.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.08.a5':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о зданиях».',
    'faq.1.08.q6':
      'В разделе «Библиотека/учебники» как исправить в показателе «Количество книг, экз.» - всего (включая учебники, художественную литературу, брошюры, журналы)?',
    'faq.1.08.a6':
      'Данное поле показателя рассчитывается автоматически – сумма строк «из них учебников» + «из них книг (включая художественную литературу, брошюры и журналы)».',
    'faq.1.08.q7':
      'Почему в разделе «Библиотека/учебники» в показателе «Обеспеченность учащихся учебниками с учетом поступлений на начало учебного года» в элементе «Язык обучения» выходит «нет данных» или не все языки обучения?',
    'faq.1.08.a7':
      'На показателе установлен форматно-логический контроль, отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в элементе «Язык обучения» нет данных.',
    'faq.1.08.q8':
      'Почему в разделе «Библиотека/учебники» в показателе «Обеспеченность учащихся учебниками с учетом поступлений на начало учебного года» в элементе «Класс» выходят не все классы?',
    'faq.1.08.a8':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение» раздела «Основные сведения об образовательном процессе».',
    'faq.1.08.q9':
      'В разделе «Библиотека/учебники» ранее были заполнены показатели: «Библиотека, единиц», «Площадь библиотеки, кв.м.», «Количество читальных залов, единиц», «Количество посадочных мест в читальных залах», «Количество читателей, человек», «Число посещений, человек» сейчас показатели не отображаются?',
    'faq.1.08.a9':
      'Данные показатели скрываются если показатель «Наличие библиотеки» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО Библиотеки нажмите на показателе «Наличие библиотеки» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.08.q10':
      'В разделе «Компьютеризация» ранее были заполнены показатели по компьютерам, сейчас показатели не отображаются?',
    'faq.1.08.a10':
      'Показатель «Количество компьютеров» скрывается если показатель «Наличие компьютеров» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие компьютеров» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.08.q11':
      'В разделе «Компьютеризация» ранее были заполнены показатели по скорости интернета, сейчас показатели не отображаются?',
    'faq.1.08.a11':
      'Показатели «Скорость интернета по договору», «Скорость интернета по факту» и «Доступ к сети Интернет (точек), единиц» скрываются если показатель «Наличие интернета» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие интернета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.08.q12':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в столовой, единиц», сейчас показатель не отображается?',
    'faq.1.08.a12':
      'Показатель «Количество посадочных мест в столовой, единиц» скрываются если показатель «Наличие столовой» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО столовой нажмите на показателе «Наличие столовой» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.08.q13':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в буфете, единиц», сейчас показатель не отображается?',
    'faq.1.08.a13':
      'Показатель «Количество посадочных мест в буфете, единиц» скрываются если показатель «Наличие буфета» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО буфета нажмите на показателе «Наличие буфета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.08.q14':
      'Почему в разделе «Основные сведения об образовательном процессе» в показателе «Группы» в элементе «Язык обучения группы» выходит «нет данных»?',
    'faq.1.08.a14':
      'На показатель установлен форматно-логический контроль в элементе «Язык обучения группы» отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, то в элементе «Язык обучения группы» выходит сообщение «нет данных».',
    'faq.1.08.q15':
      'Почему в разделе «Основные сведения об образовательном процессе» показатель «Школа развивается в сторону языка обучения» выходит «нет данных» или не все языки обучения?',
    'faq.1.08.a15':
      'На показателе установлен форматно-логический контроль, отображаются те языки, которые отмечены в показателе «Язык обучения», если в показателе не отмечен не один язык, в показателе «Школа развивается в сторону языка обучения» выходит «нет данных».',
    'faq.1.08.q16':
      'Почему в разделе «Основные сведения об объекте образования» в показателе «Количество классов, класс-комплектов» в элементе «Класс» выходят не все классы?',
    'faq.1.08.a16':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение» разделе «Основные сведения об образовательном процессе».',
    'faq.1.08.q17':
      'Почему в разделе «Ответственный за паспортизацию» выходит ошибка (окрашивается красным цветом) по показателям: Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.08.a17':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.08.q19':
      'Как уволить сотрудника показатели «Дата расторжения/прекращения трудового договора», «Причина расторжения/прекращения трудового договора», «Номер приказа о расторжении/прекращении трудового договора» не активны?',
    'faq.1.08.a19':
      'Уволить сотрудника можно с главной страницы Персонала, напротив каждого сотрудника находится кнопка «Уволить». Процесс увольнения подробно описан в руководстве пользователя.',
    'faq.1.08.q20':
      'Почему в персонале у сотрудника «Ставка основной должности» выходит 0?',
    'faq.1.08.a20':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка основной должности» может быть только 0.',
    'faq.1.08.q21':
      'Почему в персонале показатель «Общий стаж работы на текущий момент» не активный? Как его исправить? ',
    'faq.1.08.a21':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Общий стаж работы на момент принятия на работу».',
    'faq.1.08.q22':
      'Почему в персонале показатель «Стаж педагогической работы на текущий момент» не активный? Как его исправить?',
    'faq.1.08.a22':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Стаж педагогической работы на момент принятия на работу».',
    'faq.1.08.q23':
      'Почему в персонале показатель «Язык обучения» пустой, нет элементов для выбора?',
    'faq.1.08.a23':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в персонале показатель «Язык обучения» пустой.',
    'faq.1.08.q24':
      'Почему в персонале у сотрудника в показателе «Дополнительная должность» элемент «Ставка» выходит 0?',
    'faq.1.08.a24':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка» может быть только 0.',
    'faq.1.08.q25':
      'Почему у сотрудника в показателе «В каких классах больше часов» выходит «нет данных»?',
    'faq.1.08.a25':
      'На показатель установлен форматно-логический контроль выходят те классы, которые отмечены в показателе «В каких классах преподает».',
    'faq.1.08.q26':
      'У сотрудника изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.08.a26':
      'При добавлении персонала персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном персонале если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.08.q27': 'Как выгрузить персонал в Excel?',
    'faq.1.08.a27':
      'В разделе персонал пользователь может экспортировать списки персонала в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Персонал» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.08.q29': 'Почему в контингенте в показателе «Группа» выходит «нет данных»?',
    'faq.1.08.a29':
      'На показатель установлен форматно-логический контроль. Отображаются те группы, которые были выбраны для названия группы в показателе «Данные о группах всех форм собственности» элемент «Год воспитания и обучения» раздела «Основные сведения об образовательном процессе». Убедитесь, что «Название группы» соответствует с одним из наименований, введенных в показателе «Данные о группах всех форм собственности».',
    'faq.1.08.q30':
      'Почему в контингенте показатель «Язык обучения» выходит «нет данных»?',
    'faq.1.08.a30':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в контингенте показатель «Язык обучения» выходит «нет данных».',
    'faq.1.08.q31':
      'Почему в контингенте показатели Изучаемый иностранный язык 1 и Изучаемый иностранный язык 2 выходит «нет данных»?',
    'faq.1.08.a31':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Группы» раздела «Основные сведения об образовательном процессе» элементы «Как иностранный 1» и «Как иностранный 2» соответственно. Убедитесь, что «Название группы» соответствует с одним из наименований, введенных в показателе «Группы» раздела «Основные сведения об образовательном процессе».',
    'faq.1.08.q32':
      'Почему в контингенте показатель «Смена обучения» выходит «нет данных» или нет не обходимой смены?',
    'faq.1.08.a32':
      'На показатель установлен форматно-логический контроль отображаются те смены, которые отмечены в показателе «Количество смен» раздела «Основные сведения об объекте образования», если в показатель не заполнен, в контингенте показатель «Смена обучения» выходит «нет данных», если «Количество смен» выбрано 1 смена, в контингенте выйдет только 1 смена и т.д.',
    'faq.1.08.q33':
      'Почему в контингенте в показателе «Посещает кружки и секции в данной организации образования» выходит только «нет (не посещает)» или не выходит необходимый вид кружка или спортивной секции?',
    'faq.1.08.a33':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Количество кружков и спортивных секций по видам» раздела «Основные сведения об образовательном процессе».',
    'faq.1.08.q34':
      'Почему в контингенте показатели «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» не активные? Как их исправить?',
    'faq.1.08.a34':
      'Данные по показателям «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» поступают из информационной системы Министерства труда и социальной защиты РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные по инвалидности».',
    'faq.1.08.q35':
      'Почему в контингенте показатель «Сведение о здоровье» не активные? Как их исправить?',
    'faq.1.08.a35':
      'Данные по показателю «Сведение о здоровье» поступают из информационной системы Министерства здравоохранения РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные о состоянии здоровья».',
    'faq.1.08.q36':
      'У учащегося изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.08.a36':
      'При добавлении Контингента персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном контингенте если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.08.q37': 'Как выбыть учащегося показатель «Дата выбытия не активен?',
    'faq.1.08.a37':
      'Оформить выбытие учащихся можно с главной страницы Контингент, напротив каждого учащегося находится кнопка «Оформить выбытие». Процесс оформления выбытия подробно описан в руководстве пользователя.',
    'faq.1.08.q38': 'Почему Оформить выпуск, перемещение или прием нет выпускников?',
    'faq.1.08.a38':
      'В данном разделе на вкладке "Выпуск" отображается список учащихся, которым заполнили показатели "Аттестат о среднем образовании (Серия аттестата)/Аттестат об основном образовании (Серия аттестата)", "Аттестат о среднем образовании (Номер аттестата)/Аттестат об основном образовании (Номер аттестата)" и "Аттестат о среднем образовании (Дата выдачи)/Аттестат об основном образовании (Дата выдачи).',
    'faq.1.08.q39': 'Почему нет раздела «Контингент»',
    'faq.1.08.a39':
      'Специальным ОО с видами ПМПК и КППК раздел «Контингент» не заполняется – не доступен.',
    'faq.1.08.q40':
      'При добавлении контингента выходит сообщение что учащийся уже числится в другой ОО, что делать?',
    'faq.1.08.a40':
      'При добавлении учащегося, который уже числится в другой ОО выходит сообщение с контактными данными организации, при отсутствии связи с организаций обратитесь в МИО или в службу поддержки данные службы поддержки размещены в разделе «Помощь».',
    'faq.1.08.q41':
      'Как исправить не корректный ИИН ранее созданного сотрудника/контингента?',
    'faq.1.08.a41':
      'Для исправления некорректных ИИН контингента и персонала, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz. Форма размещена в разделе «Помощь».',
    'faq.1.08.q42': 'Как выгрузить контингент в Excel?',
    'faq.1.08.a42':
      'В разделе контингент пользователь может экспортировать списки контингента в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Контингент» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.08.q43': 'Как поменять пароль?',
    'faq.1.08.a43':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.08.q44': 'Кто подписывает паспорт ОО?',
    'faq.1.08.a44':
      'Паспорт ОО подписывает руководитель ОО – Для юридических лиц подписание паспорта производиться с помощью ЭЦП на юридическое лицо (GOST), при этом в системе будет сверяться БИН организации образования и БИН в ЭЦП. <br/> Для индивидуальных предпринимателей подписание паспорта производиться посредством ЭЦП на физическое лицо (RSA), при этом система при подписании паспорта будет сверять ИИН в профиле пользователя с ИИН в ЭЦП. Если ИИН не будет заполнен в профиле пользователя подписание невозможно.',
    'faq.1.08.q45':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.1.08.a45':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в МИО или в службу технической поддержки раздел «Помощь».',
    'faq.1.08.q46': 'Что такое «Траектория движения учащегося»?',
    'faq.1.08.a46':
      'Это модуль НОБД позволяющий сотруднику ОО просмотреть досье учащегося из числа действующих. Подробный процесс работы с модулем описан в руководстве пользователя по модулю траектория движения учащегося.',
    'faq.1.08.q47': 'Как зарегистрироваться в системе НОБД?',
    'faq.1.08.a47':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.1.08.q48':
      'При авторизации вышло сообщение «Пользователь не активирован». Что делать?',
    'faq.1.08.a48':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в МИО для активации пользователя.',
    'faq.1.08.q49': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.1.08.a49':
      'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.1.08.q50': 'Где посмотреть руководства пользователя НОБД?',
    'faq.1.08.a50': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.1.08.q51': 'Как изменить язык интерфейса?',
    'faq.1.08.a51':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.1.08.q52': 'Не нашли ответа на вопрос?',
    'faq.1.08.a52':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',

    'faq.1.09.q1': 'Где посмотреть данные по ОО название и тип ОО?',
    'faq.1.09.a1': 'В Паспорте ОО раскрыть (развернуть) раздел Данные организации',
    'faq.1.09.q2': 'В организации изменилось название как его отредактировать?',
    'faq.1.09.a2':
      'Название ОО (Название организации образования, Полное наименование на казахском, Краткое наименование на казахском, Полное наименование на русском, Краткое наименование на русском) заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.09.q3':
      'Почему в разделе «Регистрационные» сведения не доступны к редактированию показатели: «Виды организаций образования», «БИН организации образования», «Форма собственности», «Организация по территориальной принадлежности», «Организационно - правовая форма», «Тип составной части», «№ Дома», «Основные учредители», «Дата открытия организации образования» и «Дата закрытия организации образования»? Как в этих показателях исправить данные?',
    'faq.1.09.a3':
      'Данные показатели заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.09.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.09.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.09.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.09.a5':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о зданиях».',
    'faq.1.09.q6':
      'В разделе «Библиотека/учебники» как исправить в показателе «Количество книг, экз.» - всего (включая учебники, художественную литературу, брошюры, журналы)?',
    'faq.1.09.a6':
      'Данное поле показателя рассчитывается автоматически – сумма строк «из них учебников» + «из них книг (включая художественную литературу, брошюры и журналы)».',
    'faq.1.09.q7':
      'Почему в разделе «Библиотека/учебники» в показателе «Обеспеченность учащихся учебниками с учетом поступлений на начало учебного года» в элементе «Язык обучения» выходит «нет данных» или не все языки обучения?',
    'faq.1.09.a7':
      'На показателе установлен форматно-логический контроль, отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в элементе «Язык обучения» нет данных.',
    'faq.1.09.q8':
      'Почему в разделе «Библиотека/учебники» в показателе «Обеспеченность учащихся учебниками с учетом поступлений на начало учебного года» в элементе «Класс» выходят не все классы?',
    'faq.1.09.a8':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение» раздела «Основные сведения об образовательном процессе».',
    'faq.1.09.q9':
      'В разделе «Библиотека/учебники» ранее были заполнены показатели: «Библиотека, единиц», «Площадь библиотеки, кв.м.», «Количество читальных залов, единиц», «Количество посадочных мест в читальных залах», «Количество читателей, человек», «Число посещений, человек» сейчас показатели не отображаются?',
    'faq.1.09.a9':
      'Данные показатели скрываются если показатель «Наличие библиотеки» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО Библиотеки нажмите на показателе «Наличие библиотеки» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.09.q10':
      'В разделе «Компьютеризация» ранее были заполнены показатели по компьютерам, сейчас показатели не отображаются?',
    'faq.1.09.a10':
      'Показатель «Количество компьютеров» скрывается если показатель «Наличие компьютеров» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие компьютеров» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.09.q11':
      'В разделе «Компьютеризация» ранее были заполнены показатели по скорости интернета, сейчас показатели не отображаются?',
    'faq.1.09.a11':
      'Показатели «Скорость интернета по договору», «Скорость интернета по факту» и «Доступ к сети Интернет (точек), единиц» скрываются если показатель «Наличие интернета» отмечено НЕТ и очищаются после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие интернета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.09.q12':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в столовой, единиц», сейчас показатель не отображается?',
    'faq.1.09.a12':
      'Показатель «Количество посадочных мест в столовой, единиц» скрываются если показатель «Наличие столовой» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО столовой нажмите на показателе «Наличие столовой» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.09.q13':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в буфете, единиц», сейчас показатель не отображается?',
    'faq.1.09.a13':
      'Показатель «Количество посадочных мест в буфете, единиц» скрываются если показатель «Наличие буфета» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО буфета нажмите на показателе «Наличие буфета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.09.q14':
      'Почему в разделе «Основные сведения об образовательном процессе» показатель «Школа развивается в сторону языка обучения» выходит «нет данных» или не все языки обучения?',
    'faq.1.09.a14':
      'На показателе установлен форматно-логический контроль, отображаются те языки, которые отмечены в показателе «Язык обучения», если в показателе не отмечен не один язык, в показателе «Школа развивается в сторону языка обучения» выходит «нет данных».',
    'faq.1.09.q15':
      'Почему в разделе «Основные сведения об образовательном процессе» в показателе «Количество классов с углубленным изучением предметов» в элементе «Класс» выходят не все классы?',
    'faq.1.09.a15':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение».',
    'faq.1.09.q16':
      'Почему в разделе «Основные сведения об образовательном процессе» в показателе «Полиязычное обучение предметов» в элементе «Класс» выходят не все классы?',
    'faq.1.09.a16':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение».',
    'faq.1.09.q17':
      'Почему в разделе «Основные сведения об объекте образования» в показателе «Количество классов, класс-комплектов» в элементе «Класс» выходят не все классы?',
    'faq.1.09.a17':
      'На показателе установлен форматно-логический контроль, классы отображаются с того который отмечен в показателе «С какого класса ведется обучение» разделе «Основные сведения об образовательном процессе».',
    'faq.1.09.q18':
      'Почему показатель «Численность членов в попечительском совете» не активный?',
    'faq.1.09.a18':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о составе попечительского совета».',
    'faq.1.09.q19':
      'Почему в разделе «Ответственный за паспортизацию» выходит ошибка (окрашивается красным цветом) по показателям: Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.09.a19':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.09.q21':
      'Как уволить сотрудника показатели «Дата расторжения/прекращения трудового договора», «Причина расторжения/прекращения трудового договора», «Номер приказа о расторжении/прекращении трудового договора» не активны?',
    'faq.1.09.a21':
      'Уволить сотрудника можно с главной страницы Персонала, напротив каждого сотрудника находится кнопка «Уволить». Процесс увольнения подробно описан в руководстве пользователя.',
    'faq.1.09.q22':
      'Почему в персонале у сотрудника «Ставка основной должности» выходит 0?',
    'faq.1.09.a22':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка основной должности» может быть только 0.',
    'faq.1.09.q23':
      'Почему в персонале показатель «Общий стаж работы на текущий момент» не активный? Как его исправить? ',
    'faq.1.09.a23':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Общий стаж работы на момент принятия на работу».',
    'faq.1.09.q24':
      'Почему в персонале показатель «Стаж педагогической работы на текущий момент» не активный? Как его исправить?',
    'faq.1.09.a24':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Стаж педагогической работы на момент принятия на работу».',
    'faq.1.09.q25':
      'Почему в персонале показатель «Язык обучения» пустой, нет элементов для выбора?',
    'faq.1.09.a25':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в персонале показатель «Язык обучения» пустой.',
    'faq.1.09.q26':
      'Почему в персонале у сотрудника в показателе «Дополнительная должность» элемент «Ставка» выходит 0?',
    'faq.1.09.a26':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка» может быть только 0.',
    'faq.1.09.q27':
      'Почему у сотрудника в показателе «В каких классах больше часов» выходит «нет данных»?',
    'faq.1.09.a27':
      'На показатель установлен форматно-логический контроль выходят те классы, которые отмечены в показателе «В каких классах преподает».',
    'faq.1.09.q28':
      'У сотрудника изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.09.a28':
      'При добавлении персонала персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном персонале если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.09.q29': 'Как выгрузить персонал в Excel?',
    'faq.1.09.a29':
      'В разделе персонал пользователь может экспортировать списки персонала в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Персонал» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.09.q31':
      'Почему в контингенте показатель «Язык обучения» выходит «нет данных»?',
    'faq.1.09.a31':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в контингенте показатель «Язык обучения» выходит «нет данных».',
    'faq.1.09.q32':
      'Почему в контингенте показатель «Смена обучения» выходит «нет данных» или нет не обходимой смены?',
    'faq.1.09.a32':
      'На показатель установлен форматно-логический контроль отображаются те смены, которые отмечены в показателе «Количество смен» раздела «Основные сведения об объекте образования», если в показатель не заполнен, в контингенте показатель «Смена обучения» выходит «нет данных», если «Количество смен» выбрано 1 смена, в контингенте выйдет только 1 смена и т.д.',
    'faq.1.09.q33':
      'Почему в контингенте в показателе «Посещает кружки и секции в данной организации образования» выходит только «нет (не посещает)» или не выходит необходимый вид кружка или спортивной секции?',
    'faq.1.09.a33':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Количество кружков и спортивных секций по видам» раздела «Основные сведения об образовательном процессе».',
    'faq.1.09.q34':
      'Почему в контингенте показатели «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» не активные? Как их исправить?',
    'faq.1.09.a34':
      'Данные по показателям «Инвалид», «Группа инвалидности», «Причина инвалидности», «Срок инвалидности», «Срок инвалидности до» поступают из информационной системы Министерства труда и социальной защиты РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные по инвалидности».',
    'faq.1.09.q35':
      'Почему в контингенте показатель «Сведение о здоровье» не активные? Как их исправить?',
    'faq.1.09.a35':
      'Данные по показателю «Сведение о здоровье» поступают из информационной системы Министерства здравоохранения РК. Запрос в ИС отправляется автоматически при добавлении контингента, в уже созданном ранее контингенте запросить информацию необходимо с помощью кнопки «Данные о состоянии здоровья».',
    'faq.1.09.q36':
      'У учащегося изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.09.a36':
      'При добавлении Контингента персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном контингенте если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.09.q37': 'Как выбыть учащегося показатель «Дата выбытия не активен?',
    'faq.1.09.a37':
      'Оформить выбытие учащихся можно с главной страницы Контингент, напротив каждого учащегося находится кнопка «Оформить выбытие». Процесс оформления выбытия подробно описан в руководстве пользователя.',
    'faq.1.09.q38': 'Почему Оформить выпуск, перемещение или прием нет выпускников?',
    'faq.1.09.a38':
      'В данном разделе на вкладке "Выпуск" отображается список учащихся, которым заполнили показатели "Аттестат о среднем образовании (Серия аттестата)/Аттестат об основном образовании (Серия аттестата)", "Аттестат о среднем образовании (Номер аттестата)/Аттестат об основном образовании (Номер аттестата)" и "Аттестат о среднем образовании (Дата выдачи)/Аттестат об основном образовании (Дата выдачи).',
    'faq.1.09.q39': 'Как поменять пароль?',
    'faq.1.09.a39':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.09.q40':
      'При добавлении контингента выходит сообщение что учащийся уже числится в другой ОО, что делать?',
    'faq.1.09.a40':
      'При добавлении учащегося, который уже числится в другой ОО выходит сообщение с контактными данными организации, при отсутствии связи с организаций обратитесь в МИО или в службу поддержки данные службы поддержки размещены в разделе «Помощь».',
    'faq.1.09.q41':
      'Как исправить не корректный ИИН ранее созданного сотрудника/контингента?',
    'faq.1.09.a41':
      'Для исправления некорректных ИИН контингента и персонала, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz.  Форма размещена в разделе «Помощь».',
    'faq.1.09.q42': 'Как выгрузить контингент в Excel?',
    'faq.1.09.a42':
      'В разделе контингент пользователь может экспортировать списки контингента в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Контингент» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.09.q43': 'Как поменять пароль?',
    'faq.1.09.a43':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.09.q44': 'Кто подписывает паспорт ОО?',
    'faq.1.09.a44':
      'Паспорт ОО подписывает руководитель ОО – Для юридических лиц подписание паспорта производиться с помощью ЭЦП на юридическое лицо (GOST), при этом в системе будет сверяться БИН организации образования и БИН в ЭЦП. <br/> Для индивидуальных предпринимателей подписание паспорта производиться посредством ЭЦП на физическое лицо (RSA), при этом система при подписании паспорта будет сверять ИИН в профиле пользователя с ИИН в ЭЦП. Если ИИН не будет заполнен в профиле пользователя подписание невозможно.',
    'faq.1.09.q45':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.1.09.a45':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в МИО или в службу технической поддержки раздел «Помощь».',
    'faq.1.09.q46': 'Что такое «Траектория движения учащегося»?',
    'faq.1.09.a46':
      'Это модуль НОБД позволяющий сотруднику ОО просмотреть досье учащегося из числа действующих. Подробный процесс работы с модулем описан в руководстве пользователя по модулю траектория движения учащегося.',
    'faq.1.09.q47': 'Как зарегистрироваться в системе НОБД?',
    'faq.1.09.a47':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.1.09.q48':
      'При авторизации вышло сообщение «Пользователь не активирован». Что делать?',
    'faq.1.09.a48':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в МИО для активации пользователя.',
    'faq.1.09.q49': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.1.09.a49':
      'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.1.09.q50': 'Где посмотреть руководства пользователя НОБД?',
    'faq.1.09.a50': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.1.09.q51': 'Как изменить язык интерфейса?',
    'faq.1.09.a51':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.1.09.q52': 'Не нашли ответа на вопрос?',
    'faq.1.09.a52':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',

    'faq.1.10.q1': 'Где посмотреть данные по ОО название и тип ОО?',
    'faq.1.10.a1': 'В Паспорте ОО раскрыть (развернуть) раздел Данные организации',
    'faq.1.10.q2': 'В организации изменилось название как его отредактировать?',
    'faq.1.10.a2':
      'Название ОО (Название организации образования, Полное наименование на казахском, Краткое наименование на казахском, Полное наименование на русском, Краткое наименование на русском) заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.10.q3':
      'Почему в разделе «Регистрационные» сведения не доступны к редактированию показатели: «Виды организаций образования», «БИН организации образования», «Форма собственности», «Организация по территориальной принадлежности», «Организационно - правовая форма», «Тип составной части», «№ Дома», «Основные учредители», «Дата открытия организации образования» и «Дата закрытия организации образования»? Как в этих показателях исправить данные?',
    'faq.1.10.a3':
      'Данные показатели заполняются Управлением или Отделом образования (далее - МИО) при создании организации образования и редактируются только МИО в реестре организаций образования. Пользователю необходимо связаться с сотрудником МИО.',
    'faq.1.10.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.10.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.10.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.10.a5':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о зданиях».',
    'faq.1.10.q6':
      'В разделе «Компьютеризация» ранее были заполнены показатели по компьютерам, сейчас показатели не отображаются?',
    'faq.1.10.a6':
      'Показатель «Количество компьютеров» скрывается если показатель «Наличие компьютеров» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО компьютеров нажмите на показателе «Наличие компьютеров» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателях.',
    'faq.1.10.q7':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в столовой, единиц», сейчас показатель не отображается?',
    'faq.1.10.a7':
      'Показатель «Количество посадочных мест в столовой, единиц» скрывается если показатель «Наличие столовой» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО столовой нажмите на показателе «Наличие столовой» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.10.q8':
      'В разделе «Столовая» ранее был заполнен показатель «Количество посадочных мест в буфете, единиц», сейчас показатель не отображается?',
    'faq.1.10.a8':
      'Показатель «Количество посадочных мест в буфете, единиц» скрываются если показатель «Наличие буфета» отмечено НЕТ и очищается после сохранения раздела паспорта. При наличии в ОО буфета нажмите на показателе «Наличие буфета» ДА, если данные были ранее запенены и паспорт не сохранялся они отобразятся в показателе.',
    'faq.1.10.q9':
      'Почему показатель «Численность членов в попечительском совете» не активный?',
    'faq.1.10.a9':
      'Показатель рассчитывается автоматически из данных введенных в показатель «Сведения о составе попечительского совета».',
    'faq.1.10.q10':
      'Почему в разделе «Ответственный за паспортизацию» выходит ошибка (окрашивается красным цветом) по показателям: Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.10.a10':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.10.q12':
      'Как уволить сотрудника показатели «Дата расторжения/прекращения трудового договора», «Причина расторжения/прекращения трудового договора», «Номер приказа о расторжении/прекращении трудового договора» не активны?',
    'faq.1.10.a12':
      'Уволить сотрудника можно с главной страницы Персонала, напротив каждого сотрудника находится кнопка «Уволить». Процесс увольнения подробно описан в руководстве пользователя.',
    'faq.1.10.q13':
      'Почему в персонале у сотрудника «Ставка основной должности» выходит 0?',
    'faq.1.10.a13':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка основной должности» может быть только 0.',
    'faq.1.10.q14':
      'Почему в персонале показатель «Общий стаж работы на текущий момент» не активный? Как его исправить? ',
    'faq.1.10.a14':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Общий стаж работы на момент принятия на работу».',
    'faq.1.10.q15':
      'Почему в персонале показатель «Стаж педагогической работы на текущий момент» не активный? Как его исправить?',
    'faq.1.10.a15':
      'Показатель увеличивается автоматически ежегодно после первого января и зависит от числа, введённого в показатель «Стаж педагогической работы на момент принятия на работу».',
    'faq.1.10.q16':
      'Почему в персонале показатель «Язык обучения» пустой, нет элементов для выбора?',
    'faq.1.10.a16':
      'На показатель установлен форматно-логический контроль отображаются те языки, которые отмечены в показателе «Язык обучения» раздела «Основные сведения об образовательном процессе», если в показателе не отмечен не один язык, в персонале показатель «Язык обучения» пустой.',
    'faq.1.10.q17':
      'Почему в персонале у сотрудника в показателе «Дополнительная должность» элемент «Ставка» выходит 0?',
    'faq.1.10.a17':
      'На показатель установлен форматно-логический контроль, если сотрудник в данный момент находится в декретном отпуске, в отпуске по причине болезни, в армии или в отпуске без сохранения заработной платы (показатель «Текущий статус сотрудника»), то «Ставка» может быть только 0.',
    'faq.1.10.q18':
      'У сотрудника изменились персональные данные: Фамилия, имя, отчество, гражданство и т.д.  Как их поменять в НОБД?',
    'faq.1.10.a18':
      'При добавлении персонала персональные данные Фамилия, имя, отчество, гражданство, национальность, пол запрашиваются из ГБД ФЛ по ИИН.  В уже созданном персонале если данные изменились их можно обновить при нажатии на кнопку «Персональные данные».',
    'faq.1.10.q19': 'Как выгрузить персонал в Excel?',
    'faq.1.10.a19':
      'В разделе персонал пользователь может экспортировать списки персонала в Excel для дальнейшей работы с ними. Для этого необходимо нажать кнопку «Экспорт в Excel» в правом углу окна на вкладке «Персонал» или «Выбывшие». После завершения экспорта выйдет сообщение об удачном завершении экспорта.',
    'faq.1.10.q20':
      'Как исправить не корректный ИИН ранее созданного сотрудника/контингента?',
    'faq.1.10.a20':
      'Для исправления некорректных ИИН контингента и персонала, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz.  Форма размещена в разделе «Помощь».',
    'faq.1.10.q21': 'Как поменять пароль?',
    'faq.1.10.a21':
      'В правой части окна расположено меню управления профилем пользователя. Необходимо раскрыв список, выбрать «смена пароля» и осуществить смену пароля. Подробно процесс смены пароля и работы с профилем пользователя описан в руководстве пользователя.',
    'faq.1.10.q22': 'Кто подписывает паспорт ОО?',
    'faq.1.10.a22':
      'Паспорт ОО подписывает руководитель ОО – Для юридических лиц подписание паспорта производиться с помощью ЭЦП на юридическое лицо (GOST), при этом в системе будет сверяться БИН организации образования и БИН в ЭЦП. <br/> Для индивидуальных предпринимателей подписание паспорта производиться посредством ЭЦП на физическое лицо (RSA), при этом система при подписании паспорта будет сверять ИИН в профиле пользователя с ИИН в ЭЦП. Если ИИН не будет заполнен в профиле пользователя подписание невозможно.',
    'faq.1.10.q23':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.1.10.a23':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в МИО или в службу технической поддержки раздел «Помощь».',
    'faq.1.10.q24': 'Что такое «Траектория движения учащегося»?',
    'faq.1.10.a24':
      'Это модуль НОБД позволяющий сотруднику ОО просмотреть досье учащегося из числа действующих. Подробный процесс работы с модулем описан в руководстве пользователя по модулю траектория движения учащегося.',
    'faq.1.10.q25': 'Как зарегистрироваться в системе НОБД?',
    'faq.1.10.a25':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.1.10.q26':
      'При авторизации вышло сообщение «Пользователь не активирован». Что делать?',
    'faq.1.10.a26':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в МИО для активации пользователя.',
    'faq.1.10.q27': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.1.10.a27':
      'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.1.10.q28': 'Где посмотреть руководства пользователя НОБД?',
    'faq.1.10.a28': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.1.10.q29': 'Как изменить язык интерфейса?',
    'faq.1.10.a29':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.1.10.q30': 'Не нашли ответа на вопрос?',
    'faq.1.10.a30':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',

    'faq.2.q1': 'Почему Паспорт МИО не активный – не редактируется?',
    'faq.2.a1':
      'Права на редактирование паспорта МИО есть только у пользователя с ролью «сотрудник».',
    'faq.2.q2': 'При подписании выходит ошибка?',
    'faq.2.a2':
      'Проверьте установлен ли NCALayer на вашем компьютере, соответствует ли ИИН владельца подписи и ИИН в профиле пользователя, если подпись на Юридическое лицо соответствуют ли БИН организации образования и БИН в ЭЦП, подпись действительна (срок действия ЭЦП не окончен).',
    'faq.2.q3':
      'Почему в траектории движения учащегося в поле Нынешнее состояние выходит сообщение «Числится в организации образования (наименование организации не заполнено)»?',
    'faq.2.a3':
      'Название организации ОО в которой числится учащийся не заполнено.  Сотруднику МИО необходимо перейти в Реестр организаций образования и заполнить все поля по названию ОО.',
    'faq.2.q4':
      'При закрытии ОО выходит сообщение «Не удалось сохранить данные Невозмжно закрыть организацию. Необходимо оформить выбытие/увольнение сотрудников и обучающихся в разделах персонал и контингент school cannot be closed because of current job infos 4 or/and education infos 25»',
    'faq.2.a4':
      'В ОО числится действующий персонал и (или) контингент. Перед закрытием необходимо что бы ОО уволили всех сотрудников и оформили выбытие всем учащимся.',
    'faq.2.q5':
      'Почему в созданной организации образования поле «Основные учредители» пустое?',
    'faq.2.a5':
      'Запросите данные из ГБДЮЛ по БИН для юридических лиц и данные из ГБДФЛ по ИИН для физических лиц.',
    'faq.2.q6': 'Как изменить пароль пользователю организации образования?',
    'faq.2.a6':
      'Пройти в модуль управление пользователями, открыть профиль пользователя и сменить пароль.',
    'faq.2.q7':
      'При авторизации в системе НОБД выходит сообщение Неправильный ИИН или пароль, попробуйте снова?',
    'faq.2.a7':
      'Если при входе в систему пользователь забыл пароль, необходимо нажать на «Забыли пароль?» или обратиться в службу технической поддержки раздел «Помощь».',
    'faq.2.q8': 'Как зарегистрироваться в системе НОБД?',
    'faq.2.a8':
      'Для регистрации пройти по ссылке «Зарегистрироваться», заполнить все обязательные поля и подтвердить согласие на обработку и хранение персональных данных, нажать кнопку «Подписать и отправить». Перед пользователем появится окно выбора ЭЦП. Представленные виды ЭЦП: на носителе Казтокен, на компьютере, на удостоверении личности. После выбора носителя ЭЦП пользователь должен указать путь, где расположен ключ ЭЦП, открыть папку и выбрать ключ RSA_********, нажать на открыть. После чего нужно набрать пароль от ЭЦП и нажать на кнопку «Открыть» и далее «Подписать».  Подробное описание процесса описано в руководстве пользователя.',
    'faq.2.q9':
      'При авторизации вышло сообщение «Пользователь не активирован». Что делать?',
    'faq.2.a9':
      'Данное сообщение говорит о том, что пользователь не активирован. Для активации обратиться в службу технической поддержки раздел «Помощь».',
    'faq.2.q10': 'Где посмотреть инструкции по заполнению НОБД?',
    'faq.2.a10': 'Инструкции по заполнению паспортов НОБД размещены в разделе «Помощь».',
    'faq.2.q11': 'Где посмотреть руководства пользователя НОБД? ',
    'faq.2.a11': 'Руководства пользователей НОБД размещены в разделе «Помощь».',
    'faq.2.q12': 'Как изменить язык интерфейса?',
    'faq.2.a12':
      'В верхнем левом углу расположена языковая панель, переключите на қаз или рус.',
    'faq.2.q13': 'Не нашли ответа на вопрос?',
    'faq.2.a13':
      'Если Вы не нашли ответов в разделе «Часто задаваемые вопросы» и руководстве пользователя обратитесь в службу поддержки.',
    'faq.2.q14': 'Как исправить не корректный БИН ранее созданной ОО?',
    'faq.2.a14':
      'Для исправления некорректных БИН, заполните и направьте форму на электронный адрес Службы поддержки support_nobd@iac.kz.  Форма размещена в разделе «Помощь».',

    notifications_title: 'Управление уведомлениями',
    notifications_create: 'Создать уведомление',
    notifications_ruText: 'Текст на русском',
    notifications_kkText: 'Текст на казахском',
    notifications_createdAt: 'Дата создания',
    notifications_updatedAt: 'Дата редактирования',
    notifications_roles: 'Перечень ролей',
    notifications_actions: 'Действия',
    notificationsDucks_loadNotificationsFailed: 'Не удалось загрузить уведомления',
    notificationsDucks_createNotificationFailed: 'Не удалось создать уведомление',
    notificationsDucks_createNotificationSuccess: 'Уведомление создано',
    notificationsDucks_updateNotificationSuccess: 'Изменения сохранены',
    notificationsDucks_updateNotificationFailed: 'Не удалось сохранить изменения',
    notificationsDucks_deleteNotificationFailed: 'Не удалось удалить уведомление',
    notificationsDucks_deleteNotificationSuccess: 'Уведомление удалено',
    notifications_deleteTitle: 'Удаленить уведомление',
    notifications_deleteText: 'Вы уверены что хотите удалить уведомление',
    notifications_updateNotification: 'Редактировать уведомление',
    notifications_createNotification: 'Создать уведомление',
    notifications_notification: 'Уведомление',
    notifications_familiar: 'Ознакомлен',

    monitoring_title: 'Мониторинг сервисов по интеграции',
    monitoring_filterLabel_periodFrom: 'Период с',
    monitoring_filterLabel_periodTo: 'по',
    monitoring_filterLabel_IIN: 'ИИН',
    monitoring_filterLabel_id: 'ID очереди',
    monitoring_filterLabel_BINSchoolId: 'SchoolID',
    monitoring_dateFormatError: 'Недопустимый формат даты',
    monitoring_inputIINError: 'Недопустимое значение ИИН',
    monitoring_inputBINError: 'Недопустимое значение БИН',
    monitoring_tableColumn_nedb_id: 'ID Студента',
    monitoring_tableColumn_native_id: 'ИИН',
    monitoring_tableColumn_sender: 'Отправитель',
    monitoring_tableColumn_import_object_type: 'Тип объекта',
    monitoring_tableColumn_process_status: 'Статус',
    monitoring_tableColumn_last_attempt_date: 'Дата импорта в НОБД',
    monitoring_tableColumn_accepted_date: 'Дата отправки запроса в НОБД',
    monitoring_tableColumn_error_message: 'Сообщение об ошибке',
    monitoring_info_title: 'Подробнее',
    monitoring_info_buttonClose: 'OK',

    actualization_title: 'Мониторинг сервиса актуализации по отправке данных в МТСЗН',
    actualization_logs: 'Просмотреть логи',
    actualization_tab1_title: 'Мониторинг пакетов',
    actualization_tab2_title: 'Мониторинг по записям',
    actualization_table_config: 'Кофигурация',
    actualization_table_sendStartDate: 'Дата начала отправки',
    actualization_table_sendEndDate: 'Дата завершения отправки',
    actualization_table_status: 'Статус',
    actualization_table_error: 'Сообщение об ошибке',
    actualization_table_updatedDate: 'Дата обновления записи в НОБД',
    actualization_table_quantity: 'Количество записей в пакете',
    actualization_table_code: 'Код пакета',
    actualization_table_id: 'ID объекта',
    actualization_table_datetimeSend: 'Дата и время отправки',
    actualization_periodOfUpdating: 'Период обновления данных в НОБД',
    actualization_periodOfSending: 'Период отправки данных в МТСЗН',
    actualization_search: 'Поиск',

    bolashak_title: 'Мониторинг сервиса актуализации Болашак',
    bolashak_period: 'Период обновления данных в НОБД',
    bolashak_from: 'с',
    bolashak_to: 'до',
    bolashak_column_iin: 'ИИН',
    bolashak_column_lastName: 'Фамилия',
    bolashak_column_firstName: 'Имя',
    bolashak_column_middleName: 'Отчество',
    bolashak_column_eduType: 'Тип обучения',
    bolashak_column_mainStatus: 'Основной статус',
    bolashak_column_scholarStatus: 'Статус стипендиата',
    bolashak_column_updated: 'Дата и время обновления',

    universalService_title: 'Мониторинг универсальных сервисов (запрос-ответ)',
    universalService_period: 'Период отправки запроса в НОБД',
    universalService_from: 'с',
    universalService_to: 'до',
    universalService_column_service: 'Наименование сервиса',
    universalService_column_config: 'Конфигурация',
    universalService_column_sender: 'Отправитель',
    universalService_column_jobStart: 'Дата запроса',
    universalService_column_jobEnd: 'Дата ответа',
    universalService_column_iin: 'ИИН',
    universalService_column_status: 'Статус',
    universalService_column_errorText: 'Текст ошибки',

    common_delete: 'Удалить',
    common_cancel: 'Отмена',
    common_create: 'Создать',
    common_update: 'Редактировать'
  }
};
