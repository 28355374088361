import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContentText, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../modules/Button/Button';
import { useTranslation } from 'react-i18next';

export default function DialogRevoke({ open, handleClose, handleDone, loading }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('kindergarten_appointments_actions_review')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('kindergarten_appointments_modal_review_body')}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{textAlign: "center"}}>
        <div style={{width: "50%"}}>
          <Button
            onClick={handleClose}
            color="primary"
            loading={loading}
            variant="contained"
            text={t('kindergarten_appointments_modal_no')}
          />
        </div>
        <div style={{width: "50%"}}>
          <Button
            disabled={loading}
            onClick={() => {handleDone()}}
            color="secondary"
            variant="contained"
            loading={loading}
            text={t('kindergarten_appointments_modal_yes')}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
