import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '../../../../modules/Select/Select';
import KatoTreeSelect from '../../../../_ui/KatoTreeSelect/KatoTreeSelect';
import './FiltersTranslate';
import { Wrapper } from './FiltersStyle';
import { useDispatch, useSelector } from 'react-redux';
import { bulletinFilterModule, getDicts, getKindergartens } from './FiltersDucks';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import SelectAutocomlate from './SelectAutocomlate'

function Filters({loading, filter, searchByFilter, lang}) {
  const { t } = useTranslation();
  const [region, setRegion] = useState(null);
  const [ageGroup, setAgeGroup] = useState(null);
  const [queuedType, setQueueType] = useState(null);
  const [bulletinKind, setBulletinKind] = useState(null);
  const [accessType, setAccessType] = useState(null);
  const [ownership, setOwnership] = useState(null);
  const [language, setLanguage] = useState(null);
  const [kindergarten, setKindergarten] = useState(null);
  const [rehabType, setRehabType] = useState(null);
  const [corrType, setCorrType] = useState(null);
  const [childBirthDay, setChildBirthDay] = useState(null);
  const dispatch = useDispatch();
  const dicts = useSelector(state => state[bulletinFilterModule].dicts);
  const kindergartens = useSelector(state => state[bulletinFilterModule].kindergartens);
  const loading_kindergarten = useSelector(state => state[bulletinFilterModule].loading);
  const kindergarten_filter = {formOwnership: ownership, rehabType: rehabType || null,
    correctionType: corrType || null, locationCode: region || null,
    langEducation: language || null, ageGroup: ageGroup || null,
    availableSuo: ownership === 'private' ? true : null}

  const loadByFilter = () => {
    searchByFilter({locationCode: region, ageGroup: ageGroup, queuedType: queuedType,
      typeBulletin: bulletinKind, statusWeb: accessType, formOwnership: ownership,
      language: language, rehabType: rehabType, correctionType: corrType,
      serviceProviderId: kindergarten, availableSuo: ownership === 'private' ? true : null,
      childBirthDay: childBirthDay ? moment(childBirthDay).format("YYYY-MM-DD") : null});
  };

  const clearFilter = () => {
    setRegion(null);
    setAgeGroup(null)
    setQueueType(null);
    setBulletinKind(null);
    setAccessType(null);
    setOwnership(null);
    setLanguage(null);
    setRehabType(null);
    setCorrType(null);
    setKindergarten(null);
    setChildBirthDay(null);
    searchByFilter({locationCode: null, ageGroup: null, queuedType: null, typeBulletin: null, statusWeb: null,
      formOwnership: null, language: null, rehabType: null, correctionType: null,
      serviceProviderId: null, availableSuo: null, childBirthDay: null});
  }

  const queue_type_handler = (e) => {
    setRehabType(null);
    setCorrType(null);
    setQueueType(e.target.value);
    filter.rehabType = null;
    filter.correctionType = null;
    filter.queuedType = e.target.value;
  }

  useEffect(() => {
    setKindergarten(null);
    dispatch(getKindergartens(t, kindergarten_filter));
  }, [kindergarten_filter.formOwnership, kindergarten_filter.rehabType,
    kindergarten_filter.correctionType, kindergarten_filter.locationCode,
    kindergarten_filter.langEducation, kindergarten_filter.ageGroup,
    kindergarten_filter.availableSuo])

  useEffect(() => {
    dispatch(getDicts(t));
    setRegion(filter.locationCode || null);
    setAgeGroup(filter.ageGroup || null)
    setQueueType(filter.queuedType || null);
    setBulletinKind(filter.typeBulletin || null);
    setAccessType(filter.statusWeb || null);
    setOwnership(filter.formOwnership || null);
    setLanguage(filter.language || null);
    setRehabType(filter.rehabType || null);
    setCorrType(filter.correctionType || null);
    // setKindergarten(filter.serviceProviderId || null);
    setChildBirthDay(filter.childBirthDay ? moment(filter.childBirthDay) : null);
  }, [])

  return (
    <Wrapper>
      <div className="queue_filter_inputs">
        <div>
          <KatoTreeSelect
            filterCode={"330000000"}
            name={"region"}
            placeholder={t('bulletin_op_filters_region')}
            onChange={value => setRegion(value)}
            value={region}
            parentSelectable={false}
          />
        </div>
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('bulletin_op_filters_age_group')}
            value={ageGroup}
            options={dicts.age_groups}
            onChange={e => setAgeGroup(e.target.value)}
            style={{height: '48px'}}
          />
        </div>
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('bulletin_op_filters_type')}
            value={queuedType}
            lookupId={"gu_queued_type"}
            onChange={e => queue_type_handler(e)}
            style={{height: '48px'}}
          />
        </div>
        {queuedType === "sanatorium" &&
          <div className='mgt5'>
            <Select
              withoutForm
              placeholder={t('queue_applications_filters_rehab')}
              value={rehabType}
              lookupId={'gu_rehab_type'}
              onChange={e => setRehabType(e.target.value)}
              style={{height: '48px'}}
            />
          </div>
        }
        {queuedType === "special" &&
          <div className="mgt5">
            <Select
              withoutForm
              placeholder={t('queue_applications_filters_corrType')}
              value={corrType}
              lookupId={"gu_correction_type"}
              onChange={e => setCorrType(e.target.value)}
              style={{height: '48px'}}
            />
          </div>
        }
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('bulletin_op_filters_kind')}
            value={bulletinKind}
            lookupId={"gu_type_bulletin"}
            onChange={e => setBulletinKind(e.target.value)}
            style={{height: '48px'}}
          />
        </div>
        {loading_kindergarten ?
          <CircularProgress />
          :
          <div className="mgt5">
            <SelectAutocomlate 
              options={kindergartens}
              setKindergarten = {setKindergarten}
            />
        </div>
        }
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('bulletin_op_filters_access_type')}
            value={accessType}
            options={dicts.access_types}
            onChange={e => setAccessType(e.target.value)}
            style={{height: '48px'}}
          />
        </div>
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('bulletin_op_filters_ownership')}
            value={ownership}
            options={dicts.ownerships}
            onChange={e => setOwnership(e.target.value)}
            style={{height: '48px'}}
          />
        </div>
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('bulletin_op_filters_lang')}
            value={language}
            options={dicts.languages}
            onChange={e => setLanguage(e.target.value)}
            style={{height: '48px'}}
          />
        </div>
        <div className="mgt15">
          <DatePicker
            variant="inline"
            openTo="year"
            onChange={(value) => setChildBirthDay(value)}
            value={childBirthDay}
            label={t('bulletin_op_filters_birth_day')}
            helperText=""
            autoOk
          />
        </div>
        <div className="mgt15 centerContainer">
          {loading ?
            <CircularProgress />
            :
            <>
              <div>
                <Button variant='contained' color='secondary' onClick={() => loadByFilter()}>{t('bulletin_op_filters_but')}</Button>
              </div>
              <div className="mgt15">
                <Button variant='outlined' color='secondary' size="small" onClick={() => clearFilter()}>{t('bulletin_op_filters_clear')}</Button>
              </div>
            </>
          }
        </div>
      </div>
    </Wrapper>
  );
}

export default Filters;
