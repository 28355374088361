import { useEffect, useState } from 'react';
import Form from './FormData/PassportForm';

function useStaticInfo(infoM) {
  const [info, setInfo] = useState(infoM);
  useEffect(
    () => {
      if (JSON.stringify(info) !== JSON.stringify(infoM)) {
        setInfo(infoM);
      }
    },
    // eslint-disable-next-line
    [infoM]
  );
  useEffect(() => Form.initInfo(info), [info]);
  return null
}

export default useStaticInfo;
