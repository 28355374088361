import { RENDERER } from '../../Utils/Constants';
import { setComplexData } from './setComplexData';
import { setCheckboxGroupData } from './setCheckboxGroupData';
import { setDocumentData } from './setDocumentData';
import { setTableData } from './setTableData';
import { DomainData } from '../../Utils/DomainData';
import { flcHasValue } from '../../Utils/flcHasValue';
import moment from 'moment';

// получение данных показателя
export async function setQuestionData(question, valueObj = {}, complexValues) {
  question.source = {};
  question.savedValue = '';
  question.multiple = question.config.multiple;

  question.savedFlc = {
    visibility: !valueObj.shownManually,
    readOnly: valueObj.readOnly,
    required: valueObj.required
  };

  if (valueObj.status) {
    question.stamp = valueObj.status ? { ...valueObj.status.stamp } : undefined;
    if (question.stamp && question.stamp.updatedAt) {
      question.stamp.qUpdatedAt = question.stamp.updatedAt;
      question.stamp.updatedAt = moment(question.stamp.updatedAt).format(
        'DD.MM.YYYY HH:mm'
      );
    }
  }

  if (valueObj.values && valueObj.values[0]) {
    question.source = valueObj.values[0].source || {};
    question.savedValue = flcHasValue(valueObj.values[0].value)
      ? valueObj.values[0].value
      : '';

    if (valueObj.values[0].version) {
      question.savedVersion = valueObj.values[0].version;
    }
  }

  // eslint-disable-next-line
  switch (question.renderer) {
    case RENDERER.check_box: {
      if (valueObj.values && valueObj.values[0]) {
        question.source = valueObj.values[0].source || {};
        question.savedValue = valueObj.values[0].value;
      }
      break;
    }
    case RENDERER.document:
      await setDocumentData(question, valueObj, complexValues);
      break;
    case RENDERER.table:
      await setTableData(question, valueObj);
      break;
    case RENDERER.checkbox_group:
      await setCheckboxGroupData(question, valueObj);
      break;
    case RENDERER.multiple_dropdown_tree:
      await setCheckboxGroupData(question, valueObj);
      break;
    case RENDERER.complex_inline:
      await setComplexData(question, valueObj, complexValues);
      break;
    case RENDERER.complex_table:
      await setComplexData(question, valueObj, complexValues);
      break;
  }

  if (question.source.name) {
    let sources = {};
    await DomainData.load('external_sources', list => {
      for (const source of list) {
        sources[source.code] = source;
      }
    });

    let source = sources[question.source.name];
    if (source) {
      question.source = source;
      question.source.loaded = true;
    }
  }
  // показатель иницализирован
  question.initialized = true;
}
