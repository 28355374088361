import React from 'react';
import moment from 'moment';
const styles = {
  textAlign: 'center'
};

export const requestsColumns = ({ t }) => {
  return [
    {
      Cell: (row) => row.original.position,
      width: 60,
    },
    {
      Cell: (row) => <NameColumn row={row} t={t}/>
    },
    {
      Cell: (row) => <ApplicationColumn row={row} t={t}/>
    },
    {
      style: styles,
      Cell: (row) => <PriorityColumn row={row} t={t}/>,
    },
  ];
};

const NameColumn = ({ row, t }) => {
  return <div style = {{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
          <b>{row.original.childSurname} {row.original.childName} {row.original.childMiddleName}</b>
          <br/>{t('queue_applications_table_requester')}<br/>
          {row.original.requesterSurname} {row.original.requesterName} {row.original.requesterMiddleName}
        </div>;
}

const ApplicationColumn = ({ row, t }) => {
  return <div>
    {t('queue_applications_table_app_n')} {row.original.requestFormId}<br/>
    {t('queue_applications_table_submitted')} {moment(row.original.submissionDate).format('DD.MM.YYYY HH:mm:ss')}
        </div>;
}

const PriorityColumn = ({ row, t }) => {
  switch (row.original.priorityQueued) {
    case('VO'):
      return t('queue_applications_vo');
    case('PO'):
      return t('queue_applications_po');
    case('OO'):
      return t('queue_applications_oo');
    default:
      return ''
  }
}
