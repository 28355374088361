import { findMatches } from './findMatches';

export function getTableCellDependency(question, findCode) {
  let cellsDependencies = {};
  for (const cellKey of Object.keys(question.cellsRules)) {
    let cellDependency = {};
    const cellRules = question.cellsRules[cellKey];
    for (const rule of Object.keys(cellRules)) {
      findMatches(findCode, cellDependency, cellRules[rule]);
    }
    if (Object.keys(cellDependency).length > 0) {
      cellsDependencies[cellKey] = cellDependency;
    }
  }
  return cellsDependencies;
}
