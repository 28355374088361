import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardsContainer } from './KindergartenAppointmentsStyle';
import './KindergartenAppointmentsTranslate';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearState,
  loadApplications,
  KindergartenAppointmentsModule,
  revokeByApplicant,
  prolongByApplicant
} from './KindergartenAppointmentsDucks';
import { requestsColumns } from './TableColumns/TableColumns';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import Typography from '@material-ui/core/Typography';
import useModal from '../../modules/_hooks/useModal';
import DialogRevoke from './Dialogs/DialogRevoke';
import DialogExtend from './Dialogs/DialogExtend';

function KindergartenAppointments() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(state => state[KindergartenAppointmentsModule].data);
  const pageNumber = useSelector(
    state => state[KindergartenAppointmentsModule].pageNumber
  );
  const totalElements = useSelector(
    state => state[KindergartenAppointmentsModule].totalElements
  );
  const loading = useSelector(state => state[KindergartenAppointmentsModule].loading);
  const loadData = filter => dispatch(loadApplications(filter));
  const { toPage, filter } = useTableFilter(loadData, '', {
    pageNumber: 1,
    pageSize: 10,
  });
  const modalReview = useModal('review');
  const modalExtend = useModal('extend');

  useEffect(() => {
    dispatch(clearState());
  }, []);

  const handleDoneReview = async () => {
    dispatch(revokeByApplicant(modalReview.data.id, modalReview, filter));
  };
  const handleDoneExtend = async (dataForm, clearState) => {
    dispatch(
      prolongByApplicant(
        modalExtend.data.id,
        dataForm,
        modalExtend,
        filter,
        clearState
      )
    );
  };
  return (
    <div className="mb3 mt3" style={{ width: '100%' }}>
      <Typography
        children={t('kindergarten_appointments_subtitle')}
        color="secondary"
        variant="h6" style={{paddingLeft:'10px'}}
      />
      <StyledCardsContainer>
        <StaticTablePaginated
          TheadComponent={_ => null}
          pageNumber={pageNumber}
          loading={loading}
          data={data}
          pageSize={10}
          totalElements={totalElements}
          columns={requestsColumns({
            t, filter,
            pageNumber,
            lang: i18n.language,
            modalReview,
            modalExtend
          })}
          filterable={false}
          pagePagination={page => {
            if (page !== pageNumber) {
              toPage(page);
            }
          }}
        />
      </StyledCardsContainer>
      <DialogRevoke
        handleDone={handleDoneReview}
        handleClose={modalReview.close}
        open={modalReview.isOpen}
        loading={loading}
      />
      <DialogExtend
        open={modalExtend.isOpen}
        handleClose={modalExtend.close}
        handleDone={handleDoneExtend}
        data={modalExtend.data}
        loading={loading}
      />
    </div>
  );
}

export default KindergartenAppointments;
