import axios from 'axios';
import { metaCaching } from '../../modules/utils/MetaData';

export const ServiceRequestApi = {
  loadMetaData: metaDataKey => metaCaching(
    `/api/public/metadata/${metaDataKey}`
  ),
  loadData: id => axios.get(
    `/api/self/request_form/${id}`
  ),
  createRequestForm: values => axios.post(
    '/api/self/request_form', values
  ),
  serviceProvider: filter => axios.get(
    `/api/public/info/paging/service_provider?filter=${filter}`
  ),
  searchGbdfl: iin => axios.get(
    `/api/info/gbdfl_personal_data/${iin}`
  ),
  searchKindergartenQueue: (childIin, queuedType) => axios.get(
    `/api/public/info/kindergarten_queue/related_entities?childIin=${childIin}&queuedType=${queuedType}`
  ),
  getSignString: data => axios.post(
    `/api/info/signature/sign_string/request_form`, data
  ),
  getUniversities: iin => axios.get(
    `/api/public/data/nedb/common_university_education_student_data_by_iin?iin=${iin}`
  ),
  getSchools: iin => axios.get(
    `/api/public/data/nedb/common_school_student_data_by_iin?iin=${iin}`
  ),
  getColleges: iin => axios.get(
    `/api/public/data/nedb/common_college_education_student_data_by_iin?iin=${iin}`
  ),
  getGuardianship: iin => axios.get(
    `/api/public/data/nedb/guardianship_data_by_student_iin?iin=${iin}`
  ),
  searchGBDUL: bin => axios.get(
    `/api/info/gbdul_organization_data/${bin}`
  ),
  checkQueueByIin: (childIin, queuedType) => axios.get(
    `/api/public/info/current/kindergarten_queue?childIin=${childIin}&queuedType=${queuedType}`
  ),
  getDirection: filter => axios.get(
    `/api/public/info/paging/direction?filter=${filter}`
  ),
  getPersonFlBy: iin => axios.get(
    `api/public/data/person_fl_by_iin?iin=${iin}`
  ),
  getContingentCheck: iin => axios.get(
    `api/public/info/current/contingent?childIin=${iin}`
  ),
};
