import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { countNotifications } from '../KindergartenNotifications/KindergartenNotificationsDucks';

function Account() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(countNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div/>
  );
}

export default Account;
