import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { StyledServiceCard } from './ServiceCardStyles';
import { history } from '../../_helpers/history';
import useUserInfo from '../_hooks/useUserInfo';
import { loginModalHandler } from '../../pages/LoginPage/LoginDucks';
import { services } from '../../_helpers/Constants';
import Notice from '../../modules/utils/Notice';
import './ServiceCardTranslate';
import { checkAuth } from '../../utils/needAuth';

function ServiceCard({ metadata }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUserInfo();
  const needAuth = checkAuth(metadata.url);
  let showService = !(document.domain === 'es-portal-test.e-zhetysu.kz') && metadata.code === 'children_property_short'

  return (
    <StyledServiceCard style={{display: showService && 'none'}}>
      <div className="text">
        {metadata[`${i18n.language}_name`]}
      </div>
      <div className="button-container">
        {
          (user.username || !needAuth)
            ? <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (
                  [
                    services.government_order_short.code,
                    services.status_assignment_short.code
                  ].includes(metadata.code) && !user.bin
                ) {
                  return Notice.info(t('serviceCard_forLegalPerson'));
                } else if (user.bin) {
                  return Notice.info(t('serviceCard_forPhysicalPerson'));
                }
                if (!metadata.url) {
                  history.push(`/services/create/${metadata.code}`);
                }else{
                  history.push(metadata.url);
                }
              }}
              children={metadata.url ? t('serviceCard_button_url') : t('serviceCard_button')}
            />
            : <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(loginModalHandler(true, metadata))}
              children={metadata.url ? t('serviceCard_button_url') : t('serviceCard_button')}
            />
        }
      </div>
    </StyledServiceCard>
  );
}

export default ServiceCard;
