import React, { useEffect, useState, useCallback } from 'react';
import Form from '../../FormData/PassportForm';
import Trigger from '../../FormData/ChangeTrigger';
import FormChange from '../../FormData/FormChange';
import { runFlc } from '../../Flc/execute/runFlc';
import { TestMode } from '../../Flc/TestMode';
import equalValue from '../../Utils/equalValue';
import { RENDERER } from '../../Utils/Constants';
import { handleQuestionLabel } from '../../Utils/handleQuestionLabel';

function withFlc(Component) {
  /**
   * @return {null}
   *
   */
  function WithFlc(props) {
    const {
      code,
      source: sSource,
      savedValue,
      defaultValue,
      dependency: deps,
      cellConfig,
      cancelValidation,
      checkCellHasValue,
      groupHided,
      ...rest
    } = props;
    const [value, setValue] = useState(defaultValue || savedValue);
    const [source, setSource] = useState(sSource);
    const [flc, setFlc] = useState({});
    // eslint-disable-next-line
    const [forceUpdate, setForceUpdate] = useState({});

    const handleFlc = useCallback(
      () => {
        let newFlc = runFlc(props, flc.visibility, value);
        if (cancelValidation) {
          newFlc.validation = undefined;
        }

        if (JSON.stringify(newFlc) !== JSON.stringify(flc)) {
          setFlc(newFlc);
        } else if (newFlc.itemVisibility || newFlc.inputRestriction) {
          setForceUpdate({});
        }
        if (!equalValue(value, newFlc.calculation)) {
          setValue(newFlc.calculation);
        }
      },
      // eslint-disable-next-line
      [value, flc, groupHided, cancelValidation]
    );

    useEffect(() => setSource(sSource), [sSource]);
    useEffect(() => setValue(defaultValue || savedValue), [savedValue, defaultValue]);
    useEffect(() => Form.setFlc(code, flc, cellConfig), [code, flc, cellConfig]);
    useEffect(() => Trigger.subscribe(deps, code, handleFlc), [deps, code, handleFlc]);
    useEffect(() => TestMode.register(code, handleFlc), [code, handleFlc]);
    useEffect(
      () =>
        FormChange.subscribe(
          code,
          (value, source) => {
            setValue(value);
            setSource(source);
          },
          cellConfig
        ),
      [code, cellConfig]
    );
    useEffect(
      () => {
        Form.setValue(code, value, cellConfig);
        handleFlc();
        checkCellHasValue && checkCellHasValue(value);
      },
      [code, value, handleFlc, checkCellHasValue, cellConfig]
    );

    useEffect(() => {
      let versions = [];
      if (value && value.length > 0 && props.options && props.options.length > 0) {
        versions = value.map(valueItem => {
          const option = props.options.find(item => item.code === valueItem);
          return option ? option.version : null;
        });
      }
      Form.setVersion(code, versions);
      // eslint-disable-next-line
    }, []);

    // скрываем по правилам флк
    if (!flc.visibility || flc.hideMode) {
      if (window.test && !cellConfig) {
        return (
          <div style={{ opacity: 0.5 }}>
            Скрытый показатель - {handleQuestionLabel(props, 'ru')}
          </div>
        );
      }
      return null;
    }

    const cProps = { ...rest, code, savedValue, cellConfig };
    if (flc.itemVisibility) {
      if (cProps.renderer === RENDERER.dropdown_tree) {
        cProps.filterCode = flc.itemVisibility(value);
      }
      if (cProps.options) {
        cProps.options = cProps.options.filter(item => flc.itemVisibility(value, item.code, item));
      }
    }

    return (
      <div id={`anchor_${code}`}>
        <Component
          {...cProps}
          source={source}
          error={flc.validation}
          required={flc.required}
          inputRestriction={flc.inputRestriction}
          value={value}
          incorrect={flc.incorrect || []}
          disabled={cProps.disabled || flc.readOnly}
          onChange={newValue => setValue(newValue)}
        />
      </div>
    );
  }
  return WithFlc;
}

export default withFlc;
