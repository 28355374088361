import permissions from './permissions';

export default function(role, backendPermission, result = []) {
  // default permissions
  setPermissions(result, [
    ...Object.values(permissions)
  ])
  if (result.length === 0) {
    /*switch (role) {
      case ROLES.CL_ADMINS:
        setPermissions(result, [
          ...Object.values(permissions)
        ]);
        break;
      default:
        break;
    }*/
  }
}

function setPermissions(result = [], permissions = []) {
  for (const permission of permissions) {
    if (!result.includes(permission)) {
      result.push(permission);
    }
  }
}
