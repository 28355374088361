import styled from 'styled-components';

export const StyledContent = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

export const StyledSubtitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const StyledLoginContainer = styled.div`
  @media screen and (max-width: 900px) {
    form {
      padding: 0 30px;
    }
  }
`;