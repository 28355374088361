import React from 'react';
import playMarket from '../../_assets/images/playMarket.png'
import playMarketApp from './../../_assets/files/playMarketApp_v145.apk'

function MarketPlay() {
  return (
    <a href={playMarketApp} download>
      <img src={playMarket} />
    </a>
  );
}

export default MarketPlay;
