export const CHANGE_HIDDEN_COMPLEX_QUESTIONS = {
  'government_order': {
    'specialty_name_dictionary': 'specialty_name_dictionary',
    'Qualification_name_dictionary': 'Qualification_name_dictionary'
  },
  'healthList': {
    'narcological_info_dictionary': 'narcological_info_dictionary',
    'psychiatric_info_dictionary': 'psychiatric_info_dictionary',
    'narcological_info_spouse_dictionary': 'narcological_info_spouse_dictionary',
    'psychiatric_info_spouse_dictionary': 'psychiatric_info_spouse_dictionary'
  }
};

export const SP_MODAL_TRIGGERS = [
  'name_institution',
  'request_form_accept_doc_edu_short.School_Name_applic',
  'request_form_accept_school_short.School_Name_applic'
];

export const SIMPLE_IIN_QUESTIONS = [
  'request_form_children_adoption_short.guardianship_child_iin',
  'request_form_payment_patronage_short.spouse_iin',
  'request_form_children_property_short.spouse_iin',
  'request_form_children_property_short.child_iin',
  'request_form_children_property_short.previous_owner_iin',
  //'request_form_doc_acceptance_ddo_short.child_iin',
  'request_form_accept_doc_edu_short.Child_iin',
  'request_form_accept_doc_edu_short.mother_iin',
  'request_form_accept_doc_edu_short.father_iin',
  'request_form_accept_doc_edu_short.iin_spouse',
  'request_form_payment_family_short.spouse_iin',
  'request_form_children_adoption_short.iin_child_mother',
  'request_form_children_adoption_short.iin_child_father',
  'request_form_accept_doc_edu_short.divorce_spouse_iin',
];

export const SIMPLE_GUARDIANSHIP_QUESTIONS = [
  'request_form_children_property_short.guardian_doc_number',
  'request_form_accept_doc_edu_short.DecNumb_TrustGuard',
  'request_form_doc_acceptance_ddo_short.number_guardian'
];

export const SIMPLE_SCHOOL_QUESTIONS = [
  'request_form_accept_doc_edu_short.School_Name'
];

export const COMPLEX_IIN_QUESTIONS = {
  'fostercare_InfoChild': {
    'fostercare_child_iin': 'fostercare_child_iin'
  },
  'child_infoList': {
    'guardianship_child_iin': 'guardianship_child_iin'
  }
};

export const COMPLEX_SCHOOL_QUESTIONS = {
  'fostercare_InfoChild': {
    'fostercare_child_edu_org_name': 'fostercare_child_edu_org_name'
  }
};

export const COMPLEX_COLLEGE_QUESTIONS = {
  'edu_TapE': {
    'EDU_Name': 'EDU_Name'
  }
};

export const COMPLEX_UNIVER_QUESTIONS = {
  'edu_list': {
    'Univer_name': 'Univer_name'
  }
};

export const CHANGE_SIMPLE_QUESTIONS = [
  'request_form_government_order_short.adress_country'
];

export const LISTEN_SIMPLE_QUESTIONS = [
  'request_form_accept_doc_edu_short.edu_form'
];

export const CHECK_AGE_QUESTIONS = [
  'request_form_doc_queued_ddo_short.child_birthday',
  'request_form_doc_queued_ddo_short.queued_type',
  'request_form_doc_queued_ddo_short.privilege_type'
];

export const CHECK_IIN_IN_QUEUE = [
  'request_form_doc_queued_ddo_short.child_iin',
]
