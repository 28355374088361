import React, { useState, useContext, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { ConfigContext } from '../../PassportProvider';
import { Collapsed, Expanded } from './FullScreenStyle';

function Wrapper({ fullScreen, hideScroll, children }) {
  if (fullScreen) {
    return <Expanded children={children} />;
  } else {
    return <Collapsed hideScroll={hideScroll} children={children} />;
  }
}

function FullScreen({ title, children, afterRender }) {
  const { withoutScroll } = useContext(ConfigContext);
  const [fullScreen, setFullScreen] = useState(false);
  const onFullScreen = () => {
    setFullScreen(!fullScreen);
    const elem = document.getElementById('app');
    if (elem) {
      elem.style.overflow = fullScreen ? 'auto' : 'hidden';
    }
  };

  useEffect(
    () => () => {
      const elem = document.getElementById('app');
      if (elem) elem.style.overflow = 'auto';
    },
    []
  );

  return (
    <>
      <Wrapper fullScreen={fullScreen} hideScroll={withoutScroll}>
        <div className="fc-con">
          <div className="fc-header">
            <span className="bold">{title}</span>
            {!withoutScroll && (
              <IconButton className="fc-icon" onClick={onFullScreen}>
                {fullScreen && <FullscreenExitIcon />}
                {!fullScreen && <FullscreenIcon />}
              </IconButton>
            )}
          </div>
        </div>

        <div className={!fullScreen ? 'fc-scroll' : ''}>{children}</div>
        {fullScreen && afterRender}
      </Wrapper>
      {!fullScreen && afterRender}
    </>
  );
}

export default FullScreen;
