import { getFlcRules } from '../../Utils/getFlcRules';
import { findMatches } from './findMatches';

export function getComplexCellDependency(question, findCode) {
  let cellsDependencies = {};
  let usesRowIndex = false;
  for (const cellQuestion of Object.values(question.cellsQuestions)) {
    let cellDependency = {};
    for (const rule of getFlcRules(cellQuestion.renderer)) {
      if (cellQuestion[rule]) {
        findMatches(findCode, cellDependency, cellQuestion[rule], question.code);

        if (!usesRowIndex && cellQuestion[rule].indexOf('rowIndex') !== -1) {
          usesRowIndex = true;
          question.usesRowIndex = true;
        }
      }
    }
    if (Object.keys(cellDependency).length > 0) {
      cellsDependencies[cellQuestion.code] = cellDependency;
    }
  }
  return cellsDependencies;
}
