import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    accountSettings_iin: 'ИИН заявителя',
    accountSettings_update: 'Обновить данные',
    accountSettings_updateFailed: 'Не удалось обновить данные пользователя',
    accountSettings_fillForm: 'Уважаемый пользователь! Чтобы продолжить работу, необходимо заполнить обязательные поля! Для заполнения обязательных полей нужно нажать на кнопку "Редактировать", после cохранить личные данные нажав на кнопку "Сохранить".',

    accountSettingsDucks_loadRequesterFailed: 'Не удалось загрузить данные пользователя',
    accountSettingsDucks_saveRequesterFailed: 'Не удалось сохранить данные пользователя',
    accountSettingsDucks_saveRequesterSuccess: 'Данные успешно сохранены',
    accountSettingsDucks_lastLoginDt: 'Дата последнего успешного входа',
  },
  kk: {
    accountSettings_iin: 'ИИН заявителя',
    accountSettings_update: 'Деректерді жаңарту',
    accountSettings_updateFailed: 'Не удалось обновить данные пользователя',
    accountSettings_fillForm: 'Құрметті пайдаланушы! Жұмысты жалғастыру үшін міндетті өрістерді толтыру қажет! Міндетті өрістерді толтыру үшін "Өңдеу" батырмасын басыңыз, кейін Өрістерді толтырғаннан кейін "Сақтау" батырмасын басу керек.',

    accountSettingsDucks_loadRequesterFailed: 'Не удалось загрузить данные пользователя',
    accountSettingsDucks_saveRequesterFailed: 'Не удалось сохранить данные пользователя',
    accountSettingsDucks_saveRequesterSuccess: 'Данные успешно сохранены',
    accountSettingsDucks_lastLoginDt: 'Соңғы сәтті кіру күні',
  }
});