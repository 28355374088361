import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StyledLoginContainer } from '../Home/HomeStyles';
import Form from '../../_ui/Form/Form';
import Button from '../../modules/Button/Button';
import { login, loginModule } from './LoginDucks';
import Row from '../../_ui/Row';
import Input from '../../_ui/Input/Input';
import { SignInSchema } from './LoginValidation';

function DefaultLogin() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loadingLogin } = useSelector(state => state[loginModule]);

  return (
    <StyledLoginContainer>
      <Form
        initialValues={{
          username: '',
          password: ''
        }}
        validate={SignInSchema}
        onSubmit={values => dispatch(login(values))}
        render={() => (
          <>
            <Row
              label={t('loginPage_labelLogin')}
              style={{ marginBottom: '26px', fontFamily: 'Montserrat' }}
            >
              <Input
                name="username"
                placeholder={t('loginPage_placeholderLogin')}
              />
            </Row>
            <Row
              label={t('loginPage_labelPassword')}
              style={{ marginBottom: '26px', fontFamily: 'Montserrat' }}
            >
              <Input
                type="password"
                name="password"
                placeholder={t('loginPage_placeholderPassword')}
              />
            </Row>
            <Button
              type="submit"
              color='secondary'
              variant="contained"
              loading={loadingLogin}
              text={t('loginPage_login')}
            />
          </>
        )}
      />
    </StyledLoginContainer>
  )
}

export default DefaultLogin;