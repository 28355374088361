import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kindergarten_title: 'Централизованная база данных подведомственных учреждений акимата Алматинской области',
    kindergarten_insert_title: 'Займите очередь в детский сад за несколько минут',
    kindergarten_insert_text: 'Постановка детей на очередь в детский сад в области Жетісу',
    kindergarten_subtitle: 'Очередь в детский сад',
    kindergarten_our_services: 'Наши услуги',
    kindergarten_support: 'Служба поддержки',
    kindergarten_faq: 'Часто задаваемые вопросы',
    kindergarten_guide: 'Руководство пользователя',
    kindergarten_video: 'Видео инструкция пользователя',
    kindergarten_go: 'Перейти',

    kindergarten_article_h1: 'Остались вопросы? Обязательно на них ответим!',
    kindergarten_article_title: 'Полезные советы',
    kindergarten_articles_title: 'Первые дни в детском саду. Адаптация родителей',
    kindergarten_articles_text: 'Почти все дети плачут по утрам в первые дни посещения детского сада. И это нормально. Наоборот, было бы странно, если бы ребенок с радостью ушел от мамы в абсолютно неизвестное, новое для него место. Своим криком малыш показывает, что он вас любит и не хочет с вами расставаться. Особенно сильно это проявляется у детей, которые все время проводят только с мамой',
    kindergarten_articles_title2: 'Адаптация ребенка к детскому саду, как ее пройти?',
    kindergarten_articles_text2: 'Детям, впервые поступающим в детский сад, рекомендуется укороченное время пребывания в дошкольной организации. Чтобы помочь ребенку привыкнуть, в первое время приводите его в сад не на полный день, а на несколько часов. По мере адаптации к условиям детского сада можно постепенно увеличивать время его пребывания в группе.',
    kindergarten_articles_title3: 'Чем полезен режим дня и как его соблюдать',
    kindergarten_articles_text3: 'Распорядок дня традиционно является неотъемлемой частью воспитания детей, поэтому грамотно организованный режим пойдет на пользу не только малышу, но и его родителям. Соблюдение правильного режима дисциплинирует детей, повышает их работоспособность, аппетит, улучшает сон, способствует физическому и психологическому развитию.',
  },
  kk: {
    kindergarten_title: 'Алматы облысы әкімдігінің ведомстволық бағыныстағы мекемелерінің орталықтандырылған деректер базасы',
    kindergarten_insert_title: 'Бірер минутта балабақша кезегіне орын алыңыз',
    kindergarten_insert_text: 'Мектепкі дейінгі ұйымдарға балаларды Жетісу облысы бойынша кезекке қою',
    kindergarten_subtitle: 'Балабақшаға кезек',
    kindergarten_our_services: 'Біздің қызметтер',
    kindergarten_support: 'Қолдау қызметі',
    kindergarten_faq: 'Жиі қойылатын сұрақтар',
    kindergarten_guide: 'Пайдаланушы нұсқаулығы',
    kindergarten_video: 'Пайдаланушының бейне нұсқаулығы',
    kindergarten_go: 'Өту',

    kindergarten_article_h1: 'Мазалаған сұрақтарыңыз бар ма? Міндетті түрде жауап береміз!',
    kindergarten_article_title: 'Пайдалы кеңес',
    kindergarten_articles_title: 'Балабақшадағы алғашқы күндер. Ата-ананың көнуі',
    kindergarten_articles_text: 'Балалардың барлығы дерлік балабақшаға барған алғашқы күндері таңертең жылайды. Бұл қалыпты жағдай. Керісінше,  егер бала анасымен тез қоштаса алып,  ол үшін мүлдем белгісіз, жаңа жерде қалуды оңай қабылдай алатын оқиғаларды сирек кездестіреміз. Балаңыздың жылағаны сізді жақсы көретінін және сізбен қоштасқысы келмейтінін көрсетеді. Бұл әсіресе анасымен көп уақыт өткізіп үйреніп қалған балаларға қатысты жағдай.',
    kindergarten_articles_title2: 'Балаңың балабақшаға бейімделуі жайлы',
    kindergarten_articles_text2: 'Балабақшаға бірінші рет баратын балалар үшін мектепке дейінгі ұйымға қысқа мерзімнен бастап бару ұсынылады. Алдымен, балаңызды балабақшаға бірден толық күнге емес бірнеше сағатқа ғана әкеліп бастаңыз. Кейін, балабақшаның жағдайына бейімделе отырып, оның топта болу уақытын бірте-бірте арттыруға болады.',
    kindergarten_articles_title3: 'Күн тәртібінің маңызы және оны ұстану пайдасы',
    kindergarten_articles_text3: 'Күнделікті күн тәртібі дәстүрлі түрде балаларды тәрбиелеудің ажырамас бөлігі болып табылады. Сондықтан,  дұрыс ұйымдастырылған режим балаға ғана емес, оның ата-анасына да пайдалы болады. Дұрыс режимді сақтау балаларды тәртіпке келтіреді, олардың тиімділігін, тәбетін арттырады, ұйқыны жақсартады, физикалық және психологиялық дамуына ықпал етеді.',
  }
});
