export const FLC_RULES = {
  readOnlyRule: 'readOnlyRule',
  requiredRule: 'requiredRule',
  validationRule: 'validationRule',
  visibilityRule: 'visibilityRule',
  itemVisibilityRule: 'itemVisibilityRule',
  matrixVisibilityRule: 'matrixVisibilityRule',
  calculationRule: 'calculationRule',
  rowAddRule: 'rowAddRule',
  rowDeletionRule: 'rowDeletionRule',
  inputRestrictionRule: 'inputRestrictionRule'
};

export const RENDERER = {
  text: 'text',
  date: 'date',
  date_time: 'date time',
  short_text: 'short text',
  long_text: 'long text',
  numeric_text: 'numeric text',
  radio_button: 'radio button',
  check_box: 'check box',
  checkbox_group: 'checkbox group',
  dropdown: 'dropdown',
  dropdown_tree: 'dropdown_tree',
  multiple_dropdown_tree: 'multiple_dropdown_tree',

  table: 'table',
  table_cell: 'table_cell',
  document: 'document',
  complex_table: 'complex table',
  complex_inline: 'complex inline',
  group: 'group'
};
