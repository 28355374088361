export function flcHasValue(value) {
  if (value instanceof Array) {
    return value.filter(item => flcHasValue(item)).length > 0;
  }

  return Boolean(
    typeof value === 'number'
    || typeof value === 'boolean'
    || (value instanceof Object && Object.keys(value).length > 0)
    || (typeof value === 'string' && value.trim())
  )
}
