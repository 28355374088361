import React from 'react';
import Group from './Group';

function GenerateGroups({
  groups,
  questions,
  lang,
  disabled,
  renderAfterGroup,
  betweenRender,
  hideMode,
  excludeGroupId,
  groupHided,
  firstLevel
}) {
  return groups.map(group => (
    <React.Fragment key={group.id}>
      <Group
        {...group}
        hideTitle={firstLevel && groups.length === 1}
        hideMode={hideMode}
        questions={questions}
        groupHided={groupHided}
        excludeGroupId={excludeGroupId}
        lang={lang}
        disabled={disabled}
        betweenRender={betweenRender}
      />
      {renderAfterGroup && renderAfterGroup[group.id]}
    </React.Fragment>
  ));
}

export default GenerateGroups;