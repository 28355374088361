class ChangeTrigger {
  static subscribers = {};

  // подписка на изменения
  static subscribe(dependency, code, callback) {
    if (dependency) {
      for (const item of Object.values(dependency)) {
        if (item.complexCode && item.columnCode) {
          let key = `${item.complexCode}_${item.columnCode}`;
          this.setSubscriber(item.code, key, code, callback);
        } else if (item.complexCode) {
          this.setSubscriber(item.code, item.complexCode, code, callback);
        } else if (item.columnCode) {
          this.setSubscriber(item.code, item.columnCode, code, callback);
        } else if (item.coordinates) {
          let key = JSON.stringify(item.coordinates);
          this.setSubscriber(item.code, key, code, callback);
        } else {
          this.setSubscriber(item.code, null, code, callback);
        }
      }
      // unsubscribe
      return () => {
        for (const item of Object.values(dependency)) {
          if (item.complexCode && item.columnCode) {
            let key = `${item.complexCode}_${item.columnCode}`;
            this.unsubscribe(item.code, key, code);
          } else if (item.complexCode) {
            this.unsubscribe(item.code, item.complexCode, code);
          } else if (item.columnCode) {
            this.unsubscribe(item.code, item.columnCode, code);
          } else if (item.coordinates) {
            let key = JSON.stringify(item.coordinates);
            this.unsubscribe(item.code, key, code);
          } else {
            this.unsubscribe(item.code, null, code);
          }
        }
      };
    }
  }

  static setSubscriber(code, key, subscriber, callback) {
    if (key) {
      if (this.subscribers.hasOwnProperty(code)) {
        if (this.subscribers[code].hasOwnProperty(key)) {
          this.subscribers[code][key][subscriber] = callback;
        } else {
          this.subscribers[code][key] = { [subscriber]: callback };
        }
      } else {
        this.subscribers[code] = { [key]: { [subscriber]: callback } };
      }
    } else {
      if (this.subscribers.hasOwnProperty(code)) {
        this.subscribers[code][subscriber] = callback;
      } else {
        this.subscribers[code] = { [subscriber]: callback };
      }
    }
  }

  static unsubscribe(code, key, subscriber) {
    if (key) {
      if (this.subscribers.hasOwnProperty(code)) {
        if (this.subscribers[code].hasOwnProperty(key)) {
          delete this.subscribers[code][key][subscriber];
        }
      }
    } else {
      if (this.subscribers.hasOwnProperty(code)) {
        delete this.subscribers[code][subscriber];
      }
    }
  }

  // триггер по изменениям
  static change(code, value, config) {
    if (config && Object.keys(config).length > 0) {
      // динамическая таблица
      if (config.isComplex) {
        this.changeComplex(code, value, config);
      } else if (config.isComplexCell) {
        this.changeComplexCell(code, value, config);
      } else if (config.isTable) {
        this.changeTable(code, value, config);
      } else if (config.isTableCell) {
        this.changeTableCell(code, value, config);
      }
    } else if (this.subscribers.hasOwnProperty(code)) {
      this.executeCallbacks(this.subscribers[code], code);
    }
  }

  static changeComplex(code, value, config) {
    // динамическая таблица - столбец
    if (this.subscribers[code]) {
      if (config.cellsQuestions) {
        for (const questionCode of Object.keys(config.cellsQuestions)) {
          if (this.subscribers[code][questionCode]) {
            this.executeCallbacks(this.subscribers[code][questionCode], code);
          }
        }
      } else {
        // выполняется если очищаются данные полсе скрытия
        let executed = [];
        for (const columnOrRow of Object.keys(this.subscribers[code])) {
          this.executeCallbacks(this.subscribers[code][columnOrRow], code, executed);
          executed.push(columnOrRow);
        }
      }
    }
  }

  static changeComplexCell(code, value, config) {
    if (this.subscribers[config.code]) {
      // динамическая таблица - столбец
      if (this.subscribers[config.code][config.questionCode]) {
        this.executeCallbacks(this.subscribers[config.code][config.questionCode], code);
      }
      // динамическая таблица - строка
      if (this.subscribers[config.code][config.complexCode]) {
        this.executeCallbacks(this.subscribers[config.code][config.complexCode], code);
      }
      // динамическая таблица - ячейка
      if (this.subscribers[config.code][`${config.complexCode}_${config.questionCode}`]) {
        this.executeCallbacks(
          this.subscribers[config.code][`${config.complexCode}_${config.questionCode}`],
          code
        );
      }
    }
  }

  static changeTable(code) {
    // статическая таблица - поиск по кординатам
    if (this.subscribers[code]) {
      let executed = [];
      for (const cellObject of Object.values(this.subscribers[code])) {
        this.executeCallbacks(cellObject, code, executed);
        executed.push(cellObject);
      }
    }
  }

  static changeTableCell(code, value, config) {
    // статическая таблица - поиск по кординатам
    if (this.subscribers[config.code]) {
      let configCoordinates = {
        ...config.coordinates.row,
        ...config.coordinates.column
      };
      for (const coordinateKey of Object.keys(this.subscribers[config.code])) {
        let coordinates = JSON.parse(coordinateKey);

        if (coordinates instanceof Object) {
          let founded = true;
          for (const key of Object.keys(coordinates)) {
            if (configCoordinates[key] !== coordinates[key]) {
              founded = false;
            }
          }
          if (founded) {
            this.executeCallbacks(this.subscribers[config.code][coordinateKey], code);
          }
        } else if (typeof this.subscribers[config.code][coordinateKey] === 'function') {
          // если подписка идет на саму таблицу а не на ячейки
          this.subscribers[config.code][coordinateKey]();
        }
      }
    }
  }

  static executeCallbacks(subscribers, code, executedSubscribers = []) {
    for (const subscriber of Object.keys(subscribers)) {
      // проверка, если менятся сам, чтобы самого себя не запускать
      if (
        subscriber !== code &&
        subscribers[subscriber] &&
        !executedSubscribers.includes(subscriber)
      ) {
        subscribers[subscriber](code);
      }
    }
  }
}
export default ChangeTrigger;
