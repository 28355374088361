import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContentText, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../modules/Button/Button';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../modules/DatePicker/DatePicker';
import Select from '../../../modules/Select/Select';
import moment from 'moment';
import { encodeBase64 } from '../../../utils/encodeBase64';

export default function DialogExtend({ data, open, handleClose, handleDone, loading }) {
  const { t } = useTranslation();
  const [date, setDate] = useState('');
  const [cause, setCause] = useState(null);
  const [file, setFile] = useState(null);
  const onChangeDate = value => {
    setDate(value);
  };
  const clearState = () => {
    setDate('');
    setCause(null);
    setFile(null);
  };
  const onClose = () => {
    clearState();
    handleClose();
  };
  const onDone = async () => {
    try {
      const fileBase64 = await encodeBase64(file);
      const data = {
        extensionDirection: cause,
        secondDirDate: date,
        docProlongFileData: fileBase64,
        docProlongFileName: file.name
      };

      handleDone(data, clearState);
    } catch (error) {}
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('kindergarten_appointments_modal_extend_title')}
      </DialogTitle>
      <DialogContent>
        <div className="mb1">
          <div className={'flex justify-between items-center mb1'}>
            <div style={{ flex: 2.0 }}>
              {t('kindergarten_appointments_modal_extend_date')}
            </div>
            <div style={{ flex: 4.0 }}>
              <DatePicker
                minDate={moment(data?.firstDirDate || moment()).add(1, 'day')}
                maxDate={moment(data?.firstDirDate || moment()).add(30, 'day')}
                withoutForm={true}
                onChange={onChangeDate}
                value={date}
                name="second_dir_date"
                helperText={''}
                format="DD.MM.YYYY"
                autoOk
              />
            </div>
          </div>
          <div className={'flex justify-between items-center mb1'}>
            <div style={{ flex: 2.0 }}>
              {t('kindergarten_appointments_modal_extend_cause')}
            </div>
            <div style={{ flex: 4.0 }}>
              <Select
                withoutForm={true}
                lookupId={'gu_dir_extension'}
                name={'extension_direction'}
                value={cause}
                onChange={event => {
                  setCause(event.target.value);
                }}
              />
            </div>
          </div>

          <div className={'mb1'}>
            <input
              style={{ display: 'none' }}
              id="contained-button-file"
              multiple={false}
              onChange={e => {
                setFile(e.target.files[0]);
              }}
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                text={file ? file.name : t('kindergarten_appointments_modal_extend_file')}
                component="span"
              />
            </label>
          </div>
        </div>

        <DialogContentText id="alert-dialog-description">
          {t('kindergarten_appointments_modal_extend_body')}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{textAlign: "center"}}>
        <div style={{width: "50%"}}>
          <Button
            onClick={() => {onClose()}}
            loading={loading}
            variant="contained"
            text={t('kindergarten_appointments_modal_extend_no')}
            color="primary"
          />
        </div>
        <div style={{width: "50%"}}>
          <Button
            disabled={!(date && cause && file !== null)}
            onClick={() => {onDone()}}
            loading={loading}
            variant="contained"
            text={t('kindergarten_appointments_modal_extend_yes')}
            color="secondary"
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
