import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {StyledInsert, StyledInsertContent, StyledInsertTitle, StyledInsertText, StyledInsertColorBg} from './HomeSectionStyles';
import { Content } from '../../../components/ThemeProvider/ThemeStyle';
import zhetysuHomeBg from '../../../_assets/images/zhetysuHomeBg.png';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { loginModalHandler } from '../../../pages/LoginPage/LoginDucks';
import { useSelector } from 'react-redux';
import { loginModule } from '../../../pages/LoginPage/LoginDucks';

const ColorButton = withStyles((theme) => ({
  root: {
      padding: '20px 40px',
      fontWeight: '700',
      fontSize: '14px',
      textTransform: 'uppercase',
      letterSpacing: '0.01em',
      fontFamily: 'Montserrat',
      '@media (max-width:1000px)': {
          padding: '15px 30px',
      }
  }
}))(Button);

function HomeInsert({contentWidth}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state[loginModule]);

  return (
    <StyledInsert>
        <img src={zhetysuHomeBg} alt={t('home_insert_title')} />
        <StyledInsertColorBg />
            <StyledInsertContent>
            <Content contentWidth={contentWidth}>
                <StyledInsertTitle children={t('home_insert_title')} />
            </Content>
            <Content contentWidth={contentWidth}>
                <StyledInsertText children={t('home_insert_text')} />
            </Content>
            {
              !user ? 
              <Content contentWidth={contentWidth}>
                <div>
                  <ColorButton
                    variant="contained"
                    color="secondary"
                    children={t('home_insert_btn')}
                    onClick={() => dispatch(loginModalHandler(true))}
                  />  
                </div>
              </Content> : null
            }
            </StyledInsertContent>
    </StyledInsert>
  );
}

export default HomeInsert;
