import React from 'react';
import withOptions from '../flc/withOptions';
import DTreeSelect from './DTreeSelect/DTreeSelect';
import DSelect from './DSelect';

function DDropdown(props) {
  if (props.isTreeOption) {
    return <DTreeSelect {...props} />;
  } else {
    return <DSelect {...props} />;
  }
}

export default withOptions(DDropdown);
