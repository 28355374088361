import React, { useEffect, useState, useCallback } from 'react';
import Trigger from '../../FormData/ChangeTrigger';
import Form from '../../FormData/PassportForm';
import { runFlc } from '../../Flc/execute/runFlc';
import { TestMode } from '../../Flc/TestMode';
import { flcHasValue } from '../../Utils/flcHasValue';
import FormChange from '../../FormData/FormChange';
import { handleQuestionLabel } from '../../Utils/handleQuestionLabel';
import uuid from 'uuid/v4';
import { cloneObject } from '../../Utils/cloneObject';

const checkCellValue = (code, cellValue, cellHasValue, setCellHasValue) => {
  const hasValue = flcHasValue(cellValue);
  if (hasValue !== cellHasValue) {
    if (hasValue) {
      setCellHasValue(true);
    } else {
      checkCellValues(code, cellHasValue, setCellHasValue);
    }
  }
};

const checkCellValues = (code, cellHasValue, setCellHasValue) => {
  let hasValue = false;
  if (Form.cellsValues[code]) {
    for (const rowValue of Object.values(Form.cellsValues[code])) {
      for (const cellValue of Object.values(rowValue)) {
        if (flcHasValue(cellValue)) {
          hasValue = true;
          break;
        }
      }
      if (hasValue) break;
    }
  }
  if (hasValue !== cellHasValue) {
    setCellHasValue(hasValue);
    return true;
  }
};

function svComplexCode(savedValue) {
  return flcHasValue(savedValue) ? savedValue : [uuid()];
}

function withComplexFlc(Component) {
  /**
   * @return {null}
   */
  function WithComplexFlc(props) {
    const { code, savedValue, dependency: deps, cellsQuestions, groupHided } = props;
    const [complexCodes, setComplexCodes] = useState(svComplexCode(savedValue));
    const [cellHasValue, setCellHasValue] = useState(props.cellHasValue);
    const [cellsDefaultValue, setCellsDefaultValue] = useState({});
    const [cellsSource, setCellsSource] = useState(props.cellsSource);
    const [flc, setFlc] = useState({});

    const handleFlc = useCallback(
      () => {
        let newFlc = runFlc(props, flc.visibility, cellHasValue ? true : undefined);
        if (JSON.stringify(newFlc) !== JSON.stringify(flc)) {
          setFlc(newFlc);
        }
        if (newFlc.calculation !== undefined) {
          Form.setValue(code, newFlc.calculation, { isComplex: true });
          setComplexCodes(
            flcHasValue(newFlc.calculation)
              ? svComplexCode(savedValue)
              : newFlc.visibility
              ? svComplexCode([])
              : []
          );
          let newFlc2 = runFlc(props, newFlc.visibility, cellHasValue ? true : undefined);
          if (JSON.stringify(newFlc2) !== JSON.stringify(newFlc)) {
            setFlc(newFlc2);
          }
        }
      },
      // eslint-disable-next-line
      [cellHasValue, flc, groupHided]
    );

    useEffect(handleFlc, [cellHasValue, groupHided]);
    useEffect(() => setComplexCodes(svComplexCode(savedValue)), [savedValue]);
    useEffect(() => Form.setFlc(code, flc), [code, flc]);
    useEffect(() => Trigger.subscribe(deps, code, handleFlc), [deps, code, handleFlc]);
    useEffect(() => TestMode.register(code, handleFlc), [code, handleFlc]);
    useEffect(
      () =>
        FormChange.subscribe(code, (complexCodes, cellsDefaultValue, cellsSource) => {
          Form.setValue(code, cloneObject(cellsDefaultValue), { isComplex: true });
          setCellsDefaultValue(cellsDefaultValue);
          setCellsSource(cellsSource);
          setComplexCodes(complexCodes);
        }),
      [code]
    );

    // скрываем по правилам флк
    if (!flc.visibility || flc.hideMode) {
      if (window.test) {
        return (
          <div style={{ opacity: 0.5 }}>
            Скрытый показатель - {handleQuestionLabel(props, 'ru')}
          </div>
        );
      }
      return null;
    }

    return (
      <Component
        {...props}
        error={flc.validation}
        required={flc.required}
        checkCellHasValue={cellValue =>
          checkCellValue(code, cellValue, cellHasValue, setCellHasValue)
        }
        cellsSource={cellsSource}
        cancelValidation={!cellHasValue && complexCodes.length === 1}
        cellsDefaultValue={cellsDefaultValue}
        complexCodes={complexCodes}
        disabled={props.disabled || flc.readOnly}
        rowAdd={!flc.rowAdd}
        rowDeletion={flc.rowDeletion}
        onAddRow={complexCode => {
          const config = { isComplex: true, type: 'add', cellsQuestions };
          Form.setValue(code, complexCode, config);
          setComplexCodes([...complexCodes, complexCode]);
          handleFlc();
        }}
        onDeleteRow={complexCode => {
          const newCode = uuid();
          const newCC = complexCodes.filter(code => code !== complexCode);
          const config = {
            isComplex: true,
            type: 'delete',
            cellsQuestions,
            newCode: newCC.length === 0 ? newCode : null
          };

          Form.setValue(code, complexCode, config);
          let updated = checkCellValues(code, cellHasValue, setCellHasValue);
          if (newCC.length === 0) {
            setComplexCodes([newCode]);
          } else {
            setComplexCodes(complexCodes.filter(code => code !== complexCode));
          }
          if (!updated) handleFlc();
        }}
      />
    );
  }
  return WithComplexFlc;
}

export default withComplexFlc;
