import React from 'react';
import InfoPassport from './InfoPassport'

export function extraFunctionality({ code, value }) {
  if (code === 'requester_short.house' ||
      code === 'requester_short.flat' ||
      code === 'requester_short.city' ||
      code === 'requester_short.street'
  ) {
    return <InfoPassport code={code} value={value} />
  }
}
