import React from 'react';
import PropTypes from 'prop-types';
import RcTreeSelect from 'rc-tree-select';
import { injectIntl } from 'react-intl';
import { Div } from './TreeSelectStyles';
import { ReactComponent as ArrowDownIcon } from '../../static/arrow-down-light.svg';

class TreeSelect extends React.Component {
  state = {
    keys: [],
    open: false
  };

  componentDidMount() {
    if (!this.props.keys) {
      this.iniExpandKeys();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.keys) {
      if (
        prevProps.value !== this.props.value ||
        prevProps.tree !== this.props.tree
      ) {
        this.iniExpandKeys();
      }
    }
  }

  iniExpandKeys = () => {
    const { tree, value } = this.props;

    if (tree.length) {
      let keys = [];
      if (value instanceof Array) {
        for (let val of value) {
          keys = keys.concat(this.getExpandedKeys(val, tree));
        }
      } else {
        keys = this.getExpandedKeys(value, tree);
      }
      this.setState({ keys });
    }
  };

  getExpandedKeys = (value, tree) => {
    let result = [];
    for (let item of tree) {
      if (item.value === value) {
        result = [];
      } else {
        let children = this.getExpandedKeys(value, item.children);
        if (children.length) {
          result = [...children];
        }
      }

      if (result.length) break;
    }
    return result;
  };

  handleChange = (code, title, trigger) => {
    const { onChange, multiple, value, tree } = this.props;

    if (multiple) {
      let unCheckValues = this.checkValues(trigger, tree, value);
      let resultCode = code;
      if (unCheckValues.length > 0 && trigger.selected) {
        let result = [];
        for (let itemCode of code) {
          if (!unCheckValues.includes(itemCode)) {
            result.push(itemCode);
          }
        }
        resultCode = result;
      }
      let paths = [];
      for (let item of resultCode) {
        paths.push(this.getHierarchyPath(item, tree));
      }
      if (typeof onChange === 'function') {
        onChange(resultCode, title, paths);
      }
    } else {
      if (typeof onChange === 'function') {
        let path = this.getHierarchyPath(code, tree);
        if (code === undefined) code = '';
        onChange(code, title, path);
      }
    }
  };

  getHierarchyPath = (code, tree) => {
    let result = false;
    for (let item of tree) {
      if (item.value === code) {
        result = { title: item.title, value: item.value };
      } else if (item.hasOwnProperty('children')) {
        let children = this.getHierarchyPath(code, item.children);
        if (children) {
          result = {
            title: item.title,
            value: item.value,
            children: children
          };
        }
      }
    }
    return result;
  };

  checkValues = (trigger, treeData, value) => {
    let result = [];
    let isChild = (value, tree) => {
      let isChildResult = false;
      for (let item of tree) {
        if (item.props.value === value) {
          isChildResult = true;
          break;
        } else if (item.props.hasOwnProperty('children')) {
          isChildResult = isChild(value, item.props.children);
          if (isChildResult) break;
        }
      }
      return isChildResult;
    };

    let isParent = (value, hierarchy) => {
      let isParentResult = false;
      if (hierarchy.value === value) {
        isParentResult = true;
      } else if (hierarchy.hasOwnProperty('children')) {
        isParentResult = isParent(value, hierarchy.children);
      }
      return isParentResult;
    };

    let parentTree = this.getHierarchyPath(trigger.triggerValue, treeData);
    let childTree = trigger.triggerNode.props.children;

    if (value instanceof Array) {
      for (let val of value) {
        if (isChild(val, childTree)) {
          result.push(val);
        } else if (isParent(val, parentTree)) {
          result.push(val);
        }
      }
    }
    return result;
  };

  setTitles = tree => {
    for (const item of tree) {
      item.title = item[`${this.props.intl.locale}_name`];
      if (item.hasOwnProperty('children')) {
        this.setTitles(item.children)
      }
    }
    return tree
  };

  render() {
    const {
      error,
      disabled,
      tree,
      dropdownFixed,
      keys,
      onExpand,
      hideErrorText,
      parentSelectable,
      changedMode,
      intl
    } = this.props;
    let ddStyle = { maxHeight: 350, overflow: 'auto', maxWidth: 500 };
    if (dropdownFixed) ddStyle.position = 'fixed';
    return (
      <Div
        className="control has-icons-right"
        disabled={disabled}
        error={error}
        borderColor={this.props.borderColor}
        size={this.props.size}
        style={this.props.style}
        changedMode={changedMode}
      >
        <RcTreeSelect
          placeholder={intl.formatMessage({id: 'ui.select.placeholder'})}
          {...this.props}
          open={this.state.open}
          treeExpandedKeys={keys ? keys : this.state.keys}
          onTreeExpand={onExpand ? onExpand : keys => this.setState({ keys })}
          inputIcon={<ArrowDownIcon style={{ width: '20px' }} />}
          treeData={this.setTitles([...tree])}
          dropdownStyle={ddStyle}
          allowClear={!disabled}
          showSearch={false}
          switcherIcon={<div className="kato-switch-icon" />}
          loadData={typeof this.props.loadData === 'function' ? node => {
            let promise = this.props.loadData(node);
            promise.then(childCount => {
              if (this.cancelChange && childCount === 0) {
                this.handleChange(node.props.value);
                this.cancelChange = false;
                setTimeout(()=> {
                  this.setState({ open: false });
                });
              }
            });
            return promise;
          } : undefined}
          onChange={(code, title, trigger) => {
            if (!this.cancelChange) {
              this.handleChange(code, title, trigger);
            }
          }}
          onDropdownVisibleChange={open => {
            setTimeout(()=> {
              if (this.props.onDropdownVisibleChange) {
                this.props.onDropdownVisibleChange(open);
              }
              if (!this.cancelChange) {
                this.setState({ open });
              }

              if (this.cancelChangeParent) {
                this.cancelChange = false;
                this.cancelChangeParent = false;
              }
            });
          }}
          onSelect={(value, node) => {
            if (!node.isLeaf() && !parentSelectable) {
              this.cancelChange = true;

              if (node.props.children.length > 0) {
                this.cancelChangeParent = true;
              } else {
                if (keys && onExpand) {
                  if (!keys.includes(value)) {
                    onExpand([...keys, value])
                  }
                } else {
                  if (!this.state.keys.includes(value)) {
                    this.setState({ keys: [...this.state.keys, value ]})
                  }
                }
              }
            }
          }}
        />
        {error && !hideErrorText && (
          <span className="has-error-text">{error}</span>
        )}
      </Div>
    );
  }
}

TreeSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  tree: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  error: PropTypes.any,
  hideErrorText: PropTypes.bool,
  placeholder: PropTypes.any,
  keys: PropTypes.array,
  onExpand: PropTypes.func,
  dropdownFixed: PropTypes.bool,
  parentSelectable: PropTypes.bool
};

TreeSelect.defaultProps = {
  parentSelectable: true,
};


export default injectIntl(TreeSelect);
