import { RENDERER } from '../../Utils/Constants';
import moment from 'moment';
import FlcDate from '../FlcDate';

export const valueTypes = (renderer, initialValue) => {
  if (renderer === RENDERER.date) {
    if (initialValue === undefined) {
      initialValue = moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);
    }
    return initialValue ? new FlcDate(initialValue) : null;
  }
  if (renderer === RENDERER.date_time) {
    if (initialValue === undefined) {
      initialValue = moment();
    }
    return initialValue ? new FlcDate(initialValue) : null;
  }
  if (renderer === RENDERER.numeric_text) {
    if (initialValue && typeof initialValue !== 'number') {
      return parseInt(initialValue);
    } else {
      return initialValue === '' ? null : initialValue;
    }
  }
  return initialValue === '' ? null : initialValue;
};
