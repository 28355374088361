import { changeFormValue } from '../../../passport/Passport';

export default class ZeroingQuestionValue {
  static DDO_CODES = [
    `request_form_doc_queued_ddo_short.queued_type`,
    `request_form_doc_queued_ddo_short.correction_type`,
    `request_form_doc_queued_ddo_short.rehab_type`,
    `request_form_doc_queued_ddo_short.queued_region`,
  ]

  static values = {
    'request_form_doc_queued_ddo_short.queued_type': '',
    'request_form_doc_queued_ddo_short.correction_type': '',
    'request_form_doc_queued_ddo_short.rehab_type': '',
    'request_form_doc_queued_ddo_short.queued_region': '',
  }

  static DDO_CODES_TO_ZEROING = [
    'request_form_doc_queued_ddo_short.choice_ddo',
    'request_form_doc_queued_ddo_short.choice_ddo_kk',
    'request_form_doc_queued_ddo_short.bin_ddo'
  ]

  static clearState() {
    this.values = {
      'request_form_doc_queued_ddo_short.queued_type': '',
      'request_form_doc_queued_ddo_short.correction_type': '',
      'request_form_doc_queued_ddo_short.rehab_type': '',
      'request_form_doc_queued_ddo_short.queued_region': '',
    }
  }

  static setValue(code, value) {
    if (this.DDO_CODES.includes(code)) {
      if(this.values[code] !== value?.code) {
        this.values[code] = value?.code;
        this.DDO_CODES_TO_ZEROING.forEach(code => {
          changeFormValue(code, '');
        });
      }
    }
  }
}