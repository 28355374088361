import Notice from './Notice';
import Dict from './Dict';
import i18next from 'i18next';

export function handleError(error, message = '', type = 'error') {
  let errorMsg = error.message;
  if (error && error.request) {
    errorMsg = `
      STATUS: ${error.request.status},
      STATUS_TEXT: ${error.request.statusText}
      METHOD: ${error.config.method},
      URL: ${error.config.url}
      ${
        error.config.data && !error.config.url.includes('/login')
          ? `REQUEST_BODY:  ${error.config.data} \n`
          : ''
      }
    `;
    if (error.data && error.data.results instanceof Array) {
      errorMsg += `RESPONSE_BODY: ${JSON.stringify(
        error.data.results.filter(item => item.status !== 'OK')
      )}`;
    } else {
      errorMsg += `RESPONSE_BODY: ${JSON.stringify(error.data)}`;
    }
  }

  if (error && error.response) {
    errorMsg = `
      STATUS: ${error.response.status},
      STATUS_TEXT: ${error.response.statusText}
      METHOD: ${error.response.config.method},
      URL: ${error.response.config.url}
      ${
        error.response.config.data && !error.response.config.url.includes('/login')
          ? `REQUEST_BODY:  ${error.response.config.data} \n`
          : ''
      }
    `;
    if (error.response.data) {
      errorMsg += `RESPONSE_BODY: ${JSON.stringify(error.response.data)}`;
    } else {
      errorMsg += `RESPONSE_BODY: ${JSON.stringify(error.response)}`;
    }
  }

  if (message) {
    message += '\n';

    try {
      Dict.itemsObject('error_message')
        .then(dict => {
          let notFound = true;
          const errors = error?.response?.data?.errors || [];
          const messages = errors.map(e => {
            if (dict.hasOwnProperty(e.code)) {
              notFound = false;
              return dict[e.code][`${i18next.language}_name`];
            } else {
              return e.code;
            }
          });

          if (notFound) {
            Notice[type](`${message}${errorMsg}`);
          } else {
            Notice[type](messages.join('\n'));
          }
        })
        .catch(() => Notice[type](`${message}${errorMsg}`));
    } catch (ignore) {
      Notice[type](`${message}${errorMsg}`);
    }
  }
  return `${message}${errorMsg}`;
}
