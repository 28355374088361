import { getFlcRules } from '../../Utils/getFlcRules';
import { findMatches } from './findMatches';

export function searchGroupsDependencies(groups, metadataKeys, questions) {
  for (const group of groups) {
    let dependency = {};

    const findCode = code => {
      if (code.indexOf('.') === -1) {
        if (questions.hasOwnProperty(`${group.metaDataId}.${code}`)) {
          return `${group.metaDataId}.${code}`;
        } else {
          for (const metaDataKey of metadataKeys) {
            if (questions.hasOwnProperty(`${metaDataKey}.${code}`)) {
              return `${metaDataKey}.${code}`;
            }
          }
        }
      } else {
        return code;
      }
      return `notExist_${code}`;
    };

    // зависимости показателей
    for (const rule of getFlcRules(group.renderer)) {
      if (group[rule]) {
        findMatches(findCode, dependency, group[rule]);
      }
    }
    if (Object.keys(dependency).length > 0) {
      group.dependency = dependency;
    }

    if (group.hasOwnProperty('subGroups') && group.subGroups.length > 0) {
      searchGroupsDependencies(group.subGroups, metadataKeys, questions);
    }
  }
}
