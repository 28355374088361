import { createReducer } from 'redux-starter-kit';
import i18next from 'i18next';
import axios from 'axios';
import { handleError } from '../../../modules/utils/handleError';
import PassportForm from '../../../passport/FormData/PassportForm';

const Api = {
  loadTableData: (filter) => {
    return axios.get(
      `/api/public/data/service_provider_po_sanatorium_or_special_filter${filter}`
    );
  }
};
/**
 * Constants
 */

export const selectDDoModule = 'selectDDo';
const LOADING = `${selectDDoModule}/LOADING`;
const SET_DATA = `${selectDDoModule}/SET_DATA`;
const CLEAR_STATE = `${selectDDoModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: true,
  data: []
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = () => async dispatch => {
  try {
    const key = 'request_form_doc_queued_ddo_short';
    const queuedType = PassportForm.values[key + '.queued_type'];
    const correctionType = PassportForm.values[key + '.correction_type'];
    const locationCode = PassportForm.values[key + '.queued_region'];
    const rehabType = PassportForm.values[key + '.rehab_type'];

    if ((correctionType || rehabType) && locationCode) {
      dispatch({ type: LOADING, payload: true });
      let filter = `?locationCode=${locationCode}&queuedType=${queuedType}`;
      if (correctionType) filter+= `&correctionType=${correctionType}`;
      if (rehabType) filter+= `&rehabType=${rehabType}`;

      let { data } = await Api.loadTableData(filter);
      const contentData = getKeyValues(data.content);
      dispatch({ type: SET_DATA, payload: contentData });
    }
  } catch (error) {
    handleError(error, i18next.t('kezekDirection_errorLoad'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

const getKeyValues = data => {
  let res = [];
  data.forEach(item => {
    let obj = {};
    item.passportValues.forEach(a => {
      obj[a.code] = a.value;
    });
    res.push(obj);
  });
  return res;
};

export const clearState = () => ({ type: CLEAR_STATE });
