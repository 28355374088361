export function checkAuth(url){
  switch (url) {
    case 'gov-services':
    case 'kindergarten-services':
    case 'check-queue-number':
    case 'bulletin-open-places':
    case 'queue-applications':
    case 'kindergarten-reestr':
      return false;
    default:
      return true;
  }
}
