import { addTranslation } from '../../../../_helpers/Translate';

addTranslation({
  ru: {
    queue_applications_search_label: 'Поиск по ФИО',
  },
  kk: {
    queue_applications_search_label: 'Іздеу',
  }
});
