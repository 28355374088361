import axios from 'axios';

export const AccountSettingsApi = {
  loadRequesterMeta: () => axios.get(
    `/api/public/metadata/requester_short`
  ),
  updateRequesterData: formData => axios.put(
    `/api/public/passport/requester`, formData
  ),
  getPerson: iin => axios.get(
    `api/public/data/person_fl_by_iin?iin=${iin}`
  ),
  getUserProfile: username =>
    axios.get(`api:services/nedb-entitlement/api/entlmnt/users/${username}`),
};