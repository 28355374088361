import { store } from 'react-notifications-component';

const setMessage = (type, message, title = null) => {
    return store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceInRight'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
            click: true,
            duration: 10000,
            pauseOnHover: true,
            showIcon: true
        },
        slidingEnter: { duration: 0 },
        slidingExit: { duration: 300 }
    });
};

const notice = {
  info: (message, title) => setMessage('info', message, title),
  success: (message, title) => setMessage('success', message, title),
  error: (message, title) => setMessage('danger', message, title),
  warning: (message, title) => setMessage('warning', message, title)
};

export default notice;
