import moment from 'moment';

export function convertToMoment(date) {
  if (date) {
    if (date instanceof moment) {
      return date;
    } else if (date.indexOf('-') !== -1) {
      return moment(date);
    } else {
      return moment(date, "DD.MM.YYYY");
    }
  }
}
