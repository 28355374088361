import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledTable } from './TableColumnStyle';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Colors from '../../../components/ThemeProvider/Colors';

export const requestsColumns = ({
  t,
  lang,
  check_loading,
  modalRevoke,
  pageNumber,
  checkBulletins,
  modalInterested,
  filter,
  modalSelectDDO
}) => {
  return [
    {
      Cell: row => (
        <Column
          data={row.original}
          index={row.index}
          t={t}
          lang={lang}
          pageNumber={pageNumber}
          modalRevoke={modalRevoke}
          filter={filter}
          checkBulletins={checkBulletins}
          check_loading={check_loading}
          modalInterested={modalInterested}
          modalSelectDDO={modalSelectDDO}
        />
      )
    }
  ];
};

const Column = ({
  data,
  t,
  lang,
  modalRevoke,
  pageNumber,
  filter,
  index,
  checkBulletins,
  check_loading,
  modalInterested,
  modalSelectDDO
}) => {
  return (
    <StyledTable>
      <div className="row1">
        <div className="col1">
          <div>
            <b>{(pageNumber - 1) * filter.pageSize + index + 1}</b>
          </div>
          <div>
            <b>{`${data.childSurname || ''} ${data.childName ||
              ''} ${data.childMiddleName || ''}`}</b>
          </div>
          <div>-</div>
          <div>
            <b>{t('kindergarten_applications_birthdate')}:</b> {moment(data.childBirthday).format("DD.MM.YYYY")}
          </div>
        </div>
        <div className="col2">
          {
            data && data.appStatusWeb === 'IN_STOP_LIST' ?
            <Button 
              variant="contained"
              disableElevation
              style={{backgroundColor: Colors.primary}}
              size="small"
              className="tab_but" 
              children={data.appStatusWebStatus[`${lang}_name`]} 
            />
            :
            data.appStatusWebStatus[`${lang}_name`]
          }
        </div>
      </div>
      <div className="row2">
        <div className="col2_1">
          <div>
            <b>{t('kindergarten_applications_iin_child')}:</b> {data.childIin}
          </div>
          <div>
            <b>{t('kindergarten_applications_queue_number')}:</b> {data.position}
          </div>
          <div>
            <b>{t('kindergarten_applications_application_number')}:</b>{' '}
            <b>{data.requestFormId},{' '}</b>
            {
              lang === 'ru' ? 
              <>
                {t('kindergarten_applications_from')}{' '}
                {moment(data.submissionDate).format("DD.MM.YYYY")}
              </> :
              <>
                {moment(data.submissionDate).format("DD.MM.YYYY")}{' '}
                {t('kindergarten_applications_from')}
              </>
            }
          </div>
          <div>
            <b>{t('kindergarten_applications_queue_type')}:</b>{' '}
            {data.queuedTypeStatus && data.queuedTypeStatus[`${lang}_name`]}
          </div>
          <div>
            <b>{t('kindergarten_applications_benefit')}:</b>{' '}
            {data.priorityQueuedStatus && data.priorityQueuedStatus[`${lang}_name`]}
          </div>
          <div>
            <b>{t('kindergarten_applications_region')}</b>{' '}
            {data.providerLocation[`${lang}_name`]}
          </div>
          {
            data.interestedKindergartenShortInfos.map((item, index) => {
              return (
                <div key={index}>
                  <b>{t('kindergarten_applications_DDO')}{index+1}:</b>{' '}
                  { item[`${lang}_name`] }
                </div>
              )
            })
          }
        </div>
        <div className="col2_2">
          {
            data.appStatusWeb === 'IN_STOP_LIST' && 
            <>
              <div>
                <b>{t('kindergarten_applications_date_transition_stop_list')}</b>{' '}
                {moment(data.startStopList).format("DD.MM.YYYY")}
              </div>
              <div>
                <b>{t('kindergarten_applications_date_exit_stop_list')}</b>{' '} 
                {moment(data.deadlineStopList).format("DD.MM.YYYY")}
              </div>
            </>
          }
          {
            data.appStatusWeb === 'IN_ARCHIVED' && 
            <div>
              <b>{t('kindergarten_applications_archive_date')}</b>{' '}
              {moment(data.startArchived).format("DD.MM.YYYY")}
            </div>
          }
        </div>
        <div className="col2_3">
          <div>
            {data && ['IN_QUEUED', 'PRIORITY_ACCESS', 'WAITING_PRIORITY'].includes(data.appStatus) ? (
              <>
                {check_loading ? (
                  <CircularProgress size={23} />
                ) : (
                  <Button
                    onClick={() => checkBulletins(data)}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className="tab_but"
                  >
                    {t('kindergarten_applications_actions_get_appointment')}
                  </Button>
                )}
              </>
            ) : null}
          </div>
          { data?.isInterested === false &&
            (data?.isArchived === false) &&
            (data?.appStatus && ['IN_QUEUED', 'STOP_LIST_DC'].includes(data.appStatus)) && (
              <div style={{ paddingTop: '10px' }}>
                <Button
                  onClick={() => modalInterested.open(data)}
                  variant="contained"
                  color="secondary"
                  size="small"
                  className="tab_but"
                >
                  {t('kindergarten_applications_button_interested_text')}
                </Button>
              </div>
            )}

            {data && ['IN_QUEUED', 'PRIORITY_ACCESS'].includes(data.appStatus) ? (
              <div style={{ paddingTop: '10px' }}>
                <Button
                  onClick={() => modalRevoke.open(data)}
                  variant="contained"
                  color="secondary"
                  size="small"
                  className="tab_but_revoke"
                >
                  {t('kindergarten_applications_actions_revoke')}
                </Button>
              </div>
            ) : null}
            
            {
              data && ['IN_QUEUED', 'PRIORITY_ACCESS'].includes(data.appStatus) ? (
                <div style={{ paddingTop: '10px' }}>
                  <Button
                    onClick={() => {
                      modalSelectDDO.open(data)
                    }}
                    variant="contained"
                    size="small"
                    className="tab_but_revoke"
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    {t('kindergarten_applications_selectDDO_btn')}
                  </Button>
                </div>
              ) : null
            }
        </div>
      </div>
    </StyledTable>
  );
};
