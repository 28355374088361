import { flcHasValue } from '../../Utils/flcHasValue';
import { convertToMoment } from '../../Utils/convertToMoment';
import lodash from 'lodash';
import PassportForm from '../../FormData/PassportForm';
import { valueTypes } from './valueTypes';
import FlcDate from '../FlcDate';
import { DomainData } from '../../Utils/DomainData';

export function createContext(metaDataId) {
  return {
    s(path) {
      let value = lodash.get(PassportForm.info, path, null);
      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
        return new FlcDate(value);
      } else {
        return value;
      }
    },
    qUpdatedAt(mCode) {
      const code = PassportForm.findCode(metaDataId, mCode);
      if (code) {
        if (PassportForm.questions[code]) {
          if (PassportForm.questions[code].stamp) {
            if (PassportForm.questions[code].stamp.qUpdatedAt) {
              return new FlcDate(PassportForm.questions[code].stamp.qUpdatedAt);
            }
          }
        }
      }
      return null;
    },
    q(mCode) {
      const code = PassportForm.findCode(metaDataId, mCode);
      if (code) {
        let value = PassportForm.values[code];
        if (PassportForm.questions[code]) {
          const { renderer } = PassportForm.questions[code];
          return valueTypes(renderer, value);
        }
        return value === '' ? null : value;
      }
    },
    qs(mCode) {
      const code = PassportForm.findCode(metaDataId, mCode);
      if (code) {
        if (PassportForm.questions[code]) {
          const { renderer, savedValue } = PassportForm.questions[code];
          return valueTypes(renderer, savedValue);
        }
        return null;
      }
    },
    getDictionary(lookupId) {
      if (DomainData.cash[lookupId] instanceof Array) {
        return DomainData.cash[lookupId];
      } else {
        return []
      }
    },
    qDictionaryItem(questionCode) {
      let result = {};
      const code = PassportForm.findCode(metaDataId, questionCode);
      if (code) {
        let value = PassportForm.values[code];
        if (PassportForm.questions[code]) {
          const { renderer, lookupId } = PassportForm.questions[code];
          const questionValue = valueTypes(renderer, value);
          if (DomainData.cash[lookupId] instanceof Array) {
            const dict = DomainData.cash[lookupId];
            const item = dict.find(item => item.code === questionValue);
            result = { ...item };
          }
        }
      }
      return result;
    },
    hasValue(value) {
      return flcHasValue(value);
    },
    getCurrentDate: function() {
      return new FlcDate(PassportForm.currentDate);
    },
    convertToDate: date => convertToMoment(date),
    tableDomainData: mCode => {
      const code = PassportForm.findCode(metaDataId, mCode);
      if (code) {
        if (PassportForm.questions[code]) {
          return PassportForm.questions[code].domainData;
        }
      }
      return {};
    },
    tableMatrix(mCode, coordinate) {
      let result = [];
      const code = PassportForm.findCode(metaDataId, mCode);
      if (code) {
        if (PassportForm.cellsValues[code]) {
          for (const cell of Object.values(PassportForm.cellsValues[code])) {
            let founded = true;
            const cellCoordinate = {
              ...cell.coordinates.row,
              ...cell.coordinates.column
            };
            for (const code of Object.keys(coordinate)) {
              if (coordinate[code] !== cellCoordinate[code]) {
                founded = false;
              }
            }
            if (founded) result.push(cell.value);
          }
        }
        if (result.length === 1) {
          return flcHasValue(result[0]) ? result[0] : null;
        } else {
          return result.filter(flcHasValue);
        }
      }
      return result;
    },
    complexTableColumn(mCode, columnCode) {
      const result = [];
      const code = PassportForm.findCode(metaDataId, mCode);
      if (code) {
        const renderer = getComplexCellRenderer(code, columnCode);
        if (PassportForm.cellsValues[code]) {
          for (const row of Object.values(PassportForm.cellsValues[code])) {
            if (flcHasValue(row[columnCode])) {
              result.push(valueTypes(renderer, row[columnCode]));
            }
          }
        }
      }
      return result;
    },
    complexTableRowsCount(mCode) {
      const code = PassportForm.findCode(metaDataId, mCode);
      if (code) {
        if (PassportForm.cellsValues[code]) {
          return Object.keys(PassportForm.cellsValues[code]).length
        }
      }
    },
    createComplexTableCell(code, complexCode) {
      this.complexTableCell = (code1, code2) => {
        const columnCode = code2 || code1;
        const path = `['${code}']['${complexCode}']['${columnCode}']`;
        const renderer = getComplexCellRenderer(code, columnCode);
        const value = lodash.get(PassportForm.cellsValues, path, null);
        return valueTypes(renderer, value);
      };
    },
    createComplexTableRow(code, complexCode) {
      this.complexTableRow = () => {
        const result = [];
        const path = `['${code}']['${complexCode}']`;
        const row = lodash.get(PassportForm.cellsValues, path, {});

        for (const [columnCode, value] of Object.entries(row)) {
          const renderer = getComplexCellRenderer(code, columnCode);
          if (flcHasValue(value)) {
            result.push(valueTypes(renderer, value));
          }
        }
        return result;
      };
    }
  };
}

function getComplexCellRenderer(code, columnCode) {
  let renderer;
  if (
    PassportForm.questions[code] &&
    PassportForm.questions[code].cellsQuestions[columnCode]
  ) {
    renderer = PassportForm.questions[code].cellsQuestions[columnCode].renderer;
  }
  return renderer;
}
