import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    sidebar_title: 'Навигация',
    sidebar_home: 'Главная',
    sidebar_users: 'Пользователи',
    sidebar_exit: 'Выход',
    sidebar_requests: 'Заявки в обработке',
    sidebar_completedRequests: 'Завершенные заявки',
    sidebar_ddo: 'Заявки на электронную очередь в ДДО',
    sidebar_queue_title: 'Очередь в детский сад',
    sidebar_queue_applications: 'Заявления',
    sidebar_queue_appointments: 'Направления',
    sidebar_queue_notifications: 'Уведомления'
  },
  kk: {
    sidebar_title: 'Навигация',
    sidebar_home: 'Главная',
    sidebar_users: 'Пользователи',
    sidebar_exit: 'Выход',
    sidebar_requests: 'Өңдеудегі өтінімдер',
    sidebar_completedRequests: 'Аяқталған өтінімдер',
    sidebar_ddo: 'Электрондық кезекке өтініштер',
    sidebar_queue_title: 'Очередь в детский сад',
    sidebar_queue_applications: 'Өтініштер',
    sidebar_queue_appointments: 'Жолдамалар',
    sidebar_queue_notifications: 'Хабарламалар'
  }
});
