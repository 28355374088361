import styled from 'styled-components';

export const IconWrapper = styled.div`
  width: 68px;
  background-color: #e0dcff;
  height: calc(100% - 2px);

  display: flex;
  align-items: center;
  justify-content: center;
  right: 1px !important;
  top: 1px !important;

  ${props =>
    props.className.includes('iconOpen')
      ? `border-top-left-radius: inherit; border-bottom-left-radius: inherit;`
      : `border-top-right-radius: inherit; border-bottom-right-radius: inherit;`}
`;
