import React from 'react';
import Card from './Card';
import GenerateForms from './GenerateForms';
import GenerateGroups from './GenerateGroups';

function getGroupById(groupId, groups) {
  for (const group of groups) {
    if (String(groupId) === String(group.id)) {
      return group;
    } else if (group.subGroups.length > 0) {
      let founded = getGroupById(groupId, group.subGroups);
      if (founded) return founded;
    }
  }
}

function GenerateCards({
  groups,
  groupId,
  questions,
  lang,
  disabled,
  betweenRender,
  renderCardUrl = id => id
}) {
  if (groupId) {
    const { subGroups, questionCodes } = getGroupById(groupId, groups) || {};
    return (
      <>
        <div className="display-none">
          <GenerateGroups
            hideMode
            excludeGroupId={groupId}
            groups={groups}
            questions={questions}
            lang={lang}
            disabled={disabled}
            betweenRender={betweenRender}
          />
        </div>
        {questionCodes && (
          <div className="fullwidth">
            <GenerateForms
              questionCodes={questionCodes}
              questions={questions}
              disabled={disabled}
              lang={lang}
              betweenRender={betweenRender}
            />
          </div>
        )}
        {subGroups && (
          <>
            <div className="clearfix">
              {subGroups.map(group => (
                <Card
                  key={group.id}
                  url={renderCardUrl(group.id)}
                  {...group}
                  lang={lang}
                  disabled={disabled}
                  betweenRender={betweenRender}
                />
              ))}
            </div>
          </>
        )}
      </>
    );
  }
  return (
    <>
      <div className="display-none">
        <GenerateGroups
          hideMode
          excludeGroupId={groupId}
          groups={groups}
          questions={questions}
          lang={lang}
          disabled={disabled}
          betweenRender={betweenRender}
        />
      </div>
      {groups.map(group => (
        <Card
          key={group.id}
          url={renderCardUrl(group.id)}
          {...group}
          lang={lang}
          disabled={disabled}
          betweenRender={betweenRender}
        />
      ))}
    </>
  );
}

export default GenerateCards;
