import React from 'react';
import { StyledMarketDiv } from './MarketStyle'
import MarketApple from './MarketApple';
import MarketPlay from './MarketPlay';
function LinkApp() {
  return (
    <StyledMarketDiv>
      <MarketApple />
      <MarketPlay />
    </StyledMarketDiv>   
  );
}

export default LinkApp;
