import React from 'react';
import { StyledTable } from './TableColumnStyle';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import { KindergartenAppointmentsApi } from '../KindergartenAppointmentsApi';
import { capitalize } from '@material-ui/core';

export const requestsColumns = ({
  t,
  lang,
  modalReview,
  modalExtend,
  pageNumber,
  filter
}) => {
  return [
    {
      Cell: row => (
        <Column
          data={row.original}
          index={row.index}
          t={t}
          lang={lang}
          pageNumber={pageNumber}
          modalExtend={modalExtend}
          modalReview={modalReview}
          filter={filter}
        />
      )
    }
  ];
};

const checkActionsReview = data => {
  if (data && ['RESERVED'].includes(data.statusDirection)) {
    let hour = moment().diff(moment(data.time24Direction), 'hour');
    if (hour < 24) {
      return true;
    }
  }
  return false;
};

const onPressDownloadDirection = id => {
  KindergartenAppointmentsApi.downloadDirection(id).then(response => {
    const data = response.data;
    let element = document.createElement('a');
    document.body.appendChild(element);
    element.style.display = 'none';

    let blob = new Blob([data], { type: 'octet/stream' });
    let url = window.URL.createObjectURL(blob);
    element.href = url;
    element.download = `Направление №${id} .pdf`;
    element.click();
    window.URL.revokeObjectURL(url);
  });
};

const Column = ({
  data,
  t,
  lang,
  modalExtend,
  pageNumber,
  index,
  modalReview,
  filter
}) => {
  return (
    <StyledTable>
      <div className="row1">
        <div className="col1">
          <div>
            <b>{(pageNumber - 1) * filter.pageSize + index + 1}</b>
          </div>
          <div>
            <b>{`${data.childSurname || ''} ${data.childName ||
              ''} ${data.childMiddlename || ''}`}</b>
          </div>
        </div>
        <div className="col2">{data.status && data.status[`${lang}_name_web`]}</div>
      </div>
      <div className="row2">
        <div className="col2_1">
          <div>
            <b>{t('kindergarten_appointments_iin_child')}:</b> {data.childIin}
          </div>
          <div>
            <b>{t('kindergarten_appointments_requestNumber')}:</b> {data.requestFormId}
          </div>
          <div>
            <b>{t('kindergarten_appointments_appointment_number')}:</b> {data.id}
          </div>
          <div>
            <b>{t('kindergarten_appointments_group_name')}:</b>{' '}
            {data[`nameGroup${capitalize(lang)}`]}
          </div>
          <div>
            <b>{t('kindergarten_appointments_ddo')}:</b>{' '}
            {lang === 'ru'
              ? data.serviceProviderRuName || ''
              : data.serviceProviderKkName || ''}
          </div>
          <div>
            <b>{t('kindergarten_appointments_region')}</b>{' '}
            {data.providerLocation[`${lang}_name`]}
          </div>
        </div>
        <div className="col2_2">
          <div>
            <b>{t('kindergarten_appointments_date_given')}:</b>{' '}
            {data.submissionDate ? moment(data.submissionDate).format('DD.MM.YYYY') : ''}
          </div>
          <div>
            <b>{t('kindergarten_appointments_expiration_date')}:</b> {data.directionDate}
          </div>
        </div>
        <div className="col2_3">
            {data && ['RECEIVED'].includes(data.statusDirection) ? (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => modalExtend.open(data)}
                  size="small"
                  className="tab_but"
                >
                  {t('kindergarten_appointments_actions_extend')}
                </Button>
              </div>
            ) : null}
            {data &&
            ['RESERVED', 'RECEIVED', 'PROLONGED', 'ARRIVED_TO_PO'].includes(data.statusDirection) ? (
              <div style={{ paddingTop: '10px' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className="tab_but"
                  endIcon={<GetAppIcon />}
                  onClick={() => onPressDownloadDirection(data.id)}
                >
                  {t('kindergarten_appointments_actions_download')}
                </Button>
              </div>
            ) : null}
            {checkActionsReview(data) ? (
              <div style={{ paddingTop: '10px' }}>
                <Button
                  onClick={() => modalReview.open(data)}
                  variant="contained"
                  color="secondary"
                  size="small"
                  className="tab_but"
                >
                  {t('kindergarten_appointments_actions_review')}
                </Button>
              </div>
            ) : null}
            {data && ['RESERVED', 'RECEIVED', 'PROLONGED'].includes(data.statusDirection) ? (
              <div style={{ paddingTop: '10px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="tab_but"
                  style={{
                    backgroundColor: '#1976D2',
                  }}
                  onClick={() => {
                    window.open('/services/create/doc_acceptance_ddo_short', '_blank');
                  }}
                >
                  {t('kindergarten_appointments_actions_enroll_do')}
                </Button>
              </div> 
            ) : null}
        </div>
      </div>
    </StyledTable>
  );
};
