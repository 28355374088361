import { cloneObject } from '../../../Utils/cloneObject';

export function getCellDependency(dependency, complexCode) {
  if (dependency) {
    let result = cloneObject(dependency);
    for (let key of Object.keys(result)) {
      if (key.indexOf('complexCode') !== -1) {
        let value = result[key];
        let newKey = key.replace('complexCode', complexCode);
        delete result[key];
        if (value.complexCode) {
          value.complexCode = complexCode;
        }
        result[newKey] = value;
      }
    }
    return result;
  }
}
