import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker } from '@material-ui/pickers';
import Select from '../../../../modules/Select/Select';
import KatoTreeSelect from '../../../../_ui/KatoTreeSelect/KatoTreeSelect';
import moment from 'moment';
import './FiltersTranslate';
import { Wrapper } from './FiltersStyle';
import { useDispatch } from 'react-redux';
import { getKindergartens } from './FiltersDucks';
import { clearState } from '../../QueueApplicationsDucks';

function Filters({loading, filter, searchByFilter, setSearch}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [region, setRegion] = useState(null);
  const [queuedType, setQueueType] = useState(null);
  const [rehabType, setRehabType] = useState(null);
  const [corrType, setCorrType] = useState(null);
  const [childYearBirth, setChildYearBirth] = useState(null);
  // const kindergartens = useSelector(state => state[queueAppsFilterModule].kindergartens);
  // const loading_kindergarten = useSelector(state => state[queueAppsFilterModule].loading);
  const kindergarten_filter = {rehabType: rehabType || null, correctionType: corrType || null,
    locationCode: region || null}

  const loadByFilter = () => {
    searchByFilter({childYearBirth: childYearBirth ? moment(childYearBirth).year() : null,
      queuedRegion: region, queuedType: queuedType, rehabType: rehabType,
      correctionType: corrType, action: "filter"});
  };

  const clearFilter = () => {
    setSearch("");
    setChildYearBirth(null);
    setQueueType(null);
    setRegion(null);
    setRehabType(null);
    setCorrType(null);
    searchByFilter({childYearBirth: null, queuedRegion: null, queuedType: null, rehabType: null, correctionType: null,
      action: null});
    dispatch(clearState());
  }

  const queue_type_handler = (e) => {
    setRehabType(null);
    setCorrType(null);
    setQueueType(e.target.value);
    filter.rehabType = null;
    filter.correctionType = null;
    filter.queuedType = e.target.value;
  }

  useEffect(() => {
    if(kindergarten_filter.locationCode && (kindergarten_filter.rehabType || kindergarten_filter.correctionType)){
      dispatch(getKindergartens(t, kindergarten_filter));
    }
  }, [kindergarten_filter.rehabType, kindergarten_filter.correctionType,
    kindergarten_filter.locationCode])

  useEffect(() => {
    setChildYearBirth(filter.childYearBirth ? new Date(filter.childYearBirth, 1, 1) : null);
    setQueueType(filter.queuedType || null);
    setRegion(filter.queuedRegion || null);
    setRehabType(filter.rehabType || null);
    setCorrType(filter.correctionType || null);
  }, [])

  return (
    <Wrapper>
      <div className="queue_filter_inputs">
        <div>
          <KatoTreeSelect
            filterCode={"330000000"}
            name={"region"}
            placeholder={t('queue_applications_filters_region')}
            onChange={value => setRegion(value)}
            value={region}
            parentSelectable={false}
          />
        </div>
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('queue_applications_filters_type')}
            value={queuedType}
            lookupId={"gu_queued_type"}
            onChange={e => queue_type_handler(e)}
            style={{height: '48px'}}
          />
        </div>
        {queuedType === "sanatorium" &&
          <div className='mgt5'>
            <Select
              withoutForm
              placeholder={t('queue_applications_filters_rehab')}
              value={rehabType}
              lookupId={'gu_rehab_type'}
              onChange={e => setRehabType(e.target.value)}
              style={{height: '48px'}}
            />
          </div>
        }
        {queuedType === "special" &&
          <div className="mgt5">
            <Select
              withoutForm
              placeholder={t('queue_applications_filters_corrType')}
              value={corrType}
              lookupId={"gu_correction_type"}
              onChange={e => setCorrType(e.target.value)}
              className="filter_input"
              style={{height: '48px'}}
            />
          </div>
        }
        <div className="mgt15">
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year"]}
            onChange={(value) => setChildYearBirth(value)}
            value={childYearBirth}
            label={t('queue_applications_filters_birth_year')}
            helperText=""
            autoOk
          />
        </div>
        <div className="mgt15 centerContainer">
          {loading ?
            <CircularProgress />
            :
            <>
              <div>
                <Button variant='contained' color='secondary' onClick={() => loadByFilter()}>{t('queue_applications_filters_but')}</Button>
              </div>
              <div className="mgt15">
                <Button variant='outlined' color='secondary' size="small" onClick={() => clearFilter()}>{t('queue_applications_filters_clear')}</Button>
              </div>
            </>
          }
        </div>
      </div>
    </Wrapper>
  );
}

export default Filters;
