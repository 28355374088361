import { useEffect, useState } from 'react';
import {
  addChildren,
  arrayToTree,
  getChildrenByCode,
  loadChildLevel,
  loadTreeByArrValue,
  treeFindItems,
  treeMap
} from './DTreeSelectMultiUtils';

function useDTree({
  value = [],
  parentSelectable,
  lookupId,
  filterCode = '',
  options,
  hardDict,
  ...rest
}) {
  const [loadingArr, setLoadingArr] = useState([]);
  const [tree, setTree] = useState(arrayToTree(options, value, true, !hardDict));
  const [anchorEl, setAnchorElement] = useState(null);
  const [filteredCode, setFilteredCode] = useState(filterCode);
  const [status, setStatus] = useState(null);
  const onChange = itemCode => {
    if (value.includes(itemCode)) {
      rest.onChange(value.filter(vItem => vItem !== itemCode));
    } else {
      if (itemCode === '-1') rest.onChange([itemCode]);
      else rest.onChange([...value, itemCode].filter(vItem => vItem !== '-1'));
    }
  };

  const setAnchorEl = anchor => {
    setAnchorElement(anchor);
    if (hardDict && status === null) setStatus('loading');
  };

  useEffect(() => {
    if (!hardDict && anchorEl === null) {
      setTree(arrayToTree(options, value, true, !hardDict));
    }
    // eslint-disable-next-line
  }, [options, value, hardDict]);

  useEffect(() => {
    if (!hardDict && filterCode && filterCode !== filteredCode) {
      setTree(getChildrenByCode(tree, filterCode));
      setFilteredCode(filterCode);
    }
    // eslint-disable-next-line
  }, [filterCode]);

  useEffect(() => {
    let isCancelled = false;
    if (hardDict && status !== null) {
      if (
        filterCode !== filteredCode ||
        status === 'loading' ||
        treeFindItems(tree, item => value.includes(item.code)).length !== value.length
      ) {
        loadTreeByArrValue(lookupId, value, filterCode).then(tree => {
          if (!isCancelled) {
            setTree(tree);
            setFilteredCode(filterCode);
            setStatus('loaded');
          }
        });
      }
    } else if (
      value &&
      treeFindItems(tree, item => value.includes(item.code)).length !== value.length
    ) {
      loadTreeByArrValue(lookupId, value, filterCode).then(tree => {
        if (!isCancelled) {
          setTree(tree);
          setFilteredCode(filterCode);
          setStatus('loaded');
        }
      });
    }
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [lookupId, value, filterCode, status]);

  const handleExpand = (item, changeTrigger) => {
    let newTree = treeMap(tree, treeItem => {
      if (treeItem.code === item.code) {
        treeItem.expanded = !treeItem.expanded;
      }
      return treeItem;
    });
    if (lookupId && !item.childItems) {
      setLoadingArr([...loadingArr, item.code]);
      loadChildLevel(lookupId, item.code).then(children => {
        if (changeTrigger && children.length === 0) {
          if (typeof onChange === 'function') {
            onChange(item.code);
          }
        }
        addChildren(newTree, item.code, children);
        setTree(newTree);
        let arr = loadingArr.filter(code => code === item.code);
        setLoadingArr(arr);
      });
    } else {
      setTree(newTree);
    }
  };

  const handleChange = item => {
    if (parentSelectable) {
      onChange(item.code);
    } else {
      if (!item.childItems || item.childItems.length > 0) {
        handleExpand(item, true);
      } else if (typeof onChange === 'function') {
        onChange(item.code);
      }
    }
  };

  return {
    tree,
    loadingArr,
    onExpand: handleExpand,
    onChange: handleChange,
    anchorEl,
    setAnchorEl,
    loadingMenu: status === 'loading'
  };
}

export default useDTree;
