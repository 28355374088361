import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardsContainer } from './CheckQueueNumberStyle';
import './CheckQueueNumberTranslate';
import paths from '../../_helpers/paths';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { chechQueueNumber, checkQueueNumberModule, clearState } from './CheckQueueNumberDucks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestsColumns } from './TableColumns';
import { StyledTableContainer } from './CheckQueueNumberStyle';
import StaticTable from '../../_ui/Table/StaticTable';
import i18n from "i18next";
import { useForm, Controller } from "react-hook-form";
import makeStyles from '@material-ui/core/styles/makeStyles';

const useHelperTextStyles = makeStyles(() => ({
  root: {
    marginTop: '10px',
    textTransform: 'none'
  }
}));

function CheckQueueNumber() {
  const { t } = useTranslation();
  let lang = i18n.language;
  const dispatch = useDispatch();
  const breadCrumbItems = [];
  breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
  breadCrumbItems.push({ name: t('kindergarten_subtitle'), href: paths.kindergartenServices });
  breadCrumbItems.push({ name: t('check_queue_number_subtitle') });

  const queue = useSelector(state => state[checkQueueNumberModule].data);
  const loading = useSelector(state => state[checkQueueNumberModule].loading);

  const { handleSubmit, control, errors } = useForm();
  const helperTextStyles = useHelperTextStyles();

  const onSubmit = (data) => {
    dispatch(chechQueueNumber(data.iin, lang));
  };

  useEffect(() => {
    dispatch(clearState());
  }, [])

  return (
    <div className="mb3 mt3" style={{width: '100%'}}>
      <BreadCrumbs items={breadCrumbItems} />
      <StyledCardsContainer>
        <div className="centerContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="inputs">
              <div className="text">{t('check_queue_number_put_iin')}</div>
              <Controller
                name="iin" 
                control={control}
                defaultValue=''
                rules={{
                  required: t('check_queue_requiredField'), 
                  maxLength: { value: 12, message: t('check_queue_correctData'),  },
                  minLength: { value: 12, message: t('check_queue_correctData'),  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: t('check_queue_correctData'),
                  },
                }}
                as={
                  <TextField 
                    id="outlined-basic" 
                    label={t('check_queue_number_iin')} 
                    variant="outlined"
                    style={{width: '370px'}}
                    error={!!errors?.iin}
                    helperText={errors?.iin?.message}
                    FormHelperTextProps={{
                      classes:{
                          root:helperTextStyles.root
                      }
                    }}
                  />
                } 
              />
              
              <div>
                {loading ?
                  <CircularProgress className="mrgTop10"/>
                  :
                  <Button 
                    className="mrgTop10"
                    variant='contained'
                    color='secondary'
                    type="submit"
                  >
                    {t('check_queue_number_but')}
                </Button>
                }
              </div>
            </div>
          </form>

          <div>
            {queue &&
              <StyledTableContainer>
                <StaticTable
                  TheadComponent={_ => null}
                  loading={loading}
                  data={queue}
                  columns={requestsColumns({t, lang})}
                  filterable={false}
                />
              </StyledTableContainer>
            }
          </div>
        </div>
      </StyledCardsContainer>
    </div>
  );
}

export default CheckQueueNumber;
