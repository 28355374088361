import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import Notice from '../../../modules/utils/Notice';
import { handleError } from '../../../modules/utils/handleError';
import { changeComplexValue } from '../../../passport/Passport';
import PassportForm from '../../../passport/FormData/PassportForm';
import { ServiceRequestApi } from '../ServiceRequestApi';

async function requestGBDFL({ code, setLoading, t, complexCode, questionCode, value }) {
  try {
    setLoading(true);
    const response = await ServiceRequestApi.searchGbdfl(value);
    const user = response.data.result;
    if (user) {
      const source = questionCode.replace('_iin', '');
      changeComplexValue(code, {
        ...PassportForm.cellsValues[code],
        [complexCode]: {
          ...PassportForm.cellsValues[code][complexCode],
          [questionCode]: value,
          [`${source}_surname`]: user['lastName'],
          [`${source}_name`]: user['firstName'],
          [`${source}_middlename`]: user['middleName'],
          [`${source}_birthday`]: user['birthDate']
        }
      });
    } else {
      handleError(response, t('dataNotFound'));
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.gbdfl_person_not_found') {
      Notice.error(t('gbdflButton_iinNotFound'));
    } else {
      handleError(error, t('gbdflButton_searchFailed'));
    }
  } finally {
    setLoading(false);
  }
}

function ComplexGBDFLButton({ code, value, complexCode, questionCode }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        disabled={value.length !== 12}
        text={t('gbdflButton_search')}
        onClick={() => requestGBDFL({
          t,
          code,
          value,
          setLoading,
          complexCode,
          questionCode
        })}
      />
    </div>
  );
}

export default ComplexGBDFLButton;