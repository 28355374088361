import { useEffect } from 'react';

import Notice from '../../../modules/utils/Notice';

function ListenSimpleQuestions({ code, value }) {
  useEffect(() => {
    switch (code) {
      case 'request_form_accept_doc_edu_short.edu_form':
        if (value && value.code === '2') {
          Notice.info('Ребенок не младше 15 лет может обучаться по вечерней форме обучения.');
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return null;
}

export default ListenSimpleQuestions;