let config;
let protocol = window.location.protocol;

switch (window.location.hostname) {
  case 'es-portal.e-zhetysu.kz':
  case 'e-zhetysu.kz':
    config = {
      url: {
        base: `${protocol}//es-api.e-zhetysu.kz`,
        service: `${protocol}//es-services.e-zhetysu.kz`,
        fileStore: `${protocol}//es-filestore-api.e-zhetysu.kz`,
        kezek: `${protocol}//kezek.e-zhetysu.kz/#/`
      }
    };
    break;
  case 'es-portal-test.e-zhetysu.kz':
  case 'tszh.e-zhetysu.kz':
    config = {
      url: {
        base: `${protocol}//es-api-tszh.e-zhetysu.kz`,
        service: `${protocol}//es-services-tszh.e-zhetysu.kz`,
        fileStore: `${protocol}//es-filestore-api-tszh.e-zhetysu.kz`,
        kezek: `${protocol}//kezek-test.e-zhetysu.kz/#/`
      }
    };
    break;
  default: {
    protocol = 'https:';
    config = {
      url: {
        base: `${protocol}//es-api-tszh.e-zhetysu.kz`,
        service: `${protocol}//es-services-tszh.e-zhetysu.kz`,
        fileStore: `${protocol}//es-filestore-api-tszh.e-zhetysu.kz`,
        kezek: `${protocol}//kezek-test.e-zhetysu.kz/#/`
      }
    };
  }
}

export default config;
