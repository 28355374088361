import styled from 'styled-components';

export const StyledTable = styled.div`
  .row1, .row2{
    display: flex;
  }
  .row2{
    padding-top: 10px;
  }
  .col1 div{
    display: inline-block;
    padding-right: 20px;
  }
  .col1{
    width: 70%;
  }
  .col2{
    width: 30%;
    text-align: center;
    white-space: pre-wrap;
  }
  .col2_1{
    width: 40%;
    white-space: pre-wrap;
  }
  .col2_1 div{
    padding-bottom: 5px;
  }
  .col2_2{
    width: 30%;
    padding: 0px 10px;
  }
  .col2_3{
    width: 30%;
    text-align: center;
  }
  .tab_but{
    width: 280px;
  }
`;
