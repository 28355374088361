import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginModalHandler, loginModule } from './LoginDucks';
import Modal from '../../_ui/Modal/Modal';
import DefaultLogin from './DefaultLogin';
import LoginWithECP from './LoginWithECP';
import './LoginTranslate';

function renderLoginMethod() {
  const isWithoutECP = localStorage.getItem('authWithoutSignature');
  if (isWithoutECP === 'true') {
    return <DefaultLogin/>;
  }
  return <LoginWithECP/>;
}

function Login() {
  const { isOpen } = useSelector(state => state[loginModule]);
  const dispatch = useDispatch();

  return (
    <Modal
      open={isOpen}
      onClose={() => dispatch(loginModalHandler(false))}
      children={renderLoginMethod()}
    />
  );
}

export default Login;