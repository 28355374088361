import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import PassportForm from '../../../passport/FormData/PassportForm';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeFormValue } from '../../../passport/Passport';

async function changeSchool({ t, lang, setLoading }) {
  try {
    setLoading(true);
    const childIin = PassportForm.values['request_form_accept_doc_edu_short.Child_iin'];
    if (!childIin) {
      return Notice.info(t('serviceRequest_fillChildIin'));
    }
    const response = await ServiceRequestApi.getSchools(childIin);
    const s = response.data.content.find(s => s.status === 2);
    if (s) {
      const childSchoolName = s[`schoolName${capitalize(lang)}`];
      const childSchoolArrival = s.passportValues.find(q => q.code === 'DateOfArrival');
      const childSchoolParallel = s.passportValues.find(q => q.code === 'Parallel');
      const childSchoolForm = s.passportValues.find(q => q.code === 'StudyForm');
      const childSchoolBoarding = s.passportValues.find(q => q.code === 'BoardingInformation');
      const childSchoolSpecial = s.passportValues.find(q => q.code === 'StudyingInSpecialClasses');
      changeFormValue(
        `request_form_accept_doc_edu_short.School_Name`,
        childSchoolName
      );
      changeFormValue(
        `request_form_accept_doc_edu_short.DateOfArrival`,
        childSchoolArrival ? childSchoolArrival.value : ''
      );
      changeFormValue(
        `request_form_accept_doc_edu_short.Parallel`,
        childSchoolParallel ? childSchoolParallel.value.value : ''
      );
      changeFormValue(
        `request_form_accept_doc_edu_short.StudyForm`,
        childSchoolForm ? childSchoolForm.value.value : ''
      );
      changeFormValue(
        `request_form_accept_doc_edu_short.BoardingInformation`,
        childSchoolBoarding ? childSchoolBoarding.value.value[0] : ''
      );
      changeFormValue(
        `request_form_accept_doc_edu_short.StudyingInSpecialClasses`,
        childSchoolSpecial ? childSchoolSpecial.value.value : ''
      );
    } else {
      handleError(response, t('dataNotFound'));
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function SimpleSchool({ code }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeSchool({
          t,
          code,
          setLoading,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default SimpleSchool;