import styled from 'styled-components';

export const StyledCardsContainer = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-around;
  position: relative;
  font-family: Roboto,serif;
  height: 100%;
  flex-grow: 1;
  padding: 0 15px;
  width: 100%;
  .warning{
    max-width: fit-content;
    font-size: 12px;
    margin: 0px 0px 30px 0px;
  }
  .ReactTable {
    border-top: 1px solid rgba(125,125,125,0.4);
  }
  .MuiFormLabel-root {
    color: rgb(191, 191, 191);
    font-size: 14px;
  }
  .text{
    margin-top: 10px;
  }
  @media screen and (max-width: 900px) {
    flex-decoration: column;
    flex-wrap: wrap;
    justify-content: start;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  .result{
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    text-transform: initial;
  }
`;

export const StyledTableContainer = styled.div`
  min-width: 800px;
  .table_head_q{
    display: none;
  }
`;
