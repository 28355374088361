import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './HeadTranslate';

export default function HeadTitle() {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>
        {t('head_title')}
      </title>
    </Helmet>
  );
}
