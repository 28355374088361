import React from 'react';
import { useSelector } from 'react-redux';
import { serviceRequestModule } from './ServiceRequestDucks';
import CircularLoading from '../../modules/Loading/CircularLoading';
import { LoadingWrapper } from './ServiceRequestStyles';

function Loading() {
  const { loadingAction } = useSelector(state => state[serviceRequestModule]);

  if (loadingAction) {
    return (
      <LoadingWrapper>
        <CircularLoading />
      </LoadingWrapper>
    );
  }

  return null;
}

export default Loading;