import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardsContainer, StyledTableContainer } from './KindergartenReestrStyle';
import './KindergartenReestrTranslate';
import paths from '../../_helpers/paths';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { clearState, kindergartenReestrModule, loadKindergartens } from './KindergartenReestrDucks';
import { requestsColumns } from './TableColumns/TableColumns';
import Counters from './KindergartenReestrComponents/Counters/Counters';
import Filters from './KindergartenReestrComponents/Filters/Filters.jsx';
import { getKindergartenCounter } from './KindergartenReestrComponents/Counters/CountersDucks';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import Search from './KindergartenReestrComponents/Search/Search';
import i18n from 'i18next';

function KindergartenReestr() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let lang = i18n.language;
  const breadCrumbItems = [];
  breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
  breadCrumbItems.push({ name: t('kindergarten_subtitle'), href: paths.kindergartenServices });
  breadCrumbItems.push({ name: t('kindergarten_reestr_subtitle') });

  const kindergartens = useSelector(state => state[kindergartenReestrModule].data);
  const pageNumber = useSelector(state => state[kindergartenReestrModule].pageNumber);
  const totalElements = useSelector(state => state[kindergartenReestrModule].totalElements);
  const loading = useSelector(state => state[kindergartenReestrModule].loading);
  const loadData = filter => {
    dispatch(loadKindergartens(filter, t));
    dispatch(getKindergartenCounter({ ...filter, availabilitySo: filter.availableSuo}, t))
  };

  const { filter, toPage, changePageSize, searchByFilter } = useTableFilter(loadData, "", { pageNumber: 1, pageSize: 10 });

  useEffect(() => {
    dispatch(clearState());
  }, [])

  return (
    <div className="mb3 mt3" style={{width: '100%'}}>
      <BreadCrumbs items={breadCrumbItems} />
      <StyledCardsContainer>
        <Filters loading={loading} filter={filter} searchByFilter={searchByFilter}/>
        <div>
          <Counters/>
          <Search loading={loading} searchByFilter={searchByFilter}/>
          {kindergartens &&
            <StyledTableContainer>
              <div className="mt2">
                <StaticTablePaginated
                  TheadComponent={_ => null}
                  pageNumber={pageNumber}
                  loading={loading}
                  data={kindergartens}
                  totalElements={totalElements}
                  changePageSize={changePageSize}
                  pageSizes={[10, 20, 50, 100]}
                  columns={requestsColumns({t, lang})}
                  filterable={false}
                  pagePagination={page => {
                    if (page !== pageNumber) {
                      toPage(page);
                    }
                  }}
                />
              </div>
            </StyledTableContainer>
          }
        </div>
      </StyledCardsContainer>
    </div>
  );
}

export default KindergartenReestr;
