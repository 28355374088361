import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    serviceCard_button: 'Заказать услугу',
    serviceCard_button_url: 'Перейти',
    serviceCard_forLegalPerson: 'Данная услуга доступна только для юридических лиц',
    serviceCard_forPhysicalPerson: 'Данную услугу могут получать только физические лица',
  },
  kk: {
    serviceCard_button: 'Қызметке тапсырыс беру',
    serviceCard_button_url: 'Кіру',
    serviceCard_forLegalPerson: 'Бұл қызмет тек заңды тұлғалар үшін қолжетімді',
    serviceCard_forPhysicalPerson: 'Бұл қызмет тек жеке тұлғалар үшін қолжетімді',
  }
});
