import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

import { ServiceRequestApi } from './ServiceRequestApi';
import Notice from '../../modules/utils/Notice';
import { handleError } from '../../modules/utils/handleError';
import Dict from '../../modules/utils/Dict';
import { translation } from '../../_helpers/Translate';
import { history } from '../../_helpers/history';
import paths from '../../_helpers/paths';
import { NCALayerSign } from '../../modules/NCALayer/NCALayerSign';
import { changeFormValue } from '../../passport/Passport';
import { services } from '../../_helpers/Constants';
import { ServiceProvidersApi } from './ServiceProvidersModal/ServiceProvidersApi';
import i18next from 'i18next';

/**
 * Constants
 */
export const serviceRequestModule = 'serviceRequest';
const LOAD_DATA_REQUEST = `${serviceRequestModule}/LOAD_DATA_REQUEST`;
const SET_DATA_REQUEST = `${serviceRequestModule}/SET_DATA_REQUEST`;
const LOAD_SAVING_REQUEST = `${serviceRequestModule}/LOAD_SAVING_REQUEST`;
const CLEAR_STATE = `${serviceRequestModule}/CLEAR_STATE`;
const LOADING_ACTION = `${serviceRequestModule}/LOADING_ACTION`;
const DISABLE_FORM_BUTTON = `${serviceRequestModule}/DISABLE_FORM_BUTTON`;
const BIN_FUNDER = `${serviceRequestModule}/BIN_FUNDER`;
const SET_DDO_SHORT_DISABLED = `${serviceRequestModule}/SET_DDO_SHORT_DISABLED`;

/**
 * Reducer
 */
export const initialState = {
  meta: null,
  data: {},
  loading: false,
  loadingSave: false,
  loadingAction: false,
  disableFormButton: false,
  bin: null,
  ddoShortDisabled: false,
};

export default createReducer(initialState, {
  [LOAD_DATA_REQUEST]: (state, action) => {
    state.loading = action.loading;
  },
  [LOAD_SAVING_REQUEST]: (state, action) => {
    state.loadingSave = action.loading;
  },
  [SET_DATA_REQUEST]: (state, action) => {
    state.meta = action.meta;
    state.data = action.data || { metadataKey: action.meta.id };
  },
  [LOADING_ACTION]: (state, action) => {
    state.loadingAction = action.loading;
  },
  [DISABLE_FORM_BUTTON]: (state, action) => {
    state.disableFormButton = action.payload;
  },
  [BIN_FUNDER]: (state, action) => {
    state.bin = action.payload
  },
  [SET_DDO_SHORT_DISABLED]: (state, action) => {
    state.ddoShortDisabled = action.payload
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Action Creators
 */

export const disableFormButton = (disable) => ({ type: DISABLE_FORM_BUTTON, payload: disable });

export const loadingData = (id, metadataKey) => async (dispatch, getState) => {
  dispatch({ type: LOAD_DATA_REQUEST, loading: true });
  try {
    if (id) {
      const { data } = await ServiceRequestApi.loadData(id);
      const response = await ServiceRequestApi.loadMetaData(data.metadataKey);
      const filter = JSON.stringify({ id: data.serviceProviderId });
      const { data: serviceProviderData } = await ServiceRequestApi.serviceProvider(
        filter
      );
      let serviceProvider;
      if (serviceProviderData.result.content.length) {
        serviceProvider = serviceProviderData.result.content[0];
      }
      const dictStatuses = await Dict.itemsObject('gu_status_res');
      const dictResolutions = await Dict.itemsObject('gu_status_pr');
      dispatch({
        type: SET_DATA_REQUEST,
        meta: response.data,
        data: {
          ...data,
          serviceProvider,
          ruProcessingStatus: dictStatuses[data.processingStatus][`ru_name`],
          kkProcessingStatus: dictStatuses[data.processingStatus][`kk_name`],
          ruResolutionType: dictResolutions[data.resolutionType][`ru_name`],
          kkResolutionType: dictResolutions[data.resolutionType][`kk_name`],
          requesterIin: getState().login.user.iin
        }
      });
    } else {
      const response = await ServiceRequestApi.loadMetaData(
        `request_form_${metadataKey}`
      );
      dispatch({ type: SET_DATA_REQUEST, meta: response.data });
    }
  } catch (errorCode) {
    console.error(errorCode);
    handleError(errorCode, 'serviceRequestDucks_loadDataFailed');
  } finally {
    dispatch({ type: LOAD_DATA_REQUEST, loading: false });
  }
};

export const onSave = (formData, method) => async dispatch => {
  dispatch({ type: LOAD_SAVING_REQUEST, loading: true });
  try {
    const { data } = await ServiceRequestApi.getSignString(formData);
    const signString = data['result']['signature'];
    const signedString = await NCALayerSign(signString, false, method, false);
    if (!signedString) {
      return Notice.warning(translation('serviceRequestDucks_signCanceled'));
    }
    const response = await ServiceRequestApi.createRequestForm({
      ...formData,
      signature: signedString
    });
    if (response.data.results.length === 0) {
      Notice.success(translation('serviceRequestDucks_saveSuccess'));
      history.push(paths.homePage);
    } else {
      handleError(response, translation('serviceRequestDucks_saveFailed'));
    }
  } catch (e) {
    const errorCode = _.get(e, 'response.data.errors[0].code');
    if (errorCode === 'error.runtime_user_must_sing_own_signature') {
      Notice.error(translation('must_sing_own_signature'));
    } else {
      handleError(e, translation('serviceRequestDucks_saveFailed'));
    }
  } finally {
    dispatch({ type: LOAD_SAVING_REQUEST, loading: false });
  }
};

export const getServiceProvider = (id, metadataKey) => async dispatch => {
  try {
    dispatch({ type: LOADING_ACTION, loading: true });
    const filter = JSON.stringify({
      id,
      pageSize: 1,
      pageNumber: 1
    });
    const { data } = await ServiceRequestApi.serviceProvider(filter);
    const { content } = data.result;
    if (content.length > 0) {
      if (metadataKey === services.academical_resignations_short.code) {
        changeFormValue(
          'request_form_academical_resignations_short.location',
          content[0].location
        );
      }
    }
  } catch (error) {
    handleError(error, translation('serviceRequestDucks_loadDataFailed'));
  } finally {
    dispatch({ type: LOADING_ACTION, loading: false });
  }
};
export const searchServiceProvider = async (
  queuedRegion,
  switchSPBIN,
  setServiceProvider,
  serviceType,
  typeCode = '13'
) => {
  try {
    let filter = {};
    let bin;
    if (queuedRegion) {
      let kato = queuedRegion.code.slice(0, 4);
      bin = switchSPBIN([], kato);
      kato = kato + '00000';
      filter = {
        pageNumber: 1,
        pageSize: 100,
        legalLocality: kato,
        serviceType: serviceType,
        serviceProviderType: '0',
        typeCode: typeCode
      };
      let { data } = await ServiceProvidersApi.searchServiceProvider(filter);
      if (data.content) {
        const content = [];
        for (let row of data.content) {
          const item = {};
          for (let r of row.passportValues) {
            item[r.code] = r.value;
          }
          content.push(item);
        }
        if (content.length > 0) {
          const findContent = content.find(el => el.bin === bin);
          if (findContent) {
            setServiceProvider(findContent);
          } else {
            if (content.length === 1) {
              setServiceProvider(content[0]);
            } else {
              setServiceProvider(null);
            }
          }
        }
      }
    } else {
      setServiceProvider(null);
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_errorLoadTable'));
  }
};

export const clearState = () => dispatch => {
  dispatch({ type: CLEAR_STATE });
};


export const saveBinFunder = (bin) => dispatch => {
  dispatch({ type: BIN_FUNDER, payload: bin });
};

export const chengDDOShortDisabled = (isDisabled) => dispatch => {
  dispatch({ type: SET_DDO_SHORT_DISABLED, payload: isDisabled });
};