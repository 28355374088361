export function findMatches(findCode, dependency, ruleBody, tableCode) {
  let math;
  let regexpStaticValue = /\.s\('(\d|\s|\w|\.)+'\)/g;
  let regexpValue = /\.q\('(\d|\s|\w|\.)+'\)/g;
  let regexpDictValue = /\.qDictionaryItem\('(\d|\s|\w|\.)+'\)/g;
  let regexpTableDomainData = /\.tableDomainData\('(\d|\s|\w|\.)+'\)/g;
  let regexpTableMatrix = /\.tableMatrix\((\d|\s|\w|'|"|,|{|}|:|\.)+\)/g;
  let regexpComplexTableColumn = /\.complexTableColumn\((\d|\s|\w|'|"|,|\.)+\)/g;
  let regexpComplexTableRow = 'complexTableRow()';
  let regexpComplexTableCell = /\.complexTableCell\((\d|\s|\w|'|"|,|\.)+\)/g;

  while ((math = regexpStaticValue.exec(ruleBody)) !== null) {
    dependency['staticDataChanged'] = { code: 'staticDataChanged' };
  }

  while ((math = regexpValue.exec(ruleBody)) !== null) {
    let mCode = math[0].slice(4, math[0].length - 2);
    let code = findCode(mCode);
    if (!dependency.hasOwnProperty(code)) {
      dependency[code] = { code };
    }
  }

  while ((math = regexpDictValue.exec(ruleBody)) !== null) {
    let mCode = math[0].slice(18, math[0].length - 2);
    let code = findCode(mCode);
    if (!dependency.hasOwnProperty(code)) {
      dependency[code] = { code };
    }
  }

  while ((math = regexpTableDomainData.exec(ruleBody)) !== null) {
    let mCode = math[0].slice(18, math[0].length - 2);
    let code = findCode(mCode);
    if (!dependency.hasOwnProperty(code)) {
      dependency[code] = { code };
    }
  }

  while ((math = regexpTableMatrix.exec(ruleBody)) !== null) {
    let args = math[0]
      .slice(14, math[0].length - 1)
      .replace(/\s/g, '')
      .replace(/'/g, '"');
    let index = args.indexOf('{');
    let mCode = args.substr(0, index - 2);
    let coordinates = JSON.parse(args.substr(index));
    let code = findCode(mCode);
    let key = `${code}_${JSON.stringify(coordinates)}`;

    if (!dependency.hasOwnProperty(key)) {
      dependency[key] = { code, coordinates };
    }
  }

  while ((math = regexpComplexTableColumn.exec(ruleBody)) !== null) {
    let [mCode, columnCode] = math[0]
      .slice(20, math[0].length - 1)
      .replace(/('|\s)/g, '')
      .split(',');
    let code = findCode(mCode);
    let key = `${code}_${columnCode}`;
    if (!dependency.hasOwnProperty(key)) {
      dependency[key] = { code, columnCode };
    }
  }

  if (ruleBody.indexOf(regexpComplexTableRow) !== -1) {
    let key = `${tableCode}_complexCode`;
    if (!dependency.hasOwnProperty(key)) {
      dependency[key] = { code: tableCode, complexCode: true };
    }
  }

  while ((math = regexpComplexTableCell.exec(ruleBody)) !== null) {
    let [code1, code2] = math[0]
      .slice(18, math[0].length - 1)
      .replace(/('|\s)/g, '')
      .split(',');
    let columnCode = code2 ? code2 : code1;
    let key = `${tableCode}_complexCode_${columnCode}`;
    if (!dependency.hasOwnProperty(key)) {
      dependency[key] = { code: tableCode, complexCode: true, columnCode };
    }
  }
}
