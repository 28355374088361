import React, { useContext } from 'react';
import GenerateForms from './GenerateForms';
import { Groups } from '../../PassportStyle';
import GenerateGroups from './GenerateGroups';
import Expander from '../Expander';
import withGroupFlc from '../flc/withGroupFlc';
import { ConfigContext } from '../../PassportProvider';
import { handleQuestionLabel } from '../../Utils/handleQuestionLabel';

function Group({
  id,
  lang,
  questionCodes,
  questions,
  subGroups,
  disabled,
  betweenRender,
  hideMode,
  groupHided,
  excludeGroupId,
  hideTitle,
  ...rest
}) {
  const { stickyTop } = useContext(ConfigContext);

  return (
    <Groups top={stickyTop} id={`group_${id}`} className="group" hide={rest.hide}>
      <Expander
        code={id}
        title={handleQuestionLabel({ id, ...rest }, lang, true)}
        hideTitle={hideTitle}
      >
        <div id={`group_anchor_${id}`} className="anchor" />
        <div className="fullwidth">
          {id !== excludeGroupId && (
            <GenerateForms
              hideMode={hideMode}
              questionCodes={questionCodes}
              groupHided={groupHided}
              questions={questions}
              disabled={disabled}
              lang={lang}
              betweenRender={betweenRender}
            />
          )}
          {subGroups.length > 0 && (
            <GenerateGroups
              hideMode={hideMode}
              groups={subGroups}
              questions={questions}
              lang={lang}
              excludeGroupId={excludeGroupId}
              groupHided={groupHided}
              disabled={disabled}
            />
          )}
        </div>
      </Expander>
    </Groups>
  );
}

export default withGroupFlc(Group);
