import lodash from 'lodash';
import { getQuestions } from './getQuestions';
import { getGroups } from './getGroups';
import { searchDependencies } from '../Flc/dependency/searchDependencies';
import { TestMode } from '../Flc/TestMode';
import { searchGroupsDependencies } from '../Flc/dependency/searchGroupsDependencies';
import { DomainData } from '../Utils/DomainData';
import Dict from '../Utils/Dict';
import { PassportApi } from '../PassportService';
import PassportForm from '../FormData/PassportForm';

export async function handlePassport(listData, excludeMetaKeys) {
  // для тестового режима
  TestMode.initialize();

  // формирование разделов и показателей
  const metadataKeys = listData.map(data => data.metaData.id);
  let result = { metadataKeys, groups: [], questions: {}, saveWithError: [] };
  const getCodeWithMetaId = (questionCode, defaultMetaId) => {
    for (const data of listData) {
      if (data.metaData.questions.hasOwnProperty(questionCode)) {
        return `${data.metaData.id}.${questionCode}`;
      }
    }
    return `${defaultMetaId}.${questionCode}`
  };

  for (const data of listData) {
    let metaData = data.metaData;
    let passport = data.passport;
    let groups = lodash.get(metaData, 'group.subGroups', []);
    let questions = await getQuestions(metaData, passport);

    if (groups.length === 0) {
      groups = [metaData.group];
    }
    if (excludeMetaKeys.includes(metaData.id)) {
      groups = [];
    }

    if (metaData['allowSavingWithFailedRules']) {
      result.saveWithError.push(metaData.id);
    }

    result.groups = [
      ...result.groups,
      ...getGroups(metaData, groups, listData.length === 1, getCodeWithMetaId)
    ];
    result.questions = { ...result.questions, ...questions };
  }

  // поиск зависимостей для флк
  searchGroupsDependencies(result.groups, metadataKeys, result.questions);
  searchDependencies(result.questions, metadataKeys);

  // загружаем справочник для переводов ошибки
  const validation_messages = await Dict.itemsObject('validation_messages');
  DomainData.cash.validation_messages = validation_messages || {};

  // загружаем серверное время
  try {
    const response = await PassportApi.getCurrentTime();
    PassportForm.currentDate = response.data.time;
  } catch (ignore) {}

  // делаем сортировку для удобства
  let sortedQuestions = {};
  for (const key of Object.keys(result.questions).sort()) {
    sortedQuestions[key] = result.questions[key];
    if (listData.length === 1) {
      sortedQuestions[key].hideKeyInLabel = true;
    }
  }
  result.questions = sortedQuestions;
  return result;
}
