import { createReducer } from 'redux-starter-kit';
import { history } from '../../../../_helpers/history';
import routerProps from '../../../../_helpers/routerProps';
import paths from '../../../../_helpers/paths';
import { CountersApi } from './CountersApi';
import Notice from '../../../../modules/utils/Notice';
import Dict from '../../../../modules/utils/Dict';

/**
 * Constants
 */

export const kindergartenCountersModule = 'kindergarten_counters';
const SET_DATA = `${kindergartenCountersModule}/SET_DATA`;
const LOADING = `${kindergartenCountersModule}/LOADING`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  total: 0,
  totalPrivate: 0,
  totalState: 0,
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.total = action.total;
    state.totalPrivate = action.totalPrivate;
    state.totalState = action.totalState;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
});

export const getKindergartenCounter = (filter, t) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await CountersApi.getCount(JSON.stringify(filter));
    if(data.status === 'SUCCESS'){
      dispatch({ type: SET_DATA, total: data.result.totalDo, totalPrivate: data.result.totalPrivate,
        totalState: data.result.totalState});
    }else{
      Notice.error(t('kindergarten_counter_err'));
    }
  } catch (error) {
    let location = window.location;
    for (const route of Object.values(routerProps)) {
      if (route.path === location.pathname && !route.withoutAuth) {
        window.addressBarUrl = `${location.pathname}${location.search}`;
        history.push(paths.homePage);
      }
    }
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};
