import { stripBase64 } from '../Utils/stripBase64';

export function getDocumentChange(question, value, savedValue) {
  let changes = [];

  if (value) {
    if (savedValue) {
      if (value.complexValueId !== savedValue.complexValueId) {
        if (savedValue.complexValueId) {
          changes.push({
            '@type': 'RequestQuestionValueChange',
            questionCode: question.originalCode,
            businessObjectId: question.passportId,
            newValue: [{ fileInfo: null }]
          });
          changes.push({
            '@type': 'RequestRemoveComplexQuestionItem',
            questionCode: question.originalCode,
            businessObjectId: question.passportId,
            valueId: savedValue.complexValueId
          });
        }
        changes.push({
          '@type': 'RequestAddComplexQuestionItem',
          questionCode: question.originalCode,
          businessObjectId: question.passportId,
          valueId: value.complexValueId
        });
      }
    } else {
      changes.push({
        '@type': 'RequestAddComplexQuestionItem',
        questionCode: question.originalCode,
        businessObjectId: question.passportId,
        valueId: value.complexValueId
      });
    }

    if (value.data) {
      changes.push({
        '@type': 'RequestQuestionValueChange',
        questionCode: question.originalCode,
        businessObjectId: question.passportId,
        relatedBusinessObjectId: question.relatedBusinessObjectId,
        newValue: [
          {
            fileInfo: {
              complexValueId: value.complexValueId,
              data: stripBase64(value.data),
              name: value.name
            }
          }
        ]
      });
    }
  } else if (value === null && savedValue) {
    changes.push({
      '@type': 'RequestQuestionValueChange',
      questionCode: question.originalCode,
      businessObjectId: question.passportId,
      newValue: [{ fileInfo: null }]
    });
    if (savedValue.complexValueId) {
      changes.push({
        '@type': 'RequestRemoveComplexQuestionItem',
        questionCode: question.originalCode,
        businessObjectId: question.passportId,
        valueId: savedValue.complexValueId
      });
    }
  }
  return changes;
}
