import { flcHasValue } from '../Utils/flcHasValue';

export function getTableChange(question, cellsValue, savedCellsValue) {
  let tableValue = [];
  if (JSON.stringify(cellsValue) !== JSON.stringify(savedCellsValue)) {
    const columnOrder = question.config.verticalDomainDatas;
    const rowOrder = question.config.horizontalDomainDatas;
    for (const columnSet of question.domain.columns.cells) {
      for (const rowSet of question.domain.rows.cells) {
        let code = JSON.stringify({ row: rowSet, column: columnSet });
        if (cellsValue[code].value !== savedCellsValue[code].value) {
          const cell = cellsValue[code];
          tableValue.push({
            coordinates: {
              columnSet: columnOrder.map(code => cell.coordinates.column[code]),
              rowSet: rowOrder.map(code => cell.coordinates.row[code])
            },
            value: flcHasValue(cell.value) ? parseInt(cell.value) : null
          });
        }
      }
    }
  }

  // данные по не существующим координатам
  if (question.cellsToDelete.length > 0) {
    const columnOrder = question.config.verticalDomainDatas;
    const rowOrder = question.config.horizontalDomainDatas;
    for (const cell of question.cellsToDelete) {
      tableValue.push({
        coordinates: {
          columnSet: columnOrder.map(code => cell.coordinates.column[code]),
          rowSet: rowOrder.map(code => cell.coordinates.row[code])
        },
        value: null
      });
    }
  }

  return tableValue;
}
