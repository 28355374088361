import { createReducer } from 'redux-starter-kit';
import { history } from '../../_helpers/history';
import routerProps from '../../_helpers/routerProps';
import paths from '../../_helpers/paths';
import { CheckQueueNumberApi } from './CheckQueueNumberApi';
import Notice from '../../modules/utils/Notice';
import Dict from '../../modules/utils/Dict';

/**
 * Constants
 */

export const checkQueueNumberModule = 'check_queue_number';
const SET_DATA = `${checkQueueNumberModule}/SET_DATA`;
const LOADING = `${checkQueueNumberModule}/LOADING`;
const CLEAR_STATE = `${checkQueueNumberModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data: null,
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.data;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [CLEAR_STATE]: () => initialState
});

export const clearState = () => ({ type: CLEAR_STATE })

export const chechQueueNumber = (iin, lang) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    let filter = JSON.stringify({childIin: iin});
    const { data } = await CheckQueueNumberApi.getQueueInfo(iin);
    for (let row of data.result.content) {
      row.providerLocation = await Dict.item('60', row.queuedRegion);
    }
    const queue_types = await Dict.items('gu_queued_type');
    if(data.status === "SUCCESS"){
      await Promise.all(data.result.content.map(async item => {
        let id = item.id;
        const d = new Date(item.childBirthday);
        let filter = JSON.stringify({
          queuedType: item.queuedType, queuedRegion: item.queuedRegion, correctionType: item.correctionType,
          childYearBirth: d.getFullYear(), binDdo: item.binDdo, rehabType: item.rehabType
        });
        switch (lang){
          case 'kk':
            item['queuedType'] = queue_types.filter(type => type.code === item['queuedType']).map( obj => obj.kk_name);
            break;
          default:
            item['queuedType'] = queue_types.filter(type => type.code === item['queuedType']).map( obj => obj.ru_name);
        }
      }));
      dispatch({ type: SET_DATA, data: data.result.content });
    }else{
      Notice.error('Ошибка запроса очереди');
    }
  } catch (error) {
    let location = window.location;
    for (const route of Object.values(routerProps)) {
      if (route.path === location.pathname && !route.withoutAuth) {
        window.addressBarUrl = `${location.pathname}${location.search}`;
        history.push(paths.homePage);
      }
    }
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};
