import React, { useEffect, useState, useCallback } from 'react';
import Trigger from '../../FormData/ChangeTrigger';
import Form from '../../FormData/PassportForm';
import { runFlc } from '../../Flc/execute/runFlc';
import { TestMode } from '../../Flc/TestMode';
import { flcHasValue } from '../../Utils/flcHasValue';
import FormChange from '../../FormData/FormChange';
import { handleQuestionLabel } from '../../Utils/handleQuestionLabel';

const checkCellValue = (code, cellValue, cellHasValue, setCellHasValue) => {
  const hasCellValue = flcHasValue(cellValue);
  if (hasCellValue !== cellHasValue) {
    if (hasCellValue) {
      setCellHasValue(true);
    } else {
      let hasValue = false;
      if (Form.cellsValues[code]) {
        for (const cellObject of Object.values(Form.cellsValues[code])) {
          if (flcHasValue(cellObject.value)) {
            hasValue = true;
            break;
          }
        }
      }
      if (hasValue !== cellHasValue) {
        setCellHasValue(hasValue);
      }
    }
  }
};

function withTableFlc(Component) {
  /**
   * @return {null}
   */
  function WithTableFlc(props) {
    const { code, dependency: deps, groupHided } = props;
    const [cellHasValue, setCellHasValue] = useState(props.cellHasValue);
    const [source, setSource] = useState(props.source);
    const [cellsDefaultValue, setCellsDefaultValue] = useState({});
    const [flc, setFlc] = useState({});

    const handleFlc = useCallback(
      () => {
        let newFlc = runFlc(props, flc.visibility, cellHasValue ? true : undefined);
        if (JSON.stringify(newFlc) !== JSON.stringify(flc)) {
          setFlc(newFlc);
        }
        if (newFlc.calculation !== undefined) {
          Form.setValue(code, newFlc.calculation, { isTable: true });
        }
      },
      // eslint-disable-next-line
      [cellHasValue, flc, groupHided]
    );

    useEffect(handleFlc, [cellHasValue, groupHided]);
    useEffect(() => Form.setFlc(code, flc), [code, flc]);
    useEffect(() => Trigger.subscribe(deps, code, handleFlc), [deps, code, handleFlc]);
    useEffect(() => TestMode.register(code, handleFlc), [code, handleFlc]);
    useEffect(
      () =>
        FormChange.subscribe(code, (cellsValue, source) => {
          setCellsDefaultValue(cellsValue);
          setSource(source);
        }),
      [code]
    );

    // скрываем по правилам флк
    if (!flc.visibility || flc.hideMode) {
      if (window.test) {
        return (
          <div style={{ opacity: 0.5 }}>
            Скрытый показатель - {handleQuestionLabel(props, 'ru')}
          </div>
        );
      }
      return null;
    }

    return (
      <Component
        {...props}
        source={source}
        cellsDefaultValue={cellsDefaultValue}
        error={flc.validation}
        required={flc.required}
        matrixVisibility={flc['matrixVisibility']}
        checkCellHasValue={cellValue =>
          checkCellValue(code, cellValue, cellHasValue, setCellHasValue)
        }
        disabled={props.disabled || flc.readOnly}
      />
    );
  }
  return WithTableFlc;
}

export default withTableFlc;
