import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    header_help: 'Помощь',
    header_register: 'Регистрация',
    header_login: 'Войти',
    header_lang_ru: 'Русский',
    header_lang_kk: 'Қазақша',
    header_myProfile: 'Личный кабинет',
    header_getEDS: 'Получить ЭЦП',
    header_exit: 'Выйти',
    header_support: 'Служба поддержки',
    header_faq: 'Часто задаваемые вопросы',
    header_guide: 'Руководство пользователя',
    header_video: 'Видео инструкция пользователя',
    header_account: 'Управление профилем',
    header_agreement: 'Если ребенок зачислен в ДО, для корректной работы МП ("e-zhetysu qr") нужно подписать соглашение на сбор и обработку данных (фотографии)',
    header_agreementLink: 'Подписать соглашение можно здесь',
    header_agreementModalText: 'Я даю согласие на использование своих персональных данных, полученных из внешних систем, также на сбор и обработку персональных данных (фотографии) ребенка с бумажного носителя в электронный, для оказания услуги',
    header_agreementSign: 'Подписать',
    header_agreementSaveSuccess: 'Успешно подписана',
    header_agreementErrorSuccess: 'Не удалось подписать',
    header_agreementDonSign: 'Не согласен',
    header_agreementDonSignText: 'Если Вы не согласны на сбор и обработку данных, для корректной работы мобильного приложения нужно сканировать QR самостоятельно',
  },
  kk: {
    header_help: 'Көмек',
    header_register: 'Регистрация',
    header_login: 'Кіру',
    header_lang_ru: 'Русский',
    header_lang_kk: 'Қазақша',
    header_myProfile: 'Жеке кабинет',
    header_getEDS: 'ЭЦҚ алу',
    header_exit: 'Шығу',
    header_support: 'Қолдау қызметі',
    header_faq: 'Жиі қойылатын сұрақтар',
    header_guide: 'Пайдаланушы нұсқаулығы',
    header_video: 'Пайдаланушының бейне нұсқаулығы',
    header_account: 'Профильді басқару',
    header_agreement: 'Егер бала мектепке дейінгі мекемеге қабылданса, МП ("e-zhetysu qr") дұрыс жұмыс істеуі үшін деректерді (суреттер) жинау және өңдеу туралы келісімге қол қою қажет.',
    header_agreementLink: ' Келісімге осы жерде қол қоюға болады',
    header_agreementModalText: 'Мен сыртқы жүйелерден алынған жеке деректерімді пайдалануға, сондай-ақ баланың жеке деректерін (фотосуреттерін) қағаздан электронды тасымалдағышқа жинауға және өңдеуге келісім беремін.',
    header_agreementSign: 'Қол қою',
    header_agreementSaveSuccess: 'Сәтті қол қойылды',
    header_agreementErrorSuccess: 'Қол қою мүмкін болмады',
    header_agreementDonSign: 'Келіспеймін',
    header_agreementDonSignText: 'Егер сіз деректерді жинауға және өңдеуге келіспесеңіз, мобильді қосымша дұрыс жұмыс істеуі үшін QR-ді өзіңіз сканерлеуіңіз керек.',
  }
});
