export function encodeBase64(data) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(stripBase64(reader.result));
    reader.onerror = error => reject(error);
  });
}
export function stripBase64(string) {
  return string.slice(string.indexOf(',') + 1);
}
