import { useEffect, useState } from 'react';
import {
  addChildren,
  arrayToTree,
  getChildrenByCode,
  loadChildLevel,
  loadTree,
  treeFind,
  treeMap
} from './DTreeSelectUtils';

function useDTree({
  value = '',
  onChange,
  parentSelectable,
  lookupId,
  filterCode = '',
  options,
  hardDict
}) {
  const [loadingArr, setLoadingArr] = useState([]);
  const [tree, setTree] = useState(arrayToTree(options, value, true, !hardDict));
  const [anchorEl, setAnchorElement] = useState(null);
  const [filteredCode, setFilteredCode] = useState(filterCode);
  const [status, setStatus] = useState(null);

  const setAnchorEl = anchor => {
    setAnchorElement(anchor);
    if (hardDict && status === null) setStatus('loading');
  };

  useEffect(() => {
    if (!hardDict) {
      setTree(arrayToTree(options, value, true, !hardDict))
    }
  },[options, value, hardDict]);

  useEffect(() => {
    if (!hardDict && filterCode && filterCode !== filteredCode) {
      setTree(getChildrenByCode(tree, filterCode));
      setFilteredCode(filterCode);
    }
    // eslint-disable-next-line
  }, [filterCode]);

  useEffect(() => {
    let isCancelled = false;
    if (hardDict && status !== null) {
      if (
        filterCode !== filteredCode ||
        status === 'loading' ||
        !treeFind(tree, item => item.code === value)
      ) {
        loadTree(lookupId, value, filterCode).then(tree => {
          if (!isCancelled) {
            setTree(tree);
            setFilteredCode(filterCode);
            setStatus('loaded');
          }
        });
      }
    } else if (value && !treeFind(tree, item => item.code === value)) {
      loadTree(lookupId, value, filterCode).then(tree => {
        if (!isCancelled) {
          setTree(tree);
          setFilteredCode(filterCode);
          setStatus('loaded');
        }
      });
    }
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [lookupId, value, filterCode, status]);

  const handleExpand = (item, changeTrigger) => {
    let newTree = treeMap(tree, treeItem => {
      if (treeItem.code === item.code) {
        treeItem.expanded = !treeItem.expanded;
      }
      return treeItem;
    });
    if (lookupId && !item.childItems) {
      setLoadingArr([...loadingArr, item.code]);
      loadChildLevel(lookupId, item.code).then(children => {
        if (changeTrigger && children.length === 0) {
          if (typeof onChange === 'function') {
            onChange(item.code);
            setAnchorEl(null);
          }
        }
        addChildren(newTree, item.code, children);
        setTree(newTree);
        let arr = loadingArr.filter(code => code === item.code);
        setLoadingArr(arr);
      });
    } else {
      setTree(newTree);
    }
  };

  const handleChange = item => {
    if (parentSelectable) {
      onChange(item.code);
      setAnchorEl(null);
    } else {
      if (!item.childItems || item.childItems.length > 0) {
        handleExpand(item, true);
      } else if (typeof onChange === 'function') {
        onChange(item.code);
        setAnchorEl(null);
      }
    }
  };

  return {
    tree,
    loadingArr,
    onExpand: handleExpand,
    onChange: handleChange,
    anchorEl,
    setAnchorEl,
    loadingMenu: status === 'loading'
  };
}

export default useDTree;
