import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputBase from '@material-ui/core/InputBase/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';

import { usePagePaginationStyles } from './TableStyle';
import Select from '@material-ui/core/Select';

/**
 * @return {null}
 */
function PagePagination({ pageNumber, totalPages, onPageChange, pageSizes, pageSize, changePageSize, totalElements }) {
  const [page, setPage] = useState('');
  const [page_size, setPage_size] = useState(pageSize);
  const classes = usePagePaginationStyles();
  const { t } = useTranslation();

  const handle_page_size = (e) => {
    setPage_size(e.target.value);
    changePageSize(parseInt(e.target.value));
  }

  const onChangeHandler = ({ target }) => {
    const reg = /^\d+$/;
    if (reg.test(target.value)) {
      if (target.value === '0') return null;
      if (JSON.parse(target.value) > totalPages) return null;

      setPage(target.value);
    } else if (!target.value) {
      setPage('');
    }
  };

  useEffect(() => {
    setPage_size(10)
  }, [])

  if (totalPages > 0 && onPageChange) {
    return (
      <div className="mt1 flex justify-between items-center">
        <Pagination
          shape="rounded"
          variant="outlined"
          color="primary"
          page={pageNumber}
          count={ Math.ceil(totalElements / page_size)}
          onChange={(_, page) => onPageChange(page)}
          showFirstButton
          showLastButton
        />
        {pageSizes && pageSizes.length > 0 &&
          <Select
            native
            value={page_size}
            onChange={(e)=>{handle_page_size(e)}}
          >
            {pageSizes.map((item, index) =>{
              return <option value={item} key={index}>{item}</option>
            })}
          </Select>
        }
        <Paper className={classes.root}>
          <InputBase
            value={page}
            onChange={onChangeHandler}
            className={classes.input}
            placeholder={t('staticTable_page')}
            max={totalPages}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            className={classes.iconButton}
            disabled={!page || String(page) === String(pageNumber)}
            onClick={() => onPageChange(page)}
          >
            <DirectionsIcon />
          </IconButton>
        </Paper>
      </div>
    );
  }

  return null;
}

export default PagePagination;
