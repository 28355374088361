import { metaCaching } from './Utils/metaCaching';
import axios from 'axios';

export const PassportApi = {
  loadMeta: id => metaCaching(`/api/public/metadata/${id}`),
  getCurrentTime: () => axios.get(`/api/public/info/current_time`)
};

export const DocumentApi = {
  download: (downloadLink, version) =>
    axios.get(`api:fileStore${downloadLink}?documentVersion=${version}&action=download`, {
      responseType: 'arraybuffer'
    }),
  view: (downloadLink, version) =>
    axios.get(`api:fileStore${downloadLink}?documentVersion=${version}&action=view`, {
      responseType: 'blob'
    })
};
