import React from 'react';
import Colors from '../components/ThemeProvider/Colors';

const Row = ({ label, noteText, children, ...restProps }) => (
  <div {...restProps}>
    {label && noteText ? (
      <>
        <div style={{ marginBottom: '4px' }}>{label}</div>
        <div
          style={{
            marginBottom: '19px',
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '14px',
            color: Colors.textNote,
            fontFamily: 'Montserrat',
          }}
        >
          {noteText}
        </div>
      </>
    ) : (
      label && <div style={{ marginBottom: '5px', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: '600',}}>{label}</div>
    )}
    {children}
  </div>
);

export default Row;
