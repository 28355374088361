import React from 'react';
import { capitalize } from '@material-ui/core';

const styles = {
  textAlign: 'center',
};

const stylesMessage = {
  whiteSpace: 'pre-wrap',
};

export const requestsColumns = ({ t, lang }) => {
  return [
    {
      Header: t('kindergarten_notifications_date'),
      style: styles,
      width: 200,
      Cell: (row) => {return row.original.sendDate ? row.original.sendDate: row.original.createdOn}
    },
    {
      Header: t('kindergarten_notifications_description'),
      Cell: (row) => {return <div dangerouslySetInnerHTML={{ __html: row.original[`message${capitalize(lang)}`] }}></div>},
      style: stylesMessage
    }
  ];
};
