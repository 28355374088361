import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';

import Modal from '../../../_ui/Modal/Modal';
import { StyledModalContainer, TableWrapper, TreeSelectWrapper } from './ServiceProvidersModalStyles';
import ServiceProvidersTable from './ServiceProvidersTable';
import useColumnFilter from '../../../components/TableFilter/useColumnFilter';
import { changeFormValue } from '../../../passport/Passport';
import TreeSelect from '../../../modules/TreeSelect/TreeSelect';
import useTree from '../../../components/TableFilter/ColumnsFilters/FilterTreeSelect/useTree';
import './ServiceProvidersTranslate';

const lookupId = 60;
const FILTER_CODE = '330000000';

function ServiceProvidersModal({ modal, setFormData }) {
  const { data, close } = modal;
  const { t, i18n } = useTranslation();

  const { value, setFilter } = useColumnFilter('extraFilters', 1);
  const parsedValue = JSON.parse(value) || {};
  const { tree, onExpand, loadingArr } = useTree({
    lookupId,
    filterCode: FILTER_CODE,
    value: parsedValue['locationCode'] === FILTER_CODE ? '' : parsedValue['locationCode']
  });

  const onClickRow = ({ original }) => {
    if (data.code === 'request_form_accept_doc_edu_short.School_Name_applic' || data.code === 'request_form_accept_school_short.School_Name_applic') {
      setFormData({ ...original });
    }
    if (data.code) {
      changeFormValue(data.code, original[`${i18n.language}_name`]);
    } else {
      setFormData({ ...original });
    }
    modal.close();
  };

  useEffect(() => {
    if (data.code) {
      if (data.code === 'request_form_accept_doc_edu_short.School_Name_applic' || data.code === 'request_form_accept_school_short.School_Name_applic') {
        let typeApplic = null;
        let areaCode = null;
        if (data.code === 'request_form_accept_school_short.School_Name_applic') {
          typeApplic = window.PassportForm.values['request_form_accept_school_short.Type_applic'];
          areaCode = window.PassportForm.values['request_form_accept_school_short.AreaCode_applic'];
        } else {
          typeApplic = window.PassportForm.values['request_form_accept_doc_edu_short.Type_applic'];
          areaCode = window.PassportForm.values['request_form_accept_doc_edu_short.AreaCode_applic'];
        }
        if (['2', '3', '4'].includes(typeApplic)) {
          areaCode = window.PassportForm.values['request_form_accept_doc_edu_short.AreaCode_applic_short'];
        }
        let typeCode, subTypeCode;
        switch (typeApplic) {
          case '0':
          case '1':
            typeCode = '02,07';
            break;
          case '2':
          case '3':
            typeCode = '13';
            break;
          case '4':
            typeCode = '12';
            break;
          case '5':
            typeCode = '08';
            subTypeCode = '08.3,08.4,08.5,08.6';
            break;
          case '6':
            typeCode = '02';
            break;
          default:
            break;
        }
        setFilter(JSON.stringify({
          ...parsedValue,
          locationCode: areaCode,
          serviceProviderType: typeCode,
          serviceProviderSubType: subTypeCode
        }));
      } else {
        setFilter(JSON.stringify({
          ...parsedValue,
          serviceProviderType: '03'
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open
      maxWidth="lg"
      title={t('serviceProviders_title')}
      onClose={close}
      scroll="paper"
    >
      <DialogContent dividers>
        <div className="overflow-auto">
          <StyledModalContainer>
            <TreeSelectWrapper>
              <span>{t('serviceProviders_columnLocation')}</span>
              <TreeSelect
                lookupId={lookupId}
                loadingArr={loadingArr}
                onExpand={onExpand}
                data={tree}
                withoutForm
                fullWidth
                onChange={(v) => setFilter(JSON.stringify({
                  ...parsedValue,
                  locationCode: v
                }))}
                value={parsedValue['locationCode']}
              />
            </TreeSelectWrapper>
            <TableWrapper>
              <ServiceProvidersTable
                index={1}
                onClickRow={onClickRow}
              />
            </TableWrapper>
          </StyledModalContainer>
        </div>
      </DialogContent>
    </Modal>
  );
}

export default ServiceProvidersModal;
