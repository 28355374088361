export async function setCheckboxGroupData(question, valueObj) {
  question.savedValue = [];
  question.savedVersion = [];

  if (valueObj.values && valueObj.values[0]) {
    const values = valueObj.values[0];
    question.source = values.source || {};
    question.savedValue = values.value instanceof Array ? values.value : [];

    if (valueObj.values[0].versions) {
      question.savedVersion = valueObj.values[0].versions;
    }
  }
}
