export default {
  ru: {
    flc_required: 'Обязательно для заполнения',
    flc_incorrectValue: 'Некорректное значение',
    flc_email_validation: 'Неправильный формат адреса электронной почты',
    flc_page_validation: 'Ошибка на выделенных страницах',
  },
  kk: {
    flc_required: 'Міндетті өріс',
    flc_incorrectValue: 'Жарамсыз мән',
    flc_email_validation: 'Жарамсыз электрондық пошта форматы',
    flc_page_validation: 'Белгіленген беттерде қате бар',
  }
}
