import React from 'react';
import { useTranslation } from 'react-i18next';
import {StyledService, StyledTitle, StyledServiceCards} from '../KindergartenServicesStyle';
import './../KindergartenServicesTranslate';
import { Content } from '../../../components/ThemeProvider/ThemeStyle';
import {kindergarten_services_items} from './../KindergartenServicesItems';
import OurServiceCard from './OurServiceCard'

function KindergartenOurService({contentWidth}) {
  const { t } = useTranslation();

  return (
    <Content contentWidth={contentWidth}>
        <StyledService>
            <StyledTitle children={t('kindergarten_our_services')} />
            <StyledServiceCards>
                {Object.values(kindergarten_services_items).map((metadata, index) => (
                <OurServiceCard
                    key={index}
                    metadata={metadata}
                />
                ))}
            </StyledServiceCards>
        </StyledService>
    </Content>
  );
}

export default KindergartenOurService;