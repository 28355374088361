import { createMuiTheme } from '@material-ui/core';
import lodash from 'lodash';
import { Color, getTheme } from '../../ThemeProvider/ThemeStyle';
import styled from 'styled-components';
import green from '@material-ui/core/colors/green';

export const tableTheme = createMuiTheme(
  lodash.merge(getTheme(), {
    props: {
      MuiTextField: {
        style: {
          marginTop: 0,
          marginBottom: 0
        }
      },
      MuiTableCell: {
        style: {
          padding: 0,
          minWidth: 200,
          maxWidth: 300,
          height: 33
        }
      }
    },
    overrides: {
      MuiFormHelperText: { contained: { paddingLeft: 15, marginTop: 0 } },
      MuiOutlinedInput: {
        input: {
          paddingBottom: '6px !important',
          paddingTop: '6px !important'
        }
      },
      MuiInputBase: {
        root: {
          height: 'inherit',
          '&$disabled': {
            background: 'transparent'
          }
        }
      }
    }
  })
);

export const Wrapper = styled.div`
  margin: 16px 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  table {
    background-color: ${() => Color.bgColorPaper};
    border-collapse: separate;
    border-radius: ${() => Color.borderRadius};
    border-top: 1px solid ${() => Color.border};
    border-left: 1px solid ${() => Color.border};
    ${props => (props.error ? 'border: 1px solid red;' : '')};
  }
  td {
    fieldset {
      display: none !important;
    }
    border-right: 1px solid ${() => Color.border};
  }

  tr:last-child {
    td {
      :first-child {
        border-bottom-left-radius: ${() => Color.borderRadius};
      }
      :last-child {
        border-bottom-right-radius: ${() => Color.borderRadius};
      }
    }
  }
  th {
    border-right: 1px solid ${() => Color.border};
    :first-child {
      border-top-left-radius: ${() => Color.borderRadius};
    }
    :last-child {
      border-top-right-radius: ${() => Color.borderRadius};
    }
  }

  .column-title,
  .row-title {
    font-size: 13px !important;
    background-color: rgba(125, 125, 125, 0.1);
    padding: 3px 10px !important;
    line-height: 1 !important;
  }

  .row-title {
    height: 36px;
  }

  .row-title {
    padding: 7px 10px !important;
  }
  .Mui-error {
    background-color: #ff003308 !important;
  }
  .cell-disabled {
    background-color: rgba(125, 125, 125, 0.1);
  }
  .cell-changed {
    border-bottom: 3px solid ${green[200]};
  }
`;
