import Trigger from './ChangeTrigger';
import FormChange from './FormChange';
import { RENDERER } from '../Utils/Constants';
import { createContext } from '../Flc/execute/context';
import { getComplexChanges } from './getComplexChanges';
import { getTableChange } from './getTableChange';
import { valueVersion } from './valueVersion';
import { hasFormError } from './hasFormError';
import { buildChange } from './buildChange';
import FlcTrigger from './FlcTrigger';
import LoadedStatusTrigger from './LoadedStatusTrigger';
import { getDocumentChange } from './getDocumentChange';
import { getComplexCellsChanges } from './getComplexCellsChanges';
import { cloneObject } from '../Utils/cloneObject';
import moment from 'moment';

class PassportForm {
  // текущее
  static info = {};
  static values = {};
  static cellsValues = {};
  static versions = {};
  static flc = {};
  static complexPagesErrors = {};
  static cellsFlc = {};
  static currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');

  // сохраненные
  static savedValues = {};
  static savedCellsValues = {};
  static savedFlc = {};
  static questions = {};
  static metadataKeys = [];
  static saveWithError = [];

  static clear() {
    // текущее
    this.info = {};
    this.values = {};
    this.cellsValues = {};
    this.flc = {};
    this.cellsFlc = {};
    this.complexPagesErrors = {};

    // сохраненные
    this.savedValues = {};
    this.savedCellsValues = {};
    this.savedFlc = {};
    this.questions = {};
    this.groups = {};
    this.metadataKeys = [];
    this.saveWithError = [];
  }

  static async getFormValues(onlyFlc, checkError) {
    let result = {};

    if (!window.hasAccessToSaveWithErrors) {
      if (hasFormError(this.flc, this.saveWithError, checkError)) return false;
      if (hasFormError(this.cellsFlc, this.saveWithError, checkError)) {
        hasFormError(this.complexPagesErrors, this.saveWithError, checkError);
        return false;
      }
    }

    const setHidedGroupChanges = groups => {
      for (const group of groups) {
        if (this.flc[group.code]) {
          if (this.flc[group.code].visibility === false || this.flc[group.code].hided) {
            setChanges(group.metaDataId, [
              {
                '@type': 'RequestRefreshStatisticInfoChange',
                sections: [group.id]
              }
            ]);
          }
        }
        if (group.subGroups && group.subGroups.length > 0) {
          setHidedGroupChanges(group.subGroups);
        }
      }
    };

    const setChanges = (metaDataId, changes) => {
      if (result.hasOwnProperty(metaDataId)) {
        result[metaDataId] = [...result[metaDataId], ...changes];
      } else {
        result[metaDataId] = changes;
      }
    };

    setHidedGroupChanges(this.groups);

    if (!onlyFlc) {
      // собираем changes
      for (const code of Object.keys(this.values)) {
        const question = this.questions[code];
        const value = this.values[code];
        const savedValue = this.savedValues[code];
        const { dropdown, dropdown_tree, document } = RENDERER;
        const { complex_inline, complex_table, table } = RENDERER;
        const { checkbox_group, multiple_dropdown_tree } = RENDERER;

        if (question === undefined) continue;

        // виртуальные показатели
        if (
          question.code.indexOf('v_') === 0 ||
          question.originalCode.indexOf('v_') === 0
        ) {
          continue;
        }

        if ([complex_inline, complex_table, table].includes(question.renderer)) {
          continue;
        }

        if (question.renderer === document) {
          const changes = getDocumentChange(question, value, savedValue);
          setChanges(question.metaDataId, changes);
        } else if ([dropdown, dropdown_tree].includes(question.renderer)) {
          const version = this.versions[code];
          const savedVersion = question.savedVersion;
          if (value !== savedValue || version !== savedVersion) {
            const qValue = await valueVersion(this.questions[code], value, savedVersion);
            const change = buildChange(question, qValue);
            setChanges(question.metaDataId, [change]);
          }
        } else if ([checkbox_group, multiple_dropdown_tree].includes(question.renderer)) {
          const version = this.versions[code];
          const savedVersion = question.savedVersion;
          if (
            JSON.stringify(value) !== JSON.stringify(savedValue) ||
            JSON.stringify(version) !== JSON.stringify(savedVersion)
          ) {
            const qValue = await valueVersion(this.questions[code], value);
            const change = buildChange(question, qValue);
            setChanges(question.metaDataId, [change]);
          }
        } else {
          if (value !== savedValue) {
            const change = buildChange(question, value);
            setChanges(question.metaDataId, [change]);
          }
        }
      }

      // собираем changes для таблиц
      for (const code of Object.keys(this.cellsValues)) {
        const { complex_inline, complex_table, table, document } = RENDERER;
        const question = this.questions[code];
        const cellsValue = this.cellsValues[code];
        const savedCellsValue = this.savedCellsValues[code];

        if (question === undefined) continue;
        if (question.renderer === table) {
          const qValue = getTableChange(question, cellsValue, savedCellsValue);
          if (qValue.length > 0) {
            const change = buildChange(question, qValue);
            setChanges(question.metaDataId, [change]);
          }
        }
        if ([complex_inline, complex_table].includes(question.renderer)) {
          const changes = getComplexChanges(question, cellsValue, savedCellsValue);
          const cellChanges = await getComplexCellsChanges(
            question,
            cellsValue,
            savedCellsValue,
            this.versions
          );
          setChanges(question.metaDataId, changes);
          setChanges(question.metaDataId, cellChanges);
        }
        if (question.renderer === document) {
          const cellChanges = await getComplexCellsChanges(
            question,
            cellsValue,
            savedCellsValue,
            this.versions
          );
          setChanges(question.metaDataId, cellChanges);
        }
      }
    }

    // собираем changes для ФЛК
    for (const code of Object.keys(this.savedFlc)) {
      const flc = this.flc[code];
      const savedFlc = this.savedFlc[code];
      if (flc) {
        if (
          flc.readOnly !== savedFlc.readOnly ||
          flc.required !== savedFlc.required ||
          flc.visibility !== savedFlc.visibility
        ) {
          const question = this.questions[code];
          if (question === undefined) continue;
          const change = buildChange({ ...question, type: 'flc' }, flc);
          setChanges(question.metaDataId, [change]);
        }
      }
    }

    return result;
  }

  static initInfo(info = {}) {
    this.info = cloneObject(info);
    Trigger.change('staticDataChanged');
  }

  static initQuestions(questions, groups, metadataKeys, saveWithError) {
    let values = {};
    let cellsValues = {};
    let savedFlc = {};
    for (const question of Object.values(cloneObject(questions))) {
      const { table, complex_table, complex_inline, document } = RENDERER;
      savedFlc[question.code] = question.savedFlc;

      if ([table, complex_table, complex_inline].includes(question.renderer)) {
        cellsValues[question.code] = question.cellsSavedValue;
      } else if (question.renderer === document) {
        values[question.code] = question.savedValue;
        cellsValues[question.code] = question.cellsSavedValue;
      } else {
        values[question.code] = question.savedValue;
      }
    }

    this.values = values;
    this.cellsValues = cellsValues;

    this.savedValues = cloneObject(values);
    this.savedCellsValues = cloneObject(cellsValues);
    this.savedFlc = savedFlc;
    this.questions = cloneObject(questions);
    this.groups = cloneObject(groups);
    this.metadataKeys = cloneObject(metadataKeys);
    this.saveWithError = cloneObject(saveWithError);

    window.PassportForm = PassportForm;
    window.ChangeTrigger = Trigger;
    window.FormChange = FormChange;
    window.LoadedStatusTrigger = LoadedStatusTrigger;
    window.FlcTrigger = FlcTrigger;
    window.context = createContext();
    Trigger.change('staticDataChanged');
    return () => this.clear();
  }

  static clearChangedValues() {
    this.values = cloneObject(this.savedValues);
    this.cellsValues = cloneObject(this.savedCellsValues);
  }

  static setVersion(code, version) {
    this.versions[code] = version;
  }

  static setValue(code, value, config = {}) {
    let valueChanged = false;
    if (config.isComplex) {
      valueChanged = true;
      if (config.type === 'add') {
        let cellValues = {};
        for (const questionCode of Object.keys(config.cellsQuestions)) {
          cellValues[questionCode] = '';
        }
        this.cellsValues[code][value] = cellValues;
      } else if (config.type === 'delete') {
        if (config.newCode) {
          this.cellsValues[code][config.newCode] = {};
        }
        delete this.cellsValues[code][value];
      } else {
        this.cellsValues[code] = value;
      }
    } else if (config.isComplexCell) {
      if (this.cellsValues[config.code][config.complexCode]) {
        if (
          this.cellsValues[config.code][config.complexCode][config.questionCode] !== value
        ) {
          valueChanged = true;
          this.cellsValues[config.code][config.complexCode][config.questionCode] = value;
        }
      } else {
        this.cellsValues[config.code][config.complexCode] = {
          [config.questionCode]: value
        };
      }
    } else if (config.isTable) {
      valueChanged = true;
      this.cellsValues[code] = value;
    } else if (config.isTableCell) {
      if (this.cellsValues[config.code]) {
        if (this.cellsValues[config.code][config.questionCode]) {
          if (this.cellsValues[config.code][config.questionCode].value !== value) {
            valueChanged = true;
            this.cellsValues[config.code][config.questionCode].value = value;
          }
        }
      }
    } else if (config.isDocument) {
      this.values[code] = value;
      if (value && this.cellsValues[code].hasOwnProperty(value.complexValueId)) {
        const qValues = this.cellsValues[code][value.complexValueId];
        this.cellsValues[code] = { [value.complexValueId]: qValues };
      } else {
        this.cellsValues[code] = {};
        for (const qQuestion of Object.keys(config.cellsQuestions)) {
          this.cellsValues[code][qQuestion.code] = '';
        }
      }
    } else if (config.isDocumentCell) {
      valueChanged = true;
      if (this.cellsValues[config.code][config.complexCode]) {
        this.cellsValues[config.code][config.complexCode][config.questionCode] = value;
      } else {
        this.cellsValues[config.code][config.complexCode] = {
          [config.questionCode]: value
        };
      }
    } else {
      if (this.values[code] !== value) {
        valueChanged = true;
        this.values[code] = value;
      }
    }

    if (valueChanged) {
      Trigger.change(code, value, config);
    }
  }

  static setFlc(code, flc, cellConfig) {
    if (flc && Object.keys(flc).length > 1) {
      if (cellConfig) {
        this.cellsFlc[code] = flc;
        return () => {
          delete this.cellsFlc[code];
        };
      } else {
        this.flc[code] = flc;
        LoadedStatusTrigger.setQuestionStatus(code);
        FlcTrigger.run(code);
      }
    }
  }

  static groupHide(codes) {
    let hide = true;
    for (const code of codes) {
      if (this.flc[code]) {
        if (this.questions[code]) {
          if (this.flc[code].visibility) {
            hide = false;
            break;
          }
        } else {
          hide = false;
          break;
        }
      } else {
        hide = false;
        break;
      }
    }
    return hide;
  }

  static findCode(metaDataId, code) {
    if (code.indexOf('.') === -1) {
      if (metaDataId && this.questions.hasOwnProperty(`${metaDataId}.${code}`)) {
        return `${metaDataId}.${code}`;
      } else {
        for (const metaDataKey of this.metadataKeys) {
          if (this.questions.hasOwnProperty(`${metaDataKey}.${code}`)) {
            return `${metaDataKey}.${code}`;
          }
        }
      }
    } else {
      return code;
    }
  }
}
export default PassportForm;
