import styled from 'styled-components';
import { Color } from '../../ThemeProvider/ThemeStyle';

export const Wrapper = styled.div`
  margin: 16px 0 25px 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  > div.card {
    ${props => (props.error ? 'border: 1px solid red;' : '')};
  }
  .add-row {
    margin-top: -5px;
    padding: 4px 15px;
    background-color: ${() => Color.bgColor};
  }
  .delete-btn {
    position: absolute;
    right: 13px;
    top: 3px;
  }
`;
