import { addTranslation } from '../../../../_helpers/Translate';

addTranslation({
  ru: {
    kindergarten_reestr_filters_but: 'Применить',
    kindergarten_reestr_filters_clear: 'Очистить фильтр',
    kindergarten_reestr_filters_region: 'Населенный пункт',
    kindergarten_reestr_filters_ownership: 'Форма собственности',
    kindergarten_reestr_filters_type: 'Тип детского сада',
    kindergarten_reestr_filters_lang: 'Язык обучения',
    kindergarten_reestr_filters_age_group: 'Возрастная группа'
  },
  kk: {
    kindergarten_reestr_filters_but: 'Қолдану',
    kindergarten_reestr_filters_clear: 'Сүзгіні жою',
    kindergarten_reestr_filters_region: 'Елді мекен',
    kindergarten_reestr_filters_ownership: 'Ұйымның меншік нысаны',
    kindergarten_reestr_filters_type: 'Мектепке дейінгі ұйым түрі',
    kindergarten_reestr_filters_lang: 'Оқытылу тілі',
    kindergarten_reestr_filters_age_group: 'Жас топтары'
  }
});
