import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';

import { sideBarItems, sideBarQueueItems } from './SideBarItems';
import { useStyles, Wrapper } from './SideBarStyles';
import useUserInfo from '../../_hooks/useUserInfo';
import './SideBarTranslate';
import { Badge } from '@material-ui/core';
import {
  countNotifications,
  kindergartenNotificationsModule
} from '../../../pages/KindergartenNotifications/KindergartenNotificationsDucks';

export function getFullName(user) {
  const userFullName = [user.lastName, user.firstName, user.middleName];
  return userFullName
    .filter(n => n)
    .map(n => n.charAt(0).toUpperCase() + n.slice(1).toLowerCase())
    .join(' ');
}
const SideBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const user = useUserInfo();
  const counters = [];
  counters['notif_counter'] = useSelector(
    state => state[kindergartenNotificationsModule].notificationsCount
  );

  return (
    <div className="flex">
      <Wrapper>
        <Paper className={classes.paper}>
          <div className="flex items-center">
            {user.username && (
              <Avatar className={classes.avatar} src="/head_avatar.png" />
            )}
            <div className="ml2">
              {user.username && (
                <Typography
                  color="textPrimary"
                  className={classes.userName}
                  children={getFullName(user)}
                />
              )}
            </div>
          </div>
        </Paper>
        <Typography variant="h5" color="secondary" children={t('sidebar_title')} />
        <List className={classes.menu}>
          {sideBarItems.map(({ path, translate, icon }) => {
            return (
              <Link
                to={path}
                style={{ textDecoration: 'none' }}
                key={path}
                onClick={() => dispatch(countNotifications())}
              >
                <ListItem
                  key={path}
                  selected={location.pathname === path}
                  button
                  className={classes.menuItem}
                >
                  <ListItemIcon className={classes.icon} children={icon} />
                  <Typography children={t(translate)} style={{ marginLeft: '15px' }} />
                </ListItem>
              </Link>
            );
          })}
        </List>
        {
          !user.bin && 
          <>
            <Typography
              variant="h5"
              color="secondary"
              children={t('sidebar_queue_title')}
              style={{ marginTop: '15px' }}
            />
            <List className={classes.menu}>
              {sideBarQueueItems.map(({ counter, path, translate, icon }) => {
                return (
                  <Link
                    to={path}
                    style={{ textDecoration: 'none' }}
                    key={path}
                    onClick={() => dispatch(countNotifications())}
                  >
                    <ListItem
                      key={path}
                      selected={location.pathname === path}
                      button
                      className={classes.menuItem}
                    >
                      <Badge badgeContent={counter ? counters[counter] : 0} color="primary">
                        <ListItemIcon className={classes.icon} children={icon} />
                      </Badge>
                      <Typography children={t(translate)} style={{ marginLeft: '15px' }} />
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          </>
        }
        
      </Wrapper>
    </div>
  );
};

export default SideBar;
