import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navigation } from '../PassportStyle';
import withTranslation from './translation/withTranslation';
import FlcTrigger from '../FormData/FlcTrigger';
import Form from '../FormData/PassportForm';
import { PassportContext, ConfigContext } from '../PassportProvider';

/**
 * @return {null}
 */
function NavigationItem({ id, lang, subGroups, level, code, ...rest }) {
  let codes = [...subGroups.map(group => group.code), ...rest.questionCodes];

  const [hide, setHide] = useState(false);
  useEffect(
    () =>
      FlcTrigger.subscribe([code, ...codes], `navigation_group_${id}`, () => {
        setHide(Form.groupHide([code]) || Form.groupHide(codes));
      }),
    // eslint-disable-next-line
    []
  );

  if (hide) return null;
  return (
    <div key={id} className="my1" style={{ marginLeft: level * 15, lineHeight: 1 }}>
      <Link to={`#anchor_${id}`}>{rest[`${lang}_name`]}</Link>
      {subGroups.length > 0 && (
        <NavigationItems groups={subGroups} lang={lang} level={level + 1} />
      )}
    </div>
  );
}

const NavigationItems = ({ groups, lang, level }) => {
  return groups.map(group => (
    <NavigationItem key={group.id} {...group} lang={lang} level={level} />
  ));
};

/**
 * @return {null}
 */
function PassportNavigation({ lang, className }) {
  const { error, groups } = useContext(PassportContext);
  const { stickyTop } = useContext(ConfigContext);
  if (error || groups.length === 0) return null;
  return (
    <Navigation top={stickyTop} className={`card py1 px2 ${className}`}>
      <NavigationItems lang={lang} groups={groups} level={0} />
    </Navigation>
  );
}

export default withTranslation(PassportNavigation);
