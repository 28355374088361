import React, { useContext } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Row from '../Row';
import withFlc from '../flc/withFlc';
import CloseIcon from '@material-ui/icons/Close';
import { flcHasValue } from '../../Utils/flcHasValue';
import { changedStyle } from '../../PassportStyle';
import { ConfigContext, QuestionRender } from '../../PassportProvider';

function InputField({
  code,
  value = '',
  onChange,
  cellConfig,
  disabled,
  lang,
  error,
  changed,
  ...rest
}) {
  const questionRender = useContext(QuestionRender);
  const config = useContext(ConfigContext);
  const handleChange = event => {
    let value = event.target.value;
    if (value !== ' ') {
      if (rest.type === 'number' && value) {
        if (value.indexOf('-') !== -1) {
          value = value.replace(/-/, '');
        }
      }
      if (!rest.inputRestriction(value)) {
        onChange(value);
      }
    }
  };

  return (
    <div className="flex">
      <TextField
        multiline={config.wrapText}
        type={rest.type}
        placeholder={!disabled ? rest[`${lang}_placeholder`] : ''}
        value={value}
        title={value}
        onChange={handleChange}
        disabled={disabled}
        error={Boolean(error)}
        helperText={error}
        InputProps={{
          style: flcHasValue(value) && changed ? changedStyle : undefined,
          endAdornment: (
            <InputAdornment position="end">
              {flcHasValue(value) &&
                !disabled && (
                  <IconButton
                    className="show-on-hover"
                    tabIndex="-1"
                    onClick={() => onChange('')}
                    children={<CloseIcon fontSize="small" />}
                  />
                )}
            </InputAdornment>
          )
        }}
      />
      {questionRender && questionRender(cellConfig ? { ...cellConfig } : code, value)}
    </div>
  );
}

function DInput(props) {
  return <Row {...props} children={<InputField {...props} />} />;
}

export default withFlc(DInput);
