import { useLocation, useHistory } from 'react-router-dom';

export default function useModal(name) {
  const { pathname, search } = useLocation();
  const { replace } = useHistory();
  const sp = new URLSearchParams(search);

  return {
    isOpen: !!sp.get(name),
    data: JSON.parse(sp.get(name))?.data,
    open: (data) => {
      typeof data === 'object'
        ? sp.set(name, JSON.stringify({ data }))
        : sp.set(name, data);
      replace(`${pathname}?${sp.toString()}`);
    },
    close: () => {
      sp.delete(name);
      replace(`${pathname}?${sp.toString()}`);
    },
  };
}
