import Dict from './Dict';

export class DomainData {
  static cash = {};
  static queue = {};

  static async queueLoad(lookupId, callback,lookupFilter) {
    if (this.cash.hasOwnProperty(lookupId)) {
      callback(this.cash[lookupId]);
    } else {
      if (this.queue.hasOwnProperty(lookupId)) {
        this.queue[lookupId].callbacks.push(callback);
      } else {
        this.queue[lookupId] = {
          loading: true,
          callbacks: [callback]
        };
        let data = await Dict.items(lookupId,lookupFilter);
        if (data.length > 10000) {
          let dict = {};
          for (const item of data) {
            dict[item.code] = item;
          }
          this.cash[lookupId] = dict;
          for (const cb of this.queue[lookupId].callbacks) {
            cb(dict);
          }
          delete this.queue[lookupId];
        } else {
          this.cash[lookupId] = data;
          for (const cb of this.queue[lookupId].callbacks) {
            cb(data);
          }
          delete this.queue[lookupId];
        }
      }
    }
  }

  static async load(lookupId, callback) {
    if (this.cash.hasOwnProperty(lookupId)) {
      callback(this.cash[lookupId]);
    } else {
      let data = await Dict.items(lookupId);
      if (data.length > 10000) {
        let dict = {};
        for (const item of data) {
          dict[item.code] = item;
        }
        this.cash[lookupId] = dict;
      } else {
        this.cash[lookupId] = data;
      }
      callback(this.cash[lookupId]);
    }
  }
}

window.DomainData = DomainData;
