import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ServiceRequestApi } from '../ServiceRequestApi';
import Notice from '../../../modules/utils/Notice';
import { handleError } from '../../../modules/utils/handleError';
import { changeFormValue } from '../../../passport/Passport';
import Dict from '../../../modules/utils/Dict';

async function request({ t, lang, code, requesterIin }) {
  try {
    let response = {}, data = {};
    const source = code.split('.')[0];

    switch (code) {
      case 'request_form_government_order_short.adress_country':
        response = await ServiceRequestApi.searchGBDUL(requesterIin);
        data = response.data.result;
        const countryName = (await Dict.item('38934', data['CountryCode']))[`${lang}_name`];
        changeFormValue(`${source}.adress_country`, countryName);
        changeFormValue(`${source}.adress_area`, data[`${lang}District`]);
        changeFormValue(`${source}.adress_region`, data[`${lang}Region`]);
        changeFormValue(`${source}.adress_city`, data['katoCode']);
        changeFormValue(`${source}.adress_street`, data[`${lang}Street`]);
        changeFormValue(`${source}.adress_house`, data['buildingNumber']);
        changeFormValue(`${source}.adress_corpus_number`, data['Corpus']);
        changeFormValue(`${source}.adress_flat`, data['OfficeNumber']);
        break;
      default:
        break;
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.gbdul_organization_not_found') {
      Notice.error(t('gbdflButton_binNotFound'));
    } else {
      handleError(error, t('gbdflButton_searchFailed'));
    }
  }

}

function ChangeSimpleQuestions({ code, requesterIin }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    request({
      t,
      code,
      requesterIin,
      lang: i18n.language
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default ChangeSimpleQuestions;