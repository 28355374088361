import axios from 'axios';

export const TokenRefresherApi = {
  refreshUser: token =>
    axios.post('/token/refresh', null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
};
