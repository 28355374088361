import React from 'react';
import styled from 'styled-components';
import { Color } from '../ThemeProvider/ThemeStyle';
import withGroupFlc from '../flc/withGroupFlc';
import { Link } from 'react-router-dom';
import { handleQuestionLabel } from '../../Utils/handleQuestionLabel';

const Wrapper = styled(Link)`
  height: 90px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(125, 125, 125, 0.2), 0 0 0 1px rgba(125, 125, 125, 0.3);
  border-radius: ${() => Color.borderRadius};
  color: #2185d0;
  transition: box-shadow 0.3s ease;
  :hover {
    text-decoration: none;
    box-shadow: 0 3px 7px 2px #d4d4d5, 0 0 0 1px #d4d4d5;
  }
  .badge {
    background-color: #2185d0;
    margin-left: auto;
    min-width: 70px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: normal;
    font-size: 14px;
    padding: 3px !important;
  }
`;

/**
 * @return {null}
 */
export function Card({ id, lang, url, filledCount, count, ...rest }) {
  if (!window.test && rest.hide) return null;
  return (
    <div
      className="col col-6 px1 mb2"
      style={{ opacity: window.test && rest.hide ? 0.5 : 1 }}
    >
      <Wrapper to={url} id={`group_${id}`}>
        {handleQuestionLabel({id, ...rest}, lang, true)}
        <div className="badge badge-primary badge-pill" style={{display: rest.no_statistics ? "none" : "inherit" }}>
          {filledCount ? `${filledCount.filled} / ${filledCount.all}` : count}
        </div>
      </Wrapper>
    </div>
  );
}

export default withGroupFlc(Card);
