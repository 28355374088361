import styled from 'styled-components';
import Colors from '../../components/ThemeProvider/Colors';

export const StyledContent = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

export const StyledBreadCrumbs = styled.div`
  padding-top: 90px;
  z-index: 2;
  position: relative;
  @media screen and (max-width: 900px) {
    padding-top: 140px;
  }
  @media screen and (max-width: 600px) {
    padding-top: 170px;
  }
  @media screen and (max-width: 500px) {
    padding-top: 230px;
  }
  @media screen and (max-width: 400px) {
    padding-top: 250px;
  }
`;

export const StyledInsert = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledInsertContent = styled.div`
  position: absolute;
  bottom: 105px;
  left: 0;
  width: 100%;
  @media screen and (max-width: 500px) {
    bottom: 30px;
  }
`;

export const StyledInsertTitle = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 130%;
  text-transform: uppercase;
  color: #0D4780;
  width: 739px;
  @media screen and (max-width: 900px) {
    font-size: 30px;
    width: 500px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 27px;
  }
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

export const StyledInsertText = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #0D4780;
  width: 470px;
  @media screen and (max-width: 900px) {
    width: 370px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

export const StyledInsertBtn = styled.div`
  
`;

export const StyledSubtitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;


export const StyledService = styled.div`
  padding: 100px 0;
  @media screen and (max-width: 600px) {
    padding: 50px 0;
  }
`;

export const StyledTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #0D4780;
  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`;

export const StyledServiceCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  width: 100%;
  margin-top: 40px;
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledServiceCard = styled.div`
  width: 100%;
  background: ${Colors.cardBackground};;
  box-shadow: 0px 0px 14px rgba(10, 138, 156, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledServiceCardImg = styled.div`
 width: 100%;
 height: 162px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
`

export const StyledServiceCardContent = styled.div`
 width: 100%;
 padding-top: 12px;
 padding-left: 8px;
 padding-right: 8px;
 text-align: center;
`

export const StyledServiceCardTitle = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: #0D4780;
`

export const StyledServiceCardText = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #919191;
`

export const StyledServiceCardBtn = styled.div`
  padding-bottom: 20px;
  text-align: center;
  font-family: 'Montserrat' !important;
`

export const StyledQuestions = styled.div`
  width: 100%;
  background-color: #fff9f6;
  padding-top: 60px;
  padding-bottom: 65px;
  @media screen and (max-width: 600px) {
    padding-top: 40px;
    padding-bottom: 45px;
  }
`

export const StyledQuestionsCards  = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-top: 44px;
  @media screen and (max-width: 900px) {
    grid-gap: 15px;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
`

export const StyledQuestionsCard  = styled.div`
  text-align: center;
  
  & p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #0D4780;
    width: 162px;
    margin: auto;
    margin-top: 20px;
    cursor: pointer;
  }

  & img {
    cursor: pointer;
  }

  & a {
    display: inline-block;
  }
`
export const StyledQuestionsCardImg  = styled.div`
  width: 100px;
  height: 100px;
  margin: auto;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 800px) {
    width: 80px;
    height: 80px;
  }
  @media screen and (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
`

export const StyledUsefulArticles  = styled.div`
  width: 100%;
  position: relative;
  padding: 120px 0;
  @media screen and (max-width: 600px) {
    padding: 50px 0;
  }
`

export const StyledUsefulArticlesBg  = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding: 20px 0;
  & img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1000px) {
    padding: 0;
    & img {
      object-fit: cover;
    }
  }
`

export const StyledUsefulArticlesCards  = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 100%;
  margin-top: 40px;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 500px) {
    width: 95%;
  }
`

export const StyledUsefulArticlesCard  = styled.div`
  padding: 15px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px rgba(10, 138, 156, 0.1);
  @media screen and (max-width: 900px) {
    &:nth-child(3) {
      grid-column: 1 / span 2;
    }
  }
  @media screen and (max-width: 600px) {
    &:nth-child(3) {
      grid-column: 1;
    }
  }
`

export const StyledUsefulArticlesCardImg  = styled.div`
  width: 100%;
  height: 170px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
`

export const StyledUsefulArticlesCardTitle  = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #0D4780;
  width: 100%;
`

export const StyledUsefulArticlesCardText  = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #919191;
  width: 100%;
`