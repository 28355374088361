import React, { useState, useEffect, useCallback, useContext } from 'react';
import Row from '../Row';
import { KeyboardDatePicker } from '@material-ui/pickers';
import withFlc from '../flc/withFlc';
import CalendarIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { changedStyle } from '../../PassportStyle';
import { flcHasValue } from '../../Utils/flcHasValue';
import { ConfigContext, QuestionRender } from '../../PassportProvider';

function DatePicker({
  cellConfig,
  code,
  value,
  onChange,
  disabled,
  lang,
  error,
  inputRestriction,
  changed,
  ...rest
}) {
  const questionRender = useContext(QuestionRender);
  const { fullDatePicker } = useContext(ConfigContext);
  const [restrictionError, setRestrictionError] = useState('');
  const isIncorrect = ['Недопустимый формат даты', 'Қате күн мәні'].includes(error);
  const textFieldComponent = useCallback(({ isIncorrect, inputStyle, ...props }) => {
    return (
      <TextField
        {...props}
        InputProps={{
          style: inputStyle,
          endAdornment: (
            <InputAdornment position="end">
              {(!props.disabled || isIncorrect) &&
                props.value && (
                  <IconButton
                    className="show-on-hover"
                    style={{ margin: 0 }}
                    onClick={() => onChange('')}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              {props.InputProps.endAdornment.props.children}
            </InputAdornment>
          )
        }}
      />
    );
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (value) {
        let validated = disabled || !inputRestriction(moment(value));
        if (validated && restrictionError) {
          setRestrictionError('');
        } else if (!validated && !restrictionError) {
          setRestrictionError(lang === 'ru' ? 'Недопустимая дата' : 'Жарамсыз күн');
        }
      } else {
        setRestrictionError('');
      }
    },
    // eslint-disable-next-line
    [lang, value]
  );

  return (
    <div>
      <KeyboardDatePicker
        isIncorrect={isIncorrect}
        variant="inline"
        disableToolbar={!fullDatePicker}
        format="DD.MM.YYYY"
        inputStyle={flcHasValue(value) && changed ? changedStyle : undefined}
        error={Boolean(error)}
        onError={cError => {
          if (![cError, restrictionError].includes(error)) {
            rest.setError(cError || restrictionError);
          }
        }}
        helperText={error}
        shouldDisableDate={inputRestriction}
        placeholder={!disabled ? rest[`${lang}_placeholder`] : ''}
        autoOk
        inputValue={isIncorrect && disabled && value ? '####' : undefined}
        KeyboardButtonProps={{ style: { marginLeft: 0, marginRight: 3 } }}
        keyboardIcon={<CalendarIcon fontSize="small" />}
        invalidDateMessage={lang === 'ru' ? 'Недопустимый формат даты' : 'Қате күн мәні'}
        minDateMessage={
          lang === 'ru' ? 'Дата не должна быть раньше минимальной даты' : 'Қате күн мәні'
        }
        maxDateMessage={
          lang === 'ru' ? 'Дата не должна быть после максимальной даты' : 'Қате күн мәні'
        }
        onChange={date =>
          onChange(
            date
              ? date
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .format('YYYY-MM-DDTHH:mm:ss')
              : ''
          )
        }
        value={value || null}
        disabled={disabled}
        TextFieldComponent={textFieldComponent}
      />
      {questionRender && questionRender(cellConfig ? { ...cellConfig } : code, value)}
  </div>
  );
}

function DDatePicker(props) {
  return <Row {...props} children={<DatePicker {...props} />} />;
}

export default withFlc(DDatePicker);
