export function isMatrixVisible(matrixVisibility, dataSet) {
  let visible = true;
  for (const matrixItem of matrixVisibility) {
    let founded = true;
    for (const [key, value] of Object.entries(matrixItem)) {
      if (dataSet[key] !== value) {
        founded = false;
      }
    }
    if (founded) {
      visible = false;
    }
  }
  return visible;
}
