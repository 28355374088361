import styled from 'styled-components';

export const StyledCardsContainer = styled.div`
  font-family: Roboto,serif;
  width: 100%;
  height: 100%;
  .MuiFormLabel-root {
    color: rgb(191, 191, 191);
    font-size: 14px;
  }
  .table_actions{
    height: 29px;
    margin: 16px 0px;
  }
  .ReactTable {
    border-top: 1px solid rgba(125,125,125,0.4);
  }
  .table_actions button{
    margin-right: 20px;
  }
  .centerContainer{
    text-align: center;
    margin: auto;
    top: 10%;
  }
  .queue_filter_inputs{
    font-size: 14px;
    text-transform: uppercase;
    width: 290px;
  }
  .mgt5{
    margin-top: 5px;
  }
  .mgt15{
    margin-top: 15px;
  }
  .text{
    margin-top: 10px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  .result{
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    text-transform: initial;
  }
  .table_head_q{
    display: none;
  }
`;
