import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export function handleQuestionLabel(question, lang, isGroup) {
  let source = question.source || {};
  let code = question.hideKeyInLabel ? question.originalCode : question.code;

  if (window.test) {
    if (question.cellConfig) {
      code = question.cellConfig.questionCode;
    } else if (isGroup) {
      code = question.hideKeyInLabel
        ? question.id
        : `${question.metaDataId}.${question.id}`;
    }
  }
  if (question.required || source.loaded || window.test) {
    return (
      <span style={{ width: '100%' }}>
        {question.label || question[`${lang}_name`]}
        {question.required && <span className="bold color-secondary">*</span>}
        {window.test && code && (
          <Tooltip
            title={
              question.stamp
                ? `Обновлен: ${question.stamp.updatedBy} от ${question.stamp.updatedAt}`
                : ''
            }
          >
            <span
              className="bold"
              style={{ fontSize: 12, color: 'rgb(228 0 243)', letterSpacing: '-0.5px' }}
            >
              &nbsp; &nbsp;['{code}']
              {question.lookupId && ` {${question.lookupId}}`}
            </span>
          </Tooltip>
        )}
        {source[`${lang}_name`] && (
          <span className="bold text-success ml1">{source[`${lang}_name`]}</span>
        )}
      </span>
    );
  }
  return question.label || question[`${lang}_name`];
}
