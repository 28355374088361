import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { theme, ThemeWrapper, Color } from './ThemeStyle';

export default function ThemeProvider({ children }) {
  return (
    <MuiThemeProvider
      theme={outerTheme => {
        const newTheme = {
          ...theme,
          palette: {
            ...theme.palette,
            primary: outerTheme.palette.primary,
          },
          shape: { borderRadius: outerTheme.shape.borderRadius }
        };
        Color.updateParams(newTheme);
        return newTheme;
      }}
    >
      <ThemeWrapper>{children}</ThemeWrapper>
    </MuiThemeProvider>
  );
}
