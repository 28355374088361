import PassportForm from '../../../passport/FormData/PassportForm';
import Notice from '../../../modules/utils/Notice';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { changeFormValue } from '../../../passport/Passport';
import { CheckChildInQueue } from './CheckChildInQueue';
import moment from 'moment';

function CheckAgeQuestions({code, value, setDisabled}){
  const [showNotif, setShowNotif] = useState(true);

  useEffect(() => {
    let birth_str = 'request_form_doc_queued_ddo_short.child_birthday';
    let queue_str = 'request_form_doc_queued_ddo_short.queued_type';
    let priv_str = 'request_form_doc_queued_ddo_short.privilege_type';
    let child_iin = PassportForm.values['request_form_doc_queued_ddo_short.child_iin'];
    let data = {};
    data[birth_str] = PassportForm.values[birth_str];
    data[queue_str] = PassportForm.values[queue_str];
    data[priv_str] = PassportForm.values[priv_str];
    data[code] = value.code ?? value;
    if (data[birth_str] && data[queue_str] && data[priv_str] && data[priv_str] !== '005' && data[queue_str] !== 'special') {
      const birthdate = moment(data[birth_str])
      const septemberDate = moment(`${moment().year()}-08-31T00:00:00`);
      //let age = septemberDate.diff(birthdate, 'year');
      let age = moment().diff(birthdate, 'year');
      if (age === 6) {
        if (showNotif) {
          setShowNotif(false);
          Notice.error(i18next.t('serviceRequest_age_error'), () => {
            setShowNotif(true);
          });
        }
        setDisabled(true);
        return;
      }
      if (age > 6) {
        if (showNotif) {
          setShowNotif(false);
          Notice.error(i18next.t('serviceRequest_age_error'), () => {
            setShowNotif(true);
          });
        }
        setDisabled(true);
        return;
      }
    }
    if(data[queue_str] && child_iin && child_iin.length === 12) {
      CheckChildInQueue(child_iin, data[queue_str]).then(result => {
        if(result){
          setDisabled(true);
          changeFormValue(queue_str, null);
        }else{
          setDisabled(false);
        }
      });
      return;
    }
    setDisabled(false);
  },[value]);

  return null;
}
export default CheckAgeQuestions;
