import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  .flex h4{
    margin-right: 5px;
  }
  .do_link{
    text-decoration: underline;
    color: blue;
  }
  .col1{
    width: 50%;
  }
  .col2{
    width: 50%;
  }
  .photo_wrapper{
    width: 100%;
  }
  .do_image{
    width: fill-available;
    width: -webkit-fill-available;
    width: -moz-available;
  }
`;
