import { addTranslation } from '../../../../_helpers/Translate';

addTranslation({
  ru: {
    queue_kindergarten_counter_all: "Всего <br/>в очереди",
    queue_kindergarten_counter_vo: "Внеочередные",
    queue_kindergarten_counter_po: "Первоочередные",
    queue_kindergarten_counter_oo: "Без льгот",
  },
  kk: {
    queue_kindergarten_counter_all: "Кезекте<br/>барлығы",
    queue_kindergarten_counter_vo: "Кезектен тыс",
    queue_kindergarten_counter_po: "Бірінші кезекте",
    queue_kindergarten_counter_oo: "Жеңілдіксіз",
  }
});
