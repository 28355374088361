export function hasPassportData(data, meta) {
  if (data && meta) {
    if (data instanceof Array && meta instanceof Array) {
      let hasData = true;
      for (const dataItem of data) {
        for (const metaItem of meta) {
          if (dataItem && metaItem) {
            if (Object.keys(dataItem).length === 0) {
              hasData = false;
              break;
            }
            if (Object.keys(metaItem).length === 0) {
              hasData = false;
              break;
            }
          } else {
            hasData = false;
            break;
          }
        }
      }
      return hasData;
    } else if (Object.keys(data).length > 0 && Object.keys(meta).length > 0) {
      return true;
    }
  }
  return false;
}
