import React, { useEffect, useContext, useState } from 'react';
import { PassportWrapper, LoadingRender } from './PassportStyle';
import GenerateGroups from './Components/Generate/GenerateGroups';
import withTranslation from './Components/translation/withTranslation';
import Form from './FormData/PassportForm';
import GenerateCards from './Components/Generate/GenerateCards';
import { PassportContext } from './PassportProvider';
import GenerateForms from './Components/Generate/GenerateForms';
import LoadedTrigger from './FormData/LoadedStatusTrigger';
import PassportButtons from './PassportButtons';
import Loading from './Components/Loading/Loading';

const LoadingForm = () => (
  <LoadingRender>
    <div className="sticky-container">
      <Loading />
    </div>
  </LoadingRender>
);

function Passport({
  groupId,
  mode,
  loading,
  disabled,
  renderAfterGroup,
  betweenRender,
  renderCardUrl,
  onLoadGroup,
  buttonRender,
  questionCodes: qc,
  mainUrl,
  lang,
  hideMode,
  afterRender
}) {
  const { groups, questions } = useContext(PassportContext);
  const [questionCodes, setQuestionCodes] = useState([]);

  useEffect(() => (groupId ? Form.clearChangedValues() : undefined), [groupId]);
  useEffect(() => {
    if (mode === 'questions' && qc) {
      setQuestionCodes(qc.map(code => Form.findCode(null, code)));
    }
    return () => {
      Form.clear();
      LoadedTrigger.clear();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (typeof onLoadGroup === 'function') {
        let getList = (groups, list = []) => {
          for (const group of groups) {
            if (group.id === groupId) {
              list.push({
                id: group.id,
                ru_name: group.ru_name,
                kk_name: group.kk_name,
                url: `${mainUrl}/${group.id}`
              });
              return list;
            } else if (group.subGroups.length > 0) {
              let newList = getList(group.subGroups, [
                ...list,
                {
                  id: group.id,
                  ru_name: group.ru_name,
                  kk_name: group.kk_name,
                  url: `${mainUrl}/${group.id}`
                }
              ]);
              if (newList) {
                return newList;
              }
            }
          }
        };
        onLoadGroup(getList(groups) || []);
      }
    },
    // eslint-disable-next-line
    [groupId, groups]
  );

  return (
    <>
      <PassportWrapper loading={loading ? 'true' : ''}>
        {loading && <LoadingForm />}
        {mode === 'cards' && (
          <div className="clearfix">
            <GenerateCards
              groups={groups}
              groupId={groupId}
              questions={questions}
              lang={lang}
              disabled={disabled}
              betweenRender={betweenRender}
              renderCardUrl={renderCardUrl}
            />
            {afterRender}
          </div>
        )}
        {mode === 'questions' && (
          <div className="fullwidth">
            <GenerateForms
              hideMode={hideMode}
              questionCodes={questionCodes}
              questions={questions}
              disabled={disabled}
              lang={lang}

              betweenRender={betweenRender}
            />
          </div>
        )}
        {!mode && (
          <GenerateGroups
            firstLevel
            groups={groups}
            questions={questions}
            disabled={disabled}
            lang={lang}
            renderAfterGroup={renderAfterGroup}
            betweenRender={betweenRender}
          />
        )}
        <PassportButtons
          groupId={groupId}
          mode={mode}
          groups={groups}
          questionCodes={questionCodes}
          children={buttonRender}
        />
      </PassportWrapper>
    </>
  );
}

export default withTranslation(Passport);
