import React, { useEffect, useState, useCallback } from 'react';
import Trigger from '../../FormData/ChangeTrigger';
import Form from '../../FormData/PassportForm';
import { runFlc } from '../../Flc/execute/runFlc';
import { TestMode } from '../../Flc/TestMode';
import { flcHasValue } from '../../Utils/flcHasValue';
import { TableCell } from '@material-ui/core';
import uuid from 'uuid/v4';
import { handleQuestionLabel } from '../../Utils/handleQuestionLabel';

function svComplexCode(savedValue, hasQuestions) {
  if (savedValue) {
    const value = { ...savedValue };
    if (hasQuestions && !savedValue.complexValueId) {
      value.complexValueId = uuid();
    }
    return value;
  } else {
    return savedValue;
  }
}

function withDocumentFlc(Component) {
  /**
   * @return {null}
   */
  function WithDocumentFlc(props) {
    const {
      code,
      savedValue,
      dependency: deps,
      cellsQuestions,
      cellConfig,
      hasQuestions,
      setPageError,
      groupHided
    } = props;
    const [value, setValue] = useState(svComplexCode(savedValue, hasQuestions));
    const [flc, setFlc] = useState({});
    let changed = !value !== !savedValue;
    if (value && savedValue) {
      changed = value['downloadLink'] !== savedValue['downloadLink'];
    }
    const handleFlc = useCallback(
      () => {
        let newFlc = runFlc(props, flc.visibility, value);
        if (JSON.stringify(newFlc) !== JSON.stringify(flc)) {
          setFlc(newFlc);
          if (setPageError) setPageError(!!newFlc.validation);
        }
        if (newFlc.calculation !== undefined) {
          Form.setValue(code, newFlc.calculation, {
            isDocument: true,
            cellsQuestions
          });
          setValue(flcHasValue(newFlc.calculation) ? savedValue : null);
        }
      },
      // eslint-disable-next-line
      [value, flc, cellsQuestions, groupHided]
    );

    useEffect(() => setValue(svComplexCode(savedValue, hasQuestions)), [
      savedValue,
      hasQuestions
    ]);
    useEffect(() => Form.setFlc(code, flc, cellConfig), [code, flc, cellConfig]);
    useEffect(() => Trigger.subscribe(deps, code, handleFlc), [deps, code, handleFlc]);
    useEffect(() => TestMode.register(code, handleFlc), [code, handleFlc]);
    useEffect(
      () => {
        Form.setValue(
          code,
          value ? { ...value } : value,
          cellConfig
            ? cellConfig
            : {
                isDocument: true,
                cellsQuestions
              }
        );
        handleFlc();
      },
      // eslint-disable-next-line
      [code, value, handleFlc, cellsQuestions, cellConfig]
    );

    // скрываем по правилам флк
    if (!flc.visibility || flc.hideMode) {
      if (window.test && !cellConfig) {
        return (
          <div style={{ opacity: 0.5 }}>
            Скрытый показатель - {handleQuestionLabel(props, 'ru')}
          </div>
        );
      }
      return null;
    }

    if (cellConfig && cellConfig.hiddenByPagination) {
      return null;
    }

    if (cellConfig && !cellConfig.isInline) {
      return (
        <TableCell className={props.disabled || flc.readOnly ? 'cell-disabled' : ''}>
          <Component
            {...props}
            value={value}
            onChange={setValue}
            error={flc.validation}
            required={flc.required}
            changed={changed}
            complexCode={value ? value.complexValueId : null}
            disabled={props.disabled || flc.readOnly}
          />
        </TableCell>
      );
    } else {
      return (
        <Component
          {...props}
          value={value}
          onChange={setValue}
          changed={changed}
          error={flc.validation}
          required={flc.required}
          complexCode={value ? value.complexValueId : null}
          disabled={props.disabled || flc.readOnly}
        />
      );
    }
  }
  return WithDocumentFlc;
}

export default withDocumentFlc;
