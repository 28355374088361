import React, { useContext, useEffect, useState } from 'react';
import CheckBoxTrueIcon from '@material-ui/icons/CheckBox';
import CheckBoxFalseIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import { treeFindItems, treeToList } from './DTreeSelectMultiUtils';
import { Menu, Option } from './DTreeSelectMultiStyle';
import Row from '../../Row';
import useDTree from './useDTree';
import withFlc from '../../flc/withDropdownFlc';
import CloseIcon from '@material-ui/icons/Close';
import {
  TextField,
  ClickAwayListener,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { translation } from '../../translation/translation';
import { changedStyle } from '../../../PassportStyle';
import { flcHasValue } from '../../../Utils/flcHasValue';
import { QuestionRender } from '../../../PassportProvider';
import withOptions from '../../flc/withOptions';

const TreeSelect = props => {
  const {
    code,
    value = [],
    error,
    changed,
    lang,
    incorrect,
    disabled,
    lookupId,
    hardDict,
    cellConfig,
    setError
  } = props;

  const {
    tree,
    onExpand,
    onChange,
    anchorEl,
    setAnchorEl,
    loadingArr,
    loadingMenu
  } = useDTree(props);
  const questionRender = useContext(QuestionRender);
  const [searchValue, setSearchValue] = useState();

  let isIncorrect = false;
  let inputValues = [];
  if (value.length > 0) {
    inputValues = treeFindItems(tree, item => value.includes(item.code));
    if (inputValues.length !== value.length) {
      isIncorrect = true;
      setError(translation('flc_incorrectValue'));
      const incorrectItems = treeFindItems(incorrect, item => item.code === value);
      inputValues = [...inputValues, ...incorrectItems];
      if (inputValues.length !== value.length) {
        const incorrectItems2 = value
          .filter(vItem => inputValues.some(item => item.code === vItem))
          .map(vItem => ({
            ru_name: `<${vItem}>`,
            kk_name: `<${vItem}>`,
            code: vItem
          }));
        inputValues = [...inputValues, ...incorrectItems2];
      }
    } else {
      setError('');
    }
  } else {
    setError('');
  }

  useEffect(
    () => {
      if (!anchorEl && !hardDict) {
        setSearchValue();
      }
    },
    // eslint-disable-next-line
    [anchorEl]
  );

  useEffect(() => {
    document
      .getElementById(`tree-select-${code}`)
      .addEventListener('keydown', event => setAnchorEl(event.currentTarget));
    // eslint-disable-next-line
  }, []);

  const inputValueString =
    searchValue !== undefined
      ? searchValue
      : inputValues.map(item => item[`${lang}_name`]).join(', ');

  return (
    <div className="flex">
      <TextField
        fullWidth
        id={`tree-select-${code}`}
        value={inputValueString}
        title={inputValueString}
        onChange={ev => !hardDict && setSearchValue(ev.target.value)}
        disabled={disabled}
        placeholder={!disabled ? props[`${lang}_placeholder`] : undefined}
        data-tree-code={`tree-select-${code}`}
        error={!!error}
        helperText={error}
        onClick={event => {
          if (!disabled) {
            setAnchorEl(event.currentTarget);
          }
        }}
        InputProps={{
          style: flcHasValue(value) && changed ? changedStyle : undefined,
          endAdornment: (
            <InputAdornment position="end">
              {value.length > 0 && (isIncorrect || !disabled) && (
                <IconButton
                  tabIndex="-1"
                  className="show-on-hover"
                  style={{ margin: 0 }}
                  onClick={() => props.onChange([])}
                  children={<CloseIcon fontSize="small" />}
                />
              )}
              <IconButton
                tabIndex="-1"
                disabled={disabled}
                data-tree-code={code}
                style={{ padding: 2 }}
              >
                <DropDownIcon
                  color={disabled ? 'disabled' : 'action'}
                  data-tree-code={code}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <ClickAwayListener
        onClickAway={event => {
          if (
            !(
              event.target.getAttribute('data-tree-code') === code ||
              event.target.id === `tree-select-${code}`
            )
          ) {
            setAnchorEl(null);
          }
        }}
      >
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 1300 }}
        >
          {loadingMenu && (
            <div className="center">
              <CircularProgress />
            </div>
          )}
          {!loadingMenu &&
            treeToList(tree, searchValue, lang).map(item => {
              const loading = loadingArr.includes(item.code);
              const hasChild =
                (lookupId && !item.childItems) ||
                (item.childItems && item.childItems.length);
              return (
                <Option
                  key={item.code}
                  selected={value.includes(item.code)}
                  id={`ts_anchor_${item.code}`}
                  style={{ marginLeft: 30 * item.level }}
                >
                  <div className="ts-expander" onClick={() => !loading && onExpand(item)}>
                    {hasChild ? (
                      <IconButton style={{ padding: '7px' }}>
                        {loading && <CircularProgress size={18} />}
                        {!loading && item.expanded && <DownIcon fontSize="small" />}
                        {!loading && !item.expanded && <RightIcon fontSize="small" />}
                      </IconButton>
                    ) : (
                      <IconButton
                        style={{ padding: '7px' }}
                        onClick={() => onChange(item)}
                      >
                        {value.includes(item.code) ? (
                          <CheckBoxTrueIcon fontSize="small" />
                        ) : (
                          <CheckBoxFalseIcon fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </div>
                  <div className="ts-item-label" onClick={() => onChange(item)}>
                    <span>{item[`${props.lang}_name`]}</span>
                  </div>
                </Option>
              );
            })}
        </Menu>
      </ClickAwayListener>
      {questionRender &&
        questionRender(cellConfig ? { ...cellConfig } : code, inputValues)}
    </div>
  );
};

const DTreeSelectMulti = props => {
  const parentSelectable = props.anyTreeLevelSelectable || false;
  return (
    <Row
      {...props}
      children={<TreeSelect {...props} parentSelectable={parentSelectable} />}
    />
  );
};

DTreeSelectMulti.defaultProps = {
  parentSelectable: true
};

export default withOptions(withFlc(DTreeSelectMulti));
