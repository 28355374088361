import styled from 'styled-components';

export const StyledCounters = styled.div`
  font-family: Roboto,serif;
  width: 100%;
  min-width: 800px;
  text-transform: uppercase;
  .MuiTableCell-root {
    padding: 0px;
    border: none;
    width: 50%;
    min-height: fit-content;
  }
  .MuiTableRow-root{
    height: fit-content;
  }
  .center_obj{
    text-align: left;
    margin: auto 0px;
    height: 100%;
  }
  .content{
    padding: 5px 5px 5px 20px;
  }
  .total_counter{
    display: inline-block;
    padding-left: 10px;
    margin: auto 0px;
  }
  .search_box{
    width: 100%;
    box-shadow: 0px 1px 5px 5px rgb(119 111 196 / 25%);
  }
  .search_box input{
    color: black;
  }
`;
