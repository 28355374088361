import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '../../../_ui/Modal/Modal';
import Select from '../../../modules/Select/Select';
import Button from '../../../modules/Button/Button';
import Notice from '../../../modules/utils/Notice';
import { onSave } from '../ServiceRequestDucks';
import { ECP_OPTIONS, SIGN_METHOD_PKCS12 } from '../../../_helpers/Constants';

function SignModal({ data, close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [method, setMethod] = useState(SIGN_METHOD_PKCS12);

  return (
    <Modal
      open={true}
      onClose={close}
    >
      <div className="center">
        <Select
          withoutForm
          placeholder="Выберите источник хранения эцп ключа"
          value={method}
          options={ECP_OPTIONS}
          onChange={e => setMethod(e.target.value)}
        />
        <div className="my2"/>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          text={t('sign')}
          onClick={() => {
            if (!method) {
              return Notice.warning('Необходимо выбрать источник хранения эцп ключа');
            }
            dispatch(onSave(data, method));
            close();
          }}
        />
      </div>
    </Modal>
  );
}

export default SignModal;