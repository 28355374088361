export default {
  // theme colors
  primary: '#F84D39',
  secondary: '#1976D2',
  background: '#FFFFFF',
  border: '#CECFD2',
  action: '#9C9C9C',
  textPrimary: '#000000',
  textSecondary: '#717171',
  textDisabled: '#9C9C9C',
  textSuccess: '#559754',
  textNote: '#9C9C9C',
  disabled: '#E0DCFF',

  radioNotActive: '#E0E0E0',
  radioActive: '#0DBF6A',

  link: '#1185E0',
  linkHovered: '#F87A06',

  inputDisabledBackground: 'rgba(125,125,125,0.1)',

  cardBackground: '#FFFFFF',

  smallButtonBackground: '#0DBF6A',
  smallButtonHoverBackground: '#129E5B',

  iconInfo: '#9C9C9C',
  iconAction: '#7264D6',

  tableHeader: '#F6EFFF',

  textAreaBorder: '#EFE2FF',

  // named colors
  violet: '#7264D6',
  darkViolet: '#6552C7',
  lightRed: '#F84D39',
  orange: '#F87A06',
  lightPrimary: '#F2FBFF',
  lightGrey: '#9C9C9C',
  lighterGrey: '#F9F9F9'
};
