import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledGBDFLButton } from '../ServiceRequestStyles';

function SPModalButton({ SPModal, code }) {
  const { t } = useTranslation();

  return (
    <div>
      <StyledGBDFLButton
        color="secondary"
        text={t('serviceRequest_chooseServiceProvider')}
        onClick={() => SPModal.open({ code })}
      />
    </div>
  );
}

export default SPModalButton;