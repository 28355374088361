import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Row from '../Row';
import withFlc from '../flc/withFlc';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { changedStyle } from '../../PassportStyle';
import { flcHasValue } from '../../Utils/flcHasValue';

function DCheckBox(props) {
  const { value, onChange, changed, lang, disabled, withoutLabel, ...rest } = props;
  const style = flcHasValue(value) && changed ? changedStyle : undefined;
  return (
    <Row {...props}>
      <FormControl
        error={!!rest.error}
        style={withoutLabel ? { paddingLeft: 14 } : undefined}
      >
        <FormControlLabel
          label={<span style={style}>{rest[`${lang}_name`]}</span>}
          control={
            <Checkbox
              disabled={disabled}
              color="primary"
              size="small"
              checked={Boolean(value)}
              onChange={(event, value) => onChange(value)}
            />
          }
        />
        {rest.error && <FormHelperText>{rest.error}</FormHelperText>}
      </FormControl>
    </Row>
  );
}

export default withFlc(DCheckBox);
