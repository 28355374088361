import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    page404_oops: 'Упс, Страница не найдена!',
    page404_notFound:
      'Возможно вы ввели не правильный адрес, \n или страница была удалена.',
    page404_toHome: 'на главную'
  },
  kk: {
    page404_oops: 'Упс, Бет табылмады!',
    page404_notFound:
      'Сіз мекен-жайды дұрыс енгізбеген сияқтысыз, <br / > немесе бет жойылған.',
    page404_toHome: 'басты бетке'
  }
});
