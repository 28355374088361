import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0 !important;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none !important;
`;