import styled from 'styled-components';
import { Color } from './Components/ThemeProvider/ThemeStyle';
import green from '@material-ui/core/colors/green';

export const changedStyle = { color: green[600], fontWeight: 600 };

export const PassportWrapper = styled.div`
  min-width: 500px;
  min-height: ${props => props.minHeight || 'inherit'};
  position: relative;
  opacity: ${props => (props.loading ? '0.5' : '1')};
  pointer-events: ${props => (props.loading ? 'none' : 'inherit')};
  
  textarea:focus {
    border: none;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1999;
`;

export const LoadingRender = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  background: #ffffff78;
  z-index: 2;
  padding-top: 150px;

  .sticky-container {
    position: sticky;
    top: 50vh;
    background: red;
    z-index: 1999;
  }
`;

export const ExpanderButtons = styled.div`
  height: 37px;
  line-height: 37px;
  text-align: right;
`;

export const Groups = styled.div`
  margin-top: 16px;
  transition: background-color 0.2s linear;
  display: ${props => (props.hide && !window.test ?  'none' : 'inherit')};
  opacity: ${props => (props.hide && window.test ?  0.5 : 1)};
  :first-child {
    margin-top: 0;
  }
  .anchor {
    transform: translateY(-${props => (props.top ? props.top + 62 : 62)}px);
  }
`;

export const Navigation = styled.div`
  width: 350px;
  position: sticky;
  color: ${() => Color.isDark ? Color.secondary : 'inherit'};
  top: ${props => (props.top ? props.top + 15 : 15)}px;
  max-height: calc(100vh - 30px);
  overflow-y: auto;
  a:hover {
    text-decoration: underline;
  }
`;

export const ErrorMessage = styled.div`
  border: 1px solid ${() => Color.border};
  border-radius: ${() => Color.borderRadius};
  color: ${() => Color.secondary};
  padding: 16px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
`;
