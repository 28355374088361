import styled from 'styled-components';

export const StyledTable = styled.div`
  cursor: pointer;
  .cont1,
  .cont2 {
    display: inline-block;
    height: 100%;
  }
  .cont1 {
    vertical-align: top;
    width: 30%;
    height: 155px;
  }
  .cont2 {
    width: 70%;
    height: 100%;
    min-height: fit-content;
    position: relative;
  }
  .do_image {
    width: fill-available;
    width: -webkit-fill-available;
    width: -moz-available;
    height: 100%;
  }
  .row1 {
    height: 100%;
    min-height: fit-content;
    width: 100%;
  }
  .row2 {
    position: absolute;
    left: 30%;
    bottom: 0;
    height: 31px;
    padding: 5px;
    background-color: green;
    width: 65%;
    margin-left: 15px;
    margin-bottom: 5px;
  }
  .counts {
    color: white;
    display: inline-block;
    width: 50%;
  }
  .col1 {
    display: inline-block;
    padding-bottom: 36px;
    margin-left: 15px;
    width: 75%;
    white-space: pre-wrap;
  }
  .col2 {
    vertical-align: top;
    display: inline-block;
    width: 25%;
  }
`;

export const StyledBlockImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #008000;
  border-radius: 5px;
  height: 100%;
    &:hover {
      color: #000000;
    }
    &:focus {
      color: #000000;
    }
    &:active {
      color: #000000;
    }
    &:visited {
      color: #000000;
    }
    &:link {
      color: #000000;
    }
`;