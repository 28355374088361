import register_kindergartens from '../../_assets/images/register_kindergartens.png'
import documents_enrollment from '../../_assets/images/documents_enrollment.png'
import bulletin_vacancies from '../../_assets/images/bulletin_vacancies.png'
import queuing from '../../_assets/images/queuing.png'

export const kindergarten_services_items = {
    kindergarten_reestr: {
      url: 'kindergarten-reestr',
      ru_name: 'Реестр детских садов',
      kk_name: 'Балабақшалар тізімі',
      ru_text: 'Перечень дошкольных организаций с подробным описанием и количеством свободных мест',
      kk_text: 'Мектепке дейінгі ұйымдардың тізімі және олардың сипаттамасы',
      img: register_kindergartens,
      ru_text_btn: 'Перейти в реестр',
      kk_text_btn: 'Перейти в реестр',
    },
    application_queue: {
      url: 'queue-applications',
      ru_name: 'Очередь заявлений',
      kk_name: 'Өтініштер кезегі',
      ru_text: 'Список детей, ожидающих направление в дошкольные образовательные учреждения, с уточнением количества и льгот',
      kk_text: 'Мектепке дейінгі білім беру ұйымдарына жолдама алу үшін кезекке тұрған балалардың тізімі',
      img: documents_enrollment,
      ru_text_btn: 'Проверить номер',
      kk_text_btn: 'Проверить номер',
    },
    bulletin_open_place: {
      url: 'bulletin-open-places',
      ru_name: 'Бюллетень свободных мест',
      kk_name: 'Бос орындар бюллетені',
      ru_text: 'Выбор свободного места и получение электронного направления для зачисления в детский сад',
      kk_text: 'Босаған орынды таңдау және балабақшаға қабылдану үшін электронды жолдама алу',
      img: bulletin_vacancies,
      ru_text_btn: 'Подробнее',
      kk_text_btn: 'Подробнее',
    },
    doc_acceptance_ddo_short: {
      code: 'doc_acceptance_ddo_short',
      ru_name: 'Зачисление',
      kk_name: 'Тіркеу',
      ru_text: 'Прием документов в электронном виде и зачисление детей в дошкольные учреждения',
      kk_text: 'Мектепке дейінгі білім беру ұйымдарына құжаттарды қабылдау және балаларды тіркеу',
      img: queuing,
      ru_text_btn: 'Подробнее',
      kk_text_btn: 'Подробнее',
    },
}

export const kindergarten_services_insert = {
  doc_queued_ddo_short: {
    code: 'doc_queued_ddo_short',
    ru_name: 'Постановка на очередь детей дошкольного возраста (до 6 лет) для направления в детские дошкольные организации',
    kk_name: 'Мектепке дейінгі балалар ұйымдарына жіберу үшін мектепке дейінгі (6 жасқа дейін) жастағы балаларды кезекке қою',
    ru_text: 'Встать в очередь',
    kk_text: 'Кезекке тұру'
  },
  check_queue_number: {
    url: 'check-queue-number',
    ru_name: 'ПРОВЕРКА НОМЕРА В ОЧЕРЕДИ',
    kk_name: 'КЕЗЕКТЕГІ НӨМІРДІ ТЕКСЕРУ',
    ru_text: 'Проверить очередь',
    kk_text: 'Кезектегі нөмірді тексеру'
  },
}