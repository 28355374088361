import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { kindergartenCountersModule } from './CountersDucks';
import Grid from '@material-ui/core/Grid';
import { StyledCounters } from './CountersStyle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import './CountersTranslate';
import Paper from '@material-ui/core/Paper';

function Filters() {
  const { t } = useTranslation();
  const total = useSelector((state) => state[kindergartenCountersModule].total);
  const totalPrivate = useSelector((state) => state[kindergartenCountersModule].totalPrivate);
  const totalState = useSelector((state) => state[kindergartenCountersModule].totalState);

  return (
    <StyledCounters>
      <Paper style={{height:'fit-content'}} className="search_box">
          <Grid container spacing={0} className="content">
            <Grid container item xs={12} md={3} spacing={0} className="center_obj">
              <div className="total_counter" dangerouslySetInnerHTML={{ __html: t('kindergarten_counter') }}>
              </div>
              <div className="total_counter">
                {total}
              </div>
            </Grid>
            <Grid container item xs={12} md={6} spacing={0} className="center_obj">
                <Table><TableBody>
                  <TableRow>
                    <TableCell style={{width:"fit-content"}}>
                      {t('kindergarten_counter_state')}
                    </TableCell>
                    <TableCell>
                      {totalState}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{width:"fit-content"}}>
                      {t('kindergarten_counter_private')}
                    </TableCell>
                    <TableCell>
                      {totalPrivate}
                    </TableCell>
                  </TableRow>
                </TableBody></Table>
            </Grid>
          </Grid>
      </Paper>
    </StyledCounters>
  );
}

export default Filters;
