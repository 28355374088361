import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    outputForm: 'Выходная форма.pdf',
    downloadOutputForm_failed: 'Не удалось скачать выходную форму',
    completedRequests_title: 'Завершенные заявки',
    completedRequests_outputForm: 'Выходная форма',
    completedRequests_download: 'Скачать',
    completedRequests_downloadInfo: 'Чтобы скачать на нужном вам языке смените язык сайта'
  },
  kk: {
    completedRequests_title: 'Аяқталған өтінімдер',
    downloadOutputForm_failed: 'Не удалось скачать выходную форму',
    completedRequests_outputForm: 'Шығу нысаны',
    completedRequests_download: 'Скачать',
    completedRequests_downloadInfo: 'Чтобы скачать на нужном вам языке смените язык сайта',
    outputForm: 'Выходная форма.pdf'
  }
});