import flc from './flc_kk';

export const KK_TRANSLATION = {
  lang: 'kk',
  messages: {
    ...flc,
    'header.changeLangBtn': 'КАЗ',
    'header.logout': 'Шығу',
    'header.changePass': 'Құпия сөзді ауыстыру',
    'header.userManagement': 'Пайдаланушыларды басқару',
    'header.notificationManagement': 'Хабарландыруларды басқару',
    'header.editProfile': 'Профильді өңдеу',
    'header.errorOccurred': 'Қате кетті',
    'header.errorFirstPart':
      'NCALayer бағдарламасына қосылу кезіндегі қате. Бағдарлама іске қосылғанына көз жеткізіңіз немесе келесі бойынша өтіңіз',
    'header.errorSecondPart': ' сілтеме ',
    'header.errorThirdPart': 'NCALayer бағдарламасын орнату үшін',
    'header.help': 'Көмек',
    'header.support': 'Қолдау қызметі',
    'header.FAQ': 'Жиі қойылатын сұрақтар',
    'header.manual': 'Пайдаланушы нұсқаулығы',
    'header.instructions': 'Толтыру бойынша нұсқаулықтар',
    'header.infoSecurity': 'Ақпараттық қауіпсіздік',
    'header.nca.connect.success': 'NCALayer бағдарламасына сәтті қосылу',

    'manual.admin': 'Әкімші нұсқаулығы',
    'manual.PO':
      'Мектепке дейінгі білім беру ұйымдарына" арналған пайдаланушы нұсқаулығы',
    'manual.orphan':
      '"Жетім балалар мен ата-анасының қамқорлығынсыз қалған балаларға арналған білім беру ұйымдарының" пайдаланушы нұсқаулығы',
    'manual.OSE': '"Орта білім беру ұйымдарына" арналған пайдаланушы нұсқаулығы',
    'manual.special': '"Арнайы білім беру ұйымдарына" арналған пайдаланушы нұсқаулығы',
    'manual.OTVE':
      '"Техникалық және кәсіптік білім беру ұйымдарына" арналған пайдаланушы нұсқаулығы',
    'manual.mio_employees': 'ЖАО қызметкерлеріне арналған пайдаланушы нұсқаулығы',
    'manual.mio_directors': 'ЖАО басшысына арналған пайдаланушы нұсқаулығы',
    'manual.ministry_employees':
      'Қазақстан Республикасы Білім және ғылым министрлігінің қызметкерлеріне арналған пайдаланушы нұсқаулығы',
    'manual.dkson_employees': 'БСБД қызметкерлеріне арналған пайдаланушы нұсқаулығы',
    'manual.dkson_directors': 'БСБД басшысына арналған пайдаланушы нұсқаулығы',
    'manual.kkson_employees': 'БҒСБК қызметкерлеріне арналған пайдаланушы нұсқаулығы',
    'manual.vseobuch': '"Жалпы оқыту модулі бойынша" пайдаланушы нұсқаулығы',
    'manual.OAE': '"Қосымша білім беру ұйымдарына" арналған пайдаланушы нұсқаулығы',
    'manual.specialized':
      '"Мамандандырылған білім беру ұйымдарына" арналған пайдаланушы нұсқаулығы',
    'manual.trajectory':
      '"Оқушы қозғалысының траекториясы модулі" бойынша пайдаланушы нұсқаулығы',
    'manual.microterritory': '"Микроучаскелер модулі" бойынша пайдаланушы нұсқаулығы',
    'manual.createSchool': '"Білім беру ұйымын тіркеу" бойынша пайдаланушы нұсқаулығы',
    'manual.MIO_MON':
      '"Қазақстан Республикасы аудандық және қалалық білім бөлімінің қызметкерлеріне" арналған пайдаланушы нұсқаулығы',

    'instruction.OOType.01':
      '«Мектепке дейінгі білім беру ұйымдарына» арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.OOType.02':
      '«Орта білім беру ұйымдарына» арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.OOType.03':
      '"Техникалық және кәсіптік білім беру ұйымдарына" арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.OOType.06':
      '«Жоғары және (немесе) жоғары оқу орнынан кейінгі білім беру ұйымдарына» арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.OOType.07':
      '«Мамандандырылған білім беру ұйымдарына» арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.OOType.08':
      '«Арнайы білім беру ұйымдарына» арналған ҰБДҚ-ын толтыру бойынша нұсқаулық"',
    'instruction.OOType.09':
      '"Жетім балаларға және ата-анасының қамқорлығынсыз қалған балаларға арналған білім беру ұйымдарының" ҰБДҚ-ын толтыру жөніндегі нұсқаулық',
    'instruction.OOType.10':
      '"Балаларға арналған қосымша білім беру ұйымдарының"  ҰБДҚ-ын толтыру жөніндегі нұсқаулық',

    'instruction.mio_employees':
      'ЖАО қызметкерлеріне арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.mio_directors':
      'ЖАО басшысына арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.ministry_employees':
      'ҚР БҒМ қызметкерлеріне арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.dkson_employees':
      'БСБД қызметкерлеріне арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.dkson_directors':
      'БСБД басшысына арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',
    'instruction.kkson_employees':
      'БҒСБК қызметкерлеріне арналған ҰБДҚ-ын толтыру бойынша нұсқаулық',

    'footer.text': '© ҚР Білім және ғылым Министрлігі, 2019',

    'validation.error.number_of_pupils':
      '«Жетім балалар мен ата-анасының қамқорлығынсыз қалған балалар ұлты бойынша» және «жетім балалар мен ата-анасының қамқорлығынсыз қалған балалар» жолдары бірдей болу қажет',
    'validation.error.incorrect': 'Қате мән',
    'validation.error.undefined': 'Толтыру міндетті',
    'validation.error.required': 'Толтыру міндетті',
    'validation.error.test_validation_code': 'Ақпаратты тестілік тексеру',
    'validation.error.email_validation':
      'Электрондық пошта мекенжайының форматы дұрыс емес',
    'validation.error.another_test_validation_code':
      'Электрондық пошта мекенжайының форматы дұрыс емес',
    'validation.error.interactive_tools_validation_code': 'Нөлге тең болуы мүмкін емес',
    'validation.error.zero_validation_code':
      'Декретік демалыстағы қызметкер үшін жүктеме нөлге тең болуы тиіс',
    'validation.error.personalCompareDates':
      'Еңбек шартын бұзу/тоқтату күні жұмысқа қабылдау күнінен кем болмауы тиіс',
    'validation.error.personalCheckemploymentDate':
      'Жұмысқа қабылдау күні міндетті түрде толтырылуы тиіс',
    'validation.error.group_room_area':
      'Топтық бөлмелердің ауданы ғимараттың жалпы ауданынан аз болуы тиіс',
    'validation.error.up_limit': '2500 артық болмауы керек',
    'validation.error.area_not_null':
      'Спорт залы болған жағдайда ауданы 0 тең болмауы қажет',
    'validation.error.less_five_numbers': 'Сан 5 цифрдан аспауы тиіс',
    'validation.error.less_count_book':
      'Кітаптар жиынтығының санынан аз немесе тең болуы тиіс',
    'validation.error.less_count_mug': 'Үйірмелер санынан аз немесе тең болуы тиіс',
    'validation.error.office_quantaty_nomore_quantaty_withequipment':
      'Замануи оқыту құрылғыларымен жабдықталған кабинеттердің саны (соңғы 10 жылға) кабинеттердің жалпы санынан артық болмауы тиіс',
    'validation.error.not_more_people': '20 адамнан аспауы керек',
    'validation.error.total_design_capacity':
      'Жиынтық жобалық қуаты 2500-ден артық бола алмайды',
    'validation.error.groups_must_coincide':
      'Топтың атауы «Топтар» көрсеткішінде енгізілген атаулардың бірімен сәйкес келуі қажет',
    'validation.error.not_null': 'нөлге тең бола алмайды',
    'validation.error.no_more_total': '"Барлығы" мәнінен аспауы тиіс',
    'validation.error.no_more_used_instudy':
      '«Оқу/тәрбие үдерісінде қолданылатын» мәнінен аспауы тиіс',
    'validation.error.no_more_for__teachers':
      '"Оқытушыларға/мұғалімдерге/тәрбиешілерге арналған" мәнінен аспауы тиіс',
    'validation.error.can_not_exceed':
      '«Барлық келушілер, адам» кестесінің осы жолындағы мәнінен аспауы тиіс',
    'validation.error.no_more_total_equipment':
      '"орнатылған жабдық бірліктерінің саны" мәнінен аспауы тиіс',
    'validation.error.no_more_total_facilities':
      '"Бөлінген қаражат сомасы" мәнінен аспауы тиіс',
    'validation.error.no_more_3number':
      'Орташа жалақы мөлшері 999 мың теңгеден аспауы тиіс',
    'validation.error.sum_lines_equal':
      '«Барлық оқушылар» жолы бойынша мәндердің қосындысы «Білім беру үдерісі туралы негізгі мәліметтер» бөліміндегі «Мектеп жасындағы оқушылар саны (6-18 жас), адам» жолындағы мәнге тең болуы тиіс',
    'validation.error.no_more_total_countries':
      '"Елдің шегінен тыс кетті" мәнінен аспауы тиіс',
    'validation.error.groups_must_coincide_property':
      'Топтың атауы "Меншік түріне қарай барлық топтар туралы мәліметтер" көрсеткішінде енгізілген атаулардың бірімен сәйкес келуі тиіс',
    'validation.error.can_not_exceed_diseases':
      '"Балалардың сырқаттарының саны, адам" кестесінің осы жолындағы мәнінен аспауы тиіс',
    'validation.error.wrongCoordinates': 'Координаттары қате, картада орын белгілеңіз!',
    'validation.error.no_more_2_number': '99 санынан аспауы тиіс',
    'validation.error.no_more_3_number': '999 санынан аспауы тиіс',
    'validation.error.no_more_exploitation':
      '"Ғимаратттың пайдалануға берілген жылынан" артық болмауы қажет',
    'validation.error.no_more_contract':
      '««Келісім шарт бойынша интернеттің жылдамдығынан» артық болмауы тиіс',
    'validation.error.no_more_total_area': '«Ғимараттың жалпы ауданы» артық болмауы тиіс',
    'validation.error.no_more_work_area':
      '«Ғимараттың жалпы жұмыс ауданынан» артық болмауы тиіс',
    'validation.error.no_more_current_computers':
      'Қосындысы ағымдағы жылға арналған компьютерлер санынан аспауы тиіс',
    'validation.error.no_more_total_cabinets': 'Кабинеттер санынан артық болмауы тиіс',
    'validation.error.more_null': 'Нөлден артық болуы тиіс',
    'validation.error.no_more_ling_cabinets':
      'Лингафондық кабинеттер санынан аспауы тиіс',
    'validation.error.null_total_ling_cabinets':
      'Лингафондық кабинеттер саны секілді нөлге тең болуы тиіс',
    'validation.error.null_total_inf_cabinets':
      'Информатика кабинеттерінің саны секілді нөлге тең болу керек',
    'validation.error.up_limit_do': '1000 артық болмауы тиіс',
    'validation.error.null_cabinets_fizics':
      'Физикадан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
    'validation.error.no_more_cabinets_fizics':
      'Физикадан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
    'validation.error.null_cabinets_chemistry':
      'Химиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
    'validation.error.no_more_cabinets_chemistry':
      'Химиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
    'validation.error.null_cabinets_bilogy':
      'Биологиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
    'validation.error.no_more_cabinets_biology':
      'Биологиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
    'validation.error.null_cabinets_geography':
      'Географиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
    'validation.error.no_more_cabinets_geography':
      'Географиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
    'validation.error.null_cabinets_ling':
      'Лингафондық-мультимедиялық кабинеттер "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
    'validation.error.no_more_cabinets_ling':
      'Лингафондық-мультимедиялық кабинеттер"Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
    'validation.error.equal_zero': 'Нөлге тең болуы тиіс',
    'validation.error.less_element':
      'Аудиторлық және аудиториядан тыс қордың сомасы ғимараттың жалпы ауданынан аспауы тиіс',
    'validation.error.no_more_5number': '100 000 кем болуы тиіс',
    'validation.error.more_yard_clubs': '"аула клубтарының саны" көп болуы керек',
    'validation.error.personnelInvalidBirthday': 'Жас бойынша шектеу, жас ауқымы',
    'validation.error.one_hot_or_buffet':
      'Бір уақытта ыстық және буфеттік тамақпен қамтыла алмайды',
    'validation.error.no_hotmeal':
      '"Ақысыз ыстық тамақпен қамтылды" белгілеу үшін "Ыстық тамақпен қамтылды" белгілеу қажет',
    'validation.error.no_buffet':
      '"Ақысыз буфеттік тамақпен қамтылды" белгілеу үшін "Буфеттік тамақпен қамтылды" белгілеу қажет',

    'error_window_number_tab':'5 беттен артық ашуға рұқсат етілмейді',

    'validation.error.equal_rebuildings':
      'Ғимараттың техникалық жағдайы «күрделі жөндеуді қажет етеді немесе жүргізілді» деп белгіленген ғимараттың санына тең болуы тиіс',
    'validation.error.equal_current_repairs':
      'Ғимараттың техникалық жағдайы «ағымдағы жөндеуді қажет етеді немесе жүргізілді» деп белгіленген ғимараттың санына тең болуы тиіс',
    'validation.error.cost_more_null': 'Құны нөлден көп болуы тиіс',
    'validation.error.need_boording':
      'Оқушы бір мезгілде "мектеп жанындағы интернатта тұруы" және "мектеп жанындағы интернатқа орналастыруды қажет етуі" мүмкін емес',
    'validation.error.equal_sum_lingcabinets':
      'Ұяшықтардың қосындысы "Кабинеттердің бар болуы, бірлік" көрсеткішінің "лингафондық кабинетер" элементіне тең болуы қажет',
    'validation.error.school_children_age':
      '«Мектеп жасындағы оқушылар саны (6-18 жас)» элементіне тең немесе кем болуы қажет',
    'validation.error.not_more_total_work_experience':
      'Педагогикалық еңбек өтілі «Жалпы еңбек өтілінен» көп бола алмайды',
    'validation.error.no_more_16_year':
      'Педагогикалық еңбек өтілі 16 жастан рұқсат етіледі',
    'validation.error.sum_lines_equal_two':
      'Осы жолдың мәндерінің қосындысы «Білім беру үдерісі туралы негізгі мәліметтер» бөліміндегі «Мектепке дейінгі жастағы балалар саны (1-6 жас)» мәніне тең болуы тиіс',
    'validation.error.integer_5number': '5 цифрдан аспайтын бүтін оң сан болуы қажет',
    'validation.error.school_children_age_two':
      '«Мектепке дейінгі жастағы балалар саны (1-6 жас)» элементіне тең немесе кем болуы қажет',
    'validation.error.must_main': 'кем дегенде бір «Басты ғимарат» болуы керек',
    'validation.error.following_format':
      'Телефон нөмірі таңбасыз 11 санға сәйкес келуі керек',
    'validation.error.scholarships_available':
      'стипендия болған жағдайда «Стипендия мөлшері және төлем жүргізілуі туралы ақпарат, теңге» көрсеткішінде кем дегенде 1 ұяшық 0-ден артық болуы тиіс',
    'validation.error.should_not_exceed':
      '«Аталған мекемедегі жалпы еңбек өтілі» көрсеткішінен артық болмауы керек',
    'validation.error.property_group_data':
      '«Топтар» және «Меншік түріне қарай барлық топтар туралы мәліметтер» кестелерінің өрістерінің саны тең болуы қажет',
    'validation.error.girls_boys_total_not_exceed':
      '«Оның ішінде қыздар» және «Оның ішінде ұлдар» жолдарының қосындысы «Барлығынан» аспауы тиіс',
    'validation.error.the_total_must_match':
      '«Балалардың жалпы саны» көрсеткіші «Бұзылыс (сырқат) түрлері бойынша балалардың жалпы саны» мен «Ондағы жасы бойынша» көрсеткіштеріне сай келуі тиіс',
    'validation.error.need_for_staff':
      '«оның ішінде әйелдер» «кадрлар қажеттілігі» аспауы керек',
    'validation.error.language_match_groups':
      '«Оқыту тілі» көрсеткішіндегі таңдалған тілдер «Топтар» көрсеткішімен сәйкес келуі тиіс',
    'validation.error.should_once_main': 'Тек бір ғана негізгі ғимарат болуы мүмкін',
    'validation.error.cant_anymore_quantity':
      '«Саны, дана» көрсеткішінен артық болмауы керек',

    'loginForm.title': 'Авторизация',
    'loginForm.login': 'Кіру',
    'loginForm.iin': 'Пайдаланушының аты',
    'loginForm.password': 'Құпия сөз',
    'loginForm.changePassword': 'Құпия сөзді өзгерту',
    'loginForm.register': 'Тіркелу',
    'loginForm.recoverPassword': 'Құпия сөзді ұмыттыңыз ба?',
    'loginForm.loginTip': 'Кіру мәліметтерін ешкімге хабарламаңыз',
    'loginForm.formValidation.required': 'Бұл өрісті толтыру міндетті',
    'loginForm.formValidation.wrongIin': 'ЖСН форматы дұрыс емес',
    'loginForm.error.authorization': 'Кіру мүмкін болмады',
    'loginForm.error.validECP':
      'ЭСҚ иесінің ЖСН-ні қолданушының ЖСН-ніне сәйкес келмейді',
    'loginForm.error.passwordCanNotBeUsed': 'Бұл құпиясөзді қолдану мүмкін емес',
    'loginForm.error.block':
      'Есептік жазба/құпиясөз 3 рет сәтсіз еңгізілген үшін пайдаланушы тіркелгісі бұғатталды.',
    'loginForm.formValidation.wrongPassword':
      'Міндетті түрде әріптерді (a-z и A-Z), кем дегенде 8 сан және символ (мысалы: "#", "!" немесе "&") қолдану қажет',
    'loginForm.warning.passwordPolicy':
      'Құпиясөздің бітуіне {{xDay}} күн қалды. \nҚұпиясөзді сілтеме бойынша өзгертуге болады : ',
    'loginForm.warning.passwordPolicy0Day':
      'Құпия сөздің мерзімі бүгін аяқталады. \nҚұпиясөзді сілтеме бойынша өзгертуге болады : ',
    'loginForm.warning.infoSafety': 'Жауапкершілік туралы хабарлама\n' +
      'Мен, “Ұлттық білім беру деректер қорының” пайдаланушысы жүйеде жұмысымды жалғастыра отырып, “Дербес деректер және оларды қорғау туралы” Қазақстан Республикасының Заңына сәйкес жасалған барлық әрекеттер үшін жауапкершілік туралы келісімді растаймын.',

    'changePasswordForm.title': 'Құпия сөзді өзгерту',
    'changePasswordForm.changePassword': 'Құпия сөзді өзгерту',
    'changePasswordForm.currentPassword': 'Ағымдағы кұпия сөз',
    'changePasswordForm.newPassword': 'Жаңа құпия сөз',
    'changePasswordForm.confirmationPassword': 'Құпия сөзді қайталаңыз',
    'changePasswordForm.formValidation.required': 'Бұл өрісті толтыру міндетті',
    'changePasswordForm.formValidation.wrongPasswordLength':
      'Құпия сөз 8 таңбадан кем болмауы тиіс',
    'changePasswordForm.formValidation.wrongPasswordDigits':
      'Құпия сөз сандарды қамтуы тиіс',
    'changePasswordForm.formValidation.wrongPasswordUppercase':
      'Құпия сөз жоғарғы регистрдің латын әріптерін (A-Z) қамтуы тиіс',
    'changePasswordForm.formValidation.wrongPasswordLowercase':
      'Құпия сөз төменгі регистрдің латын әріптерін (a-z) қамтуы тиіс',
    'changePasswordForm.formValidation.wrongPasswordNotCyrillic':
      'Құпия сөз кириллицаны қамтымауы керек',
    'changePasswordForm.formValidation.wrongPasswordSymbols':
      "Құпия сөз әріптік емес және сандық емес символдарды қамтуы тиіс (мысалы, '#', '!' немесе '&')",

    'onetimePasswordForm.title': 'Құпия сөзді қалпына келтіру',
    'onetimePasswordForm.recoverPassword': 'Қалпына келтіру',
    'onetimePasswordForm.usernameOrEmail': 'Пайдаланушының аты немесе электрондық пошта',
    'onetimePasswordForm.formValidation.required': 'Бұл өрісті толтыру міндетті',
    'onetimePasswordForm.tip':
      'Сіз көрсеткен электрондық мекенжайға жаңа құпия сөзді орнату үшін сілтеме көрсетілген хат жіберіледі',

    'signMethodForm.methods.kaztoken.title': 'Қазтокен',
    'signMethodForm.methods.kaztoken.text':
      'Кіру үшін электрондық сандық қолтаңбасы бар арнайы USB құрылғысын пайдаланыңыз',
    'signMethodForm.methods.desktop.title': 'Сіздің компьютеріңіз',
    'signMethodForm.methods.desktop.text':
      'Компьютеріңіздегі сандық қолтаңба файлын таңдаңыз',
    'signMethodForm.methods.personal.title': 'Жеке куәлік',
    'signMethodForm.methods.personal.text':
      'Жеке куәлігіңіздегі электрондық цифрлық қолтаңбаның көмегімен кіруді жүзеге асырыңыз',
    'signMethodForm.choose': 'Таңдау',
    'signMethodForm.sign': 'Қол қою және жіберу',
    'signMethodForm.signShort': 'Қол қою',
    'signMethodForm.methodNotSupported': 'Мүмкін емес',
    'signMethodForm.methodNotSupported2': 'Электрондық цифрлық қолтаңба үшін',
    'signMethodForm.methodCancelled': 'Электрондық цифрлық қолтаңба көмегімен',
    'signMethodForm.methodCancelled2': 'Жойылды',
    'signMethodForm.methodSuccess': 'Электрондық цифрлық қолтаңба көмегімен',
    'signMethodForm.methodSuccess2': 'Сәтті аяқталды',

    'registerForm.iin': 'ЖСН',
    'registerForm.username': 'Пайдаланушының аты',
    'registerForm.password': 'Құпия сөз',
    'registerForm.category': 'Ұйым санаты',
    'registerForm.role': 'Рөлі',
    'registerForm.emailAddress': 'Электрондық пошта',
    'registerForm.agreement': 'Дербес деректерді сақтауға және өңдеуге келісім',
    'registerForm.submit': 'Тіркеу',
    'registerForm.signAndSubmit': 'Қол қою және жіберу',
    'registerForm.educationManagement': 'Білім басқармасы',
    'registerForm.region': 'Облыс',
    'registerForm.regionEducation': 'Білім бөлімі',
    'registerForm.organizationType': 'Ұйым типі',
    'registerForm.locality': 'Елді мекен',
    'registerForm.organization': 'Ұйым',
    'registerForm.territorialLawsKKSON': 'БҒСБК аумақтық органдары',
    'registerForm.formValidation.required': 'Бұл өрісті толтыру міндетті',
    'registerForm.formValidation.wrongEmail': 'Қате email',
    'registerForm.formValidation.wrongIin': 'ЖСН форматы дұрыс емес',
    'registerForm.formValidation.wrongPasswordLength':
      'Құпия сөз 8 таңбадан кем болмауы тиіс',
    'registerForm.formValidation.wrongPasswordDigits': 'Құпия сөз сандарды қамтуы тиіс',
    'registerForm.formValidation.wrongPasswordUppercase':
      'Құпия сөз жоғарғы регистрдің латын әріптерін (A-Z) қамтуы тиіс',
    'registerForm.formValidation.wrongPasswordLowercase':
      'Құпия сөз төменгі регистрдің латын әріптерін (a-z) қамтуы тиіс',
    'registerForm.formValidation.wrongPasswordNotCyrillic':
      'Құпия сөз кириллицаны қамтымауы керек',
    'registerForm.formValidation.wrongPasswordSymbols':
      "Құпия сөз әріптік емес және сандық емес символдарды қамтуы тиіс (мысалы, '#', '!' немесе '&')",
    'registerForm.formValidation.wrongUsernameNotCyrillic':
      'Пайдаланушының аты тек қана латын әріптері мен сандарды құрау қажет',

    'passport.history.signDateColumn': 'Қол қою күні',
    'passport.signSubTitle': 'Ұйымның төлқұжатына қол қойыңыз',
    'passport.regInfo.loadData': 'Деректерді жүктеп алу:',
    'passport.regInfo.license': 'Лицензиялар бойынша деректер',

    'passportOrg.accordion.orgData': 'Ұйымның деректері',
    'passportOrg.accordion.bin': 'БСН',
    'passportOrg.accordion.name': 'Білім беру ұйымының атауы',
    'passportOrg.accordion.kk_fullName': 'Қазақ тіліндегі толық атауы',
    'passportOrg.accordion.kk_shortName': 'Қазақ тіліндегі қысқаша атауы',
    'passportOrg.accordion.kk_latin_fullName': 'Қазақ тіліндегі толық атауы (латын)',
    'passportOrg.accordion.kk_latin_shortName': 'Қазақ тіліндегі қысқаша атауы (латын)',
    'passportOrg.accordion.ru_fullName': 'Орыс тіліндегі толық атауы',
    'passportOrg.accordion.ru_shortName': 'Орыс тіліндегі қысқаша атауы',
    'passportOrg.accordion.en_fullName': 'Ағылшын тіліндегі толық атауы',
    'passportOrg.accordion.en_shortName': 'Ағылшын тіліндегі қысқаша атауы',
    'passportOrg.accordion.shortInfo': 'Білім беру ұйымының типі (міндетті)',
    'passportOrg.totalPercentage': 'Толтырудың жалпы пайызы',
    'passportOrg.signed': 'Қол қойылды!',
    'passportOrg.signedError': 'Паспортқа қол қою сәтсіз аяқталды',
    'passportOrg.personal': 'Қызметкерлер',
    'passportOrg.contingent': 'Контингент',

    'queueKindergarten.all': 'Жалпы кезек',
    'queueKindergarten.special': 'Арнайы мектепке дейінгі ұйымдарға кезек',
    'queueKindergarten.sanatornye': 'Санаториялық мектепке дейінгі ұйымдарға кезек',
    'queueKindergarten.archive': 'Мұрағат',
    'queueKindergarten.table.id': '№ өтінім',
    'queueKindergarten.table.iin': 'Контингентінің ЖСН-і',
    'queueKindergarten.table.lastName': 'Контингентінің тегі',
    'queueKindergarten.table.firstName': 'Контингентінің аты',
    'queueKindergarten.table.middleName': 'Контингентінің әкесінің аты',
    'queueKindergarten.table.birthDate': 'Туған күні',
    'queueKindergarten.table.queueType': 'Тізім түрі',
    'passportOrg.queueKindergarten': 'Мектепке дейінгі кезек',
    'ducks.queueKindergarten.cantloadQueue': 'Тізімді жүктеу мүмкін болмады',
    'queueKindergartenView.cantloadQueue': 'Тізімді жүктеу мүмкін болмады',
    'queueKindergarten.cantloadQueueTypes': 'Тізім түрін жүктеу мүмкін болмады',
    'queueKindergarten.removed': 'Өтінім өшірілді',
    'queueKindergartenView.createdAt': 'Жазба жасалды',
    'queueKindergartenView.updatedAt': 'Өтініш туралы деректер өзгертілді ',
    'queueKindergartenView.studentUpdatedAt': 'Дербес деректер өзгертілді',
    'queueKindergartenView.noData': 'Деректер жоқ',

    'miopassport.signSubTitle': 'Толтырылған ЖАО төлқұжатының деректеріне қол қойыңыз.',
    'miopassport.signed': 'Жазылым орындалды!',

    'vseobuch.tab.covered': 'Балаларды оқумен қамту',
    'vseobuch.tab.notVisiting': 'Білім беру ұйымына бармайтын балалар',
    'vseobuch.button.history': 'Тарих',
    'vseobuch.signSubTitle': 'Толтырылған деректерге қол қойыңыз',
    'vseobuch.covered.table.id': '№',
    'vseobuch.covered.table.iin': 'ЖСН',
    'vseobuch.covered.table.secondName': 'Тегі',
    'vseobuch.covered.table.firstName': 'Аты',
    'vseobuch.covered.table.thirdName': 'Әкесінің аты',
    'vseobuch.covered.table.birthDate': 'Туған күні',
    'vseobuch.covered.table.address': 'Мекен-жайы, қаласы',
    'vseobuch.covered.table.organization': 'Білім беру ұйымының атауы',
    'vseobuch.covered.table.group': 'Сынып/Топ/Курс',
    'vseobuch.covered.table.reason': 'Қамтылмау себебі',
    'vseobuch.covered.table.measures': 'Қабылданған шаралар',
    'vseobuch.covered.table.note': 'Ескертпе',
    'vseobuch.covered.table.upload': 'Жалғау',
    'vseobuch.covered.table.options.childCovered': 'Оқумен қамтылған балалар',
    'vseobuch.covered.table.options.childNotCovered': 'Оқумен қамтылмаған балалар',
    'vseobuch.covered.select.age': 'Жас санатын таңдау',
    'vseobuch.covered.select.coverage': 'Балаларды қамту',
    'vseobuch.covered.button.makeReport': 'Табу',
    'vseobuch.covered.button.export': 'Жүктеу',
    'vseobuch.covered.button.save': 'Сақтау',
    'vseobuch.covered.button.print': 'Басып шығару',
    'vseobuch.notVisited.table.iin': 'ЖСН',
    'vseobuch.notVisited.table.firstName': 'Аты',
    'vseobuch.notVisited.table.lastName': 'Тегі',
    'vseobuch.notVisited.table.middleName': 'Әкесінің аты',
    'vseobuch.notVisited.table.birthDate': 'Туған күні',
    'vseobuch.notVisited.table.grade': 'Сынып/Топ/Курс',
    'vseobuch.notVisited.table.fromDate': 'Қай күннен бастап қатыспайды',
    'vseobuch.notVisited.table.toDate': 'Орта білім беру ұйымына қайтарылды',
    'vseobuch.notVisited.table.familyState': 'Отбасының әлеуметтік жағдайы',
    'vseobuch.notVisited.table.reason': 'Қатыспау себептері',
    'vseobuch.notVisited.table.measures': 'Қабылданған шаралар',
    'vseobuch.notVisited.table.note': 'Ескертпе',
    'vseobuch.notVisited.table.actions': 'Әрекеттер',
    'vseobuch.notVisited.table.delete': 'Өшіру',
    'vseobuch.notVisited.table.upload': 'Жалғау',
    'vseobuch.notVisited.addStudent': 'Оқушыны қосу',
    'vseobuch.notVisited.save': 'Сақтау',
    'vseobuch.notVisited.download': 'Жүктеу',
    'vseobuch.notVisited.print': 'Басып шығару',
    'vseobuch.notVisited.search': 'Іздеу',
    'vseobuch.notVisited.saveInfo': 'Деректерді сақтау үшін ЭЦҚ қол қою қажет',
    'vseobuch.notVisited.sign': 'ЭЦҚ қол қою',
    'vseobuch.notVisited.add': 'Қосу',
    'vseobuch.notVisited.cancel': 'Жою',
    'vseobuch.notVisited.adding': 'Контингентті қосу',
    'vseobuch.notVisited.searchByFullName': 'Аты-жөні және туған күні бойынша іздеу',
    'vseobuch.notVisited.searchByIin': 'ЖСН бойынша іздеу',
    'vseobuch.notVisited.studentRemoved': 'Контингент сәтті жойылды!',
    'vseobuch.notVisited.studentAdded': 'Деректер сақталды',
    'vseobuch.notVisited.alreadyAdded':
      'Енгізілген ЖСН аталған білім беру ұйымында тіркелген!',
    'vseobuch.noFile': 'Жүктелген файлдар жоқ',
    'vseobuch.alt': 'Жүктелген файл',
    'vseobuch.covered.csv.iin': 'ЖСН',
    'vseobuch.covered.csv.lastName': 'Тегі',
    'vseobuch.covered.csv.firstName': 'Аты',
    'vseobuch.covered.csv.middleName': 'Әкесінің аты',
    'vseobuch.covered.csv.birthDate': 'Туған күні',
    'vseobuch.covered.csv.address': 'Мекен-жайы, қаласы',
    'vseobuch.covered.csv.nameOO': 'Білім беру ұйымының атауы',
    'vseobuch.covered.csv.grade': 'Сынып/Топ/Курс',
    'vseobuch.covered.csv.reason': 'Қамтылмау себебі',
    'vseobuch.covered.csv.measures': 'Қабылданған шаралар',
    'vseobuch.covered.csv.note': 'Ескертпе',
    'vseobuch.notVisited.csv.iin': 'ЖСН',
    'vseobuch.notVisited.csv.lastName': 'Тегі',
    'vseobuch.notVisited.csv.firstName': 'Аты',
    'vseobuch.notVisited.csv.middleName': 'Әкесінің аты',
    'vseobuch.notVisited.csv.birthDate': 'Туған күні',
    'vseobuch.notVisited.csv.grade': 'Сынып/Топ/Курс',
    'vseobuch.notVisited.csv.fromDate': 'Қай күннен бастап қатыспайды',
    'vseobuch.notVisited.csv.toDate': 'Орта білім беру ұйымына қайтарылды',
    'vseobuch.notVisited.csv.reason': 'Қатыспау себебі',
    'vseobuch.notVisited.csv.measures': 'Қабылданған шаралар',
    'vseobuch.notVisited.csv.note': 'Ескертпе',
    'vseobuch.signing': 'Қол қойылуда',
    'vseobuch.cantSign': 'Деректерге қол қойылмады',
    'vseobuch.cantLoadForSign': 'Қол қою үшін деректерді алу мүмкін болмады',
    'vseobuch.signDone': 'Қол қою сәтті өтті',
    'vseobuch.signFailed': 'Қол қойылмады',
    'vseobuch.history': 'Жалпыға бідей білім беру тарихы',
    'vseobuch.withoutChanges': 'Өзгерістер жоқ',

    'cameralControl.Checklist.options.yes': 'Иә',
    'cameralControl.Checklist.options.no': 'Жоқ',
    'cameralControl.Checklist.input.numberAkt':
      'Тексеріс өткізуді тағайындау туралы акт №',
    'cameralControl.Checklist.input.dateAkt':
      'Тексеріс өткізуді тағайындау туралы акт күні',
    'cameralControl.checklistReport.title': 'Тексеріс парағы',
    'cameralControl.checklistReport.appointedBy':
      'Тексерісті тағайындаған мемлекеттік орган - Қазақстан Республикасы Білім және ғылым саласындағы бақылау департаменті',
    'cameralControl.checklistReport.akt': 'Тексеріс өткізуді тағайындау туралы акт',
    'cameralControl.checklistReport.numAndDate': '(№, күні)',
    'cameralControl.checklistReport.name': 'Тексерілетін субъект  (объект) атауы',
    'cameralControl.checklistReport.bin':
      'Тексерілетін субъектінің (объектінің) бизнес-сәйкестендіру нөмірі (БСН)',
    'cameralControl.checklistReport.address': 'Орналасқан мекенжайы',
    'cameralControl.checklistReport.executive':
      'Лауазымды тұлға (тегі, аты, әкесінің аты (бар болған жағдайда))',
    'cameralControl.DKSONEmployee.select.statusSort': 'Сұрыптау',
    'cameralControl.DKSONEmployee.select.statusAll': 'Барлығы',
    'cameralControl.DKSONEmployee.select.statusHistory': 'Тексерістен өтті',
    'cameralControl.DKSONEmployee.select.statusDate':
      'Тексеріс өткізуді тағайындау туралы акт күні',
    'cameralControl.DKSONEmployee.select.statusDateFrom': 'Бастап',
    'cameralControl.DKSONEmployee.select.statusDateTo': 'Дейін',
    'cameralControl.DKSONEmployee.table.name': 'Білім беру ұйымының атауы',
    'cameralControl.DKSONEmployee.table.location': 'Пункт',
    'cameralControl.DKSONEmployee.history.title': 'Мемлекеттік камералдық бақылау тарихы',
    'cameralControl.DKSONEmployee.history.table.no': '№',
    'cameralControl.DKSONEmployee.history.table.numberAkt':
      'Тексеріс өткізуді тағайындау туралы акт №',
    'cameralControl.DKSONEmployee.history.table.dateAkt':
      'Тексеріс өткізуді тағайындау туралы акт күні',
    'cameralControl.DKSONEmployee.history.table.status': 'Статус',
    'cameralControl.DKSONEmployee.history.table.status.new': 'Жаңа',
    'cameralControl.DKSONEmployee.history.table.status.waitsign': 'Қол қойылуда',
    'cameralControl.DKSONEmployee.history.table.status.history': 'Қол қойылды',
    'cameralControl.DKSONEmployee.history.button.create': 'Тексеріс парағын ашу',
    'cameralControl.DKSONEmployee.createChecklist.save': 'Сақтау',
    'cameralControl.DKSONEmployee.createChecklist.sign': 'Қол қоюға жіберу',
    'cameralControl.DKSONDirector.tab.unsignedChecklists': 'Қол қоюды талап ететін',
    'cameralControl.DKSONDirector.tab.signedChecklists': 'Қол қойылған',
    'cameralControl.DKSONDirector.unsignedChecklists.note':
      'Қол қоюды қажет ететін құжаттар бар!',
    'cameralControl.DKSONDirector.unsignedChecklists.table.no': '№',
    'cameralControl.DKSONDirector.unsignedChecklists.table.orgName':
      'Білім беру ұйымының атауы',
    'cameralControl.DKSONDirector.unsignedChecklists.table.numberAkt':
      'Тексеріс өткізуді тағайындау туралы акт №',
    'cameralControl.DKSONDirector.unsignedChecklists.table.dateAkt':
      'Тексеріс өткізуді тағайындау туралы акт күні',
    'cameralControl.DKSONDirector.unsignedChecklists.table.checkListProject':
      'Тексеру парағының жобасы',
    'cameralControl.DKSONDirector.unsignedChecklists.table.checkListProject.value':
      'Жоба',
    'cameralControl.DKSONDirector.signedChecklists.table.no': '№',
    'cameralControl.DKSONDirector.signedChecklists.table.orgName':
      'Білім беру ұйымының атауы',
    'cameralControl.DKSONDirector.signedChecklists.table.numberAkt':
      'Тексеріс өткізуді тағайындау туралы акт №',
    'cameralControl.DKSONDirector.signedChecklists.table.dateAkt':
      'Тексеріс өткізуді тағайындау туралы акт күні',
    'cameralControl.DKSONDirector.signedChecklists.table.checkList': 'Тексеру парағы',
    'cameralControl.DKSONDirector.signedChecklists.table.checkList.value': 'Жүктеу',
    'cameralControl.DKSONDirector.signedChecklists.select.statusDate':
      'Тексеріс өткізуді тағайындау туралы акт күні',
    'cameralControl.DKSONDirector.signedChecklists.select.statusDateFrom': 'Бастап',
    'cameralControl.DKSONDirector.signedChecklists.select.statusDateTo': 'Дейін',
    'cameralControl.DKSONDirector.signChecklist.saveAndSign': 'Сақтау және қол қою',
    'cameralControl.KKSONEmployee.title': 'Аумақтық тиістілігі',
    'cameralControl.KKSONEmployee.passed.title': 'Тексерістен өткен білім беру ұйымдары',
    'cameralControl.KKSONEmployee.passed.table.name': 'Білім беру ұйымының атауы',
    'cameralControl.KKSONEmployee.passed.table.location': 'Елді мекен',
    'cameralControl.SchoolEmployee.history.title':
      'Мемлекеттік камералдық бақылау тарихы',
    'cameralControl.SchoolEmployee.history.table.no': '№',
    'cameralControl.SchoolEmployee.history.table.numberAkt':
      'Тексеріс өткізуді тағайындау туралы акт №',
    'cameralControl.SchoolEmployee.history.table.dateAkt':
      'Тексеріс өткізуді тағайындау туралы акт күні',
    'cameralControl.SchoolEmployee.history.downloadChecklist.view':
      'Тексеріс парағын көру',
    'cameralControl.SchoolEmployee.history.downloadChecklist.download':
      'Тексеріс парағын жүктеу',
    'cameralControl.sign.success': 'Қол қойылды!',
    'cameralControl.sign.error': 'Қол қойылмады!',
    'cameralControl.verify.error': 'Қол қоюды тексеру кезінде қате табылды!',

    'admin.addUser': '+ Пайдаланушыны қосу',
    'admin.table.id': 'ID',
    'admin.table.schoolId': 'Ұйым ID',
    'admin.table.username': 'Пайдаланушының аты',
    'admin.table.email': 'Электрондық пошта',
    'admin.table.firstName': 'Аты',
    'admin.table.lastName': 'Тегі',
    'admin.table.middleName': 'Әкесінің аты',
    'admin.table.organization': 'Ұйым',
    'admin.table.active': 'Іске қосу/өшіру',
    'admin.table.active.selectAll': 'Барлығы',
    'admin.table.active.selectActivated': 'Белсендірілген',
    'admin.table.active.selectDeactivated': 'Белсендірілмеген',
    'admin.table.action': 'Әрекет',
    'admin.table.action.delete': 'Өшіру',
    'admin.role.SCHOOL_EMPLOYEES': 'Білім беру ұйымының қызметкері немесе басшысы',
    'admin.role.MINISTRY_EMPLOYEES': 'ҚР БҒМ пайдаланушылары',
    'admin.role.MIO_EMPLOYEES': 'Білім басқармасының немесе бөлімінің пайдаланушысы',
    'admin.role.KKSON_EMPLOYEES':
      'Білім және ғылым саласындағы бақылау комитетінің пайдаланушысы',
    'admin.role.DKSON_EMPLOYEES':
      'Білім және ғылым саласындағы бақылау департаментінің пайдаланушысы',
    'admin.role.MIO_EMPLOYEES.cat_1': 'Білім басқармасының қызметкері немесе басшысы',
    'admin.role.MIO_EMPLOYEES.cat_7': 'Білім бөлімінің қызметкері немесе басшысы',
    'admin.header.role': 'Рөл',
    'admin.header.typeOO': 'Ұйымның типі',
    'admin.header.settlement': 'Елді мекен',
    'admin.header.region': 'Облыс',
    'admin.confirmModal.title': 'Өшіру',
    'admin.confirmModal.content': 'Өшіргіңіз келе ме',
    'admin.confirmModal.yes': 'Өшіру',
    'admin.confirmModal.no': 'Жою',

    'profile.username': 'Пайдаланушының аты',
    'profile.iin': 'ЖСН',
    'profile.email': 'Электронық пошта мекенжайы',
    'profile.firstName': 'Аты',
    'profile.lastName': 'Тегі',
    'profile.middleName': 'Әкесінің',
    'profile.active': 'Белсенді',
    'profile.regDate': 'Тіркеу күні',
    'profile.btn': 'Қол қою және сақтау',
    'profile.lastSign': 'Соңғы кіру уақыты',
    'profile.lastPassChange': 'Құпия сөзді соңғы ауыстыру уақыты',
    'profile.changePassword': 'Құпия сөзді ауыстыру',
    'profile.errorTimeAutch': 'Соңғы сәтсіз авторизация әрекетінің күні',
    'profile.iinLength': 'ЖСН 12 саннан тұруы керек',
    'profile.requiredFields': '"Ұйым" және "рөл" толтырылуы тиіс!',
    'profile.successfullyUpdated': 'Деректер сақталынды',
    'profile.changePassword.newPassword': 'Жана құпия сөз',
    'profile.changePassword.ok': 'Ок',
    'profile.changePassword.cancel': 'Болдырмау',
    'profile.changePassword.invalid':
      '"Парольдің ұзындығы 8 символдан кем емес болуы және сандардан мен латын әріптерінен (a-z и A-Z), әріптік емес және сандық емес символдардан тұруы тиіс (мысалы, "#", "!" немесе "&")"',

    'GBDFL.validation.first': 'ЖТ МДҚ-нан деректерді сұрату қажет',

    'personal.validation.yearsOld': '16 жасқа толмаған қызметкерді қоса алмайсыз',

    'contingent.validation.yearsOld':
      'Контингенттің жасы 5 айдан 15 жас аралығында болу керек',

    'statistics.select.title': 'Статистика',
    'statistics.select.statistics.filledPassports': 'Паспорттарды толтыру',
    'statistics.select.statistics.signedPassports': 'Паспорттарға қол қою',
    'statistics.tab.eduOrg': 'Білім беру ұйымдары',
    'statistics.tab.eduMng': 'Білім басқармасы/бөлім',
    'statistics.tab.eduControlMng': 'Білім басқармасы',
    'statistics.tab.eduDepartmentMng': 'Білім бөлімі',
    'statistics.button.export': 'Excel-ге экспорттау',
    'statistics.FilledPassports.EduOrg.rootName': 'Қазақстан Республикасы',
    'statistics.FilledPassports.EduOrg.table.total': 'Барлығы',
    'statistics.FilledPassports.EduOrg.table.filled': 'Толтырылған',
    'statistics.FilledPassports.EduOrg.table.filledTotalCount': 'толтырылған/барлығы',
    'statistics.FilledPassports.EduOrg.table.name': 'Атауы',
    'statistics.FilledPassports.EduOrg.table.mio': 'ЖАО',
    'statistics.FilledPassports.EduOrg.table.typeOO': 'ББҰ типі',
    'statistics.FilledPassports.EduOrg.table.totalCount': 'ББҰ ,барлығы',
    'statistics.FilledPassports.EduOrg.table.filledCount': 'ББҰ толтырылған',
    'statistics.FilledPassports.EduOrg.table.personnel': 'Қызметкерлер',
    'statistics.FilledPassports.EduOrg.table.contingent': 'Контингент',
    'statistics.FilledPassports.EduOrg.table.section.3': 'Тіркеу мәліметтері',
    'statistics.FilledPassports.EduOrg.table.section.10': 'Ғимарат туралы мәлімет',
    'statistics.FilledPassports.EduOrg.table.section.12': 'Кітапхана/оқулықтар',
    'statistics.FilledPassports.EduOrg.table.section.13':
      'Кабинеттер / Сыныптар / Аудиториялар',
    'statistics.FilledPassports.EduOrg.table.section.18': 'Компьютерлеу',
    'statistics.FilledPassports.EduOrg.table.section.20': 'Асхана',
    'statistics.FilledPassports.EduOrg.table.section.36': 'МТҚ туралы қосымша мәліметтер',
    'statistics.FilledPassports.EduOrg.table.section.21':
      'Білім беру үдерісі туралы негізгі мәліметтер',
    'statistics.FilledPassports.EduOrg.table.section.22':
      'Білім беру объектісі туралы негізгі мәліметтер',
    'statistics.FilledPassports.EduOrg.table.section.26': 'Жатақхана/интернат',
    'statistics.FilledPassports.EduOrg.table.section.44':
      'Кадрлар туралы қосымша мәліметтер',
    'statistics.FilledPassports.EduOrg.table.section.45':
      'Контингент туралы қосымша мәліметтер',
    'statistics.FilledPassports.EduOrg.table.section.81': 'Түлектер',
    'statistics.FilledPassports.EduOrg.table.section.185': 'Аккредиттеу',
    'statistics.FilledPassports.EduOrg.table.section.186': 'ЖОО-ның инновациялық қызметі',
    'statistics.FilledPassports.EduOrg.table.section.197': 'Қаржыландыру',
    'statistics.FilledPassports.EduOrg.table.section.198': 'Қамқоршылық кеңестер',
    'statistics.FilledPassports.EduOrg.table.section.199': 'Әлеуметтік серіктестік',
    'statistics.FilledPassports.EduOrg.table.section.125':
      'Төлқұжатты толтыратын жауапты адам',
    'statistics.FilledPassports.EduOrg.table.section.cameral_control':
      'Камералды бақылау',
    'statistics.FilledPassports.EduOrg.table.section.strict_reporting_documents':
      'Қатаң есептегі құжаттар',
    'statistics.FilledPassports.ControlMngOrg.table.total': 'Барлығы',
    'statistics.FilledPassports.ControlMngOrg.table.filled': 'Толтырылған',
    'statistics.FilledPassports.ControlMngOrg.table.filledTotalCount':
      'толтырылған/барлығы',
    'statistics.FilledPassports.ControlMngOrg.table.name': 'Атауы',
    'statistics.FilledPassports.ControlMngOrg.table.totalCount': 'ББ барлығы',
    'statistics.FilledPassports.ControlMngOrg.table.filledCount': 'ББ толтырылған',
    'statistics.FilledPassports.ControlMngOrg.table.personnel': 'Қызметкерлер',
    'statistics.FilledPassports.ControlMngOrg.table.section.registration_info':
      'Тіркеу мәліметтері',
    'statistics.FilledPassports.ControlMngOrg.table.section.166':
      'Білім беру ұйымдарының апаттылық мәселесін шешу үшін қолданылатын шаралар туралы мәлімет',
    'statistics.FilledPassports.ControlMngOrg.table.section.145':
      'Білім беру ұйымдары туралы мәліметтер',
    'statistics.FilledPassports.ControlMngOrg.table.section.167':
      'Білім беру ұйымдары жоқ елді мекендер туралы мәліметтер',
    'statistics.FilledPassports.ControlMngOrg.table.section.168':
      'Қосымша білім беру ұйымдары туралы мәліметтер',
    'statistics.FilledPassports.ControlMngOrg.table.section.169': 'Қосымша мәліметтер',
    'statistics.FilledPassports.ControlMngOrg.table.section.127':
      'Төлқұжатты толтыратын жауапты адам',
    'statistics.FilledPassports.ControlMngOrg.table.section.165':
      'Балалардың құқығын қорғау',
    'statistics.FilledPassports.ControlMngOrg.table.section.194':
      'Мектепке дейінгі ұйымдардың мәліметтері',
    'statistics.FilledPassports.ControlMngOrg.table.section.201': 'Қаржыландыру',
    'statistics.FilledPassports.ControlMngOrg.table.section.225': 'Жұмыспен қамту',
    'statistics.FilledPassports.ControlMngOrg.table.section.208':
      'Қаржыландыру (жазғы демалыс)',
    'statistics.FilledPassports.DepartmentMngOrg.table.total': 'Барлығы',
    'statistics.FilledPassports.DepartmentMngOrg.table.filled': 'Толтырылған',
    'statistics.FilledPassports.DepartmentMngOrg.table.filledTotalCount':
      'толтырылған/барлығы',
    'statistics.FilledPassports.DepartmentMngOrg.table.name': 'Атауы',
    'statistics.FilledPassports.DepartmentMngOrg.table.totalCount': 'ҚББ/АББ барлығы',
    'statistics.FilledPassports.DepartmentMngOrg.table.filledCount':
      'БББ/БББ толтырылған',
    'statistics.FilledPassports.DepartmentMngOrg.table.personnel': 'Қызметкерлер',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.registration_info':
      'Тіркеу мәліметтері',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.61':
      'Білім беру ұйымдарының апаттылық мәселесін шешу үшін қолданылатын шаралар туралы мәлімет',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.121':
      'Білім беру ұйымдары туралы мәліметтер',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.122':
      'Білім беру ұйымдары жоқ елді мекендер туралы мәліметтер',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.123':
      'Қосымша білім беру ұйымдары туралы мәліметтер',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.124': 'Қосымша мәліметтер',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.126':
      'Төлқұжатты толтыруға жауапты адам',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.195':
      'Балалардың құқығын қорғау',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.196':
      'Мектепке дейінгі ұйымдардың мәліметтері',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.200': 'Қаржыландыру',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.207': 'Жұмыспен қамту',
    'statistics.FilledPassports.DepartmentMngOrg.table.section.209':
      'Қаржыландыру (жазғы демалыс)',
    'statistics.SignedPassports.EduOrg.rootName': 'Қазақстан Республикасы',
    'statistics.SignedPassports.EduOrg.table.name': 'Атауы',
    'statistics.SignedPassports.EduOrg.table.mio': 'ЖАО',
    'statistics.SignedPassports.EduOrg.table.typeOO': 'ББҰ типі',
    'statistics.SignedPassports.EduOrg.table.signDate': 'Қол қойылған күні',
    'statistics.SignedPassports.EduOrg.table.signWho': 'Кім қол қойды',
    'statistics.SignedPassports.EduOrg.table.filled': '(%) толтырылған',
    'statistics.SignedPassports.MngOrg.table.name': 'Атауы',
    'statistics.SignedPassports.MngOrg.table.signDate': 'Қол қойылған күні',
    'statistics.SignedPassports.MngOrg.table.signWho': 'Кім қол қойды',
    'statistics.SignedPassports.MngOrg.table.filled': '(%) толтырылған',

    'ui.dynamic.document.filePath': 'Файлға жол',
    'ui.dynamic.document.chooseFile': 'Файлды таңдау',
    'ui.dynamic.document.noFileChosen': 'Файл таңдалмаған',
    'ui.dynamic.document.file': 'Файл',
    'ui.dynamic.document.cantViewFile': 'Файлды ашу мүмкін емес, жүктеп көріңіз',
    'ui.dynamic.document.attachment': 'Жүктелген файл',
    'ui.dynamic.dTable.buildingsInfo': 'Ғимараттар туралы мәлімет',
    'ui.dynamic.dTable.building': 'Ғимарат',
    'ui.dynamic.dTable.deleteBuilding': 'Ғимаратты өшіру',
    'ui.dynamic.dTable.addBuilding': 'Ғимаратты қосу',
    'ui.dynamic.dTable.action': 'Әрекеттер',
    'ui.dynamic.dTable.delete': 'Өшіру',
    'ui.dynamic.dTable.addString': 'Жолды қосу',
    'ui.dynamic.dTable.emptyList.part1': 'Толтыруды бастаңыз',
    'ui.dynamic.dTable.emptyList.part2': '"Жолды қосу +" батырмасын басу',
    'ui.dynamic.dTable.emptyList.part3': 'Егер сізде жоқ болса',
    'ui.dynamic.dTable.emptyList.part4': 'онда ақпараттың бұл бөлігін толтырмаңыз',
    'ui.expComponent.collapse': 'Барлығын жасыру',
    'ui.expComponent.expand': 'Барлығын ашу',
    'ui.row.source': 'Дереккөз',
    'ui.select.placeholder': 'Мәнді таңдаңыз',
    'ui.select.loading': 'Жүктелуде...',
    'ui.select.noOptions': 'Деректер жоқ',
    'ui.staticTable.collapse': 'Кестені жасыру',
    'ui.staticTable.expand': 'Кестені ашу',
    'ui.staticTablePagination.load': 'Тағы жүктеу',
    'ui.staticTablePagination.goToPage': 'Параққа өту',
    'ui.loadingData.reload': 'Парақшаны жаңарту',

    'errors.UNKNOWN_ERROR': 'Белгісіз қате',
    'errors.CONNECTION': 'Ғаламторға қосылмаған',
    'errors.FAIL': 'Қате сұраныс',
    'errors.SERVER_ERROR': 'Сервер қатесі, операцияны кейінірек қайталаңыз',
    'errors.BAD_GATEWAY':
      'Қазіргі уақытта техникалық жұмыстар жүргізілуде. Кейінірек кіріп көріңіз',
    'errors.NETWORK_TIMEOUT': 'Қосылу қатесі, сервер жауап бермейді',
    'errors.NETWORK_ERROR': 'Қате, қосылу үзілді',
    'errors.NETWORK_PARAMS': 'Ғаламторға қосылу параметрлерін тексеріп көріңіз',
    'errors.INCORRECT_USERNAME_PASSWORD':
      'Пайдаланушының аты немесе құпия сөзі қате, қайтадан теріп көріңіз',
    'errors.MULTISESSION_NOT_ALLOWED': 'Көрсетілген пайдаланушы жүйеге кіріп отыр',
    'errors.ALREADY_EXISTS':
      'Пайдаланушының аты бұрыннан бар, жаңасын тіркеңіз немесе бар пайдаланушы атымен кіріп көріңіз',
    'errors.SIGNATURE_VERIFICATION_FAIL':
      'Электрондық цифрлық қолтаңбадағы ЖСН пайдаланушы ЖСН-імен сәйкес келмейді',
    'errors.USER_NOT_ACTIVE': 'Пайдаланушы белсендірілмеген',
    'errors.AUTH_SERVICE_IS_UNAVAILABLE': 'Авторизация қызметі қолжетімсіз',
    'errors.INVALID_TOKEN': 'Токен жарамсыз',
    'errors.REQUIRED_ATTRIBUTES_ARE_NOT_FILLED':
      'Құжаттың міндетті атрибуттары толтырылмаған',
    'errors.PASSWORD_ATTEMPTS_EXCEEDED':
      'Есептік жазба/құпиясөз 3 рет сәтсіз еңгізілген үшін пайдаланушы тіркелгісі бұғатталды',
    'errors.PASSWORD_IS_EXPIRED': 'Құпия сөздің мерзімі өткен',
    'errors.NEW_PASSWORD_IS_EQUALS_TO_PREVIOUS':
      'Соңғы үш парольді пайдалануға тыйым салынады',
    'errors.AUTH_HEADER_IS_NULL':"Пайдаланушы авторизацияланбаған",

    'errors.NCALayer_CONNECTION': 'NCALayer бағдарламасына қосылмаған',
    'errors.activation_failed': 'Операция орындалмады',
    'errors.cantLoadFlk': 'rule.js ФЛК скрипті жүктелген жоқ',
    'errors.cantLoadProfile': 'Профиль деректері жүктелген жоқ',
    'errors.typeOOFor': 'Білім беру ұйымының типі',
    'errors.notFound': 'табылмады',

    'errors.common_0001': 'Қазіргі уақытта қызмет көрсету қолжетімсіз, кейінірек көріңіз',
    'errors.common_0002': 'Ресурс табылған жоқ',
    'errors.common_0003':
      'Сыртқы қызмет көрсету уақытша қолжетімсіз. Қолдау көрсету қызметіне хабарласыңыз',
    'errors.common_0004': 'Сұранысты тексеру орындалған жоқ',
    'errors.common_0005': 'Ішкі жалпы қате. Қолдау көрсету қызметіне хабарласыңыз',
    'errors.common_0006': 'Қазіргі уақытта қызмет көрсету қолжетімсіз, кейінірек көріңіз',
    'errors.common_0007': 'Ішкі жалпы қате. Қолдау көрсету қызметіне хабарласыңыз',
    'errors.common_0008': 'Ішкі жалпы қате. Қолдау көрсету қызметіне хабарласыңыз',
    'errors.common_0009':
      'Авторизация мүмкін болмады. Ағымдағы қолданушыға әрекет қолжетімсіз',
    'errors.common_0010':
      "External service returned data which couldn't be interpreted correctly. Please contact OMC User Support",

    'errors.entitlement_0001':
      'Токенді тексеру орындалған жоқ. Пайдаланушының аты мен құпия сөзін тексеріңіз және қайта теріп көріңіз',
    'errors.entitlement_0002':
      'Құпия сөзді қалпына келтіруге арналған сілтеме қолжетімсіз',
    'errors.entitlement_0003': 'Мұндай атауымен пайдаланушы бар',
    'errors.entitlement_0004':
      'Деректер қорында сіз өшіргелі отырған көрсеткішке қатысты байланыстар бар',
    'errors.entitlement_0005':
      'Қолданушының қолтаңбасының түпнұсқалығын тексеру мүмкін болмады',
    'errors.entitlement_0006': 'Электронндық пошта мекенжайы бос емес',
    'errors.entitlement_password_is_not_correct':
      'Сіздің құпия сөзіңіз ағымдағы құпия сөзбен сәйкес келмейді.',
    'errors.entitlement_passwords_are_not_equal': 'Құпия сөздер сәйкес келмейді',
    'errors.entitlement_user_username_is_null': 'Пайдаланушының аты толтырылмаған',
    'errors.entitlement_user_iin_is_null': 'ЖСН толтырылмаған',
    'errors.entitlement_user_iin_is_busy': 'Мұндай ЖСН-мен пайдаланушы бар',
    'errors.entitlement_user_profile_data_registration_role_does_not_exist':
      'Пайдаланушының рөлі көрсетілмеген',
    'errors.entitlement_user_profile_data_school_id_does_not_exist':
      'Білім беру ұйымы көрсетілмеген',
    'errors.entitlement_teacher_does_not_work_at_school':
      'Сіз осы ұйымның қызметкерлер құрамында жоқсыз.',
    'errors.entitlement_user_not_found_in_gbdfl':
      'ЖТ МДҚ-да мұндай ЖСН-мен адам табылмады',
    'errors.entitlement_signature_verification': 'ЭЦҚ жарамсыз',
    'errors.entitlement_user_email_address_is_null': 'email толтырылмаған',
    'errors.entitlement_user_email_address_is_busy': 'Бұл email бос емес',
    'errors.entitlement_user_by_one_time_password_not_found':
      'Бір реттік құпия сөз жарамсыз',
    'errors.entitlement_user_not_found': 'Пайдаланушы табылмады',
    'errors.sign_only_rsa_method':
      'Пайдаланушыны тіркеу үшін ҚР ҰКО электрондық цифрлық қолтаңба (жеке тұлға) қолдану қажет',
    'errors.error.runtime_education_coverage_not_found':
      'Аталған білім беру ұйымына шағын учаскелер қосылмаған',
    'errors.error.runtime_last_name_filter_must_be_more_than_or_equals_to_three_symbols':
      '"Тегі" өрісінде екі таңбадан артық болуы керек',
    'errors.error.runtime_fist_name_filter_must_be_more_than_or_equals_to_three_symbols':
      '"Аты" өрісінде екі таңбадан көп болуы керек',
    'errors.error.runtime_middle_name_filter_must_be_more_than_or_equals_to_three_symbols':
      '"Әкесінің аты" өрісінде екі таңбадан көп болуы керек',
    'errors.error.runtime_not_found': 'Көрсетілген мекенжай дұрыс емес',
    'errors.error.ddm_item_not_found':
      '«{Code}» элемент коды «{Database} каталогынан табылған жоқ',
    'errors.error.runtime_school_cannot_be_deleted_because_has_contingents_and_personals':
      'Ұйымды жою мүмкін емес, өйткені қызметкерлер және контингент тізімі бар.',
    'errors.error.runtime_school_cannot_be_deleted_because_has_contingents':
      'Ұйымды жою мүмкін емес, өйткені контингент тізімі бар.',
    'errors.error.runtime_school_cannot_be_deleted_because_has_personals':
      'Ұйымды жою мүмкін емес, өйткені қызметкерлер тізімі бар.',
    'errors.error.runtime_school_cannot_be_deleted_because_has_contraints':
      'Ұйымды жою мүмкін емес, өйткені ұйымның толтырылған жазулары бар.',

    'errors.error.runtime_job_info_already_registered':
      'Қызметкер аталған мекемеде тіркелген',
    'errors.error.common_0004': 'Сіз жарамсыз деректерді жібердіңіз',
    'errors.error.entitlement_password_is_not_correct':
      'Сіздің құпия сөзіңіз ағымдағы құпия сөзбен сәйкес келмейді.',
    'errors.error.entitlement_exists_current_at_users':
      'Бұл білім беру ұйымының қолданушысы бар. Тек бір қолданушыны құруға болады.',
    'errors.error.runtime_business_object_type_is_unavailable':
      'Бизнес нысанның жарамсыз түпі',
    'errors.error.runtime_company_data_not_found': 'ЗТ МДҚ-нан мәліметтер табылмады',
    'errors.error.runtime_school_not_found': 'Білім беру ұйымы табылған жоқ',
    'errors.error.runtime_teacher_not_found': 'Қызметкер табылған жоқ',
    'errors.error.runtime_student_not_found': 'Контингент табылған жоқ',
    'errors.error.runtime_job_info_not_found': 'job табылмады',
    'errors.error.runtime_education_info_not_found': 'education табылмады',
    'errors.error.runtime_authenticated_user_not_found':
      'Пайдаланушы авторизацияланбаған',
    'errors.error.runtime_schools_are_equal':
      'қате. Out мектебі және in мектебі тең болмайды',
    'errors.error.runtime_schools_are_not_equal': 'Қате. Мектептер тең болуы керек',
    'errors.error.runtime_school_subtype_not_found':
      'Білім беру ұйымының түрі табылған жоқ',
    'errors.error.runtime_target_school_is_not_found':
      'Оқушы түскісі келген білім беру ұйымы табылған жоқ',
    'errors.error.runtime_source_school_is_not_found':
      'білім беру ұйымы, студент кетгісі келетіннен, табылған жоқ',
    'errors.error.runtime_school_must_not_be_null': 'Мектеп өрісі боа болмауы қажет',
    'errors.error.runtime_student_cannot_study_somewhere_else':
      'Қате, білім алушы басқа білім беру ұйымында қатар оқи алмайды',
    'errors.error.runtime_education_info_already_registered': 'Контингент тіркелген',
    'errors.error.runtime_student_movement_is_unavailable':
      'Білім алушылары басқа білім беру ұйымына ауыстыруға тыйым салынады',
    'errors.error.runtime_education_status_is_incorrect': 'Білім алушы шығарылған',
    'errors.error.runtime_iin_must_not_be_null': 'ЖСН бос бола алмайды',
    'errors.error.runtime_bin_must_not_be_null': 'БСН бос бола алмайды',
    'errors.error.runtime_ids_must_not_be_null': 'id тізімі бос бола алмайды',
    'errors.error.runtime_signature_data_was_changed': 'қол қою деректері ауыстырылды',
    'errors.error.runtime_user_must_sing_own_signature':
      'Пайдаланушының ЖСН ЭЦҚ-ға сәйкес емес',
    'errors.error.runtime_signature_is_invalid': 'Қол қою кезінде қате',
    'errors.error.runtime_questionnaire_metadata_not_found': 'Метадеректер табылмады',
    'errors.error.runtime_student_has_gone_previous_year':
      'Білім алушының соңғы ауысуы осы жылы болған жоқ',
    'errors.error.runtime_student_cannot_enter_inside': 'Білім алушы қабылдана алмайды',
    'errors.error.runtime_cameral_control_does_not_exists_for_this_school':
      'School type code does not match for cameral control',
    'errors.error.runtime_cameral_control_can_not_create_repeat_for_this_school':
      'Cameral control can not create repeat for this school',
    'errors.error.runtime_page_size_and_page_number_must_be_more_than_zero':
      'Page size and page number must be more than zero',
    'errors.error.runtime_file_must_be_less_than_or_equal_to_one_megabyte':
      'Жүктелген файлдың өлшемі 1 Мб аспауы керек',
    'errors.error.runtime_school_cannot_be_closed':
      'Ұйымды жабу мүмкін емес. Қызметкерлер және контингент бөлімдеріндегі қызметкерлер мен білім алушыларды жұмыстан босату/кетуді рәсімдеу қажет',
    'errors.error.entitlement_signature_iin_not_equal_current':
      'Пайдаланушының ЖСН электронды-цифрлық қолтаңбадағы ЖСН сәйкес емес',
    'errors.error.runtime_student_is_held_back':
      'Білім алушы қайтадан курсқа қалдырылғандықтан көшіріле алмайды',
    'errors.error.runtime_student_is_on_academic_leave': 'Студент академиялық демалыста',
    'errors.error.runtime_leave_date_more_than_days':
      'Кеткенді қайтару мерзімі 30 күн аяқталды',
    'errors.error.can_not_transition_approve':
      'Бұл студент келесі ұйымның қазіргі контингентінде көрсетілген',
    'errors.error.runtime_education_statistic_fully_filled_not_be_false':
      'Контингент үшін паспортты толтыру және сақтау қажет',
    'errors.error.runtime_education_info_already_registered2':
      'Аталған білім алушы контингентте тіркелген',
    'errors.error.student_with_iin_already_exists':
      'Аталған білім алушы контингентте тіркелген"',
    'errors.error.teacher_with_iin_already_exists':
      'Мұндай ЖСН-мен қызметкер жүйеде басқа ID-мен  бар',

    'warning.staff_personal': 'Тек қоса атқарушы ретінде қабылдауға болады',

    'searchAddress.addressPartType': 'Елді мекеннің құрамдас бөлігі',
    'searchAddress.addressPart': 'Атауы',
    'searchAddress.buildingNumber': 'Нөмірі',
    'searchAddress.search': 'Іздеу',
    'searchAddress.delete': 'Өшіру',
    'searchAddress.add': 'Қосу',
    'searchAddress.added': 'Қосылды',
    'searchAddress.found': 'Табылған мекенжайлар',
    'searchAddress.rka': 'МТК',
    'searchAddress.action': 'Әрекет',
    'searchAddress.addresses': 'Қосылған мекенжайлар',

    'responsibleForPassportization.iin': 'ЖСН',
    'responsibleForPassportization.request': 'Қызметкердің деректерін сұрату',

    'secondSidebar.contingent': 'Контингент',
    'secondSidebar.personal': 'Қызметкерлер',

    'support.title':
      'ҰБДҚ-мен жұмыс істеу кезінде техникалық ақаулар мен өзге де мәселелер туындаған жағдайда, ҰБДҚ техникалық қолдау қызметіне сұраснысты қатаң түрде келесі форматта жіберу:',
    'support.firstDemand':
      'Білім беру ұйымының толық атауын көрсетіңіз, аймақ/қала/аудан/ауыл, ұйымның id, жауапты тұлғаны (толық есімі, лауазымы),телефон нөмері, Пайдаланушының аты (логин);',
    'support.secondDemand':
      'Міндетті түрде көрсету керек: қатенің күні мен уақыты, қатенің себебін сипаттау, ҰБДҚ-ның қай бөлімде қателіктің кеткенін толық көрсету, қателіктің сипаттамасы жайлы қосымша ақпарат енгізіңіз (мысалы ЖСН және контингенттің немесе персоналдың толық есімін көрсетіңіз, student_id, educationinfo_id, teacher_id, jobinfo_id, мәселе туындаған сұрақтарға).',
    'support.thirdDemand':
      'Қандай әрекеттер жасалады-орындалған әрекеттердің скриншоттарын тіркеңіз («аты» бөліміне кіру, «аты» батырмасын басу, хабарлама/қате/жауап бермеу көрсетіледі).',
    'support.fourthDemand': 'ҰБДҚ-да нақты не жасау қажет.',
    'support.send': 'Жоғарыда көрсетілген ақпаратты электрондық пошта бойынша жіберу',
    'support.contacts': 'Байланыс телефондары',

    'infoSecurity.title':
      'Ақпараттық қауіпсіздікті қамтамасыз ету талаптарына байланысты:',
    'infoSecurity.point_1': 'құпиясөздің жарамдылық мерзімі 60 күн;',
    'infoSecurity.point_2':
      'құпиясөзді ауыстырған кезде соңғы үш құпиясөзді пайдалануға тыйым салынады;',
    'infoSecurity.point_3':
      '3 сәтсіз қуаттау әрекетінен кейін пайдаланушы тіркелгісі бұғатталады;',
    'infoSecurity.point_4': 'бірнеше адамға бір есептік жазбамен кіруге тыйым салынады;',
    'infoSecurity.point_5': 'ҰБДҚ - ға кіру ЭСҚ арқылы жүзеге асырылады.',

    'yandexMap.search': 'Орындар мен мекенжайларды іздеу',
    'yandexMap.ready': 'Дайын',
    'yandexMap.mapLoading': 'Карта жүктелуде',
    'yandexMap.error': 'Картаны жүктеу мүмкін болмады',

    'trajectory.studentTrajectory': 'Оқушы қозғалысының траекториясы',
    'trajectory.studentDocie': 'Оқушы құжаттамасы',
    'trajectory.trajectory': 'Қозғалыс траекториясы',
    'trajectory.searchForm.searchByInn': 'ЖСН бойынша іздеу',
    'trajectory.searchForm.searchByFio': 'Аты-жөні және туған күні бойынша іздеу',
    'trajectory.searchForm.foundSubjects': 'Білім берудің табылған субъектілері',
    'trajectory.searchForm.notifInnSearchWasPerformed':
      'Осы ЖСН бойынша іздеу нәтижелері берілген!',
    'trajectory.searchForm.notifFioSearchWasPerformed':
      'Енгізілген өрістер бойынша іздеу нәтижелері берілген',
    'trajectory.searchForm.innForm.search': 'Іздеу',
    'trajectory.searchForm.innForm.iin': 'ЖСН',
    'trajectory.searchForm.fioForm.lastName': 'Тегі',
    'trajectory.searchForm.fioForm.firstName': 'Аты',
    'trajectory.searchForm.fioForm.middleName': 'Әкесінің аты',
    'trajectory.searchForm.fioForm.birthDate': 'Туған күні',
    'trajectory.searchForm.fioForm.search': 'Іздеу',
    'trajectory.searchForm.popup.header.lastName': 'Тегі',
    'trajectory.searchForm.popup.header.firstName': 'Аты',
    'trajectory.searchForm.popup.header.middleName': 'Әкесінің аты',
    'trajectory.searchForm.popup.header.birthDate': 'Туған күні',
    'trajectory.searchForm.popup.header.regAddressCountry': 'Тіркеу мекенжайы',
    'trajectory.searchForm.popup.header.studentId': 'ID',
    'trajectory.searchForm.popup.select': 'Таңдау',
    'trajectory.searchForm.popup.cancel': 'Жою',
    'trajectory.dossier.dossierButtonTitle': 'Құжаттаманы жүктеу',
    'trajectory.dossier.iin': 'ЖСН',
    'trajectory.dossier.fio': 'Аты-жөні',
    'trajectory.dossier.birthDate': 'Туған күні',
    'trajectory.dossier.status': 'Азаматтық мәртебесі',
    'trajectory.dossier.regAddress': 'Тіркеу мекенжайы',
    'trajectory.dossier.currentState': 'Ағымдағы күйі',
    'trajectory.dossier.employed': 'Жұмысқа орналасты',
    'trajectory.dossier.inOO': 'Білім беру ұйымдында тіркелген',
    'trajectory.dossier.notInOO': 'Білім беру ұйымының ешқайсында тіркелмеген',
    'trajectory.dossier.groupName': 'Топтың атауы',
    'trajectory.dossier.group': 'Топ',
    'trajectory.dossier.parallel': 'Параллель',
    'trajectory.dossier.litera': 'Литера',
    'trajectory.dossier.groupCode': 'Топтың коды',
    'trajectory.dossier.studentCourse': 'Студент (оқу курсы)',
    'trajectory.dossier.profession': 'Мамандық және классификатор (негізгі)',
    'trajectory.table.data': 'Оқу / жұмыс орны туралы деректер',
    'trajectory.table.progress': 'Үлгерім',
    'trajectory.table.docs': 'Білім беру бойынша құжаттар',
    'trajectory.table.competitions': 'Сайыстарға қатысу',
    'trajectory.table.sections': 'Үйірмелер мен секцияларға қатысады',
    'trajectory.table.health': 'Денсаулық жағдайы',
    'trajectory.table.signs': 'Ерекше белгілер',
    'trajectory.table.languages': 'Оқитын тілдер',

    'contingent.createContingentModal.yes': 'Иә',
    'contingent.createContingentModal.no': 'Жоқ',
    'contingent.createContingentModal.header': 'Контингент тіркелген',
    'contingent.createContingentModal.desc': 'Өткіңіз келе ме?',
    'contingent.arrivalModal.cancel': 'Жою',
    'contingent.arrivalModal.confirm': 'Қабылдауды рәсімдеу',
    'contingent.arrivalTable.action': 'Әрекет',
    'contingent.arrivalTable.status': 'Мәртебесі',
    'contingent.arrivalTable.confirm': 'Қабылдауды рәсімдеу',
    'contingent.arrivalTable.deny': 'Қабылдауды кері қайтару',
    'contingent.arrivalTable.denied': 'Қабылдау кері қайтарылды',
    'contingent.arrivalTable.restrictedAction':
      '1-сыныпқа қабылдау ағымдағы жылғы 25 тамыздан қол жетімді болады',
    'contingent.denyModal.header': 'Қабылдауды кері қайтару',
    'contingent.denyModal.date': 'Бас тартылған күні',
    'contingent.denyModal.reason': 'Бас тарту себебі',
    'contingent.denyModal.deny': 'Қабылдауды кері қайтару',
    'contingent.denyModal.cancel': 'Күшін жою',
    'contingent.checkoutModal.header': 'Шығуды ресімдеу',
    'contingent.checkoutModal.date': 'Кету күні',
    'contingent.checkoutModal.number': 'Кету бұйрығының нөмірі',
    'contingent.checkoutModal.reason': 'Кету себебі',
    'contingent.checkoutModal.status': 'Мәртебесі',
    'contingent.checkoutModal.kato': 'ӘАОК',
    'contingent.checkoutModal.typeOO': 'Білім беру ұйымының қабылдаушы түрі',
    'contingent.checkoutModal.receiveOO': 'Қабылдаушы ұйым',
    'contingent.checkoutModal.confirm': 'Кетуді ресімдеу',
    'contingent.checkoutModal.cancel': 'Жою',
    'contingent.checkoutModal.country': 'Кету елі',
    'contingent.checkoutModal.notice':
      'Назар аударыңыз! Сіз кетуді ресімдеп жатырсыз, егер сізге түлек ретінде шығару қажет болса, "Жою" түймешігін басып, «Шығаруды, көшіруді немесе қабылдауды ресімдеу»-ді таңдаңыз',
    'contingent.checkoutModal.alreadyExist':
      'Контингент ата-анасының өтінімі бойынша «электрондық үкімет» веб-порталы арқылы келесі мектептерге келуі тиіс',
    'contingent.checkoutModal.schoolId': 'Ұйым ID',
    'contingent.checkoutModal.schoolName': 'Ұйым атауы',
    'contingent.checkoutModal.phone': 'Телефон',
    'contingent.checkoutModal.location': 'Елді мекен',
    'contingent.checkoutModal.contingentID': 'Контингент ID',
    'contingent.checkoutModal.select': 'Таңдау',
    'contingent.tableContingent.action': 'Әрекет',
    'contingent.tableContingent.confirm': 'Кетуді ресімдеу',
    'contingent.tableContingent.educationForm': 'Оқыту нысанын өзгерту',
    'contingent.disposalModal.header': 'Кетуді кері қайтару',
    'contingent.disposalModal.date': 'Кетуді жою күні',
    'contingent.disposalModal.reason': 'Кетуді жою себебі',
    'contingent.disposalModal.confirm': 'Кетуді ресімдеу',
    'contingent.disposalModal.cancel': 'Жою',
    'contingent.disposalTable.status': 'Мәртебесі',
    'contingent.disposalTable.canceled': 'Кері қайтарылды',
    'contingent.disposalTable.inProgress': 'Орындалуда',
    'contingent.disposalTable.action': 'Әрекет',
    'contingent.disposalTable.deny': 'Кетуді кері қайтару',
    'contingent.disposalTable.orgId': 'Ұйым ID',
    'contingent.disposalTable.name': 'Атауы',
    'contingent.disposalTable.location': 'Елді мекен',
    'contingent.disposalTable.phone': 'Телефоны',
    'contingent.disposalTable.reason': 'Кері қайтару себебі',
    'contingent.disposalTable.info':
      '"Кері қайтарылды" статусы бар оқушыларды кері қайтарып қайтадан кетуді рәсімдеу қажет',
    'contingent.disposalTable.importByES':
      'Контингент ата-анасының өтінімі бойынша 1-сыныпқа қабылдау үшін «электрондық үкімет» веб-порталы арқылы келіп түсті',
    'contingent.integrations.title': 'Деректерді жүктеп алу',
    'contingent.integrations.personal': 'Дербес деректер',
    'contingent.integrations.labor': 'Жұмысқа орналасуы бойынша деректер',
    'contingent.integrations.socAssist': 'Әлеуметтік көмек алу туралы деректер',
    'contingent.integrations.eisz': 'Денсаулық жағдайы туралы деректер',
    'contingent.integrations.disability': 'Мүгедектік бойынша деректер',
    'contingent.enterModal.header': 'Қабылдауды рәсімдеу',
    'contingent.enterModal.date': 'Қабылдау күні',
    'contingent.enterModal.number': 'Бұйрықтың нөмірі',
    'contingent.enterModal.confirm': 'Қабылдауды рәсімдеу',
    'contingent.enterModal.cancel': 'Жою',
    'contingent.enterTable.confirm': 'Қабылдауды рәсімдеу',
    'contingent.notDisplacedModal.header': 'Ауыстыруды рәсімдеу',
    'contingent.notDisplacedModal.course': 'Курс',
    'contingent.notDisplacedModal.grade': 'Параллель',
    'contingent.notDisplacedModal.gradeLetter': 'Литера',
    'contingent.notDisplacedModal.confirm': 'Ауыстыруды рәсімдеу',
    'contingent.notDisplacedModal.cancel': 'Жою',
    'contingent.notDisplacedModal.transition': 'Көшіруді рәсімдегіңіз келеді ме? ',
    'contingent.notDisplacedModal.selectedCount': 'Таңдалғандар саны: ',
    'contingent.notDisplacedTable.confirm': 'Көшіру',
    'contingent.notDisplacedTable.info':
      'Аталмыш "Көшіру" қосымшасында барлық көрсеткіштері толтырылған оқушылар тізімі көрсетіледі',
    'contingent.motionReleaseModal.header': 'Шығаруды рәсімдеу',
    'contingent.motionReleaseModal.released': 'Шығарылды',
    'contingent.motionReleaseModal.date': 'Кету күні',
    'contingent.motionReleaseModal.type': 'Диплом түрі',
    'contingent.motionReleaseModal.serial': 'Диплом сериясы',
    'contingent.motionReleaseModal.diplomaNumber': 'Диплом №',
    'contingent.motionReleaseModal.number': 'Кету бұйрығының нөмірі',
    'contingent.motionReleaseModal.employment': 'Жұмысқа орналасуы',
    'contingent.motionReleaseModal.upload': 'Диплом көшірмесін жүктеу',
    'contingent.motionReleaseModal.confirm': 'Шығаруды ресімдеу',
    'contingent.motionReleaseModal.cancel': 'Жою',
    'contingent.motionReleaseModal.disposalCountry': 'Кету елі',
    'contingent.motionReleaseTable.form': 'Сынып',
    'contingent.motionReleaseTable.confirm': 'Шығаруды ресімдеу',
    'contingent.motionReleaseTable.info':
      'Аталған "Түлек" қосымшасында "Диплом сериясы және №" көрсеткіші және барлық міндетті көрсеткіштерi толтырылған студенттер тізімі көрсетіледі',
    'contingent.motionReleaseTable.infoType02':
      'Аталған "Түлек" қосымшасында "Орта білім беру туралы аттестат (Аттестат сериясы) /Негізгі білім беру туралы куәлік (аттестат сериясы)", "Орта білім беру туралы аттестат (Аттестат номері /Негізгі білім беру туралы куәлік (аттестат номері)", "Орта білім беру туралы аттестат (Берілу күні) /Негізгі білім беру туралы куәлік (Берілу күні)" және барлық міндетті көрсеткіштерi толтырылған оқушылар тізімі көрсетіледі',
    'contingent.educationFormModal.header': 'Оқыту нысанын өзгерту',
    'contingent.educationFormModal.course': 'Курс',
    'contingent.educationFormModal.grade': 'Параллель',
    'contingent.educationFormModal.gradeLetter': 'Литера',
    'contingent.educationFormModal.confirm': 'Оқыту нысанын өзгерту',
    'contingent.educationFormModal.cancel': 'Жою',
    'contingent.educationFormModal.close': 'Закрыть',
    'contingent.educationFormModal.text': 'Оқыту нысанын өзгерткіңіз келеді ме?',
    'contingent.educationFormModal.error': 'Таңдалған жазбалардың саны көп болмауы керек',
    'contingent.educationFormModal.selectedCount': 'Таңдалғандар саны: ',
    'contingent.educationFormTable.confirm': 'Оқыту нысанын өзгерту',
    'contingent.educationFormTable.placeholder': 'Оқыту нысаны',
    'contingent.educationFormTable.showSelectionNote':
      'Таңдауды орындау үшін «Параллель» және «Литераны» таңдаңыз',
    'contingent.columnsTable.contingentID': 'Контингент ID',
    'contingent.columnsTable.iin': 'ЖСН',
    'contingent.columnsTable.statistic': 'Толтыру статистикасы',
    'contingent.columnsTable.lastName': 'Тегі',
    'contingent.columnsTable.firstName': 'Аты',
    'contingent.columnsTable.middleName': 'Әкесінің аты',
    'contingent.columnsTable.group': 'Топ',
    'contingent.columnsTable.groupName': 'Топтың атауы',
    'contingent.columnsTable.parallel': 'Параллель',
    'contingent.columnsTable.litera': 'Литера',
    'contingent.columnsTable.course': 'Курс/Жыл',
    'contingent.columnsTable.groupCode': 'Топтың коды',
    'contingent.contingent': 'Контингент',
    'contingent.graduates': 'Түлектер',
    'contingent.retired': 'Кеткендер',
    'contingent.forRetire': 'Кетуге тиіс',
    'contingent.forIncoming': 'Келуі тиіс',
    'contingent.add': 'Контингент қосу',
    'contingent.issue': 'Шығаруды, көшіруді немесе қабылдауды ресімдеу',
    'contingent.awaitingRelease': 'Шығарылым күтілуде',
    'contingent.personal': 'Дербес деректер',
    'contingent.delete': 'Өшіру',
    'contingent.save': 'Сақтау',
    'contingent.error.loadCount': 'Контингент санының деректерін алу мүмкін болмады',
    'contingent.exportExcel': 'Excel-ге жүктеу',
    'contingent.exportExcel.success': 'Excel-ге сәтті жүктелді',
    'contingent.exportExcel.error': 'Excel-ге жүктеу қатесі',
    'contingent.id': 'Контингент ID',
    'contingent.entries': '{total}-ден {mapped} жазбаны көрсетеді',
    'contingent.entriesSelected': '{total} арасынан {mapped} таңдалған',
    'contingent.entered': 'Контингент сәтті қабылданды',
    'contingent.notEntered': 'Контингентті қабылдау мүмкін емес',
    'contingent.transited': 'Контингент сәтті шығарылды',
    'contingent.notTransited': 'Контингентті шығару мүмкін емес',
    'contingent.disposed': 'Контингент сәтті кері қайтарылды',
    'contingent.notDisposed': 'Контингентті қайтарып алу мүмкін емес',
    'contingent.approved': 'Контингент сәтті қабылданды',
    'contingent.notApproved': 'Контингентті қабылдау мүмкін емес',
    'contingent.withdrawed': 'Контингент сәтті кері қайтарылды',
    'contingent.notWithdrawed': 'Контингентті қабылдамау мүмкін емес',
    'contingent.graduated': 'Контингент сәтті шығарылды',
    'contingent.notGraduated': 'Контингентті шығару мүмкін емес',
    'contingent.internalTransited': 'Көшіру сәтті орындалды',
    'contingent.notInternalTransited': 'Контингентті көшіру мүмкін емес',
    'contingent.successChangeEducationForm': 'Оқу нысаны өзгерту мүмкін емес',
    'contingent.errorChangeEducationForm': 'Не удалось изменить форму обучения',
    'contingent.attention': 'Назар аударыңыз',
    'contingent.needSave': 'Паспортты сақтау қажет',
    'contingent.createdAt': 'Жазба жасалды',
    'contingent.updatedAt': 'Oқу туралы деректер өзгертілді',
    'contingent.studentUpdatedAt': 'Дербес деректер өзгертілді',
    'contingent.noData': 'Деректер жоқ',
    'contingent.warning.otherSpecialty':
      'Қабыллау тек басқа мамандыққа немесе басқа білім деңгейге мүмкүн',

    'createSchool.fields.yes': 'Иә',
    'createSchool.fields.no': 'Жоқ',
    'createSchool.fields.bin': 'БСН',
    'createSchool.fields.iin': 'ЖСН',
    'createSchool.form.hasBin': 'БСН/ЖСН бар болуы',
    'createSchool.form.bin': 'Білім беру ұйымының БСН',
    'createSchool.form.iin': 'ЖСН',
    'createSchool.form.request': 'Деректерді ЗТ МДҚ-нан сұрау',
    'createSchool.form.request.iin': 'Деректерді ЖТ МДҚ-нан сұрау',
    'createSchool.form.kkName': 'Қазақ тіліндегі атауы',
    'createSchool.form.kkShortName': 'Қазақ тіліндегі қысқаша атауы',
    'createSchool.form.kkFullName': 'Қазақ тіліндегі толық атауы',
    'createSchool.form.kkLatinName': 'Қазақ тіліндегі атауы (латын)',
    'createSchool.form.kkLatinShortName': 'Қазақ тіліндегі қысқаша атауы (латын)',
    'createSchool.form.kkLatinFullName': 'Қазақ тіліндегі толық атауы (латын)',
    'createSchool.form.ruName': 'Орыс тіліндегі атауы',
    'createSchool.form.ruShortName': 'Орыс тіліндегі қысқаша атауы',
    'createSchool.form.ruFullName': 'Орыс тіліндегі толық атауы',
    'createSchool.form.enName': 'Ағылшын тіліндегі атауы',
    'createSchool.form.enShortName': 'Ағылшын тіліндегі қысқаша атауы',
    'createSchool.form.enFullName': 'Ағылшын тіліндегі толық атауы',
    'createSchool.form.uLocality': 'Заңды елді мекені',
    'createSchool.form.locality': 'Елді мекен',
    'createSchool.form.affiliationMIO': 'Тиістілігі',
    'createSchool.form.affiliation': 'Ведомстволық тиістілік',
    'createSchool.form.typeOO': 'Білім беру ұйымының типі',
    'createSchool.form.subtypesOO': 'Білім беру ұйымдарының түрлері',
    'createSchool.form.ownershipForm': 'Меншік түрі',
    'createSchool.form.OOByTerritory': 'Ұйымның территориялық тиістілігі',
    'createSchool.form.legalForm': 'Ұйымдастрыу-құқықтық тұрі',
    'createSchool.form.kkAddress': 'Қазақ тіліндегі заңды мекен-жайы',
    'createSchool.form.ruAddress': 'Орыс тіліндегі заңды мекен-жайы',
    'createSchool.form.mainFounders': 'Негізгі құрылтайшылар',
    'createSchool.form.dateOpen': 'Білім беру ұйымының ашылған күні',
    'createSchool.form.dateClose': 'Білім беру ұйымының жабылу күні',
    'createSchool.form.save': 'Сақтау',
    'createSchool.create': 'Білім беру ұйымын ашу',
    'createSchool.edit': 'Білім беру ұйымдарын өңдеу',
    'createSchool.view': 'Білім беру ұйымын қарау',
    'createSchool.form.map': 'Картадағы координат (енділік, ұзындық)',
    'createSchool.form.coords': 'Координаттар белгіленбеген',
    'createSchool.form.partType': 'Елді мекеннің құрамдас бөлігінің түрі',
    'createSchool.form.partName': 'Елді мекеннің құрамдас бөлігінің атауы',
    'createSchool.form.houseNumber': 'Үй №',
    'createSchool.form.subtypesFlk': '"білім деңгейі бойынша" толтыру үшін міндетті өріс',
    'createSchool.form.changeTypeCodeNote':
      'Білім беру ұйымының типін өзгерту көрсеткіштерге әсер етеді.',

    'filterForTerritory.search': 'Ұйымдар бойынша іздеу',
    'filterForTerritory.loading': 'Жүктеу...',
    'filterForTerritory.choose': 'Ұйым тпін таңдаңыз',

    'forms.save': 'Сақтау',
    'forms.required': 'Бұл толтыру үшін міндетті өріс',
    'forms.wrongFormatIin': 'Бұл толтыру үшін міндетті өріс',
    'forms.delete': 'Өшіру',
    'forms.yes': 'Иә',
    'forms.no': 'Жоқ',
    'forms.1': 'Иә',
    'forms.2': 'Жоқ',

    'modalDelete.title': 'Өшіру',
    'modalDelete.question': 'Өшіргіңіз келе ме?',
    'modalDelete.delete': 'Өшіру',
    'modalDelete.cancel': 'Жою',

    'personal.id': 'ID персонала',
    'personal.integrations.loadData': 'Деректерді жүктеп алу:',
    'personal.integrations.personal': 'Дербес деректер',
    'personal.integrations.disability': 'Мүгедектік бойынша деректер',
    'personal.integrations.labor': 'Жұмысқа орналасуы бойынша деректер',
    'personal.columnsTable.personalID': 'Персонал ID',
    'personal.columnsTable.iin': 'ЖСН',
    'personal.columnsTable.statistic': 'Толтыру статистикасы',
    'personal.columnsTable.lastName': 'Тегі',
    'personal.columnsTable.firstName': 'Аты',
    'personal.columnsTable.middleName': 'Әкесінің аты',
    'personal.columnsTable.jobPost': 'Лауазымы',
    'personal.columnsTable.birthDate': 'Туған күні',
    'personal.layOffModal.title': 'Жұмыстан босату',
    'personal.layOffModal.number': 'Бұйрықтың нөмірі',
    'personal.layOffModal.date': 'Босату күні',
    'personal.layOffModal.reason': 'Босату себебі',
    'personal.layOffModal.order': 'Еңбек шартын бұзу туралы бұйрық',
    'personal.layOffModal.confirm': 'Жұмыстан босату',
    'personal.layOffModal.cancel': 'Жою',
    'personal.table.action': 'Әрекет',
    'personal.table.confirm': 'Жұмыстан босату',
    'personal.personal': 'Қызметкерлер',
    'personal.add': 'Қызметкерді қосу',
    'personal.retired': 'Кеткендер',
    'personal.error.loadCount': 'Қызметкерлер саны бойынша деректерді алу мүмкін болмады',
    'personal.updatedAt':'Жұмыс туралы деректер өзгертілді',

    'personData.personal.dosie': 'Қызметкер деректері',
    'personData.dosie': 'Оқушы деректері',
    'personData.gbdflData': 'ЖТ МДҚ деректері',
    'personData.iin': 'ЖСН',
    'personData.addIin': 'ЖСН қосу',
    'personData.correctIIN': 'ЖСН түзеу',
    'personData.correctingIIN': 'ЖСН түзеу',
    'personData.addTitle': 'ЖСН қосу',
    'personData.addPlaceholder': 'ЖСН енгізіңіз',
    'personData.addCancel': 'Жою',
    'personData.fio': 'Аты-жөні',
    'personData.requestGBDFL': 'ЖТ МДҚ-нан деректерді сұрату',
    'personData.signAndAdd': 'Қол қою және қосу',
    'personData.signAndSend': 'Қол қою және жіберу',
    'personData.lastName': 'Тегі',
    'personData.firstName': 'Аты',
    'personData.middleName': 'Әкесінің аты',
    'personData.birthDate': 'Туған күні',
    'personData.sex': 'Жынысы',
    'personData.correctIINSuccess': 'ЖСН сәтті өзгертілді',
    'personData.addIINSuccess': 'ЖСН сәтті енгізілді',
    'personData.addIINFail': 'ЖСН енгізу сәтсіз аяқталды',
    'personData.correctIINFail': 'ЖСН өзгеру сәтсіз аяқталды',
    'personal.teacherID': 'Қызметкер ID:',
    'personData.applicationId': 'Өтініш номері',

    'reestr.deleteModal.title': 'Өшіру',
    'reestr.deleteModal.question': 'Өшіргіңіз келе ме?',
    'reestr.deleteModal.confirm': 'Өшіру',
    'reestr.deleteModal.cancel': 'Жою',
    'reestr.filterForm.name': 'Атауы',
    'reestr.filterForm.typeOO': 'Білім беру ұйымының типі',
    'reestr.filterForm.locality': 'Елді мекен',
    'reestr.filterForm.search': 'Іздеу',
    'reestr.table.id': 'ID',
    'reestr.table.name': 'Атауы',
    'reestr.table.typeOO': 'Білім беру ұйымының типі',
    'reestr.table.area': 'Облыс',
    'reestr.table.region': 'Аудан',
    'reestr.table.locality': 'Елді мекен',
    'reestr.table.action': 'Әрекеттер',
    'reestr.table.delete': 'Өшіру',
    'reestr.table.actingDate': 'Ашылу күні',
    'reestr.table.closedDate': 'Жабылу күні',
    'reestr.table.deletedDate': 'Жойылу күні',
    'reestr.reestr': 'Білім беру ұйымдарының тізімі',
    'reestr.create': 'Білім беру ұйымын ашу',
    'reestr.acting': 'Актерлік',
    'reestr.closed': 'Жабық',
    'reestr.deleted': 'Жойылған',

    'controlToken.expiredAt': 'Сеанстың аяқталуына',
    'controlToken.second': 'сек',
    'controlToken.stay': 'Жүйеде қалу',

    'GBDFLInn.placeholder': 'КК.АА.ЖЖЖЖ',
    'GBDFLInn.hasIIN': 'ЖСН бар болуы',
    'GBDFLInn.yes': 'Иә',
    'GBDFLInn.no': 'Жоқ',
    'GBDFLInn.request': 'ЖТ МДҚ-нан деректерді сұрату қажет',
    'GBDFLInn.iin': 'ЖСН',
    'GBDFLInn.lastName': 'Тегі',
    'GBDFLInn.firstName': 'Аты',
    'GBDFLInn.middleName': 'Әкесінің аты',
    'GBDFLInn.birthDate': 'Туған күні',
    'GBDFLInn.sex': 'Жынысы',
    'GBDFLInn.male': 'Ер',
    'GBDFLInn.female': 'Қыз',
    'GBDFLInn.citizenship': 'Азаматтығы',
    'GBDFLInn.nationality': 'Ұлты',
    'GBDFLInn.rka': 'МТК',
    'GBDFLInn.add': 'Қосу',
    'GBDFLInn.addAndSign': 'Қол қою және қосу',
    'GBDFLInn.errorSign': 'Қол қою сәтсіз аяқталды',
    'GBDFLInn.choose': 'Таңдау',
    'GBDFLInn.close': 'Жабу',
    'GBDFLInn.cancel': 'Болдырмау',
    'GBDFLInn.notMatches': 'Тізімде сәйкес адамдар жоқ',
    'GBDFLInn.regTimeAddress': 'Уақытша тіркеу мекенжайы',
    'GBDFLInn.regAddress': 'Тіркелген мекенжайы',
    'GBDFLInn.duplicates': 'ҚР ӘМ "Жеке тұлғалар" МДБ мынадай сәйкестіктер табылды',
    'GBDFLInn.noticeContingent':
      'НАЗАР АУДАРЫҢЫЗ! ЖСН жоқ контингентті қосу егер контингент шетелдік немесе азаматтығы жоқ адам болса ғана рұқсат етіледі.',
    'GBDFLInn.noticePersonal':
      'НАЗАР АУДАРЫҢЫЗ! ЖСН жоқ қызметкерді қосу егер қызметкер шетелдік немесе азаматтығы жоқ адам болса ғана рұқсат етіледі.',

    'mioTerritory.management': 'Басқарма төлқұжатына өту',
    'mioTerritory.agency': 'Жергілікті атқарушы орган',

    'motion.release': 'Түлек',
    'motion.moving': 'Көшіру',
    'motion.notMoved': 'Көшірілмегендер',
    'motion.moved': 'Көшірілген',
    'motion.reception': 'Қабылдау',

    'territory.affiliation': 'Аумақтық үлестес',
    'territory.name': 'Атауы',

    'treeTable.noData': 'Нет данных',
    'treeTable.loading': 'Идет загрузка...',

    'utils.dict.cantLoad': 'Анықтамалықты жүктеу мүмкін болмады',
    'utils.dict.withCode': 'Кодпен',

    'invalidIins.contingentMessage':
      'Сіздің ұйымыңыздың контингентінде қате ЖСН анықталды. Түзету үшін басыңыз',
    'invalidIins.personnelMessage':
      'Сіздің ұйымыңыздың персоналында қате ЖСН анықталды. Түзету үшін басыңыз',
    'invalidIins.fixButton': 'Түзету',
    'invalidIins.contingentListTitle': 'Контингенттің қате ЖСН тізімі',
    'invalidIins.personnelListTitle': 'Персоналдың қате ЖСН тізімі',
    'invalidIins.table.id': '№',
    'invalidIins.table.iin': 'ЖСН',
    'invalidIins.table.lastName': 'Тегі',
    'invalidIins.table.firstName': 'Аты',
    'invalidIins.table.middleName': 'Әкесінің аты',
    'invalidIins.table.birthDate': 'туған күні',
    'invalidIins.invalidIinFixTitle': 'Дұрыс ЖСН енгізу үшін нысан',
    'invalidIinFixForm.formValidation.required': 'Міндетті түрде толтырылатын өріс',
    'invalidIinFixForm.formValidation.wrongIin': 'ЖСНнің форматы дұрыс емес',
    'invalidIinFixForm.lastName': 'Тегі',
    'invalidIinFixForm.firstName': 'Аты',
    'invalidIinFixForm.middleName': 'Әкесінің аты',
    'invalidIinFixForm.birthDate': 'туған күні',
    'invalidIinFixForm.fetchGBDFLButton': 'ЖТ МДҚ-дан деректерді сұрату',
    'invalidIinFixForm.saveButton': 'Сақтау',
    'invalidIinFixForm.cancelButton': 'Болдырмау',
    'invalidIinFixForm.iinError': 'Көрсетілген ЖСН бойынша деректерді жүктеу қатесі',
    'invalidIinFixForm.iinNotFound': 'Көрсетілген ЖСН бойынша деректер табылған жоқ',

    'ducks.netConnection': 'Өтінеміз, интернетке қолжетілімдігін тексеріңіз',
    'ducks.serverError': 'Сервер қатесі',
    'ducks.registrationSuccess': 'Тіркеу сәтті өтті!',
    'ducks.passChanged': 'Құпия сөзді ауыстыру сәтті өтті!',
    'ducks.linkSent': 'Құпия сөзді өзгертуге арналған сілтемесі бар хат жіберілді',
    'ducks.loadDataError': 'Деректерді жүктеу қатесі',
    'ducks.error': 'Қате',
    'ducks.dataSaved': 'Деректер сәтті сақталды',
    'ducks.dataNotSaved': 'Деректерді сақтау мүмкін болмады',
    'ducks.dataUpdated': 'Деректер сәтті жаңартылды',
    'ducks.contingentRemoved': 'Контингент сәтті өшірілді',
    'ducks.contingentCreated': 'Контингент сәтті тіркелді',
    'ducks.dataNotFound': 'Деректер табылмады',
    'ducks.dataNotFoundElicence': 'Е-лицензиялау сервисі бойынша мәліметтер табылмады',
    'ducks.cantLoadMIOCatalog': '"ЖАО-ның тиесілігі" анықтамасын жүктеу мүмкін емес',
    'ducks.cantLoadOOSubtypesCatalog':
      '«Білім беру ұйымдарының түрлері» анықтамалығын жүктеу мүмкін болмады',
    'ducks.bin': 'БСН',
    'ducks.iin': 'ЖСН',
    'ducks.notFoundInGBDUL': 'ЗТ МДҚ-нан табылған жоқ',
    'ducks.cantLoadFromGBDUL': 'ЗТ МДҚ-нан жүктеу мүмкін болмады',
    'ducks.cantLoadFromGBDFL': 'ЖТ МДҚ-нан жүктеу мүмкін болмады',
    'ducks.cantLoadDataForEdit': 'Өңдеу үшін деректерді жүктеу мүмкін болмады',
    'ducks.notFound': 'Табылмады',
    'ducks.cantLoadPassportHistory': 'Паспорт тарихын жүктеу қатесі',
    'ducks.microTerritory.cantLoadData': 'Деректерді жүктеу қатесі',
    'ducks.microTerritory.cantAddAddress': 'Деректерді қосу қатесі',
    'ducks.microTerritory.cantDeleteAddress': 'Деректерді өшіру қатесі',
    'ducks.notIncludesInThisOO':
      'Аталған білім беру ұйымында мұндай ЖСН-мен пайдаланушы жоқ',
    'ducks.cantLoadJobPostsCatalog': 'Лауазым анықтамалығын жүктеу мүмкін болмады',
    'ducks.cantLoadPersonalList': 'Қызметкерлер тізімін жүктеу мүмкін болмады',
    'ducks.getInvalidIinsError': 'Қате ЖСН тізімін жүктеу мүмкін емес',
    'ducks.saveIinSuccess': 'ЖСН сәтті өзгертілді',
    'ducks.saveIinError': 'ЖСН өзгерту мүмкін емес',
    'ducks.employeeDismissed': 'Қызметкер сәтті жұмыстан босатылды',
    'ducks.personalCreated': 'Қызметкер сәтті тіркелді',
    'ducks.personalRemoved': 'Қызметкер сәтті өшірілді',
    'ducks.already.staff':
      'Аталған қызметкер штаттық қызметкер ретінде басқа ұйымда тіркелген',
    'ducks.reestr.cantLoadSchoolList': 'Мектеп тізімін жүктеу мүмкін болмады',
    'ducks.reestr.cantDeleteOO': 'Ұйымды өшіру мүмкін болмады',
    'ducks.reestr.organization': 'Ұйым',
    'ducks.reestr.removed': 'Сәтті өшірілді',
    'ducks.territory.cantLoadOOTypes': 'Кате, ұйым типтерін жүктеу мүмкін болмады',
    'ducks.trajectory.cantFindByFullName':
      'Аты мен туған күні бойынша табу мүмкін болмады',
    'ducks.trajectory.cantFindByIIN': 'ЖСН бойынша табу мүмкін болмады',
    'ducks.trajectory.notIncludes': 'Аталған ұйымда білім алушы табылған жоқ',
    'ducks.users.cantLoadUsersList': 'Пайдаланушылар тізімін жүктеу кезінде қате',
    'ducks.users.userAdded': 'Пайдаланушыны қосу сәтті өтті!',
    'ducks.cantLoadContingentList': 'Контингент тізімін жүктеу мүмкін болмады',
    'ducks.statisticsError': 'Толтырылған көрсеткіштердің санын алу мүмкін болмады',
    'ducks.alreadyStudying': 'Аталған білім алушы контингентте тіркелген',
    'ducks.personal.already.exists1': 'Мұндай ЖСН бар қызметкер жүйеде бар, ID: ',
    'ducks.personalAlreadyExist': 'Аталған қызметкер ұйымда тіркелген',
    'ducks.contingent.already.exists1': 'Мұндай ЖСН бар контингент жүйеде бар, ID: ',
    'ducks.contingent.already.exists2': 'ЖСН қосу мүмкін емес',
    'ducks.alreadyOtherWaitingOut': 'Бұл білім алушы контингенттің кеткендер бөлімінде',
    'ducks.alreadyWaitingIn':
      'Аталмыш контингент «Келуі тиіс» қосымша бетінде бар, қабылдауды рәсімдеу үшін қосымша бетке өтіңіз',
    'ducks.alreadyWaitingOut': 'Бұл контингент "кету" мәртебесімен тіркелген',

    'utils.fullNameOrShortName': 'Ұйымның аты толтырылмаған',

    'exportExcel.title': 'Excel-ге экспорттау',
    'exportExcel.success': 'Excel-ге экспорттау сәтті аяқталды',
    'exportExcel.error': 'Excel-ге экспорттау қатесі',
    'exportExcel.cancel': 'Excel-ге экспорттау тоқтатылды',
    'exportExcel.tooltip':
      '"Дербес деректер және оларды қорғау туралы" ҚР Заңына сәйкес дербес деректерді үшінші тұлғаларға беруге ТЫЙЫМ салынады',

    'financing.dictName': 'Каталог атауы',
    'financing.actions': 'Әрекеттер',
    'financing.dictSaved': 'Каталог сәтті сақталды!',

    'dictManag.ADD_item': 'Элемент қосу',
    'dictManag.REMOVE_item': 'Элеметті өшіру',
    'dictManag.CHANGE_item': 'Элементті өңдеу',
    'dictManag.entry_year': 'Түскен жылы',
    'dictManag.college_type': 'ЖОО Типі',
    'dictManag.education_level': 'Білім деңгейі',
    'dictManag.specialty': 'Мамандығы және сыныптауышы (негізгі)',
    'dictManag.grant_cost_per_month': 'Айына 1 (бір) білім алушының оқу құны',
    'dictManag.grant_cost_per_year': 'Оқу жылы ішінде 1 (бір) білім алушының оқу құны',
    'dictManag.education_program_group': 'Білім беру бағдарламаларының топтары',
    'dictManag.credit_cost': '1 (бір) кредит құны',
    'dictManag.ADD': 'Қосу',
    'dictManag.REMOVE': 'Өшіру',
    'dictManag.CHANGE': 'Редакциялау',
    'dictManag.save': 'Сақтау',
    'dictManag.cancel': 'Болдырмау',
    'dictManag.ruName': 'Орыс тілінде атауы',
    'dictManag.kkName': 'Қазақ тілінде атауы',
    'dictManag.description': 'Сипаттамасы',
    'dictManag.editDict': 'Каталогты өңдеу',
    'dictManag.elementSaved': 'Элемент сәтті сақталды!',
    'dictManag.duplicate': 'Элементті қайталау!',
    'dictManag.notZero': 'Мән нөлден басталмауы керек',
    'dictManag.notNegative': 'Мәні теріс болмауы керек',

    page404_oops: 'Бет табылмады!',
    page404_notFound:
      'Мүмкін сіз қате мекен-жай енгізген боларсыз, <br/> немесе бет жойылды.',
    tableFilter_min3symbol: 'кем дегенде 3 таңба',
    notice_transition: 'Көшірілетін тізімде толтырылмаған параллельі бар контингент бар',

    contingent_retiredAction: 'Шығаруды кері қайтарып алу',
    contingent_graduatesAction: 'Шығарылымды кері қайтарып алу',

    contingent_cancelLeaveSchool: 'Контингенттің шығуын кері қайтарып алу',
    contingent_cancelLeaveSchoolConfirm: 'Шығаруды кері қайтарып алу',
    contingent_cancelLeaveSchoolCancel: 'Болдырмау',
    contingent_cancelLeaveSchoolNotice: 'Шығуды кері қайтарып алғыңыз келеді',
    contingent_canceledLeaveSchool: 'Контингенттің кетуі',
    contingent_canceledLeaveSchoolError:
      'Контингенттің шығуын кері қайтарып алу мүмкін емес',
    contingent_canceledLeaveSchoolError14:
      'Шығуды кері қайтарып алу мүмкін емес, өйткені кеткен күннен бастап 14 күннен астам уақыт өтті',

    contingent_cancelGraduated: 'Контингент шығарылымын кері қайтарып алу',
    contingent_cancelGraduatedConfirm: 'Шығарылымды кері қайтарып алу',
    contingent_cancelGraduatedCancel: 'Болдырмау',
    contingent_cancelGraduatedNotice: 'Шығарылымды қайтарып алғыңыз келеді',
    contingent_cancelGraduatedSuccess: 'Контингент шығарылымы сәтті кері қайтарылды',
    contingent_cancelGraduatedError: 'Контингент шығарылымын қайтарып алу мүмкін емес',
    contingent_cancelGraduatedError14:
      'Шығарылымды кері қайтарып алу мүмкін емес, өйткені шығарылған күннен бастап 14 күннен астам уақыт өтті',
    personal_cancelRetired: 'Жұмыстан шығарудың күшін жою',
    personal_cancelRetiredConfirm: 'Шығаруды болдырмау',
    personal_cancelRetiredCancel: 'Болдырмау',
    personal_cancelRetiredNotice: 'Жұмыстан шығаруды тоқтатқыңыз келеді',
    personal_cancelRetiredSuccess: 'Қызметкерлерді жұмыстан босату сәтті тоқтатылды',
    personal_cancelRetiredError: 'Қызметкерлерді жұмыстан шығаруды болдырмау мүмкін емес',
    personal_cancelRetiredError14:
      'Жұмыстан босатудан бас тарту мүмкін емес, өйткені жұмыстан босатылған күннен бастап 14 күннен астам уақыт өтті',
    contingentDucks_errorCrossQuestion1: 'Метадеректердегі қате, көрсеткіштер',
    contingentDucks_errorCrossQuestion2: ' метадеректерде кездеседі ',
    contingentDucks_orgName: 'Білім беру ұйымының атауы',

    personalDucks_errorCrossQuestion1: 'Ошибка в мета данных, показатели ',
    personalDucks_errorCrossQuestion2: ' встречаются в мета данных ',
    accordion_next: 'Толығырақ',
    flkRuleJs_error: 'ФЛБ-ға өзгерістер енгізілді. Бетті жаңарту қажет.',
    ContingentTables_phoneNumber: 'Жұмыс телефоны',
    contingentView_deleteError: 'Кетуі рәсімделген оқушыны өшіруге болмайды',
    columnFilter_filled: 'Толтырылған',
    columnFilter_notFilled: 'Толтырылмаған',

    'faq.question': 'Сұрақ:',
    'faq.answer': 'Жауап:',
    'faq.title': 'ЖИІ ҚОЙЫЛАТЫН СҰРАҚТАР',

    'faq.1.01.q1':
      'Білім беру ұйымының атауы мен түрі туралы мәліметтерді қайдан көруге болады?',
    'faq.1.01.a1': 'Төлқұжатта ұйымның деректері бөлімін ашу (кеңейту)',
    'faq.1.01.q2': 'Ұйымның атауы өзгерді оны қалай өңдеуге болады?',
    'faq.1.01.a2':
      'Білім беру ұйымының атауын (білім беру ұйымының атауы, қазақ тіліндегі толық атауы, қазақ тіліндегі қысқаша атауы, орыс тіліндегі толық атауы, орыс тіліндегі қысқаша атауы) білім беру ұйымын құру кезінде білім басқармасы немесе бөлімі (бұдан әрі - ЖАО) толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет',
    'faq.1.01.q3':
      'Неліктен "тіркеу" бөліміндегі "білім беру ұйымдарының түрлері", "білім беру ұйымының БСН", "меншік нысаны", "аумақтық тиесілілігі бойынша ұйым", "ұйымдық - құқықтық нысан", "құрамдас бөліктің типі", " үйдің №", "негізгі құрылтайшылар", "білім беру ұйымының ашылу күні" және "білім беру ұйымының жабылу күні" көрсеткіштері редакциялауға қолжетімсіз:? Бұл көрсеткіштерде деректерді қалай түзетуге болады?',
    'faq.1.01.a3':
      'Бұл көрсеткіштерді білім басқармасы немесе білім бөлімі (бұдан әрі - ЖАО) білім беру ұйымын құру кезінде толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет.',
    'faq.1.01.q4':
      'Почему в разделе «Регистрационные сведения» выходит ошибка (окрашивается красным цветом) по показателям: Факс (код+номер), Рабочий телефон (код+номер), Сотовый телефон (номер)?',
    'faq.1.01.a4':
      'На показателях установлен форматно-логический контроль, номер должен состоять из 11 цифр, не содержать символы и начинаться с 8. Удалите все символы тире, скобки, знак плюс и т.п., проверьте что бы номер начинался с 8 и содержал только 11 цифр.',
    'faq.1.01.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.01.a5':
      'Көрсеткіш "ғимараттар туралы мәліметтер" көрсеткішіне енгізілген деректерден автоматты түрде есептеледі.',
    'faq.1.01.q6':
      'Компьютерлендіру бөлімінде бұрын компьютерлер бойынша көрсеткіштер толтырылды, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.01.a6':
      'Егер "компьютерлердің болуы" көрсеткіші жасырынып және паспорт бөлімі сақталғаннан кейін тазаланса, "компьютерлер саны" көрсеткіші жасырылады. ББ компьютерлер болған жағдайда "компьютерлердің болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын көбіктенген болса және паспорт сақталмаса, олар көрсеткіштерде көрсетіледі.',
    'faq.1.01.q7':
      '"Компьютерлендіру" бөлімінде бұрын Интернет жылдамдығы бойынша көрсеткіштер толтырылған болатын, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.01.a7':
      '"Ғаламтордың  бар болуы" көрсеткішінде ЖОҚ мәні таңдалса Келісім "шарт бойынша интернеттің жылдамдығы", "Факт бойынша интернеттің жылдамдығы" "Интернет (нүкте) желісіне рұқсаттың болуы, бірлік" көрсеткіштері сақтағаннан кейін жасырылады және тазаланады. БҰ Интернет болған жағдайда "Ғаламтордың  бар болуы" көрсеткішінде "ИӘ" таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.01.q8':
      'Неліктен "топтың" көрсеткішіндегі "білім беру процесі туралы негізгі мәліметтер" бөлімінде "топтың оқыту тілі" элементінде "деректер жоқ "шығады?',
    'faq.1.01.a8':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "топтың оқыту тілі" элементінде "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште бірнеше тіл белгіленбесе, "топтың оқыту тілі" элементінде "деректер жоқ" хабарлама шығады.',
    'faq.1.01.q9':
      'Неліктен "паспорттауға жауапты" бөлімінде көрсеткіштер бойынша қате пайда болады (қызыл түспен боялған): жұмыс телефоны (код+нөмір), ұялы телефон (нөмір)?',
    'faq.1.01.a9':
      'Көрсеткіштерде форматтық-логикалық бақылау орнатылған, Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.01.q11':
      'Қызметкерді қалай жұмыстан шығаруға болады "еңбек шартын бұзу/тоқтату күні", "еңбек шартын бұзу/тоқтату себебі", "еңбек шартын бұзу/тоқтату туралы бұйрық нөмірі" белсенді емес пе?',
    'faq.1.01.a11':
      'Сіз қызметкерді қызметкерлердің басты бетінен жұмыстан шығара аласыз, әр қызметкерге қарама-қарсы жұмыстан босату "батырмасы бар. Жұмыстан шығару процесі пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.01.q12': 'Неге персоналда қызметкердің "негізгі лауазым ставкасы" 0 шығады?',
    'faq.1.01.a12':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "негізгі лауазымның ставкасы" тек 0 болуы мүмкін.',
    'faq.1.01.q13':
      'Персоналда "Ағымдағы мерзімдегі жалпы еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.01.a13':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "жұмысқа қабылдау кезіндегі жалпы жұмыс өтілі" көрсеткішіне енгізілген санға байланысты болады.',
    'faq.1.01.q14':
      'Неліктен персоналда "қазіргі кездегі педагогикалық жұмыс өтілі" көрсеткіші белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.01.a14':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "жұмысқа қабылдау кезіндегі педагогикалық жұмыс өтілі"индикаторына енгізілген санға байланысты болады.',
    'faq.1.01.q15':
      'Неліктен персоналда "оқу тілі" көрсеткіші бос, таңдау элементтері жоқ?',
    'faq.1.01.a15':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште бірнеше тіл белгіленбесе, персоналда "оқыту тілі" көрсеткіші бос болады.',
    'faq.1.01.q16':
      'Неге персоналда қызметкердің көрсеткішінде" қосымша лауазым "элементі" Ставка " 0 шығады?',
    'faq.1.01.a16':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "Ставка" тек 0 болуы мүмкін.',
    'faq.1.01.q17':
      'Қызметкердің жеке деректері өзгерді: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. оларды ҰБДҚ-ға қалай ауыстыруға болады?',
    'faq.1.01.a17':
      'Персонал қосылған кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Бұрын құрылған персоналда, Егер деректер өзгерсе, оны "жеке деректер" батырмасын басқан кезде жаңартуға болады.',
    'faq.1.01.q18': 'Қызметкерлерді Excel-ге қалай түсіруге болады?',
    'faq.1.01.a18':
      'Қызметкерлер бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "қызметкерлер" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" түймесін басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.01.q20':
      'Контингент қосылған кезде оқушы басқа БҰ туралы есепте тұрғаны туралы хабарлама шығады, не істеу керек?',
    'faq.1.01.a20':
      'Басқа білім беру ұйымында тіркелген оқушыны қосқан кезде  байланыс деректері бар хабарлама шығады.  Мекемемен байланыс болмаған жағдайда ЖАО-ға немесе қолдау қызметінің "көмек" бөлімінде деректері көрсетілген қолдау қызметіне хабарласыңыз',
    'faq.1.01.q21': 'Неліктен контингентте "топ" көрсеткішінде "деректер жоқ" шығады?',
    'faq.1.01.a21':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған. "Білім беру процесі туралы негізгі мәліметтер" бөлімінің "тәрбие және оқыту жылы" элементі "меншіктің барлық нысандарындағы топтар туралы деректер"көрсеткішінде топ Атауы үшін таңдалған топтар көрсетіледі. "Топ Атауы" "меншіктің барлық нысандарындағы топтар туралы мәліметтер"көрсеткішіне енгізілген атаулардың біріне сәйкес келетініне көз жеткізіңіз.',
    'faq.1.01.q22':
      'Неліктен контингентте "оқыту тілі "көрсеткішінде "деректер жоқ" деп шығады?',
    'faq.1.01.a22':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе контингентте "оқыту тілі" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.01.q23':
      'Неліктен контингентте Оқылатын шетел тілі 1 және Оқылатын шетел тілі 2 көрсеткіштерінде "деректер жоқ" деп шығады?',
    'faq.1.01.a23':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "топ" көрсеткішінде "Шетел тілі ретінде 1 " және "Шетел тілі ретінде 2" элементтерінде белгіленген тілдер көрсетіледі. "Топ атауы" "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "топ" көрсеткішіне енгізілген атаулардың біріне сәйкес келетініне көз жеткізіңіз',
    'faq.1.01.q24':
      'Неліктен контингентте "осы білім беру ұйымындағы үйірмелер мен секцияларға барады" көрсеткіші "жоқ (бармайды)"ғана шығады?',
    'faq.1.01.a24':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "түрлері бойынша үйірмелер мен спорт секцияларының саны"көрсеткішінде белгіленген тілдер көрсетіледі.',
    'faq.1.01.q25':
      'Неліктен контингентте "мүгедек", "мүгедектік тобы", "мүгедектік себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері белсенді емес? Оларды қалай түзетуге болады?',
    'faq.1.01.a25':
      '"Мүгедек", "мүгедектік тобы", "мүгедектіктің себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері бойынша деректер ҚР Еңбек және халықты әлеуметтік қорғау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "мүгедектік бойынша деректер" батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.01.q26':
      'Неліктен контингентте "Денсаулығы туралы мәлімет" көрсеткіші белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.01.a26':
      '"Денсаулығы туралы мәлімет" көрсеткіші бойынша деректер ҚР Денсаулық сақтау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "денсаулық жағдайы туралы деректер" батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.01.q27':
      'Оқушының дебес деректері: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. өзгерді. Оларды ҰБДҚ-да қалай ауыстыруға болады?',
    'faq.1.01.a27':
      'Контингентті қосқан кезде Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы секілді дербес деректер ЖСН бойынша ЖТ МДҚ-дан сұратылады. Бұрын қосылған контингентте деректер өзгерген болса, оларды "Дербес деректер" батырмасын басқан кезде жаңартуға болады.',
    'faq.1.01.q28': 'Оқушыны қалай шығаруға болады? Шығу күні белсенді емес',
    'faq.1.01.a28':
      'Оқушылардың шығарылуын контингенттің басты бетінен ресімдеуге болады, әр оқушыға қарама-қарсы "шығаруды ресімдеу" батырмасы орналасқан. Шығару процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.01.q29':
      'Бұрын қосылған қызметкердің/контингенттің қате ЖСН-ін қалай түзетуге болады?',
    'faq.1.01.a29':
      'Контингент пен персоналдың қате ЖСН түзету үшін нысанды толтырып, қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. Нысан "Көмек" бөлімінде орналастырылған.',
    'faq.1.01.q30': 'Контингентті Excel-ге қалай экспорттауға болады?',
    'faq.1.01.a30':
      'Контингент бөлімінде пайдаланушы одан әрі жұмыс істеу үшін контингент тізімін Excel-ге экспорттай алады. Ол үшін "Контингент" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" түймесін басу керек. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.01.q31': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.01.a31':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.01.q32': 'БҰ паспортына кім қол қояды?',
    'faq.1.01.a32':
      'ББ паспортына ББ басшысы қол қояды – рөлі ЭЦҚ басшысы жеке тұлғаға RSA_********.',
    'faq.1.01.q33':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.1.01.a33':
      'Егер пайдаланушы жүйеге кірген кезде құпиясөзді ұмытып қалса, "құпиясөзді ұмыттыңыз ба?" басу немесе ЖАО-ға, техникалық қолдау қызметіне "көмек" бөліміне жүгіну қажет.',
    'faq.1.01.q34': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.1.01.a34':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу" батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою" қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.1.01.q35':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.1.01.a35':
      'Бұл хабарламада пайдаланушы белсендірілмегені  туралы айтады. Пайдаланушыны іске қосу үшін ЖАО-ға хабарласыңыз.',
    'faq.1.01.q36': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.1.01.a36':
      'ҰБДҚ паспорттарын толтыру жөніндегі Нұсқаулық "Көмек" бөлімінде орналастырылған.',
    'faq.1.01.q37': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.1.01.a37': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.1.01.q38': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.1.01.a38':
      'Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.1.01.q39': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.1.01.a39':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',
    'faq.1.02.q1':
      'Білім беру ұйымының атауы мен түрі туралы мәліметтерді қайдан көруге болады?',
    'faq.1.02.a1': 'Төлқұжатта ұйымның деректері бөлімін ашу (кеңейту)',
    'faq.1.02.q2': 'Ұйымның атауы өзгерді оны қалай өңдеуге болады?',
    'faq.1.02.a2':
      'Білім беру ұйымының атауын (білім беру ұйымының атауы, қазақ тіліндегі толық атауы, қазақ тіліндегі қысқаша атауы, орыс тіліндегі толық атауы, орыс тіліндегі қысқаша атауы) білім беру ұйымын құру кезінде білім басқармасы немесе бөлімі (бұдан әрі - ЖАО) толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет',
    'faq.1.02.q3':
      'Неліктен "тіркеу" бөліміндегі "білім беру ұйымдарының түрлері", "білім беру ұйымының БСН", "меншік нысаны", "аумақтық тиесілілігі бойынша ұйым", "ұйымдық - құқықтық нысан", "құрамдас бөліктің типі", " үйдің №", "негізгі құрылтайшылар", "білім беру ұйымының ашылу күні" және "білім беру ұйымының жабылу күні" көрсеткіштері редакциялауға қолжетімсіз:? Бұл көрсеткіштерде деректерді қалай түзетуге болады?',
    'faq.1.02.a3':
      'Бұл көрсеткіштерді білім басқармасы немесе білім бөлімі (бұдан әрі - ЖАО) білім беру ұйымын құру кезінде толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет.',
    'faq.1.02.q4':
      '"Тіркеу мәліметтері" бөлімінде Факс (код+нөмір), Жұмыс телефоны (код+нөмір), ұялы телефон (нөмір) көрсеткіштері бойынша қате шығады (қызыл түспен боялады) ?',
    'faq.1.02.a4':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.02.q5': 'Неліктен" ғимараттар саны " көрсеткіші белсенді емес?',
    'faq.1.02.a5':
      'Көрсеткіш "ғимараттар туралы мәліметтер"көрсеткішіне енгізілген деректерден автоматты түрде есептеледі.',
    'faq.1.02.q6':
      '"Кітапхана / оқулықтар" бөлімінде "барлығы ( оқулықтар, көркем әдебиеттер, брошюралар және журналдар)" көрсеткішін қалай түзетуге болады?',
    'faq.1.02.a6':
      'Көрсеткіш автоматты түрде есептеледі – "оның ішінде оқулықтар" + "оның ішінде кітаптар (көркем әдебиеттер, брошюралар және журналдар)" жолдарының сомасы.',
    'faq.1.02.q7':
      'Неліктен "Кітапхана / оқулықтар " бөлімінде "Оқу жылының басында келіп түскен оқулықтарды ескере отырып оқушылардың қамтамасыздандырылуы" көрсеткішінде "оқыту тілі" элементінде "деректер жоқ" немесе барлық оқыту тілдері шықпайды?',
    'faq.1.02.a7':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште тіл белгіленбесе, "оқыту тілі" элементінде деректер болмайды.',
    'faq.1.02.q8':
      'Неліктен "Кітапхана/оқулықтар" бөлімінде "Оқу жылының басында келіп түскен оқулықтарды ескере отырып оқушылардың қамтамасыздандырылуы" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.02.a8':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Оқыту нешінші сыныптан бастап жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі',
    'faq.1.02.q9':
      '"Кітапхана / оқулықтар" бөлімінде бұрын келесі көрсеткіштер толтырылған болатын: "Кітапхана, бірлік", "кітапхана алаңы, ш.м.", "оқу залдарының саны, бірлік", "оқу залдарындағы отыратын орындардың саны", "оқырмандар саны, адам", "келушілер саны, адам" көрсеткіштері қазір көрсетілмейді ме?',
    'faq.1.02.a9':
      'Егер "Кітапхананың болуы" көрсеткішіне Жоқ деп таңдалса паспорт  сақталғаннан кейін көрсеткіштер тазаланады, бұл көрсеткіштер жасырылады. Егер БҰ-да кітапхана болса, "кітапхананың болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.02.q10':
      '"Компьютерлендіру" бөлімінде бұрын компьютерлер бойынша көрсеткіштер толтырылды, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.02.a10':
      'Егер "компьютерлердің болуы" көрсеткішінде жоқ деп белгіленсе паспорт  сақталғаннан кейін көрсеткіштер тазаланады, "компьютерлер саны" көрсеткіші жасырылады. БҰ компьютерлер болған жағдайда "компьютерлердің болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.02.q11':
      'Компьютерлендіру бөлімінде бұрын Интернет жылдамдығы бойынша көрсеткіштер толтырылған, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.02.a11':
      '"Ғаламтордың  бар болуы" көрсеткішінде ЖОҚ мәні таңдалса Келісім "шарт бойынша интернеттің жылдамдығы", "Факт бойынша интернеттің жылдамдығы" "Интернет (нүкте) желісіне рұқсаттың болуы, бірлік" көрсеткіштері сақтағаннан кейін жасырылады және тазаланады. БҰ Интернет болған жағдайда "Ғаламтордың  бар болуы" көрсеткішінде "ИӘ" таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.02.q12':
      '"Асхана" бөлімінде "Асханада отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.02.a12':
      '"Асханада отыратын орындар саны, бірлік" көрсеткіші "Асхананың бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ асхана болған жағдайда "Асхананың бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.02.q13':
      '"Асхана" бөлімінде "Буфетте отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.02.a13':
      '"Буфетте отыратын орындар саны, бірлік" көрсеткіші "Буфеттің бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ буфет болған жағдайда "Буфеттің бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.02.q14':
      'Неліктен "Білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "Мектеп оқыту тілі жағына қарай дамиды" көрсеткішінде "деректер жоқ" немесе барлық оқыту тілдері шықпайды?',
    'faq.1.02.a14':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Оқыту тілі"  көрсеткішінде бір де тіл белгіленбесе "Мектеп оқыту тілі жағына қарай дамиды" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.02.q15':
      'Неліктен "Білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "Пәндерді тереңдетіп оқытатын сыныптардың саны" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.02.a15':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.02.q16':
      'Неліктен "білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "пәндерді көптілді оқыту" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.02.a16':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.02.q17':
      'Неліктен "білім беру объектісі туралы негізгі мәліметтер" бөлімінде "Сыныптар , сынып-комплектілер саны" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.02.a17':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.02.q18':
      'Неліктен «Қамқоршылық кеңес мүшелерінің саны» индикаторы белсенді емес?',
    'faq.1.02.a18':
      'Көрсеткіш «Қамқоршылық кеңестің құрамы туралы мәліметтер » индикаторына енгізілген мәліметтерден автоматты түрде есептеледі.',
    'faq.1.02.q19':
      'Неліктен "паспорттауға жауапты" бөлімінде көрсеткіштер бойынша қате пайда болады (қызыл түспен боялған): жұмыс телефоны (код+нөмір), ұялы телефон (нөмір)?',
    'faq.1.02.a19':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.02.q21':
      'Қызметкерді қалай жұмыстан шығаруға болады "еңбек шартын бұзу/тоқтату күні", "еңбек шартын бұзу/тоқтату себебі", "еңбек шартын бұзу/тоқтату туралы бұйрық нөмірі" белсенді емес пе?',
    'faq.1.02.a21':
      'Қызметкерді қызметкерлердің басты бетінен жұмыстан шығара аласыз, әр қызметкерге қарама-қарсы жұмыстан босату"батырмасы бар. Жұмыстан шығару процесі пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.02.q22': 'Неге персоналда қызметкердің" негізгі лауазым ставкасы " 0 шығады?',
    'faq.1.02.a22':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "негізгі лауазымның ставкасы" тек 0 болуы мүмкін.',
    'faq.1.02.q23':
      'Персоналда "Ағымдағы мерзімдегі жалпы еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.02.a23':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі жалпы еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.02.q24':
      'Неліктен персоналда "қазіргі кездегі педагогикалық жұмыс өтілі" көрсеткіші белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.02.a24':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі педагогикалық еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.02.q25': 'Персоналда "оқыту тілі" көрсеткіші неге бос, таңдау элементтері жоқ',
    'faq.1.02.a25':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе персоналда "оқыту тілі" көрсеткіші бос болады.',
    'faq.1.02.q26':
      'Не себепті персонал бөліміндегі қызметкерде "Қосымша лауазым" көрсеткішінде мөлшерлеме 0 шығады?',
    'faq.1.02.a26':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, әскерде немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "мөлшерлеме" тек 0 болуы мүмкін',
    'faq.1.02.q27':
      'Неліктен қызметкерде "Оқыту сағаты қай сыныпта көп" көрсеткішінде "деректер жоқ"деп шығады?',
    'faq.1.02.a27':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "қай сыныптарда сабақ береді"көрсеткішінде белгіленген сыныптар шығады.',
    'faq.1.02.q28':
      'Қызметкердің жеке деректері өзгерді: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. оларды ҰБДҚ-ға қалай ауыстыруға болады?',
    'faq.1.02.a28':
      'Персоналды қосқан кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Егер бұрын енгізілген персоналдың деректері өзгерсе, оны "дербес деректер" батырмасын басу арқылы жаңартуға болады',
    'faq.1.02.q29': 'Қызметкерлерді Excel-ге қалай түсіруге болады?',
    'faq.1.02.a29':
      'Персонал бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "қызметкерлер" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" батырмасын басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.02.q31':
      'Неліктен контингентте "оқыту тілі "көрсеткішінде "деректер жоқ" деп шығады?',
    'faq.1.02.a31':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе контингентте "оқыту тілі" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.02.q32':
      'Неліктен контингентте "оқу ауысымы" көрсеткішінде "деректер жоқ" деп шығады немесе қажет ауысым жоқ?',
    'faq.1.02.a32':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру объектісі туралы негізгі мәліметтер" бөлімінің "ауысымдар саны" көрсеткішінде белгіленген ауысымдар көрсетіледі, егер көрсеткіш толтырылмаса, контингентте "оқу ауысымы" көрсеткішінде "деректер жоқ" деп шығады, егер "ауысымдар санында" 1 ауысым таңдалса, контингентте тек 1 ауысым ғана шығады және т. б.',
    'faq.1.02.q33':
      'Неліктен контингентте "Аталған білім беру ұйымындағы секциялар мен үйірмелерге қатысады" көрсеткішінде "жоқ (бармайды)" ғана шығады немесе үйірменің не спорт секциясының қажетті түрі шықпайды?',
    'faq.1.02.a33':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Түрі бойынша үйірмелердің және спорттық секциялардың саны көрсеткішінде белгіленген үйірмелер мен секциялар көрсетіледі.',
    'faq.1.02.q34':
      'Неліктен контингентте "мүгедек", "мүгедектік тобы", "мүгедектік себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері белсенді емес? Оларды қалай түзетуге болады?',
    'faq.1.02.a34':
      '"Мүгедек", "мүгедектік тобы", "мүгедектіктің себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері бойынша деректер ҚР Еңбек және халықты әлеуметтік қорғау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "мүгедектік бойынша деректер" батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.02.q35':
      'Неліктен контингентте "Денсаулығы туралы мәлімет" көрсеткіші белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.02.a35':
      '"Денсаулығы туралы мәлімет" көрсеткіші бойынша деректер ҚР Денсаулық сақтау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "денсаулық жағдайы туралы деректер"батырмасының көмегімен ақпаратты сұрату қажет',
    'faq.1.02.q36':
      'Оқушының дебес деректері: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. өзгерді. Оларды ҰБДҚ-да қалай ауыстыруға болады?',
    'faq.1.02.a36':
      'Контингентті қосқан кезде Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы секілді дербес деректер ЖСН бойынша ЖТ МДҚ-дан сұратылады. Бұрын қосылған контингентте деректер өзгерген болса, оларды "Дербес деректер" батырмасын басқан кезде жаңартуға болады.',
    'faq.1.02.q37': 'Оқушыны қалай шығаруға болады? Шығу күні белсенді емес',
    'faq.1.02.a37':
      'Оқушылардың шығарылуын контингенттің басты бетінен ресімдеуге болады, әр оқушыға қарама-қарсы "шығаруды ресімдеу"батырмасы орналасқан. Шығару процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.02.q38':
      'Неліктен "Шығаруды, көшіруді немесе қабылдауды рәсімдеу" бөлімінде түлектер жоқ?',
    'faq.1.02.a38':
      'Аталған бөлімнің "Түлек" қойындысында Орта білім беру туралы аттестат (Аттестат сериясы) /Білім беру туралы куәлік (Куәлік сериясы), Орта білім беру туралы аттестат (Аттестат нөмірі) /Білім беру туралы куәлік (Куәлік нөмірі), Орта білім беру туралы аттестат (Берілу күні) /Білім беру туралы куәлік (Берілу күні) көрсеткіштері толтырған оқушылар көрсетіледі',
    'faq.1.02.q39':
      'Контингентті қосқан кезде оқушы басқа білім беру ұйымында тіркелгендігі хабарлама шығады, не істеу керек?',
    'faq.1.02.a39':
      'Басқа білім беру ұйымында тіркелген оқушыны қосқан кезде  байланыс деректері бар хабарлама шығады.  Мекемемен байланыс болмаған жағдайда ЖАО-ға немесе қолдау қызметінің "көмек" бөлімінде деректері көрсетілген қолдау қызметіне хабарласыңыз',
    'faq.1.02.q40':
      'Бұрын қосылған қызметкердің/контингенттің қате ЖСН-ін қалай түзетуге болады?',
    'faq.1.02.a40':
      'Контингент пен персоналдың қате ЖСН түзету үшін нысанды толтырып, қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. Нысан "Көмек"бөлімінде орналастырылған.',
    'faq.1.02.q41': 'Контингентті Excel-ге қалай экспорттауға болады?',
    'faq.1.02.a41':
      'Контингент бөлімінде пайдаланушы одан әрі жұмыс істеу үшін контингент тізімін Excel-ге экспорттай алады. Ол үшін "Контингент" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" түймесін басу керек. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.02.q42': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.02.a42':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.02.q43': 'БҰ паспортына кім қол қояды?',
    'faq.1.02.a43':
      'ББ паспортына ББ басшысы қол қояды – рөлі ЭЦҚ басшысы жеке тұлғаға RSA_********.',
    'faq.1.02.q44':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.1.02.a44':
      'Егер пайдаланушы жүйеге кірген кезде құпиясөзді ұмытып қалса, "құпиясөзді ұмыттыңыз ба?" басу немесе ЖАО-ға, техникалық қолдау қызметіне "көмек" бөліміне жүгіну қажет.',
    'faq.1.02.q45': '"Оқушының траекториясы" дегеніміз не?',
    'faq.1.02.a45':
      'Бұл НОБД модулі білім беру ұйымы қызметкеріне оқушының іс жүзіндегі дерекнамасын көруге мүмкіндік береді. Модульмен жұмыс істеудің егжей-тегжейлі процесі оқушы қозғалысының траекториясы модулінің пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.02.q46': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.1.02.a46':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу" батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою" қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.1.02.q47':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.1.02.a47':
      'Бұл хабарламада пайдаланушы белсендірілмегені  туралы айтады. Пайдаланушыны іске қосу үшін ЖАО-ға хабарласыңыз.',
    'faq.1.02.q48': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.1.02.a48': 'ҰБДҚ толтыру жөніндегі нұсқаулық "Көмек" бөлімінде орналастырылған',
    'faq.1.02.q49': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.1.02.a49': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.1.02.q50': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.1.02.a50':
      'Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.1.02.q51': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.1.02.a51':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',

    'faq.1.03.q1':
      '"Білім беру ұйымының атауы мен түрі туралы мәліметтерді қайдан көруге болады?',
    'faq.1.03.a1': 'Төлқұжатта ұйымның деректері бөлімін ашу (кеңейту)',
    'faq.1.03.q2': 'Ұйымның атауы өзгерді оны қалай өңдеуге болады?',
    'faq.1.03.a2':
      'Білім беру ұйымының атауын (білім беру ұйымының атауы, қазақ тіліндегі толық атауы, қазақ тіліндегі қысқаша атауы, орыс тіліндегі толық атауы, орыс тіліндегі қысқаша атауы) білім беру ұйымын құру кезінде білім басқармасы немесе бөлімі (бұдан әрі - ЖАО) толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет',
    'faq.1.03.q3':
      'Неліктен "тіркеу" бөліміндегі "білім беру ұйымдарының түрлері", "білім беру ұйымының БСН", "меншік нысаны", "аумақтық тиесілілігі бойынша ұйым", "ұйымдық - құқықтық нысан", "құрамдас бөліктің типі", " үйдің №", "негізгі құрылтайшылар", "білім беру ұйымының ашылу күні" және "білім беру ұйымының жабылу күні" көрсеткіштері редакциялауға қолжетімсіз:? Бұл көрсеткіштерде деректерді қалай түзетуге болады?',
    'faq.1.03.a3':
      'Бұл көрсеткіштерді білім басқармасы немесе білім бөлімі (бұдан әрі - ЖАО) білім беру ұйымын құру кезінде толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет.',
    'faq.1.03.q4':
      '"Тіркеу мәліметтері" бөлімінде Факс (код+нөмір), Жұмыс телефоны (код+нөмір), ұялы телефон (нөмір) көрсеткіштері бойынша қате шығады (қызыл түспен боялады) ?',
    'faq.1.03.a4':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.03.q5': 'Неліктен" ғимараттар саны " көрсеткіші белсенді емес?',
    'faq.1.03.a5':
      'Көрсеткіш "ғимараттар туралы мәліметтер"көрсеткішіне енгізілген деректерден автоматты түрде есептеледі.',
    'faq.1.03.q6':
      '"Кітапхана / оқулықтар" бөлімінде "барлығы ( оқулықтар, көркем әдебиеттер, брошюралар және журналдар)" көрсеткішін қалай түзетуге болады?   ',
    'faq.1.03.a6':
      'Көрсеткіш автоматты түрде есептеледі – "оның ішінде оқулықтар" + "оның ішінде кітаптар (көркем әдебиеттер, брошюралар және журналдар)" жолдарының сомасы.',
    'faq.1.03.q7':
      '"Кітапхана / оқулықтар" бөлімінде бұрын келесі көрсеткіштер толтырылған болатын: "Кітапхана, бірлік", "кітапхана алаңы, ш.м.", "оқу залдарының саны, бірлік", "оқу залдарындағы отыратын орындардың саны", "оқырмандар саны, адам", "келушілер саны, адам" көрсеткіштері қазір көрсетілмейді ме?',
    'faq.1.03.a7':
      'Егер "Кітапхананың болуы" көрсеткішіне Жоқ деп таңдалса паспорт  сақталғаннан кейін көрсеткіштер тазаланады, бұл көрсеткіштер жасырылады. Егер БҰ-да кітапхана болса, "кітапхананың болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.03.q8':
      '"Компьютерлендіру" бөлімінде бұрын компьютерлер бойынша көрсеткіштер толтырылды, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.03.a8':
      'Егер "компьютерлердің болуы" көрсеткішінде жоқ деп белгіленсе паспорт бөлімі сақталғаннан кейін көрсеткіштер тазаланады, "компьютерлер саны" көрсеткіші жасырылады. БҰ компьютерлер болған жағдайда "компьютерлердің болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.03.q9':
      'Компьютерлендіру бөлімінде бұрын Интернет жылдамдығы бойынша көрсеткіштер толтырылған, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.03.a9':
      '"Ғаламтордың  бар болуы" көрсеткішінде ЖОҚ мәні таңдалса Келісім "шарт бойынша интернеттің жылдамдығы", "Факт бойынша интернеттің жылдамдығы" "Интернет (нүкте) желісіне рұқсаттың болуы, бірлік" көрсеткіштері сақтағаннан кейін жасырылады және тазаланады. БҰ Интернет болған жағдайда "Ғаламтордың  бар болуы" көрсеткішінде "ИӘ" таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.03.q10':
      '"Асхана" бөлімінде бұрын "асханадағы отырғызу орындарының саны, бірлік", "үй-жай пайдаланылады" және "айына бір білім алушыға тамақтанудың орташа құны, теңге" көрсеткіштері толтырылған болатын.',
    'faq.1.03.a10':
      '"Асханадағы отыратын орындар саны, бірлік" көрсеткіштері "үй-жай пайдаланылады" және "айына бір білім алушыға тамақтанудың орташа құны, теңге" көрсеткіштері "асхананың болуы" көрсеткіші байқалмаса жасырылады және паспорттың бөлімі сақталғаннан кейін тазартылады. Егер ОО асхана болса, "асхананың болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын көбіктенген болса және паспорт сақталмаса, олар көрсеткіште көрсетіледі.',
    'faq.1.03.q11':
      'Неліктен «Қамқоршылық кеңес мүшелерінің саны» индикаторы белсенді емес?',
    'faq.1.03.a11':
      'Көрсеткіш «Қамқоршылық кеңестің құрамы туралы мәліметтер » индикаторына енгізілген мәліметтерден автоматты түрде есептеледі.',
    'faq.1.03.q12':
      'Неліктен" паспорттауға жауапты " бөлімінде көрсеткіштер бойынша қате пайда болады (қызыл түспен боялған): жұмыс телефоны (код+нөмір), ұялы телефон (нөмір)?',
    'faq.1.03.a12':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.03.q14':
      'Қызметкерді қалай жұмыстан шығаруға болады "еңбек шартын бұзу/тоқтату күні", "еңбек шартын бұзу/тоқтату себебі", "еңбек шартын бұзу/тоқтату туралы бұйрық нөмірі" белсенді емес пе?',
    'faq.1.03.a14':
      'Қызметкерді қызметкерлердің басты бетінен жұмыстан шығара аласыз, әр қызметкерге қарама-қарсы жұмыстан босату"батырмасы бар. Жұмыстан шығару процесі пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.03.q15': 'Неге персоналда қызметкердің "негізгі лауазым ставкасы" 0 шығады?',
    'faq.1.03.a15':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "негізгі лауазымның ставкасы" тек 0 болуы мүмкін.',
    'faq.1.03.q16':
      'Персоналда "Ағымдағы мерзімдегі жалпы еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.03.a16':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі жалпы еңбек өтілі" көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.03.q17':
      'Персоналда "Ағымдағы мерзімдегі педагогикалық еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады? ',
    'faq.1.03.a17':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі педагогикалық еңбек өтілі" көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.03.q18':
      'Персоналда "оқыту тілі" көрсеткіші неге бос, таңдау элементтері жоқ?',
    'faq.1.03.a18':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе персоналда "оқыту тілі" көрсеткіші бос болады.',
    'faq.1.03.q19':
      'Не себепті персонал бөліміндегі қызметкерде "Қосымша лауазым" көрсеткішінде мөлшерлеме 0 шығады?',
    'faq.1.03.a19':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, әскерде немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "мөлшерлеме" тек 0 болуы мүмкін',
    'faq.1.03.q20':
      'Қызметкердің жеке деректері өзгерді: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. оларды ҰБДҚ-ға қалай ауыстыруға болады?',
    'faq.1.03.a20':
      'Персоналды қосқан кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Егер бұрын енгізілген персоналдың деректері өзгерсе, оны "дербес деректер" батырмасын басу арқылы жаңартуға болады',
    'faq.1.03.q21': 'Қызметкерлерді Excel-ге қалай түсіруге болады?',
    'faq.1.03.a21':
      'Персонал бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "қызметкерлер" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" батырмасын басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.03.q23':
      'Неліктен контингентте "оқыту тілі "көрсеткішінде "деректер жоқ" деп шығады?',
    'faq.1.03.a23':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе контингентте "оқыту тілі" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.03.q24': 'Көрсеткіш  «Мамандық» контингентте неге жоқ?',
    'faq.1.03.a24':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, білім беру объектісі туралы негізгі мәліметтер бөлімінің "деңгейлік біліктіліктер бойынша дайындық" көрсеткіші толтырылған кезде көрсетіледі. Егер "деңгейлік біліктіліктер бойынша даярлау" көрсеткішінде "бір біліктілік" белгіленсе, "мамандық және сыныптауыш (негізгі)"ғана көрсетіледі. Егер "деңгейлік біліктіліктер бойынша даярлау" көрсеткішінде "екі деңгейлі біліктілік" белгіленсе, "мамандық және сыныптауыш (негізгі)" және мамандық және сыныптауыш 2 ғана көрсетіледі (екі деңгейлі біліктілігі барлар үшін). Егер "деңгейлік біліктіліктер бойынша даярлау" көрсеткішінде "үш деңгейлік біліктілік" тек "мамандық және сыныптауыш (негізгі)", мамандық және сыныптауыш 2 (екі деңгейлік біліктіліктері барлар үшін) және мамандық және сыныптауыш 3 (үш деңгейлік біліктіліктері барлар үшін) көрсетіледі.',
    'faq.1.03.q25':
      'Контингентте" Студент (оқу курсы) " көрсеткіштеріне неге барлық курстар шықпайды?',
    'faq.1.03.a25':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, оған дейінгі курстар "оқу мерзімі" көрсеткішінде белгіленген, егер "оқу мерзімі" толтырылмаған болса, онда "Студент (оқу курсы)" көрсеткішінде "деректер жоқ"деп шығады.',
    'faq.1.03.q26':
      'Неліктен контингентте "мүгедек", "мүгедектік тобы", "мүгедектік себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері белсенді емес? Оларды қалай түзетуге болады?',
    'faq.1.03.a26':
      '"Мүгедек", "мүгедектік тобы", "мүгедектіктің себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері бойынша деректер ҚР Еңбек және халықты әлеуметтік қорғау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "мүгедектік бойынша деректер" батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.03.q27':
      'Неліктен контингентте "Денсаулығы туралы мәлімет" көрсеткіші белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.03.a27':
      '"Денсаулығы туралы мәлімет" көрсеткіші бойынша деректер ҚР Денсаулық сақтау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "денсаулық жағдайы туралы деректер"батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.03.q28':
      'Оқушының дебес деректері: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. өзгерді. Оларды ҰБДҚ-да қалай ауыстыруға болады?',
    'faq.1.03.a28':
      'Контингентті қосқан кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Егер бұрын енгізілген персоналдың деректері өзгерсе, оны "дербес деректер" батырмасын басу арқылы жаңартуға болады',
    'faq.1.03.q29': 'Оқушыны қалай шығаруға болады? Шығу күні белсенді емес',
    'faq.1.03.a29':
      'Оқушылардың шығарылуын контингенттің басты бетінен ресімдеуге болады, әр оқушыға қарама-қарсы "шығаруды ресімдеу"батырмасы орналасқан. Шығару процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.03.q30':
      '"Неліктен "Шығаруды, көшіруді немесе қабылдауды рәсімдеу" бөлімінде түлектер жоқ?',
    'faq.1.03.a30':
      'Аталған бөлімнің "Түлек" қойындысында Орта білім беру туралы аттестат (Аттестат сериясы) /Білім беру туралы куәлік (Куәлік сериясы), Орта білім беру туралы аттестат (Аттестат нөмірі) /Білім беру туралы куәлік (Куәлік нөмірі), Орта білім беру туралы аттестат (Берілу күні) /Білім беру туралы куәлік (Берілу күні) көрсеткіштері толтырған оқушылар көрсетіледі',
    'faq.1.03.q31':
      'Контингентті қосқан кезде оқушы басқа білім беру ұйымында тіркелгендігі хабарлама шығады, не істеу керек?',
    'faq.1.03.a31':
      'Басқа білім беру ұйымында тіркелген оқушыны қосқан кезде  байланыс деректері бар хабарлама шығады.  Мекемемен байланыс болмаған жағдайда ЖАО-ға немесе қолдау қызметінің "көмек" бөлімінде деректері көрсетілген қолдау қызметіне хабарласыңыз',
    'faq.1.03.q32':
      'Бұрын қосылған қызметкердің/контингенттің қате ЖСН-ін қалай түзетуге болады?',
    'faq.1.03.a32':
      'Контингент пен персоналдың қате ЖСН түзету үшін нысанды толтырып, қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. Нысан "Көмек" бөлімінде орналастырылған.',
    'faq.1.03.q33': 'Контингенті Excel-ге қалай жүктеуге болады?',
    'faq.1.03.a33':
      'Контингент бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "контигент" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" батырмасын басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.03.q34': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.03.a34':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.03.q35': 'БҰ паспортына кім қол қояды?',
    'faq.1.03.a35':
      'БҰ паспортына БҰ басшысы қол қояды - заңды тұлғалар үшін  паспортқа заңды тұлғаның ЭСҚ арқылы қол қояды (GOST), мекеменің БСН мен ЭСҚ БСН сәйкестігі тексерілетін болады. <br/>  Жеке кәсіпкерлер үшін паспортқа қол қою жеке тұлғаның ЭСҚ (RSA) арқылы жүргізіледі, бұл ретте жүйе паспортқа қол қою кезінде пайдаланушының профиліндегі ЖСН-ді ЭСҚ-дағы ЖСН-мен салыстырып тексереді. Егер ЖСН толтырылмаса пайдаланушы профилінде қол қою мүмкін емес.',
    'faq.1.03.q36':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.1.03.a36':
      'Егер пайдаланушы жүйеге кірген кезде құпиясөзді ұмытып қалса, "құпиясөзді ұмыттыңыз ба?" басу немесе ЖАО-ға, техникалық қолдау қызметіне "көмек" бөліміне жүгіну қажет.',
    'faq.1.03.q37': '"Оқушының траекториясы" дегеніміз не?',
    'faq.1.03.a37':
      'Бұл НОБД модулі білім беру ұйымы қызметкеріне оқушының іс жүзіндегі дерекнамасын көруге мүмкіндік береді. Модульмен жұмыс істеудің егжей-тегжейлі процесі оқушы қозғалысының траекториясы модулінің пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.03.q38': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.1.03.a38':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу" батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою" қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.1.03.q39':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.1.03.a39':
      'Бұл хабарламада пайдаланушы белсендірілмегені  туралы айтады. Пайдаланушыны іске қосу үшін ЖАО-ға хабарласыңыз.',
    'faq.1.03.q40': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.1.03.a40': 'ҰБДҚ толтыру жөніндегі нұсқаулық "Көмек" бөлімінде орналастырылған',
    'faq.1.03.q41': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.1.03.a41': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.1.03.q42': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.1.03.a42':
      'Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.1.03.q43': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.1.03.a43':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',
    'faq.1.06.q1':
      'Білім беру ұйымының атауы мен түрі туралы мәліметтерді қайдан көруге болады?',
    'faq.1.06.a1': 'Төлқұжатта ұйымның деректері бөлімін ашу (кеңейту)',
    'faq.1.06.q2': 'Ұйымның атауы өзгерді оны қалай өңдеуге болады?',
    'faq.1.06.a2':
      'Білім беру ұйымының атауын (білім беру ұйымының атауы, қазақ тіліндегі толық атауы, қазақ тіліндегі қысқаша атауы, орыс тіліндегі толық атауы, орыс тіліндегі қысқаша атауы) білім беру ұйымын құру кезінде білім басқармасы немесе бөлімі (бұдан әрі - ЖАО) толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет',
    'faq.1.06.q3':
      'Неліктен "тіркеу" бөліміндегі "білім беру ұйымдарының түрлері", "білім беру ұйымының БСН", "меншік нысаны", "аумақтық тиесілілігі бойынша ұйым", "ұйымдық - құқықтық нысан", "құрамдас бөліктің типі", " үйдің №", "негізгі құрылтайшылар", "білім беру ұйымының ашылу күні" және "білім беру ұйымының жабылу күні" көрсеткіштері редакциялауға қолжетімсіз:? Бұл көрсеткіштерде деректерді қалай түзетуге болады?',
    'faq.1.06.a3':
      'Бұл көрсеткіштерді білім басқармасы немесе білім бөлімі (бұдан әрі - ЖАО) білім беру ұйымын құру кезінде толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет.',
    'faq.1.06.q4':
      '"Тіркеу мәліметтері" бөлімінде Факс (код+нөмір), Жұмыс телефоны (код+нөмір), ұялы телефон (нөмір) көрсеткіштері бойынша қате шығады (қызыл түспен боялады) ?',
    'faq.1.06.a4':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.06.q5': 'Неліктен" ғимараттар саны " көрсеткіші белсенді емес?',
    'faq.1.06.a5':
      'Көрсеткіш "ғимараттар туралы мәліметтер" көрсеткішіне енгізілген деректерден автоматты түрде есептеледі.',
    'faq.1.06.q6':
      '"Кітапхана / оқулықтар" бөлімінде "барлығы ( оқулықтар, көркем әдебиеттер, брошюралар және журналдар)" көрсеткішін қалай түзетуге болады?',
    'faq.1.06.a6':
      'Көрсеткіш автоматты түрде есептеледі – "оның ішінде оқулықтар" + "оның ішінде кітаптар (көркем әдебиеттер, брошюралар және журналдар)" жолдарының сомасы.',
    'faq.1.06.q7':
      '"Кітапхана / оқулықтар" бөлімінде бұрын келесі көрсеткіштер толтырылған болатын: "Кітапхана, бірлік", "кітапхана алаңы, ш.м.", "оқу залдарының саны, бірлік", "оқу залдарындағы отыратын орындардың саны", "оқырмандар саны, адам", "келушілер саны, адам" көрсеткіштері қазір көрсетілмейді ме?',
    'faq.1.06.a7':
      'Егер "Кітапхананың болуы" көрсеткішіне Жоқ деп таңдалса паспорт  сақталғаннан кейін көрсеткіштер тазаланады, бұл көрсеткіштер жасырылады. Егер БҰ-да кітапхана болса, "кітапхананың болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.06.q8':
      'Компьютерлендіру бөлімінде бұрын компьютерлер бойынша көрсеткіштер толтырылды, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.06.a8':
      'Егер "компьютерлердің болуы" көрсеткішінде жоқ деп белгіленсе паспорт бөлімі сақталғаннан кейін көрсеткіштер тазаланады, "компьютерлер саны" көрсеткіші жасырылады. БҰ компьютерлер болған жағдайда "компьютерлердің болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.06.q9':
      'Компьютерлендіру бөлімінде бұрын Интернет жылдамдығы бойынша көрсеткіштер толтырылған, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.06.a9':
      '"Ғаламтордың  бар болуы" көрсеткішінде ЖОҚ мәні таңдалса Келісім "шарт бойынша интернеттің жылдамдығы", "Факт бойынша интернеттің жылдамдығы" "Интернет (нүкте) желісіне рұқсаттың болуы, бірлік" көрсеткіштері сақтағаннан кейін жасырылады және тазаланады. БҰ Интернет болған жағдайда "Ғаламтордың  бар болуы" көрсеткішінде "ИӘ" таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.06.q10':
      '"Асхана" бөлімінде бұрын "асханадағы отырғызу орындарының саны, бірлік", "үй-жай пайдаланылады" және "айына бір білім алушыға тамақтанудың орташа құны, теңге" көрсеткіштері толтырылған болатын.',
    'faq.1.06.a10':
      '"Асханадағы отыратын орындар саны, бірлік" көрсеткіштері "үй-жай пайдаланылады" және "айына бір білім алушыға тамақтанудың орташа құны, теңге" көрсеткіштері "асхананың болуы" көрсеткіші байқалмаса жасырылады және паспорттың бөлімі сақталғаннан кейін тазартылады. Егер ОО асхана болса, "асхананың болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын көбіктенген болса және паспорт сақталмаса, олар көрсеткіште көрсетіледі.',
    'faq.1.06.q11':
      'Неліктен «Қамқоршылық кеңес мүшелерінің саны» индикаторы белсенді емес?',
    'faq.1.06.a11':
      'Көрсеткіш «Қамқоршылық кеңестің құрамы туралы мәліметтер » индикаторына енгізілген мәліметтерден автоматты түрде есептеледі.',
    'faq.1.06.q12':
      'Неліктен "паспорттауға жауапты" бөлімінде көрсеткіштер бойынша қате пайда болады (қызыл түспен боялған): жұмыс телефоны (код+нөмір), ұялы телефон (нөмір)?',
    'faq.1.06.a12':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.06.q14':
      'Қызметкерді қалай жұмыстан шығаруға болады "еңбек шартын бұзу/тоқтату күні", "еңбек шартын бұзу/тоқтату себебі", "еңбек шартын бұзу/тоқтату туралы бұйрық нөмірі" белсенді емес пе?',
    'faq.1.06.a14':
      'Қызметкерді қызметкерлердің басты бетінен жұмыстан шығара аласыз, әр қызметкерге қарама-қарсы жұмыстан босату" батырмасы бар. Жұмыстан шығару процесі пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.06.q15': 'Неге персоналда қызметкердің" негізгі лауазым ставкасы " 0 шығады?',
    'faq.1.06.a15':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "негізгі лауазымның ставкасы" тек 0 болуы мүмкін.',
    'faq.1.06.q16':
      'Персоналда "Ағымдағы мерзімдегі жалпы еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.06.a16':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі жалпы еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.06.q17':
      'Персоналда "Ағымдағы мерзімдегі педагогикалық еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.06.a17':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі педагогикалық еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.06.q18':
      'Персоналда "оқыту тілі" көрсеткіші неге бос, таңдау элементтері жоқ?',
    'faq.1.06.a18':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе персоналда "оқыту тілі" көрсеткіші бос болады.',
    'faq.1.06.q19':
      'Не себепті персонал бөліміндегі қызметкерде "Қосымша лауазым" көрсеткішінде мөлшерлеме 0 шығады?',
    'faq.1.06.a19':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, әскерде немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "мөлшерлеме" тек 0 болуы мүмкін',
    'faq.1.06.q20':
      'Қызметкердің жеке деректері өзгерді: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. оларды ҰБДҚ-ға қалай ауыстыруға болады?',
    'faq.1.06.a20':
      'Персоналды қосқан кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Егер бұрын енгізілген персоналдың деректері өзгерсе, оны "дербес деректер" батырмасын басу арқылы жаңартуға болады',
    'faq.1.06.q21': 'Персоналды Excel-ге қалай жүктеуге болады?',
    'faq.1.06.a21':
      'Персонал бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "қызметкерлер" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" батырмасын басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.06.q23':
      'Неліктен контингентте "оқыту тілі "көрсеткішінде "деректер жоқ" деп шығады?',
    'faq.1.06.a23':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе контингентте "оқыту тілі" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.06.q24': 'Көрсеткіш  «Мамандық» контингентте неге жоқ?',
    'faq.1.06.a24':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, білім беру объектісі туралы негізгі мәліметтер бөлімінің "деңгейлік біліктіліктер бойынша дайындық" көрсеткіші толтырылған кезде көрсетіледі. Егер "деңгейлік біліктіліктер бойынша даярлау" көрсеткішінде "бір біліктілік" белгіленсе, "мамандық және сыныптауыш (негізгі)"ғана көрсетіледі. Егер "деңгейлік біліктіліктер бойынша даярлау" көрсеткішінде "екі деңгейлі біліктілік" белгіленсе, "мамандық және сыныптауыш (негізгі)" және мамандық және сыныптауыш 2 ғана көрсетіледі (екі деңгейлі біліктілігі барлар үшін). Егер "деңгейлік біліктіліктер бойынша даярлау" көрсеткішінде "үш деңгейлік біліктілік" тек "мамандық және сыныптауыш (негізгі)", мамандық және сыныптауыш 2 (екі деңгейлік біліктіліктері барлар үшін) және мамандық және сыныптауыш 3 (үш деңгейлік біліктіліктері барлар үшін) көрсетіледі.',
    'faq.1.06.q25':
      'Контингентте" Студент (оқу курсы) " көрсеткіштеріне неге барлық курстар шықпайды?',
    'faq.1.06.a25':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, оған дейінгі курстар "оқу мерзімі" көрсеткішінде белгіленген, егер "оқу мерзімі" толтырылмаған болса, онда "Студент (оқу курсы)" көрсеткішінде "деректер жоқ"деп шығады.',
    'faq.1.06.q26':
      'Неліктен контингентте "мүгедек", "мүгедектік тобы", "мүгедектік себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері белсенді емес? Оларды қалай түзетуге болады?',
    'faq.1.06.a26':
      '"Мүгедек", "мүгедектік тобы", "мүгедектіктің себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері бойынша деректер ҚР Еңбек және халықты әлеуметтік қорғау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "мүгедектік бойынша деректер" батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.06.q27':
      'Оқушының дебес деректері: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. өзгерді. Оларды ҰБДҚ-да қалай ауыстыруға болады?',
    'faq.1.06.a27':
      'Контингентті қосқан кезде Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы секілді дербес деректер ЖСН бойынша ЖТ МДҚ-дан сұратылады. Бұрын қосылған контингентте деректер өзгерген болса, оларды "Дербес деректер" батырмасын басқан кезде жаңартуға болады.',
    'faq.1.06.q28': 'Оқушыны қалай шығаруға болады? Шығу күні белсенді еме',
    'faq.1.06.a28':
      'Оқушылардың шығарылуын контингенттің басты бетінен ресімдеуге болады, әр оқушыға қарама-қарсы "шығаруды ресімдеу"батырмасы орналасқан. Шығару процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.06.q29':
      'Неліктен "Шығаруды, көшіруді немесе қабылдауды рәсімдеу" бөлімінде түлектер жоқ?',
    'faq.1.06.a29':
      'Аталған бөлімнің "Түлек" қойындысында Орта білім беру туралы аттестат (Аттестат сериясы) /Білім беру туралы куәлік (Куәлік сериясы), Орта білім беру туралы аттестат (Аттестат нөмірі) /Білім беру туралы куәлік (Куәлік нөмірі), Орта білім беру туралы аттестат (Берілу күні) /Білім беру туралы куәлік (Берілу күні) көрсеткіштері толтырған оқушылар көрсетіледі',
    'faq.1.06.q30':
      'Контингентті қосқан кезде оқушы басқа білім беру ұйымында тіркелгендігі хабарлама шығады, не істеу керек?',
    'faq.1.06.a30':
      'Басқа білім беру ұйымында тіркелген оқушыны қосқан кезде  байланыс деректері бар хабарлама шығады.  Мекемемен байланыс болмаған жағдайда ЖАО-ға немесе қолдау қызметінің "көмек" бөлімінде деректері көрсетілген қолдау қызметіне хабарласыңыз',
    'faq.1.06.q31':
      'Бұрын қосылған қызметкердің/контингенттің қате ЖСН-ін қалай түзетуге болады?',
    'faq.1.06.a31':
      'Контингент пен персоналдың қате ЖСН түзету үшін нысанды толтырып, қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. Нысан "Көмек"бөлімінде орналастырылған.',
    'faq.1.06.q32': 'Контингентті Excel-ге қалай экспорттауға болады?',
    'faq.1.06.a32':
      'Контингент бөлімінде пайдаланушы одан әрі жұмыс істеу үшін контингент тізімін Excel-ге экспорттай алады. Ол үшін "Контингент" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" түймесін басу керек. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.06.q33': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.06.a33':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.06.q34': 'БҰ паспортына кім қол қояды?',
    'faq.1.06.a34':
      'БҰ паспортына БҰ басшысы қол қояды - заңды тұлғалар үшін  паспортқа заңды тұлғаның ЭСҚ арқылы қол қояды (GOST), мекеменің БСН мен ЭСҚ БСН сәйкестігі тексерілетін болады. <br/> Жеке кәсіпкерлер үшін паспортқа қол қою жеке тұлғаның ЭСҚ (RSA) арқылы жүргізіледі, бұл ретте жүйе паспортқа қол қою кезінде пайдаланушының профиліндегі ЖСН-ді ЭСҚ-дағы ЖСН-мен салыстырып тексереді. Егер ЖСН толтырылмаса пайдаланушы профилінде қол қою мүмкін емес.',
    'faq.1.06.q35':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.1.06.a35':
      'Егер пайдаланушы жүйеге кірген кезде құпиясөзді ұмытып қалса, "құпиясөзді ұмыттыңыз ба?" басу немесе ЖАО-ға, техникалық қолдау қызметіне "көмек" бөліміне жүгіну қажет.',
    'faq.1.06.q36': '"Оқушының траекториясы" дегеніміз не?',
    'faq.1.06.a36':
      'Бұл НОБД модулі білім беру ұйымы қызметкеріне оқушының іс жүзіндегі дерекнамасын көруге мүмкіндік береді. Модульмен жұмыс істеудің егжей-тегжейлі процесі оқушы қозғалысының траекториясы модулінің пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.06.q37': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.1.06.a37':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу" батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою" қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.1.06.q38':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.1.06.a38':
      'Бұл хабарламада пайдаланушы белсендірілмегені  туралы айтады. Пайдаланушыны іске қосу үшін ЖАО-ға хабарласыңыз.',
    'faq.1.06.q39': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.1.06.a39': 'ҰБДҚ толтыру жөніндегі нұсқаулық "Көмек" бөлімінде орналастырылған',
    'faq.1.06.q40': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.1.06.a40': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.1.06.q41': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.1.06.a41':
      'Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.1.06.q42': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.1.06.a42':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',
    'faq.1.07.q1':
      'Білім беру ұйымының атауы мен түрі туралы мәліметтерді қайдан көруге болады?',
    'faq.1.07.a1': 'Төлқұжатта ұйымның деректері бөлімін ашу (кеңейту)',
    'faq.1.07.q2': 'Ұйымның атауы өзгерді оны қалай өңдеуге болады?',
    'faq.1.07.a2':
      'Білім беру ұйымының атауын (білім беру ұйымының атауы, қазақ тіліндегі толық атауы, қазақ тіліндегі қысқаша атауы, орыс тіліндегі толық атауы, орыс тіліндегі қысқаша атауы) білім беру ұйымын құру кезінде білім басқармасы немесе бөлімі (бұдан әрі - ЖАО) толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет',
    'faq.1.07.q3':
      'Неліктен "тіркеу" бөліміндегі "білім беру ұйымдарының түрлері", "білім беру ұйымының БСН", "меншік нысаны", "аумақтық тиесілілігі бойынша ұйым", "ұйымдық - құқықтық нысан", "құрамдас бөліктің типі", " үйдің №", "негізгі құрылтайшылар", "білім беру ұйымының ашылу күні" және "білім беру ұйымының жабылу күні" көрсеткіштері редакциялауға қолжетімсіз:? Бұл көрсеткіштерде деректерді қалай түзетуге болады?',
    'faq.1.07.a3':
      'Бұл көрсеткіштерді білім басқармасы немесе білім бөлімі (бұдан әрі - ЖАО) білім беру ұйымын құру кезінде толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет.',
    'faq.1.07.q4':
      'Неліктен "Тіркеу мәліметтері" бөлімінде Факс (код+нөмір), Жұмыс телефоны (код+нөмір), ұялы телефон (нөмір) көрсеткіштері бойынша қате шығады (қызыл түспен боялады) ?',
    'faq.1.07.a4':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.07.q5': 'Почему показатель «Количество зданий» не активный?',
    'faq.1.07.a5': 'Неліктен" ғимараттар саны " көрсеткіші белсенді емес?',
    'faq.1.07.q6':
      'Көрсеткіш "ғимараттар туралы мәліметтер"көрсеткішіне енгізілген деректерден автоматты түрде есептеледі.',
    'faq.1.07.a6':
      '"Кітапхана / оқулықтар" бөлімінде "барлығы ( оқулықтар, көркем әдебиеттер, брошюралар және журналдар)" көрсеткішін қалай түзетуге болады?',
    'faq.1.07.q7':
      'Көрсеткіш автоматты түрде есептеледі – "оның ішінде оқулықтар" + "оның ішінде кітаптар (көркем әдебиеттер, брошюралар және журналдар)" жолдарының сомасы.',
    'faq.1.07.a7':
      'Неліктен "Кітапхана / оқулықтар " бөлімінде "Оқу жылының басында келіп түскен оқулықтарды ескере отырып оқушылардың қамтамасыздандырылуы" көрсеткішінде "оқыту тілі" элементінде "деректер жоқ" немесе барлық оқыту тілдері шықпайды?',
    'faq.1.07.q8':
      'Неліктен "Кітапхана/оқулықтар" бөлімінде "Оқу жылының басында келіп түскен оқулықтарды ескере отырып оқушылардың қамтамасыздандырылуы" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.07.a8':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Оқыту нешінші сыныптан бастап жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі',
    'faq.1.07.q9':
      '"Кітапхана / оқулықтар" бөлімінде бұрын келесі көрсеткіштер толтырылған болатын: "Кітапхана, бірлік", "кітапхана алаңы, ш.м.", "оқу залдарының саны, бірлік", "оқу залдарындағы отыратын орындардың саны", "оқырмандар саны, адам", "келушілер саны, адам" көрсеткіштері қазір көрсетілмейді ме?',
    'faq.1.07.a9':
      'Егер "Кітапхананың болуы" көрсеткішіне Жоқ деп таңдалса паспорт  сақталғаннан кейін көрсеткіштер тазаланады, бұл көрсеткіштер жасырылады. Егер БҰ-да кітапхана болса, "кітапхананың болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.07.q10':
      '"Компьютерлендіру" бөлімінде бұрын компьютерлер бойынша көрсеткіштер толтырылды, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.07.a10':
      'Егер "компьютерлердің болуы" көрсеткішінде жоқ деп белгіленсе паспорт  сақталғаннан кейін көрсеткіштер тазаланады, "компьютерлер саны" көрсеткіші жасырылады. БҰ компьютерлер болған жағдайда "компьютерлердің болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.07.q11':
      'Компьютерлендіру бөлімінде бұрын Интернет жылдамдығы бойынша көрсеткіштер толтырылған, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.07.a11':
      '"Ғаламтордың  бар болуы" көрсеткішінде ЖОҚ мәні таңдалса Келісім "шарт бойынша интернеттің жылдамдығы", "Факт бойынша интернеттің жылдамдығы" "Интернет (нүкте) желісіне рұқсаттың болуы, бірлік" көрсеткіштері сақтағаннан кейін жасырылады және тазаланады. БҰ Интернет болған жағдайда "Ғаламтордың  бар болуы" көрсеткішінде "ИӘ" таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.07.q12':
      '"Асхана" бөлімінде "Асханада отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.07.a12':
      '"Асханада отыратын орындар саны, бірлік" көрсеткіші "Асхананың бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ асхана болған жағдайда "Асхананың бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.07.q13':
      '"Асхана" бөлімінде "Буфетте отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.07.a13':
      '"Буфетте отыратын орындар саны, бірлік" көрсеткіші "Буфеттің бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ буфет болған жағдайда "Буфеттің бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.07.q14':
      'Неліктен "Білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "Мектеп оқыту тілі жағына қарай дамиды" көрсеткішінде "деректер жоқ" немесе барлық оқыту тілдері шықпайды?',
    'faq.1.07.a14':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Оқыту тілі"  көрсеткішінде бір де тіл белгіленбесе "Мектеп оқыту тілі жағына қарай дамиды" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.07.q15':
      'Неліктен "Білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "Пәндерді тереңдетіп оқытатын сыныптардың саны" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.07.a15':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.07.q16':
      'Неліктен "білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "пәндерді көптілді оқыту" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.07.a16':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.07.q17':
      'Неліктен "білім беру объектісі туралы негізгі мәліметтер" бөлімінде "Сыныптар , сынып-комплектілер саны" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.07.a17':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.07.q18':
      'Неліктен «Қамқоршылық кеңес мүшелерінің саны» индикаторы белсенді емес?',
    'faq.1.07.a18':
      'Көрсеткіш «Қамқоршылық кеңестің құрамы туралы мәліметтер » индикаторына енгізілген мәліметтерден автоматты түрде есептеледі.',
    'faq.1.07.q19':
      'Неліктен "паспорттауға жауапты" бөлімінде көрсеткіштер бойынша қате пайда болады (қызыл түспен боялған): жұмыс телефоны (код+нөмір), ұялы телефон (нөмір)?',
    'faq.1.07.a19':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.07.q21':
      'Қызметкерді қалай жұмыстан шығаруға болады "еңбек шартын бұзу/тоқтату күні", "еңбек шартын бұзу/тоқтату себебі", "еңбек шартын бұзу/тоқтату туралы бұйрық нөмірі" белсенді емес пе?',
    'faq.1.07.a21':
      'Қызметкерді қызметкерлердің басты бетінен жұмыстан шығара аласыз, әр қызметкерге қарама-қарсы жұмыстан босату"батырмасы бар. Жұмыстан шығару процесі пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.07.q22': 'Неге персоналда қызметкердің" негізгі лауазым ставкасы " 0 шығады?',
    'faq.1.07.a22':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "негізгі лауазымның ставкасы" тек 0 болуы мүмкін.',
    'faq.1.07.q23':
      'Персоналда "Ағымдағы мерзімдегі жалпы еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.07.a23':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі жалпы еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.07.q24':
      'Персоналда "Ағымдағы мерзімдегі педагогикалық еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.07.a24':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі педагогикалық еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.07.q25':
      'Персоналда "оқыту тілі" көрсеткіші неге бос, таңдау элементтері жоқ?',
    'faq.1.07.a25':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе персоналда "оқыту тілі" көрсеткіші бос болады',
    'faq.1.07.q26':
      'Не себепті персонал бөліміндегі қызметкерде "Қосымша лауазым" көрсеткішінде мөлшерлеме 0 шығады?',
    'faq.1.07.a26':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, әскерде немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "мөлшерлеме" тек 0 болуы мүмкін',
    'faq.1.07.q27':
      'Неліктен қызметкерде "Оқыту сағаты қай сыныпта көп" көрсеткішінде "деректер жоқ"деп шығады?',
    'faq.1.07.a27':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "қай сыныптарда сабақ береді"көрсеткішінде белгіленген сыныптар шығады.',
    'faq.1.07.q28':
      'Қызметкердің жеке деректері өзгерді: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. оларды ҰБДҚ-ға қалай ауыстыруға болады?',
    'faq.1.07.a28':
      'Персоналды қосқан кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Егер бұрын енгізілген персоналдың деректері өзгерсе, оны "дербес деректер" батырмасын басу арқылы жаңартуға болады',
    'faq.1.07.q29': 'Персоналды Excel-ге қалай жүктеуге болады?',
    'faq.1.07.a29':
      'Персонал бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "қызметкерлер" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" батырмасын басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.07.q31':
      'Неліктен контингентте "оқыту тілі "көрсеткішінде "деректер жоқ" деп шығады?',
    'faq.1.07.a31':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе контингентте "оқыту тілі" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.07.q32':
      'Неліктен контингентте "оқу ауысымы" көрсеткішінде "деректер жоқ" деп шығады немесе қажет ауысым жоқ?',
    'faq.1.07.a32':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру объектісі туралы негізгі мәліметтер" бөлімінің "ауысымдар саны" көрсеткішінде белгіленген ауысымдар көрсетіледі, егер көрсеткіш толтырылмаса, контингентте "оқу ауысымы" көрсеткішінде "деректер жоқ" деп шығады, егер "ауысымдар санында" 1 ауысым таңдалса, контингентте тек 1 ауысым ғана шығады және т. б.',
    'faq.1.07.q33':
      'Неліктен контингентте "Аталған білім беру ұйымындағы секциялар мен үйірмелерге қатысады" көрсеткішінде "жоқ (бармайды)" ғана шығады немесе үйірменің не спорт секциясының қажетті түрі шықпайды?',
    'faq.1.07.a33':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Түрі бойынша үйірмелердің және спорттық секциялардың саны көрсеткішінде белгіленген үйірмелер мен секциялар көрсетіледі.',
    'faq.1.07.q34':
      'Неліктен контингентте "мүгедек", "мүгедектік тобы", "мүгедектік себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері белсенді емес? Оларды қалай түзетуге болады?',
    'faq.1.07.a34':
      '\\"Мүгедек\\", \\"мүгедектік тобы\\", \\"мүгедектіктің себебі\\", \\"мүгедектік мерзімі\\", \\"мүгедектік мерзімі дейін\\" көрсеткіштері бойынша деректер ҚР Еңбек және халықты әлеуметтік қорғау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте \\"мүгедектік бойынша деректер\\" батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.07.q35':
      'Неліктен контингентте "Денсаулығы туралы мәлімет" көрсеткіші белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.07.a35':
      '"Денсаулығы туралы мәлімет" көрсеткіші бойынша деректер ҚР Денсаулық сақтау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "денсаулық жағдайы туралы деректер"батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.07.q36':
      'Оқушының дебес деректері: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. өзгерді. Оларды ҰБДҚ-да қалай ауыстыруға болады?',
    'faq.1.07.a36':
      'Контингентті қосқан кезде Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы секілді дербес деректер ЖСН бойынша ЖТ МДҚ-дан сұратылады. Бұрын қосылған контингентте деректер өзгерген болса, оларды "Дербес деректер" батырмасын басқан кезде жаңартуға болады.',
    'faq.1.07.q37': 'Оқушыны қалай шығаруға болады? Шығу күні белсенді емес',
    'faq.1.07.a37':
      'Оқушылардың шығарылуын контингенттің басты бетінен ресімдеуге болады, әр оқушыға қарама-қарсы "шығаруды ресімдеу"батырмасы орналасқан. Шығару процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.07.q38':
      'Неліктен "Шығаруды, көшіруді немесе қабылдауды рәсімдеу" бөлімінде түлектер жоқ?',
    'faq.1.07.a38':
      'Аталған бөлімнің "Түлек" қойындысында Орта білім беру туралы аттестат (Аттестат сериясы) /Білім беру туралы куәлік (Куәлік сериясы), Орта білім беру туралы аттестат (Аттестат нөмірі) /Білім беру туралы куәлік (Куәлік нөмірі), Орта білім беру туралы аттестат (Берілу күні) /Білім беру туралы куәлік (Берілу күні) көрсеткіштері толтырған оқушылар көрсетіледі',
    'faq.1.07.q39':
      'Контингентті қосқан кезде оқушы басқа білім беру ұйымында тіркелгендігі хабарлама шығады, не істеу керек?',
    'faq.1.07.a39':
      'Басқа білім беру ұйымында тіркелген оқушыны қосқан кезде  байланыс деректері бар хабарлама шығады.  Мекемемен байланыс болмаған жағдайда ЖАО-ға немесе қолдау қызметінің "көмек" бөлімінде деректері көрсетілген қолдау қызметіне хабарласыңыз',
    'faq.1.07.q40':
      'Бұрын қосылған қызметкердің/контингенттің қате ЖСН-ін қалай түзетуге болады?',
    'faq.1.07.a40':
      'Контингент пен персоналдың қате ЖСН түзету үшін нысанды толтырып, қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. Нысан "Көмек"бөлімінде орналастырылған.',
    'faq.1.07.q41': 'Контингентті Excel-ге қалай экспорттауға болады?',
    'faq.1.07.a41':
      'Контингент бөлімінде пайдаланушы одан әрі жұмыс істеу үшін контингент тізімін Excel-ге экспорттай алады. Ол үшін "Контингент" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау"түймесін басу керек. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.07.q42': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.07.a42':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.07.q43': 'БҰ паспортына кім қол қояды?',
    'faq.1.07.a43':
      'БҰ паспортына БҰ басшысы қол қояды - заңды тұлғалар үшін  паспортқа заңды тұлғаның ЭСҚ арқылы қол қояды (GOST), мекеменің БСН мен ЭСҚ БСН сәйкестігі тексерілетін болады.  <br/>Жеке кәсіпкерлер үшін паспортқа қол қою жеке тұлғаның ЭСҚ (RSA) арқылы жүргізіледі, бұл ретте жүйе паспортқа қол қою кезінде пайдаланушының профиліндегі ЖСН-ді ЭСҚ-дағы ЖСН-мен салыстырып тексереді. Егер ЖСН толтырылмаса пайдаланушы профилінде қол қою мүмкін емес.',
    'faq.1.07.q44':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.1.07.a44':
      'Егер пайдаланушы жүйеге кірген кезде құпиясөзді ұмытып қалса, "құпиясөзді ұмыттыңыз ба?" басу немесе ЖАО-ға, техникалық қолдау қызметіне "көмек" бөліміне жүгіну қажет.',
    'faq.1.07.q45': '"Оқушының траекториясы" дегеніміз не?',
    'faq.1.07.a45':
      'Бұл НОБД модулі білім беру ұйымы қызметкеріне оқушының іс жүзіндегі дерекнамасын көруге мүмкіндік береді. Модульмен жұмыс істеудің егжей-тегжейлі процесі оқушы қозғалысының траекториясы модулінің пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.07.q46': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.1.07.a46':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу"батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою"қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.1.07.q47':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.1.07.a47':
      'Бұл хабарламада пайдаланушы белсендірілмегені  туралы айтады. Пайдаланушыны іске қосу үшін ЖАО-ға хабарласыңыз.',
    'faq.1.07.q48': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.1.07.a48': 'ҰБДҚ толтыру жөніндегі нұсқаулық "Көмек" бөлімінде орналастырылған',
    'faq.1.07.q49': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.1.07.a49': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.1.07.q50': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.1.07.a50':
      'Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.1.07.q51': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.1.07.a51':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',
    'faq.1.08.q1':
      'Білім беру ұйымының атауы мен түрі туралы мәліметтерді қайдан көруге болады?',
    'faq.1.08.a1': 'Төлқұжатта ұйымның деректері бөлімін ашу (кеңейту)',
    'faq.1.08.q2': 'Ұйымның атауы өзгерді оны қалай өңдеуге болады?',
    'faq.1.08.a2':
      'Білім беру ұйымының атауын (білім беру ұйымының атауы, қазақ тіліндегі толық атауы, қазақ тіліндегі қысқаша атауы, орыс тіліндегі толық атауы, орыс тіліндегі қысқаша атауы) білім беру ұйымын құру кезінде білім басқармасы немесе бөлімі (бұдан әрі - ЖАО) толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет',
    'faq.1.08.q3':
      'Неліктен "тіркеу" бөліміндегі "білім беру ұйымдарының түрлері", "білім беру ұйымының БСН", "меншік нысаны", "аумақтық тиесілілігі бойынша ұйым", "ұйымдық - құқықтық нысан", "құрамдас бөліктің типі", " үйдің №", "негізгі құрылтайшылар", "білім беру ұйымының ашылу күні" және "білім беру ұйымының жабылу күні" көрсеткіштері редакциялауға қолжетімсіз:? Бұл көрсеткіштерде деректерді қалай түзетуге болады?',
    'faq.1.08.a3':
      'Бұл көрсеткіштерді білім басқармасы немесе білім бөлімі (бұдан әрі - ЖАО) білім беру ұйымын құру кезінде толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет.',
    'faq.1.08.q4':
      '"Тіркеу мәліметтері" бөлімінде Факс (код+нөмір), Жұмыс телефоны (код+нөмір), ұялы телефон (нөмір) көрсеткіштері бойынша қате шығады (қызыл түспен боялады) ?',
    'faq.1.08.a4':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.08.q5': 'Неліктен" ғимараттар саны " көрсеткіші белсенді емес?',
    'faq.1.08.a5':
      'Көрсеткіш "ғимараттар туралы мәліметтер"көрсеткішіне енгізілген деректерден автоматты түрде есептеледі.',
    'faq.1.08.q6':
      '"Кітапхана / оқулықтар" бөлімінде "барлығы ( оқулықтар, көркем әдебиеттер, брошюралар және журналдар)" көрсеткішін қалай түзетуге болады? ',
    'faq.1.08.a6':
      'Көрсеткіш автоматты түрде есептеледі – "оның ішінде оқулықтар" + "оның ішінде кітаптар (көркем әдебиеттер, брошюралар және журналдар)" жолдарының сомасы.',
    'faq.1.08.q7':
      'Неліктен "Кітапхана / оқулықтар " бөлімінде "Оқу жылының басында келіп түскен оқулықтарды ескере отырып оқушылардың қамтамасыздандырылуы" көрсеткішінде "оқыту тілі" элементінде "деректер жоқ" немесе барлық оқыту тілдері шықпайды?',
    'faq.1.08.a7':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште тіл белгіленбесе, "оқыту тілі" элементінде деректер болмайды.',
    'faq.1.08.q8':
      'Неліктен "Кітапхана/оқулықтар" бөлімінде "Оқу жылының басында келіп түскен оқулықтарды ескере отырып оқушылардың қамтамасыздандырылуы" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.08.a8':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Оқыту нешінші сыныптан бастап жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі',
    'faq.1.08.q9':
      '"Кітапхана / оқулықтар" бөлімінде бұрын келесі көрсеткіштер толтырылған болатын: "Кітапхана, бірлік", "кітапхана алаңы, ш.м.", "оқу залдарының саны, бірлік", "оқу залдарындағы отыратын орындардың саны", "оқырмандар саны, адам", "келушілер саны, адам" көрсеткіштері қазір көрсетілмейді ме?',
    'faq.1.08.a9':
      'Егер "Кітапхананың болуы" көрсеткішіне Жоқ деп таңдалса паспорт  сақталғаннан кейін көрсеткіштер тазаланады, бұл көрсеткіштер жасырылады. Егер БҰ-да кітапхана болса, "кітапхананың болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.08.q10':
      '"Компьютерлендіру" бөлімінде бұрын компьютерлер бойынша көрсеткіштер толтырылды, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.08.a10':
      'Егер "компьютерлердің болуы" көрсеткішінде жоқ деп белгіленсе паспорт бөлімі сақталғаннан кейін көрсеткіштер тазаланады, "компьютерлер саны" көрсеткіші жасырылады. БҰ компьютерлер болған жағдайда "компьютерлердің болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.08.q11':
      'Компьютерлендіру бөлімінде бұрын Интернет жылдамдығы бойынша көрсеткіштер толтырылған, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.08.a11':
      '"Ғаламтордың  бар болуы" көрсеткішінде ЖОҚ мәні таңдалса Келісім "шарт бойынша интернеттің жылдамдығы", "Факт бойынша интернеттің жылдамдығы" "Интернет (нүкте) желісіне рұқсаттың болуы, бірлік" көрсеткіштері сақтағаннан кейін жасырылады және тазаланады. БҰ Интернет болған жағдайда "Ғаламтордың  бар болуы" көрсеткішінде "ИӘ" таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.08.q12':
      '"Асхана" бөлімінде "Асханада отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.08.a12':
      '"Асханада отыратын орындар саны, бірлік" көрсеткіші "Асхананың бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ асхана болған жағдайда "Асхананың бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.08.q13':
      '"Асхана" бөлімінде "Буфетте отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.08.a13':
      'Буфетте отыратын орындар саны, бірлік" көрсеткіші "Буфеттің бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ буфет болған жағдайда "Буфеттің бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.08.q14':
      'Неліктен "топтың" көрсеткішіндегі "білім беру процесі туралы негізгі мәліметтер" бөлімінде "топтың оқыту тілі" элементінде "деректер жоқ"шығады?',
    'faq.1.08.a14':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "топтың оқыту тілі" элементінде "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште бірнеше тіл белгіленбесе, "топтың оқыту тілі" элементінде "деректер жоқ" хабарлама шығады.',
    'faq.1.08.q15':
      'Неліктен "Білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "Мектеп оқыту тілі жағына қарай дамиды" көрсеткішінде "деректер жоқ" немесе барлық оқыту тілдері шықпайды?',
    'faq.1.08.a15':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Оқыту тілі"  көрсеткішінде бір де тіл белгіленбесе "Мектеп оқыту тілі жағына қарай дамиды" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.08.q16':
      'Неліктен "білім беру объектісі туралы негізгі мәліметтер" бөлімінде "Сыныптар , сынып-комплектілер саны" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.08.a16':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.08.q17':
      'Неліктен "паспорттауға жауапты" бөлімінде көрсеткіштер бойынша қате пайда болады (қызыл түспен боялған): жұмыс телефоны (код+нөмір), ұялы телефон (нөмір)?',
    'faq.1.08.a17':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.08.q19':
      'Қызметкерді қалай жұмыстан шығаруға болады "еңбек шартын бұзу/тоқтату күні", "еңбек шартын бұзу/тоқтату себебі", "еңбек шартын бұзу/тоқтату туралы бұйрық нөмірі" белсенді емес пе?',
    'faq.1.08.a19':
      'Қызметкерді қызметкерлердің басты бетінен жұмыстан шығара аласыз, әр қызметкерге қарама-қарсы жұмыстан босату"батырмасы бар. Жұмыстан шығару процесі пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.08.q20': 'Неге персоналда қызметкердің" негізгі лауазым ставкасы " 0 шығады?',
    'faq.1.08.a20':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "негізгі лауазымның ставкасы" тек 0 болуы мүмкін.',
    'faq.1.08.q21':
      'Персоналда "Ағымдағы мерзімдегі жалпы еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.08.a21':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі жалпы еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.08.q22':
      'Персоналда "Ағымдағы мерзімдегі педагогикалық еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.08.a22':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі педагогикалық еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.08.q23':
      'Персоналда "оқыту тілі" көрсеткіші неге бос, таңдау элементтері жоқ?',
    'faq.1.08.a23':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе персоналда "оқыту тілі" көрсеткіші бос болады.',
    'faq.1.08.q24':
      'Не себепті персонал бөліміндегі қызметкерде "Қосымша лауазым" көрсеткішінде мөлшерлеме 0 шығады?',
    'faq.1.08.a24':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, әскерде немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "мөлшерлеме" тек 0 болуы мүмкін',
    'faq.1.08.q25':
      'Неліктен қызметкерде "Оқыту сағаты қай сыныпта көп" көрсеткішінде "деректер жоқ"деп шығады?',
    'faq.1.08.a25':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "қай сыныптарда сабақ береді"көрсеткішінде белгіленген сыныптар шығады.',
    'faq.1.08.q26':
      'Қызметкердің жеке деректері өзгерді: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. оларды ҰБДҚ-ға қалай ауыстыруға болады?',
    'faq.1.08.a26':
      'Персоналды қосқан кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Егер бұрын енгізілген персоналдың деректері өзгерсе, оны "дербес деректер" батырмасын басу арқылы жаңартуға болады',
    'faq.1.08.q27': 'Персоналды Excel-ге қалай жүктеуге болады?',
    'faq.1.08.a27':
      'Персонал бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "қызметкерлер" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" батырмасын басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.08.q29':
      'Неліктен контингентте "топ" көрсеткішінде "деректер жоқ" деп шығады?',
    'faq.1.08.a29':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған. "Білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Меншік түріне қарай барлық топтар туралы мәліметтер"көрсеткішінің "Тәрбиелеу және білім беру жылы" элементінде топ атауында таңдалған топтар көрсетіледі. "Топ атауы" "Меншік түріне қарай барлық топтар туралы мәліметтер"көрсеткішіне енгізілген атаулардың біріне сәйкес келетініне көз жеткізіңіз.',
    'faq.1.08.q30':
      'Неліктен контингентте "оқыту тілі "көрсеткішінде "деректер жоқ" деп шығады?',
    'faq.1.08.a30':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе контингентте "оқыту тілі" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.08.q31':
      'Неліктен контингентте Оқылатын шетел тілі 1 және Оқылатын шетел тілі 2 көрсеткіштерінде "деректер жоқ" деп шығады?',
    'faq.1.08.a31':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "топ" көрсеткішінде "Шетел тілі ретінде 1 " және "Шетел тілі ретінде 2" элементтерінде белгіленген тілдер көрсетіледі. "Топ атауы" "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "топ"көрсеткішіне енгізілген атаулардың біріне сәйкес келетініне көз жеткізіңіз.',
    'faq.1.08.q32':
      'Неліктен контингентте "оқу ауысымы" көрсеткішінде "деректер жоқ" деп шығады немесе қажет ауысым жоқ?',
    'faq.1.08.a32':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру объектісі туралы негізгі мәліметтер" бөлімінің "ауысымдар саны" көрсеткішінде белгіленген ауысымдар көрсетіледі, егер көрсеткіш толтырылмаса, контингентте "оқу ауысымы" көрсеткішінде "деректер жоқ" деп шығады, егер "ауысымдар санында" 1 ауысым таңдалса, контингентте тек 1 ауысым ғана шығады және т. б.',
    'faq.1.08.q33':
      'Неліктен контингентте "Аталған білім беру ұйымындағы секциялар мен үйірмелерге қатысады" көрсеткішінде "жоқ (бармайды)" ғана шығады немесе үйірменің не спорт секциясының қажетті түрі шықпайды?',
    'faq.1.08.a33':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Түрі бойынша үйірмелердің және спорттық секциялардың саны көрсеткішінде белгіленген үйірмелер мен секциялар көрсетіледі.',
    'faq.1.08.q34':
      'Неліктен контингентте "мүгедек", "мүгедектік тобы", "мүгедектік себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері белсенді емес? Оларды қалай түзетуге болады?',
    'faq.1.08.a34':
      '"Мүгедек", "мүгедектік тобы", "мүгедектіктің себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері бойынша деректер ҚР Еңбек және халықты әлеуметтік қорғау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "мүгедектік бойынша деректер" батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.08.q35':
      'Неліктен контингентте "Денсаулығы туралы мәлімет" көрсеткіші белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.08.a35':
      '"Денсаулығы туралы мәлімет" көрсеткіші бойынша деректер ҚР Денсаулық сақтау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "денсаулық жағдайы туралы деректер"батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.08.q36':
      'Оқушының дебес деректері: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. өзгерді. Оларды ҰБДҚ-да қалай ауыстыруға болады?',
    'faq.1.08.a36':
      'Контингентті қосқан кезде Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы секілді дербес деректер ЖСН бойынша ЖТ МДҚ-дан сұратылады. Бұрын қосылған контингентте деректер өзгерген болса, оларды "Дербес деректер" батырмасын басқан кезде жаңартуға болады.',
    'faq.1.08.q37': 'Оқушыны қалай шығаруға болады? Шығу күні белсенді емес',
    'faq.1.08.a37':
      'Оқушылардың шығарылуын контингенттің басты бетінен ресімдеуге болады, әр оқушыға қарама-қарсы "шығаруды ресімдеу"батырмасы орналасқан. Шығару процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.08.q38':
      'Неліктен "Шығаруды, көшіруді немесе қабылдауды рәсімдеу" бөлімінде түлектер жоқ?',
    'faq.1.08.a38':
      'Аталған бөлімнің "Түлек" қойындысында Орта білім беру туралы аттестат (Аттестат сериясы) /Білім беру туралы куәлік (Куәлік сериясы), Орта білім беру туралы аттестат (Аттестат нөмірі) /Білім беру туралы куәлік (Куәлік нөмірі), Орта білім беру туралы аттестат (Берілу күні) /Білім беру туралы куәлік (Берілу күні) көрсеткіштері толтырған оқушылар көрсетіледі',
    'faq.1.08.q39': '"Контингент" бөлімі неге жоқ?',
    'faq.1.08.a39':
      'ПМПК және ППТК түріндегі арнайы білім беру ұйымдары "Контингент" бөлімін толтырмайды, қол жетімсіз ',
    'faq.1.08.q40':
      'Контингентті қосқан кезде оқушы басқа білім беру ұйымында тіркелгендігі хабарлама шығады, не істеу керек?',
    'faq.1.08.a40':
      'Басқа білім беру ұйымында тіркелген оқушыны қосқан кезде  байланыс деректері бар хабарлама шығады.  Мекемемен байланыс болмаған жағдайда ЖАО-ға немесе қолдау қызметінің "көмек" бөлімінде деректері көрсетілген қолдау қызметіне хабарласыңыз',
    'faq.1.08.q41':
      'Бұрын қосылған қызметкердің/контингенттің қате ЖСН-ін қалай түзетуге болады?',
    'faq.1.08.a41':
      'Контингент пен персоналдың қате ЖСН түзету үшін нысанды толтырып, қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. Нысан "Көмек"бөлімінде орналастырылған.',
    'faq.1.08.q42': 'Контингентті Excel-ге қалай экспорттауға болады?',
    'faq.1.08.a42':
      'Контингент бөлімінде пайдаланушы одан әрі жұмыс істеу үшін контингент тізімін Excel-ге экспорттай алады. Ол үшін "Контингент" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау"түймесін басу керек. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.08.q43': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.08.a43':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.08.q44': 'БҰ паспортына кім қол қояды?',
    'faq.1.08.a44':
      'БҰ паспортына БҰ басшысы қол қояды - заңды тұлғалар үшін  паспортқа заңды тұлғаның ЭСҚ арқылы қол қояды (GOST), мекеменің БСН мен ЭСҚ БСН сәйкестігі тексерілетін болады.  <br/>Жеке кәсіпкерлер үшін паспортқа қол қою жеке тұлғаның ЭСҚ (RSA) арқылы жүргізіледі, бұл ретте жүйе паспортқа қол қою кезінде пайдаланушының профиліндегі ЖСН-ді ЭСҚ-дағы ЖСН-мен салыстырып тексереді. Егер ЖСН толтырылмаса пайдаланушы профилінде қол қою мүмкін емес.',
    'faq.1.08.q45':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.1.08.a45':
      'Егер пайдаланушы жүйеге кірген кезде құпиясөзді ұмытып қалса, "құпиясөзді ұмыттыңыз ба?" басу немесе ЖАО-ға, техникалық қолдау қызметіне "көмек" бөліміне жүгіну қажет.',
    'faq.1.08.q46': '"Оқушының траекториясы" дегеніміз не?',
    'faq.1.08.a46':
      'Бұл НОБД модулі білім беру ұйымы қызметкеріне оқушының іс жүзіндегі дерекнамасын көруге мүмкіндік береді. Модульмен жұмыс істеудің егжей-тегжейлі процесі оқушы қозғалысының траекториясы модулінің пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.08.q47': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.1.08.a47':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу"батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою"қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.1.08.q48':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.1.08.a48':
      'Бұл хабарламада пайдаланушы белсендірілмегені  туралы айтады. Пайдаланушыны іске қосу үшін ЖАО-ға хабарласыңыз.',
    'faq.1.08.q49': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.1.08.a49': 'ҰБДҚ толтыру жөніндегі нұсқаулық "Көмек" бөлімінде орналастырылған',
    'faq.1.08.q50': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.1.08.a50': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.1.08.q51': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.1.08.a51':
      '"Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.1.08.q52': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.1.08.a52':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',
    'faq.1.09.q1':
      'Білім беру ұйымының атауы мен түрі туралы мәліметтерді қайдан көруге болады?',
    'faq.1.09.a1': 'Төлқұжатта ұйымның деректері бөлімін ашу (кеңейту)',
    'faq.1.09.q2': 'Ұйымның атауы өзгерді оны қалай өңдеуге болады?',
    'faq.1.09.a2':
      'Білім беру ұйымының атауын (білім беру ұйымының атауы, қазақ тіліндегі толық атауы, қазақ тіліндегі қысқаша атауы, орыс тіліндегі толық атауы, орыс тіліндегі қысқаша атауы) білім беру ұйымын құру кезінде білім басқармасы немесе бөлімі (бұдан әрі - ЖАО) толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет',
    'faq.1.09.q3':
      'Неліктен "тіркеу" бөліміндегі "білім беру ұйымдарының түрлері", "білім беру ұйымының БСН", "меншік нысаны", "аумақтық тиесілілігі бойынша ұйым", "ұйымдық - құқықтық нысан", "құрамдас бөліктің типі", " үйдің №", "негізгі құрылтайшылар", "білім беру ұйымының ашылу күні" және "білім беру ұйымының жабылу күні" көрсеткіштері редакциялауға қолжетімсіз:? Бұл көрсеткіштерде деректерді қалай түзетуге болады?',
    'faq.1.09.a3':
      'Бұл көрсеткіштерді білім басқармасы немесе білім бөлімі (бұдан әрі - ЖАО) білім беру ұйымын құру кезінде толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет.',
    'faq.1.09.q4':
      'Неліктен "Тіркеу мәліметтері" бөлімінде Факс (код+нөмір), Жұмыс телефоны (код+нөмір), ұялы телефон (нөмір) көрсеткіштері бойынша қате шығады (қызыл түспен боялады) ?',
    'faq.1.09.a4':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.09.q5': 'Неліктен" ғимараттар саны " көрсеткіші белсенді емес?',
    'faq.1.09.a5':
      'Көрсеткіш "ғимараттар туралы мәліметтер"көрсеткішіне енгізілген деректерден автоматты түрде есептеледі.',
    'faq.1.09.q6':
      '"Кітапхана / оқулықтар" бөлімінде "барлығы ( оқулықтар, көркем әдебиеттер, брошюралар және журналдар)" көрсеткішін қалай түзетуге болады?   ',
    'faq.1.09.a6':
      'Көрсеткіш автоматты түрде есептеледі – "оның ішінде оқулықтар" + "оның ішінде кітаптар (көркем әдебиеттер, брошюралар және журналдар)" жолдарының сомасы.',
    'faq.1.09.q7':
      'Неліктен "Кітапхана / оқулықтар " бөлімінде "Оқу жылының басында келіп түскен оқулықтарды ескере отырып оқушылардың қамтамасыздандырылуы" көрсеткішінде "оқыту тілі" элементінде "деректер жоқ" немесе барлық оқыту тілдері шықпайды?',
    'faq.1.09.a7':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште тіл белгіленбесе, "оқыту тілі" элементінде деректер болмайды.',
    'faq.1.09.q8':
      'Неліктен "Кітапхана/оқулықтар" бөлімінде "Оқу жылының басында келіп түскен оқулықтарды ескере отырып оқушылардың қамтамасыздандырылуы" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.09.a8':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Оқыту нешінші сыныптан бастап жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі',
    'faq.1.09.q9':
      '"Кітапхана / оқулықтар" бөлімінде бұрын келесі көрсеткіштер толтырылған болатын: "Кітапхана, бірлік", "кітапхана алаңы, ш.м.", "оқу залдарының саны, бірлік", "оқу залдарындағы отыратын орындардың саны", "оқырмандар саны, адам", "келушілер саны, адам" көрсеткіштері қазір көрсетілмейді ме?',
    'faq.1.09.a9':
      'Егер "Кітапхананың болуы" көрсеткішіне Жоқ деп таңдалса паспорт  сақталғаннан кейін көрсеткіштер тазаланады, бұл көрсеткіштер жасырылады. Егер БҰ-да кітапхана болса, "кітапхананың болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.09.q10':
      '"Компьютерлендіру" бөлімінде бұрын компьютерлер бойынша көрсеткіштер толтырылды, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.09.a10':
      'Егер "компьютерлердің болуы" көрсеткішінде жоқ деп белгіленсе паспорт  сақталғаннан кейін көрсеткіштер тазаланады, "компьютерлер саны" көрсеткіші жасырылады. БҰ компьютерлер болған жағдайда "компьютерлердің болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.09.q11':
      'Компьютерлендіру бөлімінде бұрын Интернет жылдамдығы бойынша көрсеткіштер толтырылған, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.09.a11':
      '"Ғаламтордың  бар болуы" көрсеткішінде ЖОҚ мәні таңдалса Келісім "шарт бойынша интернеттің жылдамдығы", "Факт бойынша интернеттің жылдамдығы" "Интернет (нүкте) желісіне рұқсаттың болуы, бірлік" көрсеткіштері сақтағаннан кейін жасырылады және тазаланады. БҰ Интернет болған жағдайда "Ғаламтордың  бар болуы" көрсеткішінде "ИӘ" таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.09.q12':
      '"Асхана" бөлімінде "Асханада отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.09.a12':
      '"Асханада отыратын орындар саны, бірлік" көрсеткіші "Асхананың бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ асхана болған жағдайда "Асхананың бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.09.q13':
      '"Асхана" бөлімінде "Буфетте отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.09.a13':
      '"Буфетте отыратын орындар саны, бірлік" көрсеткіші "Буфеттің бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ буфет болған жағдайда "Буфеттің бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.09.q14':
      'Неліктен "Білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "Мектеп оқыту тілі жағына қарай дамиды" көрсеткішінде "деректер жоқ" немесе барлық оқыту тілдері шықпайды?',
    'faq.1.09.a14':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Оқыту тілі"  көрсеткішінде бір де тіл белгіленбесе "Мектеп оқыту тілі жағына қарай дамиды" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.09.q15':
      'Неліктен "Білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "Пәндерді тереңдетіп оқытатын сыныптардың саны" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.09.a15':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.09.q16':
      'Неліктен "білім беру үдерісі туралы негізгі мәліметтер" бөлімінде "пәндерді көптілді оқыту" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.09.a16':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.09.q17':
      'Неліктен "білім беру объектісі туралы негізгі мәліметтер" бөлімінде "Сыныптар , сынып-комплектілер саны" көрсеткішінде "сынып" элементінде барлық сыныптар шықпайды?',
    'faq.1.09.a17':
      'Көрсеткіште форматтық-логикалық бақылау орнатылған, сыныптар "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "қай сыныптан бастап оқыту жүргізіледі" көрсеткішінде белгіленген сыныптан бастап көрсетіледі.',
    'faq.1.09.q18':
      'Неліктен «Қамқоршылық кеңес мүшелерінің саны» индикаторы белсенді емес?',
    'faq.1.09.a18':
      'Көрсеткіш «Қамқоршылық кеңестің құрамы туралы мәліметтер » индикаторына енгізілген мәліметтерден автоматты түрде есептеледі.',
    'faq.1.09.q19':
      'Неліктен "паспорттауға жауапты" бөлімінде көрсеткіштер бойынша қате пайда болады (қызыл түспен боялған): жұмыс телефоны (код+нөмір), ұялы телефон (нөмір)?',
    'faq.1.09.a19':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.09.q21':
      'Қызметкерді қалай жұмыстан шығаруға болады "еңбек шартын бұзу/тоқтату күні", "еңбек шартын бұзу/тоқтату себебі", "еңбек шартын бұзу/тоқтату туралы бұйрық нөмірі" белсенді емес пе?',
    'faq.1.09.a21':
      'Қызметкерді қызметкерлердің басты бетінен жұмыстан шығара аласыз, әр қызметкерге қарама-қарсы жұмыстан босату"батырмасы бар. Жұмыстан шығару процесі пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.09.q22': 'Неге персоналда қызметкердің" негізгі лауазым ставкасы " 0 шығады?',
    'faq.1.09.a22':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "негізгі лауазымның ставкасы" тек 0 болуы мүмкін.',
    'faq.1.09.q23':
      'Персоналда "Ағымдағы мерзімдегі жалпы еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.09.a23':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі жалпы еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.09.q24':
      'Персоналда "Ағымдағы мерзімдегі педагогикалық еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.09.a24':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі педагогикалық еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.09.q25':
      'Персоналда "оқыту тілі" көрсеткіші неге бос, таңдау элементтері жоқ?',
    'faq.1.09.a25':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе персоналда "оқыту тілі" көрсеткіші бос болады.',
    'faq.1.09.q26':
      'Не себепті персонал бөліміндегі қызметкерде "Қосымша лауазым" көрсеткішінде мөлшерлеме 0 шығады?',
    'faq.1.09.a26':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, әскерде немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "мөлшерлеме" тек 0 болуы мүмкін',
    'faq.1.09.q27':
      'Неліктен қызметкерде "Оқыту сағаты қай сыныпта көп" көрсеткішінде "деректер жоқ"деп шығады?',
    'faq.1.09.a27':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "қай сыныптарда сабақ береді"көрсеткішінде белгіленген сыныптар шығады.',
    'faq.1.09.q28':
      'Қызметкердің жеке деректері өзгерді: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. оларды ҰБДҚ-ға қалай ауыстыруға болады?',
    'faq.1.09.a28':
      'Персоналды қосқан кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Егер бұрын енгізілген персоналдың деректері өзгерсе, оны "дербес деректер" батырмасын басу арқылы жаңартуға болады',
    'faq.1.09.q29': 'Қызметкерлерді Excel-ге қалай жүктеуге болады?',
    'faq.1.09.a29':
      'Персонал бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "қызметкерлер" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" батырмасын басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.09.q31':
      'Неліктен контингентте "оқыту тілі "көрсеткішінде "деректер жоқ" деп шығады?',
    'faq.1.09.a31':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе контингентте "оқыту тілі" көрсеткішінде "деректер жоқ" деп шығады',
    'faq.1.09.q32':
      'Неліктен контингентте "оқу ауысымы" көрсеткішінде "деректер жоқ" деп шығады немесе қажет ауысым жоқ?',
    'faq.1.09.a32':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру объектісі туралы негізгі мәліметтер" бөлімінің "ауысымдар саны" көрсеткішінде белгіленген ауысымдар көрсетіледі, егер көрсеткіш толтырылмаса, контингентте "оқу ауысымы" көрсеткішінде "деректер жоқ" деп шығады, егер "ауысымдар санында" 1 ауысым таңдалса, контингентте тек 1 ауысым ғана шығады және т. б.',
    'faq.1.09.q33':
      'Неліктен контингентте "Аталған білім беру ұйымындағы секциялар мен үйірмелерге қатысады" көрсеткішінде "жоқ (бармайды)" ғана шығады немесе үйірменің не спорт секциясының қажетті түрі шықпайды?',
    'faq.1.09.a33':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру үдерісі туралы негізгі мәліметтер" бөлімінің "Түрі бойынша үйірмелердің және спорттық секциялардың саны көрсеткішінде белгіленген үйірмелер мен секциялар көрсетіледі.',
    'faq.1.09.q34':
      'Неліктен контингентте "мүгедек", "мүгедектік тобы", "мүгедектік себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері белсенді емес? Оларды қалай түзетуге болады?',
    'faq.1.09.a34':
      '"Мүгедек", "мүгедектік тобы", "мүгедектіктің себебі", "мүгедектік мерзімі", "мүгедектік мерзімі дейін" көрсеткіштері бойынша деректер ҚР Еңбек және халықты әлеуметтік қорғау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "мүгедектік бойынша деректер" батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.09.q35':
      'Неліктен контингентте "Денсаулығы туралы мәлімет" көрсеткіші белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.09.a35':
      '"Денсаулығы туралы мәлімет" көрсеткіші бойынша деректер ҚР Денсаулық сақтау министрлігінің ақпараттық жүйесінен келіп түседі. АЖ-ға сұрау контингент қосылған кезде автоматты түрде жіберіледі, бұрын қосылған контингентте "денсаулық жағдайы туралы деректер"батырмасының көмегімен ақпаратты сұрату қажет.',
    'faq.1.09.q36':
      'Оқушының дебес деректері: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. өзгерді. Оларды ҰБДҚ-да қалай ауыстыруға болады?',
    'faq.1.09.a36':
      'Контингентті қосқан кезде Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы секілді дербес деректер ЖСН бойынша ЖТ МДҚ-дан сұратылады. Бұрын қосылған контингентте деректер өзгерген болса, оларды "Дербес деректер" батырмасын басқан кезде жаңартуға болады.',
    'faq.1.09.q37': 'Оқушыны қалай шығаруға болады? Шығу күні белсенді емес',
    'faq.1.09.a37':
      'Оқушылардың шығарылуын контингенттің басты бетінен ресімдеуге болады, әр оқушыға қарама-қарсы "шығаруды ресімдеу"батырмасы орналасқан. Шығару процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.09.q38':
      'Неліктен "Шығаруды, көшіруді немесе қабылдауды рәсімдеу" бөлімінде түлектер жоқ?',
    'faq.1.09.a38':
      'Аталған бөлімнің "Түлек" қойындысында Орта білім беру туралы аттестат (Аттестат сериясы) /Білім беру туралы куәлік (Куәлік сериясы), Орта білім беру туралы аттестат (Аттестат нөмірі) /Білім беру туралы куәлік (Куәлік нөмірі), Орта білім беру туралы аттестат (Берілу күні) /Білім беру туралы куәлік (Берілу күні) көрсеткіштері толтырған оқушылар көрсетіледі',
    'faq.1.09.q39': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.09.a39':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.09.q40':
      'Контингентті қосқан кезде оқушы басқа білім беру ұйымында тіркелгендігі хабарлама шығады, не істеу керек?',
    'faq.1.09.a40':
      'Басқа білім беру ұйымында тіркелген оқушыны қосқан кезде  байланыс деректері бар хабарлама шығады.  Мекемемен байланыс болмаған жағдайда ЖАО-ға немесе қолдау қызметінің "көмек" бөлімінде деректері көрсетілген қолдау қызметіне хабарласыңыз',
    'faq.1.09.q41':
      'Бұрын қосылған қызметкердің/контингенттің қате ЖСН-ін қалай түзетуге болады?',
    'faq.1.09.a41':
      'Контингент пен персоналдың қате ЖСН түзету үшін нысанды толтырып, қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. Нысан "Көмек"бөлімінде орналастырылған.',
    'faq.1.09.q42': 'Контингентті Excel-ге қалай экспорттауға болады?',
    'faq.1.09.a42':
      'Контингент бөлімінде пайдаланушы одан әрі жұмыс істеу үшін контингент тізімін Excel-ге экспорттай алады. Ол үшін "Контингент" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау"түймесін басу керек. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.09.q43': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.09.a43':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.09.q44': 'БҰ паспортына кім қол қояды?',
    'faq.1.09.a44':
      'БҰ паспортына БҰ басшысы қол қояды - заңды тұлғалар үшін  паспортқа заңды тұлғаның ЭСҚ арқылы қол қояды (GOST), мекеменің БСН мен ЭСҚ БСН сәйкестігі тексерілетін болады.  <br/>Жеке кәсіпкерлер үшін паспортқа қол қою жеке тұлғаның ЭСҚ (RSA) арқылы жүргізіледі, бұл ретте жүйе паспортқа қол қою кезінде пайдаланушының профиліндегі ЖСН-ді ЭСҚ-дағы ЖСН-мен салыстырып тексереді. Егер ЖСН толтырылмаса пайдаланушы профилінде қол қою мүмкін емес.',
    'faq.1.09.q45':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.1.09.a45':
      'Егер пайдаланушы жүйеге кірген кезде құпиясөзді ұмытып қалса, "құпиясөзді ұмыттыңыз ба?" басу немесе ЖАО-ға, техникалық қолдау қызметіне "көмек" бөліміне жүгіну қажет.',
    'faq.1.09.q46': '"Оқушының траекториясы" дегеніміз не?',
    'faq.1.09.a46':
      'Бұл НОБД модулі білім беру ұйымы қызметкеріне оқушының іс жүзіндегі дерекнамасын көруге мүмкіндік береді. Модульмен жұмыс істеудің егжей-тегжейлі процесі оқушы қозғалысының траекториясы модулінің пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.09.q47': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.1.09.a47':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу"батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою"қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.1.09.q48':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.1.09.a48':
      'Бұл хабарламада пайдаланушы белсендірілмегені  туралы айтады. Пайдаланушыны іске қосу үшін ЖАО-ға хабарласыңыз.',
    'faq.1.09.q49': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.1.09.a49': 'ҰБДҚ толтыру жөніндегі нұсқаулық "Көмек" бөлімінде орналастырылған',
    'faq.1.09.q50': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.1.09.a50': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.1.09.q51': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.1.09.a51':
      'Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.1.09.q52': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.1.09.a52':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',

    'faq.1.10.q1':
      'Білім беру ұйымының атауы мен түрі туралы мәліметтерді қайдан көруге болады?',
    'faq.1.10.a1': 'Төлқұжатта ұйымның деректері бөлімін ашу (кеңейту)',
    'faq.1.10.q2': 'Ұйымның атауы өзгерді оны қалай өңдеуге болады?',
    'faq.1.10.a2':
      'Білім беру ұйымының атауын (білім беру ұйымының атауы, қазақ тіліндегі толық атауы, қазақ тіліндегі қысқаша атауы, орыс тіліндегі толық атауы, орыс тіліндегі қысқаша атауы) білім беру ұйымын құру кезінде білім басқармасы немесе бөлімі (бұдан әрі - ЖАО) толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет',
    'faq.1.10.q3':
      'Неліктен "тіркеу" бөліміндегі "білім беру ұйымдарының түрлері", "білім беру ұйымының БСН", "меншік нысаны", "аумақтық тиесілілігі бойынша ұйым", "ұйымдық - құқықтық нысан", "құрамдас бөліктің типі", " үйдің №", "негізгі құрылтайшылар", "білім беру ұйымының ашылу күні" және "білім беру ұйымының жабылу күні" көрсеткіштері редакциялауға қолжетімсіз:? Бұл көрсеткіштерде деректерді қалай түзетуге болады?',
    'faq.1.10.a3':
      'Бұл көрсеткіштерді білім басқармасы немесе білім бөлімі (бұдан әрі - ЖАО) білім беру ұйымын құру кезінде толтырады және тек ЖАО білім беру ұйымдарының тізілімінде редакциялайды. Пайдаланушы ЖАО қызметкерімен байланысуы қажет.',
    'faq.1.10.q4':
      'Неліктен "Тіркеу мәліметтері" бөлімінде Факс (код+нөмір), Жұмыс телефоны (код+нөмір), ұялы телефон (нөмір) көрсеткіштері бойынша қате шығады (қызыл түспен боялады) ?',
    'faq.1.10.a4':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.10.q5': 'Неліктен" ғимараттар саны " көрсеткіші белсенді емес?',
    'faq.1.10.a5':
      'Көрсеткіш "ғимараттар туралы мәліметтер"көрсеткішіне енгізілген деректерден автоматты түрде есептеледі.',
    'faq.1.10.q6':
      '"Компьютерлендіру" бөлімінде бұрын компьютерлер бойынша көрсеткіштер толтырылды, қазір көрсеткіштер көрсетілмейді ме?',
    'faq.1.10.a6':
      'Егер "компьютерлердің болуы" көрсеткішінде жоқ деп белгіленсе паспорт  сақталғаннан кейін көрсеткіштер тазаланады, "компьютерлер саны" көрсеткіші жасырылады. БҰ компьютерлер болған жағдайда "компьютерлердің болуы" көрсеткішін басыңыз, иә, Егер деректер бұрын толтырылған болса және паспорт сақталмаса, көрсеткіштерде көрсетіледі.',
    'faq.1.10.q7':
      '"Асхана" бөлімінде "Асханада отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.10.a7':
      '"Асханада отыратын орындар саны, бірлік" көрсеткіші "Асхананың бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ асхана болған жағдайда "Асхананың бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.10.q8':
      '"Асхана" бөлімінде "Буфетте отыратын орындар саны, бірлік" көрсеткіші бұрын толтырылған болатын, қазір көрсетілмейді ме?',
    'faq.1.10.a8':
      '"Буфетте отыратын орындар саны, бірлік" көрсеткіші "Буфеттің бар болуы" көрсеткішінде ЖОҚ таңдалса  паспорт сақталғаннан кейін көрсеткіштер тазаланады, көрсеткіш жасырылады. БҰ буфет болған жағдайда "Буфеттің бар болуы" көрсеткішінде ИӘ таңдаңыз, егер көрсеткіштер бұрын толтырылған болса және паспорт сақталмаса олар көрсеткіштерде көрсетіледі',
    'faq.1.10.q9':
      'Неліктен «Қамқоршылық кеңес мүшелерінің саны» индикаторы белсенді емес?',
    'faq.1.10.a9':
      'Көрсеткіш «Қамқоршылық кеңестің құрамы туралы мәліметтер » индикаторына енгізілген мәліметтерден автоматты түрде есептеледі.',
    'faq.1.10.q10':
      'Неліктен "паспорттауға жауапты" бөлімінде көрсеткіштер бойынша қате пайда болады (қызыл түспен боялған): жұмыс телефоны (код+нөмір), ұялы телефон (нөмір)?',
    'faq.1.10.a10':
      'Көрсеткіштерге форматтық-логикалық бақылау орнатылған. Нөмір 11 саннан тұруы, символдары болмауы және 8-ден басталуы тиіс. Барлық таңбаларды сызықшалар, жақшалар, плюс белгісі және т.б. алып тастаңыз, нөмір 8-ден басталып, тек 11 саннан тұратынын тексеріңіз.',
    'faq.1.10.q12':
      'Қызметкерді қалай жұмыстан шығаруға болады "еңбек шартын бұзу/тоқтату күні", "еңбек шартын бұзу/тоқтату себебі", "еңбек шартын бұзу/тоқтату туралы бұйрық нөмірі" белсенді емес пе?',
    'faq.1.10.a12':
      'Қызметкерді қызметкерлердің басты бетінен жұмыстан шығара аласыз, әр қызметкерге қарама-қарсы жұмыстан босату"батырмасы бар. Жұмыстан шығару процесі пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.10.q13': 'Неге персоналда қызметкердің" негізгі лауазым ставкасы " 0 шығады?',
    'faq.1.10.a13':
      'Көрсеткішке форматтық-логикалық бақылау белгіленген, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, армияда немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "негізгі лауазымның ставкасы" тек 0 болуы мүмкін.',
    'faq.1.10.q14':
      'Персоналда "Ағымдағы мерзімдегі жалпы еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.10.a14':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі жалпы еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.10.q15':
      'Персоналда "Ағымдағы мерзімдегі педагогикалық еңбек өтілі" көрсеткіші неге белсенді емес? Оны қалай түзетуге болады?',
    'faq.1.10.a15':
      'Көрсеткіш жыл сайын бірінші қаңтардан кейін автоматты түрде артады және "Жұмысқа қабылдау кезіндегі педагогикалық еңбек өтілі"көрсеткішіне енгізілген санға байланысты болады',
    'faq.1.10.q16':
      'Персоналда "оқыту тілі" көрсеткіші неге бос, таңдау элементтері жоқ?',
    'faq.1.10.a16':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған "білім беру процесі туралы негізгі мәліметтер" бөлімінің "оқыту тілі" көрсеткішінде белгіленген тілдер көрсетіледі, егер көрсеткіште ешқандай тіл белгіленбесе персоналда "оқыту тілі" көрсеткіші бос болады.',
    'faq.1.10.q17':
      'Не себепті персонал бөліміндегі қызметкерде "Қосымша лауазым" көрсеткішінде мөлшерлеме 0 шығады?',
    'faq.1.10.a17':
      'Көрсеткішке форматтық-логикалық бақылау орнатылған, егер қызметкер қазіргі уақытта декреттік демалыста, науқастануына байланысты демалыста, әскерде немесе жалақысы сақталмайтын демалыста болса ("қызметкердің ағымдағы мәртебесі" көрсеткіші), онда "мөлшерлеме" тек 0 болуы мүмкін',
    'faq.1.10.q18':
      'Қызметкердің жеке деректері өзгерді: Тегі, Аты, Әкесінің аты, Азаматтығы және т. б. оларды ҰБДҚ-ға қалай ауыстыруға болады?',
    'faq.1.10.a18':
      'Персоналды қосқан кезде Дербес деректер Тегі, Аты, Әкесінің аты, Азаматтығы, ұлты, жынысы ЖСН бойынша ЖТ МДҚ-дан сұратылады. Егер бұрын енгізілген персоналдың деректері өзгерсе, оны "дербес деректер" батырмасын басу арқылы жаңартуға болады',
    'faq.1.10.q19': 'Қызметкерлерді Excel-ге қалай түсіруге болады?',
    'faq.1.10.a19':
      'Персонал бөлімінде пайдаланушы одан әрі жұмыс істеу үшін қызметкерлер тізімін Excel-ге экспорттай алады. Ол үшін "қызметкерлер" немесе "кеткендер" қойындысындағы терезенің оң жақ бұрышындағы "Excel-ге экспорттау" батырмасын басыңыз. Экспорт аяқталғаннан кейін экспорттың сәтті аяқталғаны туралы хабарлама шығады.',
    'faq.1.10.q20':
      'Бұрын қосылған қызметкердің/контингенттің қате ЖСН-ін қалай түзетуге болады?',
    'faq.1.10.a20':
      'Контингент пен персоналдың қате ЖСН түзету үшін нысанды толтырып, қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. Нысан "Көмек"бөлімінде орналастырылған.',
    'faq.1.10.q21': 'Құпия сөзді қалай өзгертуге болады?',
    'faq.1.10.a21':
      'Терезенің оң жағында пайдаланушы профилін басқару мәзірі орналасқан. Тізімді ашып, "құпия сөзді ауыстыруды" таңдап, құпия сөзді ауыстыру қажет. Құпия сөзді өзгерту және пайдаланушы профилімен жұмыс істеу процесі пайдаланушы нұсқаулығында егжей-тегжейлі сипатталған.',
    'faq.1.10.q22': 'БҰ паспортына кім қол қояды?',
    'faq.1.10.a22':
      'БҰ паспортына БҰ басшысы қол қояды - заңды тұлғалар үшін  паспортқа заңды тұлғаның ЭСҚ арқылы қол қояды (GOST), мекеменің БСН мен ЭСҚ БСН сәйкестігі тексерілетін болады.  <br/>Жеке кәсіпкерлер үшін паспортқа қол қою жеке тұлғаның ЭСҚ (RSA) арқылы жүргізіледі, бұл ретте жүйе паспортқа қол қою кезінде пайдаланушының профиліндегі ЖСН-ді ЭСҚ-дағы ЖСН-мен салыстырып тексереді. Егер ЖСН толтырылмаса пайдаланушы профилінде қол қою мүмкін емес.',
    'faq.1.10.q23':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.1.10.a23':
      'Егер пайдаланушы жүйеге кірген кезде құпиясөзді ұмытып қалса, "құпиясөзді ұмыттыңыз ба?" басу немесе ЖАО-ға, техникалық қолдау қызметіне "көмек" бөліміне жүгіну қажет.',
    'faq.1.10.q24': '"Оқушының траекториясы" дегеніміз не?',
    'faq.1.10.a24':
      'Бұл НОБД модулі білім беру ұйымы қызметкеріне оқушының іс жүзіндегі дерекнамасын көруге мүмкіндік береді. Модульмен жұмыс істеудің егжей-тегжейлі процесі оқушы қозғалысының траекториясы модулінің пайдаланушы нұсқаулығында сипатталған.',
    'faq.1.10.q25': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.1.10.a25':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу"батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою"қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.1.10.q26':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.1.10.a26':
      'Бұл хабарламада пайдаланушы белсендірілмегені  туралы айтады. Пайдаланушыны іске қосу үшін ЖАО-ға хабарласыңыз.',
    'faq.1.10.q27': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.1.10.a27': 'ҰБДҚ толтыру жөніндегі нұсқаулық "Көмек" бөлімінде орналастырылған',
    'faq.1.10.q28': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.1.10.a28': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.1.10.q29': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.1.10.a29':
      'Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.1.10.q30': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.1.10.a30':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',
    'faq.2.q1': 'Неліктен ЖАО паспорты белсенді емес-редакцияланбайды?',
    'faq.2.a1': 'ЖАО паспортын өңдеу құқығы тек "қызметкер"рөлі бар пайдаланушыда бар.',
    'faq.2.q2': 'Қол қою кезінде қате шығады',
    'faq.2.a2':
      'Сіздің компьютеріңізде NCALayer орнатылған ба, қолтаңба иесінің ЖСН және пайдаланушының бейініндегі ЖСН сәйкес келе ме, егер заңды тұлғаның қойған қолы білім беру ұйымының БСН және ЭЦҚ-дағы БСН сәйкес келсе, қойылған қол жарамды ма (ЭЦҚ қолданылу мерзімі аяқталмаған)тексеріңіз',
    'faq.2.q3':
      'Неліктен оқушының қозғалыс траекториясында қазіргі жағдайы өрісінде "білім беру ұйымында есепте тұр (ұйымның атауы толтырылмаған)" деген хабарлама шығады?',
    'faq.2.a3':
      'Оқушы тіркелген ЖШС ұйымының атауы толтырылмаған. ЖАО қызметкері білім беру ұйымдарының тізіліміне өтіп, ББ атауы бойынша барлық жолдарды толтыруы қажет',
    'faq.2.q4':
      'БҰ жапқан кезде "Ақпаратты сақтау мүмкін емес, Мекемені жабу мүмкін емес. Персонал және контингент бөліміндегі оқушылар мен қызметкерлерді шығару қажет" деген хабарлама шығады',
    'faq.2.a4':
      'БҰ-да жұмыс істейтін персонал және (немесе) контингент бар. Жабу алдында БҰ барлық қызметкерлерді жұмыстан шығарып, барлық оқушылардың кетуін ресімдеуі қажет.',
    'faq.2.q5': 'Неге құрылған білім беру ұйымында "негізгі құрылтайшылар" өрісі бос?',
    'faq.2.a5':
      'Заңды тұлғалар үшін БСН бойынша ЗТ МДҚ-дан деректерді және жеке тұлғалар үшін ЖСН бойынша ЖТ МДҚ-дан деректерді сұратыңыз.',
    'faq.2.q6': 'Білім беру ұйымының пайдаланушысына парольді қалай өзгертуге болады',
    'faq.2.a6':
      'Пайдаланушыларды басқару модуліне өтіп, пайдаланушы профилін ашып, құпия сөзді өзгертіңіз.',
    'faq.2.q7':
      'ҰБДҚ жүйесінде авторизациялау кезінде қате ЖСН немесе пароль хабары шығады, қайталап көріңіз.',
    'faq.2.a7':
      'Егер пайдаланушы жүйеге кірген кезде құпия сөзді ұмытып қалса," құпия сөзді ұмыттыңыз ба?" батырмасына басу қажет немесе техникалық қолдау қызметіне "көмек" бөліміне хабарласыңыз.',
    'faq.2.q8': 'ҰБДҚ жүйесінде қалай тіркелуге болады?',
    'faq.2.a8':
      'Тіркелу үшін "тіркелу" сілтемесі бойынша өтіп, барлық міндетті жолдарды толтырып, дербес деректерді өңдеуге және сақтауға келісіміңізді растаңыз, "қол қою және Жіберу"батырмасын басыңыз. Пайдаланушының алдында ЭСҚ таңдау терезесі пайда болады. Ұсынылған ЭСҚ түрлері: Казтокен тасығышында, компьютерде, жеке куәлікте. ЭСҚ тасымалдаушысын таңдағаннан кейін пайдаланушы ЭСҚ кілті орналасқан жолды көрсетуі, папканы ашуы және rsa_******** кілтін таңдауы, ашуға басуы тиіс. Содан кейін ЭСҚ-дан парольді теріп, "Ашу" батырмасын басу және одан әрі "қол қою"қажет. Процестің толық сипаттамасы пайдаланушы нұсқаулығында сипатталған',
    'faq.2.q9':
      'Авторизация кезінде "пайдаланушы іске қосылмаған" деген хабарлама шықты. Не істеу керек?',
    'faq.2.a9':
      'Осы хабар пайдаланушы белсендірілмегендігі туралы айтады. Іске қосу үшін техникалық қолдау қызметіне "көмек"бөліміне хабарласыңыз.',
    'faq.2.q10': 'ҰБДҚ толтыру жөніндегі нұсқаулықты қайдан көруге болады?',
    'faq.2.a10': 'ҰБДҚ толтыру жөніндегі нұсқаулық "Көмек" бөлімінде орналастырылған',
    'faq.2.q11': 'ҰБДҚ-дағы пайдаланушы нұсқаулығын қайдан қарауға болады?',
    'faq.2.a11': 'ҰБДҚ-дағы пайдаланушы нұсқаулығы "Көмек" бөлімінде орналастырылған',
    'faq.2.q12': 'Интерфейс тілін қалай өзгертуге болады?',
    'faq.2.a12':
      'Жоғарғы сол жақ бұрышта тіл тақтасы орналасқан, қазақ немесе орыс тіліне ауысыңыз.',
    'faq.2.q13': 'Сұрағыңызға жауап тапқан жоқсыз ба?',
    'faq.2.a13':
      'Егер сіз "жиі қойылатын сұрақтар" бөлімінде және пайдаланушы нұсқаулығында жауап таба алмасаңыз, қолдау қызметіне хабарласыңыз.',
    'faq.2.q14': 'Бұрын құрылған БҰ қате БСН қалай түзетуге болады',
    'faq.2.a14':
      'Қате БСН түзету үшін, нысанды толтырыңыз және қолдау қызметінің support_nobd@iac.kz электрондық мекенжайына жолдаңыз. нысан "Көмек"бөлімінде орналастырылған.',

    notifications_title: 'Хабарламаларды басқару',
    notifications_create: 'Хабарлама жасау',
    notifications_ruText: 'Орыс тіліндегі мәтін',
    notifications_kkText: 'Қазақ тіліндегі мәтін',
    notifications_createdAt: 'Құрылған күні',
    notifications_updatedAt: 'Дұрыстау күні',
    notifications_roles: 'Рөлдер тізімі',
    notifications_actions: 'Әрекеттер',
    notificationsDucks_loadNotificationsFailed: 'Хабарландыруларды жүктеу мүмкін емес',
    notificationsDucks_createNotificationFailed: 'Хабарлама жасау мүмкін емес',
    notificationsDucks_createNotificationSuccess: 'Хабарлама жасалды',
    notificationsDucks_updateNotificationSuccess: 'Өзгерістер сақталды',
    notificationsDucks_updateNotificationFailed: 'Өзгерістерді сақтау мүмкін емес',
    notificationsDucks_deleteNotificationFailed: 'Хабарламаны өшіру мүмкін емес',
    notificationsDucks_deleteNotificationSuccess: 'Хабарлама жойылды',
    notifications_deleteTitle: 'Хабарламаны өшіру',
    notifications_deleteText: 'Сіз хабарламаны жойғыңыз келетініне сенімдісіз бе',
    notifications_updateNotification: 'Хабарламаны өңдеу',
    notifications_createNotification: 'Хабарлама жасау',
    notifications_notification: 'Хабарландыру',
    notifications_familiar: 'Таныстым',

    monitoring_title: 'Мониторинг сервисов по интеграции',
    monitoring_filterLabel_periodFrom: 'Период с',
    monitoring_filterLabel_periodTo: 'по',
    monitoring_filterLabel_IIN: 'ИИН',
    monitoring_filterLabel_id: 'ID очереди',
    monitoring_filterLabel_BINSchoolId: 'SchoolID',
    monitoring_dateFormatError: 'Недопустимый формат даты',
    monitoring_inputIINError: 'Недопустимое значение ИИН',
    monitoring_inputBINError: 'Недопустимое значение БИН',
    monitoring_tableColumn_nedb_id: 'ID Студента',
    monitoring_tableColumn_native_id: 'ИИН',
    monitoring_tableColumn_sender: 'Отправитель',
    monitoring_tableColumn_import_object_type: 'Тип объекта',
    monitoring_tableColumn_process_status: 'Статус',
    monitoring_tableColumn_last_attempt_date: 'Дата импорта в НОБД',
    monitoring_tableColumn_accepted_date: 'Дата отправки запроса в НОБД',
    monitoring_tableColumn_error_message: 'Сообщение об ошибке',
    monitoring_info_title: 'Подробнее',
    monitoring_info_buttonClose: 'OK',

    actualization_title: 'Мониторинг сервиса актуализации по отправке данных в МТСЗН',
    actualization_logs: 'Просмотреть логи',
    actualization_tab1_title: 'Мониторинг пакетов',
    actualization_tab2_title: 'Мониторинг по записям',
    actualization_table_config: 'Кофигурация',
    actualization_table_sendStartDate: 'Дата начала отправки',
    actualization_table_sendEndDate: 'Дата завершения отправки',
    actualization_table_status: 'Статус',
    actualization_table_error: 'Сообщение об ошибке',
    actualization_table_updatedDate: 'Дата обновления записи в НОБД',
    actualization_table_quantity: 'Количество записей в пакете',
    actualization_table_code: 'Код пакета',
    actualization_table_id: 'ID объекта',
    actualization_table_datetimeSend: 'Дата и время отправки',
    actualization_periodOfUpdating: 'Период обновления данных в НОБД',
    actualization_periodOfSending: 'Период отправки данных в МТСЗН',
    actualization_search: 'Поиск',

    bolashak_title: 'Мониторинг сервиса актуализации Болашак',
    bolashak_period: 'Период обновления данных в НОБД',
    bolashak_from: 'с',
    bolashak_to: 'до',
    bolashak_column_iin: 'ИИН',
    bolashak_column_lastName: 'Фамилия',
    bolashak_column_firstName: 'Имя',
    bolashak_column_middleName: 'Отчество',
    bolashak_column_eduType: 'Тип обучения',
    bolashak_column_mainStatus: 'Основной статус',
    bolashak_column_scholarStatus: 'Статус стипендиата',
    bolashak_column_updated: 'Дата и время обновления',

    universalService_title: 'Мониторинг универсальных сервисов (запрос-ответ)',
    universalService_period: 'Период отправки запроса в НОБД',
    universalService_from: 'с',
    universalService_to: 'до',
    universalService_column_service: 'Наименование сервиса',
    universalService_column_config: 'Конфигурация',
    universalService_column_sender: 'Отправитель',
    universalService_column_jobStart: 'Дата запроса',
    universalService_column_jobEnd: 'Дата ответа',
    universalService_column_iin: 'ИИН',
    universalService_column_status: 'Статус',
    universalService_column_errorText: 'Текст ошибки',

    common_delete: 'Өшіру',
    common_cancel: 'Болдырмау',
    common_create: 'Құру',
    common_update: 'Редакциялау'
  }
};
