import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kindergarten_notifications_subtitle: 'Уведомления',
    kindergarten_notifications_date: 'Дата и время',
    kindergarten_notifications_description: 'Описание уведомления',
    kindergarten_notifications_error_load: 'Ошибка при загрузке уведомлений',
    kindergarten_notifications_count_error_load: 'Ошибка при загрузке количества уведомлений',
    kindergarten_notifications_mark_as_read_error: 'Ошибка при отметке уведомлений',
  },
  kk: {
    kindergarten_notifications_subtitle: 'Хабарламалар',
    kindergarten_notifications_date: 'Күні мен уақыты',
    kindergarten_notifications_description: 'Хабарлама сипаты',
    kindergarten_notifications_error_load: 'Деректерді жүктеуде қателік орын алды',
    kindergarten_notifications_count_error_load: 'Ошибка при загрузке количества уведомлений',
    kindergarten_notifications_mark_as_read_error: 'Ошибка при отметке уведомлений',
  }
});
