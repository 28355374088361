import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import './SelectDDOTranslate';
import { loadData, selectDDoModule } from './SelectDDODucks';
import { Table } from '../ServiceProvidersModal/ServiceProvidersModalStyles';
import { changeFormValue } from '../../../passport/Passport';
import useDict from '../../../modules/_hooks/useDict';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function SelectDDOModal({ close }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[selectDDoModule].loading);
  const data = useSelector(state => state[selectDDoModule].data);
  const validationMessages = useDict('validation_messages');
  const noPO = validationMessages.find(i => i.code === 'no_PO') || {};

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  return (
    <Modal
      open
      maxWidth="lg"
      scroll="paper"
      title={t('selectDDOModal_title')}
      onClose={close}
    >
      <DialogContent dividers>
        {data.length === 0 && !loading && (
          <div
            className="p2 flex mb2"
            style={{ backgroundColor: '#f8695e', color: 'white', borderRadius: 10 }}
          >
            <ErrorOutlineIcon color="inherit" />
            <div className="ml2">{noPO[`${i18n.language}_name`]}</div>
          </div>
        )}
        <Table
          loading={loading}
          columns={[
            {
              Header: t('serviceProviders_columnBin'),
              accessor: 'bin'
            },
            {
              Header: t('serviceProviders_columnName'),
              Cell: ({ original }) => original[`${i18n.language}_name`]
            }
          ]}
          data={data}
          onClickRow={({ original }) => {
            changeFormValue(
              'request_form_doc_queued_ddo_short.choice_ddo',
              original.ru_name
            );
            changeFormValue(
              'request_form_doc_queued_ddo_short.choice_ddo_kk',
              original.kk_name
            );
            changeFormValue('request_form_doc_queued_ddo_short.bin_ddo', original.bin);
            close();
          }}
        />
      </DialogContent>
    </Modal>
  );
}
