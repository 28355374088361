import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContentText, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export default function DialogRevoke({ open, handleClose, handleDone }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('kindergarten_applications_modal_title_revoke')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('kindergarten_applications_modal_body_revoke')}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{textAlign: "center"}}>
        <div style={{width: "50%"}}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
          >{t('kindergarten_applications_modal_no_revoke')}</Button>
        </div>
        <div style={{width: "50%"}}>
          <Button
            onClick={() => {handleDone()}}
            color="secondary"
            variant="contained"
          >{t('kindergarten_applications_modal_yes_revoke')}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
