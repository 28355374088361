import React, { useContext, useState } from 'react';
import Row from '../Row';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import withFlc from '../flc/withDropdownFlc';
import CloseIcon from '@material-ui/icons/Close';
import { changedStyle } from '../../PassportStyle';
import { flcHasValue } from '../../Utils/flcHasValue';
import { QuestionRender, ConfigContext } from '../../PassportProvider';

function Select({
  code,
  value,
  onChange,
  cellConfig,
  disabled,
  lang,
  options,
  incorrect,
  error,
  changed,
  ...rest
}) {
  const questionRender = useContext(QuestionRender);
  const config = useContext(ConfigContext);
  const [open, setOpen] = useState(false);
  let isIncorrect = false;
  let inputValue = [...options].find(item => item.code === value) || null;
  if (!inputValue) {
    inputValue = [...incorrect].find(item => item.code === value) || null;
    if (inputValue) {
      isIncorrect = true;
    }
  }
  return (
    <div className="flex">
      <Autocomplete
        fullWidth
        open={open}
        onOpen={() => !disabled && setOpen(true)}
        onClose={() => setOpen(false)}
        value={inputValue}
        disabled={disabled}
        options={options}
        getOptionLabel={option => option[`${lang}_name`]}
        onChange={(event, option) => onChange(option ? option.code : '')}
        renderInput={params => {
          if (!open) {
            params.inputProps.value = inputValue ? inputValue[`${lang}_name`] : '';
          }
          return (
            <TextField
              {...params}
              multiline={config.wrapText}
              disabled={disabled}
              placeholder={!disabled ? rest[`${lang}_placeholder`] : undefined}
              error={!!error}
              helperText={error}
              onClick={() => !disabled && setOpen(true)}
              InputProps={{
                ...params.InputProps,
                title: params.inputProps.value,
                style: flcHasValue(value) && changed ? changedStyle : undefined,
                endAdornment: (
                  <InputAdornment position="end">
                    {disabled &&
                      isIncorrect && (
                        <IconButton
                          className="show-on-hover"
                          style={{ margin: 0 }}
                          onClick={() => onChange('')}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      )}
                    {params.InputProps.endAdornment.props.children}
                  </InputAdornment>
                )
              }}
            />
          );
        }}
      />
      {questionRender &&
        questionRender(cellConfig ? { ...cellConfig } : code, inputValue)}
    </div>
  );
}

function DSelect(props) {
  return <Row {...props} children={<Select {...props} />} />;
}

export default withFlc(DSelect);
