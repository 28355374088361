import React from 'react';
import CheckIinInQueue from './CheckIinInQueue';
import СhildInformationButton from './СhildInformationButton';

function CheckIinInQueueButton({ code, value, setDisabled }) {
  return (
    <>
        <СhildInformationButton code={code} value={value}/>
        <CheckIinInQueue code={code} value={value} setDisabled={setDisabled} />
    </> 
  );
}

export default CheckIinInQueueButton;