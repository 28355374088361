import { flcHasValue } from '../Utils/flcHasValue';

export function buildChange(question, value, complexCode) {
  let hasVal = flcHasValue(value);
  let props = {
    '@type': 'RequestQuestionValueChange',
    questionCode: question.originalCode
  };

  if (question.relatedBusinessObjectId) {
    props.relatedBusinessObjectId = question.relatedBusinessObjectId;
  }

  if (question.passportId) {
    props.businessObjectId = question.passportId;
  }

  if (complexCode) {
    props.complexValueId = complexCode;
  }

  switch (question.type) {
    case 'checkbox group':
    case 'multiple dynamic lookup':
      return {
        ...props,
        newValue: [
          {
            stringList: hasVal ? value.value : null,
            versions: hasVal ? value.version : undefined
          }
        ]
      };

    case 'yes/no':
      return {
        ...props,
        newValue: [{ boolean: hasVal ? value : null }]
      };

    case 'text':
    case 'long text':
      return {
        ...props,
        newValue: [
          {
            string: hasVal ? String(value).trim() : null
          }
        ]
      };

    case 'dynamic lookup':
      return {
        ...props,
        newValue: [
          {
            string: hasVal ? value.value : null,
            version: hasVal ? value.version : undefined
          }
        ]
      };

    case 'number':
      return {
        ...props,
        newValue: [{ number: hasVal ? parseFloat(value) : null }]
      };

    case 'date':
      return {
        ...props,
        newValue: [{ timestamp: hasVal ? value : null }]
      };

    case 'table':
      return {
        ...props,
        '@type': 'RequestTableCellsValueChange',
        newTableContents: { tableContents: value }
      };

    case 'flc':
      return {
        ...props,
        '@type': 'RequestQuestionStateChange',
        shownManually: !value.visibility,
        readOnly: value.readOnly,
        required: value.required
      };

    default:
      return;
  }
}
