import { addTranslation } from '../../../../_helpers/Translate';

addTranslation({
  ru: {
    bulletin_op_search_label: 'Поиск по наименованию организации',
  },
  kk: {
    bulletin_op_search_label: 'Ұйым атауы бойынша іздеу',
  }
});
