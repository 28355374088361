const intersectionObserver = new IntersectionObserver(entries => {
  let [entry] = entries;
  if (entry.isIntersecting) {
    setTimeout(() => {
      intersectionObserver.unobserve(entry.target);
    }, 400);
  }
});

function isScrolledIntoView(el) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  const elemLeft = rect.left;
  const elemRight = rect.right;
  return (
    elemTop >= 0 &&
    elemBottom <= window.innerHeight &&
    (elemLeft >= 0 && elemRight <= window.innerWidth)
  );
}

export function hasFormError(flc, saveWithError = [], checkError) {
  let result = false;
  let scrolledIntoView = false;
  let list = Object.keys(flc).filter(code => {
    if (checkError || !saveWithError.some(item => code.indexOf(item + '.') === 0)) {
      if (flc[code].validation && !flc[code].hideMode) {
        return true;
      }
    }
    return false;
  });

  for (const code of list) {
    const elementAnchor = document.getElementById(`anchor_${code}`);
    if (elementAnchor) {
      elementAnchor.classList.add('shake');
      elementAnchor.classList.add('animated');

      if (!scrolledIntoView) {
        scrolledIntoView = isScrolledIntoView(elementAnchor);
      }

      if (list[list.length - 1] === code && !scrolledIntoView) {
        elementAnchor.scrollIntoView({
          behavior: 'smooth',
          block: checkError ? 'center' : 'nearest',
          inline: 'center'
        });
        intersectionObserver.observe(elementAnchor);
      }
    }
    result = true;
  }

  setTimeout(() => {
    for (const code of Object.keys(flc)) {
      if (flc[code].validation && !flc[code].hideMode) {
        const elementAnchor = document.getElementById(`anchor_${code}`);
        if (elementAnchor) {
          elementAnchor.classList.remove('shake');
          elementAnchor.classList.remove('animated');
        }
      }
    }
  }, 2000);

  return result;
}
