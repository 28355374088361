import React from 'react';
import FullScreen from '../../FullScreen/FullScreen';
import { ThemeProvider } from '@material-ui/styles';
import { tableTheme } from './DTableStyle';
import { Table, TableBody } from '@material-ui/core';
import { Wrapper } from './DTableStyle';
import withTableFlc from '../../flc/withTableFlc';
import FormHelperText from '@material-ui/core/FormHelperText';
import DTableHead from './DTableHead';
import DTableRow from './DTableRow';
import { handleQuestionLabel } from '../../../Utils/handleQuestionLabel';
import { isMatrixVisible } from './DTableUtils';

class DTable extends React.Component {
  checkCellValue(value) {
    this.props.checkCellHasValue(value);
  }

  render() {
    const {
      domain: { columns, rows },
      code,
      lang,
      error,
      disabled,
      cellsSavedValue,
      cellsDefaultValue,
      cellsRules,
      cellsDependencies,
      matrixVisibility
    } = this.props;

    const hideCol =
      !columns.titles.some(item =>
        item.some(dictItem => Boolean(dictItem[`${lang}_name`].trim()))
      ) && !window.test;

    const hideRow =
      !rows.titles.some(item =>
        item.dict.some(dictItem => Boolean(dictItem[`${lang}_name`].trim()))
      ) && !window.test;

    return (
      <Wrapper id={`anchor_${code}`} error={error}>
        <ThemeProvider theme={tableTheme}>
          <FullScreen title={handleQuestionLabel(this.props, lang)}>
            <Table>
              <DTableHead
                code={code}
                lang={lang}
                rows={rows}
                columns={columns}
                hideRow={hideRow}
                hideCol={hideCol}
                matrixVisibility={matrixVisibility}
              />
              <TableBody>
                {columns.cells.map(
                  (columnSet, colIndex) =>
                    isMatrixVisible(matrixVisibility, columnSet) && (
                      <DTableRow
                        key={`column${colIndex}`}
                        code={code}
                        lang={lang}
                        hideCol={hideCol}
                        colIndex={colIndex}
                        columns={columns}
                        rows={rows}
                        columnSet={columnSet}
                        disabled={disabled}
                        matrixVisibility={matrixVisibility}
                        cellsSavedValue={cellsSavedValue}
                        cellsDefaultValue={cellsDefaultValue}
                        cellsDependencies={cellsDependencies}
                        cellsRules={cellsRules}
                        checkCellHasValue={value => this.checkCellValue(value)}
                      />
                    )
                )}
              </TableBody>
            </Table>
          </FullScreen>
          {error && (
            <FormHelperText
              style={{ marginTop: 4, float: 'right' }}
              error
              children={error}
            />
          )}
        </ThemeProvider>
      </Wrapper>
    );
  }
}

export default withTableFlc(DTable);
