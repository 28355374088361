import Dict from '../Utils/Dict';

function buildRows(rows, dict) {
  if (rows.length) {
    let newRows = [];
    rows.forEach(item => {
      item.colSpan = item.colSpan * dict.length;
      return item;
    });

    for (const parentDictItem of rows[rows.length - 1].dict) {
      const dwp = dict.map(item => ({
        ...item,
        dataSet: {
          ...parentDictItem.dataSet,
          [item.dictCode]: item.code,
          [parentDictItem.dictCode]: parentDictItem.code
        }
      }));
      newRows = [...newRows, ...dwp];
    }
    rows.push({ colSpan: 1, dict: newRows });
  } else {
    const dwp = dict.map(item => ({
      ...item,
      dataSet: {
        [item.dictCode]: item.code
      }
    }));
    rows.push({ colSpan: 1, dict: [...dwp] });
  }
  return rows;
}

function buildColumns(oldColumns, dict) {
  let columns = [...oldColumns];
  if (columns.length) {
    let newColumns = [];
    columns.forEach(columnItem => {
      let tempColum = columnItem.map(item => {
        item.rowSpan = item.rowSpan * dict.length;
        return item;
      });
      dict.forEach(dictItem => {
        newColumns.push([...tempColum, { ...dictItem, rowSpan: 1 }]);
        tempColum = [];
      });
    });
    columns = newColumns;
  } else {
    columns = dict.map(item => [{ ...item, rowSpan: 1 }]);
  }
  return columns;
}

function buildCells(cells, dict, dictCode) {
  if (cells.length) {
    let newDatas = [];
    cells.forEach(dataItem => {
      dict.forEach(dictItem => {
        newDatas.push({ ...dataItem, [dictCode]: dictItem.code });
      });
    });
    cells = newDatas;
  } else {
    cells = dict.map(item => ({ [dictCode]: item.code }));
  }
  return cells;
}

export async function tableDomainData(domainData, exclusions, isRow) {
  let cells = [];
  let titles = [];
  if (domainData) {
    for (const dictCode of domainData) {
      let dict = await Dict.items(dictCode, undefined, undefined, false);
      if (exclusions && exclusions[dictCode]) {
        dict = dict.filter(item => !exclusions[dictCode].includes(item.code));
      }
      dict.forEach(item => {
        item.dictCode = dictCode;
        item.dataSet = {};
      });
      cells = buildCells(cells, dict, dictCode);
      if (isRow) {
        titles = buildRows(titles, dict);
      } else {
        titles = buildColumns(titles, dict);
      }
    }
  }
  return { cells, titles };
}
