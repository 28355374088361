import React from 'react';
import { RENDERER } from '../../Utils/Constants';
import DInput from '../FormComponents/DInput';
import DDatePicker from '../FormComponents/DDatePicker';
import DDateTimePicker from '../FormComponents/DDateTimePicker';
import DRadio from '../FormComponents/DRadio';
import DDocument from '../FormComponents/DDocument/DDocument';
import DCheckBox from '../FormComponents/DCheckBox';
import DTable from '../FormComponents/DTable/DTable';
import DCheckboxGroup from '../FormComponents/DCheckboxGroup/DCheckboxGroup';
import DComplexTable from '../FormComponents/DComplexTable/DComplexTable';
import DDropdown from '../FormComponents/DDropdown';
import DComplexInline from '../FormComponents/DComplexInline/DComplexInline';
import DTreeSelectMulti from '../FormComponents/DTreeSelectMulti/DTreeSelectMulti';

/**
 * @return {null}
 */
function RenderComponent(question) {
  switch (question.renderer) {
    case RENDERER.text:
    case RENDERER.short_text:
    case RENDERER.long_text:
    case RENDERER.numeric_text:
      return <DInput key={question.code} {...question} />;
    case RENDERER.date:
      return <DDatePicker key={question.code} {...question} />;
    case RENDERER.date_time:
      return <DDateTimePicker key={question.code} {...question} />;
    case RENDERER.check_box:
      return <DCheckBox key={question.code} {...question} />;
    case RENDERER.radio_button:
      return <DRadio key={question.code} {...question} />;
    case RENDERER.checkbox_group:
      return <DCheckboxGroup key={question.code} {...question} />;
    case RENDERER.dropdown:
    case RENDERER.dropdown_tree:
      return <DDropdown key={question.code} {...question} />;
    case RENDERER.multiple_dropdown_tree:
      return <DTreeSelectMulti key={question.code} {...question} />;
    case RENDERER.table:
      return <DTable key={question.code} {...question} />;
    case RENDERER.complex_table:
      return <DComplexTable key={question.code} {...question} />;
    case RENDERER.complex_inline:
      return <DComplexInline key={question.code} {...question} />;
    case RENDERER.document:
      return <DDocument key={question.code} {...question} />;
    default:
      console.error('Компонент не найден в GenerateForms', question);
      return (
        <div key={question.code}>Компонент {question.renderer} еще не разработан</div>
      );
  }
}

export default RenderComponent;
