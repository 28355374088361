import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/kk';
import './locale/momentKK';

import { LocaleProvider as AntdLocaleProvider } from 'antd';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import en_US from 'antd/lib/locale-provider/en_US';
import kk_KK from './locale/kk_KK';

let messages = {};
export let lang = null;

export function getMessage(id) {
  return messages[id] || id;
}

class LocaleProvider extends React.Component {
  constructor(props) {
    super(props);
    messages = props.messages;
    lang = props.locale;
    this.state = {};
  }

  static getDerivedStateFromProps(props) {
    messages = props.messages;
    lang = props.locale;
    return null;
  }

  getLocale = () => {
    moment.locale(this.props.locale);
    switch (this.props.locale) {
      case 'kk':
        return kk_KK;
      case 'ru':
        return ru_RU;
      case 'en':
        return en_US;
      default:
        return ru_RU;
    }
  };

  render() {
    return (
      <IntlProvider {...this.props}>
        <AntdLocaleProvider locale={this.getLocale()}>
          {this.props.children}
        </AntdLocaleProvider>
      </IntlProvider>
    );
  }
}

const mapStateProp = state => ({
  locale: state.locale.lang,
  messages: state.locale.messages
});

export default connect(mapStateProp)(LocaleProvider);
