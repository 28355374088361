import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    videoInstructions_title: 'Видео инструкции пользователя',
    videoInstructions_viewVideoFailed: 'Не удалось зугрузить видео'
  },
  kk: {
    videoInstructions_title: 'Пайдаланушының бейне нұсқаулығы',
    videoInstructions_viewVideoFailed: 'Не удалось зугрузить видео'
  }
});