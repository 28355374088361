import React from 'react';
import PropTypes from 'prop-types';
import Field from '../Form/Field';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const Input = props => {
  const { withoutForm, name, onChange, label, ...restProps } = props;

  return withoutForm ? (
    <div className={restProps.fullWidth ? 'fullWidth' : ''}>
      <Typography children={label} />
      <TextField name={name} onChange={onChange} {...restProps} />
    </div>
  ) : (
    <Field name={name}>
      {({ onChange, ...fieldProps }) => (
        <div>
          <Typography children={label} />
          <TextField
            name={name}
            onChange={event => onChange(event.target.value)}
            {...restProps}
            {...fieldProps}
          />
        </div>
      )}
    </Field>
  );
};

Input.propsTypes = {
  withoutForm: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

Input.defaultProps = {
  withoutForm: false,
  label: ''
};

export default Input;
