import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kindergarten_reestr_subtitle: 'Реестр детских садов',
    kindergarten_reestr_table_lang: 'Язык обучения:',
    kindergarten_reestr_table_address: 'Адрес:',
    kindergarten_reestr_table_seats: 'Свободные места:',
    kindergarten_reestr_table_total_seats: 'Проектная мощность:',
    kindergarten_reestr_view_button_back: 'Назад',
    kindergarten_reestr_view_form: 'Форма собственности',
    kindergarten_reestr_view_name_org: 'Наименование организации',
    kindergarten_reestr_view_address: 'Юридический адрес',
    kindergarten_reestr_view_contact: 'Контактные данные',
    kindergarten_reestr_view_age_groups: 'Возрастная группа',
    kindergarten_reestr_view_project: 'Проектная мощность',
    kindergarten_reestr_view_time_stay: 'Время пребывания',
    kindergarten_reestr_view_playing_areas: 'Наличие игровых участков',
    kindergarten_reestr_view_age_group: 'Возрастные группы',
    kindergarten_reestr_view_operating_mode: 'Режим работы',
    kindergarten_reestr_view_video_surv: 'Наличие видеонаблюдения',
    kindergarten_reestr_view_website: 'Сайт',
    kindergarten_reestr_view_go_to: 'Перейти',
    kindergarten_reestr_about: 'Об организации',
    kindergarten_reestr_err: 'Ошибка запроса садиков',
    kindergarten_reestr_err_meta: 'Ошибка запроса садика',
    kindergarten_reestr_stepper_next: 'Следующий',
    kindergarten_reestr_stepper_back: 'Предыдущий',
    kindergarten_reestr_noPhoto: 'Нет фото',
  },
  kk: {
    kindergarten_reestr_subtitle: 'Балабақшалар тізімі',
    kindergarten_reestr_table_lang: 'Оқытылу тілі:',
    kindergarten_reestr_table_address: 'Мекен-жайы:',
    kindergarten_reestr_table_seats: 'Бос орындар саны:',
    kindergarten_reestr_table_total_seats: 'Жобалық қуаты:',
    kindergarten_reestr_view_button_back: 'Назад',
    kindergarten_reestr_view_form: 'Ұйымның меншік нысаны',
    kindergarten_reestr_view_name_org: 'Ұйымның атауы',
    kindergarten_reestr_view_address: 'Заңды мекен-жайы',
    kindergarten_reestr_view_contact: 'Байланыс деректері',
    kindergarten_reestr_view_age_groups: 'Жас тобы',
    kindergarten_reestr_view_project: 'Жобалық қуаты',
    kindergarten_reestr_view_time_stay: 'Болу уақыты',
    kindergarten_reestr_view_playing_areas: 'Ойын участкелерінің болуы',
    kindergarten_reestr_view_age_group: 'Жас топтары',
    kindergarten_reestr_view_operating_mode: 'Жұмыс тәртібі',
    kindergarten_reestr_view_video_surv: 'Бейнебақылаудың болуы',
    kindergarten_reestr_view_website: 'Сайт',
    kindergarten_reestr_view_go_to: 'Кіру',
    kindergarten_reestr_about: 'Ұйым жайында',
    kindergarten_reestr_err: 'Ошибка запроса садиков',
    kindergarten_reestr_err_meta: 'Ошибка запроса садика',
    kindergarten_reestr_stepper_next: 'Келесі',
    kindergarten_reestr_stepper_back: 'Алдыңғысы',
    kindergarten_reestr_noPhoto: 'Фото жоқ',
  }
});
