import React from 'react';

import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';

export const serviceProvidersColumns = (
  t,
  lang
) => {
  return [
    {
      Header: t('serviceProviders_columnName'),
      Cell: ({ original }) => original[`${lang}_name`],
      Filter: <FilterInputField name={`${lang}Name`} type="text" placeholder="Поиск"/>
    }
  ];
};
