import { useState } from 'react';
import i18next from 'i18next';
import _ from 'lodash';

import { changeComplexValue } from '../../../passport/Passport';
import PassportForm from '../../../passport/FormData/PassportForm';
import { CHANGE_HIDDEN_COMPLEX_QUESTIONS } from './constants';

export function changeHiddenQuestions({ code, questionCode, complexCode, value }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [v, setV] = useState(null);

  if (!_.isEqual(v, value)) {
    let textQuestionCode = '';

    switch (questionCode) {
      case CHANGE_HIDDEN_COMPLEX_QUESTIONS['government_order']['Qualification_name_dictionary']:
        textQuestionCode = 'qualification';
        break;
      case CHANGE_HIDDEN_COMPLEX_QUESTIONS['government_order']['specialty_name_dictionary']:
        textQuestionCode = 'speciality_name';
        break;
      case CHANGE_HIDDEN_COMPLEX_QUESTIONS['healthList']['narcological_info_dictionary']:
        textQuestionCode = 'narcological_info';
        break;
      case CHANGE_HIDDEN_COMPLEX_QUESTIONS['healthList']['narcological_info_spouse_dictionary']:
        textQuestionCode = 'narcological_info_spouse';
        break;
      case CHANGE_HIDDEN_COMPLEX_QUESTIONS['healthList']['psychiatric_info_dictionary']:
        textQuestionCode = 'psychiatric_info';
        break;
      case CHANGE_HIDDEN_COMPLEX_QUESTIONS['healthList']['psychiatric_info_spouse_dictionary']:
        textQuestionCode = 'psychiatric_info_spouse';
        break;
      default:
        break;
    }

    if (value) {
      changeComplexValue(code, {
        ...PassportForm.cellsValues[code],
        [complexCode]: {
          ...PassportForm.cellsValues[code][complexCode],
          [questionCode]: value.code,
          [textQuestionCode]: value[`${i18next.language}_name`]
        }
      });
      setV(value);
    } else if (!value && v) {
      changeComplexValue(code, {
        ...PassportForm.cellsValues[code],
        [complexCode]: {
          ...PassportForm.cellsValues[code][complexCode],
          [questionCode]: null,
          [textQuestionCode]: null
        }
      });
      setV(null);
    }
  }
}