import { flcHasValue } from '../Utils/flcHasValue';

export function getComplexChanges(question, cellsValue, savedCellsValue) {
  let changes = [];

  for (const complexCode of Object.keys(cellsValue)) {
    if (savedCellsValue.hasOwnProperty(complexCode)) {
      if (!Object.values(cellsValue[complexCode]).some(flcHasValue)) {
        changes.push({
          '@type': 'RequestRemoveComplexQuestionItem',
          relatedBusinessObjectId: question.relatedBusinessObjectId,
          businessObjectId: question.passportId,
          questionCode: question.originalCode,
          valueId: complexCode
        });
      }
    } else {
      if (Object.keys(cellsValue).length > 1 || Object.values(cellsValue[complexCode]).some(flcHasValue)) {
        changes.push({
          '@type': 'RequestAddComplexQuestionItem',
          relatedBusinessObjectId: question.relatedBusinessObjectId,
          businessObjectId: question.passportId,
          questionCode: question.originalCode,
          valueId: complexCode
        });
      }
    }
  }

  let removedRowsChanges = Object.keys(savedCellsValue)
    .filter(complexCode => !cellsValue.hasOwnProperty(complexCode))
    .map(complexCode => ({
      '@type': 'RequestRemoveComplexQuestionItem',
      relatedBusinessObjectId: question.relatedBusinessObjectId,
      businessObjectId: question.passportId,
      questionCode: question.originalCode,
      valueId: complexCode
    }));

  return [...changes, ...removedRowsChanges];
}
