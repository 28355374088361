import React from 'react';
import { StyledContent} from './HomeStyles';
import './HomeTranslate';
import HomeInsert from './section/HomeInsert'
import HomeServices from './section/HomeServices'
import { getContentWidth } from '../../utils/getContentWidth';

function Home() {
  const contentWidth = getContentWidth('/kindergarten-services');

  return (
    <div style={{width: '100%'}}>
      <StyledContent>
        <HomeInsert contentWidth={contentWidth} />
        
      </StyledContent>
      <HomeServices contentWidth={contentWidth}/>
    </div>
  );
}

export default Home;
