import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import PassportForm from '../../../passport/FormData/PassportForm';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeComplexValue } from '../../../passport/Passport';

async function changeSchool({ code, complexCode, t, setLoading, lang }) {
  try {
    setLoading(true);
    const childIin = PassportForm.cellsValues[code][complexCode]['fostercare_child_iin'];
    if (!childIin) {
      return Notice.info(t('serviceRequest_fillChildIin'));
    }
    const response = await ServiceRequestApi.getSchools(childIin);
    const s = response.data.content.find(s => s.status === 0);
    if (s) {
      const name = s[`schoolName${capitalize(lang)}`];
      const parallel = s.passportValues.find(q => q.code === 'Parallel');
      const language = s.passportValues.find(q => q.code === 'Language');
      const arrivalDate = s.passportValues.find(q => q.code === 'DateOfArrival');
      const formStudy = s.passportValues.find(q => q.code === 'StudyForm');
      const spec = s.passportValues.find(q => q.code === 'StudyingInSpecialClasses');
      changeComplexValue(code, {
        ...PassportForm.cellsValues[code],
        [complexCode]: {
          ...PassportForm.cellsValues[code][complexCode],
          fostercare_child_edu_org_name: name,
          fostercare_child_edu_class: parallel ? parallel.value.value : '',
          language_study: language ? language.value.value : '',
          fostercare_child_edu_dat_arrival: arrivalDate ? arrivalDate.value : '',
          fostercare_child_edu_form_train: formStudy ? formStudy.value.value : '',
          fostercare_child_edu_org_inclus: spec ? spec.value.value : ''
        }
      });
    } else {
      handleError(response, t('dataNotFound'));
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function ComplexSchool({ code, complexCode }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeSchool({
          t,
          code,
          complexCode,
          setLoading,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default ComplexSchool;