import { createReducer } from 'redux-starter-kit';
import moment from 'moment';

import Notice from '../../modules/utils/Notice';
import { handleError } from '../../modules/utils/handleError';
import { P_STATUS } from '../../_helpers/Constants';
import { CompletedRequestsApi } from './CompletedRequestsApi';
import * as Dict from '../../modules/utils/Dict';

export const completedRequestsModule = 'completedRequests';
const LOADING_TABLE = `${completedRequestsModule}/LOADING_TABLE`;
const SET_DATA = `${completedRequestsModule}/SET_DATA`;

const initialState = {
  loadingTable: false,
  tableData: {
    content: [],
    totalElements: 0
  }
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.loading;
  },
  [SET_DATA]: (state, { tableData }) => {
    state.tableData = {
      ...tableData,
      totalElements: tableData.pageNumber === 1
        ? tableData.totalElements
        : state.tableData.totalElements
    };
  }
});

export const getCompletedRequests = filters => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_TABLE, loading: true });

    filters = {
      ...filters,
      requesterIinBin: getState().login.user.iin,
      processingStatuses: [
        P_STATUS.COMPLETED,
        P_STATUS.FAILED
      ],
      isArchived: null
    };

    const { data } = await CompletedRequestsApi.getRequests(filters);

    if (data.status === 'SUCCESS') {
      const content = [];
      for (let request of data.result.content) {
        let serviceType = request.serviceType.toLowerCase();
        if (!serviceType.includes('_short')) {
          serviceType = `${serviceType}_short`;
        }
        const status = await Dict.item('gu_status_res', request.processingStatus);
        content.push({
          ...request,
          status,
          serviceType,
          submissionDate: request.submissionDate && moment(request.submissionDate).format('L')
        });
      }

      dispatch({ type: SET_DATA, tableData: { ...data.result, content } });
    }

  } catch (error) {
    Notice.error(handleError(error));
  } finally {
    dispatch({ type: LOADING_TABLE, loading: false });
  }
};