import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';
import { DocumentApi } from '../../../PassportService';
import { handleError } from '../../../Utils/handleError';
import { Color } from '../../ThemeProvider/ThemeStyle';

/**
 * @return {null}
 */
function DDocumentModal({ t, value = {}, open, onClose }) {
  const [error, setError] = useState(null);
  const [fileUrl, setFileUrl] = useState();
  const [contentType, setContentType] = useState();
  let type = 'loading';

  if (contentType) {
    if (contentType.startsWith('image')) type = 'image';
    else if (contentType.startsWith('video')) type = 'video';
    else type = 'object';
  }
  useEffect(() => {
    if (value.file) {
      setFileUrl(URL.createObjectURL(value.file));
      setContentType(value.file.type);
      setError(null);
    }
  }, [value]);

  useEffect(() => {
    if (open && value && !fileUrl && value['downloadLink']) {
      DocumentApi.view(value['downloadLink'], value.version)
        .then(response => {
          setFileUrl(URL.createObjectURL(response.data));
          setContentType(response.headers['content-type']);
        })
        .catch(error => setError(handleError(error)));
    }
  }, [value, open, fileUrl]);

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose} scroll="body">
      <DialogContent>
        {type === 'image' && <img src={fileUrl} width="auto" height="800px" alt="" />}
        {type === 'video' && (
          <video
            autoPlay
            controls
            controlsList="nodownload"
            width="auto"
            height="400px"
            src={fileUrl}
          />
        )}
        {type === 'object' && (
          <object data={fileUrl} width="100%" height="800px" style={{ minWidth: 600 }}>
            <div className="bold center p3" style={{ color: Color.secondary }}>
              {t('passport_canNotViewFile')}
            </div>
          </object>
        )}
        {!error && type === 'loading' && (
          <div className="center p3">
            <CircularProgress />
          </div>
        )}
        {error && (
          <div className="bold center p3" style={{ color: Color.secondary }}>
            {t('passport_errorDownloadFile')}
            <br />
            {error}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DDocumentModal;
