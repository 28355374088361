import { FLC_RULES } from '../Utils/Constants';
import { findMatches } from './dependency/findMatches';
import Trigger from '../FormData/ChangeTrigger';

export class TestMode {
  static callbacks = {};
  static dependencies = {};
  static rules = {};

  static register(code, callback) {
    if (window.test) {
      this.callbacks[code] = callback;
      Trigger.subscribe(this.dependencies[code], code, callback);
    }
  }

  static setRule(code, ruleName, ruleBody) {
    if (TestMode.rules) {
      if (TestMode.rules[code]) {
        TestMode.rules[code][ruleName] = ruleBody;
      } else {
        TestMode.rules[code] = { [ruleName]: ruleBody };
      }
    } else {
      TestMode.rules = { [code]: { [ruleName]: ruleBody } };
    }
    if (TestMode.callbacks[code]) {
      const callback = TestMode.callbacks[code];
      // зависимости показателей
      let dependency = {};
      findMatches(code => code, dependency, ruleBody);
      if (Object.keys(dependency).length > 0) {
        TestMode.dependencies[code] = dependency;
        Trigger.subscribe(dependency, code, callback);
      }
      callback();
    }
  }

  static initialize() {
    if (String(window.localStorage.getItem('test')) === 'true') {
      window.test = true;
      window.rules = FLC_RULES;
      window.localStorage.setItem('test', 'true');

      window.setQuestionRule = TestMode.setRule;
      window.setGroupRule = function(id, ruleName, ruleBody) {
        id = `group_${id}`;
        TestMode.setRule(id, ruleName, ruleBody);
      };
    } else {
      window.test = false;
      window.localStorage.setItem('test', 'false');
    }
  }

  static getRule(question, rule) {
    if (window.test && TestMode.rules) {
      let key = question.code;
      if (TestMode.rules[key] && TestMode.rules[key][rule]) {
        return TestMode.rules[key][rule];
      }
    }
    return question[rule];
  }
}
