import React, { useContext, useEffect, useState } from 'react';
import Row from '../../Row';
import { DocumentApi } from '../../../PassportService';
import {
  Tooltip as MuiTooltip,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import RenderComponent from '../../Generate/RenderComponent';
import withDocumentFlc from '../../flc/withDocumentFlc';
import CloseIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { encodeBase64 } from '../../../Utils/encodeToBase64';
import uuid from 'uuid/v4';
import withTranslation from '../../translation/withTranslation';
import { Document } from './DDocumentStyle';
import DDocumentModal from './DDocumentModal';
import { flcHasValue } from '../../../Utils/flcHasValue';
import { changedStyle } from '../../../PassportStyle';
import { ConfigContext } from '../../../PassportProvider';
import withStyles from '@material-ui/core/styles/withStyles';

function byteToTranslate(size) {
  return `${size / 1000} ${size < 1000000 ? 'Кб' : 'Мб'}`;
}

const Tooltip = withStyles(theme => ({
  tooltip: { backgroundColor: theme.palette.error.light || '#f16969' }
}))(MuiTooltip);

function downloadFile(value) {
  const download = data => {
    let element = document.createElement('a');
    document.body.appendChild(element);
    element.style.display = 'none';

    let blob = new Blob([data], { type: 'octet/stream' });
    let url = window.URL.createObjectURL(blob);
    element.href = url;
    element.download = value.name;
    element.click();
    window.URL.revokeObjectURL(url);
  };

  if (value.file) {
    download(value.file);
  } else {
    DocumentApi.download(value.downloadLink, value.version).then(response => {
      download(response.data);
    });
  }
}

function DDocument({
  code,
  value,
  error,
  complexCode,
  lang,
  hasQuestions,
  cellsQuestions,
  cellsQuestionCodes,
  cellsSource,
  cellsSavedValue,
  disabled,
  onChange,
  savedValue,
  changed,
  t,
  ...rest
}) {
  const [view, setView] = useState(false);
  const [docError, setDocError] = useState();
  const config = useContext(ConfigContext);
  const docProps = config.documentsProps[code] || {
    mimeType: config.documentsProps.mimeType,
    maxSize: config.documentsProps.maxSize,
    multiple: config.documentsProps.multiple
  };

  useEffect(() => {
    if (docError) {
      setTimeout(() => {
        if (setDocError && docError) {
          setDocError();
        }
      }, 5000);
    }
  }, [docError]);

  return (
    <div className={hasQuestions ? 'my1' : undefined}>
      <FormControl error={!!error || !!docError} fullWidth>
        <div className={hasQuestions ? 'card p2' : undefined}>
          {hasQuestions && <div className="bold">{rest[`${lang}_name`]}</div>}
          <Row
            label={hasQuestions ? t('passport_file') : rest[`${lang}_name`]}
            code={code}
            lang={lang}
            source={rest.source}
            withoutLabel={rest.withoutLabel}
            changed={changed}
          >
            <Document id={`anchor_${code}`} className="flex" inTable={rest.withoutLabel}>
              <div className="nowrap">
                <input
                  accept={docProps.mimeType ? docProps.mimeType : undefined}
                  id={`attach_${code}`}
                  type="file"
                  disabled={disabled}
                  className="display-none"
                  onChange={event => {
                    let file = event.target.files[0];
                    if (file) {
                      encodeBase64(file).then(base64 => {
                        if (docProps.mimeType && !docProps.mimeType.includes(file.type)) {
                          setDocError(t('passport_fileErrorMimeType'));
                          return;
                        }
                        if (docProps.maxSize && file.size > docProps.maxSize) {
                          setDocError(
                            `${t('passport_fileErrorMaxSize')} ${byteToTranslate(
                              docProps.maxSize
                            )}`
                          );
                          return;
                        }
                        setDocError(null);
                        onChange({
                          name: file.name,
                          file: file,
                          data: base64,
                          complexValueId: value
                            ? value.complexValueId
                            : savedValue
                            ? savedValue.complexValueId
                            : uuid()
                        });
                      });
                    }
                  }}
                />
                <label htmlFor={`attach_${code}`}>
                  {rest.withoutLabel && (
                    <IconButton
                      disabled={disabled}
                      size="small"
                      component="span"
                      style={{ marginRight: -2 }}
                      children={<AttachFileIcon fontSize="small" />}
                    />
                  )}
                  {!rest.withoutLabel && (
                    <Tooltip
                      style={{ backgroundColor: 'red' }}
                      open={!!docError}
                      title={docError || ''}
                      placement="top-start"
                    >
                      <Button
                        disabled={disabled}
                        component="span"
                        variant="contained"
                        startIcon={<AttachFileIcon />}
                        style={{
                          boxShadow: 'none',
                          marginRight: 4,
                          marginTop: 2,
                          padding: '7px 10px'
                        }}
                        children={t('passport_fileSelect')}
                      />
                    </Tooltip>
                  )}
                </label>
              </div>
              <TextField
                value={value && value.name ? value.name : ''}
                placeholder={t('passport_fileNotSelected')}
                disabled
                onClick={() => value && setView(true)}
                inputProps={{
                  disabled: false,
                  style: { cursor: value ? 'pointer' : 'default' }
                }}
                InputProps={{
                  style: flcHasValue(value) && changed ? changedStyle : undefined,
                  startAdornment: value && (
                    <InputAdornment position="start">
                      {!disabled && docProps.multiple && (
                        <Tooltip title={t('passport_filesToArchive')} placement="top">
                          <IconButton
                            onClick={event => {
                              event.stopPropagation();
                            }}
                            children={<InfoIcon fontSize="small" />}
                          />
                        </Tooltip>
                      )}
                      <Tooltip title={t('passport_downloadFile')} placement="top">
                        <IconButton
                          onClick={event => {
                            event.stopPropagation();
                            downloadFile(value);
                          }}
                        >
                          <GetAppIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  endAdornment: value && !disabled && (
                    <InputAdornment position="end">
                      <Tooltip title={t('passport_deleteFile')} placement="top">
                        <IconButton
                          className="show-on-hover"
                          onClick={event => {
                            event.stopPropagation();
                            onChange(null);
                          }}
                          children={<CloseIcon fontSize="small" />}
                        />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Document>
          </Row>
          {hasQuestions &&
            cellsQuestionCodes.map(questionCode => {
              let question = cellsQuestions[questionCode];
              return RenderComponent({
                ...question,
                lang,
                code: `${code}_${complexCode}_${question.code}`,
                disabled: complexCode ? disabled : true,
                savedValue: cellsSavedValue[complexCode]
                  ? cellsSavedValue[complexCode][question.code]
                  : '',
                source: cellsSource[complexCode]
                  ? cellsSource[complexCode][question.code]
                  : undefined,
                cellConfig: {
                  code: code,
                  complexCode,
                  questionCode: question.code,
                  isDocumentCell: true
                }
              });
            })}
        </div>
        {error && <FormHelperText style={{ textAlign: 'right' }} children={error} />}
      </FormControl>
      <DDocumentModal
        t={t}
        open={view}
        onClose={() => setView(false)}
        value={value || {}}
      />
    </div>
  );
}

export default withTranslation(withDocumentFlc(DDocument));
