import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    ncaLayer_switched_off: 'Убедитесь, что запущен NCALayer, и повторите попытку заново',
    header_closed_connect: 'Соединение с NCALayer оборвалось'
  },
  kk: {
    ncaLayer_switched_off: 'NCALayer іске қосылғанына көз жеткізіңіз және қайта көріңіз',
    header_closed_connect: 'NCALayer-мен байланыс үзілді'
  }
});
