import { FLC_RULES, RENDERER } from './Constants';

export function getFlcRules(renderer) {
  switch (renderer) {
    case RENDERER.date:
    case RENDERER.date_time:
    case RENDERER.short_text:
    case RENDERER.long_text:
    case RENDERER.numeric_text:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.calculationRule,
        FLC_RULES.inputRestrictionRule
      ];
    case RENDERER.radio_button:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.calculationRule
      ];
    case RENDERER.check_box:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.validationRule,
        FLC_RULES.calculationRule
      ];
    case RENDERER.checkbox_group:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.itemVisibilityRule,
        FLC_RULES.calculationRule
      ];
    case RENDERER.multiple_dropdown_tree:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.itemVisibilityRule,
        FLC_RULES.calculationRule
      ];
    case RENDERER.dropdown:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.itemVisibilityRule,
        FLC_RULES.calculationRule
      ];
    case RENDERER.dropdown_tree:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.itemVisibilityRule,
        FLC_RULES.calculationRule
      ];
    case RENDERER.document:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule
      ];
    case RENDERER.table_cell:
      return [
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.calculationRule,
        FLC_RULES.inputRestrictionRule
      ];
    case RENDERER.table:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.itemVisibilityRule,
        FLC_RULES.matrixVisibilityRule
      ];
    case RENDERER.complex_inline:
    case RENDERER.complex_table:
      return [
        FLC_RULES.visibilityRule,
        FLC_RULES.readOnlyRule,
        FLC_RULES.requiredRule,
        FLC_RULES.validationRule,
        FLC_RULES.itemVisibilityRule,
        FLC_RULES.rowAddRule,
        FLC_RULES.rowDeletionRule,
      ];
    case RENDERER.group:
      return [FLC_RULES.visibilityRule];
    default:
      return [];
  }
}
