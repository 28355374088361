import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import PassportForm from '../../../passport/FormData/PassportForm';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeComplexValue } from '../../../passport/Passport';

async function changeUniver({ code, complexCode, t, setLoading, lang, requesterIin }) {
  try {
    setLoading(true);
    const response = await ServiceRequestApi.getUniversities(requesterIin);
    const u = response.data.content.find(s => s.status === 2);

    if (u) {
      const name = u[`schoolName${capitalize(lang)}`];
      const spec = u.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
      const category = u.passportValues.find(q => q.code === 'EducationCategory');
      const dispose = u.passportValues.find(q => q.code === 'DateOfDisposal');
      const diplomaNum = u.passportValues.find(q => q.code === 'DiplomaNumber');
      const diplomaSeries = u.passportValues.find(q => q.code === 'DiplomaSeries');
      changeComplexValue(code, {
        ...PassportForm.cellsValues[code],
        [complexCode]: {
          ...PassportForm.cellsValues[code][complexCode],
          Univer_name: name,
          Specialty_Name: spec ? spec.value.value : '',
          edu_level: category ? category.value.value : '',
          Year_graduate: dispose ? category.value : '',
          Diplom_numb: diplomaNum ? diplomaNum.value : '',
          Diplom_series: diplomaSeries ? diplomaSeries.value : ''
        }
      });
    } else {
      handleError(response, t('dataNotFound'));
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function ComplexUniver({ code, complexCode, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeUniver({
          t,
          code,
          complexCode,
          setLoading,
          requesterIin,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default ComplexUniver;