import styled from 'styled-components';
import { Color } from '../ThemeProvider/ThemeStyle';

export const Expanded = styled.div`
  min-width: 100%;
  background-color: ${() => Color.bgColorPaper};
  z-index: 1300;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${() => Color.bgColorPaper};
  max-height: 100%;
  overflow: auto;
  padding: 20px;

  .fc-con {
    height: 40px;
    .fc-header {
      width: 100%;
      position: fixed;
      padding: 10px 15px;
      z-index: 2;
      .fc-icon {
        position: absolute;
        right: 30px;
        top: 10px;
      }
    }
  }
`;

export const Collapsed = styled.div`
  min-width: 100%;
  position: relative;

  .fc-scroll {
    ${props => !props.hideScroll && 'overflow-x: auto;'};
    ${props => !props.hideScroll && 'max-height: calc(100vh - 170px);'};

    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      overflow: hidden;
      background: rgba(140, 140, 140, 0.15);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(140, 140, 140, 0.3);
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 18px;
    }
  }

  .fc-con {
    height: 25px;
    .fc-header {
      width: 100%;
      color: ${() => Color.isDark ? Color.secondary : 'inherit'};
      padding: 5px 0;
      border-radius: ${() => Color.borderRadius};

      .fc-icon {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
      }
    }
  }
`;
