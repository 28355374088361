import { addTranslation } from '../../../../_helpers/Translate';

addTranslation({
  ru: {
    bulletin_op_filters_but: 'Применить',
    bulletin_op_filters_clear: 'Очистить фильтр',
    bulletin_op_filters_region: 'Населенный пункт',
    bulletin_op_filters_age_group: 'Возрастная группа',
    bulletin_op_filters_type: 'Тип бюллетеня/очереди',
    bulletin_op_filters_kind: 'Вид бюллетеня',
    bulletin_op_filters_access_type: 'Вид доступа',
    bulletin_op_filters_ownership: 'Форма собственности',
    bulletin_op_filters_lang: 'Язык обучения',
    bulletin_op_filters_kindergartens: 'Детский сад',
    bulletin_op_filters_kindergartens_err: 'Ошибка запроса садиков',
    bulletin_op_filters_birth_day: 'День рождения',
    bulletin_op_filters_not_found: 'По запросу ничего не найдено',
  },
  kk: {
    bulletin_op_filters_but: 'Қолдану',
    bulletin_op_filters_clear: 'Сүзгіні жою',
    bulletin_op_region: 'Елді мекен',
    bulletin_op_filters_age_group: 'Жас тобы',
    bulletin_op_filters_type: 'Кезек түрі',
    bulletin_op_filters_kind: 'Бюллетень түрі',
    bulletin_op_filters_access_type: 'Қолжетімділік',
    bulletin_op_filters_ownership: 'Ұйымның меншік нысаны',
    bulletin_op_filters_lang: 'Оқытылу тілі',
    bulletin_op_filters_kindergartens: 'Балабақша атауы',
    bulletin_op_filters_kindergartens_err: 'Ошибка запроса садиков',
    bulletin_op_filters_birth_day: 'Туған күні',
    bulletin_op_filters_not_found: 'Сұранысыңыз бойынша ақпарат табылмады',
  }
});
