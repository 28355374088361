import React from 'react';
import { AppBar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { HeaderTitle, StyledToolbar } from './HeaderStyle';
import LocaleButton from './LocaleButton';
import { history } from '../../../_helpers/history';
import AccountButton from './AccountButton';
import HelpButton from './HelpButton';
import LinkApp from './../../../pages/Market/LinkApp';
import paths from '../../../_helpers/paths';
import Logo from '../../../_assets/images/logo.png';
import './HeaderTranslate';
import { Content } from '../../ThemeProvider/ThemeStyle';
import CollectionBlock from './CollectionBlock';

function HeaderWithAuth({contentWidth, isCurrent}) {
  const { t } = useTranslation();
  const location = useLocation();
  const home = location.pathname === paths.homePage;

  return (
    <>
      {
        location.pathname === paths.homePage ?
          <AppBar position={isCurrent ? 'absolute' : 'static'} style={{ borderBottom: 'none' }}
                  elevation={0} color="transparent" id="header">
            <CollectionBlock />
            <Content contentWidth={contentWidth}>
              <StyledToolbar>
                <HeaderTitle>
                  <img src={Logo} alt="Logo" />
                  <li onClick={() => history.push('/')}
                      style={{ color: home ? '#fff' : '#3433B8', fontWeight: home && '600' }}
                  >
                    {t('home_title')}
                  </li>
                </HeaderTitle>
                <ul>
                  <LinkApp />
                  <HelpButton />
                  <LocaleButton />
                  <AccountButton />
                </ul>
              </StyledToolbar>
            </Content>
          </AppBar>
          :
          <AppBar position={isCurrent ? 'absolute' : 'static'} elevation={1}
                  style={{ borderBottom: isCurrent && 'none' }} color="transparent" id="header">
            <Content contentWidth={contentWidth}>
              <StyledToolbar>
                <HeaderTitle>
                  <img src={Logo} alt="Logo" />
                  <li onClick={() => history.push('/')}>
                    {t('home_title')}
                  </li>
                </HeaderTitle>
                <ul>
                  <LinkApp />
                  <HelpButton/>
                  <LocaleButton/>
                  <AccountButton/>
                </ul>
              </StyledToolbar>
            </Content>
          </AppBar>
      }
    </>

  );
}

export default HeaderWithAuth;
