import axios from 'axios';

export const KindergartenApplicationsApi = {
  getBulletins: filter =>
    axios.get(`/api/public/data/buliten_of_free_place_by_filter`, { params: filter }),
  getApplications: filter => axios.get(`/api/self/kindergarten_queue?filter=${filter}`),
  getSignStringForRevoke: data =>
    axios.post('/api/public/transitions/info/sign_string/kindergarten_queue', data),
  revokeByApplicantApi: (id, data) =>
    axios.post(
      `/api/public/transitions/kindergarten_queue/${id}/revoke_by_applicant`,
      data
    ),
  confirmInterestApi: id =>
    axios.post(`/api/public/transitions/kindergarten_queue/${id}/confirm_interest`),
  getListDDO: id => 
    axios.get(`/api/public/info/kindergartens_by_queue_id?queueId=${id}`),
  choiceListDDO: (data) => 
    axios.post(
      `/api/public/transitions/change_interested_kindergartens`,
      data
    ),
  signatureListDDO: (data) => axios.post('/api/public/transitions/info/sign_string/kindergarten_queue', data), 
};
