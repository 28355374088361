import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardsContainer } from './KindergartenNotificationsStyle';
import './KindergartenNotificationsTranslate';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearState,
  loadApplications,
  kindergartenNotificationsModule
} from './KindergartenNotificationsDucks';
import { requestsColumns } from './TableColumns';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import Typography from '@material-ui/core/Typography';

function KindergartenNotifications() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const applications = useSelector(state => state[kindergartenNotificationsModule].data);
  const pageNumber = useSelector(
    state => state[kindergartenNotificationsModule].pageNumber
  );
  const totalElements = useSelector(
    state => state[kindergartenNotificationsModule].totalElements
  );
  const loading = useSelector(state => state[kindergartenNotificationsModule].loading);
  const loadData = filter => dispatch(loadApplications(filter));
  const { toPage } = useTableFilter(loadData, '', {
    pageNumber: 1,
    pageSize: 10
  });

  useEffect(() => {
    dispatch(clearState());
  }, []);

  return (
    <div className="mb3 mt3" style={{ width: '100%' }}>
      <Typography
        children={t('kindergarten_notifications_subtitle')}
        color="secondary"
        variant="h6"
      />
      <StyledCardsContainer>
        {applications && (
          <StaticTablePaginated
            pageNumber={pageNumber}
            loading={loading}
            pageSize={10}
            data={applications}
            totalElements={totalElements}
            columns={requestsColumns({ t, lang: i18n.language })}
            filterable={false}
            pagePagination={page => {
              if (page !== pageNumber) {
                toPage(page);
              }
            }}
          />
        )}
      </StyledCardsContainer>
    </div>
  );
}

export default KindergartenNotifications;
