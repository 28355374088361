import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { handleQuestionLabel } from '../../../Utils/handleQuestionLabel';
import { isMatrixVisible } from './DTableUtils';

class DTableHead extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.lang !== this.props.lang ||
      nextProps.code !== this.props.code ||
      nextProps.matrixVisibility !== this.props.matrixVisibility ||
      nextProps.hideRow !== this.props.hideRow ||
      nextProps.hideCol !== this.props.hideCol ||
      nextProps.rows !== this.props.rows ||
      nextProps.columns !== this.props.columns
    );
  }

  render() {
    const { lang, code, hideRow, hideCol, rows, columns, matrixVisibility } = this.props;
    return (
      <TableHead>
        {!hideRow &&
          rows.titles.map((rowItem, index) => (
            <TableRow key={`title_${code}_${index}`}>
              {!hideCol &&
                index === 0 &&
                columns.titles[0] &&
                columns.titles[0].length && (
                  <TableCell
                    key="emptyCell"
                    className="row-title"
                    colSpan={columns.titles[0].length}
                    rowSpan={rows.titles.length}
                  />
                )}
              {rowItem.dict.map((title, dictIndex) => {
                const colSpan = rows.titles[index + 1]
                  ? rows.titles[index + 1].dict
                      .filter(item => item.dataSet[title.dictCode] === title.code)
                      .filter(item => isMatrixVisible(matrixVisibility, item.dataSet))
                      .length
                  : rowItem.colSpan;
                return (
                  isMatrixVisible(matrixVisibility, title.dataSet) && (
                    <TableCell
                      key={`row_title_${title.code}_${dictIndex}`}
                      className="row-title"
                      colSpan={colSpan}
                    >
                      {handleQuestionLabel({ ...title, lookupId: title.dictCode }, lang)}
                    </TableCell>
                  )
                );
              })}
            </TableRow>
          ))}
      </TableHead>
    );
  }
}

export default DTableHead;
