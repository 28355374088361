import React, { useEffect } from 'react';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import paths from '../../../_helpers/paths';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import { kindergartenReestrViewModule, loadKindergartenView } from './KindergartenReestrViewDucks';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Wrapper } from './KindergartenReestrViewStyle';
import SwipeableTextMobileStepper from './Stepper';

export default function KindergartenReestrView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let lang = i18n.language;
  const { id } = useParams();
  const breadCrumbItems = [];
  breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
  breadCrumbItems.push({ name: t('kindergarten_subtitle'), href: paths.kindergartenServices });
  breadCrumbItems.push({ name: t('kindergarten_reestr_subtitle'), href: paths.kindergartenReestr });
  breadCrumbItems.push({ name: t('kindergarten_reestr_subtitle') });

  const loading = useSelector(state => state[kindergartenReestrViewModule].loading);
  let data = useSelector(state => state[kindergartenReestrViewModule].data);

  useEffect(() => {
    dispatch(loadKindergartenView(id, t));
  }, [])

  return (
    <div className="mb3 mt3" style={{width: '100%'}}>
      <BreadCrumbs items={breadCrumbItems} />
      {loading ?
        <CircularProgress />
      :
        <div className={'flex  mt2 justify-between'}>
          <div className="col1">
            <Button
              startIcon={<ArrowBackIcon color={'white'} />}
              variant="contained"
              color="secondary"
              onClick={() => history.go(-1)}
            >
              {t('kindergarten_reestr_view_button_back')}
            </Button>
            <div className='mt2 photo_wrapper'>
              {data[lang]['photos'] && data[lang]['photos'].length > 0 ? (
                <SwipeableTextMobileStepper photos={data[lang]['photos']}/>
              ) : (
                <img
                  src="https://langformula.ru/wp-content/uploads/2019/04/house.jpg"
                  className="do_image"
                  alt={''}
                />
              )}
            </div>
          </div>
          <div className="ml2 border fullWidth col2">
            <div className="border-bottom p2">
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_form')}: </h4>
                <span>{data[lang]['ownership']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_name_org')}: </h4>
                <span>{data[lang]['name']}</span>
              </div>
            </div>

            <div className="p1">
              <h3>{t('kindergarten_reestr_about')}</h3>
            </div>
            <div className="pl2 pr2">
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_address')}: </h4>
                <span>{data[lang]['legal_address']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_contact')}: </h4>
                <span>{data[lang]['contact']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_table_lang')}: </h4>
                <span>{data[lang]['lang']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_age_groups')}: </h4>
                <span>{data[lang]['age_group']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_project')}: </h4>
                <span>{data[lang]['design_capacity']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_time_stay')}: </h4>
                <span>{data[lang]['time_stay']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_playing_areas')}: </h4>
                <span>{data[lang]['playing_areas']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_operating_mode')}: </h4>
                <span>{data[lang]['operating_mode']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_video_surv')}: </h4>
                <span>{data[lang]['video_surv']}</span>
              </div>
              <div className="flex">
                <h4>{t('kindergarten_reestr_view_website')}: </h4>
                <span><a className="do_link" href={data[lang]['website']} target={'_blank'} rel="noopener noreferrer">
                  {t('kindergarten_reestr_view_go_to')}</a></span>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
