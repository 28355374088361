import { useLocation, useHistory } from 'react-router-dom';

export default function useModal(name) {
  const { pathname } = useLocation();
  const { replace } = useHistory();

  return {
    isOpen: !!(new URLSearchParams(window.location.search)).get(name),
    data: JSON.parse((new URLSearchParams(window.location.search)).get(name))?.data,
    open: (data) => {
      let sp = new URLSearchParams(window.location.search);
      typeof data === 'object'
        ? sp.set(name, JSON.stringify({ data }))
        : sp.set(name, data);
      replace(`${pathname}?${sp.toString()}`);
    },
    close: () => {
      let sp = new URLSearchParams(window.location.search);
      sp.delete(name);
      replace(`${pathname}?${sp.toString()}`);
    },
  };
}
