import React from 'react';
import { Button } from '@material-ui/core';
import { Wrapper } from './DComplexInlineStyle';
import AddIcon from '@material-ui/icons/Add';
import withTranslation from '../../translation/withTranslation';
import uuid from 'uuid/v4';
import withComplexFlc from '../../flc/withComplexFlc';
import FormHelperText from '@material-ui/core/FormHelperText';
import DComplexInlineRow from './DComplexInlineRow';
import { handleQuestionLabel } from '../../../Utils/handleQuestionLabel';

class DComplexInline extends React.Component {
  deleteRow(complexCode) {
    this.props.onDeleteRow(complexCode);
  }

  checkCellValue(value) {
    this.props.checkCellHasValue(value);
  }

  render() {
    const {
      t,
      lang,
      error,
      rowAdd,
      rowDeletion,
      complexCodes,
      onAddRow,
      disabled,
      cellsQuestions,
      cellsQuestionCodes,
      cellsSavedValue,
      cellsSavedVersion,
      cellsDefaultValue,
      cellsSource,
      cancelValidation,
      cellsDependencies = {},
      ...rest
    } = this.props;

    return (
      <Wrapper error={error} id={`anchor_${rest.code}`}>
        <div className="bold mb1">{handleQuestionLabel(this.props, lang)}</div>
        <div>
          {complexCodes.map((complexCode, index) => (
            <DComplexInlineRow
              key={complexCode}
              t={t}
              rowIndex={index}
              singleEntry={complexCodes.length === 1}
              code={rest.code}
              cancelValidation={cancelValidation}
              lang={lang}
              disabled={disabled}
              rowDeletion={rowDeletion}
              complexCode={complexCode}
              cellsQuestionCodes={cellsQuestionCodes}
              cellsQuestions={cellsQuestions}
              checkCellHasValue={value => this.checkCellValue(value)}
              cellsSavedValue={cellsSavedValue}
              cellsSavedVersion={cellsSavedVersion}
              cellsDefaultValue={cellsDefaultValue}
              cellsSource={cellsSource}
              cellsDependencies={cellsDependencies}
              onDeleteRow={complexCode => this.deleteRow(complexCode)}
            />
          ))}
          {!disabled && rowAdd && (
            <div className="mb2">
              <Button
                className="add-row"
                startIcon={<AddIcon />}
                onClick={() => onAddRow(uuid())}
                children={t('passport_complexTableAdd')}
              />
            </div>
          )}
        </div>
        {error && (
          <FormHelperText
            style={{ marginTop: 4, float: 'right' }}
            error
            children={error}
          />
        )}
      </Wrapper>
    );
  }
}

export default withTranslation(withComplexFlc(DComplexInline));
