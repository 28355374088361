import FlcTranslate from './Flc/FlcTranslate';

export default {
  ru: {
    passport_expandAll: 'Развернуть все разделы',
    passport_collapseAll: 'Свернуть все разделы',
    passport_crossingQuestions: 'Ошибка, пересечение показателей',
    passport_crossingCodes: 'Ошибка, пересечение кодов показателей в разделах',
    passport_complexTableAction: 'Действие',
    passport_complexTableDelete: 'Удалить запись',
    passport_complexTableAdd: 'Добавить запись',
    passport_errorExecuteRule: 'Ошибка при выполнении правил ФЛК',
    passport_file: 'Файл',
    passport_fileNotSelected: 'Файл не выбран',
    passport_fileErrorMimeType: 'Формат файла не подходит',
    passport_fileErrorMaxSize: 'Максимальный размер файла',
    passport_fileSelect: 'Выбрать файл',
    passport_canNotViewFile: 'Невозможно просмотреть файл, попробуйте скачать',
    passport_errorDownloadFile: 'Ошибка при загрузке файла!',
    passport_deleteFile: 'Удалить файл',
    passport_downloadFile: 'Скачать файл',
    passport_filesToArchive: 'Возможно прикрепление только одного файла! Если файлов несколько, необходимо собрать их в архив!',
    passport_dRadioYes: 'Да',
    passport_dRadioNo: 'Нет',
    passport_valueChanged: 'Значение изменено',
    ...FlcTranslate.ru
  },
  kk: {
    passport_expandAll: 'Бөлімдерді кеңейту',
    passport_collapseAll: 'Бөлімдерді тасалау',
    passport_crossingQuestions: 'Қате, өлшемдердің қиылысы',
    passport_crossingCodes: 'Қате, бөлімдердегі индикатор кодтарының қиылысы',
    passport_complexTableAction: 'Әрекет',
    passport_complexTableDelete: 'Жазбаны жою',
    passport_complexTableAdd: 'Жазба қосу',
    passport_errorExecuteRule: 'ФЛБ ережелерін орындау кезіндегі қате',
    passport_file: 'Файл',
    passport_fileNotSelected: 'Файл таңдалмаған',
    passport_fileErrorMimeType: 'Файл форматы сәйкес келмейді',
    passport_fileErrorMaxSize: 'Файлдың максималды өлшемі',
    passport_fileSelect: 'Файлды таңдаңыз',
    passport_canNotViewFile: 'Файлды көру мүмкін емес, жүктеп көріңіз',
    passport_errorDownloadFile: 'Файлды жүктеу кезінде қате пайда болды!',
    passport_deleteFile: 'Файлды жою',
    passport_downloadFile: 'Файлды жүктеу',
    passport_filesToArchive: 'Тек бір файлды қоса беруге болады! Егер бірнеше файл болса, оларды мұрағатқа жинау керек!',
    passport_dRadioYes: 'Иә',
    passport_dRadioNo: 'Жоқ',
    passport_valueChanged: 'Мән өзгерді',
    ...FlcTranslate.kk
  }
};
