import React from 'react';
import { useTranslation } from 'react-i18next';
import {StyledTitle, StyledUsefulArticles, StyledUsefulArticlesBg, StyledUsefulArticlesCards, 
    StyledUsefulArticlesCard, StyledUsefulArticlesCardImg, StyledUsefulArticlesCardTitle, StyledUsefulArticlesCardText} from '../KindergartenServicesStyle';
import './../KindergartenServicesTranslate';
import { Content } from '../../../components/ThemeProvider/ThemeStyle';
import usefulArticlesBg from '../../../_assets/images/UsefulArticlesBg.png'
import articles1 from '../../../_assets/images/articles1.jfif'
import articles2 from '../../../_assets/images/articles2.jfif'
import articles3 from '../../../_assets/images/articles3.jfif'

function KindergartenUsefulArticles({contentWidth}) {
  const { t } = useTranslation()
  return (
    <StyledUsefulArticles>
        <StyledUsefulArticlesBg>
            <img src={usefulArticlesBg} alt="" />
        </StyledUsefulArticlesBg>
        
        <Content contentWidth={contentWidth}>
            <StyledTitle children={t('kindergarten_article_title')} />
        </Content>
        <Content contentWidth={contentWidth}>
            <StyledUsefulArticlesCards>
                <StyledUsefulArticlesCard>
                    <StyledUsefulArticlesCardImg>
                        <img src = {articles2} alt=""/>
                    </StyledUsefulArticlesCardImg>
                    <StyledUsefulArticlesCardTitle children={t('kindergarten_articles_title')}/>
                    <StyledUsefulArticlesCardText children={t('kindergarten_articles_text')}/>
                </StyledUsefulArticlesCard>
                <StyledUsefulArticlesCard>
                    <StyledUsefulArticlesCardImg>
                        <img src = {articles1} alt=""/>
                    </StyledUsefulArticlesCardImg>
                    <StyledUsefulArticlesCardTitle children={t('kindergarten_articles_title2')}/>
                    <StyledUsefulArticlesCardText children={t('kindergarten_articles_text2')}/>
                </StyledUsefulArticlesCard>
                <StyledUsefulArticlesCard>
                    <StyledUsefulArticlesCardImg>
                        <img src = {articles3} alt=""/>
                    </StyledUsefulArticlesCardImg>
                    <StyledUsefulArticlesCardTitle children={t('kindergarten_articles_title3')}/>
                    <StyledUsefulArticlesCardText children={t('kindergarten_articles_text3')}/>
                </StyledUsefulArticlesCard>
            </StyledUsefulArticlesCards>
        </Content>
    </StyledUsefulArticles> 
  );
}

export default KindergartenUsefulArticles;