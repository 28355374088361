import { createReducer } from 'redux-starter-kit';
import { history } from '../../_helpers/history';
import routerProps from '../../_helpers/routerProps';
import paths from '../../_helpers/paths';
import { KindergartenReestrApi } from './KindergartenReestrApi';
import Notice from '../../modules/utils/Notice';
import Dict from '../../modules/utils/Dict';
import appConfig from '../../_helpers/config';
import axios from "axios";

/**
 * Constants
 */

export const kindergartenReestrModule = 'kindergartenReestrModule';
const SET_DATA = `${kindergartenReestrModule}/SET_DATA`;
const LOADING = `${kindergartenReestrModule}/LOADING`;
const CLEAR_STATE = `${kindergartenReestrModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data: [],
  pageNumber: 1,
  totalElements: 0
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.data;
    state.pageNumber = action.pageNumber;
    state.totalElements =
      action.pageNumber === 1 ? action.totalElements : state.totalElements;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [CLEAR_STATE]: () => initialState
});

export const clearState = () => ({ type: CLEAR_STATE });

export const loadKindergartens = (filter, t) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    let languages = await Dict.items('gu_language');
    let ownerships = await Dict.items('gu_form_ownership');
    const { data } = await KindergartenReestrApi.getKindergartens(filter);
    if (data.content) {
      let res = getKeyValues(data.content);
      let resData = []
      for (let bulletin of res) {

        if (bulletin.form_ownership) {
          let ownership = ownerships.find(function(item) {
            return item.code === bulletin.form_ownership.value;
          });
          bulletin.ownership_ru = ownership?.ru_name;
          bulletin.ownership_kk = ownership?.kk_name;
        }
        bulletin.langs = [];
        if (bulletin.lang_education) {
          let b_languages = [];
          bulletin.lang_education.value.map(cur_lang => {
            let language = languages.find(function(item) {
              return item.code === cur_lang;
            });
            b_languages.push({ru_name: language?.ru_name, kk_name: language?.kk_name});
          });
          bulletin.langs = b_languages;
        }
        if(bulletin.photo_do) {
          bulletin['photoDDO'] = await getImg(appConfig.url.fileStore+bulletin.photo_do.downloadLink+'?action=view')
        }
        resData.push(bulletin)
      }
      dispatch({
        type: SET_DATA,
        data: resData,
        pageNumber: data.pageNumber,
        totalElements: data.totalElements
      });
    } else {
      Notice.error(t('kindergarten_reestr_err'));
    }
  } catch (error) {
    console.log(error);
    let location = window.location;
    for (const route of Object.values(routerProps)) {
      if (route.path === location.pathname && !route.withoutAuth) {
        window.addressBarUrl = `${location.pathname}${location.search}`;
        history.push(paths.homePage);
      }
    }
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

const getKeyValues = data => {
  let res = [];
  data.forEach(item => {
    let obj = {};
    item.passportValues.forEach(a => {
      obj[a.code] = a.value;
    });
    res.push(obj);
  });
  return res;
};

function imagePreview(data) {
  const blob = new Blob([data])
  return URL.createObjectURL(blob)
}

export const getImg = async (imgUrl) => {
  try {
    let accessToken = localStorage.getItem('accessToken');
    const {data} = await KindergartenReestrApi.getImg(imgUrl, accessToken);
    return imagePreview(data)
  } catch (error) {
    console.log(error);
  }
}