import React, { useContext } from 'react';
import { ExpanderButtons } from '../PassportStyle';
import { Button } from '@material-ui/core';
import withTranslation from './translation/withTranslation';
import GroupExpander from './GroupExpander';
import { PassportContext } from '../PassportProvider';

function PassportExpander({ t, onExpand }) {
  const { hideExpander } = useContext(PassportContext);
  return (
    <ExpanderButtons>
      {!hideExpander && (
        <>
          <Button
            size="small"
            style={{ padding: '4px 10px' }}
            onClick={() => {
              GroupExpander.onExpandAll(true);
              if (onExpand) onExpand(true);
            }}
            children={t('passport_expandAll')}
          />
          <Button
            size="small"
            style={{ padding: '4px 10px' }}
            onClick={() => {
              GroupExpander.onExpandAll(false);
              if (onExpand) onExpand(false);
            }}
            children={t('passport_collapseAll')}
          />
        </>
      )}
    </ExpanderButtons>
  );
}

export default withTranslation(PassportExpander);
