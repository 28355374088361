import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardsContainer, StyledTableContainer } from './BulletinOpenPlacesStyle';
import './BulletinOpenPlacesTranslate';
import paths from '../../_helpers/paths';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearState,
  loadBulletins,
  bulletinOpenPlacesModule,
  createDirection
} from './BulletinOpenPlacesDucks';
import { requestsColumns } from './TableColumns/TableColumns';
import Filters from './BulletinOpenPlacesComponents/Filters/Filters.jsx';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import Search from './BulletinOpenPlacesComponents/Search/Search';
import i18n from 'i18next';
import useModal from '../../components/_hooks/useModal';
import DialogGetAppointment from './Dialogs/DialogGetAppointment';
import { useParams } from 'react-router-dom';
import SuccessfulAppointment from './Dialogs/SuccessfulAppointment';

function BulletinOpenPlaces() {
  const { t } = useTranslation();
  let lang = i18n.language;
  const dispatch = useDispatch();
  const breadCrumbItems = [];
  breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
  breadCrumbItems.push({
    name: t('kindergarten_subtitle'),
    href: paths.kindergartenServices
  });
  breadCrumbItems.push({ name: t('bulletin_op_subtitle') });

  const bulletins = useSelector(state => state[bulletinOpenPlacesModule].data);
  const pageNumber = useSelector(state => state[bulletinOpenPlacesModule].pageNumber);
  const totalElements = useSelector(
    state => state[bulletinOpenPlacesModule].totalElements
  );
  const loading = useSelector(state => state[bulletinOpenPlacesModule].loading);
  const { KqId } = useParams();
  const loadData = filter => {
    dispatch(loadBulletins(lang, filter, KqId));
  };
  const { filter, toPage, changePageSize, searchByFilter } = useTableFilter(loadData, '', {
    pageNumber: 1,
    pageSize: 10
  });
  const modal = useModal('getAppointment');
  const modalSuccess = useModal('successfulAppointment');
  const handleDone = data => {
    dispatch(createDirection(data, modal, modalSuccess));
  };
  useEffect(() => {
    dispatch(clearState());
  }, []);

  return (
    <div className="mb3 mt3" style={{ width: '100%' }}>
      <BreadCrumbs items={breadCrumbItems} />
      <StyledCardsContainer>
        <Filters loading={loading} filter={filter} searchByFilter={searchByFilter} lang={lang} />
        <div>
          <div className="warning">
            <div><b>{t('bulletin_op_warning_title')}</b></div>
            <div style={{textIndent: '15px'}}>
              {t('bulletin_op_warning_msg_before')}
              {t('bulletin_op_warning_items', { returnObjects: true }).map(
                (item, index) => {
                  return <div key={index}>{item}</div>;
                }
              )}
              {t('bulletin_op_warning_msg_after')}
            </div>
          </div>
          <Search loading={loading} filter={filter} searchByFilter={searchByFilter} />
          {bulletins && (
            <StyledTableContainer>
              <div className="mt2">
                <StaticTablePaginated
                  className='bulletin__ope-places'
                  TheadComponent={_ => null}
                  pageNumber={pageNumber}
                  loading={loading}
                  data={bulletins}
                  totalElements={totalElements}
                  changePageSize={changePageSize}
                  pageSizes={[10, 20, 50, 100]}
                  columns={requestsColumns({ t, lang, modal })}
                  filterable={false}
                  pagePagination={page => {
                    if (page !== pageNumber) {
                      toPage(page);
                    }
                  }}
                />
              </div>
            </StyledTableContainer>
          )}
        </div>
      </StyledCardsContainer>
      <SuccessfulAppointment
        open={modalSuccess.isOpen}
        handleClose={modalSuccess.close}
        data={modalSuccess.data}
        lang={lang}
      />
      <DialogGetAppointment
        open={modal.isOpen}
        handleClose={modal.close}
        loading={loading}
        handleDone={handleDone}
        original={modal.data}
        lang={lang}
        KqId={KqId}
      />
    </div>
  );
}

export default BulletinOpenPlaces;