import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  width: 68px;
  background-color: #e0dcff;
  height: 37px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const IconComponent = ({ value, onChange, setOpen }) => {
  return value ? (
    <IconWrapper onClick={() => onChange(null)}>
      <CloseIcon fontSize="small" />
    </IconWrapper>
  ) : (
    <IconWrapper onClick={() => setOpen(true)}>
      <ExpandMoreIcon style={{ fontSize: 25 }} />
    </IconWrapper>
  );
};

export default IconComponent;
