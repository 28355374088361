import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {useStyles, useStyles1} from './FiltersStyle'
import './FiltersTranslate';

function SelectAutocomlate({options, setKindergarten}) {
  const { t } = useTranslation();
  let lang = i18n.language;
  const classes = useStyles();
  const classes1 = useStyles1();
  const [acValue, setACValue] = React.useState();
  const [open, setOpen] = React.useState(false);
  
  const openOptions = (reason) => {
    if (reason === 'clear') {
      setOpen(true)
    }
  }

  return (
    <Autocomplete
      open={open}
      freeSolo = {acValue && open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disablePortal
      options={options}
      filterSelectedOptions = {false}
      getOptionLabel={(option) => option[`${lang}_name`]}
      noOptionsText={t('bulletin_op_filters_not_found')}
      onChange={(e, value, reason) => {
        setKindergarten(value?.id)
        setACValue(value)
        openOptions(reason)
      }}
      className={(acValue) ? classes.root : classes1.root}
      classes={{
        popupIndicator: acValue ? classes.popupIndicator : classes1.popupIndicator
      }}
      renderInput={(params) => <TextField {...params} label={t('bulletin_op_filters_kindergartens')} />}
      renderOption={(option) => (
        <Typography className={classes.comboOptions}>{option[`${lang}_name`]}</Typography>
    )}
    />
  );
}

export default SelectAutocomlate;