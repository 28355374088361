import styled from 'styled-components';

export const StyledSubtitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
