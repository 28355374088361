import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../modules/utils/handleError';
import { translation } from '../../_helpers/Translate';
import { NCALayerSign } from '../NCALayer/NCALayerSign';
import { HeaderApi } from './HeaderApi';
import Notice from '../../modules/utils/Notice';

const initialState = {
  loading: false,
};

export const headerModule = 'header';
const LOADING = `${headerModule}/LOADING`;

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
});

export const setConsentCollection = ({close, type}) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    let data = null
    if (type === 'agree') {
      data =  await HeaderApi.getTokenAgree();
    } else {
      data =  await HeaderApi.getTokenDisagree()
    }
    if (data.status === 200) {
      const signatureDoc = await NCALayerSign(data.data.result, false);
      const dataSignature = await HeaderApi.setSign({ signature: signatureDoc });
      if (dataSignature.status === 200) {
        close()
        Notice.success(translation('header_agreementSaveSuccess'));
      }
    }
  } catch (e) {
    handleError(e, translation('header_agreementErrorSuccess'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

