export default {
  flc_number_of_pupils:
    '«Дети-сироты и дети оставшиеся без попечения родителей по национальности» и «дети-сироты и дети оставшиеся без попечения родителей» должны быть равны',
  flc_incorrect: 'Некорректное значение',
  flc_undefined: 'Обязательно для заполнения',
  flc_required: 'Обязательно для заполнения',
  flc_test_validation_code: 'Тестовая проверка данных',
  flc_email_validation: 'Неправильный формат адреса электронной почты',
  flc_another_test_validation_code: 'Неправильный формат адреса электронной почты',
  flc_interactive_tools_validation_code: 'Не может быть равно нулю',
  flc_zero_validation_code:
    'Ставка должна равняться нулю для сотрудника в декретном отпуске',
  flc_personalCompareDates:
    'Дата расторжения/прекращения трудового договора не может быть меньше даты принятия на работу',
  flc_personalCheckemploymentDate:
    'Дата принятия на работу должна быть обязательно заполнена',
  flc_group_room_area:
    'Площадь групповых помещений должна быть меньше общей плошади здания',
  flc_up_limit: 'Не должно быть больше 2500',
  flc_area_not_null: 'При наличии спортивного зала площадь не должна равняться нулю',
  flc_less_five_numbers: 'Число должно быть не более 5 цифр',
  flc_less_count_book: 'Должно быть равно или меньше количеству комплектов книг',
  flc_less_count_mug: 'Должно быть меньше или равно количеству кружков',
  flc_office_quantaty_nomore_quantaty_withequipment:
    'Количество кабинетов, оснащенных современным обучающим оборудованием (за последние 10 лет) не может быть больше общего количества кабинетов',
  flc_not_more_people: 'Не может быть больше 20 человек',
  flc_total_design_capacity: 'Суммарная проектная мощность не может быть больше 2500',
  flc_groups_must_coincide:
    'Наименование группы должно совпадать с одним из наименований, введенных в показателе "Группы"',
  flc_not_null: 'Не может быть равно нулю',
  flc_no_more_total: 'Не может превышать значение «Всего»',
  flc_no_more_used_instudy:
    'Не может превышать значение «Используются в учебном/воспитательном процессе»',
  flc_no_more_for__teachers:
    'Не может превышать значение «Для преподавателей/учителей/воспитателей»',
  flc_can_not_exceed:
    'Не может превышать значение той же строки таблицы «Посещаемость всех, человек»',
  flc_no_more_total_equipment:
    'не может превышать значение «количество единиц установленного оборудования»',
  flc_no_more_total_facilities: 'Не может превышать значение «сумма выделенных средств»',
  flc_no_more_3number:
    'Средний размер заработной платы не должен превышать 999 тысяч тенге',
  flc_sum_lines_equal:
    'Сумма значений по строке «Всего учащихся» должна быть равна значению «Количество учащихся школьного возраста (6-18 лет) ,чел.» в разделе «Основные сведения об образовательном процессе»',
  flc_no_more_total_countries: 'Не может превышать значение «Выехали за пределы страны»',
  flc_groups_must_coincide_property:
    'Наименование группы должно совпадать с одним из наименований, введенных в показателе «Данные о группах всех форм собственности»',
  flc_can_not_exceed_diseases:
    'Не может превышать значение в той же строке таблицы «Число случаев заболевания у детей»',
  flc_wrongCoordinates: 'Некорректные координаты, отметьте место на карте!',
  flc_no_more_2_number: 'Количество  не должно превышать числа 99',
  flc_no_more_3_number: 'Количество не должно превышать числа 999',
  flc_no_more_exploitation: 'Не может быть больше «Год ввода здания в эксплуатацию»',
  flc_no_more_contract: 'Не должно быть больше «Скорость интернета по договору»',
  flc_no_more_total_area: 'Не должно быть больше «Общая площадь здания»',
  flc_no_more_work_area: 'Не должно быть больше «Рабочая площадь здания»',
  flc_no_more_current_computers:
    'Сумма не должна превышать количество компьютеров на текущий год',
  flc_no_more_total_cabinets: 'Не должно быть больше количества кабинетов',
  flc_more_null: 'Должно быть больше нуля',
  flc_no_more_ling_cabinets: 'Не должно превышать количество лингофонных кабинетов',
  flc_null_total_ling_cabinets:
    'Должно равняться нулю как в количестве лингофонных кабинетов',
  flc_null_total_inf_cabinets:
    'Должно равняться нулю как в количестве  кабинетов по информатике',
  flc_up_limit_do: 'Не должно быть больше 1000',
  flc_null_cabinets_fizics:
    'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по физике',
  flc_no_more_cabinets_fizics:
    'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" по физике',
  flc_null_cabinets_chemistry:
    'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по химии',
  flc_no_more_cabinets_chemistry:
    'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" по химии',
  flc_null_cabinets_bilogy:
    'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по биологии',
  flc_no_more_cabinets_biology:
    'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" по биологии',
  flc_null_cabinets_geography:
    'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по географии',
  flc_no_more_cabinets_geography:
    'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" по географии',
  flc_null_cabinets_ling:
    'Должно быть равно нулю как в таблице "Наличие кабинетов, единиц" по лингафонно-мультимедийным кабинетам',
  flc_no_more_cabinets_ling:
    'Должно быть меньше или равно как в таблице "Наличие кабинетов, единиц" полингафонно-мультимедийным кабинетам',
  flc_equal_zero: 'Должно быть равно нулю',
  flc_less_element:
    'Сумма Аудиторного и Внеаудиторного фонда не должны превышать Общую площадь здания',
  flc_no_more_5number: 'должно быть меньше 100 000',
  flc_more_yard_clubs: 'Должно быть больше «Количество дворовых клубов»',
  flc_one_hot_or_buffet:
    'Не может быть охвачен и горячим и буфетным питанием одновременно',
  flc_no_hotmeal:
    'Чтобы отметить "Охвачен(-а) бесплатным горячим питанием" нужно отметить  "Охвачен(-а) горячим питанием"',
  flc_no_buffet:
    'Чтобы отметить "Охвачен(-а) бесплатным буфетным питанием" нужно отметить  "Охвачен(-а) буфетным питанием"',
  flc_equal_rebuildings:
    'Должно быть равным количеству зданий с техническим состоянием «проведен или требует капитальный ремонт»',
  flc_equal_current_repairs:
    'Должно быть равным количеству зданий с техническим состоянием «проведен или требует текущий ремонт»',
  flc_cost_more_null: 'Стоимость должна быть больше нуля',
  flc_need_boording:
    'Учащийся не может одновременно «проживать в интернате при школе» и «нуждаться в размещении в интернате при школе»',
  flc_equal_sum_lingcabinets:
    'Сумма ячеек должна быть равна показателю «Наличие кабинетов, единиц» в нем элементу «лингафонные кабинеты»',
  flc_not_more_total_work_experience:
    'Стаж педагогической работы не может быть больше «Общий стаж работы»',
  flc_no_more_16_year: 'Стаж педагогической работы допустим с 16-ти лет',
  flc_school_children_age:
    'Должно быть меньше или равно элементу «Количество учащихся школьного возраста (6-18 лет)»',
  flc_sum_lines_equal_two:
    'Сумма значений по этой строке должна быть равна значению «Количество детей дошкольного возраста (1-6 лет)» в разделе «Основные сведения об образовательном процессе»',
  flc_integer_5number: 'Должно быть целое положительное число не более 5 цифр',
  flc_school_children_age_two:
    'Должен быть меньше или равен элементу «Количество детей дошкольного возраста (1-6 лет)»',
  flc_must_main: 'Должно быть хотя бы одно «Основное здание»',
  flc_following_format: 'Номер телефона должен соответствовать 11 цифр без символов',
  flc_scholarships_available:
    'при наличии стипендии в показателе «Информация о начислении и размер стипендии, тенге» не менее 1 ячейки должно быть больше 0',
  flc_should_not_exceed: 'не должен превышать «Общий стаж в данной организации»',
  flc_property_group_data:
    'количество строк таблицы «Группы» и «Данные о группах всех форм собственности» должны быть равны',
  flc_girls_boys_total_not_exceed:
    'сумма строк «Из них девочек» и «Из них мальчиков» не должно превышать «Общее количество»',
  flc_the_total_must_match:
    'показатель «Общее количество детей» должно совпадать с «Общее количество детей по нарушениям (заболеваниям)» и «Из них по возрасту»',
  flc_need_for_staff: '«из них женщин» не должно превышать «потребность в кадрах»',
  flc_language_match_groups:
    'выбранные языки в показателе «Язык обучения» должны совпадать с показателем «Группы»',
  flc_should_once_main: 'Основное здание может быть только одно',
  flc_cant_anymore_quantity: 'Не может быть больше «Количество, экз»',
  flc_transportation_information:
    'Нельзя одновременно выбрать «самостоятельно добирается в школу» и «охвачен ежедневным подвозом»',
  flc_the_above_line: 'может быть равна или меньше вышеуказанной строке',
  flc_the_above_line_teachers:
    'может быть равна или меньше строке «Для преподавателей/учителей/воспитателей»',
  flc_unused_amount:
    'сумма элементов «из них подлежат ремонт» и «из них не подлежат ремонту (требует списания)» может быть равна или меньше элемента «Не используемых»',
  flc_latin_allowed: 'допускается ввод латинских букв и цифры',
  flc_sum_elements_total:
    'сумма элементов «Всего» должна быть равна элементу «Всего» таблицы «Количество компьютеров, единиц»',
  flc_sum_elements_total2:
    '«Количество компьютеров с установленной операционной системой» и «Количество компьютеров с установленными пакетами офисных программ» должны быть равны или меньше элемента «Всего» таблицы «Количество компьютеров, единиц»',
  flc_sum_elements_total3:
    'сумма строк по каждому году должна быть равна элементу «Всего» таблицы «Количество компьютеров, единиц»',
  flc_no_more_than_25: 'Не должно превышать больше 25 мест'
};
