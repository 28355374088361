export default class FlcTrigger {
  static subscribers = {};

  // подписка на изменения
  static subscribe(codes, subscriber, callback) {
    if (codes) {
      for (const code of codes) {
        if (this.subscribers.hasOwnProperty(code)) {
          this.subscribers[code][subscriber] = callback;
        } else {
          this.subscribers[code] = { [subscriber]: callback };
        }
      }
      // unsubscribe
      return () => {
        for (const code of codes) {
          if (this.subscribers.hasOwnProperty(code)) {
            delete this.subscribers[code][subscriber];
          }
        }
      };
    }
  }

  static run(code) {
    if (code && this.subscribers.hasOwnProperty(code)) {
      for (const callback of Object.values(this.subscribers[code])){
        callback();
      }
    }
  }
}
