import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    market_download: 'Загрузить',
    modal_title: 'Для отметки  учета посещения детей посредством QR, необходимо установить мобильное приложение "e-zhetysu QR".  Для установки мобильного приложения перейдите по данной ссылке:',
  },
  kk: {
    market_download: 'Жүктеу',
    modal_title: 'Балалардың келуін тіркеуді QR арқылы белгілеу үшін «e-zhetysu QR» мобильді қосымшасын орнату қажет. Мобильді қосымшаны орнату үшін мына сілтеме арқылы өтіңіз:',
  }
});
