import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import PassportForm from '../../../passport/FormData/PassportForm';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeComplexValue } from '../../../passport/Passport';

async function changeCollege({ code, complexCode, t, setLoading, requesterIin, lang }) {
  try {
    setLoading(true);
    const response = await ServiceRequestApi.getColleges(requesterIin);
    const c = response.data.content.find(s => s.status === 2);
    if (c) {
      const name = c[`schoolName${capitalize(lang)}`];
      const spec = c.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
      const docNumber = c.passportValues.find(q => q.code === 'DiplomaSeriesandNumber');
      const dispose = c.passportValues.find(q => q.code === 'DateOfDisposal');
      changeComplexValue(code, {
        ...PassportForm.cellsValues[code],
        [complexCode]: {
          ...PassportForm.cellsValues[code][complexCode],
          EDU_Name: name,
          EDU_specialty: spec ? spec.value.value : '',
          EDU_doc_number: docNumber ? docNumber.value : '',
          EDU_date_finish: dispose ? dispose.value : ''
        }
      });
    } else {
      handleError(response, t('dataNotFound'));
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function ComplexCollege({ code, complexCode, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeCollege({
          t,
          code,
          complexCode,
          setLoading,
          requesterIin,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default ComplexCollege;