import React, {useEffect} from 'react';
import { DialogTitle, TextField } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { StyledDialog, StyledAutocompleteContainer } from './../KindergartenApplicationsStyle';
import Typography from '@material-ui/core/Typography';
import { getListDDO, kindergartenApplicationsModule, signatureListDDO } from './../KindergartenApplicationsDucks'
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";

export default function DialogSelectDDO({ open, handleClose, data }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const listDDO = useSelector(state => state[kindergartenApplicationsModule].listDDO);

  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
  });

  useEffect(() => {
    if (data?.id) {
      dispatch(getListDDO(data?.id))
      form.reset({ 
        DD0_1: data.interestedKindergartenShortInfos[0],
        DD0_2: data.interestedKindergartenShortInfos[1],
        DD0_3: data.interestedKindergartenShortInfos[2],
        DD0_4: data.interestedKindergartenShortInfos[3],
      });
    }
  }, [data?.id])

  const onSubmit = (values) => {
    dispatch(signatureListDDO({ values, id: data?.id, handleClose }))
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className='center'>
        <Typography  
          style={{
            width: '70%',
            maxWidth: '70%',
            margin: 'auto',
            fontSize: '18px',
            fontWeight: 'bold'
          }}
          children={t('kindergarten_applications_selectDDO_title')} 
        />
      </DialogTitle>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogContent>
          <StyledAutocompleteContainer>
            <Controller
              control={form.control}
              name='DD0_1'
              as={(fieldProps) => (
                <Autocomplete
                  options={listDDO}
                  onChange={(event, newValue) => {
                    fieldProps.onChange(newValue);
                    form.setValue('DD0_1', newValue)
                  }}
                  noOptionsText={t('kindergarten_applications_selectDDO_not_found')}
                  value={form.watch('DD0_1')}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id}
                  }
                  getOptionLabel={(option) => option[`${i18n.language}_name`]}
                  {...fieldProps.field}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label={t('bulletin_op_filters_kindergartens')} 
                    />
                  }
                  style={{
                    width: '100%',
                    marginBottom: '10px'
                  }}
                />
              )}
            />
          </StyledAutocompleteContainer>
          <StyledAutocompleteContainer>
            <Controller
              control={form.control}
              name={'DD0_2'}
              defaultValue={null}
              as={(fieldProps) => (
                <Autocomplete
                  options={listDDO}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id}
                  }
                  getOptionLabel={(option) => option[`${i18n.language}_name`]}
                  value={form.watch('DD0_2')}
                  onChange={(event, newValue) => {
                    fieldProps.onChange(newValue);
                    form.setValue('DD0_2', newValue)
                  }}
                  noOptionsText={t('kindergarten_applications_selectDDO_not_found')}
                  {...fieldProps.field}
                  renderInput={(params) => <TextField {...params} label={t('bulletin_op_filters_kindergartens')} />}
                  style={{
                    width: '100%',
                    marginBottom: '10px'
                  }}
                />
              )}
            />
          </StyledAutocompleteContainer>
          <StyledAutocompleteContainer>
            <Controller
              control={form.control}
              name={'DD0_3'}
              as={(fieldProps) => (
                <Autocomplete
                  options={listDDO}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id}
                  }
                  getOptionLabel={(option) => option[`${i18n.language}_name`]}
                  value={form.watch('DD0_3')}
                  onChange={(event, newValue) => {
                    fieldProps.onChange(newValue);
                    form.setValue('DD0_3', newValue)
                  }}
                  noOptionsText={t('kindergarten_applications_selectDDO_not_found')}
                  {...fieldProps.field}
                  renderInput={(params) => <TextField {...params} label={t('bulletin_op_filters_kindergartens')} />}
                  style={{
                    width: '100%',
                    marginBottom: '10px'
                  }}
                />
              )}
            />
          </StyledAutocompleteContainer>
          <StyledAutocompleteContainer>
            <Controller
              control={form.control}
              name={'DD0_4'}
              as={(fieldProps) => (
                <Autocomplete
                  options={listDDO}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id}
                  }
                  getOptionLabel={(option) => option[`${i18n.language}_name`]}
                  value={form.watch('DD0_4')}
                  onChange={(event, newValue) => {
                    fieldProps.onChange(newValue);
                    form.setValue('DD0_4', newValue)
                  }}
                  noOptionsText={t('kindergarten_applications_selectDDO_not_found')}
                  {...fieldProps.field}
                  renderInput={(params) => <TextField {...params} label={t('bulletin_op_filters_kindergartens')} />}
                  style={{
                    width: '100%',
                    marginBottom: '10px'
                  }}
                />
              )}
            />
          </StyledAutocompleteContainer>
        </DialogContent>
        <DialogActions className='flex justify-end'>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            {t('kindergarten_applications_selectDDO_no')}
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
          >
            {t('kindergarten_applications_selectDDO_yes')}
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
}