import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    instructions_title: 'Руководство пользователя',
    instructions_viewFileFailed: 'Не удалось открыть файл для просмотра'
  },
  kk: {
    instructions_title: 'Пайдаланушы нұсқаулығы',
    instructions_viewFileFailed: 'Көру үшін файлды ашу мүмкін болмады'
  }
});