import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { Autocomplete } from '@material-ui/lab';

export const StyledCardsContainer = styled.div`
  font-family: Roboto,serif;
  width: 100%;
  height: 100%;
  .MuiFormLabel-root {
    color: rgb(191, 191, 191);
    font-size: 14px;
  }
  .ReactTable {
    border-top: 1px solid rgba(125,125,125,0.4);
  }
  .table_actions{
    height: 29px;
    margin: 16px 0px;
  }
  .table_actions button{
    margin-right: 20px;
  }
  .centerContainer{
    text-align: center;
    margin: auto;
    top: 10%;
  }
  .queue_filter_inputs{
    font-size: 14px;
    text-transform: uppercase;
    width: 290px;
  }
  .mgt5{
    margin-top: 5px;
  }
  .mgt15{
    margin-top: 15px;
  }
  .text{
    margin-top: 10px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  .result{
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    text-transform: initial;
  }
  .table_head_q{
    display: none;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 20px !important;
    width: 1000px;
    max-width: 1000px;
  }
`;

export const StyledAutocompleteContainer = styled.div`
  .MuiAutocomplete-popperDisablePortal {
    position: fixed;
    height: 300px; 
    overflow: auto !important;
  }
  .MuiAutocomplete-popperDisablePortal::-webkit-scrollbar {
    width: 16px;
  }
  .MuiAutocomplete-popperDisablePortal::-webkit-scrollbar-track {
    position: absolute;
    right: -3rem;
    top: -50rem;
    background: transparent;
  }
`;