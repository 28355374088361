import styled from 'styled-components';
import { Color } from '../../ThemeProvider/ThemeStyle';
import { Popper } from '@material-ui/core';

export const Menu = styled(Popper)`
  max-height: 450px;
  padding: 15px 0;
  background-color: ${() => Color.bgColorPaper};
  overflow-y: auto;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: ${() => Color.borderRadius};
  min-width: ${props => (props.anchorEl ? props.anchorEl.offsetWidth + 'px' : 'auto')};
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(140, 140, 140, 0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(140, 140, 140, 0.4);
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
`;

export const Option = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  transform: translateX(-20px);
  user-select: none;

  :focus {
    outline: none;
  }

  .ts-expander {
    min-width: 35px;
    min-height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    margin-right: 5px;
  }

  .ts-item-label {
    background-color: ${props => (props.selected ? Color.bgColor : 'inherit')};
    font-weight: ${props => (props.selected ? '600' : 'normal')};
    padding: 0 15px;
    width: calc(100% - 40px);
    min-height: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    :hover {
      background-color: ${() => Color.bgColor};
    }
    span {
      line-height: 1;
    }
  }
`;
