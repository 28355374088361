import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { DialogContentText, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../modules/Button/Button';
import React from 'react';

export default function DialogGetAppointment({
  open,
  handleClose,
  handleDone,
  loading,
  original,
  lang,
  KqId
}) {
  const { t } = useTranslation();
  const address = original ? (lang === 'ru' ? original.legal_address : original.legal_address_kz) : '';

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('bulletin_op_get_appointment')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('bulletin_op_get_appointment_modal_body', {name: original ? original[`${lang}_name`] : '',
            language: original ? original[`gu_language_${lang}`] : '',
            group_name: original ? original[`name_group_${lang}`] : '',
            address: address ? address.replace('/', " ∕ ") : ''})}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{textAlign: "center"}}>
        <div style={{width: "50%"}}>
          <Button
            onClick={handleClose}
            color="primary"
            loading={loading}
            variant="contained"
            text={t('bulletin_op_get_appointment_modal_action_no')}
          />
        </div>
        <div style={{width: "50%"}}>
          <Button
            disabled={loading}
            onClick={() =>
              handleDone({
                bulletinId: original?.id,
                kindergartenQueueId: Number(KqId),
                ru_name: original ? original['ru_name'] : '',
                kk_name: original ? original['kk_name'] : '',
              })
            }
            color="secondary"
            variant="contained"
            loading={loading}
            text={t('bulletin_op_get_appointment_modal_action_yes')}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
