import styled, { css } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

export const getTheme = () => {
  let themeType = 'light';
  if (String(window.localStorage.getItem('themeType')) === 'dark') {
    themeType = 'dark';
    window.localStorage.setItem('themeType', 'dark');
  } else {
    window.localStorage.setItem('themeType', themeType);
  }
  let isDark = themeType === 'dark';
  return {
    isDark: isDark,
    palette: {
      type: themeType,
      primary: { main: '#2185D0' },
      secondary: { main: '#e2726f' },
      background: {
        paper: isDark ? '#252525' : '#fff',
        default: isDark ? '#333333' : '#E1E1E1'
      },
      border: {
        default: isDark ? '#444446' : '#cecfd2'
      },
      text: {
        disabled: isDark ? 'rgba(255, 255, 255, 0.50)' : 'rgba(0, 0, 0, 0.50)',
        primary: isDark ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.87)',
        secondary: isDark ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.54)'
      }
    },
    shape: { borderRadius: '4px' },
    typography: {
      useNextVariants: true,
      fontFamily: 'Lato,Helvetica Neue,Arial,Helvetica,sans-serif',
      fontSize: 13
    },
    props: {
      MuiRadio: { size: 'small' },
      MuiTooltip: { placement: 'top' },
      MuiOutlinedInput: { margin: 'none' },
      MuiInputLabel: { margin: 'none' },
      MuiFilledInput: { margin: 'none' },
      MuiFormControl: { margin: 'none', variant: 'outlined' },
      MuiIconButton: { size: 'small' },
      MuiInputBase: { margin: 'none', variant: 'outlined' },
      MuiAccordion: { elevation: 0 },
      MuiDrawer: { style: { zIndex: 1000 } },
      MuiListItem: { dense: true },
      MuiFab: { size: 'small' },
      MuiTable: { size: 'small' },
      MuiTableCell: { style: { fontSize: 14 } },
      MuiSelect: {
        SelectDisplayProps: {
          style: {
            paddingBottom: 8,
            paddingTop: 8
          }
        }
      },
      MuiTextField: {
        margin: 'none',
        variant: 'outlined',
        fullWidth: true,
        autoComplete: 'off'
      }
    },
    overrides: {
      MuiTooltip: {
        tooltip: { fontSize: 12 }
      },
      MuiRadio: { root: { padding: 7.22 } },
      MuiCheckbox: { root: { padding: 7.22 } },
      MuiIconButton: {
        sizeSmall: {
          marginLeft: 2,
          marginRight: 2,
          padding: 4
        }
      },
      MuiAccordion: {
        root: {
          backgroundColor: 'transparent'
        }
      },
      MuiAccordionSummary: {
        root: {
          minHeight: '45px !important',
          padding: '0 16px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          backgroundColor: 'rgba(125,125,125,0.1)'
        },
        content: {
          margin: '0 !important'
        }
      },
      MuiAccordionDetails: {
        root: {
          borderTop: `1px solid ${isDark ? '#444446' : '#cecfd2'}`,
          padding: '10px 16px 14px',
          display: 'block'
        }
      },
      MuiFormHelperText: {
        contained: {
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 2,
          lineHeight: 1
        }
      },
      MuiInputBase: {
        root: {
          fontSize: '15px',
          height: 'inherit',
          '&$disabled': {
            background: 'rgba(125,125,125,0.1)'
          }
        },
        input: {
          paddingBottom: '9.5px !important',
          paddingTop: '9.5px !important',
          height: 'inherit'
        }
      },
      MuiAutocomplete: {
        inputRoot: {
          padding: '0 !important',
          paddingRight: '8px !important'
        },
        input: {
          height: 'inherit',
          padding: '9.5px 14px !important'
        },
        paper: {
          boxShadow:
            '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)'
        },
        listbox: {
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(140, 140, 140, 0.2)'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(140, 140, 140, 0.4)'
          },
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          }
        }
      },
      MuiOutlinedInput: {
        adornedEnd: {
          paddingRight: '3px'
        },
        adornedStart: {
          paddingLeft: '3px'
        },
        input: {
          paddingBottom: 9.5,
          paddingTop: 9.5,
          height: 'inherit'
        },
        multiline: {
          padding: '0px 14px'
        }
      },
      MuiMenu: {
        paper: {
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(140, 140, 140, 0.2)'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(140, 140, 140, 0.4)'
          },
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          }
        }
      },
      MuiMenuItem: { root: { whiteSpace: 'inherit !important' } },
      MuiList: { padding: { paddingRight: '0 !important' } },
      MuiTextField: { root: { marginTop: 2, marginBottom: 2 } },
      MuiFormControl: { root: { marginTop: 2, marginBottom: 2 } },
      MuiLinearProgress: {
        root: {
          height: '8px'
        },
        colorPrimary: {
          backgroundColor: 'rgba(125,125,125,0.1)'
        },
        barColorPrimary: {
          backgroundColor: 'rgba(125,125,125,0.3)'
        }
      },
      MuiTableCell: { root: { height: 28 } },
      MuiTypography: { body1: { lineHeight: 1 } }
    }
  };
};
export const theme = createMuiTheme(getTheme());

export class Color {
  static isDark = theme.isDark;
  static primary = theme.palette.primary.main;
  static secondary = theme.palette.secondary.main;
  static textPrimary = theme.palette.text.primary;
  static textSecondary = theme.palette.text.secondary;
  static textSuccess = '#559754';
  static bgColor = theme.palette.background.default;
  static bgColorPaper = theme.palette.background.paper;
  static border = theme.palette.border.default;
  static borderRadius = theme.shape.borderRadius;

  static updateParams(theme) {
    Color.isDark = theme.isDark;
    Color.primary = theme.palette.primary.main;
    Color.secondary = theme.palette.secondary.main;
    Color.textPrimary = theme.palette.text.primary;
    Color.textSecondary = theme.palette.text.secondary;
    Color.bgColor = theme.palette.background.default;
    Color.bgColorPaper = theme.palette.background.paper;
    Color.border = theme.palette.border.default;
    Color.borderRadius =
      typeof theme.shape.borderRadius === 'number'
        ? `${theme.shape.borderRadius}px`
        : theme.shape.borderRadius;
  }
}

export const ThemeWrapper = styled.main`
  ${() => css`
    .text-primary {
      color: ${Color.textPrimary};
    }
    .text-secondary {
      color: ${Color.textSecondary};
    }
    .color-primary {
      color: ${Color.primary};
    }
    .color-secondary {
      color: ${Color.secondary};
    }
    .text-success {
      color: ${Color.textSuccess};
    }
    .bg-color {
      background-color: ${Color.bgColor};
    }
    .bg-primary {
      background-color: ${Color.primary};
    }
    .bg-secondary {
      background-color: ${Color.secondary};
    }
    .border-primary {
      border: 1px solid ${Color.primary};
    }
    .border-secondary {
      border: 1px solid ${Color.secondary};
    }
    .border {
      border: 1px solid ${Color.border};
    }
    .border-top {
      border-top: 1px solid ${Color.border};
    }
    .border-radius {
      border-radius: ${Color.borderRadius};
    }
    .card {
      border-radius: ${Color.borderRadius} !important;
      border: 1px solid ${Color.border};
    }
  `} label {
    margin-bottom: 0 !important;
  }
`;
