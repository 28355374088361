import { createReducer } from 'redux-starter-kit';
import { history } from '../../_helpers/history';
import routerProps from '../../_helpers/routerProps';
import paths from '../../_helpers/paths';
import { QueueApplicationsApi } from './QueueApplicationsApi';
import Notice from '../../modules/utils/Notice';

/**
 * Constants
 */

export const queueApplicationsModule = 'queueApplications';
const SET_DATA = `${queueApplicationsModule}/SET_DATA`;
const LOADING = `${queueApplicationsModule}/LOADING`;
const CLEAR_STATE = `${queueApplicationsModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data: [],
  pageNumber: 1,
  totalElements: 0,
  totalVo: 0,
  totalOo: 0,
  totalPo: 0,
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.data;
    state.pageNumber = action.pageNumber;
    state.totalElements = action.pageNumber === 1 ? action.totalElements : state.totalElements;
    state.totalVo = action.totalVo;
    state.totalOo = action.totalOo;
    state.totalPo = action.totalPo;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [CLEAR_STATE]: () => initialState
});

export const clearState = () => ({ type: CLEAR_STATE })

export const loadApplications = (t, filter, search = "") => async dispatch => {
  try {
    if(filter && filter.action !== "filter"){
      return;
    }
    delete filter.action;
    if(!checkFilters(filter)){
      Notice.error(t('queue_applications_ducks_filter_err'));
      return;
    }
    dispatch({ type: LOADING, loading: true });
    const { data } = await QueueApplicationsApi.getApplications(JSON.stringify(filter), search);
    if(data.status === "SUCCESS"){
      dispatch({ type: SET_DATA, data: data.result.content, pageNumber: data.result.pageNumber,
        totalElements: data.result.totalElements, totalVo: data.result.totalVo,
        totalOo: data.result.totalOo, totalPo: data.result.totalPo});
    }else{
      Notice.error(t('queue_applications_ducks_err'));
    }
  } catch (error) {
    let location = window.location;
    for (const route of Object.values(routerProps)) {
      if (route.path === location.pathname && !route.withoutAuth) {
        window.addressBarUrl = `${location.pathname}${location.search}`;
        history.push(paths.homePage);
      }
    }
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

const checkFilters = (filter) => {
  return !!(filter && filter.childYearBirth && filter.queuedRegion &&
    (filter.queuedType === "general" || (filter.queuedType !== "general" &&
      (filter.rehabType || filter.correctionType))));
}
