import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../modules/utils/handleError';
import { translation } from '../../_helpers/Translate';
import { AccountSettingsApi } from './AccountSettingsApi';
import Notice from '../../modules/utils/Notice';
import { setUserRequester } from '../LoginPage/LoginDucks';
import Dict from '../../modules/utils/Dict';

const initialState = {
  meta: null,
  loading: false,
  loadingSave: false,
  person: null,
  account: null,
};

export const accountSettingsModule = 'accountSettings';
const LOADING = `${accountSettingsModule}/LOADING`;
const SET_DATA = `${accountSettingsModule}/SET_DATA`;
const LOADING_SAVE = `${accountSettingsModule}/LOADING_SAVE`;
const CLEAR_STATE = `${accountSettingsModule}/CLEAR_STATE`;
const SET_PERSON = `${accountSettingsModule}/SET_PERSON`
const SET_ACCOUNT = `${accountSettingsModule}/SET_ACCOUNT`

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [SET_DATA]: (state, action) => {
    if (action.meta) {
      state.meta = action.meta;
    }
    state.data = action.data;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.loading;
  },
  [SET_PERSON]: (state, action) => {
    state.person = action.payload
  },
  [SET_ACCOUNT]: (state, action) => {
    state.account = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

export const clearState = () => ({ type: CLEAR_STATE });

export const loadRequesterMeta = () => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data: metadata } = await AccountSettingsApi.loadRequesterMeta();

    dispatch({ type: SET_DATA, meta: metadata });
  } catch (e) {
    handleError(e, translation('accountSettingsDucks_loadRequesterFailed'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const saveRequesterData = (formData, callback) => async dispatch => {
  try {
    dispatch({ type: LOADING_SAVE, loading: true });
    const response = await AccountSettingsApi.updateRequesterData(formData);
    if (response.data.results.length === 0) {
      dispatch({ type: SET_DATA, data: response.data.entity });
      dispatch(setUserRequester());
      callback();
      Notice.success(translation('accountSettingsDucks_saveRequesterSuccess'));
    } else {
      handleError(response, translation('accountSettingsDucks_saveRequesterFailed'));
    }
  } catch (e) {
    handleError(e, translation('accountSettingsDucks_saveRequesterFailed'));
  } finally {
    dispatch({ type: LOADING_SAVE, loading: false });
  }
};

export const getPerson = (iin, username) => async(dispatch) => {
  try {
    dispatch({ type: LOADING_SAVE, loading: true });
    const data = await AccountSettingsApi.getPerson(iin);
    const userProfile = await AccountSettingsApi.getUserProfile(username);
    dispatch({ type: SET_ACCOUNT, payload: userProfile.data.result })

    if (data.status === 200) {
      let user = null;
      data.data.content.forEach(item => {
        let obj = {};
        item.passportValues.forEach(a => {
            obj[a.code] = a.value;
        });
        user = obj
      });
      dispatch({ type: SET_PERSON, payload: user })
    }
  } catch (e) {
    handleError(e, translation('accountSettingsDucks_loadRequesterFailed'));
  } finally {
    dispatch({ type: LOADING_SAVE, loading: false });
  }
}