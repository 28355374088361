import axios from 'axios';

export const RequestsApi = {
  getRequests: filter => axios.get(
    `/api/self/paging/request_form?filter=${JSON.stringify(filter)}`
  ),
  downloadReportForm: (requestFormId, reportId) => axios.get(
    `/api/public/info/request_form_preview/${requestFormId}/${reportId}?force=true`,
    { responseType: 'blob' }
  )
};