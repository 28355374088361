import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { StyledSearch } from './SearchStyle';
import { useTranslation } from 'react-i18next';
import './SearchTranslate';
import i18n from 'i18next';
import { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Search({loading, searchByFilter}) {
  const { t } = useTranslation();
  let lang = i18n.language;
  const [guName, setGuName] = useState("");

  const searchData = () => {
    let new_filter = {};
    if(lang === 'ru'){
      new_filter['ruName'] = guName;
      new_filter['kkName'] = null;
    }else{
      new_filter['ruName'] = null;
      new_filter['kkName'] = guName;
    }
    searchByFilter(new_filter);
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      searchData();
    }
  }

  return (
    <StyledSearch>
      <Paper
        className="search_box"
        component="form"
        style={{ padding: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <InputBase
          style={{ marginLeft: '1', flex: '1' }}
          placeholder={t('kindergarten_reestr_search_label')}
          inputProps={{ 'aria-label': t('kindergarten_reestr_search_label') }}
          value={guName}
          onChange={(e) => setGuName(e.target.value)}
          onKeyDown={e => handleKeyDown(e)}
        />
        {loading ?
          <CircularProgress />
          :
          <IconButton aria-label="search" onClick={() => searchData()}>
            <SearchIcon className="search_icon" />
          </IconButton>
        }
        <Divider style={{ height: '28', margin: '0.5' }} orientation="vertical" />
      </Paper>
    </StyledSearch>
  );
}
