import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '../../../modules/Button/Button';
import React from 'react';
import { SuccessfulAppointmentStyle } from './SuccessfulAppointmentStyle';
import { useHistory } from 'react-router-dom';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import DialogActions from '@material-ui/core/DialogActions';

export default function SuccessfulAppointment({
  open,
  handleClose,
  data,
  lang,
}) {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <SuccessfulAppointmentStyle>
      <IconButton aria-label="delete" onClick={handleClose} className="dialog_close">
        <CloseSharpIcon/>
      </IconButton>
      <DialogTitle id="alert-dialog-title" style={{textAlign: "center"}}>
        {t('bulletin_op_success_appointment_modal_title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{textIndent: '30px'}}>
          {t('bulletin_op_success_appointment_modal_body', {id: data?.id, ddo: data ? lang === 'ru' ? data['serviceProviderRuName'] : data['serviceProviderKkName'] : ''})}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{justifyContent: "center"}}>
        <Button
          onClick={() =>
          {push('/kindergarten-appointments')}
          }
          color="secondary"
          variant="contained"
          text={t('bulletin_op_success_appointment_action_goTo')}
        />
      </DialogActions>
      </SuccessfulAppointmentStyle>
    </Dialog>
  );
}
