import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import RenderComponent from '../../Generate/RenderComponent';
import { getCellDependency } from './getCellDependency';
import CloseIcon from '@material-ui/icons/Close';

class DComplexInlineRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.code !== this.props.code ||
      nextProps.lang !== this.props.lang ||
      nextProps.rowDeletion !== this.props.rowDeletion ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.complexCode !== this.props.complexCode ||
      nextProps.cellsQuestionCodes !== this.props.cellsQuestionCodes ||
      nextProps.cellsQuestions !== this.props.cellsQuestions ||
      nextProps.cellsDefaultValue !== this.props.cellsDefaultValue ||
      nextProps.cancelValidation !== this.props.cancelValidation ||
      nextProps.cellsSavedValue !== this.props.cellsSavedValue ||
      nextProps.cellsSource !== this.props.cellsSource ||
      nextProps.cellsDependencies !== this.props.cellsDependencies ||
      (!!this.props.usesRowIndex && nextProps.rowIndex !== this.props.rowIndex)
    );
  }

  render() {
    const {
      t,
      code,
      lang,
      disabled,
      rowDeletion,
      rowIndex,
      complexCode,
      cellsQuestionCodes,
      cellsQuestions,
      checkCellHasValue,
      cellsSavedValue,
      cellsSavedVersion,
      cellsDefaultValue,
      cellsSource,
      cellsDependencies,
      cancelValidation,
      singleEntry,
      onDeleteRow
    } = this.props;
    return (
      <div
        className={`${singleEntry && disabled ? '' : 'card px2 my2'} relative pb2 ${
          disabled ? 'pt2' : 'pt3'
        }`}
      >
        {!disabled && !rowDeletion && (
          <div className="delete-btn">
            <Tooltip placement="top" title={t('passport_complexTableDelete')}>
              <div className="inline">
                <IconButton
                  disabled={disabled}
                  onClick={() => onDeleteRow(complexCode)}
                  children={<CloseIcon />}
                />
              </div>
            </Tooltip>
          </div>
        )}
        {cellsQuestionCodes.map(questionCode => {
          let question = cellsQuestions[questionCode];
          return RenderComponent({
            ...question,
            lang,
            code: `${code}_${complexCode}_${question.code}`,
            disabled: disabled,
            cancelValidation,
            checkCellHasValue,
            cellConfig: {
              code: code,
              complexCode,
              rowIndex,
              questionCode: question.code,
              isComplexCell: true,
              isInline: true
            },
            savedValue: cellsSavedValue[complexCode]
              ? cellsSavedValue[complexCode][question.code]
              : '',
            savedVersion: cellsSavedVersion[complexCode]
              ? cellsSavedVersion[complexCode][question.code]
              : undefined,
            defaultValue: cellsDefaultValue[complexCode]
              ? cellsDefaultValue[complexCode][question.code]
              : undefined,
            source: cellsSource[complexCode]
              ? cellsSource[complexCode][question.code]
              : undefined,
            dependency: getCellDependency(cellsDependencies[question.code], complexCode)
          });
        })}
      </div>
    );
  }
}

export default DComplexInlineRow;
