import React, { useState } from 'react';
import _ from 'lodash';

import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeFormValue } from '../../../passport/Passport';
import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../../modules/utils/handleError';
import PassportForm from '../../../passport/FormData/PassportForm';
import Notice from '../../../modules/utils/Notice';

const changeGuardianship = async ({ requesterIin, code, t, setLoading }) => {
  try {
    setLoading(true);
    let iin = requesterIin;
    if (code === 'request_form_doc_acceptance_ddo_short.number_guardian') {
      const childIin = PassportForm.values['request_form_doc_acceptance_ddo_short.child_iin'];
      if (!childIin) {
        return Notice.info(t('serviceRequest_fillChildIin'));
      }
      iin = childIin;
    }
    const response = await ServiceRequestApi.getGuardianship(iin);
    const passport = _.get(response, 'data.content[0].passportValues');
    if (passport) {
      const contractNumber = passport.find(q => q.code === 'ContractNumber');
      const orgBIN = passport.find(q => q.code === 'OrgBIN');
      const contractDate = passport.find(q => q.code === 'ContractDate');
      const form = passport.find(q => q.code === 'Placement');
      switch (code) {
        case 'request_form_children_property_short.guardian_doc_number':
          changeFormValue(
            `request_form_children_property_short.guardian_doc_number`,
            contractNumber ? contractNumber.value : ''
          );
          changeFormValue(
            `request_form_children_property_short.guardian_doc_organization`,
            orgBIN ? orgBIN.value.value : ''
          );
          changeFormValue(
            `request_form_children_property_short.guardian_doc_number`,
            contractDate ? contractDate.value : ''
          );
          break;
        case 'request_form_accept_doc_edu_short.DecNumb_TrustGuard':
          changeFormValue(
            `request_form_accept_doc_edu_short.DecNumb_TrustGuard`,
            contractNumber ? contractNumber.value : ''
          );
          changeFormValue(
            `request_form_accept_doc_edu_short.DecOrg_TrustGuard`,
            orgBIN ? orgBIN.value.value : ''
          );
          changeFormValue(
            `request_form_accept_doc_edu_short.DecDate_TrustGuard`,
            contractDate ? contractDate.value : ''
          );
          changeFormValue(
            `request_form_accept_doc_edu_short.form_TrustGuard`,
            form ? form.value.value : ''
          );
          break;
        case 'request_form_doc_acceptance_ddo_short.number_guardian':
          changeFormValue(
            `request_form_doc_acceptance_ddo_short.number_guardian`,
            contractNumber ? contractNumber.value : ''
          );
          changeFormValue(
            `request_form_doc_acceptance_ddo_short.name_org_guardian`,
            orgBIN ? orgBIN.value.value : ''
          );
          changeFormValue(
            `request_form_doc_acceptance_ddo_short.date_gaurdian`,
            contractDate ? contractDate.value : ''
          );
          break;
        default:
          break;
      }
    } else {
      switch (code) {
        case 'request_form_accept_doc_edu_short.DecNumb_TrustGuard':
          Notice.info('Приложите подтверждающий документ');
          break;
        default:
          handleError(response, t('dataNotFound'));
      }
    }
  } catch (error) {
    switch (code) {
      case 'request_form_accept_doc_edu_short.DecNumb_TrustGuard':
        Notice.info('Приложите подтверждающий документ');
        break;
      default:
        handleError(error, t('dataNotFound'));
    }
  } finally {
    setLoading(false);
  }
};

function SimpleGuardianship({ code, requesterIin }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeGuardianship({
          t,
          code,
          setLoading,
          requesterIin
        })}
      />
    </div>
  );
}

export default SimpleGuardianship;