import { PassportApi } from '../../PassportService';
import { getQuestions } from '../getQuestions';
import lodash from 'lodash';
import { setQuestionData } from './setQuestionData';

export async function setDocumentData(question, valueObj, complexValues = {}) {
  question.cellsSource = {};
  question.cellsSavedValue = {};
  question.cellsSavedVersion = {};

  if (valueObj.values && valueObj.values[0]) {
    question.source = valueObj.values[0].source || {};
    question.savedValue = { ...valueObj.values[0].value };
  }

  // получаем questions для ячеек
  if (question.config.metadataKey) {
    let { data } = await PassportApi.loadMeta(question.config.metadataKey);
    let passport = { id: question.passportId };
    let key = 'group.subGroups[0].sections[0].questionCodes';

    data.id = question.metaDataId;
    question.cellsQuestions = await getQuestions(data, passport, question);
    question.cellsQuestionCodes = lodash.get(data, key, []);

    if (Object.keys(question.cellsQuestions).length > 0) {
      question.hasQuestions = true;
      let qValues = {};
      let qVersions = {};
      let qSources = {};
      let questionData = {};
      if (question.savedValue && question.savedValue.complexValueId) {
        const complexCode = question.savedValue.complexValueId;
        const key = 'questionnaire.questionCodeToAnswers';
        questionData = lodash.get(complexValues[complexCode], key, {});

        for (const cQuestion of Object.values(question.cellsQuestions)) {
          let dq = { ...cQuestion };
          await setQuestionData(dq, questionData[dq.code]);
          qValues[dq.code] = dq.savedValue;
          qVersions[dq.code] = dq.savedVersion;
          qSources[dq.code] = dq.source;
        }
        question.cellsSource[complexCode] = qSources;
        question.cellsSavedValue[complexCode] = qValues;
        question.cellsSavedVersion[complexCode] = qVersions;
      }
    }
  }
}
