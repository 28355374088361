import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    columnsFilters_tableFilter_minSymbol: 'минимум 3 символа'
  },
  kk: {
    columnsFilters_tableFilter_minSymbol: 'кемінде 3 таңба'
  }
});
