import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Colors from '../../ThemeProvider/Colors';

export const StyledToolbar = styled(Toolbar)`
  justify-content: flex-end;
  width: 100%;
  & ul {
    display: inline-flex;
    padding: 0;
    align-items: center;
  }

  & ul li {
    list-style: none;
    font-size: 14px;
    margin-left: 40px;
    color: #0D4780;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
  }
  
  & ul > a {
    margin-left: 20px;
    padding: 0 20px;
  }

  & ul li .icon-span {
    vertical-align: top;
    margin-right: 5px;
    display: flex;
    flex-wrap: nowrap;
  }

  & ul li .icon-span .icon {
    font-size: 16px;
    color: ${Colors.secondary};
  }

  & ul li:hover .icon {
    cursor: pointer;
    color: ${Colors.primary};
  }
  
  @media screen and (max-width: 1050px) {
    & ul li {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    & ul {
      margin-top: 10px;
      width: 100%;
      justify-content: center;
    }
  }

  @media screen and (max-width: 500px) {
    & ul:nth-child(1n) {
      margin-top: 20px;
    }
    & ul:nth-child(2n) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      & li {
        padding: 0 10px !important;
        margin-bottom: 0px;
      }
    }
  }
  
  @media screen and (max-width: 400px) {
    
    & ul:nth-child(2n) {
      & li {
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    & ul {
      margin-right: 0;
      width: 100%;
      justify-content: space-between;
      li {
        margin-left: 0;
      }
    }
    
    & ul > a {
      margin: 0;
      padding: 5px;
    }
  }
`;

export const HeaderTitle = styled.ul`
  flex-grow: 1;
  padding-right: 50px !important;
  cursor: pointer;
  @media screen and (max-width: 900px) {
    padding-right: 0px !important;
  }
  
  img {
    height: auto;
  }
  
  li {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px !important;
    font-style: normal;
    text-transform: uppercase;
    width: 325px !important;
    color: #0D4780 !important;
    margin-left: 20px !important;
  }
  li:hover {
    color: ${Colors.primary} !important;
  }
  
  @media screen and (max-width: 600px) {
    padding: 0 !important;
    display: block !important;
    text-align: center;
    margin-top: 10px;
    li {
      font-size: 14px !important;
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top: 10px;
    }
  }
  
`;

export const StyledSelectButton = styled.li`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  list-style: none;
  color: #0D4780 !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  
  .icon {
    font-size: 19px !important;
    color: #0D4780 !important;
  }
  
  .icon.left {
    margin-right: 5px;
  }
  
  &:hover,  &:hover .icon {
    cursor: pointer;
    color: ${Colors.primary};
  }

  & img {
    margin-left: 5px;
  }
`;

export const StyledHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 26px;
  & ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin-top: 0px;
    justify-content: center;
    & ul {
      justify-content: center;
    }
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    margin-top: 5px;
    & ul {
      justify-content: center;
    }
    & li {
      margin-left: 10px !important;
    }
  }
`