import { createReducer } from 'redux-starter-kit';
import { KindergartenNotificationsApi } from './KindergartenNotificationsApi';
import Notice from '../../modules/utils/Notice';
import moment from 'moment';
import { handleError } from '../../modules/utils/handleError';
import i18next from 'i18next';

/**
 * Constants
 */

export const kindergartenNotificationsModule = 'kindergartenNotifications';
const SET_DATA = `${kindergartenNotificationsModule}/SET_DATA`;
const SET_NOTIFICATIONS = `${kindergartenNotificationsModule}/SET_NOTIFICATIONS`;
const LOADING = `${kindergartenNotificationsModule}/LOADING`;
const CLEAR_STATE = `${kindergartenNotificationsModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data: [],
  pageNumber: 1,
  totalElements: 0,
  notificationsCount: null,
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.data;
    state.pageNumber = action.pageNumber;
    state.totalElements =
      action.pageNumber === 1 ? action.totalElements : state.totalElements;
  },
  [SET_NOTIFICATIONS]: (state, action) => {
    state.notificationsCount = action.count;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [CLEAR_STATE]: (state) => {
    return {...initialState, notificationsCount: state.notificationsCount};
  }
});

export const clearState = () => ({ type: CLEAR_STATE });

export const loadApplications = filter => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenNotificationsApi.getApplications(
      JSON.stringify(filter)
    );
    if (data.status === 'SUCCESS') {
      const content = [];
      const ids = [];
      for (let request of data.result.content) {
        ids.push(request.id);
        content.push({
          ...request,
          createdOn:
            request.createdOn && moment(request.createdOn).format('DD.MM.YYYY HH:mm'),
          sendDate:
            request.sendDate && moment(request.sendDate).format('DD.MM.YYYY HH:mm')
        });
      }
      dispatch({
        type: SET_DATA,
        data: content,
        pageNumber: data.result.pageNumber,
        totalElements: data.result.totalElements
      });
      const response  = await KindergartenNotificationsApi.markAsRead({ids: JSON.stringify(ids)});
      if (response.data.status !== 'SUCCESS') {
        Notice.error(i18next.t('kindergarten_notifications_mark_as_read_error'));
      }
      dispatch(countNotifications());
    } else {
      Notice.error('kindergarten_notifications_error_load');
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_notifications_error_load'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const countNotifications = () => async dispatch => {
  try {
    const { data } = await KindergartenNotificationsApi.getNotificationsCount();
    if (data.status === 'SUCCESS') {
      dispatch({ type: SET_NOTIFICATIONS, count: data.result });
    } else {
      Notice.error(i18next.t('kindergarten_notifications_count_error_load'));
    }
  } catch (error) {
    // handleError(error, i18next.t('kindergarten_notifications_count_error_load'));
  }
};
