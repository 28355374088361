import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardsContainer, StyledSubtitle } from './GovServicesStyle';
import { gov_services } from '../../_helpers/Constants';
import './GovServicesTranslate';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import paths from '../../_helpers/paths';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';

function GovServices() {
  const { t } = useTranslation();
  const breadCrumbItems = [];
  breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
  breadCrumbItems.push({ name: t('gov_subtitle') });

  return (
    <div className="mb3 mt3">
      <BreadCrumbs items={breadCrumbItems} />
      <StyledSubtitle
        children={t('gov_subtitle')}
      />
      <StyledCardsContainer>
        {Object.values(gov_services).map(metadata => (
          <ServiceCard
            key={metadata.code}
            metadata={metadata}
          />
        ))}
      </StyledCardsContainer>
    </div>
  );
}

export default GovServices;
