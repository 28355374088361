import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 290px;
  border-right: 1px solid #e0e0e0;
  margin-right: 30px;
  position: relative;

  .queue_filter_inputs{
    padding: 20px 15px 0px 0px;
    font-size: 14px;
    text-transform: uppercase;
    width: 290px;
  }
  .centerContainer{
    text-align: center;
    margin: auto;
    top: 10%;
  }
  .mgt5{
    margin-top: 5px;
  }
  .mgt15{
    margin-top: 15px;
  }
  
  @media screen and (max-width: 850px) {
    width: 100%;
    padding-top: 20px;
    border-right: 0;
    margin-right: 0;
    
    .MuiPaper-root {
      display: none;
    }
  }
`;
