import styled from 'styled-components';

export const FooterSection = styled.div`
  width: 100%;
  background-color: #1F4E7C;
  padding: 40px 0;
  z-index: 1;
  overflow: hidden;
`;

export const FooterCardText = styled.div `
  margin-top: 20px;
  line-height: 140%;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    & p {
      font-size: 12px !important;
    }
  }
`

export const FooterTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & img {
    height: auto;
  }
  & div {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 25px;
    color: white;
    width: 300px;
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    display: block;
    & div {
      width: 100%;
      max-width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 15px;
      font-size: 14px;
    }
  }
`

export const FooterCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  padding: 0 72px;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
`;

export const FooterCard = styled.div`
 width: 100%;
`

export const FooterWrapperText = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  & a {
    margin-left: 30px;
    display: block;
  }
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    width: 100%;
    max-width: 100%;
    & div {
      width: 50%;
      max-width: 50%;
      a {
        margin-left: 0px;
        text-align: center;
      }
    }
  }
`

export const FooterWrapper = styled.div`
 width: 100%;
 dispaly: flex;
 justify-content: space-between;
`

export const FooterCallCenter = styled.div`
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 5px;
  & p {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 12px;
  }
  & span {
    font-size: 12px !important;
  }
`
export const FooterCallCenterFree = styled.p`
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  padding: 0 !important;
  margin: 0 !important;
`

export const FooterAnticorBlock = styled.div`
  display: flex; 
  width: 400px;
`

export const FooterAnticorImg = styled.div`
  width: 190px;
  @media screen and (max-width: 700px) {
    width: 170px;
  }
  @media screen and (max-width: 500px) {
    width: 130px;
  }
`