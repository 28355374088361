import lodash from 'lodash';
import { PassportApi } from '../../PassportService';
import { getQuestions } from '../getQuestions';
import { setQuestionData } from './setQuestionData';
import { flcHasValue } from '../../Utils/flcHasValue';

export async function setComplexData(question, valueObj, complexValues) {
  if (question.config.metadataKey) {
    if (!question.initialized) {
      // получаем questions для ячеек
      let { data } = await PassportApi.loadMeta(question.config.metadataKey);
      let passport = { id: question.passportId };
      let key = 'group.subGroups[0].sections[0].questionCodes';

      data.id = question.metaDataId;
      question.cellsQuestions = await getQuestions(data, passport, question);
      question.cellsQuestionCodes = lodash.get(data, key, []);
    }
    question.cellHasValue = false;

    // записываем даннные таблицы
    if (valueObj.values && valueObj.values.length > 0) {
      let complexCodes = valueObj.values.map(item => item.value);
      let tableCellsSource = {};
      let tableSavedValues = {};
      let tableSavedVersions = {};
      let tableSavedFlc = {};

      // цикл по строкам
      for (const complexCode of complexCodes) {
        let rowSource = {};
        let rowSavedValues = {};
        let rowSavedVersions = {};
        let rowFlc = {};
        let cellValues = lodash.get(
          complexValues[complexCode],
          'questionnaire.questionCodeToAnswers',
          {}
        );

        // цикл по ячейкам одной строки
        for (let cQuestion of Object.values(question.cellsQuestions)) {
          let cell = { ...cQuestion };
          await setQuestionData(cell, cellValues[cell.code]);
          rowSavedValues[cell.code] = cell.savedValue;
          rowSavedVersions[cell.code] = cell.savedVersion;
          rowSource[cell.code] = cell.source;
          rowFlc[cell.code] = cell.savedFlc;
          if (!question.cellHasValue && flcHasValue(cell.savedValue)) {
            question.cellHasValue = true;
          }
        }
        tableCellsSource[complexCode] = rowSource;
        tableSavedValues[complexCode] = rowSavedValues;
        tableSavedVersions[complexCode] = rowSavedVersions;
        tableSavedFlc[complexCode] = rowFlc;
      }

      question.cellsSavedFlc = tableSavedFlc;
      question.cellsSavedValue = tableSavedValues;
      question.cellsSavedVersion = tableSavedVersions;
      question.cellsSource = tableCellsSource;
      question.savedValue = complexCodes;
    } else {
      question.cellsSavedFlc = {};
      question.cellsSavedValue = {};
      question.cellsSavedVersion = {};
      question.cellsSource = {};
      question.savedValue = [];
    }
  }
}
