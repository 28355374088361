import React, { useEffect, useState } from 'react';
import { DomainData } from '../../Utils/DomainData';
import { TableCell } from '@material-ui/core';
import { RENDERER } from '../../Utils/Constants';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import Row from '../Row';

function Skeleton(props) {
  const { withoutLabel, cellConfig } = props;
  if (withoutLabel || (cellConfig  && !cellConfig.isDocumentCell && !cellConfig.isInline)) {
    return (
      <TableCell>
        <MuiSkeleton variant="rect" height={29} />
      </TableCell>
    );
  }
  return (
    <div className="my1">
      <Row {...props}>
        <MuiSkeleton variant="rect" height={33} style={{ borderRadius: '4px' }} />
      </Row>
    </div>
  );
}

function withOptions(Component) {
  return function(props) {
    const [data, setData] = useState(props.hideMode ? { options: [] } : null);

    useEffect(() => {
      if (data === null) {
        const lookupFilter = props?.config?.lookupFilter || ''
        DomainData.queueLoad(props.lookupId, dict => {
          if (dict) {
            if (dict instanceof Array) {
              setData({ options: dict });
            } else if (dict instanceof Object) {
              if (props.savedValue) {
                let option = dict[props.savedValue];
                if (option) {
                  setData({ options: [{ ...option }], hardDict: true });
                } else {
                  setData({ options: [], hardDict: true });
                }
              } else {
                setData({ options: [], hardDict: true });
              }
            }
          }
        },lookupFilter);
      }
      // eslint-disable-next-line
    },[]);

    if (data === null) {
      return <Skeleton {...props} />;
    }
    return (
      <Component
        {...props}
        options={data.options}
        hardDict={data.hardDict}
        isTreeOption={
          props.renderer === RENDERER.dropdown_tree ||
          data.options.some(item => item.parent_code && item.parent_code !== '0')
        }
      />
    );
  };
}

export default withOptions;
