import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { handleError } from '../../modules/utils/handleError';
import Dict from '../../modules/utils/Dict';
import { Accordion, AccordionDetails, AccordionSummary } from './FAQStyles';
import './FAQTranslate';

function FAQ() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    Dict.items('question-answers-pepik')
      .then(values => {
        setData(values);
      })
      .catch(error => {
        handleError(error, t('faq_loadQuestionsFailed'));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb3 mt3 fullWidth">
      <Typography
        children={t('faq_title')}
        color="secondary"
        variant="h6"
      />
      <div className="mb2"/>
      {data.map(item => (
        <Accordion
          key={item.code}
          square
          expanded={expanded === item.code}
          onChange={handleChange(item.code)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{item[`${i18n.language}_name`]}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item[`${i18n.language}_answer`]}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default FAQ;