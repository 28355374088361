import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { StyledCounters } from './CountersStyle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import './CountersTranslate';
import Paper from '@material-ui/core/Paper';
import { queueApplicationsModule } from '../../QueueApplicationsDucks';

function Filters() {
  const { t } = useTranslation();
  const totalElements = useSelector(state => state[queueApplicationsModule].totalElements);
  const totalVo = useSelector(state => state[queueApplicationsModule].totalVo);
  const totalOo = useSelector(state => state[queueApplicationsModule].totalOo);
  const totalPo = useSelector(state => state[queueApplicationsModule].totalPo);

  return (
    <StyledCounters>
    <Paper style={{height:'fit-content'}} className="search_box">
        <Grid container spacing={0} className="content">
          <Grid container item xs={12} md={3} spacing={0} className="center_obj">
            <div className="total_counter" dangerouslySetInnerHTML={{ __html: t('queue_kindergarten_counter_all') }}>
            </div>
            <div className="total_counter">
              {totalElements}
            </div>
          </Grid>
          <Grid container item xs={12} md={6} spacing={0} className="center_obj">
              <Table><TableBody>
                <TableRow>
                  <TableCell style={{width:"fit-content"}}>
                    {t('queue_kindergarten_counter_vo')}
                  </TableCell>
                  <TableCell>
                    {totalVo}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{width:"fit-content"}}>
                    {t('queue_kindergarten_counter_po')}
                  </TableCell>
                  <TableCell>
                    {totalPo}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{width:"fit-content"}}>
                    {t('queue_kindergarten_counter_oo')}
                  </TableCell>
                  <TableCell>
                    {totalOo}
                  </TableCell>
                </TableRow>
              </TableBody></Table>
          </Grid>
        </Grid>

    </Paper>
    </StyledCounters>
  );
}

export default Filters;
