import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GroupExpander from './GroupExpander';
import { Color } from './ThemeProvider/ThemeStyle';

const Wrapper = styled.div`
  min-width: 100%;
  border-radius: ${() => Color.borderRadius};
  ${props =>
    props.hideTitle && !props.error ? '' : `border: 1px solid ${props.error ? 'red' : Color.border}`}
  .expander-title {
    color: ${() => (Color.isDark ? Color.secondary : 'inherit')};
  }
`;

function Expander({ code, title, error, children, hideTitle }) {
  const [expand, setExpand] = useState(true);
  useEffect(() => {
    if (code) {
      GroupExpander.subscribe(code, setExpand);
      return () => GroupExpander.unsubscribe(code);
    }
  }, [code]);

  return (
    <Wrapper error={error} hideTitle={hideTitle}>
      {hideTitle ? (
        children
      ) : (
        <Accordion expanded={expand} onChange={() => setExpand(!expand)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="bold expander-title">{title}</div>
          </AccordionSummary>
          <AccordionDetails children={children} />
        </Accordion>
      )}
    </Wrapper>
  );
}

export default Expander;
