import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    queue_applications_title: 'Централизованная база данных подведомственных учреждений акимата Алматинской области',
    queue_applications_subtitle: 'Очередь заявлений',
    queue_applications_vo: "Внеочередные",
    queue_applications_po: "Первоочередные",
    queue_applications_oo: "Без льгот",
    queue_applications_table_requester: "Заявитель:",
    queue_applications_table_app_n: "Заявление №",
    queue_applications_table_submitted: "Подана",
    queue_applications_ducks_err: 'Ошибка запроса очереди',
    queue_applications_ducks_filter_err: 'Заполните все фильтры!',
  },
  kk: {
    queue_applications_title: 'Алматы облысы әкімдігінің ведомстволық бағыныстағы мекемелерінің орталықтандырылған деректер базасы',
    queue_applications_subtitle: 'Өтініштердің кезегі',
    queue_applications_vo: "Кезектен тыс",
    queue_applications_po: "Бірінші кезекте",
    queue_applications_oo: "Жеңілдіксіз",
    queue_applications_table_requester: "Өтініш беруші:",
    queue_applications_table_app_n: "Өтініш №",
    queue_applications_table_submitted: "Берілді",
    queue_applications_ducks_err: 'Ошибка запроса очереди',
    queue_applications_ducks_filter_err: 'Барлық сүзгілерді толтырыңыз!',
  }
});
