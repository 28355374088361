import { createReducer } from 'redux-starter-kit';
import { history } from '../../../_helpers/history';
import routerProps from '../../../_helpers/routerProps';
import paths from '../../../_helpers/paths';
import { KindergartenReestrViewApi } from './KindergartenReestrViewApi';
import Notice from '../../../modules/utils/Notice';
import lodash from 'lodash';
import Dict from '../../../modules/utils/Dict';
import appConfig from '../../../_helpers/config';

/**
 * Constants
 */

export const kindergartenReestrViewModule = 'kindergartenReestrViewModule';
const SET_DATA = `${kindergartenReestrViewModule}/SET_DATA`;
const LOADING = `${kindergartenReestrViewModule}/LOADING`;
const CLEAR_STATE = `${kindergartenReestrViewModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data:{ru:{}, kz:{}},
  kmeta: null,
  kdata: null,
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.data;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [CLEAR_STATE]: () => initialState
});

export const clearState = () => ({ type: CLEAR_STATE });

export const loadKindergartenView = (kindergarten_id, t) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const response = await KindergartenReestrViewApi.loadData(kindergarten_id);
    if(response.data){
      let data = await getKindergartenData(response.data);
      dispatch({ type: SET_DATA, data: data });
    }else{
      Notice.error(t('kindergarten_reestr_err_meta'));
    }
  } catch (error) {
    let location = window.location;
    for (const route of Object.values(routerProps)) {
      if (route.path === location.pathname && !route.withoutAuth) {
        window.addressBarUrl = `${location.pathname}${location.search}`;
        history.push(paths.homePage);
      }
    }
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
}

const getQValue = (passportData, questionCode) => {
  return lodash.get(passportData, `questionnaire.questionCodeToAnswers.${questionCode}.values[0].value`, null);
}

const getKindergartenData = async (data) => {
  try {
    let res = { ru: {}, kk: {} };
    let ownerships = await Dict.items('gu_form_ownership');
    let ownership = ownerships.find(function(item) {
      return item.code === getQValue(data, 'form_ownership');
    });
    res.ru['ownership'] = ownership?.ru_name;
    res.kk['ownership'] = ownership?.kk_name;
    res.ru['name'] = getQValue(data, 'ru_name');
    res.kk['name'] = getQValue(data, 'kk_name');
    res.ru['legal_address'] = getQValue(data, 'legal_address');
    res.kk['legal_address'] = getQValue(data, 'legal_address_kz');
    res.ru['contact'] = getQValue(data, 'contact');
    res.kk['contact'] = getQValue(data, 'contact');
    //languages
    let languages = await Dict.items('gu_language');
    let lang_array = getQValue(data, 'lang_education');
    let lang_string_kk = '';
    let lang_string_ru = '';
    if(lang_array && lang_array.length > 0){
      lang_array.map(langCode => {
        let language = languages.find(function(item) {
          return item.code === langCode;
        });
        lang_string_kk += language.kk_name + ", ";
        lang_string_ru += language.ru_name + ", ";
      })
    }
    res.ru['lang'] = lang_string_ru ? lang_string_ru.substring(0, lang_string_ru.length - 2) : '';
    res.kk['lang'] = lang_string_kk ? lang_string_kk.substring(0, lang_string_kk.length - 2) : '';
    //end languages
    //age groups
    let age_groups = await Dict.items('gu_age_group');
    let age_group_array = getQValue(data, 'age_group');
    let age_group_string_kk = '';
    let age_group_string_ru = '';
    if(age_group_array && age_group_array.length > 0){
      age_group_array.map(code => {
        let age_group = age_groups.find(function(item) {
          return item.code === code;
        });
        age_group_string_kk += age_group.kk_name + ", ";
        age_group_string_ru += age_group.ru_name + ", ";
      })
    }
    res.ru['age_group'] = age_group_string_ru ? age_group_string_ru.substring(0, age_group_string_ru.length - 2) : '';
    res.kk['age_group'] = age_group_string_kk ? age_group_string_kk.substring(0, age_group_string_kk.length - 2) : '';
    //end age groups
    res.ru['design_capacity'] = getQValue(data, 'design_capacity');
    res.kk['design_capacity'] = getQValue(data, 'design_capacity');
    let time_stays = await Dict.items('gu_time_stay');
    let time_stay = time_stays.find(function(item) {
      return item.code === getQValue(data, 'time_stay');
    });
    res.ru['time_stay'] = time_stay?.ru_name;
    res.kk['time_stay'] = time_stay?.kk_name;
    res.ru['playing_areas'] = getQValue(data, 'playing_areas') ? 'Да' : 'Нет';
    res.kk['playing_areas'] = getQValue(data, 'playing_areas') ? 'Йә' : 'Жоқ';
    let operating_modes = await Dict.items('gu_operating_mode');
    let operating_mode = operating_modes.find(function(item) {
      return item.code === getQValue(data, 'operating_mode');
    });
    res.ru['operating_mode'] = operating_mode?.ru_name;
    res.kk['operating_mode'] = operating_mode?.kk_name;
    res.ru['video_surv'] = getQValue(data, 'video_surv') ? 'Да' : 'Нет';
    res.kk['video_surv'] = getQValue(data, 'video_surv') ? 'Йә' : 'Жоқ';
    res.ru['website'] = getUrl(getQValue(data, 'website'));
    res.kk['website'] = getUrl(getQValue(data, 'website'));
    res.ru['photos'] = getPhotoUrls(data);
    res.kk['photos'] = getPhotoUrls(data);
    return res;
  } catch (e) {
    console.log(e);
  }
}

const getPhotoUrls = (data) => {
  let hashes = lodash.get(data, `questionnaire.questionCodeToAnswers.photo.values`, null);
  let photos = [];
  if(hashes && hashes.length > 0){
    hashes.map(item => {
      let link = lodash.get(data, `complexValues.${item.value}.questionnaire.questionCodeToAnswers.photo.values[0].value.downloadLink`, null);
      if(link){
        photos.push(appConfig.url.fileStore+link);
      }
    })
  }
  return photos;
}

const getUrl = (url) => {
  if(url && url.includes('http')){
    return url;
  }
  return "https://"+url;
}
