import { createReducer } from 'redux-starter-kit';
import Notice from '../../../../modules/utils/Notice';
import { QueueAppsFiltersApi } from './FiltersApi';

/**
 * Constants
 */

export const queueAppsFilterModule = 'queueAppsFilterModule';
const SET_KINDERGARTENS = `${queueAppsFilterModule}/SET_KINDERGARTENS`;
const LOADING = `${queueAppsFilterModule}/LOADING`;
/**
 * Reducer
 */

const initialState = {
  loading: false,
  kindergartens: [],
};

export default createReducer(initialState, {
  [SET_KINDERGARTENS]: (state, action) => {
    state.kindergartens = action.kindergartens;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
});

export const getKindergartens = (t, filter) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await QueueAppsFiltersApi.getKindergartens(filter);
    let kindergartens = [];
    if(data.content){
      kindergartens = getKeyValues(data.content);
      kindergartens.map(item => {
        item.code = item.id+"_"+item.bin;
        return item;
      })
      dispatch({ type: SET_KINDERGARTENS, kindergartens: kindergartens});
    }else{
      Notice.error(t('queue_applications_filters_kindergartens_err'));
    }
  }catch (e) {
    console.log(e);
    Notice.error(t('queue_applications_filters_kindergartens_err'));
  }finally {
    dispatch({ type: LOADING, loading: false });
  }
}

const getKeyValues = (data) => {
  let res = [];
  data.forEach(item => {
    let obj = {}
    item.passportValues.forEach(a => {
      obj[a.code] = a.value;
    });
    res.push(obj);
  });
  return res;
}
