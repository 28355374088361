import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '../../../../modules/Select/Select';
import KatoTreeSelect from '../../../../_ui/KatoTreeSelect/KatoTreeSelect';
import './FiltersTranslate';
import { Wrapper } from './FiltersStyle';
import { useDispatch, useSelector } from 'react-redux';
import { getDicts, kindergartenReestrFilterModule } from './FiltersDucks';
import Checkboxes from './CheckBoxes';
import i18n from 'i18next';

function Filters({loading, filter, searchByFilter}) {
  const { t } = useTranslation();
  let lang = i18n.language;
  const [region, setRegion] = useState(null);
  const [language, setLanguage] = useState(null);
  const [ownership, setOwnership] = useState(null);
  const [type, setType] = useState(null);
  const [ageGroups, setAgeGroups] = useState([]);
  const kindergartenTypesOptions = useSelector(state => state[kindergartenReestrFilterModule].kindergarten_types);
  const languages = useSelector(state => state[kindergartenReestrFilterModule].languages);
  const ownerships = useSelector(state => state[kindergartenReestrFilterModule].ownerships);
  const age_groups = useSelector(state => state[kindergartenReestrFilterModule].age_groups);
  const dispatch = useDispatch();

  const loadByFilter = () => {
    let age_groups = ''
    if(ageGroups.length) {
      let age_group = "";
      ageGroups.forEach(item => {
        if(item.checked) age_group += item.code+','
      });
      age_groups = age_group === "" ? null : age_group;
    }else{
      age_groups = null;
    }
    searchByFilter({locationCode: region, langEducation: language, formOwnership: ownership,
      subTypes: type, ageGroup: age_groups, availableSuo: ownership === 'private' ? true : null});
  };

  const clearFilter = () => {
    setRegion(null);
    setLanguage(null);
    setOwnership(null);
    setType(null);
    let new_options = JSON.parse(JSON.stringify(age_groups));
    new_options.map(item => {
      item['checked'] = false;
      return item;
    });
    setAgeGroups(new_options);
    searchByFilter({locationCode: null, langEducation: null, formOwnership: null,
      subTypes: null, ageGroup: null, availableSuo: null});
  }

  useEffect(() => {
    dispatch(getDicts(setAgeGroups, filter.ageGroup));
    setRegion(filter.locationCode);
    setLanguage(filter.langEducation);
    setOwnership(filter.formOwnership);
    setType(filter.subTypes);
  }, [])

  return (
    <Wrapper>
      <div className="queue_filter_inputs">
        <div>
          <KatoTreeSelect
            filterCode={"330000000"}
            name={"region"}
            placeholder={t('kindergarten_reestr_filters_region')}
            onChange={value => setRegion(value)}
            value={region}
            parentSelectable={false}
          />
        </div>
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('kindergarten_reestr_filters_ownership')}
            value={ownership}
            options={ownerships}
            onChange={e => setOwnership(e.target.value)}
            style={{height: '48px'}}
          />
        </div>
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('kindergarten_reestr_filters_type')}
            value={type}
            options={kindergartenTypesOptions}
            onChange={e => setType(e.target.value)}
            style={{height: '48px'}}
          />
        </div>
        <div className="mgt5">
          <Select
            withoutForm
            placeholder={t('kindergarten_reestr_filters_lang')}
            value={language}
            options={languages}
            onChange={e => setLanguage(e.target.value)}
            style={{height: '48px'}}
          />
        </div>
        <div className="mgt5">
          <Checkboxes label={t('kindergarten_reestr_filters_age_group')} options={ageGroups} setOptions={setAgeGroups} lang={lang}/>
        </div>
        <div className="mgt15 centerContainer">
          {loading ?
            <CircularProgress />
            :
            <>
              <div>
                <Button variant='contained' color='secondary' onClick={() => loadByFilter()}>{t('kindergarten_reestr_filters_but')}</Button>
              </div>
              <div className="mgt15">
                <Button variant='outlined' color='secondary' size="small" onClick={() => clearFilter()}>{t('kindergarten_reestr_filters_clear')}</Button>
              </div>
            </>
          }
        </div>
      </div>
    </Wrapper>
  );
}

export default Filters;
