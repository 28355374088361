import { addTranslation } from '../_helpers/Translate';

addTranslation({
  ru: {
    requiredField: 'Обязательно для заполнения',
    notCyrillic: 'Только латинские буквы и цифры',
    wrongIin: 'Неверный формат ИИН',
    wrongEmail: 'Неверный формат электронной почты',
    passNotEqual: 'Пароли не совпадают',
    dateFormatError: 'Неверный формат даты',
    yes: 'Да',
    no: 'Нет',
    send: 'Отправить',
    cancel: 'Отмена',
    save: 'Сохранить',
    edit: 'Редактировать',
    sign: 'Подписать',
    dataNotFound: 'Данные не найдены',
    must_sing_own_signature: 'ЭЦП для подписи не соответствует ИИН/БИН данного услугополучателя'
  },
  kk: {
    requiredField: 'Обязательно для заполнения',
    notCyrillic: 'Только латинские буквы и цифры',
    wrongIin: 'Неверный формат ИИН',
    wrongEmail: 'Неверный формат электронной почты',
    passNotEqual: 'Пароли не совпадают',
    dateFormatError: 'Неверный формат даты',
    yes: 'Да',
    no: 'Нет',
    send: 'Отправить',
    cancel: 'Болдырмау',
    save: 'Сақтау',
    edit: 'Редакциялау',
    sign: 'Қол қою',
    dataNotFound: 'Данные не найдены',
    must_sing_own_signature: 'ЭЦП для подписи не соответствует ИИН/БИН данного услугополучателя'
  }
});