import styled from 'styled-components';

export const Div = styled.div`
  .rc-tree-select {
    font-size: 14px;
    width: 100%;
    max-width: 1000px;
    outline: none;
    cursor: pointer;
  }
  .rc-tree-select-selection {
    border-radius: 10px;
    min-height: ${props => (props.size === 'small' ? '30px' : '38px')};
    border-color: ${({ disabled, error }) => {
      if (error) return '#e0b4b4';
      else return 'rgba(34, 36, 38, 0.30)';
    }};
    background-color: ${({ disabled, error }) => {
      if (error) return '#FFF6F6';
      else if (disabled) return '#F5F5F5';
      else return 'white';
    }};
  }
  .rc-tree-select-selection-selected-value {
    line-height: 48px;
    ${props => props.changedMode ? 'color: #43A047 !important; font-weight: bold;' : ''}
  }
  .rc-tree-select-enabled.rc-tree-select-focused .rc-tree-select-selection {
    background-color: white;
    border-color: #2185d0 !important;
    box-shadow: none !important;
    svg {
      fill: hsl(0, 0%, 40%);
    }
  }
  .rc-tree-select-enabled .rc-tree-select-selection:hover {
    border-color: rgba(34, 36, 38, 0.3);
    box-shadow: none;
  }
  .rc-tree-select-selection-selected-value {
    color: ${props => (props.disabled ? 'rgba(0,0,0,.75)' : '#222')};
  }
  .rc-tree-select-selection--single{
    height: 48px;
  }
  .rc-tree-select-selection--single .rc-tree-select-selection__clear {
    font-size: 22px;
    color: hsl(0, 0%, 80%);
    right: 48px;
    top: 0;
    font-weight: 400;
    line-height: 33px;
  }
  .rc-tree-select-arrow {
    border-left: 1px solid hsl(0, 0%, 80%);
    width: 68px;
    height: 100%;
    top: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    background-color: #e0dcff;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    svg {
      fill: rgba(0, 0, 0, 0.54);
    }
    svg:hover {
      fill: rgba(0, 0, 0, 0.54);
    }
  }
  .rc-tree-select-open .rc-tree-select-selection .rc-tree-select-arrow svg{
    transform: rotate(180deg);
  }
  .rc-tree-select-arrow:after {
    display: none;
  }
  .rc-tree-select-selection__rendered {
    line-height: 48px !important;
    color: #b1b1b1 !important;
    padding-left: 15px !important;
  }

  .rc-tree-select-disabled,
  .rc-tree-select-disabled .rc-tree-select-selection--single:hover,
  .rc-tree-select-disabled .rc-tree-select-selection__choice__remove:hover {
    cursor: default !important;
  }
  .rc-tree-select-disabled .rc-tree-select-selection--single,
  .rc-tree-select-disabled .rc-tree-select-selection__choice__remove {
    cursor: default !important;
  }
  .rc-tree-select-selection__placeholder {
    color: ${props => (props.error ? '#e0b4b4' : '#d2d2d2')};
  }
  .rc-tree-select-search__field {
    display: none;
  }
  .rc-tree-select-selection--multiple {
    ${props => (props.borderColor ? `border-color: ${props.borderColor};` : '')};
    > ul > li {
      margin-top: ${props => (props.size === 'small' ? '2px' : '4px')};
    }
  }
`;
