import styled from 'styled-components';

export const StyledServiceCard = styled.div`
  position: relative;
  margin-bottom: 30px !important;
  background-color: #fafafa;
  border: 1px solid #cccccc;
  width: 45% !important;
  padding: 30px 0 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .text {
    font-size: 16px;
    padding: 0 50px 25px;
    font-weight: bold;
    text-align: center;
    @media screen and (max-width: 600px) {
      padding: 0 20px 20px;
    }
  }
  
  .button-container {
    text-align: center;
  }
  
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
`;