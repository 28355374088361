import React from 'react';
import { IconButton, TableCell, Tooltip } from '@material-ui/core';
import RenderComponent from '../../Generate/RenderComponent';
import { getCellDependency } from './getCellDependency';
import DeleteIcon from '@material-ui/icons/Delete';

class DComplexTableRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.code !== this.props.code ||
      nextProps.lang !== this.props.lang ||
      nextProps.rowDeletion !== this.props.rowDeletion ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.hiddenByPagination !== this.props.hiddenByPagination ||
      nextProps.complexCode !== this.props.complexCode ||
      nextProps.cellsQuestionCodes !== this.props.cellsQuestionCodes ||
      nextProps.cellsQuestions !== this.props.cellsQuestions ||
      nextProps.cellsDefaultValue !== this.props.cellsDefaultValue ||
      nextProps.cancelValidation !== this.props.cancelValidation ||
      nextProps.cellsSavedValue !== this.props.cellsSavedValue ||
      nextProps.cellsSource !== this.props.cellsSource ||
      nextProps.cellsDependencies !== this.props.cellsDependencies ||
      (!!this.props.usesRowIndex && nextProps.rowIndex !== this.props.rowIndex)
    );
  }

  render() {
    const {
      t,
      code,
      lang,
      disabled,
      rowIndex,
      hideMode,
      hiddenByPagination,
      setPageError,
      rowDeletion,
      complexCode,
      cellsQuestionCodes,
      cellsQuestions,
      checkCellHasValue,
      cellsSavedValue,
      cellsSavedVersion,
      cellsDefaultValue,
      cellsSource,
      cancelValidation,
      cellsDependencies,
      onDeleteRow
    } = this.props;

    return (
      <>
        {cellsQuestionCodes.map(questionCode => {
          let question = cellsQuestions[questionCode];
          return RenderComponent({
            ...question,
            lang,
            withoutLabel: true,
            code: `${code}_${complexCode}_${question.code}`,
            disabled: disabled,
            hideMode,
            cancelValidation,
            setPageError,
            checkCellHasValue,
            cellConfig: {
              code: code,
              complexCode,
              rowIndex,
              questionCode: question.code,
              hiddenByPagination,
              isComplexCell: true
            },
            savedValue: cellsSavedValue[complexCode]
              ? cellsSavedValue[complexCode][question.code]
              : '',
            savedVersion: cellsSavedVersion[complexCode]
              ? cellsSavedVersion[complexCode][question.code]
              : undefined,
            defaultValue: cellsDefaultValue[complexCode]
              ? cellsDefaultValue[complexCode][question.code]
              : undefined,
            source: cellsSource[complexCode]
              ? cellsSource[complexCode][question.code]
              : undefined,
            dependency: getCellDependency(cellsDependencies[question.code], complexCode)
          });
        })}
        {!disabled && !hideMode && !rowDeletion && (
          <TableCell className={`action-delete ${disabled && 'cell-disabled'}`}>
            <Tooltip placement="top" title={t('passport_complexTableDelete')}>
              <div>
                <IconButton
                  disabled={disabled}
                  style={{ padding: 0 }}
                  onClick={() => onDeleteRow(complexCode)}
                  children={<DeleteIcon />}
                />
              </div>
            </Tooltip>
          </TableCell>
        )}
      </>
    );
  }
}

export default DComplexTableRow;
