export const defaultConfig = {
  codeKey: 'code', // codeKey - свойство указывающее на код
  parentKey: 'parent_code', // parentKey - свойство указывающее на родителя
  childKey: 'childItems', // childKey - свойство указывающее на дочек
  addWithoutParent: true // addWithoutParent - если не нашел родителя true -> добавить на первый уровень, false -> не добавляет
};

/**
 * Перебор массива в дерево
 * @param data - данные
 * @param config - настройки добавления
 * @return array
 */
export function arrayToTree(data, config) {
  let tree = [];
  try {
    if (data instanceof Array && data.length > 0) {
      let cf = defaultConfig;
      if (config && config instanceof Object) {
        cf = { ...defaultConfig, ...config };
      }

      let residue = data.filter(item => {
        if (!item.hasOwnProperty(cf.parentKey)) {
          tree.push({ ...item });
          return false;
        } else {
          let added = addChild(tree, { ...item }, cf);
          return !added;
        }
      });

      if (cf.addWithoutParent && residue.length > 0) {
        for (let item of residue) {
          let added = addChild(tree, { ...item }, cf);
          if (!added) {
            tree.push({ ...item });
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return tree;
}

/**
 * Добавление дочки в дерево
 * @param data - данные
 * @param child - добавляемый элемент
 * @param config - настройки добавления
 * @return boolean
 */
export function addChild(data, child, config) {
  let added = false;
  try {
    if (data && data instanceof Array) {
      let cf = defaultConfig;
      if (config && config instanceof Object) {
        cf = { ...defaultConfig, ...config };
      }

      for (let item of data) {
        if (item[cf.codeKey] === child[cf.parentKey]) {
          if (item.hasOwnProperty(cf.childKey)) {
            item[cf.childKey].push(child);
          } else {
            item[cf.childKey] = [child];
          }
          added = true;
        } else if (item[cf.childKey] && item[cf.childKey].length > 0) {
          added = addChild(item[cf.childKey], child, cf);
        }
        if (added) break;
      }
    }
  } catch (error) {
    console.error(error);
  }
  return added;
}

/**
 * Добавление дочек в дерево
 * @param data - данные
 * @param parentCode - код родителя
 * @param children - добавляемые элементы
 * @param config - настройки добавления
 * @return boolean
 */
export function addChildren(data, parentCode, children, config) {
  let added = false;
  try {
    if (data && data instanceof Array) {
      let cf = defaultConfig;
      if (config && config instanceof Object) {
        cf = { ...defaultConfig, ...config };
      }

      for (let item of data) {
        if (item[cf.codeKey] === parentCode) {
          if (item.hasOwnProperty(cf.childKey)) {
            item[cf.childKey] = [...item[cf.childKey], ...children];
          } else {
            item[cf.childKey] = children;
          }
          added = true;
        } else if (item[cf.childKey] && item[cf.childKey].length > 0) {
          added = addChildren(item[cf.childKey], parentCode, children, cf);
        }
        if (added) break;
      }
    }
  } catch (error) {
    console.error(error);
  }
  return added;
}

/**
 * Маппинг по дереву
 * @param data
 * @param callback
 * @param config
 * @return Array массив, разложенное дерево в один уровень
 */
export function treeToListMap(data = [], callback, config) {
  let result = [];
  let cf = defaultConfig;
  if (config && config instanceof Object) {
    cf = { ...defaultConfig, ...config };
  }

  try {
    const caller = (tree, level) => {
      for (const item of tree) {
        let callbackResult = callback(item, level);
        result.push(callbackResult);

        if (item.hasOwnProperty(cf.childKey) && item.expanded) {
          caller(item[cf.childKey], level + 1);
        }
      }
    };

    caller([...data], 1);
  } catch (error) {
    console.error(error);
  }
  return result;
}

/**
 * Маппинг по дереву
 * @param data
 * @param callback
 * @param config
 * @return Array, дерево
 */
export function treeMap(data = [], callback, config) {
  let result = [];
  let cf = defaultConfig;
  if (config && config instanceof Object) {
    cf = { ...defaultConfig, ...config };
  }

  try {
    const caller = (tree, level) => {
      let cResult = [];
      for (const item of tree) {
        let callbackResult = callback(item, level);
        if (callbackResult !== undefined) {
          if (item.hasOwnProperty(cf.childKey) && item.expanded) {
            callbackResult[cf.childKey] = caller(item[cf.childKey], level + 1);
          }
          cResult.push(callbackResult);
        }
      }
      return cResult;
    };

    result = caller([...data], 1);
  } catch (error) {
    console.error(error);
  }
  return result;
}

/**
 * Поиск по дереву
 * @param data
 * @param callback
 * @param config
 * @return Object, элемент дерево
 */
export function treeFind(data = [], callback, config) {
  let cf = defaultConfig;
  if (config && config instanceof Object) {
    cf = { ...defaultConfig, ...config };
  }

  try {
    const caller = (tree, level) => {
      for (const item of tree) {
        let founded = callback(item, level);
        if (founded) {
          return item
        } else if (item.hasOwnProperty(cf.childKey)) {
          founded = caller(item[cf.childKey], level + 1);
          if (founded) {
            return founded
          }
        }
      }
    };

    return caller([...data], 1);
  } catch (error) {
    console.error(error);
  }
}


export default {
  defaultConfig,
  arrayToTree,
  addChild,
  addChildren,
  treeToListMap,
  treeMap,
  treeFind,
}
