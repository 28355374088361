import axios from 'axios';

export const HeaderApi = {
  getTokenAgree: () => axios.get(
    '/api/public/processing_agreement/agree'
  ),
  getTokenDisagree: () => axios.get(
    '/api/public/processing_agreement/disagree'
  ),
  setSign: formData => axios.post(
    `/api/public/processing_agreement/agree_or_disagree`, formData
  ),
};
