import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { changeFormValue } from '../../../passport/Passport';
import { ServiceRequestApi } from '../ServiceRequestApi';
import Notice from '../../../modules/utils/Notice';
import { chengDDOShortDisabled } from './../ServiceRequestDucks';
import { DIRECTION_STATUS, CONTINGENT_STATUS } from './../../../_helpers/Constants'

const requestСhildInformation = async ({ setLoading, code, value, t, dispatch }) => {
 try {
    setLoading(true);
    const metadataKey = code.split('.')[0];
    let check = await ServiceRequestApi.getContingentCheck(value);
      if (check.data.result.length === 0) {
        dispatch(chengDDOShortDisabled(false))
        const data = await ServiceRequestApi.getPersonFlBy(value);
        if (data.status === 200) {
          const passport = _.get(data, 'data.content[0].passportValues');
          const name = passport.find(q => q.code === 'name');
          const surname = passport.find(q => q.code === 'surname');
          const middlename = passport.find(q => q.code === 'middlename');
          const dateOfBirth = passport.find(q => q.code === 'date_of_birth');
          const gender = passport.find(q => q.code === 'gender');
          let currentGender = '';
          if (gender.value === 'Мужской' || gender.value === 'мужской') {
            currentGender = '2'
          } else if (gender.value === 'Женский' || gender.value === 'женский') {
            currentGender = '1'
          } else {
            currentGender = ''
          }
          switch (code) {
            case 'request_form_doc_queued_ddo_short.child_iin':
              changeFormValue(`${metadataKey}.child_surname`, surname.value);
              changeFormValue(`${metadataKey}.child_name`, name.value);
              changeFormValue(`${metadataKey}.child_middlename`, middlename.value);
              changeFormValue(`${metadataKey}.child_birthday`, dateOfBirth.value);
              changeFormValue(`${metadataKey}.child_gender`, currentGender);
              break
            default:
              break;
          }
        }
      } else {
        if (check.data.result[0].statusDirection === DIRECTION_STATUS.ARRIVED_TO_PO) {
          Notice.error(t('childInformationButton_arrivedDO', {
            fio: check.data.result[0].childName + ' ' + check.data.result[0].childSurname,
            child_iin: check.data.result[0].childIin
          }));
        } else if (check.data.result[0].statusDirection === DIRECTION_STATUS.ENROLLED_TO_PO || 
                    check.data.result[0].contingentStatus === CONTINGENT_STATUS.ENROLLED
            ) {
          Notice.error(t('childInformationButton_enrolledDO', {
            fio: check.data.result[0].childName + ' ' + check.data.result[0].childSurname,
            child_iin: check.data.result[0].childIin
          }));
        }
        dispatch(chengDDOShortDisabled(true));
      }
  } catch (error) {
    Notice.error(t('childInformationButton_iinNotFound'));
  } finally {
    setLoading(false);
  }
};

function СhildInformationButton({ code, value }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value.length === 12) {
      requestСhildInformation({ setLoading, code, value, t, lang: i18n.language, dispatch })
    }
  }, [value])

  return (
    <div>
      <StyledGBDFLButton
        color="secondary"
        loading={loading}
        text={t('gbdflButton_search')}
        disabled={value.length !== 12}
        onClick={() => requestСhildInformation({ setLoading, code, value, t, lang: i18n.language, dispatch })}
      />
    </div>  
  );
}

export default СhildInformationButton;
