import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import DInput from '../DInput';
import { RENDERER } from '../../../Utils/Constants';
import { handleQuestionLabel } from '../../../Utils/handleQuestionLabel';
import { isMatrixVisible } from './DTableUtils';

class DTableRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.code !== this.props.code ||
      nextProps.lang !== this.props.lang ||
      nextProps.hideCol !== this.props.hideCol ||
      nextProps.colIndex !== this.props.colIndex ||
      nextProps.columns !== this.props.columns ||
      nextProps.rows !== this.props.rows ||
      nextProps.columnSet !== this.props.columnSet ||
      nextProps.matrixVisibility !== this.props.matrixVisibility ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.cellsSavedValue !== this.props.cellsSavedValue ||
      nextProps.cellsDefaultValue !== this.props.cellsDefaultValue ||
      nextProps.cellsDependencies !== this.props.cellsDependencies ||
      nextProps.cellsRules !== this.props.cellsRules
    );
  }

  render() {
    const {
      code,
      lang,
      hideCol,
      colIndex,
      columns,
      rows,
      columnSet,
      disabled,
      cellsSavedValue,
      cellsDefaultValue,
      cellsDependencies,
      cellsRules,
      checkCellHasValue,
      matrixVisibility
    } = this.props;
    return (
      <TableRow key={`columnSet_${code}_${colIndex}`}>
        {!hideCol &&
          columns.titles[colIndex].map(title => (
            <TableCell
              className="column-title"
              rowSpan={title.rowSpan}
              key={`col_title_${title.code}_${colIndex}`}
            >
              {handleQuestionLabel({ ...title, lookupId: title.dictCode }, lang)}
            </TableCell>
          ))}
        {rows.cells.map(rowSet => {
          if (!isMatrixVisible(matrixVisibility, rowSet)) {
            return null;
          }
          const cellCode = JSON.stringify({ row: rowSet, column: columnSet });
          const cellData = cellsSavedValue[cellCode] || {};
          const cellDefaultData = cellsDefaultValue[cellCode] || {};
          const rules = cellsRules[cellCode] || {};
          return (
            <DInput
              key={`${code}_${cellCode}`}
              {...rules}
              code={`${code}_${cellCode}`}
              savedValue={cellData.value === undefined ? '' : cellData.value}
              defaultValue={
                cellDefaultData.value === undefined ? '' : cellDefaultData.value
              }
              cellConfig={{
                code: code,
                questionCode: cellCode,
                coordinates: { row: rowSet, column: columnSet },
                isTableCell: true
              }}
              checkCellHasValue={checkCellHasValue}
              dependency={cellsDependencies[cellCode]}
              renderer={RENDERER.table_cell}
              lang={lang}
              disabled={disabled}
              type="number"
              withoutLabel
            />
          );
        })}
      </TableRow>
    );
  }
}

export default DTableRow;
