import styled from 'styled-components';

export const SuccessfulAppointmentStyle = styled.div`
  .dialog_close{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
  }
`;

