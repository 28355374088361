import lodash from 'lodash';
import { getFlcRules } from '../Utils/getFlcRules';
import { setQuestionData } from './setQuestionData/setQuestionData';
import { replaceRuleMethods } from '../Utils/replaceRuleMethods';
import { RENDERER } from '../Utils/Constants';

// формирование показателей
export async function getQuestions(metaData, passport, parent) {
  let questions = {};
  let questionsData = lodash.get(passport, 'questionnaire.questionCodeToAnswers', {});
  let complexValues = passport.complexValues || {};

  for (const metaQuestion of Object.values(metaData.questions)) {
    let question = {
      code: parent ? metaQuestion.code : `${metaData.id}.${metaQuestion.code}`,
      originalCode: metaQuestion.code,
      kk_name: metaQuestion.kk_name,
      ru_name: metaQuestion.ru_name,
      kk_placeholder: metaQuestion.kk_name,
      ru_placeholder: metaQuestion.ru_name,
      renderer: metaQuestion.config.renderer.id,
      type: metaQuestion.type,
      keepIfHidden: metaQuestion.keepIfHidden,
      config: metaQuestion.config,
      lookupId: metaQuestion.config.lookupId,
      relatedBusinessObjectId: metaQuestion.relatedBusinessObjectId,
      anyTreeLevelSelectable: metaQuestion.config.anyTreeLevelSelectable,
      passportId: passport.id,
      metaDataId: metaData.id
    };

    if (
      question.renderer === RENDERER.dropdown_tree &&
      question.type === 'multiple dynamic lookup'
    ) {
      question.renderer = RENDERER.multiple_dropdown_tree;
    }

    if (parent) {
      question.parentCode = parent.code;
      question.parentRenderer = parent.renderer;
    }

    if (question.lookupId) question.lookupId = question.lookupId.trim();

    // записываем рулы если есть
    for (const rule of getFlcRules(question.renderer)) {
      const ruleBody = replaceRuleMethods(metaQuestion[rule]);
      if (ruleBody) question[rule] = ruleBody;
    }
    if (metaQuestion.cellRules && metaQuestion.cellRules.length > 0) {
      question.cellRules = metaQuestion.cellRules;
    }

    // записываем сохраненные данные
    await setQuestionData(question, questionsData[metaQuestion.code], complexValues);
    questions[question.code] = question;
  }
  return questions;
}
