import React from 'react';
import RenderComponent from './RenderComponent';

function GenerateForms(props) {
  return questionMapping(props, RenderComponent);
}

function questionMapping(props, callback) {
  const {
    questionCodes,
    questions,
    betweenRender,
    lang,
    disabled,
    hideMode,
    groupHided
  } = props;
  if (typeof betweenRender === 'function' && questionCodes.length) {
    let result = [];
    let nextCode = questionCodes[0];

    let render = betweenRender(undefined, nextCode);
    if (render) {
      result.push(<div key={`br${nextCode}`}>{render}</div>);
    }

    for (const code of questionCodes) {
      if (questions[code] && code.indexOf('v_') !== 0) {
        const component = callback({
          ...questions[code],
          disabled,
          lang,
          hideMode,
          groupHided
        });
        result.push(component);

        let index = questionCodes.indexOf(code);
        if (index >= 0 && index < questionCodes.length - 1) {
          nextCode = questionCodes[index + 1];
        } else {
          nextCode = undefined;
        }
        let render = betweenRender(code, nextCode);
        if (render) {
          result.push(<div key={`br${code}${nextCode}`}>{render}</div>);
        }
      }
    }
    return result;
  } else {
    return questionCodes.map(code => {
      if (!questions[code] || code.indexOf('v_') === 0) return null;
      return callback({
        ...questions[code],
        disabled,
        lang,
        hideMode,
        groupHided
      });
    });
  }
}

export default GenerateForms;
