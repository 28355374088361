class GroupExpander {
  static subscribers = {};

  static subscribe(groupId, callback) {
    this.subscribers[groupId] = callback;
  }

  static unsubscribe(groupId) {
    delete this.subscribers[groupId];
  }

  static onExpandAll(expand) {
    for (const callback of Object.values(GroupExpander.subscribers)) {
      callback(expand);
    }
  }
}

export default GroupExpander;
