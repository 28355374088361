export const PAGE_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  INVALID: 'INVALID'
};

export const P_STATUS = {
  SUBMITTED: 'SUBMITTED',
  IN_PROGRESS: 'IN_PROGRESS',
  READY_FOR_SIGN: 'READY_FOR_SIGN',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
};

export const DIRECTION_STATUS = {
  ARRIVED_TO_PO: 'ARRIVED_TO_PO',
  ENROLLED_TO_PO: 'ENROLLED_TO_PO'
}

export const CONTINGENT_STATUS = {
  ENROLLED: 'ENROLLED',
  EXPELLED: 'EXPELLED',
}

export const SIGN_METHOD_PKCS12 = 'PKCS12';
export const SIGN_METHOD_AKKaztokenStore = 'AKKaztokenStore';
export const SIGN_METHOD_AKKZIDCardStore = 'AKKZIDCardStore';

export const ECP_OPTIONS = [
  {
    code: SIGN_METHOD_PKCS12,
    ru_name: 'Ваш компьютер',
    kk_name: 'Сіздің компьютеріңіз'
  },
  {
    code: SIGN_METHOD_AKKZIDCardStore,
    ru_name: 'Удостоверение личности',
    kk_name: 'Жеке куәлік'
  },
  {
    code: SIGN_METHOD_AKKaztokenStore,
    ru_name: 'Казтокен',
    kk_name: 'Қазтокен'
  }
];

export const service_types = {
  kindergarten_services: {
    url: 'kindergarten-services',
    ru_name: 'Очередь в детский сад',
    kk_name: 'Балабақшаға кезек'
  },
  gov_services: {
    url: 'gov-services',
    ru_name: 'Государственные услуги',
    kk_name: 'Мемлекеттік қызметтер',
  }
}

export const kindergarten_services = {
  doc_queued_ddo_short: {
    code: 'doc_queued_ddo_short',
    ru_name: 'Постановка на очередь детей дошкольного возраста (до 6 лет) для направления в детские дошкольные организации',
    kk_name: 'Мектепке дейінгі балалар ұйымдарына жіберу үшін мектепке дейінгі (6 жасқа дейін) жастағы балаларды кезекке қою',
  },
  doc_acceptance_ddo_short: {
    code: 'doc_acceptance_ddo_short',
    ru_name: 'Прием документов и зачисление детей в дошкольные организации образования',
    kk_name: 'Мектепке дейінгі білім беру ұйымдарына құжаттарды қабылдау және балаларды тіркеу'
  },
  check_queue_number: {
    url: 'check-queue-number',
    ru_name: 'ПРОВЕРКА НОМЕРА В ОЧЕРЕДИ',
    kk_name: 'КЕЗЕКТЕГІ НӨМІРДІ ТЕКСЕРУ'
  },
  bulletin_open_place: {
    url: 'bulletin-open-places',
    ru_name: 'БЮЛЛЕТЕНЬ СВОБОДНЫХ МЕСТ',
    kk_name: 'БОС ОРЫНДАР БЮЛЛЕТЕНІ',
  },
  application_queue: {
    url: 'queue-applications',
    ru_name: 'ОЧЕРЕДЬ ЗАЯВЛЕНИЙ',
    kk_name: 'ӨТІНІШТЕР КЕЗЕГІ'
  },
  kindergarten_reestr: {
    url: 'kindergarten-reestr',
    ru_name: 'РЕЕСТР ДЕТСКИХ САДОВ',
    kk_name: 'БАЛАБАҚШАЛАР ТІЗІМІ',
  },
}

export const gov_services = {
  accept_doc_edu_short: {
    code: 'accept_doc_edu_short',
    ru_name: 'Прием документов и зачисление в организации образования (начальные, осн. ср., общ.ср, спец.)',
    kk_name: 'Білім беру ұйымдарына құжаттарды қабылдау және оқуға қабылдау (мектепке дейінгі, бастауыш, негізгі орта, жалпы орта, арнайы)'
  },
  children_property_short: {
    code: 'children_property_short',
    ru_name: 'Выдача справок для распоряжения имуществом несовершеннолетних детей',
    kk_name: 'Кәмелетке толмағандардың мүлкіне иелік ету үшін анықтамалар беру',
  },
  payment_patronage_short: {
    code: 'payment_patronage_short',
    ru_name: 'Передача ребенка (детей) на патронатное воспитание и назначение выплаты денежных средств на содержание ребенка (детей), переданного патронатным воспитателям',
    kk_name: 'Баланы (балаларды) патронаттық тәрбиелеуге беру және патронат тәрбиешiлерге берiлген баланы (балаларды) асырап-бағуға ақшалай қаражат төлеуді тағайындау',
  },
  payment_family_short: {
    code: 'payment_family_short',
    ru_name: 'Передача ребенка (детей) на воспитание в приемную семью и назначение выплаты денежных средств на их содержание',
    kk_name: 'Баланы (балаларды) қабылдаушы отбасына тәрбиелеуге беру және оларды асырауға ақшалай қаражат төлеуді тағайындау',
  },
  government_order_short: {
    forLegalPerson: true,
    code: 'government_order_short',
    ru_name: 'Прием документов на конкурс по размещению государственного образовательного заказа на подготовку кадров с техническим, профессиональным и послесредним образованием',
    kk_name: 'Техникалық, кәсіптік және орта білімнен кейінгі білімі бар кадрларды даярлауға арналған мемлекеттік білім беру тапсырысын орналастыру бойынша конкурсқа құжаттарды қабылдау'
  },
  children_adoption_short: {
    code: 'children_adoption_short',
    ru_name: 'Выдача справок по опеке и попечительству',
    kk_name: 'Қорғаншылық және қамқоршылық жөнінде анықтамалар беру'
  },
  home_for_champions_short: {
    code: 'home_for_champions_short',
    ru_name: 'Предоставление жилища чемпионам и призерам Олимпийских, Паралимпийских и Сурдлимпийских игр и пользования им',
    kk_name: 'Олимпиадалық, Паралимпиадалық және Сурдлимпиадалық ойындардың чемпиондары мен жүлдегерлеріне тұрғынжай беру және оны пайдалану',
  },
  academical_resignations_short: {
    code: 'academical_resignations_short',
    ru_name: 'Предоставление академических отпусков обучающимся в организациях образования',
    kk_name: 'Білім беру ұйымдарында білім алушыларға академиялық демалыстар беру'
  },
  status_assignment_short: {
    forLegalPerson: true,
    code: 'status_assignment_short',
    ru_name: 'Присвоение статусов «специализированная» спортивным школам и «специализированное» отделениям спортивных школ',
    kk_name: 'Спорт мектептеріне және спорт мектептерінің бөлімшелеріне "мамандандырылған" деген мәртебе беру'
  },
  accept_school_short: {
    code: 'accept_school_short',
    ru_name: 'Прием документов и зачисление в организации образования независимо от ведомственной подчиненности для обучения по общеобразовательным программам начального, основного среднего, общего среднего образования',
    kk_name: 'Бастауыш, негізгі орта, жалпы орта білім берудің жалпы білім беретін бағдарламалары бойынша оқыту үшін ведомстволық бағыныстылығына қарамастан білім беру ұйымдарына құжаттарды қабылдау және оқуға қабылдау',
  }
};

export const services =  {...gov_services, ...kindergarten_services};
