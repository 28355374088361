import React from 'react';
import moment from 'moment';
import { StyledTable } from './TableColumnStyle';
import Button from '@material-ui/core/Button';
import useUserInfo from '../../../components/_hooks/useUserInfo';

export const requestsColumns = ({ t, lang, modal }) => {
  return [
    {
      Cell: row => <Column row={row} t={t} lang={lang} modal={modal} />
    }
  ];
};

const Column = ({ row, t, lang, modal }) => {
  const user = useUserInfo();
  return (
    <StyledTable>
      <div className="row1">
        <div className="col1">
          <b>{t('bulletin_op_table_do_name')}</b>{' '}
          {row.original[`${lang}_name`]}
          <br />
          <b>{t('bulletin_op_table_type_ownership')}</b>{' '}
          {row.original.ownerships?.[lang]}
          <br />
          <b>{t('bulletin_op_table_academic_year')}</b>{' '}
          {row.original.academic_years?.[lang]}
          <br />
          <b>{t('bulletin_op_table_age_group')}</b>{' '}
          {row.original.age_groups?.[lang]}
          <br />
          <b>{t('bulletin_op_table_periodBirthChildren')}</b>{' '}
          {row.original.year_queues?.[lang]}
          <br />
          <b>{t('bulletin_op_table_group_name')}</b>{' '}
          {row.original[`name_group_${lang}`]}
          <br />
          <b>{t('bulletin_op_table_queue_type')}</b>{' '}
          {row.original.queue_types?.[lang]}
          <br />
          <b>{t('bulletin_op_table_address')}</b>{' '}
          {lang === 'ru' ? row.original.legal_address : row.original.legal_address_kz}
        </div>
        <div className="col2">
          {row.original.status_web === 'AVAILABLE_PRIORITY' ? (
            <>
              <div className="date1">
                {t('bulletin_op_table_priority_date')}{' '}
                {moment(row.original.available_to_all_date).format('DD.MM.YYYY')}
              </div>
              <div className="date2">
                {t('bulletin_op_table_date')}{' '}
                {moment(row.original.published_date).format('DD.MM.YYYY HH:mm')}
              </div>
            </>
          ) : (
            <div className="date2">
              {t('bulletin_op_table_date')}{' '}
              {moment(row.original.published_date).format('DD.MM.YYYY HH:mm')}
            </div>
          )}
        </div>
      </div>
      <div className="row2">
        <div className="cols">
          <p className='title'>{t('bulletin_op_table_type')}</p>
          <p>{row.original.type_bulletins?.[lang]}</p>
        </div>
        <div className="cols">
          <p className='title'>{t('bulletin_op_table_lang')}</p>
          <p>{row.original.gu_languages?.[lang]}</p>
        </div>
        <div className="cols">
          <p className='title'>{t('bulletin_op_table_published')}</p>
          <p>{row.original.published_sits}</p>
        </div>
        <div className="cols">
          <p className='title'>{t('bulletin_op_table_avaiable')}</p>
          <p>{row.original.available_sits}</p>
        </div>
      </div>
      {user?.username && row.original.canGetNapravlenie && (
        <div className={'right'}>
          <Button
            variant="contained"
            onClick={() => modal.open(row.original)}
            color="secondary"
            size="small"
          >
            {t('bulletin_op_get_appointment')}
          </Button>
        </div>
      )}
    </StyledTable>
  );
};
