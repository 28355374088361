import { createReducer } from 'redux-starter-kit';
import { KindergartenAppointmentsApi } from './KindergartenAppointmentsApi';
import Notice from '../../modules/utils/Notice';
import { handleError } from '../../modules/utils/handleError';
import i18next from 'i18next';
import * as Dict from '../../modules/utils/Dict';
import moment from 'moment';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';

/**
 * Constants
 */

export const KindergartenAppointmentsModule = 'direction';
const SET_DATA = `${KindergartenAppointmentsModule}/SET_DATA`;
const LOADING = `${KindergartenAppointmentsModule}/LOADING`;
const CLEAR_STATE = `${KindergartenAppointmentsModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: true,
  data: [],
  pageNumber: 1,
  totalElements: 0
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.data;
    state.pageNumber = action.pageNumber;
    state.totalElements =
      action.pageNumber === 1 ? action.totalElements : state.totalElements;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [CLEAR_STATE]: () => initialState
});

export const clearState = () => ({ type: CLEAR_STATE });

export const loadApplications = filter => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenAppointmentsApi.getAppointments(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const content = [];
      for (let request of data.result.content) {
        let status = await Dict.item(
          'gu_status_direction',
          request.statusDirection || ''
        );
        request.providerLocation = await Dict.item('60', request.queuedRegion);
        content.push({
          ...request,
          status,
          submissionDateColum:
            request.submissionDate && moment(request.submissionDate).format('L'),
          directionDate:
            request.directionDate && moment(request.directionDate).format('L')
        });
      }
      dispatch({
        type: SET_DATA,
        data: content,
        pageNumber: data.result.pageNumber,
        totalElements: data.result.totalElements
      });
    } else {
      Notice.error(i18next.t('kindergarten_appointments_error_load'));
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_appointments_error_load'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};
export const revokeByApplicant = (id, modal, filter) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenAppointmentsApi.getSignStringForRevoke({ id: id });
    const signature = await NCALayerSign(data.result.signature);
    if (signature) {
      await KindergartenAppointmentsApi.revokeByApplicantApi(id, {
        signature
      });
      dispatch(loadApplications(filter));
      modal.close();
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_appointments_error_revoke_applicant'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};
export const prolongByApplicant = (
  id,
  dataForm,
  modal,
  filter,
  clearState
) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenAppointmentsApi.getSignStringForRevoke({
      id: id,
      ...dataForm
    });
    const signature = await NCALayerSign(data.result.signature);
    if (signature) {
      await KindergartenAppointmentsApi.prolongByApplicantApi(id, {
        signature,
        ...dataForm
      });
      dispatch(loadApplications(filter));
      clearState();
      modal.close();
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_appointments_error_prolong_applicant'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};
