import PassportForm from '../../../passport/FormData/PassportForm';
import { useEffect } from 'react';
import { CheckChildInQueue } from './CheckChildInQueue';

export default function CheckIinInQueue({setDisabled}){
  let queue_type = PassportForm.values['request_form_doc_queued_ddo_short.queued_type'];
  let child_iin = PassportForm.values['request_form_doc_queued_ddo_short.child_iin'];

  useEffect(() => {
    if(queue_type && child_iin && child_iin.length === 12) {
      CheckChildInQueue(child_iin, queue_type).then(result => {
        if(result){
          setDisabled(true);
        }else{
          setDisabled(false);
        }
      });
    } else {
      setDisabled(false);
    }
  },[queue_type, child_iin]);

  return null;
}
