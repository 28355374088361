import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    faq_title: 'Часто задаваемые вопросы',
    faq_loadQuestionsFailed: 'Не удалось загрузить данные'
  },
  kk: {
    faq_title: 'Часто задаваемые вопросы',
    faq_loadQuestionsFailed: 'Не удалось загрузить данные'
  }
});