export default {
  locale: "kk",
  Pagination: {
    items_per_page: "/ бет.",
    jump_to: "Өту",
    jump_to_confirm: "растау",
    page: "",
    prev_page: "Алдыңғы бет",
    next_page: "Келесі бет",
    prev_5: "Алдыңғы 5",
    next_5: "Келесі 5",
    prev_3: "Алдыңғы 3",
    next_3: "Келесі 3"
  },
  DatePicker: {
    lang: {
      placeholder: "Күнін таңдаңыз",
      rangePlaceholder: ["Бастапқы күні", "Соңғы күні"],
      today: "Бүгін",
      now: "Қазір",
      backToToday: "Ағымдағы күн",
      ok: "Ok",
      clear: "Тазарту",
      month: "Ай",
      year: "Жыл",
      timeSelect: "Уақытты таңдаңыз",
      dateSelect: "Күнді таңдаңыз",
      monthSelect: "Айды таңдаңыз",
      yearSelect: "Жылды таңдаңыз",
      decadeSelect: "Онжылдықты таңдаңыз",
      yearFormat: "YYYY",
      dateFormat: "D-M-YYYY",
      dayFormat: "D",
      dateTimeFormat: "D-M-YYYY HH:mm:ss",
      monthBeforeYear: true,
      previousMonth: "Өткен ай (PageUp)",
      nextMonth: "Келесі ай (PageDown)",
      previousYear: "Өткен жыл (Control + left)",
      nextYear: "Келесі жыл (Control + right)",
      previousDecade: "Өткен онжылдық",
      nextDecade: "Келесі онжылдық",
      previousCentury: "Өткен ғасыр",
      nextCentury: "Келесі ғасыр"
    },
    timePickerLocale: { placeholder: "Уақытты таңдаңыз" }
  },
  TimePicker: { placeholder: "Уақытты таңдаңыз" },
  Calendar: {
    lang: {
      placeholder: "Күнін таңдаңыз",
      rangePlaceholder: ["Бастапқы күні", "Соңғы күні"],
      today: "Бүгін",
      now: "Қазір",
      backToToday: "Ағымдағы күн",
      ok: "Ok",
      clear: "Тазарту",
      month: "Ай",
      year: "Жыл",
      timeSelect: "Уақытты таңдаңыз",
      dateSelect: "Күнді таңдаңыз",
      monthSelect: "Айды таңдаңыз",
      yearSelect: "Жылды таңдаңыз",
      decadeSelect: "Онжылдықты таңдаңыз",
      yearFormat: "YYYY",
      dateFormat: "D-M-YYYY",
      dayFormat: "D",
      dateTimeFormat: "D-M-YYYY HH:mm:ss",
      monthBeforeYear: true,
      previousMonth: "Өткен ай (PageUp)",
      nextMonth: "Келесі ай (PageDown)",
      previousYear: "Өткен жыл (Control + left)",
      nextYear: "Келесі жыл (Control + right)",
      previousDecade: "Өткен онжылдық",
      nextDecade: "Келесі онжылдық",
      previousCentury: "Өткен ғасыр",
      nextCentury: "Келесі ғасыр"
    },
    timePickerLocale: { placeholder: "Уақытты таңдаңыз" }
  },
  Table: {
    filterTitle: "Сүзгі",
    filterConfirm: "OK",
    filterReset: "Қалпына келтіру",
    emptyText: "Мәлімет жоқ",
    selectAll: "Барлығын таңдау",
    selectInvert: "Таңдауды инвертін алу"
  },
  Modal: { okText: "OK", cancelText: "Болдырмау", justOkText: "OK" },
  Popconfirm: { okText: "OK", cancelText: "Болдырмау" },
  Transfer: {
    notFoundContent: "Еш нәрсе табылмады",
    searchPlaceholder: "Іздеу",
    itemUnit: "элем.",
    itemsUnit: "элем."
  },
  Select: { notFoundContent: "Еш нәрсе табылмады" },
  Upload: {
    uploading: "Жүктеу...",
    removeFile: "Файлды жою",
    uploadError: "Жүктеу сәтсіз аяқталды",
    previewFile: "Файлды қарап шығу"
  }
};
