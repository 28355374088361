import Dialog from '@material-ui/core/Dialog';
import { DialogContentText, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function DialogInterested({
  open,
  handleClose,
  handleDone,
  data,
  loading
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('kindergarten_applications_modal_title_interested')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>
            <b>{t('kindergarten_applications_modal_body_interested')}</b>
          </p>
          <p>
            <b>{t('kindergarten_applications_modal_full_name_interested')}</b>
            {`${data?.childSurname || ''} ${data?.childName ||
              ''} ${data?.childMiddleName || ''}`}
          </p>
          <p>
            <b>{t('kindergarten_applications_modal_number_interested')}</b>
            {data?.position || ''}
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        <div style={{ width: '50%' }}>
          <Button onClick={handleClose} color="primary" variant="contained">
            {t('kindergarten_applications_modal_cancel_button_interested')}
          </Button>
        </div>
        <div style={{ width: '50%' }}>
          {loading ? (
            <CircularProgress size={23} />
          ) : (
            <Button
              onClick={() => {
                handleDone();
              }}
              color="secondary"
              variant="contained"
            >
              {t('kindergarten_applications_modal_ok_button_interested')}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}
