import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    gov_title: 'Централизованная база данных подведомственных учреждений акимата Алматинской области',
    gov_subtitle: 'Государственные услуги',
  },
  kk: {
    gov_title: 'Алматы облысы әкімдігінің ведомстволық бағыныстағы мекемелерінің орталықтандырылған деректер базасы',
    gov_subtitle: 'Мемлекеттік қызметтер',
  }
});
