import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import CheckboxIcon from '@material-ui/icons/CheckBox';
import CheckboxBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import withFlc from '../../flc/withCheckBoxGroupFlc';
import Expander from '../../Expander';
import { arrayToTree } from '../DTreeSelect/DTreeSelectUtils';
import withOptions from '../../flc/withOptions';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components';
import { changedStyle } from '../../../PassportStyle';
import { handleQuestionLabel } from '../../../Utils/handleQuestionLabel';

const Wrapper = styled.div`
  .border-top:first-child {
    border-top: none !important;
  }

  .border-top:last-child {
    padding-bottom: 0 !important;
  }
`;

const Label = ({ item, lang, changed }) => {
  let style = {
    lineHeight: 1,
    fontSize: 14,
    color: item.incorrect ? 'red' : 'inherit',
    padding: '5px 0',
    marginBottom: 0
  };
  if (!item.incorrect && changed) style = { ...style, ...changedStyle };
  return <div style={style}>{item[`${lang}_name`]}</div>;
};

const DCheckboxGroup = ({
  value,
  lang,
  error,
  savedValue = [],
  ...props
}) => {
  const handleChange = event => {
    if (event.target.checked) {
      if (event.target.value === '-1') {
        props.onChange([event.target.value]);
      } else {
        props.onChange([...value, event.target.value].filter(item => item !== '-1'));
      }
    } else {
      props.onChange(value.filter(item => item !== event.target.value));
    }
  };

  const renderOption = (item, level, hasChild) => {
    if (hasChild) {
      if (level === 1) {
        return (
          <div className="clearfix border-top pb2 pt1 mt1" key={item.code}>
            <div className="bold pr2 col col-4">{item[`${lang}_name`]}</div>
            <div className="col col-8">{handleRender(item.childItems, level + 1)}</div>
          </div>
        );
      }
      return (
        <div key={item.code}>
          <div className="bold pr2 mt1">
            {item[`${lang}_name`]}
            <Divider style={{ marginBottom: 3, marginTop: 3 }} />
          </div>
          <div style={{ marginTop: 3 }}>{handleRender(item.childItems, level + 1)}</div>
        </div>
      );
    } else {
      const changed = value.includes(item.code) !== savedValue.includes(item.code);
      return (
        <FormControlLabel
          key={item.code}
          disabled={!item.incorrect && props.disabled}
          label={<Label item={item} lang={lang} changed={changed} />}
          style={{ width: 'calc(50% - 12px)', marginLeft: -4, marginBottom: 0 }}
          control={
            <Checkbox
              checked={value.includes(item.code)}
              onChange={handleChange}
              style={level === 1 ? { padding: '0 0 0 5px' } : { padding: 0 }}
              value={item.code}
              checkedIcon={
                <CheckboxIcon color={item.incorrect ? 'secondary' : 'primary'} />
              }
              icon={<CheckboxBlankIcon />}
            />
          }
        />
      );
    }
  };

  const handleRender = (options, level) => {
    return (
      <Wrapper>
        {level === 1 &&
        options.filter(item => !(item.childItems && item.childItems.length)).length >
          0 ? (
          <div className="row clearfix">
            <div className="col col-4" />
            <div className="col col-8">
              {options
                .filter(item => !(item.childItems && item.childItems.length))
                .map(item => renderOption(item, level, false))}
            </div>
          </div>
        ) : (
          options
            .filter(item => !(item.childItems && item.childItems.length))
            .map(item => renderOption(item, level, false))
        )}

        {options
          .filter(item => item.childItems && item.childItems.length)
          .map(item => renderOption(item, level, true))}
      </Wrapper>
    );
  };

  return (
    <div className="my1">
      <FormControl error={!!error} fullWidth>
        <Expander
          title={handleQuestionLabel(props, lang)}
          code={props.code}
          error={error}
        >
          {handleRender(arrayToTree(props.options, value, true), 1)}
          {props.incorrect.length > 0 && (
            <Divider style={{ marginBottom: 5, marginTop: 5 }} />
          )}
          {props.incorrect.map(item => renderOption({ ...item, incorrect: true }, 1))}
        </Expander>
        {error && <FormHelperText style={{ textAlign: 'right' }} children={error} />}
      </FormControl>
    </div>
  );
};

export default withOptions(withFlc(DCheckboxGroup));
