import { executeFlc } from './executeFlc';
import { translation } from '../../Components/translation/translation';
import { flcHasValue } from '../../Utils/flcHasValue';
import { RENDERER } from '../../Utils/Constants';
import { cloneObject } from '../../Utils/cloneObject';
import { DomainData } from '../../Utils/DomainData';
import equalValue from '../../Utils/equalValue';

export function runFlc(question, visibility, value) {
  let props = cloneObject(question);
  let { flc, ruleBodies } = executeFlc(value, props);

  // если раздел скрытый, скрываем и показатель
  if (question.groupHided) {
    flc.visibility = false;
  }
  if (!question.groupHided && question.hideMode) {
    delete flc.calculation;
  }

  // обработка валидаций
  handleValidation(value, flc, props.lang);
  // очищение данных если visibility = false
  const clearValue =
    (visibility && !flc.visibility && !props.keepIfHidden) ||
    flc.calculation === null ||
    (!flc.visibility && !props.keepIfHidden && flcHasValue(value));
  // возвращаем данные если visibility из false переходит в true
  const recoveryValue = visibility === false && flc.visibility && !props.keepIfHidden;

  switch (props.renderer) {
    case RENDERER.checkbox_group: {
      handleIncorrect(value, flc, props.options);
      if (clearValue) flc.calculation = [];
      if (recoveryValue && !equalValue(value, props.savedValue))
        flc.calculation = props.savedValue;
      break;
    }
    case RENDERER.text:
    case RENDERER.date:
    case RENDERER.date_time:
    case RENDERER.short_text:
    case RENDERER.long_text:
    case RENDERER.numeric_text:
    case RENDERER.radio_button:
    case RENDERER.check_box:
    case RENDERER.dropdown_tree:
    case RENDERER.table_cell: {
      if (clearValue) flc.calculation = '';
      if (recoveryValue && !equalValue(value, props.savedValue))
        flc.calculation = props.savedValue;
      break;
    }
    case RENDERER.dropdown: {
      handleIncorrect(value, flc, props.options);
      if (clearValue) flc.calculation = '';
      if (recoveryValue && !equalValue(value, props.savedValue))
        flc.calculation = props.savedValue;
      break;
    }
    case RENDERER.complex_inline:
    case RENDERER.complex_table: {
      if (clearValue) flc.calculation = {};
      if (recoveryValue && !equalValue(value, props.cellsSavedValue))
        flc.calculation = props.cellsSavedValue;
      break;
    }
    case RENDERER.table: {
      if (clearValue) {
        flc.calculation = props.cellsSavedValue;
        for (const cell of Object.values(flc.calculation)) {
          cell.value = '';
        }
      }
      if (recoveryValue && !equalValue(value, props.cellsSavedValue))
        flc.calculation = props.cellsSavedValue;
      break;
    }
    case RENDERER.document: {
      if (clearValue) flc.calculation = null;
      if (recoveryValue && !equalValue(value, props.savedValue))
        flc.calculation = props.savedValue;
      break;
    }

    default:
      break;
  }

  // вывод результатов флк для тестового режима
  if (window.test) {
    if (Object.keys(ruleBodies).length > 0) {
      console.log('%c%s', 'color: #007D77;', `[${props.code}] ---------> `, {
        ...flc,
        ...ruleBodies,
        keepIfHidden: props.keepIfHidden,
        value
      });
    }
  }
  return flc;
}

// если поле обязательное проставляем ошибку
function handleValidation(value, flc, lang) {
  if (flc.validation) {
    if (lang && DomainData.cash.validation_messages[flc.validation]) {
      flc.validation =
        DomainData.cash.validation_messages[flc.validation][`${lang}_name`];
    } else {
      flc.validation = translation(`flc_${flc.validation}`);
    }
  }
  if (flc.visibility && flc.required) {
    if (!flcHasValue(value)) {
      flc.validation = translation('flc_required');
    }
  }
}

function handleIncorrect(value, flc, options, renderer) {
  if (flc.visibility && flcHasValue(value)) {
    let incorrect = [];
    let checkValue = itemValue => {
      let option = options.find(item => item.code === itemValue);
      if (flc.itemVisibility && !flc.itemVisibility(value, itemValue, option)) {
        if (option) {
          incorrect.push(option);
        } else {
          incorrect.push({
            ru_name: `<${itemValue}>`,
            kk_name: `<${itemValue}>`,
            code: itemValue
          });
        }
      }
      if (!options.some(item => item.code === itemValue)) {
        incorrect.push({
          ru_name: `<${itemValue}>`,
          kk_name: `<${itemValue}>`,
          code: itemValue
        });
      }
    };
    if (value instanceof Array) {
      for (const itemValue of value) {
        checkValue(itemValue);
      }
      if (
        [RENDERER.checkbox_group, RENDERER.multiple_dropdown_tree].includes(renderer) &&
        value.length >= 2 &&
        value.includes('-1')
      ) {
        flc.validation = translation('flc_incorrectValue');
      }
    } else {
      checkValue(value);
    }

    if (incorrect.length > 0) {
      flc.validation = translation('flc_incorrectValue');
      flc.incorrect = incorrect;
    }
  }
}
