import React from 'react';
import PropTypes from 'prop-types';
import TreeSelect from './TreeSelect';
import withTranslation from './withTranslation';
import { sortKato } from '../../utils/sortKato';
import Dict from '../../modules/utils/Dict';

class TreeSelectCatalog extends React.Component {
  state = {
    treeLoaded: false,
    tree: [],
    keys: []
  };

  componentDidMount() {
    if (this.props.options) {
      this.setState({ tree: this.props.options });
    } else {
      this.loadCurrent(this.props.value);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.treeLoaded) {
      const { value, multiple, filterCode } = this.props;
      if (prevProps.value !== value && !multiple) {
        if (value && !this.treeHasValue(this.state.tree, value)) {
          this.loadTree();
        }
      }
      if (prevProps.filterCode !== filterCode) {
        this.loadChild();
      }
    } else if (prevProps.value !== this.props.value) {
      this.loadCurrent(this.props.value);
    }
  }

  treeHasValue = (tree, value) => {
    for (let item of tree) {
      if (item.code === value || item.value === value) {
        return true;
      } else if (item.hasOwnProperty('children')) {
        if (this.treeHasValue(item.children, value)) {
          return true;
        }
      }
    }
    return false;
  };

  addChildren(code, tree, children) {
    let result = false;
    if (children && children.length !== 0) {
      for (let item of tree) {
        if (item.value === code) {
          item.children = children;
          result = true;
        } else if (item.hasOwnProperty('children')) {
          result = this.addChildren(code, item.children, children);
        }
      }
    }
    return result;
  }

  async loadCurrent(code) {
    if (code) {
      const current = await Dict.item(this.props.lookupId, code);
      this.setState({ tree: [{ ...current, value: current.code }] });
    }
  }

  async loadChild() {
    let tree = await this.loadChildLevel(this.props.filterCode);
    if (tree.length === 0) {
      this.loadCurrent(this.props.filterCode);
    } else {
      this.setState({ tree });
    }
  }

  async loadTree() {
    let result = await this.loadTreeRecursive(this.props.value);
    this.setState({ tree: result.tree, keys: result.keys });
  }

  async loadLevel(code) {
    let children = await this.loadChildLevel(code);
    let tree = [...this.state.tree];
    this.addChildren(code, tree, children);
    this.setState({ tree });
    return children.length;
  }

  async loadChildLevel(code) {
    let filter = `?filter=[{"name":"parent_code","operation":"EQ","value":"${code}"}]`;
    if (!code) filter = `?filter=[{"name":"parent_code","operation":"EMPTY"}]`;

    try {
      let result = await Dict.items(this.props.lookupId, filter);

      if (this.props.flk && this.props.flk.getItemVisible) {
        result = result.filter(item => this.props.flk.getItemVisible(item.code));
      }

      if (this.props.lookupId === '60') {
        result.sort(sortKato('code'));
      }

      return result.map(item => ({ ...item, value: item.code }));
    } catch (e) {
      return [];
    }
  }

  async loadTreeRecursive(code, children = [], keys = []) {
    const { filterCode, lookupId } = this.props;
    let cur = await Dict.item(lookupId, code);
    let curLevel = await this.loadCurrentLevel(code);

    this.addChildren(code, curLevel, children);
    if (children.length !== 0) keys.push(code);

    if (cur.hasOwnProperty('parent_code') && cur.parent_code !== filterCode) {
      return await this.loadTreeRecursive(cur.parent_code, curLevel, keys);
    } else {
      return { keys: keys, tree: curLevel };
    }
  }

  async loadCurrentLevel(code) {
    let current = await Dict.item(this.props.lookupId, code);
    let filter = `{"name":"parent_code","operation":"EMPTY"}`;

    if (current.hasOwnProperty('parent_code')) {
      let parentCode = current.parent_code;
      filter = `{"name":"parent_code","operation":"EQ","value":"${parentCode}"}`;
    }

    try {
      let result = await Dict.items(this.props.lookupId, `?filter=[${filter}]`);
      return result.map(item => ({ ...item, value: item.code }));
    } catch (e) {
      return [];
    }
  }

  render() {
    const { value, multiple } = this.props;
    return (
      <TreeSelect
        {...this.props}
        tree={this.state.tree}
        keys={this.state.keys}
        onExpand={keys => this.setState({ keys })}
        loadData={node => this.loadLevel(node.props.value)}
        onDropdownVisibleChange={() => {
          if (!this.state.treeLoaded) {
            value && !multiple ? this.loadTree() : this.loadChild();
            this.setState({ treeLoaded: true });
          }
        }}
      />
    );
  }
}

TreeSelectCatalog.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  filterCode: PropTypes.string,
  lookupId: PropTypes.string,
  error: PropTypes.any,
  dropdownFixed: PropTypes.bool,
  parentSelectable: PropTypes.bool
};

TreeSelectCatalog.defaultProps = {
  parentSelectable: true
};

export default withTranslation()(TreeSelectCatalog);
