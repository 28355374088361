import { getFlcRules } from '../../Utils/getFlcRules';
import { createContext } from './context';
import { FLC_RULES } from '../../Utils/Constants';
import { handleError } from '../../Utils/handleError';
import { translation } from '../../Components/translation/translation';
import { TestMode } from '../TestMode';
import { valueTypes } from './valueTypes';

export function executeFlc(value, question) {
  let flc = {
    visibility: true,
    required: false,
    readOnly: false,
    hideMode: question.hideMode
  };
  let ruleBodies = {};
  let context = createContext(question.metaDataId);
  // eslint-disable-next-line
  let rowIndex,
    cell = {};
  if (question.cellConfig && question.cellConfig.isComplexCell) {
    const { code, complexCode } = question.cellConfig;
    context.createComplexTableRow(code, complexCode);
    context.createComplexTableCell(code, complexCode);
    rowIndex = question.cellConfig.rowIndex;
  }

  if (question.cellConfig && question.cellConfig.isTableCell) {
    cell.coordinates = question.cellConfig.coordinates;
    cell.value = value;
  }
  for (const rule of getFlcRules(question.renderer)) {
    const { calculationRule, inputRestrictionRule, itemVisibilityRule } = FLC_RULES;
    let ruleBody = window.test ? TestMode.getRule(question, rule) : question[rule];

    if (rule === inputRestrictionRule) {
      flc.inputRestriction = () => false;
    }

    if (ruleBody) {
      ruleBodies[rule] = ruleBody;
      try {
        if (flc.visibility) {
          if (rule === inputRestrictionRule) {
            flc.inputRestrictionRule = ruleBody;
            flc.inputRestriction = iValue => {
              // eslint-disable-next-line
              const itemValue = valueTypes(question.renderer, iValue);
              try {
                // eslint-disable-next-line
                return eval(ruleBody);
              } catch (error) {
                executeError(question, rule, error);
              }
            };
          } else if (rule === itemVisibilityRule) {
            flc.itemVisibilityRule = ruleBody;
            flc.itemVisibility = (value, itemValue, dictItem = {}) => {
              try {
                // eslint-disable-next-line
                return eval(ruleBody);
              } catch (error) {
                executeError(question, rule, error);
              }
            };
          } else if (rule === FLC_RULES.matrixVisibilityRule) {
            const matrixVisibility = [];
            for (const ruleItem of ruleBody) {
              // eslint-disable-next-line
              if (!eval(ruleItem.rule)) {
                matrixVisibility.push(ruleItem.cellsFilter);
              }
            }
            flc[rule.replace('Rule', '')] = matrixVisibility;
          } else {
            // eslint-disable-next-line
            flc[rule.replace('Rule', '')] = eval(ruleBody);
          }
        } else if (question.keepIfHidden && rule === calculationRule) {
          // eslint-disable-next-line
          flc[rule.replace('Rule', '')] = eval(ruleBody);
        }
      } catch (error) {
        executeError(question, rule, error);
      }
    }

  }
  if (flc.required !== true && flc.required !== false) {
    flc.required = Boolean(flc.required);
  }
  if (flc.visibility !== true && flc.visibility !== false) {
    flc.visibility = Boolean(flc.visibility);

  }
  return { flc, ruleBodies };
}

function executeError(question, rule, error) {
  let msg = `
        ${translation('passport_errorExecuteRule')}
        ${question.code} => ${rule}: ${question[rule]}
        `;
  handleError(error, msg, true, 'warning');
}
