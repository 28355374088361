import styled from 'styled-components';

export const StyledCardsContainer = styled.div`
  margin: 30px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  height: 100%;
  .ReactTable {
    border-top: 1px solid rgba(125,125,125,0.4);
    text-align: left;
  }
  .centerContainer{
    text-align: center;
    margin: auto;
    height: 100%;
    top: 10%;
  }
  .inputs{
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .mrgTop10{
    margin-top: 10px;
  }
  .text{
    margin-top: 10px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  .result{
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    text-transform: initial;
  }
`;

export const StyledTableContainer = styled.div`
  min-width: 800px;
  margin-top: 15px;
`;
