import styled from 'styled-components';
import Colors from '../../../components/ThemeProvider/Colors';

export const StyledInsert = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledInsertColorBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(1, 21, 41, 0.1) 10.01%, rgba(1, 21, 41, 0) 36.9%);
`

export const StyledInsertContent = styled.div`
  position: absolute;
  bottom: 105px;
  left: 0;
  width: 100%;
  @media screen and (max-width: 500px) {
    bottom: 30px;
  }
`;

export const StyledInsertTitle = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 130%;
  text-transform: uppercase;
  color: #fff;
  width: 670px;
  @media screen and (max-width: 900px) {
    font-size: 30px;
    width: 500px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 27px;
  }
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

export const StyledInsertText = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #FFFFFF;
  @media screen and (max-width: 900px) {
    width: 370px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;


export const StyledServicesCards = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
    padding-top: 68px;
    padding-bottom: 48px;
    @media screen and (max-width: 1140px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 600px) {
        grid-template-columns: 2fr;
    }
`

export const StyledServicesCard = styled.div`
  grid-column: span 2;
  background: ${Colors.cardBackground};
  padding: 30px 22px;
  box-shadow: 0px 0px 15px rgba(25, 118, 210, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
    position: relative;
`

export const StyledServicesCardNew = styled.div`
  position: absolute;
  background: ${Colors.radioActive};
  top: 20px; 
  left: 20px; 
  padding: 10px 15px;
  border-radius: 15px;
  color: #fff;
`

export const StyledServicesCardBlock = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledServicesCardImg = styled.div`
    width: 165px;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    } 
`
export const StyledServicesCardText = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #282828;
    margin-bottom: none;
`
