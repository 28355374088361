export default {
  flc_number_of_pupils:
    '«Жетім балалар мен ата-анасының қамқорлығынсыз қалған балалар ұлты бойынша» және «жетім балалар мен ата-анасының қамқорлығынсыз қалған балалар» жолдары бірдей болу қажет',
  flc_incorrect: 'Қате мән',
  flc_undefined: 'Толтыру міндетті',
  flc_required: 'Толтыру міндетті',
  flc_test_validation_code: 'Ақпаратты тестілік тексеру',
  flc_email_validation: 'Электрондық пошта мекенжайының форматы дұрыс емес',
  flc_another_test_validation_code: 'Электрондық пошта мекенжайының форматы дұрыс емес',
  flc_interactive_tools_validation_code: 'Нөлге тең болуы мүмкін емес',
  flc_zero_validation_code:
    'Декретік демалыстағы қызметкер үшін жүктеме нөлге тең болуы тиіс',
  flc_personalCompareDates:
    'Еңбек шартын бұзу/тоқтату күні жұмысқа қабылдау күнінен кем болмауы тиіс',
  flc_personalCheckemploymentDate: 'Жұмысқа қабылдау күні міндетті түрде толтырылуы тиіс',
  flc_group_room_area:
    'Топтық бөлмелердің ауданы ғимараттың жалпы ауданынан аз болуы тиіс',
  flc_up_limit: '2500 артық болмауы керек',
  flc_area_not_null: 'Спорт залы болған жағдайда ауданы 0 тең болмауы қажет',
  flc_less_five_numbers: 'Сан 5 цифрдан аспауы тиіс',
  flc_less_count_book: 'Кітаптар жиынтығының санынан аз немесе тең болуы тиіс',
  flc_less_count_mug: 'Үйірмелер санынан аз немесе тең болуы тиіс',
  flc_office_quantaty_nomore_quantaty_withequipment:
    'Замануи оқыту құрылғыларымен жабдықталған кабинеттердің саны (соңғы 10 жылға) кабинеттердің жалпы санынан артық болмауы тиіс',
  flc_not_more_people: '20 адамнан аспауы керек',
  flc_total_design_capacity: 'Жиынтық жобалық қуаты 2500-ден артық бола алмайды',
  flc_groups_must_coincide:
    'Топтың атауы «Топтар» көрсеткішінде енгізілген атаулардың бірімен сәйкес келуі қажет',
  flc_not_null: 'нөлге тең бола алмайды',
  flc_no_more_total: '"Барлығы" мәнінен аспауы тиіс',
  flc_no_more_used_instudy: '«Оқу/тәрбие үдерісінде қолданылатын» мәнінен аспауы тиіс',
  flc_no_more_for__teachers:
    '"Оқытушыларға/мұғалімдерге/тәрбиешілерге арналған" мәнінен аспауы тиіс',
  flc_can_not_exceed:
    '«Барлық келушілер, адам» кестесінің осы жолындағы мәнінен аспауы тиіс',
  flc_no_more_total_equipment:
    '"орнатылған жабдық бірліктерінің саны" мәнінен аспауы тиіс',
  flc_no_more_total_facilities: '"Бөлінген қаражат сомасы" мәнінен аспауы тиіс',
  flc_no_more_3number: 'Орташа жалақы мөлшері 999 мың теңгеден аспауы тиіс',
  flc_sum_lines_equal:
    '«Барлық оқушылар» жолы бойынша мәндердің қосындысы «Білім беру үдерісі туралы негізгі мәліметтер» бөліміндегі «Мектеп жасындағы оқушылар саны (6-18 жас), адам» жолындағы мәнге тең болуы тиіс',
  flc_no_more_total_countries: '"Елдің шегінен тыс кетті" мәнінен аспауы тиіс',
  flc_groups_must_coincide_property:
    'Топтың атауы "Меншік түріне қарай барлық топтар туралы мәліметтер" көрсеткішінде енгізілген атаулардың бірімен сәйкес келуі тиіс',
  flc_can_not_exceed_diseases:
    '"Балалардың сырқаттарының саны, адам" кестесінің осы жолындағы мәнінен аспауы тиіс',
  flc_wrongCoordinates: 'Координаттары қате, картада орын белгілеңіз!',
  flc_no_more_2_number: '99 санынан аспауы тиіс',
  flc_no_more_3_number: '999 санынан аспауы тиіс',
  flc_no_more_exploitation:
    '"Ғимаратттың пайдалануға берілген жылынан" артық болмауы қажет',
  flc_no_more_contract:
    '««Келісім шарт бойынша интернеттің жылдамдығынан» артық болмауы тиіс',
  flc_no_more_total_area: '«Ғимараттың жалпы ауданы» артық болмауы тиіс',
  flc_no_more_work_area: '«Ғимараттың жалпы жұмыс ауданынан» артық болмауы тиіс',
  flc_no_more_current_computers:
    'Қосындысы ағымдағы жылға арналған компьютерлер санынан аспауы тиіс',
  flc_no_more_total_cabinets: 'Кабинеттер санынан артық болмауы тиіс',
  flc_more_null: 'Нөлден артық болуы тиіс',
  flc_no_more_ling_cabinets: 'Лингафондық кабинеттер санынан аспауы тиіс',
  flc_null_total_ling_cabinets:
    'Лингафондық кабинеттер саны секілді нөлге тең болуы тиіс',
  flc_null_total_inf_cabinets:
    'Информатика кабинеттерінің саны секілді нөлге тең болу керек',
  flc_up_limit_do: '1000 артық болмауы тиіс',
  flc_null_cabinets_fizics:
    'Физикадан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
  flc_no_more_cabinets_fizics:
    'Физикадан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
  flc_null_cabinets_chemistry:
    'Химиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
  flc_no_more_cabinets_chemistry:
    'Химиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
  flc_null_cabinets_bilogy:
    'Биологиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
  flc_no_more_cabinets_biology:
    'Биологиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
  flc_null_cabinets_geography:
    'Географиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
  flc_no_more_cabinets_geography:
    'Географиядан "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
  flc_null_cabinets_ling:
    'Лингафондық-мультимедиялық кабинеттер "Кабинеттердің бар болуы, бірлік" кестесіндегі секілді нөлге тең болуы тиіс',
  flc_no_more_cabinets_ling:
    'Лингафондық-мультимедиялық кабинеттер"Кабинеттердің бар болуы, бірлік" кестесіндегі секілді аз немесе тең болуы тиіс',
  flc_equal_zero: 'Нөлге тең болуы тиіс',
  flc_less_element:
    'Аудиторлық және аудиториядан тыс қордың сомасы ғимараттың жалпы ауданынан аспауы тиіс',
  flc_no_more_5number: '100 000 кем болуы тиіс',
  flc_more_yard_clubs: '"аула клубтарының саны" көп болуы керек',
  flc_personnelInvalidBirthday: 'Ограничение по возрасту, диапазон возраста',
  flc_one_hot_or_buffet: 'Бір уақытта ыстық және буфеттік тамақпен қамтыла алмайды',
  flc_no_hotmeal:
    '"Ақысыз ыстық тамақпен қамтылды" белгілеу үшін "Ыстық тамақпен қамтылды" белгілеу қажет',
  flc_no_buffet:
    '"Ақысыз буфеттік тамақпен қамтылды" белгілеу үшін "Буфеттік тамақпен қамтылды" белгілеу қажет',

  flc_equal_rebuildings:
    'Ғимараттың техникалық жағдайы «күрделі жөндеуді қажет етеді немесе жүргізілді» деп белгіленген ғимараттың санына тең болуы тиіс',
  flc_equal_current_repairs:
    'Ғимараттың техникалық жағдайы «ағымдағы жөндеуді қажет етеді немесе жүргізілді» деп белгіленген ғимараттың санына тең болуы тиіс',
  flc_cost_more_null: 'Құны нөлден көп болуы тиіс',
  flc_need_boording:
    'Оқушы бір мезгілде "мектеп жанындағы интернатта тұруы" және "мектеп жанындағы интернатқа орналастыруды қажет етуі" мүмкін емес',
  flc_equal_sum_lingcabinets:
    'Ұяшықтардың қосындысы "Кабинеттердің бар болуы, бірлік" көрсеткішінің "лингафондық кабинетер" элементіне тең болуы қажет',
  flc_school_children_age:
    '«Мектеп жасындағы оқушылар саны (6-18 жас)» элементіне тең немесе кем болуы қажет',
  flc_not_more_total_work_experience:
    'Педагогикалық еңбек өтілі «Жалпы еңбек өтілінен» көп бола алмайды',
  flc_no_more_16_year: 'Педагогикалық еңбек өтілі 16 жастан рұқсат етіледі',
  flc_sum_lines_equal_two:
    'Осы жолдың мәндерінің қосындысы «Білім беру үдерісі туралы негізгі мәліметтер» бөліміндегі «Мектепке дейінгі жастағы балалар саны (1-6 жас)» мәніне тең болуы тиіс',
  flc_integer_5number: '5 цифрдан аспайтын бүтін оң сан болуы қажет',
  flc_school_children_age_two:
    '«Мектепке дейінгі жастағы балалар саны (1-6 жас)» элементіне тең немесе кем болуы қажет',
  flc_must_main: 'кем дегенде бір «Басты ғимарат» болуы керек',
  flc_following_format: 'Номер телефона должен соответствовать 11 цифр без символов',
  flc_scholarships_available:
    'стипендия болған жағдайда «Стипендия мөлшері және төлем жүргізілуі туралы ақпарат, теңге» көрсеткішінде кем дегенде 1 ұяшық 0-ден артық болуы тиіс',
  flc_should_not_exceed:
    '«Аталған мекемедегі жалпы еңбек өтілі» көрсеткішінен артық болмауы керек',
  flc_property_group_data:
    '«Топтар» және «Меншік түріне қарай барлық топтар туралы мәліметтер» кестелерінің өрістерінің саны тең болуы қажет',
  flc_girls_boys_total_not_exceed:
    '«Оның ішінде қыздар» және «Оның ішінде ұлдар» жолдарының қосындысы «Барлығынан» аспауы тиіс',
  flc_the_total_must_match:
    '«Балалардың жалпы саны» көрсеткіші «Бұзылыс (сырқат) түрлері бойынша балалардың жалпы саны» мен «Ондағы жасы бойынша» көрсеткіштеріне сай келуі тиіс',
  flc_need_for_staff: '«оның ішінде әйелдер» «кадрлар қажеттілігі» аспауы керек',
  flc_language_match_groups:
    '«Оқыту тілі» көрсеткішіндегі таңдалған тілдер «Топтар» көрсеткішімен сәйкес келуі тиіс',
  flc_should_once_main: 'Тек бір ғана негізгі ғимарат болуы мүмкін',
  flc_cant_anymore_quantity: '«Саны, дана» көрсеткішінен артық болмауы керек',
  flc_transportation_information:
    'Бір уақытта «мектепке өзі жетеді» және «күнделікті тасымалдаумен қамтылған» таңдауға болмайды',
  flc_the_above_line: 'жоғары көрсетілген жолға тең немесе одан кіші  болуы қажет',
  flc_the_above_line_teachers:
    '«Оқытушыларға/мұғалімдерге/тәрбиешілерге арналған» жолына тең немесе одан кіші  болуы қажет',
  flc_unused_amount:
    '«оның ішінде жөндеуге жатады» және «оның ішінде жөндеуге жатпайды (есептен шығаруды қажет етеді)» элементтерінің қосындысы «Қолданылмайтын» элементіне тең немесе одан кіші  болуы қажет',
  flc_latin_allowed: 'латын әріптері мен сандарды енгізу рұқсат етіледі',
  flc_sum_elements_total:
    '«Барлығы» элементтерінің қосындысы «Компьютерлердің саны, бірлік» кестесінің «Барлығы» элементіне тең болуы қажет',
  flc_sum_elements_total2:
    '«Орнатылған операциялық жүйесі бар компьютерлер саны» және «Орнатылған офистік бағдарламалар пакеті бар компьютерлер саны» элементтері «Компьютерлердің саны, бірлік» кестесінің «Барлығы» элементіне тең немесе кіші болуы қажет',
  flc_sum_elements_total3:
    'әрбір жыл бойынша жолдардың қосындысы «Компьютерлердің саны, бірлік» кестесінің «Барлығы» элементіне тең болуы қажет',
  flc_no_more_than_25: '25 орыннан аспауы тиіс'
};
