import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    serviceProviders_title: 'Реестр услугодателей',
    serviceProviders_errorLoad: 'Не удалось загрузить данные реестра услугодателей',
    serviceProviders_columnId: 'Номер',
    serviceProviders_columnIin: 'ИИН',
    serviceProviders_columnBin: 'БИН',
    serviceProviders_columnTypeName: 'Тип',
    serviceProviders_columnLocation: 'Населенный пункт',
    serviceProviders_columnName: 'Наименование',
    serviceProviders_columnAction: 'Действие',

    serviceProviders_create: 'Создать услугодателя',
    serviceProviders_close: 'Закрыть организацию',
    serviceProviders_reopened: 'Переоткрыть организацию',

    serviceProviders_errorLoadTable: 'Не удалось загрузить реестр услугодателей',
    serviceProviders_errorChangeFilter: 'Не удалось поменять фильтр',
    serviceProviders_deleteSuccess: 'Удаление услугодателей прошло успешно',
    serviceProviders_deleteError: 'Не удалось удалить услугодателей',

    serviceProviders_heirCloseTitle: 'Наследование заявок закрываемого услугодателя',
    serviceProviders_heir_choose_providers: 'Выберите услугодателя',
    serviceProviders_heirCloseSuccess:
      'Наследования истории от услугодателя успешно закрыта',
    serviceProviders_heirCloseError:
      'Не удалось закрыть наследования истории от услугодателя',
    serviceProviders_heirReopened_title: `Переоткрыть наследования`,
    serviceProviders_heirReopened: `Вы хотите переоткрыть организацию с номером бин {{id}} ?`,
    serviceProviders_heirReopenedSuccess:
      'Наследования истории от услугодателя успешно переоткрыта',
    serviceProviders_heirReopenedError:
      'Не удалось открыть наследования истории от услугодателя'
  },
  kk: {
    serviceProviders_title: 'Қызмет көрсетушілердің тізілімі',
    serviceProviders_errorLoad:
      'Қызмет көрсетушілер тізілімінің деректерін жүктеу мүмкін емес',
    serviceProviders_columnId: 'Нөмірі',
    serviceProviders_columnIin: 'ЖСН',
    serviceProviders_columnTypeName: 'Түрі',
    serviceProviders_columnLocation: 'Аймақ',
    serviceProviders_columnBin: 'БСН',
    serviceProviders_columnName: 'Атауы',
    serviceProviders_columnAction: 'Әрекет',

    serviceProviders_create: 'Қызмет көрсетушіні құру',
    serviceProviders_close: 'Ұйымды жабу',
    serviceProviders_reopened: 'Ұйымды қайта ашу',

    serviceProviders_errorLoadTable:
      'Қызмет көрсетушілердің тізілімін жүктеу мүмкін емес',
    serviceProviders_errorChangeFilter: 'Сүзгіні өзгерту мүмкін болмады',
    serviceProviders_deleteSuccess: 'Қызмет көрсеушілерді жою сәтті ақталды',
    serviceProviders_deleteError: 'Қызмет көрсетушілерді жою мүмкін емес',

    serviceProviders_heirCloseTitle:
      'Жабылатын қызмет көрсетушінің өтінімдерін мұралануы',
    serviceProviders_heir_choose_providers: 'Қызмет көрсетушіні таңдаңыз',
    serviceProviders_heirCloseSuccess:
      'Қызмет берушіден тарихының мұралануы сәтті жабылды',
    serviceProviders_heirCloseError:
      'Қызмет көрсетушіден тарихының мұралануын жабу мүмкін емес',
    serviceProviders_heirReopened_title: 'Мұралануды қайта ашу',
    serviceProviders_heirReopened:
      'Сіз ұйымды{{id}} БСН нөмірімен қайта ашқыңыз келеді ме?',
    serviceProviders_heirReopenedSuccess:
      'Қызмет көрсетушіден тарихының мұралануы сәтті қайта ашылды',
    serviceProviders_heirReopenedError:
      'Қызмет берушіден тарихының мұраларын ашу мүмкін емес'
  }
});
