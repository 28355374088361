export const footer_services = {
  kindergarten_reestr: {
    url: 'kindergarten-reestr',
    ru_name: 'Реестр детских садов',
    kk_name: 'Балабақшалар тізімі',
  },
  doc_queued_ddo_short: {
    code: 'doc_queued_ddo_short',
    ru_name: 'Постановка на очередь',
    kk_name: 'Кезекке тұру',
  },
  doc_acceptance_ddo_short: {
    code: 'doc_acceptance_ddo_short',
    ru_name: 'Прием документов и зачисление',
    kk_name: 'МДҰ-ға құжаттарды қабылдау және балаларды тіркеу',
  },
}
export const footer_services2 = {
  check_queue_number: {
    url: 'check-queue-number',
    ru_name: 'Проверка номера в очереди',
    kk_name: 'Кезектегі нөмірді тексеру',
  },
  application_queue: {
    url: 'queue-applications',
    ru_name: 'Очередь заявлений',
    kk_name: 'Өтініштер кезегі',
  },
  bulletin_open_place: {
    url: 'bulletin-open-places',
    ru_name: 'Бюллетень свободных мест',
    kk_name: 'Бос орындар бюллетені',
  },
}