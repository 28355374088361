import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Colors from '../ThemeProvider/Colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import './BreadCrumbsTranslate';

const Wrapper = styled(MuiBreadcrumbs)`
  a:hover {
    text-decoration: underline;
  }
`;

export const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

function BreadCrumbs({ items = [] }) {
  const { root } = useStyles();
  return (
    <Wrapper separator="›">
      {items.map((item, index) =>
        items.length - 1 !== index ? (
          <Link
            key={index}
            to={item.href}
            children={
              <Typography color="secondary" children={item.name} className={root} />
            }
          />
        ) : (
          <Typography
            key={index}
            style={{ color: Colors.textNote }}
            children={item.name}
          />
        )
      )}
    </Wrapper>
  );
}

export default BreadCrumbs;
