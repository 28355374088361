import { addTranslation } from '../../../../_helpers/Translate';

addTranslation({
  ru: {
    kindergarten_counter: "Всего <br/> детских<br/> садов",
    kindergarten_counter_err: "Ошибка запроса количества заявок",
    kindergarten_counter_state: "Гос садики",
    kindergarten_counter_private: "Частные садики",
  },
  kk: {
    kindergarten_counter: "Барлық <br/>мектепке <br/>дейін ұйымдар",
    kindergarten_counter_err: "Ошибка запроса количества заявок",
    kindergarten_counter_state: "Мемлекеттік ұйымдар",
    kindergarten_counter_private: "Жеке ұйымдар",
  }
});
