import { RENDERER } from '../Utils/Constants';
import Dict from '../Utils/Dict';
import { DomainData } from '../Utils/DomainData';

// добавляем версию элемента справочника
export async function valueVersion(question, value, savedVersion) {
  const { checkbox_group, multiple_dropdown_tree, dropdown, dropdown_tree } = RENDERER;
  if (question.renderer === dropdown) {
    let option = { version: savedVersion };
    await DomainData.load(question.lookupId, options => {
      if (options instanceof Array) {
        option = options.find(item => item.code === value) || option;
      } else if (options instanceof Object) {
        option = options[value] || option;
      }
    });
    return { value, version: option.version };
  }
  if (question.renderer === dropdown_tree) {
    let item = await Dict.item(question.lookupId, value);
    return { value, version: item.version || savedVersion };
  }
  if ([checkbox_group, multiple_dropdown_tree].includes(question.renderer)) {
    let version = [];
    for (const valueItem of value) {
      let option = {};
      await DomainData.load(question.lookupId, options => {
        if (options instanceof Array) {
          option = options.find(item => item.code === valueItem) || {};
        } else if (options instanceof Object) {
          option = options[valueItem] || {};
        }
        version.push(option.version);
      });
    }
    return { value, version };
  }
  return value;
}
