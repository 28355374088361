import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Row from '../Row';
import withFlc from '../flc/withFlc';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { flcHasValue } from '../../Utils/flcHasValue';
import withTranslation from '../../Components/translation/withTranslation';
import { changedStyle } from '../../PassportStyle';

function DRadio(props) {
  const { value, onChange, changed, disabled, lang, t, withoutLabel, ...rest } = props;
  const style = flcHasValue(value) && changed ? changedStyle : undefined;

  return (
    <Row {...props}>
      <FormControl
        error={!!rest.error}
        fullWidth
        style={withoutLabel ? { paddingLeft: 14 } : undefined}
      >
        <RadioGroup
          row
          value={flcHasValue(value) ? Boolean(value) : ''}
          onChange={(event, value) => onChange(value === 'true')}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" size="small" />}
            label={<span style={style}>{t('passport_dRadioYes')}</span>}
            labelPlacement="end"
            disabled={disabled}
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" size="small" />}
            label={<span style={style}>{t('passport_dRadioNo')}</span>}
            labelPlacement="end"
            disabled={disabled}
          />
        </RadioGroup>
        {rest.error && <FormHelperText>{rest.error}</FormHelperText>}
      </FormControl>
    </Row>
  );
}

export default withFlc(withTranslation(DRadio));
