import styled from 'styled-components';
import Colors from '../../components/ThemeProvider/Colors';
import Button from '../../modules/Button/Button';

export const PassportLeftInfo = styled.div`
  margin-right: 10px;
  width: 350px;
  & > .card {
    border-radius: 4px !important;
  }
`;

export const ServiceProviderContainer = styled.div`
  padding: 10px 16px;
  min-height: 35px !important;
  border: 1px solid #cecfd2;
  border-radius: 4px;
  display: flex;
  margin-bottom: 15px;
  
  & > .flex {
    width: 100%;
  }
  
  #required {
    color: ${Colors.secondary};
  }
  
  #sp-button {
    padding-left: 16px;
    
    button {
      color: grey;
      border-color: ${({error}) => error && '#f44336 !important'};
    }
    
    #error {
      color: #f44336;
      font-size: 11px;
    }
  }
`;

export const StyledGBDFLButton = styled(Button)`
  white-space: nowrap !important;
  margin: 2px 0 2px 5px !important; 
  min-width: 150px !important;
  font-size: 11px !important;
  height: 36px !important;
`;

export const SaveButton = styled.div`
  float: right;
  margin-top: 20px;
  
  button {
    width: 150px;
    height: 50px;
    font-size: 14px;
    background: #66bb6a;
  }
  
  button:hover {
    background: #58965b;
  }
`;

export const StyledPassportContainer = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    & > div, & > div > div {
      margin-right: 0 !important;
      width: 100% !important;
    }
    & > .flex-auto {
      margin-top: 20px;
    }
  }
`;

export const StyledProviderContainer = styled.div`
  overflow-x: auto;
  & > main > div {
    height: auto !important;
  }
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 99999;
  box-shadow: 0 0 15px #eaeaea;
  border-radius: 50%;
`;