import React, { useEffect } from 'react';
import { changeFormValue } from '../../../passport/Passport';
import { useSelector } from 'react-redux';
import { accountSettingsModule } from './../AccountSettingsDucks';

function InfoPassport({code, value}) {
  const person = useSelector(state => state[accountSettingsModule].person);
  useEffect(() => {
    if (code === 'requester_short.house') {
      if (value) {
        return changeFormValue(code, value)
      } else {
        return changeFormValue('requester_short.house', person?.house_number)
      }
    }
    else if (code === 'requester_short.flat') {
      if (value) {
        return changeFormValue(code, value)
      } else {
        return changeFormValue(code, person?.flat)
      }
    } else if (code === 'requester_short.city') {
      if (value) {
        return changeFormValue(code, value)
      } else {
        return changeFormValue(code, person?.locality)
      }
    } else if (code === 'requester_short.street' && value) {
      if (value) {
        return changeFormValue(code, value)
      } else {
        return changeFormValue(code, person?.street)
      }
    }
  }, [value])
 
  return null;
}

export default InfoPassport;