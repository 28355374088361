import styled from 'styled-components';
import { Color } from '../../ThemeProvider/ThemeStyle';

export const Document = styled.div`
  .MuiInputBase-root.Mui-disabled {
    background-color: transparent !important;
  }
  .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${() => Color.border} !important;
  }
  fieldset {
    display: ${props => (props.inTable ? 'none !important' : 'inherit')};
  }
`;
