import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Page403 from '../pages/Page403/Page403';
import paths from '../_helpers/paths';
import PageLoader from '../modules/PageLoader/PageLoader';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (rest.withoutAuth && !rest.loading) {
          return (
            <Component {...routeProps} />
          );
        }

        if (rest.loading) {
          return <PageLoader/>;
        }

        // перенаправляем на страницу логина если не авторизован
        if (!rest.withoutAuth && rest.user === null) {
          return <Redirect to={paths.homePage}/>;
        }

        // проверяем доступ к странице
        if (
          rest.permissions &&
          rest.userPermissions &&
          !rest.userPermissions.some(item => rest.permissions.includes(item))
        ) {
          return <Page403/>;
        }

        return (
          <Component {...routeProps} />
        );
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
  path: PropTypes.string,
  withoutAuth: PropTypes.bool,
  permissions: PropTypes.array,
  userPermissions: PropTypes.array
};

export default ProtectedRoute;
