import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import PassportTranslate from '../passport/PassportTranslate';

const resources = {
  ru: {
    translation: {
      ...PassportTranslate.ru
    }
  },
  kk: {
    translation: {
      ...PassportTranslate.kk
    }
  }
};

let language = 'ru';
if (window.localStorage.getItem('lang')) {
  language = window.localStorage.getItem('lang');
}
i18next.use(initReactI18next).init({ resources, lng: language });

i18next.init({
  interpolation: {
    escapeValue: false,
  }
});

export function translation(code) {
  return i18next.t(code);
}

export const addTranslation = translations => {
  for (const lang of Object.keys(translations)) {
    i18next.addResources(lang, 'translation', translations[lang]);
  }
};
