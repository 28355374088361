import styled from 'styled-components';

export const StyledSearch = styled.div`
  margin-top: 15px;
  .search_icon{
    fill: green;
    background-color: rgba(0,0,0,0.1);
    border-radius: 50%;
    padding: 3px;
    width: 30px;
    height: 30px;
  }
  .search_box{
    box-shadow: 0px 1px 5px 5px rgb(119 111 196 / 25%);
  }
  .search_box input{
    color: black;
  }
`;
