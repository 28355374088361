import localforage from 'localforage';
import moment from 'moment';
import axios from 'axios';

const metaData = localforage.createInstance({ name: 'metaData' });

export async function metaCaching(url) {
  let dict = await metaData.getItem(url);
  if (dict === null) {
    let response = await axios.get(url);
    await metaData.setItem(url, {
      data: response.data,
      lastRequest: moment().format('DD.MM.YYYY HH:mm')
    });
    return response;
  } else {
    let lastRequest = moment(dict.lastRequest, 'DD.MM.YYYY HH:mm');
    let timeUp = lastRequest.add(2, 'h').isBefore(moment());
    if (timeUp) {
      let response = await axios.get(url);
      await metaData.setItem(url, {
        data: response.data,
        lastRequest: moment().format('DD.MM.YYYY HH:mm')
      });
      return response;
    } else {
      return dict;
    }
  }
}
