import i18next from 'i18next';
import Notice from '../utils/Notice';
import './NCLayerTranslate';

export const NCALayerSign = (doc, isAuth, method, withCDATA = true) =>
  new Promise(resolve => {
    const socket = new WebSocket('wss://127.0.0.1:13579/');

    socket.onmessage = event => {
      const result = JSON.parse(event.data);
      console.log(result);
      if (result.message === 'action.canceled') {
        resolve(null);
        socket.close();
      }
      if (result['message'] === 'storage.empty') {
        resolve(null);
        socket.close();
        Notice.error(i18next.t('ncaLayer_storageEmpty'));
      }

      if (result.status === true) {
        let res = result?.body?.result?.length > 0 ? result?.body?.result[0] : null;
        resolve(res);
        socket.close();
      }
    };

    socket.onclose = event => {
      if (!event.wasClean) {
        resolve(null);
        Notice.error(i18next.t('ncaLayer_switched_off'));
      }
    };

    socket.onopen = () => {
      let xmlToSign = `<?xml version="1.0" encoding="UTF-8"?>
        <rootnote>
          <ds:Data xmlns:ds="http://www.w3.org/2000/09/xmldsig#">
            <![CDATA[
               ${doc}
            ]]>
          </ds:Data>
        </rootnote>`;

      const signInfo = {
        module: 'kz.gov.pki.knca.basics',
        method: 'sign',
        args: {
          allowedStorages: method,
          format: 'xml',
          data: withCDATA ? xmlToSign : doc,
          signingParams: {
            decode: false,
            encapsulate: true,
            digested: false,
            tsaProfile: {}
          },
          signerParams: {
            extKeyUsageOids: [],
            chain: []
          },
          locale: localStorage.getItem('lang')
        }
      };

      socket.send(JSON.stringify(signInfo));
    };
  });
