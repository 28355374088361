import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    bulletin_op_subtitle: 'Бюллетень свободных мест',
    bulletin_op_table_do_name: 'Наименование ДО:',
    bulletin_op_table_age_group: 'Возрастная группа:',
    bulletin_op_table_group_name: 'Название группы:',
    bulletin_op_table_academic_year: 'Учебный год:',
    bulletin_op_table_periodBirthChildren: 'Период рождения детей:',
    bulletin_op_table_queue_type: 'Тип очереди:',
    bulletin_op_table_address: 'Адрес:',
    bulletin_op_table_type: 'Вид свободного места:',
    bulletin_op_table_lang: 'Язык обучения:',
    bulletin_op_table_published: 'Опубликовано свободных мест:',
    bulletin_op_table_avaiable: 'Доступно свободных мест:',
    bulletin_op_table_date: 'Дата публикации:',
    bulletin_op_table_priority_date: 'Приоритетный доступ до:',
    bulletin_op_table_type_ownership: 'Форма собственности:',
    bulletin_op_warning_title: 'Получение направления на зачисление:',
    bulletin_op_warning_msg_before: 'При выдаче направления возраст ребенка учитывается по состоянию полных лет на 1 сентября текущего учебного года.\n' +
      'Для того, чтобы получить направление в дошкольную организацию и откликнуться на бюллетень Вам необходимо авторизоваться. После этого:',
    bulletin_op_warning_msg_after: 'Советуем обратить внимание, что бюллетени бывают в общем и приоритетном доступах. Приоритетный доступ получить направление доступен заявкам, чья очередь уже подошла. Через 3 (три) рабочих дня бюллетень выходит в общий доступ.',
    bulletin_op_warning_items: ['1. Зайдите в личный кабинет и нажмите кнопку "Получить направление";',
      '2. Система покажет все дошкольные организации, в которых есть группы, подходящие вашему ребенку по населенному пункту, возрасту и по типу бюллетени;',
      '3. Изучите список свободных мест, выберите дошкольную организацию и группу для зачисления;',
      '4. Нажмите кнопку "Получить направление"'],
    bulletin_op_get_appointment: 'Получить направление',
    bulletin_op_get_appointment_modal_body:
      'Вы действительно хотите получить направление в дошкольную организацию {{name}}, язык обучения {{language}}, группа {{group_name}}, адрес {{address}}',
    bulletin_op_get_appointment_modal_action_yes: 'Да, подписать',
    bulletin_op_get_appointment_modal_action_no: 'Нет',
    bulletin_op_get_appointment_error: 'Не удалось получить направление',
    bulletin_op_success_appointment_modal_title: 'Поздравляем!!!',
    bulletin_op_success_appointment_modal_body: 'Вы получили направление №{{id}} в дошкольную организацию {{ddo}}. Вам необходимо в течение 5 рабочих дней зачислить ребенка в дошкольную организацию.',
    bulletin_op_success_appointment_action_goTo: 'Перейти',
    bulletin_op_places_over: 'В данный момент свободных мест нет.'
  },
  kk: {
    bulletin_op_subtitle: 'Бос орындар бюллетені',
    bulletin_op_table_do_name: 'Ұйым атауы:',
    bulletin_op_table_age_group: 'Жас тобы:',
    bulletin_op_table_group_name: 'Топтың атауы:',
    bulletin_op_table_queue_type: 'Кезек түрі:',
    bulletin_op_table_address: 'Мекен-жайы:',
    bulletin_op_table_type: 'Бос орын түрі:',
    bulletin_op_table_lang: 'Оқытылу тілі:',
    bulletin_op_table_published: 'Жарияланған бос орындар саны:',
    bulletin_op_table_avaiable: 'Қолжетімді бос орындар саны:',
    bulletin_op_table_date: 'Жарияланған күні:',
    bulletin_op_table_priority_date: 'Приоритетті/ мәртебелі қолжетімділік дейін:',
    bulletin_op_table_type_ownership: 'Меншік нысаны:',
    bulletin_op_table_academic_year: 'Оқу жылы:',
    bulletin_op_table_periodBirthChildren: 'Балалардың туылу кезеңі:',
    bulletin_op_warning_title: 'Мектепке дейінгі жолдама алу:',
    bulletin_op_warning_msg_before: 'Жолдама беру кезінде жас тобын анықтау үшін баланың жасы ағымдағы күнтізбелік жыл бойынша толық жасы  есептеледі. \n' +
      '\n' +
      'Мектепке дейінгі ұйымға жолдама алу және бюллетеньге өтініш жіберу үшін жүйеге кіру қажет. Осыдан кейін:',
    bulletin_op_warning_msg_after: 'Бюллетеньдердің мәртебелі қолжетімділікте және барлығына қолжетімді болатынына назар аударуды ұсынамыз. \n' +
      'Мәртебелі қолжетімділіктегі бюллетеньдер бойынша жолдаманы тек кезегі келген өтініш берушілер ала алады. 3 (үш) жұмыс күннен соң бюллетень барлығына қолжетімді болады.',
    bulletin_op_warning_items:[
      '1. Жеке кабинетке өтіп, «Жолдама алу» түймесін басыңыз;',
      '2. Жүйе сіздің балаңыздың орналасқан жері, жасы және бюллетень түрі бойынша сәйкес келетін топтары бар барлық мектепке дейінгі мекемелерді көрсетеді;',
      '3. Бос орындар тізімін зерттеңіз, мектепке дейінгі ұйымды және қабылдау тобын таңдаңыз;',
      '4. "Жолдама алу" түймесін басыңыз.'],
    bulletin_op_get_appointment: 'Жолдама алу',
    bulletin_op_get_appointment_modal_body: 'Сіз, шынымен {{name}} мектепке дейінгі ұйымға, оқытылу тілі {{language}}, {{group_name}} тобына, мекен-жайы {{address}} жолдама алғыңыз келеді ме?',
    bulletin_op_get_appointment_modal_action_yes: 'Иә, қол қою',
    bulletin_op_get_appointment_modal_action_no: 'Жоқ',
    bulletin_op_get_appointment_error: 'Не удалось получить направление',
    bulletin_op_success_appointment_modal_title: 'Құттықтаймыз!!!',
    bulletin_op_success_appointment_modal_body: 'Сіз мектепке дейінгі ұйымға {{ddo}} жолдама нөмірін {{id}} алдыңыз. Сізге 5 жұмыс күні ішінде балаңызды мектепке дейінгі ұйымға тіркеу керек.',
    bulletin_op_success_appointment_action_goTo: 'Өту',
    bulletin_op_places_over: 'Қазіргі мезетте бос орындар жоқ.'
  }
});
