import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    requests_title: 'Заявки в обработке',
    requests_serviceName: 'Наименование услуги',
    requests_requestDate: 'Дата подачи',
    requests_responseData: 'Дата обработки',
    requests_decision: 'Решение',
    requests_status: 'Статус',
    requests_notifications: 'Выходная форма',
    downloadReportForm_failed: 'Не удалось скачать уведомление',
  },
  kk: {
    requests_title: 'Өңдеудегі өтінімдер',
    requests_serviceName: 'Қызметтің атауы',
    requests_requestDate: 'Берілген күні',
    requests_responseData: 'Дата обработки',
    requests_decision: 'Решение',
    requests_status: 'Статус',
    requests_notifications: 'Выходная форма',
    downloadReportForm_failed: 'Не удалось скачать уведомление',
  }
});