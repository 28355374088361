import React, { useEffect, useState, useCallback } from 'react';
import Form from '../../FormData/PassportForm';
import Trigger from '../../FormData/ChangeTrigger';
import { runFlc } from '../../Flc/execute/runFlc';
import { TestMode } from '../../Flc/TestMode';
import FlcTrigger from '../../FormData/FlcTrigger';

function withGroupFlc(Component) {
  /**
   * @return {null}
   */
  function WithGroupFlc({ dependency: deps, groupHided, ...rest }) {
    const code = rest.code;
    const qCodes = [...rest.subGroups.map(group => group.code), ...rest.questionCodes];
    const [hide, setHide] = useState(false);
    const [flc, setFlc] = useState({ visibility: true });
    const handleFlc = useCallback(
      () => {
        let newFlc = runFlc(rest);
        if (JSON.stringify(newFlc) !== JSON.stringify(flc)) {
          setFlc(newFlc);
        }
      },
      // eslint-disable-next-line
      [flc]
    );

    useEffect(handleFlc, []);
    useEffect(() => Form.setFlc(code, { ...flc, hided: hide }), [code, flc, hide]);
    useEffect(() => Trigger.subscribe(deps, code, handleFlc), [deps, code, handleFlc]);
    useEffect(() => TestMode.register(code, handleFlc), [code, handleFlc]);

    useEffect(
      () => {
        setHide(Form.groupHide(qCodes));
        return FlcTrigger.subscribe(qCodes, code, () => {
          setHide(Form.groupHide(qCodes));
        });
      },
      [code, qCodes]
    );

    return (
      <Component
        {...rest}
        hide={hide || !flc.visibility}
        hideMode={rest.hideMode || !flc.visibility}
        groupHided={Boolean(!flc.visibility || groupHided)}
      />
    );
  }
  return WithGroupFlc;
}

export default withGroupFlc;
