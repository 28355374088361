import Dict from '../../../../modules/utils/Dict';
import { createReducer } from 'redux-starter-kit';
import Notice from '../../../../modules/utils/Notice';
import { BulletinFiltersApi } from './FiltersApi';
import axios from 'axios';

/**
 * Constants
 */

export const bulletinFilterModule = 'bulletinFilterModule';
const SET_DATA = `${bulletinFilterModule}/SET_DATA`;
const LOADING = `${bulletinFilterModule}/LOADING`;
const SET_KINDERGARTENS = `${bulletinFilterModule}/SET_KINDERGARTENS`;
let cancelSource = null;
/**
 * Reducer
 */

const initialState = {
  loading: false,
  dicts: {
    languages: [],
    ownerships: [],
    access_types: [],
    age_groups: [],
  },
  kindergartens: [],
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.dicts.languages = action.languages;
    state.dicts.ownerships = action.ownerships;
    state.dicts.access_types = action.access_types;
    state.dicts.age_groups = action.age_groups;
  },
  [SET_KINDERGARTENS]: (state, action) => {
    state.kindergartens = action.kindergartens;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
});

export const getDicts = (t) => async dispatch => {
  let languages = await Dict.items('gu_language');
  languages = languages.filter(item => filterLangages(item.code));
  let ownerships = await Dict.items('gu_form_ownership');
  ownerships = ownerships.filter(item => filterOwnership(item.code));
  let access_types = await Dict.items('gu_bulletin_status_web');
  access_types = access_types.filter(item => filterAccessTypes(item.code));
  let age_groups = await Dict.items('gu_age_group');
  age_groups = age_groups.filter(i => i.View === 'true')
  dispatch({ type: SET_DATA, languages: languages, ownerships: ownerships,
      access_types: access_types, age_groups: age_groups });
}

export const getKindergartens = (t, filter) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, loading: true });
    if(cancelSource){
      cancelSource.cancel();
    }
    cancelSource = axios.CancelToken.source();
    const response = await BulletinFiltersApi.getKindergartens(filter, cancelSource);
    if(!response){
      return
    }
    let kindergartens = [];
    if(response.data.content){
      kindergartens = getKeyValues(response.data.content);
      kindergartens.map(item => {
        item.code = item.id;
        return item;
      })
      dispatch({ type: SET_KINDERGARTENS, kindergartens: kindergartens });
    }else{
      Notice.error(t('bulletin_op_filters_kindergartens_err'));
    }
  }catch (e) {
    console.log(e);
    Notice.error(t('bulletin_op_filters_kindergartens_err'));
  }finally {
    dispatch({ type: LOADING, loading: false });
  }
}

const getKeyValues = (data) => {
  let res = [];
  data.forEach(item => {
    let obj = {}
    item.passportValues.forEach(a => {
      obj[a.code] = a.value;
    });
    res.push(obj);
  });
  return res;
}

const filterOwnership = (code) => {
  switch (code) {
    case 'state':
    case 'private':
      return true;
    default:
      return false;
  }
}

const filterLangages = (code) => {
  switch (code) {
    case '01':
    case '02':
      return true;
    default:
      return false;
  }
}

const filterAccessTypes = (code) => {
  switch (code) {
    case 'AVAILABLE_PRIORITY':
    case 'AVAILABLE_ALL':
      return true;
    default:
      return false;
  }
}
