import axios from 'axios';

export const KindergartenNotificationsApi = {
  getApplications: filter =>
    axios.get(`/api/public/notification-message/self/info/paging?filter=${filter}`),
  getNotificationsCount: () =>
    axios.get('/api/public/notification-message/self/info/count?filter={"isRead":false}'),
  markAsRead: filter =>
    axios.put('/api/public/notification-message/read', {}, {params: filter}),
};
