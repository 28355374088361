class FormChange {
  static subscribers = {};

  // подписка на изменения
  static subscribe(code, callback, cellConfig) {
    if (!cellConfig) {
      this.subscribers[code] = callback;
      return () => {
        delete this.subscribers[code];
      };
    } else if (cellConfig && cellConfig.isComplexCell) {
      this.subscribers[code] = callback;
      return () => {
        delete this.subscribers[code];
      };
    }
  }

  static change(questionCode, value = '', source = {}) {
    if (questionCode instanceof Object && questionCode.complexCode) {
      const code = `${questionCode.code}_${questionCode.complexCode}_${questionCode.questionCode}`;
      FormChange.subscribers[code](value, source);
    } else if (FormChange.subscribers.hasOwnProperty(questionCode)) {
      FormChange.subscribers[questionCode](value, source);
    }
  }

  static changeComplex(questionCode, complexCodes, cellsValue = {}, cellsSource = {}) {
    if (FormChange.subscribers.hasOwnProperty(questionCode)) {
      FormChange.subscribers[questionCode](complexCodes, cellsValue, cellsSource);
    }
  }

  static changeTable(questionCode, cellsValue = {}, source = {}) {
    if (FormChange.subscribers.hasOwnProperty(questionCode)) {
      FormChange.subscribers[questionCode](cellsValue, source);
    }
  }
}
export default FormChange;
