import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { serviceProvidersColumns } from './ServiceProvidersColumns';
import {
  serviceProvidersModule,
  loadTableData,
  clearState
} from './ServiceProvidersDucks';
import { Table } from './ServiceProvidersModalStyles';
import useTableFilter from '../../../components/TableFilter/useTableFilter';
import TableFilterProvider from '../../../components/TableFilter/TableFilterProvider';

const ServiceProvidersTable = ({ index, tableData, loadTableData, loading, clearState, ...rest }) => {
  const { t, i18n } = useTranslation();
  const filter = useTableFilter(loadTableData, index);
  useEffect(() => clearState, [clearState]);

  return (
    <TableFilterProvider index={filter.index}>
      <Table
        loading={loading}
        columns={serviceProvidersColumns(
          t,
          i18n.language
        )}
        data={tableData.content}
        totalElements={tableData.totalElements}
        onClickLoadMore={filter.nextPage}
        {...rest}
      />
    </TableFilterProvider>
  );
};

export default connect(
  state => ({
    loading: state[serviceProvidersModule].loading,
    tableData: state[serviceProvidersModule].tableData
  }),
  {
    loadTableData,
    clearState
  }
)(ServiceProvidersTable);
