import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import green from '@material-ui/core/colors/green';
import InfoIcon from '@material-ui/icons/Info';
import { handleQuestionLabel } from '../Utils/handleQuestionLabel';

const Wrapper = styled.div`
  .col-5,
  .col-7 {
    position: initial !important;
    min-height: 30px;
  }
`;

const GreenCircle = styled.div`
  width: 10px;
  min-width: 10px;
  height: 10px;
  min-height: 10px;
  background-color: ${green[200]};
  border-radius: 50%;
`;

function Row(props) {
  const { children, changed, withoutLabel, source, lang } = props;
  if (withoutLabel) {
    return (
      <div className="flex relative items-center">
        {source && source.loaded && source[`${lang}_name`] && (
          <div className="pl1">
            <Tooltip title={source[`${lang}_name`]}>
              <InfoIcon style={{ color: green[200] }} fontSize="small" />
            </Tooltip>
          </div>
        )}
        <div className="fullwidth">{children}</div>
      </div>
    );
  } else {
    return (
      <Wrapper className="flex items-center relative">
        <div className="col-5 line-height-1 py1 px0 flex items-center justify-between">
          {handleQuestionLabel(props, lang)}
          {changed && (
            <Tooltip title="Значение изменено">
              <GreenCircle />
            </Tooltip>
          )}
        </div>
        <div className="col-7 p0 pl2">{children}</div>
      </Wrapper>
    );
  }
}

export default Row;
