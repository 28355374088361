import React, { useEffect, useState } from 'react';
import LoadedTrigger from './FormData/LoadedStatusTrigger';
import Form from './FormData/PassportForm';

/**
 * @return {null}
 */
function PassportButtons({ mode, groupId, groups, questionCodes, children }) {
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(mode !== 'cards');

  // это нужно чтобы скрывать кнопку на странице разделов
  useEffect(
    () => {
      if (mode === 'cards') {
        let result = showButtons(groupId, groups);
        if (show !== result) {
          setShow(result);
        }
      }
    },
    // eslint-disable-next-line
    [groupId]
  );

  useEffect(
    () => {
      if (mode === 'questions') {
        LoadedTrigger.subscribeQMode(questionCodes, setLoaded);
      }
    },
    // eslint-disable-next-line
    [questionCodes]
  );

  useEffect(
    () => {
      if (mode !== 'questions') {
        LoadedTrigger.subscribeCGMode(groupId, groups, setLoaded);
      }
    },
    // eslint-disable-next-line
    [groupId, groups]
  );

  useEffect(
    () => () => {
      Form.clear();
      LoadedTrigger.clear();
    },
    []
  );

  if (!show || !children) return null;
  return (
    <>
      {children(!loaded)}
      <button className="display-none save-button-for-omentos" />
    </>
  );
}

function showButtons(groupId, groups = []) {
  for (const group of groups) {
    if (group.id === groupId) {
      return !!(group.questionCodes && group.questionCodes.length);
    }
    if (group.subGroups && group.subGroups.length) {
      let show = showButtons(groupId, group.subGroups);
      if (show) return true;
    }
  }
  return false;
}

export default PassportButtons;
