import moment from 'moment';
import React from 'react';

export const requestsColumns = ({ t, lang }) => {
  return [
    {
      Cell: (row) => {
        let priority = '';
        switch (row.original.priorityQueued) {
          case('VO'):
            priority = t('queue_applications_vo');
            break;
          case('PO'):
            priority = t('queue_applications_po');
            break;
          case('OO'):
            priority = t('queue_applications_oo');
            break;
          default:
        }
        return <div>
          {t('check_queue_number_table_n')} {row.original.requestFormId}<br/>
          {t('check_queue_number_table_fio')} {row.original.childSurname} {row.original.childName} {row.original.childMiddleName}<br/>
          {t('check_queue_number_table_queue_number')} {row.original.position}<br/>
          {t('check_queue_number_table_queue_type')} {row.original.queuedType}<br/>
          {t('check_queue_number_table_priority')} {priority}<br/>
          {t('check_queue_number_table_app_date')} {moment(row.original.submissionDate).format('DD.MM.YYYY HH:mm:ss')}<br/>
          {t('check_queue_number_table_region')} {row.original.providerLocation[`${lang}_name`]}
              </div>;
      }
    },
  ];
};
