import axios from 'axios';

export const BulletinFiltersApi = {
  getKindergartens: (filter, cancelSource) => axios.get(
    `/api/public/data/service_provider_with_pre_school_graduation_with_filters`, {params: filter,
      cancelToken: cancelSource.token},
  ).catch(function (thrown) {
    if (axios.isCancel(thrown)) {
      console.log('Request canceled', thrown.message);
    } else {
      // handle error
    }
  }),
};
