import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { StyledSearch } from './SearchStyle';
import { useTranslation } from 'react-i18next';
import './SearchTranslate';
import { loadApplications } from '../../QueueApplicationsDucks';
import { useDispatch } from 'react-redux';

export default function Search({search, setSearch, filter}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchData = () => {
    dispatch(loadApplications(t,{ ...filter, action: 'filter' }, search));
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      searchData();
    }
  }

  return (
    <StyledSearch>
      <Paper
        className="search_box"
        component="form"
        style={{ padding: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <InputBase
          style={{ marginLeft: '1', flex: '1' }}
          placeholder={t('queue_applications_search_label')}
          inputProps={{ 'aria-label': t('queue_applications_search_label') }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={e => handleKeyDown(e)}
        />
        <IconButton aria-label="search" onClick={() => searchData()}>
          <SearchIcon className="search_icon"/>
        </IconButton>
        <Divider style={{ height: '28', margin: '0.5' }} orientation="vertical" />
      </Paper>
    </StyledSearch>
  );
}
