import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import Root from './Root';
import { store } from '../_helpers/store';
import { history } from '../_helpers/history';
import AppErrorMessage from '../components/AppErrorMessage';
import ThemeProvider from '../components/ThemeProvider/ThemeProvider';
import DatePickerProvider from '../modules/DatePickerProvider/DatePickerProvider';
import '../_helpers/Translate';
import '../components/commonTranslations';
import LocaleProvider from './LocaleProvider';

export default function App() {
  return (
    <Provider store={store}>
      <LocaleProvider>
      <Router history={history}>
        <ThemeProvider>
          <DatePickerProvider>
            <AppErrorMessage>
              <Root/>
            </AppErrorMessage>
          </DatePickerProvider>
        </ThemeProvider>
      </Router>
      </LocaleProvider>
    </Provider>
  );
}
