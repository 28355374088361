import Dict from '../../../../modules/utils/Dict';
import { createReducer } from 'redux-starter-kit';

/**
 * Constants
 */

export const kindergartenReestrFilterModule = 'kindergartenReestrFilterModule';
const SET_DATA = `${kindergartenReestrFilterModule}/SET_DATA`;

/**
 * Reducer
 */

const initialState = {
  kindergarten_types: [],
  languages: [],
  ownerships: [],
  age_groups: [],
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.kindergarten_types = action.kindergarten_types;
    state.languages = action.languages;
    state.ownerships = action.ownerships;
    state.age_groups = action.age_groups
  },
});

export const getDicts = (setAgeGroups, ageGroups) => async dispatch => {
  let kindergarten_types = await Dict.items('230');
  kindergarten_types = kindergarten_types.filter(item => filterTypes(item.code));
  let languages = await Dict.items('gu_language');
  languages = languages.filter(item => filterLangages(item.code));
  let ownerships = await Dict.items('gu_form_ownership');
  ownerships = ownerships.filter(item => filterOwnership(item.code));
  let age_groups = await Dict.items('gu_age_group');
  const age_groups_array = ageGroups ? ageGroups.split(",") : [];
  let new_options = JSON.parse(JSON.stringify(age_groups));
  new_options.map(item => {
    if(age_groups_array.includes(item.code)){
      item['checked'] = true;
    }else{
      item['checked'] = false;
    }
    return item;
  });
  setAgeGroups(new_options);

  dispatch({ type: SET_DATA, kindergarten_types: kindergarten_types,
    languages: languages, ownerships: ownerships, age_groups: age_groups});
}

const filterOwnership = (code) => {
  switch (code) {
    case 'state':
    case 'private':
      return true;
    default:
      return false;
  }
}

const filterLangages = (code) => {
  switch (code) {
    case '01':
    case '02':
      return true;
    default:
      return false;
  }
}

const filterTypes = (code) => {
  switch (code) {
    case '01.1':
    case '01.2':
    case '01.3':
    case '01.4':
    case '01.5':
    case '01.6':
    case '08.1':
    case '08.2':
      return true;
    default:
      return false;
  }
}
