import { handleError } from './Utils/handleError';
import { handlePassport } from './HandlePassport/handlePassport';
import Form from './FormData/PassportForm';

/**
 * Constants
 */

export const passportModule = 'passport-meta';
const LOADING = `${passportModule}/LOADING`;
const SET_GROUPS = `${passportModule}/SET_GROUPS`;
const SET_QUESTIONS = `${passportModule}/SET_QUESTIONS`;
const SET_ERROR = `${passportModule}/SET_ERROR`;
const SET_METADATAKEYS = `${passportModule}/SET_METADATAKEYS`;

/**
 * Reducer
 */

export const initialState = {
  loading: true,
  error: null,
  metadataKeys: [],
  questions: {},
  groups: []
};

export function reducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case LOADING:
      return { ...state, loading: payload };
    case SET_GROUPS:
      return { ...state, groups: payload };
    case SET_QUESTIONS:
      return { ...state, questions: payload };
    case SET_METADATAKEYS:
      return { ...state, metadataKeys: payload };
    case SET_ERROR:
      return { ...state, error: payload };
    default:
      return state;
  }
}

/**
 * Action Creators
 */
export const initPassport = (dataList, excludeMetaKeys = []) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const passport = await handlePassport(dataList, excludeMetaKeys);
    dispatch({ type: SET_GROUPS, payload: passport.groups });
    dispatch({ type: SET_QUESTIONS, payload: passport.questions });
    dispatch({ type: SET_METADATAKEYS, payload: passport.metadataKeys });
    Form.initQuestions(
      passport.questions,
      passport.groups,
      passport.metadataKeys,
      passport.saveWithError
    );
  } catch (e) {
    let msg = handleError(e, 'Error: initialize flc', false);
    dispatch({ type: SET_ERROR, payload: msg });
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};
