import { flcHasValue } from '../Utils/flcHasValue';
import { valueVersion } from './valueVersion';
import { buildChange } from './buildChange';
import { RENDERER } from '../Utils/Constants';
import { getDocumentChange } from './getDocumentChange';

export async function getComplexCellsChanges(question, cellsValue, savedCellsValue, versions) {
  let changes = [];
  for (const cQuestion of Object.values(question.cellsQuestions)) {
    let addedRows = Object.keys(cellsValue).filter(
      complexCode => !savedCellsValue.hasOwnProperty(complexCode)
    );
    for (const complexCode of addedRows) {
      const cellValue = cellsValue[complexCode][cQuestion.code];
      if (flcHasValue(cellValue)) {
        if (cQuestion.renderer === RENDERER.document) {
          const documentChanges = getDocumentChange(cQuestion, cellValue);
          changes = [
            ...changes,
            ...documentChanges.map(change => {
              change.complexValueId = complexCode;
              return change;
            })
          ];
        } else {
          let value = await valueVersion(cQuestion, cellValue);
          changes.push(
            buildChange(
              { ...cQuestion, relatedBusinessObjectId: question.relatedBusinessObjectId },
              value,
              complexCode
            )
          );
        }
      }
    }

    let rows = Object.keys(cellsValue).filter(complexCode =>
      savedCellsValue.hasOwnProperty(complexCode)
    );
    for (const complexCode of rows) {
      const cellCode = `${question.code}_${complexCode}_${cQuestion.code}`;
      const cellValue = cellsValue[complexCode][cQuestion.code];
      const savedCellValue = savedCellsValue[complexCode][cQuestion.code];
      const version = versions[cellCode];
      const savedVersion = question.cellsSavedVersion[complexCode][cQuestion.code];

      if (cellValue !== savedCellValue || version !== savedVersion) {
        if (cQuestion.renderer === RENDERER.document) {
          const documentChanges = getDocumentChange(cQuestion, cellValue, savedCellValue);
          changes = [
            ...changes,
            ...documentChanges.map(change => {
              change.complexValueId = complexCode;
              return change;
            })
          ];
        } else {
          let value = await valueVersion(cQuestion, cellValue, savedVersion);
          changes.push(
            buildChange(
              { ...cQuestion, relatedBusinessObjectId: question.relatedBusinessObjectId },
              value,
              complexCode
            )
          );
        }
      }
    }
  }

  return changes;
}
