import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    selectDDOModal_title: 'Выбор организаций дошкольного образования',
    selectDDOModal_close: 'Отмена',
    selectDDOModal_select: 'Выбрать',
    selectDDOModal_bin: 'БИН',
  },
  kk: {
  }
});