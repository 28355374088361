import React, { useContext, useEffect, useState } from 'react';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import { treeFind, treeToList } from './DTreeSelectUtils';
import { Menu, Option } from './DTreeSelectStyle';
import Row from '../../Row';
import useDTree from './useDTree';
import withFlc from '../../flc/withDropdownFlc';
import CloseIcon from '@material-ui/icons/Close';
import {
  TextField,
  ClickAwayListener,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { translation } from '../../translation/translation';
import { changedStyle } from '../../../PassportStyle';
import { flcHasValue } from '../../../Utils/flcHasValue';
import { QuestionRender } from '../../../PassportProvider';

const TreeSelect = props => {
  const {
    code,
    value,
    error,
    changed,
    lang,
    incorrect,
    disabled,
    lookupId,
    hardDict,
    cellConfig,
    setError
  } = props;

  const {
    tree,
    onExpand,
    onChange,
    anchorEl,
    setAnchorEl,
    loadingArr,
    loadingMenu
  } = useDTree(props);
  const questionRender = useContext(QuestionRender);
  const [searchValue, setSearchValue] = useState();

  let isIncorrect = false;
  let inputValue;
  if (value) {
    inputValue = treeFind(tree, item => item.code === value);
    if (!inputValue) {
      isIncorrect = true;
      setError(translation('flc_incorrectValue'));
      inputValue = treeFind(incorrect, item => item.code === value);
      if (!inputValue) {
        inputValue = {
          ru_name: `<${value}>`,
          kk_name: `<${value}>`,
          code: value
        };
      }
    } else {
      setError('');
    }
  } else {
    setError('');
  }

  useEffect(
    () => {
      if (!anchorEl && !hardDict) {
        setSearchValue();
      }
    },
    // eslint-disable-next-line
    [anchorEl]
  );

  useEffect(() => {
    document
      .getElementById(`tree-select-${code}`)
      .addEventListener('keydown', event => setAnchorEl(event.currentTarget));
    // eslint-disable-next-line
  }, []);

  const inputValueString =
    searchValue !== undefined
      ? searchValue
      : inputValue
      ? inputValue[`${lang}_name`]
      : '';

  return (
    <div className="flex">
      <TextField
        fullWidth
        id={`tree-select-${code}`}
        value={inputValueString}
        title={inputValueString}
        onChange={ev => !hardDict && setSearchValue(ev.target.value)}
        disabled={disabled}
        placeholder={!disabled ? props[`${lang}_placeholder`] : undefined}
        data-tree-code={`tree-select-${code}`}
        error={!!error}
        helperText={error}
        onClick={event => {
          if (!disabled) {
            setAnchorEl(event.currentTarget);
            if (anchorEl === null && value) {
              setTimeout(() => {
                let elem = document.getElementById(`ts_anchor_${value}`);
                if (elem !== null) {
                  elem.scrollIntoView({ block: 'nearest', inline: 'end' });
                }
              }, 100);
            }
          }
        }}
        InputProps={{
          style: flcHasValue(value) && changed ? changedStyle : undefined,
          endAdornment: (
            <InputAdornment position="end">
              {value && (isIncorrect || !disabled) && (
                <IconButton
                  tabIndex="-1"
                  className="show-on-hover"
                  style={{ margin: 0 }}
                  onClick={() => props.onChange('')}
                  children={<CloseIcon fontSize="small" />}
                />
              )}
              <IconButton
                tabIndex="-1"
                disabled={disabled}
                data-tree-code={code}
                style={{ padding: 2 }}
              >
                <DropDownIcon
                  color={disabled ? 'disabled' : 'action'}
                  data-tree-code={code}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <ClickAwayListener
        onClickAway={event => {
          if (
            !(
              event.target.getAttribute('data-tree-code') === code ||
              event.target.id === `tree-select-${code}`
            )
          ) {
            setAnchorEl(null);
          }
        }}
      >
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 1300 }}
        >
          {loadingMenu && (
            <div className="center">
              <CircularProgress />
            </div>
          )}
          {!loadingMenu &&
            treeToList(tree, searchValue, lang).map(item => {
              const loading = loadingArr.includes(item.code);
              const hasChild =
                (lookupId && !item.childItems) ||
                (item.childItems && item.childItems.length);
              return (
                <Option
                  key={item.code}
                  selected={item.code === value}
                  id={`ts_anchor_${item.code}`}
                  style={{ marginLeft: 30 * item.level }}
                >
                  <div className="ts-expander" onClick={() => !loading && onExpand(item)}>
                    {!!hasChild && (
                      <IconButton style={{ padding: '7px' }}>
                        {loading && <CircularProgress size={18} />}
                        {!loading && item.expanded && <DownIcon fontSize="small" />}
                        {!loading && !item.expanded && <RightIcon fontSize="small" />}
                      </IconButton>
                    )}
                  </div>
                  <div className="ts-item-label" onClick={() => onChange(item)}>
                    <span>{item[`${props.lang}_name`]}</span>
                  </div>
                </Option>
              );
            })}
        </Menu>
      </ClickAwayListener>
      {questionRender &&
        questionRender(cellConfig ? { ...cellConfig } : code, inputValue)}
    </div>
  );
};

const DTreeSelect = props => {
  const parentSelectable = props.anyTreeLevelSelectable || false;
  return (
    <Row
      {...props}
      children={<TreeSelect {...props} parentSelectable={parentSelectable} />}
    />
  );
};

DTreeSelect.defaultProps = {
  parentSelectable: true
};

export default withFlc(DTreeSelect);
