import axios from 'axios';

export const KindergartenAppointmentsApi = {
  getAppointments: filter =>
    axios.get(`/api/self/paging/direction?filter=${filter}`),
  getSignStringForRevoke: data =>
    axios.post('/api/public/transitions/info/sign_string/direction', data),
  revokeByApplicantApi: (id, data) =>
    axios.post(`/api/public/transitions/direction/${id}/revoke_by_applicant`, data),
  prolongByApplicantApi: (id, data) =>
    axios.post(`/api/public/transitions/direction/${id}/prolong_by_applicant`, data),
  downloadDirection: (id) =>
    axios.get(`/api/public/info/direction_preview/${id}`, {
      responseType: 'arraybuffer'
    })
};
