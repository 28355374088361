import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    breadcrumbs_home: 'Главная',
    breadcrumbs_users: 'Пользователи',
  },
  kk: {
    breadcrumbs_home: 'Главная',
    breadcrumbs_users: 'Пользователи',
  }
});
