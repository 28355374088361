import { getFlcRules } from '../../Utils/getFlcRules';
import { findMatches } from './findMatches';
import { RENDERER } from '../../Utils/Constants';
import { getComplexCellDependency } from './getComplexCellDependency';
import { getTableCellDependency } from './getTableCellDependency';

export function searchDependencies(questions, metadataKeys) {
  for (const question of Object.values(questions)) {
    let dependency = {};

    const findCode = code => {
      if (code.indexOf('.') === -1) {
        if (questions.hasOwnProperty(`${question.metaDataId}.${code}`)) {
          return `${question.metaDataId}.${code}`;
        } else {
          for (const metaDataKey of metadataKeys) {
            if (questions.hasOwnProperty(`${metaDataKey}.${code}`)) {
              return `${metaDataKey}.${code}`;
            }
          }
        }
      } else {
        return code;
      }
      return `notExist_${code}`;
    };

    // зависимости показателей
    for (const rule of getFlcRules(question.renderer)) {
      if (question[rule]) {
        findMatches(findCode, dependency, question[rule]);
      }
    }
    // зависимости ячеек complex_table и complex_inline таблицы
    if ([RENDERER.complex_table, RENDERER.complex_inline].includes(question.renderer)) {
      question.cellsDependencies = getComplexCellDependency(question, findCode);
    }
    // зависимости ячеек table таблицы
    if (question.renderer === RENDERER.table) {
      question.cellsDependencies = getTableCellDependency(question, findCode);
    }
    // зависимости ячеек Document
    if (question.renderer === RENDERER.document) {
      question.cellsDependencies = getComplexCellDependency(question, findCode);
    }

    if (Object.keys(dependency).length > 0) {
      question.dependency = dependency;
    }
  }
}
