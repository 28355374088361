import { addTranslation } from '../../../../_helpers/Translate';

addTranslation({
  ru: {
    queue_applications_filters_but: 'Применить',
    queue_applications_filters_clear: 'Очистить фильтр',
    queue_applications_filters_region: 'Населенный пункт',
    queue_applications_filters_type: 'Тип очереди',
    queue_applications_filters_rehab: 'Вид реабилитации/профилактики',
    queue_applications_filters_corrType: 'Тип коррекции',
    queue_applications_filters_birth_year: 'Год рождения',
    queue_applications_filters_kindergartens: 'Детский сад',
    queue_applications_filters_kindergartens_err: 'Ошибка запроса садиков',
  },
  kk: {
    queue_applications_filters_but: 'Қолдану',
    queue_applications_filters_clear: 'Сүзгіні жою',
    queue_applications_region: 'Елді мекен',
    queue_applications_filters_type: 'Кезек түрі',
    queue_applications_filters_rehab: 'Оңалту/алдын алу түрі',
    queue_applications_filters_corrType: 'Түзету түрі',
    queue_applications_filters_birth_year: 'Баланың туылған жылы',
    queue_applications_filters_kindergartens: 'Балабақша атауы',
    queue_applications_filters_kindergartens_err: 'Ошибка запроса садиков',
  }
});
