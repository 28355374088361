import Form from './PassportForm';

class LoadedStatusTrigger {
  static subscriber = null;
  static questionsStatus = {};
  static status = false;

  static subscribeQMode(questionCodes = [], callback) {
    for (const code of questionCodes) {
      if (Form.questions.hasOwnProperty(code)) {
        if (code.indexOf('v_') !== 0 && !this.questionsStatus[code]) {
          this.questionsStatus[code] = false;
        }
      }
    }
    this.subscriber = callback;
    if (callback) callback(this.status);
  }

  static subscribeCGMode(groupId, groups = [], callback) {
    groupTreeMap(groups, (id, questionCodes) => {
      for (const code of questionCodes) {
        if (code.indexOf('v_') !== 0 && Form.questions.hasOwnProperty(code)) {
          if (groupId === id || !this.questionsStatus[code]) {
            this.questionsStatus[code] = false;
          }
        }
      }
    });
    this.status = false;
    this.subscriber = callback;
    if (callback) callback(this.status);
  }

  static clear() {
    this.subscriber = null;
    this.questionsStatus = {};
    this.status = false;
  }

  static setQuestionStatus(code) {
    if (this.questionsStatus.hasOwnProperty(code)) {
      if (!this.questionsStatus[code]) {
        this.questionsStatus[code] = true;
        let allLoaded = true;
        for (const status of Object.values(this.questionsStatus)) {
          if (!status) {
            allLoaded = false;
            break;
          }
        }
        if (allLoaded !== this.status) {
          this.status = allLoaded;
          if (this.subscriber) this.subscriber(this.status);
        }
      }
    }
  }
}

function groupTreeMap(groups, callback) {
  for (const group of groups) {
    if (group.questionCodes && group.questionCodes.length) {
      callback(group.id, group.questionCodes);
    }
    if (group.subGroups && group.subGroups.length) {
      groupTreeMap(group.subGroups, callback);
    }
  }
}

export default LoadedStatusTrigger;
