import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withTranslation from '../../components/_hoc/withTranslation';
import { serviceRequestModule } from './ServiceRequestDucks';

const getPassportInfo = (requestForm, t, lang) => {
  let result = [{ label: t('serviceRequest_number'), value: requestForm.id }];

  result.push({
    label: t('serviceRequest_status'),
    value: requestForm[`${lang}ProcessingStatus`]
  });
  result.push({
    label: t('serviceRequest_processing_result'),
    value: requestForm[`${lang}ResolutionType`]
  });
  result.push({
    label: t('serviceRequest_date'),
    value: moment(requestForm.submissionDate).format('DD.MM.YYYY HH:mm')
  });
  result.push({
    label: t('serviceRequest_iin'),
    value: requestForm.requesterIin
  });
  requestForm.externalRequestId &&
  result.push({
    label: t('serviceRequest_PEP_number'),
    value: requestForm.externalRequestId
  });
  if (requestForm.serviceProvider) {
    result.push({
      label: t('serviceRequest_providerId'),
      value: requestForm.serviceProvider.id
    });
    result.push({
      label: t('serviceRequest_providerBin'),
      value: requestForm.serviceProvider.bin
    });
    result.push({
      label: t('serviceRequest_serviceProvider'),
      value: requestForm.serviceProvider[`${lang}_name`]
    });
  }
  return result;
};

function ServiceRequestInfo({ info, t, lang }) {
  return (
    <div className="mb2 card p2">
      {getPassportInfo(info, t, lang).map((info, index) => (
        <div className="pd1" key={index}>
          <span className="bold textSecondary mr1">{info.label}:</span>
          <span>{info.value}</span>
        </div>
      ))}
    </div>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      info: state[serviceRequestModule].data
    }),
    null
  )
)(ServiceRequestInfo);
