import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    head_title: 'Централизованная база данных подведомственных учреждений акимата области Жетісу',
  },
  kk: {
    head_title: 'Жетісу облысы әкімдігінің ведомстволық бағынысты мекемелерінің орталықтандырылған деректер базасы',
  }
});
