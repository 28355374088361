import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    footer_call_free: '*Звонок бесплатный',
    footer_encounter_corruption: 'Если вы столкнулись с коррупцией,',
    footer_contact_call: 'обращайтесь в CALL-Центр*',
  },
  kk: {
    footer_call_free: '*Қоңырау шалу тегін',
    footer_encounter_corruption: 'Егер сіз жемқорлыққа тап болсаңыз,',
    footer_contact_call: 'осы нөмірге хабарласыңыз*'
  }
});
